import { useEffect } from "react";
import { pageTitles } from "../../config/page.config";
import { updatePageTitle } from "../../Helper/Utility";

const ServiceError: React.FC = () => {

    useEffect(() => {
        updatePageTitle(pageTitles.serviceError);
    }, []);

    return (
        <div className="nhsuk-width-container">
            <main
                className="nhsuk-main-wrapper nhsuk-main-wrapper--l"
                id="maincontent"
                role="main"
            >
                <div className="nhsuk-grid-row">
                    <div className="nhsuk-grid-column-two-thirds">
                        <div className="nhsuk-u-padding-bottom-4"></div>
                        <h1>
                            Sorry, there is a problem with the service
                        </h1>
                        <p className="nhsuk-body">Try again later.</p>
                        <p className="nhsuk-body">
                            Your answers have been saved up until the last 'Check your answers' page that
                            you saw. When the service is available, you may have to complete
                            the last section you were completing again.
                        </p>
                        <p className="nhsuk-body">
                            If you need help, contact NHS England on 0300 303 5678, or email{" "}
                            <a href="mailto:enquiries@nhsdigital.nhs.uk">
                                enquiries@nhsdigital.nhs.uk
                            </a>
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ServiceError;
