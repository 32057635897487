import React, { useState, useEffect } from "react";
import { Button, Label } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { destroyStoreData, submitSpinner } from "../../../Helper/Utility";
import { useAppSelector } from "../../../state/app/hooks";
import { IDSFCContractDetails } from "../../../state/models/app.interface";
import { getDSFCContractDetails } from "../../../state/slice/DSFC/DSFCContractDetails.slice";
import SummaryCard from "../../../Components/SummaryCard/SummaryCard";
import "../../../Content/css/nhsuk-common.css";
import { routeName } from "../../../config/page.config";

const DSFCSubmit: React.FC = () => {
    const navigate = useNavigate();
    const DSFCContractDetailsData: IDSFCContractDetails = useAppSelector(getDSFCContractDetails);
    const initialState: IDSFCContractDetails = DSFCContractDetailsData || {};
    const [dsfcData] = useState(initialState);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        navigate(routeName.dashboard);
        submitSpinner(e, destroyStoreData);
    };

    useEffect(() => {
        if (!dsfcData.dsfcpreview) {
            navigate(routeName.dashboard);
        }
    }, []);

    return (
        <>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-u-padding-bottom-4"></div>
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <SummaryCard blue>
                                <SummaryCard.Content>
                                    <SummaryCard.Heading>Application submitted</SummaryCard.Heading>
                                    <SummaryCard.Description>
                                        Your reference number
                                        <span className="nhsuk-summarycard__description2">{dsfcData?.contractNumber}</span>
                                    </SummaryCard.Description>
                                </SummaryCard.Content>
                            </SummaryCard>
                            <Label size="m">What happens next</Label>
                            <Label size="s">We will send you and the authorised signatory (Approver) an email confirming that we have received the application.</Label>
                            <Label size="s">The application will be reviewed by NHS England's Data Access Request Service (DARS), who will contact you within 7 days if further information is needed. </Label>
                            <Label size="s">The contract will then be sent to the authorised signatory to sign. (You will also receive a copy of this email) </Label>
                            <Label size="s">If you would like to speak to someone about your application, email <a href={'mailto:data.applications@nhsdigital.uk?subject=DSFC - DARS Online Application Issue' + dsfcData?.contractNumber}>data.applications@nhsdigital.uk</a> quoting your reference number in the subject field.</Label>
                        </div>
                        <div className="nhsuk-grid-column-full">
                            <Button onClick={handleClick} id="nhsuk-button-spinner">Back to homepage</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default DSFCSubmit;