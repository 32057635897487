import { controllerSection, ProcessAndStorageLocationsValue, taskListSectionStatus } from "../../../../config/page.config";
import { IApiResSectionList, ISectionStatus, ISectionStatusTaskList } from "../../../../state/models/app.interface";
import { Tag } from "nhsuk-react-components";
import { enableStatus } from "../../../../Helper/Utility";

interface OrganisationsInvolvedProps  {
    headingNumber: number, 
    taskListStatus:ISectionStatus, 
    sectionStatus: ISectionStatusTaskList,
    taskEnableSectionMultiSelect: IApiResSectionList[],
    handleClickController: (e: React.MouseEvent<HTMLAnchorElement>) => void,
    handleClickDSATask: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, callBack: (target?: HTMLElement) => void) => void,
    getPrivacyNotice: () => void
    getControllerData: (target?: HTMLElement) => void,
    handleClickProcessingStorageLocations: (e: React.MouseEvent<HTMLAnchorElement>, locationRecordType?: number) => void
    handleClickFunding: (e: React.MouseEvent<HTMLAnchorElement>) => void,
    description?:string
}

const OrganisationsInvolved: React.FC<OrganisationsInvolvedProps> = ({
    headingNumber,
    taskListStatus,
    sectionStatus,
    taskEnableSectionMultiSelect,
    handleClickController,
    handleClickDSATask,
    getPrivacyNotice,
    getControllerData,
    handleClickProcessingStorageLocations,
    handleClickFunding,
    description,
  }) => {
    const checkEnable = (sectionId: Number) => {
        return enableStatus(sectionId, taskEnableSectionMultiSelect);
    }
    return (
        <>
            <h3>{headingNumber}. About the organisations involved</h3>
            <p>{description}</p>
            <table className="nhsuk-table section-list-no-border">
                <tbody className="nhsuk-table__body">
                    {checkEnable(controllerSection.aboutController) &&   <tr className="nhsuk-table__row section-list-border-tp">
                        <td className="nhsuk-table__cell ">
                            <a href="#" className="nhsuk_downloadOnclick" onClick={handleClickController}>About the Controller </a>
                        </td>
                        <td className="nhsuk-table__cell">
                            {String(taskListStatus.aboutController) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag>
                                : (String(taskListStatus.aboutController) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag>
                                    : (String(taskListStatus.aboutController) === String(taskListSectionStatus.actionNeeded)) ? <Tag color="orange" className="nhsuk-float-right">ACTION NEEDED</Tag>
                                        : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)}                        </td>
                    </tr>}

                    {checkEnable(controllerSection.PrivacyNotice) && <tr className="nhsuk-table__row">
                        <td className="nhsuk-table__cell"><a href="#" onClick={(e) => handleClickDSATask(e, getPrivacyNotice)}>Privacy Notice</a></td>
                        <td className="nhsuk-table__cell">
                            {String(taskListStatus.PrivacyNotice) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.PrivacyNotice) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : <Tag color="white" className="nhsuk-float-right">MAKE CHANGES</Tag>)}
                        </td>
                    </tr>}

                    {checkEnable(controllerSection.aboutProcessor) && <tr className="nhsuk-table__row">
                        <td className="nhsuk-table__cell">
                           <a href="#" onClick={(e) => handleClickDSATask(e, getControllerData)}>About the Processor</a>
                        </td>
                        <td className="nhsuk-table__cell">
                            {String(taskListStatus.aboutProcessor) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag>
                                : (String(taskListStatus.aboutProcessor) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag>
                                    : (String(taskListStatus.aboutProcessor) === String(taskListSectionStatus.actionNeeded)) ? <Tag color="orange" className="nhsuk-float-right">ACTION NEEDED</Tag>
                                        : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)}                              </td>
                    </tr>}
                    {checkEnable(controllerSection.ProcessingLocations) && <tr className="nhsuk-table__row">
                        <td className="nhsuk-table__cell">
                            <a href="#" onClick={(e) => handleClickProcessingStorageLocations(e, ProcessAndStorageLocationsValue!.Processor)}>Processing Locations</a>
                        </td>
                        <td className="nhsuk-table__cell">
                            {String(taskListStatus.ProcessingLocations) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.ProcessingLocations) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)}
                        </td>
                    </tr>}
                    {checkEnable(controllerSection.StorageLocations) && <tr className="nhsuk-table__row">
                        <td className="nhsuk-table__cell">
                            <a href="#" onClick={(e) => handleClickProcessingStorageLocations(e, ProcessAndStorageLocationsValue!.Storage)}>Storage Locations</a>
                        </td>
                        <td className="nhsuk-table__cell">
                            {String(taskListStatus.StorageLocations) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.StorageLocations) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)}
                        </td>
                    </tr>}
                    {checkEnable(controllerSection.Funding) && <tr className="nhsuk-table__row">
                        <td className="nhsuk-table__cell">
                            <a href="#" onClick={handleClickFunding}>Funding</a></td>
                        <td className="nhsuk-table__cell">
                            {String(taskListStatus.Funding) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.Funding) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)}
                        </td>
                    </tr>}
                </tbody>
            </table>
        </>
    )
}

export default OrganisationsInvolved;