import { Button, InsetText, Details, Textarea } from "nhsuk-react-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitScreenOutputsAndBenefits_URL } from "../../../../config/api-endpoints.config";
import { dsaContent, fileTypeSize, purposeErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IPurpose } from "../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getPurposeDetails, setPurposeDetailsData } from "../../../../state/slice/Purpose/Purpose.slice";

const ExpectedOutputs: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const [purposeData, setPurposeData] = useState(useAppSelector(getPurposeDetails) || {});
    const [expectedOuputErrorMessage, setExpectedOutputErrorMessage] = useState("");
    const savePurposeDataInStore = (stateData?: IPurpose) => {
        dispatch(setPurposeDetailsData({ ...purposeData, ...stateData }));
    }

    const [isFromAnswerPage,] = useState(purposeData?.expectedCheckYourAnswerPage);

    const submitExpectedBenefit = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "SpecificOutputsExpected": purposeData.specificOutputsExpected,
            "ExpectedMeasurableBenefits": purposeData.expectedMeasurableBenefits,
            "SectionStatus": dsaApplicationData.aboutExpectedOutputsAndBenefitsSectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenOutputsAndBenefits_URL, requestOptions)
            .then(() => {
                    let sectionStatus: IApplication = {
                        ...dsaApplicationData, aboutObjectiveAndProcessingSectionStatus: taskListSectionStatus.inProgress
                    }
                    setDsaApplicationData(sectionStatus);
                saveApplicationDataInStore(sectionStatus);
                if (isFromAnswerPage !== undefined && isFromAnswerPage != '') {
                    navigate(routeName.expectedCheckYourAnswer);
                }
                else {
                    navigate(routeName.expectedBenefits);
                }
            })
            .catch(() => navigate(routeName.serviceError));
    }


    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, submitExpectedBenefit);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IPurpose = {};
        updatedState = {
            ...purposeData,
            specificOutputsExpected: e.target.value,
            isPurposeUpdated: true
        }
        setPurposeData(updatedState);
        savePurposeDataInStore(updatedState);
        resetError(e);
    }

    const resetError = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== "") {
            setExpectedOutputErrorMessage("");
        }
    };


    const validateFields = () => {
        let isValid: boolean = true;
        if (!purposeData.specificOutputsExpected || purposeData.specificOutputsExpected.trim() === "") {
            setExpectedOutputErrorMessage(`${purposeErrorMessage.selectExpectedOutput_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                Expected outputs and benefits
                            </span>
                            <h1>Outputs</h1>
                            {expectedOuputErrorMessage !== "" && <ErrorDetails errorDescription={expectedOuputErrorMessage} errorHref="#specificOutputsExpected"></ErrorDetails>}
                            <InsetText>
                                <p>On this page, tell us what data processing enables you to produce (the output), how you will disseminate and communicate your output, and your plans to exploit it. </p>
                                <p>
                                    Use the 'Guidance' to help you provide information in the text box.
                                </p>
                            </InsetText>
                            <Details>
                                <Details.Summary>Guidance </Details.Summary>
                                <Details.Text>
                                    <p>Explain what will be produced from the data processing. This may include (but is not limited to): </p>
                                    <ul>
                                        <li>reports (indicate milestone(s) or frequency of expected reports) </li>
                                        <li>submissions to peer-reviewed journals (indicate milestone(s) or frequency of expected submissions) </li>
                                        <li>materials for conference presentations </li>
                                        <li>dashboards (published on an organisation's website) </li>
                                        <li>tools (the production of) </li>
                                        <li>databases (to be utilised as a resource for health research) </li>
                                    </ul>
                                    <p>Explain what level of data will be contained in the outputs. This is likely to be aggregate data with small numbers suppressed, for example, it might be that 'The outputs will not contain NHS England data and will only contain aggregated information with small numbers suppressed'.</p>
                                    <p>Describe your approach to dissemination and communication. Your approach should include information about developed plans that align the intended results of the research with clearly defined tasks.</p>
                                    <h4>Dissemination of outputs </h4>
                                    <p>You should aim to facilitate the dissemination of the research to stakeholders, both during the project and after its completion. Dissemination activities may target audiences that include researchers, scientists, innovative technology-focused organisations and research participants. Activities should also reach beyond the scientific community to engage with policy makers.</p>
                                    <p>Dissemination activities: </p>
                                    <ul>
                                        <li>enable engagement with scientific and policy-making communities </li>
                                        <li>ensure that knowledge developed through research benefits these communities </li>
                                    </ul>
                                    <p>Your dissemination channels might include: </p>
                                    <ul>
                                        <li>journals </li>
                                        <li>workshops </li>
                                        <li>webinars </li>
                                        <li>social media </li>
                                        <li>public reports</li>
                                        <li>direct bilateral engagement using new and established relationships </li>
                                        <li>industry newsletters </li>
                                        <li>briefing documents </li>
                                        <li>co-hosted events </li>
                                        <li>open-source frameworks </li>
                                        <li>public events </li>
                                        <li>posters </li>
                                        <li>patient information sheets (PIS) </li>
                                        <li>press or media engagement </li>
                                        <li>public promotion of research </li>
                                        <li>participant new letters </li>
                                        <li>reports aimed at participants </li>
                                    </ul>
                                    <h4>Communication of outputs </h4>
                                    <p>Actively communicating outputs ensure that: </p>
                                    <ul>
                                        <li>information about the project reaches interested groups and civil society </li>
                                        <li>the applicant engages with these groups in knowledge-sharing and dialogue </li>
                                    </ul>
                                    <p>Your communication channels might include: </p>
                                    <ul>
                                        <li>website publications </li>
                                        <li>newsletters </li>
                                        <li>open lectures and talks </li>
                                        <li>exhibitions at public events </li>
                                        <li>posters </li>
                                        <li>press or media engagement </li>
                                        <li>stakeholder mailing lists  </li>
                                    </ul>
                                    <h4>Exploitation of outputs </h4>
                                    <p>Describe any exploitation plans that you have. Increasingly, research through data processing is used to develop algorithms and test and develop tools and recent technologies.  </p>
                                    <p>Testing and developing may involve the creation of:  </p>
                                    <ul>
                                        <li>evaluation environments for the assessment and validation of processes, tools and technologies </li>
                                        <li>commercial exploitation pathways (during the lifetime of the project or beyond the project's completion) </li>
                                    </ul>
                                    <p>Explain the target dates to produce and disseminate the outputs </p>
                                </Details.Text>
                            </Details>
                            <Textarea
                                label="Provide information on your outputs in the text box below"
                                name="Output Information"
                                id="specificOutputsExpected"
                                onChange={handleChangeTextarea}
                                defaultValue={purposeData.specificOutputsExpected ? purposeData.specificOutputsExpected : ""}
                                maxLength={32000}
                                rows={5}
                                error={expectedOuputErrorMessage}
                            />
                            <div className="nhsuk-u-padding-bottom-8"></div>
                            <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick} >{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default ExpectedOutputs