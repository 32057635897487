import { Button, Fieldset, InsetText, Radios } from "nhsuk-react-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitScreenCommercialPurposeAndSublicensing_URL } from "../../../../config/api-endpoints.config";
import { dsaContent, fileTypeSize, purposeErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IPurpose } from "../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getPurposeDetails, setPurposeDetailsData } from "../../../../state/slice/Purpose/Purpose.slice";

const Commercial: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [purposeData, setPurposeData] = useState<IPurpose>(useAppSelector(getPurposeDetails) || {});
    const [commercialErrorMessage, setCommercialErrorMessage] = useState("");
    const savePurposeDataInStore = (stateData?: IPurpose) => {
        dispatch(setPurposeDetailsData({ ...purposeData, ...stateData }));
    }
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const isFromAnswerPage = purposeData?.commercialSublicensingCheckYourAnswerPage;

    /* Function to set the state value on change of Radios(yes/no) */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IPurpose = {};
        updatedState = {
            ...purposeData,
            [e.target.name]: e.target.value,
            isPurposeUpdated: true
        }
        setPurposeData(updatedState);
        savePurposeDataInStore(updatedState);
        resetError(e);
    }
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setCommercialErrorMessage("");
        }
    };
    /* Function to set the state value on change of Radios(yes/no) */

    /* To handle Onclick */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            if (isFromAnswerPage !== undefined && isFromAnswerPage != '')
                submitSpinner(e, submitCommercial());
            else
                submitSpinner(e, navigateCommercial());
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    /* To Validate the each field's data */
    const ValidateFields = () => {
        let isValid: boolean = true;
        if (!purposeData.applicationForCommercialPurposes) {
            setCommercialErrorMessage(`${purposeErrorMessage.selectCommerical_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const navigateCommercial = () => {
        if (purposeData.applicationForCommercialPurposes === dsaContent.yes) {
            navigate(routeName.commercialPurpose);
        } else {
            navigate(routeName.dsaSublicensing);
        }
    }

    const submitCommercial = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "ApplicationForCommercialPurposes": purposeData.applicationForCommercialPurposes === dsaContent.yes ? true : false,
            "ApplicationForCommercialPurposesExplanation": purposeData.applicationForCommercialPurposes === dsaContent.yes ? purposeData.applicationForCommercialPurposesExplanation : null,
            "DoesSublicensingApply": purposeData.doesSublicensingApply === dsaContent.yes ? true : false,
            "Duration": purposeData.duration,
            "SectionStatus": dsaApplicationData.commercialSublicensingSectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitScreenCommercialPurposeAndSublicensing_URL, requestOptions)
            .then(() => {
                    let sectionStatus: IApplication = {
                        ...dsaApplicationData, commercialSublicensingSectionStatus: taskListSectionStatus.inProgress
                    }
                    setDsaApplicationData(sectionStatus);
                    saveApplicationDataInStore(sectionStatus);
                    if (purposeData.applicationForCommercialPurposes === dsaContent.yes) {
                        navigate(routeName.commercialPurpose);
                    } else {
                        navigate(routeName.summaryCommercial);
                    }
              
            })
            .catch(() => navigate(routeName.serviceError));
    }

    /* To handle Onclick */


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                Commercial purposes and sub-licensing
                            </span>
                            <h1>Would any shared data from NHS England be used for commercial purposes? </h1>
                            {commercialErrorMessage !== "" && <ErrorDetails errorDescription={commercialErrorMessage} errorHref="#radioApplicationForCommercialPurposes"></ErrorDetails>}
                            <InsetText>
                                <p>You will be using your data for commercial purposes if any of the Controllers involved charge (or permit the charging of) a fee for the use of data.</p>
                                <p>You will also be using your data for commercial purposes if any party involved in the application receives any form of commercial benefit (including intangible or indirect commercial benefits such as positive publicity for a commercial venture) from the use of data, for example commercial funders or sponsors.</p>
                            </InsetText>
                            <Fieldset>
                                <Radios name="radioApplicationForCommercialPurposes" id="radioApplicationForCommercialPurposes" error={commercialErrorMessage}>
                                    <Radios.Radio name="applicationForCommercialPurposes" onChange={handleChange} value={dsaContent.yes} checked={purposeData.applicationForCommercialPurposes === dsaContent.yes}>Yes</Radios.Radio>
                                    <Radios.Radio name="applicationForCommercialPurposes" onChange={handleChange} value={dsaContent.no} checked={purposeData.applicationForCommercialPurposes === dsaContent.no}>No</Radios.Radio>
                                </Radios>
                            </Fieldset>
                            <div className="nhsuk-u-padding-bottom-8"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default Commercial