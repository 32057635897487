import { Button, Label, WarningCallout } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../../Components/Shared/ControllerHeader";
import { UpdateApplicationChangeType_URL } from "../../../../../config/api-endpoints.config";
import { dsaContent, fileTypeSize, routeName } from "../../../../../config/page.config";
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../../state/app/hooks";
import { AppDispatch } from "../../../../../state/app/store";
import { IApplication } from "../../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../../state/slice/DSA/DSA.slice";
import GoBack from "../../../../../Components/Shared/GoBack";


const AmendBeforeYouStart: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const [btnText, setBtnText] = useState(dsaContent.btnTextStart);
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData, setDSAApplicationData] = useState(initialApplicationState);

    const saveDSAApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    const navigateChooseSection = () => {
        navigate(routeName.chooseAmendSection);
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (btnText === dsaContent.btnTextStart) { submitSpinner(e, updateApplicationChangeType); }
        else {
            submitSpinner(e, navigateChooseSection);
            
        }
    };
    /* Update Application Type */
    const updateApplicationChangeType = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "ApplicationChangeType": dsaApplicationData.applicationChangeType,
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(UpdateApplicationChangeType_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setApplicationId(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    /* Set Application Id into store*/
    const setApplicationId = (data: string) => {
        let updatedState: IApplication = {};
        updatedState = {
            ...dsaApplicationData,
            id: data,
        }
        setDSAApplicationData(updatedState);
        saveDSAApplicationDataInStore(updatedState);
        navigate(routeName.chooseAmendSection);
    }


    useEffect(() => {
        (dsaApplicationData.returningFromExtendRenewAmend === dsaContent.isFromAmend) ?
            setBtnText(dsaContent.btnTextContinue) : setBtnText(dsaContent.btnTextStart)

    }, []);

    return (
        <>
            {(dsaApplicationData && dsaApplicationData.id !== "" && dsaApplicationData.id !== undefined) ?
                <ControllerHeader></ControllerHeader>
                : null}
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-full">
                            {
                                <GoBack condition={String(dsaApplicationData.returningFromExtendRenewAmend) === dsaContent.isFromAmend ? dsaContent.applyDsa : dsaContent.managedsa} />
                            }
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <div className="nhsuk-u-padding-bottom-4"></div>
                            <h1>Making Changes </h1>
                            <WarningCallout>
                                <WarningCallout.Label>Making changes </WarningCallout.Label>
                                <p>On the next page you will be able to select and submit the changes that you would like to make.</p>
                                <p>Any changes you make to your Data Sharing Agreement (DSA) may mean that it needs to go through the full approval process. The time it takes to process changes may depend on the number of adjustments you make.</p>
                                <p>Once you have submitted your changes, you will need to wait for them to be processed before submitting any further changes.</p>
                            </WarningCallout>
                            <p>You have selected, 'I would like to make a change to my DSA'.</p>
                            <p>During your application, you will only see the sections that you are able to change as part of your request to make changes to your DSA. </p>
                            <p>Where you do not make any changes to a particular section, your answers will stay the same as they were when you first applied for a DSA.</p>
                            <p>If you select and submit changes in error, you will need to contact your Case Officer. </p>

                            <Label size="m">Before you start:</Label>
                            <Label size="s" className="nhsuk-label-font-normal-600">Make sure you read the information in the links below. All links will open in a new window.</Label>
                            <ul>
                                <li><a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/dars-how-to-make-an-application" target="_blank">application guidance</a></li>
                                <li><a href="https://digital.nhs.uk/services/data-access-request-service-dars/data-access-request-service-dars-process/data-access-request-service-dars-pre-application-checklist" target="_blank">pre-application checklist</a></li>
                                <li><a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance#standards-of-information-expected-in-a-data-access-application" target="_blank">standards of information expected</a></li>
                            </ul>
                            <Label size="s" className="nhsuk-label-font-normal-600">Use the below links to help you understand:</Label>
                            <ul>
                                <li>what <a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-products-and-services" target="_blank">data sets</a> you are requesting</li>
                                <li>what <a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/dars-how-to-make-an-application#legal-basis-for-providing-the-data" target="_blank">legal basis</a> will apply</li>
                                <li>the <a href="https://digital.nhs.uk/services/data-access-request-service-dars/data-access-request-service-dars-charges" target="_blank">charges</a> that will apply for the data you are requesting</li>
                            </ul>
                            <Label size="s">You can see some examples of applications that have been approved in our <a href="https://digital.nhs.uk/services/data-access-request-service-dars/data-uses-register" target="_blank">Data Uses Register</a>. These previously accepted applications provide examples of well-written answers to questions that will support your application process.</Label>
                            <Label size="s">If you need support with your request for data, email <a href={"mailto:data.applications@nhsdigital.nhs.uk?subject="+dsaApplicationData?.referenceNumber}>data.applications@nhsdigital.nhs.uk</a></Label>
                            <Label size="m">After you've submitted your application</Label>
                            <Label size="s" bold>The DARS team will:</Label>
                            <ul>
                                <li>review your application</li>
                                <li>work with you to collect any missing details</li>
                            </ul>
                            <Button id="nhsuk-button-spinner" onClick={handleClick} >{btnText}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default AmendBeforeYouStart;
