import { Button, InsetText, Textarea } from "nhsuk-react-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitScreenCommercialPurposeAndSublicensing_URL } from "../../../../config/api-endpoints.config";
import { dsaContent, fileTypeSize, purposeErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { fetchInterceptor, getCookie, submitSpinner} from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IPurpose } from "../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getPurposeDetails, setPurposeDetailsData } from "../../../../state/slice/Purpose/Purpose.slice";

const CommercialPurpose: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [purposeData, setPurposeData] = useState<IPurpose>(useAppSelector(getPurposeDetails) || {});
    const [commercialPurposeErrorMessage, setCommercialPurposeErrorMessage] = useState("");
    const savePurposeDataInStore = (stateData?: IPurpose) => {
        dispatch(setPurposeDetailsData({ ...purposeData, ...stateData }));
    }
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const isFromAnswerPage = purposeData?.commercialSublicensingCheckYourAnswerPage;
    /* Function to set the state value*/
    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IPurpose = {};
        updatedState = {
            ...purposeData,
            [e.target.name]: e.target.value,
            isPurposeUpdated: true
        }
        setPurposeData(updatedState);
        savePurposeDataInStore(updatedState);
        resetError(e);
    }
    const resetError = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== "") {
            setCommercialPurposeErrorMessage("");
        }
    };
    /* Function to set the state value*/

    /* To handle Onclick */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            if (isFromAnswerPage !== undefined && isFromAnswerPage != '')
                submitSpinner(e, submitCommercialPurpose());
            else
                submitSpinner(e, navigateCommercialPurpose());
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    /* To Validate the each field's data */
    const ValidateFields = () => {
        let isValid: boolean = true;
        if (purposeData.applicationForCommercialPurposesExplanation === null || purposeData.applicationForCommercialPurposesExplanation === undefined || purposeData.applicationForCommercialPurposesExplanation.trim() === "") {
            setCommercialPurposeErrorMessage(`${purposeErrorMessage.selectCommericalPurpose_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const navigateCommercialPurpose = () => {
        navigate(routeName.dsaSublicensing);
    }

    const submitCommercialPurpose = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "ApplicationForCommercialPurposes": purposeData.applicationForCommercialPurposes === dsaContent.yes ? true : false,
            "ApplicationForCommercialPurposesExplanation": purposeData.applicationForCommercialPurposesExplanation,
            "DoesSublicensingApply": purposeData.doesSublicensingApply === dsaContent.yes ? true : false,
            "Duration": purposeData.duration,
            "SectionStatus": dsaApplicationData.commercialSublicensingSectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitScreenCommercialPurposeAndSublicensing_URL, requestOptions)
            .then(() => {
                    let sectionStatus: IApplication = {
                        ...dsaApplicationData, commercialSublicensingSectionStatus: taskListSectionStatus.inProgress
                    }
                    setDsaApplicationData(sectionStatus);
                    saveApplicationDataInStore(sectionStatus);
                    navigate(routeName.summaryCommercial);
               
            })
            .catch(() => navigate(routeName.serviceError));
    }

    /* To handle Onclick */


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                Commercial purposes and sub-licensing
                            </span>
                            <h1>Provide details of how shared data from NHS England would be used for commercial purposes</h1>
                            {commercialPurposeErrorMessage !== "" && <ErrorDetails errorDescription={commercialPurposeErrorMessage} errorHref="#applicationForCommercialPurposesExplanation"></ErrorDetails>}
                            <InsetText>
                                <p>NHS England need to understand any commercial arrangements relating to the release of data, such as whether:</p>
                                <ul>
                                    <li>you will charge for any outputs produced using the data </li>
                                    <li>the output will be used for marketing purposes</li>
                                    <li>the application for data is for a research project that is funded or supported (either wholly or in part) by a commercial organisation</li>
                                </ul>
                            </InsetText>
                            <Textarea
                                label="Use the text box to provide details of how data would be used for commercial purposes."
                                name="applicationForCommercialPurposesExplanation"
                                id="applicationForCommercialPurposesExplanation"
                                onChange={handleChangeTextarea}
                                maxLength={32000}
                                rows={5}
                                error={commercialPurposeErrorMessage}
                                defaultValue={purposeData.applicationForCommercialPurposesExplanation}
                            />
                            <div className="nhsuk-u-padding-bottom-8"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default CommercialPurpose