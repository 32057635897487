import { BackLink, Button, Fieldset, InsetText, Label, Radios } from "nhsuk-react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InfoHeaderPanel from "../../../../Components/InfoHeaderPanel/InfoHeaderPanel";
import { controllerDetails, routeName } from "../../../../config/page.config";
import { useAppSelector } from "../../../../state/app/hooks";
import { IHolder } from "../../../../state/models/app.interface";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
const SignDSA: React.FC = () => {
    const DSADetailsData: IHolder = useAppSelector(getDSADetails);
    const dsaInitialState: IHolder = DSADetailsData || {};
    const [dsaData] = useState(dsaInitialState);
    let navigate = useNavigate();

    const handleClick = () => {
        navigate(routeName.securityAssurance);
    }

    return (
        <>
            <InfoHeaderPanel>
                <InfoHeaderPanel.Item >
                    <strong>Reference Number</strong> : {dsaData.referenceNumber}
                </InfoHeaderPanel.Item>
            </InfoHeaderPanel>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <BackLink href="#" onClick={() => { navigate(routeName.processingData) }}>Go Back</BackLink>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the Controller
                            </span>
                            <h1>Who will sign the Data Sharing Agreement on behalf of the organisation?</h1>
                            <Label size="s">The Person who signs the DSA must have authority to sign agreements on behalf of the organisation</Label>
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <Fieldset className="nhsuk-space-bottom-double">
                                <Radios name="jointController" id="jointController" hint="">
                                    <Radios.Radio name="signerDSA" value="JimmyJazz">Jimmy Jazz</Radios.Radio>
                                    <Radios.Radio name="signerDSA" value="MarianneMusgrove" >Marianne Musgrove</Radios.Radio>
                                    <Radios.Radio name="signerDSA" value="MillyFergusonHamilton">Milly Ferguson-Hamilton</Radios.Radio>
                                    <Radios.Radio name="signerDSA" value="SignatoryNotListed" >Signatory not listed</Radios.Radio>
                                </Radios>
                            </Fieldset>
                            <InsetText className="nhsuk-space-bottom-double">
                                <Label size="s"> If the person signing your DSA is not listed, email <a href='mailto:data.applications@nhsdigital.nhs.uk'>data.applications@nhsdigital.nhs.uk</a>. Include your application reference number in the subject field.</Label>
                                <Label size="s">  If you don't know who is authorised to sign Data Sharing Agreements on behalf of your organisation, contact your local Contract or Information Governance Team</Label>
                            </InsetText>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{controllerDetails.btnSaveAndContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default SignDSA;