import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { ISecurityAssurance } from '../../models/app.interface'

const initialState: ISecurityAssurance = {
    SAType: "",
    SAId: "",
    ISOVersion: "",
    ISODateComplete: "",
    ISONotes: "",
    SLSPNotes: "",
    SLSPDateComplete: "",
    DSPT_ODS_Code: "",
    DSPT_Latest_Status: "",
    DSPT_Date_Published: "",
    DSPT_Comments:"",
    SectionType: "",
    DSPTDay: "",
    DSPTMonth: "",
    DSPTYear: "",
    ISODay: "",
    ISOMonth: "",
    ISOYear: "",
    SLSPDay: "",
    SLSPMonth: "",
    SLSPYear: "",
    SAIndex:0
}

export const SecurityAssuranceSlice = createSlice({
    name: 'SecurityAssurance',
    initialState,
    reducers: {
        setSecurityAssuranceDetailsData: (state: ISecurityAssurance, action: PayloadAction<ISecurityAssurance>) => {
            state = Object.assign(state, action.payload);
        },
        resetSecurityAssuranceDetailsData: (state: ISecurityAssurance, action: PayloadAction<ISecurityAssurance>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setSecurityAssuranceDetailsData, resetSecurityAssuranceDetailsData } = SecurityAssuranceSlice.actions;
export const getSecurityAssuranceDetails = (state: RootState) => state.securityAssuranceDetails;
export default SecurityAssuranceSlice.reducer;