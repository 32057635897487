import React, { useState } from "react";
import { Button, ErrorSummary, Textarea, InsetText } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IApplication, IEvidence } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getEvidenceDetails, setEvidenceDetailsData } from "../../../../state/slice/Evidence/Evidence.slice";
import { amendRenewExtendValue, controllerSection, dsaContent, dsaErrorMessage, EthicsApprovalInPlaceTypeOptions, evidenceErrorMessage, fileTypeSize, routeName, taskListSectionStatus } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { fetchInterceptor, getCookie, submitDSASectionStatus, submitSpinner } from "../../../../Helper/Utility";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { SubmitEthicsApproval_URL } from "../../../../config/api-endpoints.config";

const NoEthicsApproval: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [noEthicsApprovalErrorMessage, setNoEthicsApprovalErrorMessage] = useState("");

    const initialState: IEvidence = useAppSelector(getEvidenceDetails) || {};
    const [evidenceData, setevidenceData] = useState(initialState)

    const saveEvidenceDataInStore = (stateData?: IEvidence) => {
        dispatch(setEvidenceDetailsData({ ...evidenceData, ...stateData }));
    }

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IEvidence = {};
        if (e.target.value !== null && e.target.value !== undefined) {
            updatedState = {
                ...evidenceData, [e.target.name]: e.target.value,
                noEthicsApproval: e.target.value,
                ethicsApproval: "",
                ethicsApprovalNotInPlace:""
            }
            setevidenceData(updatedState);
            saveEvidenceDataInStore(updatedState);
            resetError(e);
        }
    }

    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setNoEthicsApprovalErrorMessage("");
        }
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, submitEthicsApproval);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const submitEthicsApproval = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "EthicsApprovalRequire": evidenceData.isEvidenceApproval,
            "EthicsApprovalInPlace": evidenceData.ethicsApproval,
            "EthicsApprovalReason": evidenceData.noEthicsApproval,
            "EthicsApprovalPlaceReason": evidenceData.ethicsApprovalNotInPlace
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitEthicsApproval_URL, requestOptions)
            .then(() => {
                    setEthicsApprovalStatus();
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setEthicsApprovalStatus = () => {
        submitDSASectionStatus(dsaApplicationData?.id!, controllerSection.EthicsApproval, taskListSectionStatus.completed)
            .then((data: Response) => {
                if (data) {
                    let updatedState: IEvidence = {
                        ...evidenceData,
                        aboutEthicsApprovalStatus: taskListSectionStatus.completed
                    }
                    setevidenceData(updatedState);
                    saveEvidenceDataInStore(updatedState);
                    navigate(routeName.dsaTaskList);
                }
            });
    }
    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href={"#" + dsaContent.noEthicsApproval}>{summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    /* To Validate the field's data */
    const validateFields = () => {
        let isValid: boolean = true;
        if (evidenceData.noEthicsApproval === null || evidenceData.noEthicsApproval === '') {
            setSummaryLinkErrorMessage(`${evidenceErrorMessage.noEthicsApproval_ErrorMessage}`);
            setNoEthicsApprovalErrorMessage(`${evidenceErrorMessage.noEthicsApproval_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <h1>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom ">Ethics approval</span>
                                Tell us why your purpose does not require ethics approval</h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <InsetText><p>You told us that your purpose does not require ethics approval. Now tell us why.</p></InsetText>
                            <Textarea
                                label="Provide information the below text box "
                                name={dsaContent.noEthicsApproval}
                                id={dsaContent.noEthicsApproval}
                                onChange={handleChange}
                                defaultValue={evidenceData.noEthicsApproval ? evidenceData.noEthicsApproval : ''}
                                maxLength={4000}
                                rows={5}
                                error={noEthicsApprovalErrorMessage}
                            />
                            <div className="nhsuk-u-padding-bottom-4">
                            </div>
                            <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                        </div>
                    </div>
                </main>
            </div>
        </>)
}
export default NoEthicsApproval;
