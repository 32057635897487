import React, { useEffect, useState } from "react";
import { Button, Radios, Hint, ErrorSummary, InsetText, Fieldset } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IApplication, IDataSet, IDataSetFrequency } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";
import { applicationChangeType, dataSetErrorMessage, dsaContent, frequencyOfDataOptionTypes, routeName, taskListSectionStatus } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { checkApplicationChangeType, disableHistoricAnnualCheckBox, submitProductData, submitSpinner } from "../../../../Helper/Utility";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";

const DatasetFrequency: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [datasetFrequencyErrorMessage, setdatasetFrequencyErrorMessage] = useState("");
    const dataSetDetailsData: IDataSet = useAppSelector(getDataSetDetails);
    const initialState: IDataSet = dataSetDetailsData || {};
    const [dataSetData, setDataSetData] = useState(initialState);

    const initialApplicationState: IApplication = useAppSelector(getDSADetails) || {};
    const [dsaApplicationData, setDsaApplicationData] = useState(initialApplicationState || {});      
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }


    /* To dispatch the setDataSetDetailsData Redux action */
    const saveDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }
    const [isfrequencymonthly, setisfrequencymonthly] = useState(false);
    const [isfrequencyannually, setisfrequencyannually] = useState(false);
    const [isfrequencyquarterly, setisfrequencyquarterly] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IDataSet = {};

        if (e.target.value !== null && e.target.value !== undefined) {
            updatedState = { ...dataSetData, [e.target.name]: e.target.value }
            resetError(e);
            setDataSetData(updatedState);
            saveDataInStore(updatedState);
        }
    }
    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>) => {
        const target = e.target as HTMLInputElement;
        if (target.value !== "" || (dataSetData?.frequencylist !== null && dataSetData?.frequencylist !== undefined && dataSetData?.frequencylist?.filter((x: unknown) => x !== undefined && x !== null && x !== "").length > 1)) {
            setSummaryLinkErrorMessage("");
            setdatasetFrequencyErrorMessage("");
        }
    }
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!isfrequencymonthly && !isfrequencyquarterly && !isfrequencyannually) {
            const isValid: boolean = validateFields();
            if (isValid) {
                handleNavigation(e,dataSetData.historicAnnual!);
            }
        }
        else if (isfrequencymonthly || isfrequencyquarterly || isfrequencyannually) {
            let updatedState: IDataSet = {};

            let frequencies = String(isfrequencymonthly ? frequencyOfDataOptionTypes?.monthlyValue : isfrequencyannually ? frequencyOfDataOptionTypes?.annuallyValue : isfrequencyquarterly ? frequencyOfDataOptionTypes?.quarterlyValue : null);
            updatedState = { ...dataSetData, datasetFrequency: frequencies }
            resetError(e);
            setDataSetData(updatedState);
            saveDataInStore(updatedState);
            handleNavigation(e,dataSetData.historicAnnual!);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const navigateHistoricAnnualData = () => {
        navigate(routeName.historicAnnualData);
    }

    const navigateDataSetField = () => {
        navigate(routeName.selectDatasetField);
    }

    const handleNavigation = (e: React.MouseEvent<HTMLButtonElement>, historicAnnual: boolean) => {
        if (historicAnnual && !disableHistoricAnnualCheckBox(dataSetData.historicAnnualDataList!, dataSetData.existingHistoricAnnualDataList!)) {
            submitSpinner(e, navigateHistoricAnnualData);
        }
        else if (checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)])) {
            submitProductData(dsaApplicationData, dataSetData)
                .then((data: Response) => {
                    if (data) {                       
                        let sectionStatus: IApplication = {
                            ...dsaApplicationData, aboutDataSetSectionStatus: taskListSectionStatus.inProgress
                        }
                        setDsaApplicationData(sectionStatus);
                        saveApplicationDataInStore(sectionStatus);                        
                        navigate(routeName.datasetDetails);
                    }
                });
        }
        else submitSpinner(e, navigateDataSetField);
    }

    const handleFrequencyData = (value?: string) => {
        const frequency = (String(value) === String(frequencyOfDataOptionTypes.monthlyValue) || isfrequencymonthly) ? dsaContent.monthlyFrequency :
            (String(value) === String(frequencyOfDataOptionTypes.quarterlyValue) || isfrequencyquarterly) ? dsaContent.quarterlyFrequency :
                (String(value) === String(frequencyOfDataOptionTypes.annuallyValue) || isfrequencyannually) ? dsaContent.annuallyFrequency : '';
        return frequency;
    }

    const checkFrequency = () => {
        if (dataSetData?.frequencylist !== null && dataSetData?.frequencylist !== undefined && dataSetData?.frequencylist?.filter((x: unknown) => x !== undefined && x !== null && x !== "").length !== 0) {
            const frequencyLength: number = dataSetData?.frequencylist ? dataSetData?.frequencylist?.length : 0;

            if (frequencyLength === 1) {
                const frequencyValue = dataSetData?.frequencylist ? dataSetData?.frequencylist[0]?.value : null
                if (String(frequencyValue) === String(frequencyOfDataOptionTypes.monthlyValue)) {
                    setisfrequencymonthly(true);
                }
                if (String(frequencyValue) === String(frequencyOfDataOptionTypes.quarterlyValue)) {
                    setisfrequencyquarterly(true);
                }
                if (String(frequencyValue) === String(frequencyOfDataOptionTypes.annuallyValue)) {
                    setisfrequencyannually(true)
                }
            }
        }
    }

    /*Radio Populate*/
    const renderRadio = () => {
        return dataSetData?.frequencylist?.slice(0).reverse()?.map((option: IDataSetFrequency, index: number) => {
            return <React.Fragment key={index + 1}>
                {(String(option.value) === String(frequencyOfDataOptionTypes.monthlyValue) ||
                    String(option.value) === String(frequencyOfDataOptionTypes.quarterlyValue) ||
                    String(option.value) === String(frequencyOfDataOptionTypes.annuallyValue)
                ) ? <Radios.Radio name={dsaContent.datasetFrequency} value={option.value} checked={String(dataSetData.datasetFrequency) === String(option.value)} disabled={checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)]) ? true : false}
                    onChange={handleChange}>
                    {(String(option.value) === String(frequencyOfDataOptionTypes.monthlyValue) ? dsaContent.monthlyFrequency :
                        String(option.value) === String(frequencyOfDataOptionTypes.quarterlyValue) ? dsaContent.quarterlyFrequency :
                            String(option.value) === String(frequencyOfDataOptionTypes.annuallyValue) ? dsaContent.annuallyFrequency : '')}
                    <Hint>
                        Select this option if you would like to receive data at {String(handleFrequencyData(option.value)).toLowerCase()} intervals throughout the term of your Data Sharing Agreement
                    </Hint>
                </Radios.Radio> : null}

            </React.Fragment>
        });
    }

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dataSetErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#datasetFrequency">{summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    /* To Validate the each field's data */
    const validateFields = () => {
        let isValid: boolean = true;
        if (dataSetData.datasetFrequency === undefined || dataSetData.datasetFrequency === "" || dataSetData.datasetFrequency === null) {
            setSummaryLinkErrorMessage(`${dataSetErrorMessage.datasetFrequencytmandatory_ErrorMessage}`);
            setdatasetFrequencyErrorMessage(`${dataSetErrorMessage.datasetFrequencytmandatory_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }
    useEffect(() => {
        checkFrequency();
    }, [])

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                About the data &gt; {dataSetData.name}</span>
                                What frequency is required for the future release of data? </h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            {(dataSetData?.frequencylist !== null && dataSetData?.frequencylist !== undefined && dataSetData?.frequencylist?.filter((x: unknown) => x !== undefined && x !== null && x !== "").length > 1) ?
                                <>
                                    <InsetText>
                                        <p>
                                            We have presented you with the frequency options available for this data set. Use the selectable options to tell us how often you would like to receive the data.
                                        </p>
                                        <p> Each option you select carries an associated cost. </p>
                                        <p> The date you receive the data depends on the publication date of the data set. You will receive the data after the publication date.
                                        </p>
                                        <p> You will find out the frequency schedule after submission of your application.
                                        </p>
                                    </InsetText>
                                    <Fieldset className="nhsuk-space-bottom-double">
                                        <Radios name={dsaContent.datasetFrequency}
                                            id={dsaContent.datasetFrequency}
                                            error={datasetFrequencyErrorMessage}                                            
                                        >
                                            {renderRadio()}
                                        </Radios>
                                    </Fieldset>
                                </> :
                                <>
                                    <InsetText>
                                        <p>{handleFrequencyData()} is the only frequency available for this data set. </p>
                                    </InsetText>

                                </>}
                            <div className="nhsuk-u-padding-bottom-4"></div>
                            <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>                    </div>                </main>            </div>        </>)
}
export default DatasetFrequency;
