export const pageRoute = [
    //About the Controller
    {
        currentPage: 'controller-type', routePath: [
            {
                previousPage: 'controller-type', eventType: ['']
            },
            {
                previousPage: 'task-list', eventType: ['']
            },
            {
                previousPage: 'renew_extend-task-list', eventType: ['']
            },
            {
                previousPage: 'amend-task-list', eventType: ['']
            },
            {
                previousPage: 'controller-data', eventType: ['back']
            },
            {
                previousPage: 'controller-details', eventType: ['']
            },
            {
                previousPage: 'joint-sole-controller', eventType: ['back']
            }
        ]
    },
    {
        currentPage: 'controller-data', routePath: [
            {
                previousPage: 'controller-data', eventType: ['']
            },
            {
                previousPage: 'controller-details', eventType: ['', 'submit']
            },
            {
                previousPage: 'add-organisation-details', eventType: ['back']
            },
            {
                previousPage: 'controller-type', eventType: ['submit']
            },
            {
                previousPage: 'processing-data', eventType: ['back']
            },
            {
                previousPage: 'pay-for-this-service', eventType: ['back']
            },
            {
                previousPage: 'add-another-controller', eventType: ['submit']
            },
            {
                previousPage: 'delete-controller', eventType: ['submit']
            },
            {
                previousPage: 'task-list', eventType: ['']
            }
        ]
    },
    {
        currentPage: 'add-organisation-details', routePath: [
            {
                previousPage: 'add-organisation-details', eventType: ['']
            },
            {
                previousPage: 'controller-details', eventType: ['']
            },
            {
                previousPage: 'controller-data', eventType: ['']
            },
            {
                previousPage: 'controller-type', eventType: ['submit']
            },
            {
                previousPage: 'processing-data', eventType: ['back']
            },
            {
                previousPage: 'pay-for-this-service', eventType: ['back']
            }
        ]
    },
    {
        currentPage: 'processing-data', routePath: [
            {
                previousPage: 'processing-data', eventType: ['']
            },
            {
                previousPage: 'controller-details', eventType: ['']
            },
            {
                previousPage: 'controller-data', eventType: ['submit']
            },
            {
                previousPage: 'security-assurance', eventType: ['back']
            },
            {
                previousPage: 'add-organisation-details', eventType: ['submit']
            }
        ]
    },
    {
        currentPage: 'security-assurance', routePath: [
            {
                previousPage: 'security-assurance', eventType: ['']
            },
            {
                previousPage: 'controller-details', eventType: ['']
            },
            {
                previousPage: 'processing-data', eventType: ['submit']
            },
            {
                previousPage: 'dspt-details', eventType: ['back']
            },
            {
                previousPage: 'iso', eventType: ['back']
            },
            {
                previousPage: 'slsp', eventType: ['back']
            }
        ]
    },
    {
        currentPage: 'dspt-details', routePath: [{
            previousPage: 'dspt-details', eventType: ['']
        },
        {
            previousPage: 'controller-details', eventType: ['']
        },
        {
            previousPage: 'security-assurance', eventType: ['submit']
        },
        {
            previousPage: 'data-protection-registration', eventType: ['back']
        }]
    },
    {
        currentPage: 'iso', routePath: [{
            previousPage: 'iso', eventType: ['']
        },
        {
            previousPage: 'controller-details', eventType: ['']
        },
        {
            previousPage: 'security-assurance', eventType: ['submit']
        },
        {
            previousPage: 'data-protection-registration', eventType: ['back']
        }]
    },
    {
        currentPage: 'slsp', routePath: [{
            previousPage: 'slsp', eventType: ['']
        },
        {
            previousPage: 'controller-details', eventType: ['']
        },
        {
            previousPage: 'security-assurance', eventType: ['submit']
        },
        {
            previousPage: 'data-protection-registration', eventType: ['back']
        },
        {
            previousPage: 'add-another-slsp', eventType: ['','back']
        },]
    },
    {
        currentPage: 'add-another-slsp', routePath: [{
            previousPage: 'add-another-slsp', eventType: ['']
        },
        {
            previousPage: 'controller-details', eventType: ['']
        },
        {
            previousPage: 'delete-document', eventType: ['submit', 'back']
        },
        {
            previousPage: 'data-protection-registration', eventType: ['back']
        },
        {
            previousPage: 'slsp', eventType: ['submit', 'back']
        },]
    },
    {
        currentPage: 'data-protection-registration', routePath: [{
            previousPage: 'data-protection-registration', eventType: ['']
        },
        {
            previousPage: 'controller-details', eventType: ['']
        },
        {
            previousPage: 'dspt-details', eventType: ['submit']
        },
        {
            previousPage: 'iso', eventType: ['submit']
        },
        {
            previousPage: 'slsp', eventType: ['submit']
        },
        {
            previousPage: 'add-another-slsp', eventType: ['submit']
        },]
    },
    {
        currentPage: 'pay-for-this-service', routePath: [{
            previousPage: 'pay-for-this-service', eventType: ['']
        },
        {
            previousPage: 'controller-details', eventType: ['', 'submit']
        },
        {
            previousPage: 'controller-data', eventType: ['submit']
        },
        {
            previousPage: 'add-organisation-details', eventType: ['submit']
        },
        {
            previousPage: 'add-another-controller', eventType: ['submit', '']
        }]
    },
    {
        currentPage: 'delete-controller', routePath: [{
            previousPage: 'delete-controller', eventType: ['']
        },
        {
            previousPage: 'controller-details', eventType: ['']
        }]
    },
    {
        currentPage: 'add-another-controller', routePath: [{
            previousPage: 'add-another-controller', eventType: ['']
        },
        {
            previousPage: 'delete-controller', eventType: ['submit']
        },
        {
            previousPage: 'controller-data', eventType: ['back']
        }]
    },
    {
        currentPage: 'completed-controller-section', routePath: [{

            previousPage: 'completed-controller-section', eventType: ['']
        },
        {
            previousPage: 'controller-details', eventType: ['submit']
        }]
    },
    {
        currentPage: 'joint-sole-controller', routePath: [{
            previousPage: 'joint-sole-controller', eventType: ['']
        },
        {
            previousPage: 'controller-details', eventType: ['']
        },
        {
            previousPage: 'controller-type', eventType: ['submit']
        },
        ]
    },
    {
        currentPage: 'controller-details', routePath: [{
            previousPage: 'controller-details', eventType: ['submit']
        },
        {
            previousPage: 'controller-type', eventType: ['submit', 'back']
        },
        {
            previousPage: 'controller-data', eventType: ['submit', 'back']
        },
        {
            previousPage: 'add-organisation-details', eventType: ['submit', 'back']
        },
        {
            previousPage: 'processing-data', eventType: ['submit', 'back']
        },
        {
            previousPage: 'security-assurance', eventType: ['submit', 'back']
        },
        {
            previousPage: 'dspt-details', eventType: ['submit', 'back']
        },
        {
            previousPage: 'iso', eventType: ['submit', 'back']
        },
        {
            previousPage: 'slsp', eventType: ['back']
        },
        {
            previousPage: 'data-protection-registration', eventType: ['submit', 'back']
        },
        {
            previousPage: 'pay-for-this-service', eventType: ['submit', 'back']
        },
        {
            previousPage: 'delete-controller', eventType: ['submit', 'back']
        },
        {
            previousPage: 'add-another-controller', eventType: ['submit']
        },
        {
            previousPage: 'completed-controller-section', eventType: ['back']
        },
        {
            previousPage: 'joint-sole-controller', eventType: ['submit', 'back']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'add-another-slsp', eventType: ['submit', 'back']
        }
        ]
    },
    //About the Processor
    {
        currentPage: 'solely-processor', routePath: [{
            previousPage: 'solely-processor', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'processor-data', eventType: ['back']
        },
        {
            previousPage: 'delete-processor', eventType: ['submit']
        }]
    },
    {
        currentPage: 'processor-data', routePath: [{
            previousPage: 'processor-data', eventType: ['']
        },
        {
            previousPage: 'solely-processor', eventType: ['submit']
        },
        {
            previousPage: 'processor-details', eventType: ['', 'submit']
        },
        {
            previousPage: 'add-processor-organisation', eventType: ['back']
        },
        {
            previousPage: 'processor-security-assurance', eventType: ['back']
        },
        {
            previousPage: 'delete-processor', eventType: ['submit']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        }]
    },
    {
        currentPage: 'add-processor-organisation', routePath: [{
            previousPage: 'add-processor-organisation', eventType: ['']
        },
        {
            previousPage: 'processor-details', eventType: ['']
        },
        {
            previousPage: 'processor-data', eventType: ['']
        },
        {
            previousPage: 'processor-security-assurance', eventType: ['back']
        }]
    },
    {
        currentPage: 'processor-security-assurance', routePath: [{
            previousPage: 'processor-security-assurance', eventType: ['']
        },
        {
            previousPage: 'add-processor-organisation', eventType: ['submit']
        },
        {
            previousPage: 'processor-data', eventType: ['submit']
        },
        {
            previousPage: 'processor-details', eventType: ['']
        },
        {
            previousPage: 'processor-dspt-details', eventType: ['back']
        },
        {
            previousPage: 'processor-iso', eventType: ['back']
        },
        {
            previousPage: 'processor-slsp', eventType: ['back']
        }]
    },
    {
        currentPage: 'processor-dspt-details', routePath: [{
            previousPage: 'processor-dspt-details', eventType: ['']
        },
        {
            previousPage: 'processor-security-assurance', eventType: ['submit']
        },
        {
            previousPage: 'processor-details', eventType: ['']
        },
        {
            previousPage: 'data-protection-registration-processor', eventType: ['back']
        }]
    },
    {
        currentPage: 'processor-iso', routePath: [{
            previousPage: 'processor-iso', eventType: ['']
        },
        {
            previousPage: 'processor-security-assurance', eventType: ['submit']
        },
        {
            previousPage: 'processor-details', eventType: ['']
        },
        {
            previousPage: 'data-protection-registration-processor', eventType: ['back']
        }]
    },
    {
        currentPage: 'processor-slsp', routePath: [{
            previousPage: 'processor-slsp', eventType: ['']
        },
        {
            previousPage: 'processor-security-assurance', eventType: ['submit']
        },
        {
            previousPage: 'processor-details', eventType: ['']
        },
        {
            previousPage: 'data-protection-registration-processor', eventType: ['back']
        },
        {
            previousPage: 'add-another-processorslsp', eventType: ['', 'back']
        }]
    },
    {
        currentPage: 'add-another-processorslsp', routePath: [{
            previousPage: 'add-another-processorslsp', eventType: ['']
        },
        {
            previousPage: 'processor-details', eventType: ['']
        },
        {
            previousPage: 'delete-document', eventType: ['submit', 'back']
        },
        {
            previousPage: 'data-protection-registration-processor', eventType: ['back']
        },
        {
            previousPage: 'processor-slsp', eventType: ['submit', 'back']
        },]
    },
    {
        currentPage: 'data-protection-registration-processor', routePath: [{
            previousPage: 'data-protection-registration-processor', eventType: ['']
        },
        {
            previousPage: 'processor-dspt-details', eventType: ['submit']
        },
        {
            previousPage: 'processor-slsp', eventType: ['submit']
        },
        {
            previousPage: 'processor-iso', eventType: ['submit']
        },
        {
            previousPage: 'processor-details', eventType: ['']
        },
        {
            previousPage: 'add-another-processorslsp', eventType: ['submit']
        }]
    },
    {
        currentPage: 'delete-processor', routePath: [{
            previousPage: 'delete-processor', eventType: ['']
        },
        {
            previousPage: 'processor-details', eventType: ['']
        }]
    },
    {
        currentPage: 'added-all-processors', routePath: [{
            previousPage: 'added-all-processors', eventType: ['']
        },
        {
            previousPage: 'processor-details', eventType: ['submit']
        }]
    },
    {
        currentPage: 'processor-details', routePath: [{
            previousPage: 'processor-details', eventType: ['']
        },
        {
            previousPage: 'processor-data', eventType: ['submit', 'back']
        },
        {
            previousPage: 'add-processor-organisation', eventType: ['submit', 'back']
        },
        {
            previousPage: 'processor-security-assurance', eventType: ['submit', 'back']
        },
        {
            previousPage: 'processor-dspt-details', eventType: ['submit', 'back']
        },
        {
            previousPage: 'processor-iso', eventType: ['submit', 'back']
        },
        {
            previousPage: 'processor-slsp', eventType: ['back']
        },
        {
            previousPage: 'data-protection-registration-processor', eventType: ['submit', 'back']
        },
        {
            previousPage: 'delete-processor', eventType: ['submit', 'back']
        },
        {
            previousPage: 'added-all-processors', eventType: ['back']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'add-another-processorslsp', eventType: ['submit', 'back']
        }]
    },
    //DSFC
    {
        currentPage: 'introduction', routePath: [{
            previousPage: 'introduction', eventType: ['']
        },
        {
            previousPage: 'details', eventType: ['back']
        },
        {
            previousPage: 'dashboard', eventType: ['']
        }], nextPath: '/dsfc/dashboard'
    },
    {
        currentPage: 'details', routePath: [{
            previousPage: 'details', eventType: ['']
        },
        {
            previousPage: 'introduction', eventType: ['submit']
        },
        {
            previousPage: 'signatory', eventType: ['back']
        },
        {
            previousPage: 'preview', eventType: ['', 'back']
        },
        {
            previousPage: 'dashboard', eventType: ['']
        }], nextPath: '/dsfc/dashboard'
    },
    {
        currentPage: 'signatory', routePath: [{
            previousPage: 'signatory', eventType: ['']
        },
        {
            previousPage: 'details', eventType: ['submit']
        },
        {
            previousPage: 'preview', eventType: ['', 'back']
        }], nextPath: '/dsfc/dashboard'
    },
    {
        currentPage: 'preview', routePath: [{
            previousPage: 'preview', eventType: ['']
        },
        {
            previousPage: 'details', eventType: ['submit']
        },
        {
            previousPage: 'signatory', eventType: ['submit']
        }], nextPath: '/dsfc/dashboard'
    },
    {
        currentPage: 'submit-contract', routePath: [{
            previousPage: 'submit-contract', eventType: ['']
        },
        {
            previousPage: 'preview', eventType: ['submit']
        }], nextPath: '/dsfc/dashboard'
    },
    //privacy notice
    {
        currentPage: 'privacy-notice', routePath: [{
            previousPage: 'privacy-notice', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'no-privacy-notice', eventType: ['back']
        }]
    },
    {
        currentPage: 'no-privacy-notice', routePath: [{
            previousPage: 'no-privacy-notice', eventType: ['']
        },
        {
            previousPage: 'privacy-notice', eventType: ['submit']
        }]
    },
    //Processing & Storage Locations
    {
        currentPage: 'select-locations', routePath: [{
            previousPage: 'select-locations', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        }]
    },
    //Funding
    {
        currentPage: 'funding-type', routePath: [{
            previousPage: 'funding-type', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'funding-document', eventType: ['back']
        },
        {
            previousPage: 'uploaded-document-details', eventType: ['']
        },
        {
            previousPage: 'delete-funding', eventType: ['submit']
        }
        ]
    },
    {
        currentPage: 'funding-document', routePath: [{
            previousPage: 'funding-document', eventType: ['']
        },
        {
            previousPage: 'funding-type', eventType: ['submit']
        },
        {
            previousPage: 'funding-information', eventType: ['back']
        },
        {
            previousPage: 'add-another-funding', eventType: ['']
        },
        {
            previousPage: 'delete-document', eventType: ['submit', 'back']
        },
        {
            previousPage: 'add-another-dataset-record', eventType: ['back']
        },
        {
            previousPage: 'add-another-dataset-record', eventType: ['submit', '']
        },
        ]
    },
    {
        currentPage: 'funding-information', routePath: [{
            previousPage: 'funding-information', eventType: ['']
        },
        {
            previousPage: 'funding-document', eventType: ['submit']
        },
        {
            previousPage: 'add-another-funding', eventType: ['submit']
        }],
    },
    {
        currentPage: 'uploaded-document-details', routePath: [{
            previousPage: 'uploaded-document-details', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'funding-type', eventType: ['back']
        },
        {
            previousPage: 'funding-information', eventType: ['submit']
        },
        {
            previousPage: 'delete-funding', eventType: ['submit', 'back']
        },
        ]
    },
    {
        currentPage: 'delete-funding', routePath: [{
            previousPage: 'delete-funding', eventType: ['']
        },
        {
            previousPage: 'uploaded-document-details', eventType: ['']
        }]
    },
    //Data request reason and project name
    {
        currentPage: 'data-request-reason', routePath: [{
            previousPage: 'data-request-reason', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'data-request-project', eventType: ['back']
        }]
    },
    {
        currentPage: 'data-request-project', routePath: [{
            previousPage: 'data-request-project', eventType: ['']
        },
        {
            previousPage: 'data-request-reason', eventType: ['submit']
        },
        {
            previousPage: 'proposed-dsa-term', eventType: ['back']
        },
        {
            previousPage: 'proposed-date', eventType: ['back']
        }]
    },
    {
        currentPage: 'proposed-dsa-term', routePath: [{
            previousPage: 'proposed-dsa-term', eventType: ['']
        },
        {
            previousPage: 'data-request-project', eventType: ['submit']
        },
        {
            previousPage: 'proposed-date', eventType: ['back']
        }]
    },
    {
        currentPage: 'proposed-date', routePath: [{
            previousPage: 'proposed-date', eventType: ['']
        },
        {
            previousPage: 'proposed-dsa-term', eventType: ['submit']
        },
        {
            previousPage: 'data-request-project', eventType: ['submit']
        },
        ]
    },
    //Objective(s) and processing activities
    {
        currentPage: 'objective-processing', routePath: [{
            previousPage: 'objective-processing', eventType: ['']
        },
        {
            previousPage: 'processing-activities', eventType: ['back']
        },
        {
            previousPage: 'objective-processing-check-answer', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        }]
    },
    {
        currentPage: 'processing-activities', routePath: [{
            previousPage: 'processing-activities', eventType: ['']
        },
        {
            previousPage: 'objective-processing', eventType: ['submit']
        },
        {
            previousPage: 'objective-processing-check-answer', eventType: ['']
        }]
    },
    {
        currentPage: 'objective-processing-check-answer', routePath: [{
            previousPage: 'objective-processing-check-answer', eventType: ['']
        },
        {
            previousPage: 'objective-processing', eventType: ['submit', 'back']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'processing-activities', eventType: ['submit', 'back']
        },
        {
            previousPage: 'completed-objective-processing', eventType: ['back']
        }]
    },
    {
        currentPage: 'completed-objective-processing', routePath: [{
            previousPage: 'completed-objective-processing', eventType: ['']
        },
        {
            previousPage: 'objective-processing-check-answer', eventType: ['submit']
        }]
    },
    //Expected outputs and benefits
    {
        currentPage: 'expected-outputs', routePath: [{
            previousPage: 'expected-outputs', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'expected-benefits', eventType: ['back']
        },
        {
            previousPage: 'expected-check-your-answers', eventType: ['']
        }]
    },
    {
        currentPage: 'expected-benefits', routePath: [{
            previousPage: 'expected-benefits', eventType: ['']
        },
        {
            previousPage: 'expected-outputs', eventType: ['submit', 'back']
        },
        {
            previousPage: 'expected-check-your-answers', eventType: ['']
        }]
    },
    {
        currentPage: 'expected-check-your-answers', routePath: [{
            previousPage: 'expected-check-your-answers', eventType: ['']
        },
        {
            previousPage: 'expected-benefits', eventType: ['submit', 'back']
        },
        {
            previousPage: 'expected-outputs', eventType: ['submit', 'back']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'completed-expected-section', eventType: ['back']
        }]
    },
    {
        currentPage: 'completed-expected-section', routePath: [{
            previousPage: 'completed-expected-section', eventType: ['']
        },
        {
            previousPage: 'expected-check-your-answers', eventType: ['submit']
        }]
    },
    //Commercial purposes and sub-licensing
    {
        currentPage: 'commercial', routePath: [{
            previousPage: 'commercial', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'commercial-purpose', eventType: ['back']
        },
        {
            previousPage: 'sublicensing', eventType: ['back']
        },
        {
            previousPage: 'summary-commercial', eventType: ['']
        }]
    },
    {
        currentPage: 'commercial-purpose', routePath: [{
            previousPage: 'commercial-purpose', eventType: ['']
        },
        {
            previousPage: 'commercial', eventType: ['submit']
        },
        {
            previousPage: 'sublicensing', eventType: ['back']
        },
        {
            previousPage: 'summary-commercial', eventType: ['']
        }]
    },
    {
        currentPage: 'sublicensing', routePath: [{
            previousPage: 'sublicensing', eventType: ['']
        },
        {
            previousPage: 'commercial-purpose', eventType: ['submit']
        },
        {
            previousPage: 'commercial', eventType: ['submit']
        },
        {
            previousPage: 'sublicensing-duration', eventType: ['back']
        },
        {
            previousPage: 'summary-commercial', eventType: ['']
        }]
    },
    {
        currentPage: 'sublicensing-duration', routePath: [{
            previousPage: 'sublicensing-duration', eventType: ['']
        },
        {
            previousPage: 'sublicensing', eventType: ['submit']
        },
        {
            previousPage: 'summary-commercial', eventType: ['']
        }]
    },
    {
        currentPage: 'summary-commercial', routePath: [{
            previousPage: 'summary-commercial', eventType: ['']
        },
        {
            previousPage: 'commercial', eventType: ['submit', 'back']
        },
        {
            previousPage: 'commercial-purpose', eventType: ['submit', 'back']
        },
        {
            previousPage: 'sublicensing', eventType: ['submit', 'back']
        },
        {
            previousPage: 'sublicensing-duration', eventType: ['submit', 'back']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'completed-commercial-section', eventType: ['back']
        }]
    },
    {
        currentPage: 'completed-commercial-section', routePath: [{
            previousPage: 'completed-commercial-section', eventType: ['']
        },
        {
            previousPage: 'summary-commercial', eventType: ['submit']
        }]
    },
    //Ethics approval
    {
        currentPage: 'evidence-approval', routePath: [{
            previousPage: 'evidence-approval', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'no-ethics-approval', eventType: ['back']
        },
        {
            previousPage: 'ethics-approval', eventType: ['back']
        }]
    },
    {
        currentPage: 'no-ethics-approval', routePath: [{
            previousPage: 'no-ethics-approval', eventType: ['']
        },
        {
            previousPage: 'evidence-approval', eventType: ['submit']
        }]
    },
    {
        currentPage: 'ethics-approval', routePath: [{
            previousPage: 'ethics-approval', eventType: ['']
        },
        {
            previousPage: 'evidence-approval', eventType: ['submit']
        },
        {
            previousPage: 'ethics-approval-not-in-place', eventType: ['back']
        },
        {
            previousPage: 'upload-consent', eventType: ['submit', 'back', '']
        },
        {
            previousPage: 'add-another-ethics-review', eventType: ['back', '']
        }
        ]
    },
    {
        currentPage: 'ethics-approval-not-in-place', routePath: [{
            previousPage: 'ethics-approval-not-in-place', eventType: ['']
        },
        {
            previousPage: 'ethics-approval', eventType: ['submit']
        }]
    },
    //List of uploaded documents
    {
        currentPage: 'uploaded-files', routePath: [{
            previousPage: 'uploaded-files', eventType: ['']
        },
        {
            previousPage: 'upload-consent', eventType: ['submit']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'upload-nhs-health', eventType: ['submit']
        },
        {
            previousPage: 'participant-summary', eventType: ['back']
        },
        {
            previousPage: 'delete-document', eventType: ['submit', 'back']
        },
        {
            previousPage: 'add-cohort-contributor', eventType: ['submit']
        },
        {
            previousPage: 'legal-bases-identifiable-data', eventType: ['back']
        },
        {
            previousPage: 'added-all-dataset', eventType: ['back']
        },
        {
            previousPage: 'legal-basis', eventType: ['', 'submit']
        },
        {
            previousPage: 'evidence-type', eventType: ['back']
        }]
    },
    {
        currentPage: 'upload-supporting-evidence', routePath: [{
            previousPage: 'upload-supporting-evidence', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'evidence-type', eventType: ['back']
        }]
    },
    {
        currentPage: 'evidence-type', routePath: [{
            previousPage: 'evidence-type', eventType: ['']
        },
        {
            previousPage: 'upload-supporting-evidence', eventType: ['submit', 'back']
        },
        {
            previousPage: 'upload-consent', eventType: ['back']
        },
        {
            previousPage: 'uploaded-files', eventType: ['back', '']
        },
        {
            previousPage: 'delete-document', eventType: ['submit']
        },
        {
            previousPage: 'add-another-approval-evidence', eventType: ['submit', '']
        },
        {
            previousPage: 'add-another-approval-evidence', eventType: ['back']
        }]
    },
    {
        currentPage: 'upload-consent', routePath: [{
            previousPage: 'upload-consent', eventType: ['']
        },
        {
            previousPage: 'legal-basis', eventType: ['submit']
        },
        {
            previousPage: 'uploaded-files', eventType: ['back']
        },
        {
            previousPage: 'add-another-dataset-record', eventType: ['back']
        },
        {
            previousPage: 'add-another-dataset-record', eventType: ['submit', '']
        },
        {
            previousPage: 'add-another-ethics-review', eventType: ['submit', 'back','']
        },
        {
            previousPage: 'evidence-type', eventType: ['submit']
        },
        {
            previousPage: 'legal-bases-identifiable-data', eventType: ['submit', '']
        },
        {
            previousPage: 'upload-nhs-health', eventType: ['back']
        },
        {
            previousPage: 'approval-evidence-data', eventType: ['submit', 'back', '']
        },
        {
            previousPage: 'delete-document', eventType: ['submit', '', 'back']
        },
        {
            previousPage: 'ethics-approval', eventType: ['submit']
        }]
    },
    {
        currentPage: 'delete-document', routePath: [{
            previousPage: 'delete-document', eventType: ['']
        },
        {
            previousPage: 'uploaded-files', eventType: ['back', '']
        },
        {
            previousPage: 'add-another-slsp', eventType: ['']
        },
        {
            previousPage: 'add-another-dataset-record', eventType: ['']
        },
        {
            previousPage: 'add-another-processorslsp', eventType: ['']
        },
        {
            previousPage: 'add-another-ethics-review', eventType: ['']
        },
        {
            previousPage: 'add-another-funding', eventType: ['']
        },
        {
            previousPage: 'add-another-approval-evidence', eventType: ['']
        }]
    },
    ////submit application
    {
        currentPage: 'declaration', routePath: [{
            previousPage: 'declaration', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        }]
    },
    {
        currentPage: 'submit', routePath: [{
            previousPage: 'submit', eventType: ['']
        },
        {
            previousPage: 'declaration', eventType: ['submit']
        }]
    },
    // Participant
    {
        currentPage: 'participant-data', routePath: [{
            previousPage: 'participant-data', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'participant-types', eventType: ['back']
        },
        {
            previousPage: 'participant-summary', eventType: ['']
        },
        {
            previousPage: 'relevant-participant-data', eventType: ['back']
        }
        ]
    },
    {
        currentPage: 'no-existing-agreement', routePath: [{
            previousPage: 'no-existing-agreement', eventType: ['']
        },
        {
            previousPage: 'participant-types', eventType: ['submit', '']
        },
        {
            previousPage: 'linking-participant', eventType: ['back']
        },
        {
            previousPage: 'participant-data', eventType: ['submit']
        },
        {
            previousPage: 'participant-data', eventType: ['submit']
        }]
    },
    {
        currentPage: 'participant-types', routePath: [{
            previousPage: 'participant-types', eventType: ['']
        },
        {
            previousPage: 'participant-data', eventType: ['submit']
        },
        {
            previousPage: 'no-existing-agreement', eventType: ['back']
        },
        {
            previousPage: 'linking-participant', eventType: ['back']
        },
        {
            previousPage: 'participant-summary', eventType: ['']
        },
        {
            previousPage: 'existing-type', eventType: ['back']
        },
        {
            previousPage: 'legal-basis', eventType: ['back']
        }]
    },
    {
        currentPage: 'linking-participant', routePath: [{
            previousPage: 'linking-participant', eventType: ['']
        },
        {
            previousPage: 'add-cohort-contributor', eventType: ['back']
        },
        {
            previousPage: 'participant-types', eventType: ['submit']
        },
        {
            previousPage: 'no-existing-agreement', eventType: ['submit']
        },
        {
            previousPage: 'who-will-provide', eventType: ['back']
        },
        {
            previousPage: 'participant-summary', eventType: ['', 'back']
        },
        {
            previousPage: 'existing-type', eventType: ['submit']
        },
        {
            previousPage: 'delete-participant', eventType: ['submit', 'back']
        },
        {
            previousPage: 'add-another-dataset-record', eventType: ['submit']
        },
        {
            previousPage: 'legal-basis', eventType: ['back', 'submit']
        }]
    },
    {
        currentPage: 'who-will-provide', routePath: [
            {
                previousPage: 'who-will-provide', eventType: ['']
            },
            {
                previousPage: 'linking-participant', eventType: ['submit']
            },
            {
                previousPage: 'add-cohort-contributor', eventType: ['back', '']
            },
            {
                previousPage: 'delete-participant', eventType: ['submit']
            }
        ]
    },
    {
        currentPage: 'existing-type', routePath: [{
            previousPage: 'existing-type', eventType: ['']
        },
        {
            previousPage: 'linking-participant', eventType: ['back']
        },
        {
            previousPage: 'participant-types', eventType: ['submit']
        },
        {
            previousPage: 'participant-summary', eventType: ['']
        },
        {
            previousPage: 'legal-basis', eventType: ['back']
        }]
    },
    {
        currentPage: 'add-cohort-contributor', routePath: [{
            previousPage: 'add-cohort-contributor', eventType: ['']
        },
        {
            previousPage: 'linking-participant', eventType: ['back', 'submit']
        },
        {
            previousPage: 'who-will-provide', eventType: ['back', 'submit']
        },
        {
            previousPage: 'legal-basis', eventType: ['back']
        },
        {
            previousPage: 'participant-summary', eventType: ['']
        },
        {
            previousPage: 'delete-participant', eventType: ['submit', 'back']
        },
        {
            previousPage: 'uploaded-files', eventType: ['back']
        }]
    },
    {
        currentPage: 'legal-basis', routePath: [{
            previousPage: 'legal-basis', eventType: ['']
        },
        {
            previousPage: 'add-cohort-contributor', eventType: ['submit']
        },
        {
            previousPage: 'uploaded-files', eventType: ['', 'back']
        },
        {
            previousPage: 'upload-nhs-health', eventType: ['back']
        },
        {
            previousPage: 'upload-consent', eventType: ['back']
        },
        {
            previousPage: 'delete-participant', eventType: ['']
        },
        {
            previousPage: 'participant-summary', eventType: ['']
        },
        {
            previousPage: 'approval-evidence-data', eventType: ['back']
        },
        {
            previousPage: 'participant-types', eventType: ['submit']
        },
        {
            previousPage: 'existing-type', eventType: ['submit']
        },
        {
            previousPage: 'no-existing-agreement', eventType: ['submit']
        },
        {
            previousPage: 'linking-participant', eventType: ['submit', 'back']
        }
        ]
    },
    {
        currentPage: 'upload-nhs-health', routePath: [{
            previousPage: 'upload-nhs-health', eventType: ['']
        },
        {
            previousPage: 'legal-basis', eventType: ['submit']
        },
        {
            previousPage: 'uploaded-files', eventType: ['']
        },
        {
            previousPage: 'upload-consent', eventType: ['submit']
        }
        ]
    },
    {
        currentPage: 'participant-summary', routePath: [{
            previousPage: 'participant-summary', eventType: ['']
        },
        {
            previousPage: 'relevant-participant-data', eventType: ['back']
        },
        {
            previousPage: 'uploaded-files', eventType: ['submit']
        },
        {
            previousPage: 'participant-data', eventType: ['submit', 'back']
        },
        {
            previousPage: 'no-existing-agreement', eventType: ['submit', 'back']
        },
        {
            previousPage: 'participant-types', eventType: ['submit', 'back']
        },
        {
            previousPage: 'linking-participant', eventType: ['submit', 'back']
        },
        {
            previousPage: 'add-cohort-contributor', eventType: ['submit', 'back']
        },
        {
            previousPage: 'legal-basis', eventType: ['submit', 'back']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'approval-evidence-data', eventType: ['submit', '']
        },
        {
            previousPage: 'add-another-dataset-record', eventType: ['submit', '', 'back']
        },
        {
            previousPage: 'existing-type', eventType: ['submit', 'back']
        }],
    },
    {
        currentPage: 'relevant-participant-data', routePath: [{
            previousPage: 'relevant-participant-data', eventType: ['']
        },
        {
            previousPage: 'participant-data', eventType: ['submit']
        },
        {
            previousPage: 'participant-summary', eventType: ['submit']
        }]
    },
    {
        currentPage: 'delete-participant', routePath: [{
            previousPage: 'delete-participant', eventType: ['']
        },
        {
            previousPage: 'add-cohort-contributor', eventType: ['']
        }]
    },
    //DataSet
    {
        currentPage: 'dataset-introduction', routePath: [{
            previousPage: 'dataset-introduction', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'select-dataset', eventType: ['back']
        }]
    },
    {
        currentPage: 'select-dataset', routePath: [{
            previousPage: 'select-dataset', eventType: ['']
        },
        {
            previousPage: 'dataset-introduction', eventType: ['']
        },
        {
            previousPage: 'aggregated-record', eventType: ['back']
        },
        {
            previousPage: 'dataset-details', eventType: ['']
        },
        {
            previousPage: 'delete-dataset', eventType: ['submit']
        }]
    },
    {
        currentPage: 'aggregated-record', routePath: [{
            previousPage: 'aggregated-record', eventType: ['']
        },
        {
            previousPage: 'select-dataset', eventType: ['submit']
        },
        {
            previousPage: 'data-duration', eventType: ['back']
        },
        {
            previousPage: 'dataset-check-your-answer', eventType: ['']
        }]
    },
    {
        currentPage: 'data-duration', routePath: [{
            previousPage: 'data-duration', eventType: ['']
        },
        {
            previousPage: 'aggregated-record', eventType: ['submit']
        },
        {
            previousPage: 'dataset-frequency', eventType: ['back']
        },
        {
            previousPage: 'historic-annual-data', eventType: ['back']
        },
        {
            previousPage: 'select-dataset-field', eventType: ['back']
        },
        {
            previousPage: 'dataset-check-your-answer', eventType: ['submit']
        }, {
            previousPage: 'dataset-check-your-answer', eventType: ['']
        }]
    },
    {
        currentPage: 'dataset-frequency', routePath: [{
            previousPage: 'dataset-frequency', eventType: ['']
        },
        {
            previousPage: 'data-duration', eventType: ['submit']
        },
        {
            previousPage: 'select-dataset-field', eventType: ['back']
        },
        {
            previousPage: 'historic-annual-data', eventType: ['back']
        }]
    },
    {
        currentPage: 'historic-annual-data', routePath: [{
            previousPage: 'historic-annual-data', eventType: ['']
        },
        {
            previousPage: 'data-duration', eventType: ['submit']
        },
        {
            previousPage: 'select-dataset-field', eventType: ['back']
        },
        {
            previousPage: 'dataset-frequency', eventType: ['submit']
        }]
    },
    {
        currentPage: 'select-dataset-field', routePath: [{
            previousPage: 'select-dataset-field', eventType: ['']
        },
        {
            previousPage: 'dataset-frequency', eventType: ['submit']
        },
        {
            previousPage: 'data-minimisation', eventType: ['back']
        },
        {
            previousPage: 'historic-annual-data', eventType: ['submit']
        },
        {
            previousPage: 'data-duration', eventType: ['submit']
        }]
    },
    {
        currentPage: 'data-minimisation', routePath: [{
            previousPage: 'data-minimisation', eventType: ['']
        },
        {
            previousPage: 'select-dataset-field', eventType: ['submit']
        },
        {
            previousPage: 'legal-bases', eventType: ['back']
        }]
    },
    {
        currentPage: 'legal-bases', routePath: [{
            previousPage: 'legal-bases', eventType: ['']
        },
        {
            previousPage: 'data-minimisation', eventType: ['submit']
        },
        {
            previousPage: 'cohort', eventType: ['back']
        }]
    },
    {
        currentPage: 'cohort', routePath: [{
            previousPage: 'cohort', eventType: ['']
        },
        {
            previousPage: 'legal-bases', eventType: ['submit']
        }]
    },

    {
        currentPage: 'dataset-details', routePath: [{
            previousPage: 'dataset-details', eventType: ['']
        },
        {
            previousPage: 'cohort', eventType: ['submit']
        },
        {
            previousPage: 'delete-dataset', eventType: ['back', 'submit']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'dataset-check-your-answer', eventType: ['back']
        },
        {
            previousPage: 'identifiable-data', eventType: ['back']
        },
        {
            previousPage: 'select-dataset', eventType: ['back']
        },
        {
            previousPage: 'historic-annual-data', eventType: ['submit']
        },
        {
            previousPage: 'dataset-frequency', eventType: ['']
        },
        {
            previousPage: 'data-duration', eventType: ['submit']
        },
        {
            previousPage: 'added-all-dataset', eventType: ['back']
        },
        {
            previousPage: 'dataset-check-your-answer', eventType: ['']
        }]
    },
    {
        currentPage: 'dataset-check-your-answer', routePath: [{
            previousPage: 'dataset-check-your-answer', eventType: ['']
        },
        {
            previousPage: 'dataset-details', eventType: ['']
        },
        {
            previousPage: 'data-duration', eventType: ['submit', 'back']
        },
        {
            previousPage: 'aggregated-record', eventType: ['back', '']
        }
        ]
    },

    {
        currentPage: 'delete-dataset', routePath: [{
            previousPage: 'delete-dataset', eventType: ['']
        },
        {
            previousPage: 'dataset-details', eventType: ['']
        },
        {
            previousPage: 'select-dataset', eventType: ['']
        }]
    },

    {
        currentPage: 'identifiable-data', routePath: [{
            previousPage: 'identifiable-data', eventType: ['']
        },
        {
            previousPage: 'dataset-details', eventType: ['submit']
        },
        {
            previousPage: 'legal-bases-identifiable-data', eventType: ['back']
        },
        {
            previousPage: 'added-all-dataset', eventType: ['back']
        }]
    },
    {
        currentPage: 'legal-bases-identifiable-data', routePath: [{
            previousPage: 'legal-bases-identifiable-data', eventType: ['']
        },
        {
            previousPage: 'identifiable-data', eventType: ['submit']
        },
        {
            previousPage: 'upload-consent', eventType: ['back', '']
        },
        {
            previousPage: 'uploaded-files', eventType: ['back', '']
        },
        {
            previousPage: 'approval-evidence-data', eventType: ['back']
        },
        ]
    },
    {
        currentPage: 'added-all-dataset', routePath: [{
            previousPage: 'added-all-dataset', eventType: ['']
        },
        {
            previousPage: 'identifiable-data', eventType: ['submit']
        },
        {
            previousPage: 'uploaded-files', eventType: ['submit']
        },
        {
            previousPage: 'approval-evidence-data', eventType: ['submit']
        },
        {
            previousPage: 'add-another-dataset-record', eventType: ['submit']
        },
        {
            previousPage: 'dataset-details', eventType: ['submit']
        }]
    },

    //Choose section for Amend
    {
        currentPage: 'choose-section', routePath: [{
            previousPage: 'choose-section', eventType: ['']
        },
        {
            previousPage: 'apply-dsa', eventType: ['']
        },
        {
            previousPage: 'manage-dsa', eventType: ['submit']
        },
        {
            previousPage: 'amend-before-you-start', eventType: ['back', 'submit']
        },
        {
            previousPage: 'task-list', eventType: ['back']
        }
        ]
    },
    {
        currentPage: 'amend-before-you-start', routePath: [{
            previousPage: 'amend-before-you-start', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['back']
        },
        {
            previousPage: 'active-dsa', eventType: ['']
        },
        {
            previousPage: 'choose-section', eventType: ['back']
        },
        {
            previousPage: 'manage-dsa', eventType: ['submit', '']
        },
        {
            previousPage: 'apply-dsa', eventType: ['']
        }]
    },
    {
        currentPage: 'manage-dsa', routePath: [{
            previousPage: 'manage-dsa', eventType: ['']
        },
        {
            previousPage: 'active-dsa', eventType: ['']
        },
        {
            previousPage: 'choose-section', eventType: ['back']
        },
        {
            previousPage: 'manage-before-you-start', eventType: ['back']
        },
        {
            previousPage: 'amend-before-you-start', eventType: ['back']
        }
        ]
    },
    {
        currentPage: 'manage-before-you-start', routePath: [{
            previousPage: 'manage-before-you-start', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['back']
        },
        {
            previousPage: 'active-dsa', eventType: ['']
        },
        {
            previousPage: 'manage-dsa', eventType: ['submit', '']
        },
        {
            previousPage: 'task-list', eventType: ['back']
        }]
    },

    {
        currentPage: 'approval-evidence-data', routePath: [{
            previousPage: 'approval-evidence-data', eventType: ['']
        },
        {
            previousPage: 'add-another-dataset-record', eventType: ['back']
        },
        {
            previousPage: 'add-another-dataset-record', eventType: ['submit', '']
        },
        {
            previousPage: 'legal-bases-identifiable-data', eventType: ['submit']
        },
        {
            previousPage: 'upload-consent', eventType: ['back']
        },
        {
            previousPage: 'legal-basis', eventType: ['submit']
        }
        ]
    },
    {
        currentPage: 'add-another-dataset-record', routePath: [{
            previousPage: 'add-another-dataset-record', eventType: ['']
        },
        {
            previousPage: 'approval-evidence-data', eventType: ['back', 'submit']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'renew_extend-task-list', eventType: ['']
        },
        {
            previousPage: 'amend-task-list', eventType: ['']
        },
        {
            previousPage: 'upload-consent', eventType: ['submit', 'back']
        },
        {
            previousPage: 'delete-document', eventType: ['submit', 'back']
        },
        {
            previousPage: 'legal-bases-identifiable-data', eventType: ['submit']
        },
        {
            previousPage: 'added-all-dataset', eventType: ['back', '']
        },
        {
            previousPage: 'linking-participant', eventType: ['back']
        },
        {
            previousPage: 'participant-summary', eventType: ['']
        }
        ]
    }
    ,
    {
        currentPage: 'add-another-approval-evidence', routePath: [{
            previousPage: 'add-another-approval-evidence', eventType: ['']
        },
        {
            previousPage: 'task-list', eventType: ['']
        },
        {
            previousPage: 'evidence-type', eventType: ['back', '']
        },
        {
            previousPage: 'upload-consent', eventType: ['submit', '']
        },
        {
            previousPage: 'delete-document', eventType: ['submit', '']
        }
        ]
    }
];