import { Button, InsetText, Textarea } from "nhsuk-react-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitScreenCommercialPurposeAndSublicensing_URL } from "../../../../config/api-endpoints.config";
import { dsaContent, fileTypeSize, purposeErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IPurpose } from "../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getPurposeDetails, setPurposeDetailsData } from "../../../../state/slice/Purpose/Purpose.slice";

const SublicensingDuration: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const [purposeData, setPurposeData] = useState<IPurpose>(useAppSelector(getPurposeDetails) || {});
    const savePurposeDataInStore = (stateData?: IPurpose) => {
        dispatch(setPurposeDetailsData({ ...purposeData, ...stateData }));
    }
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const [sublicensingDurationErrorMessage, setSublicensingDurationErrorMessage] = useState("");

    /* Function to set the state value on change of Radios(yes/no) */
    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IPurpose = {};
        updatedState = {
            ...purposeData,
            [e.target.name]: e.target.value,
            isPurposeUpdated: true
        }
        setPurposeData(updatedState);
        savePurposeDataInStore(updatedState);
        resetError(e);
    }
    const resetError = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== "") {
            setSublicensingDurationErrorMessage("");
        }
    };
    /* Function to set the state value on change of Radios(yes/no) */

    /* To handle Onclick */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, submitSublicensingDuration());
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    /* To Validate the each field's data */
    const ValidateFields = () => {
        let isValid: boolean = true;
        if (purposeData.duration === null || purposeData.duration === undefined || purposeData.duration.trim() === "") {
            setSublicensingDurationErrorMessage(`${purposeErrorMessage.enterSublicensingDuration_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const submitSublicensingDuration = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "ApplicationForCommercialPurposes": purposeData.applicationForCommercialPurposes === dsaContent.yes ? true : false,
            "ApplicationForCommercialPurposesExplanation": purposeData.applicationForCommercialPurposesExplanation,
            "DoesSublicensingApply": purposeData.doesSublicensingApply === dsaContent.yes ? true : false,
            "Duration": purposeData.duration,
            "SectionStatus": dsaApplicationData.commercialSublicensingSectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitScreenCommercialPurposeAndSublicensing_URL, requestOptions)
            .then(() => {
                    let sectionStatus: IApplication = {
                        ...dsaApplicationData, commercialSublicensingSectionStatus: taskListSectionStatus.inProgress
                    }
                    setDsaApplicationData(sectionStatus);
                    saveApplicationDataInStore(sectionStatus);
                    navigate(routeName.summaryCommercial);
               
            })
            .catch(() => navigate(routeName.serviceError));
    }

    /* To handle Onclick */


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                Commercial purposes and sub-licensing
                            </span>
                            <h1>How long do you intend to share data with an organisation(s) not mentioned in the Data Sharing Agreement?</h1>
                            {sublicensingDurationErrorMessage !== "" && <ErrorDetails errorDescription={sublicensingDurationErrorMessage} errorHref="#duration"></ErrorDetails>}
                            <InsetText><p>For example, it might be that you intend to share data for the length of the Data Sharing Agreement (DSA), or you might only intend to share it for a specific amount of time.</p></InsetText>
                            <Textarea
                                label="Tell us how long you intend to share the data"
                                name="duration"
                                id="duration"
                                onChange={handleChangeTextarea}
                                maxLength={2000}
                                rows={5}
                                error={sublicensingDurationErrorMessage}
                                defaultValue={purposeData.duration}
                            />
                            <div className="nhsuk-u-padding-bottom-8"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default SublicensingDuration