import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Radios, Hint } from "nhsuk-react-components";
import { controllerSection, dsaContent, dsaControllerErrorMessage, fileTypeSize, initialStateOfFileUpload, routeName, sectionOptions, taskListSectionStatus } from "../../../../config/page.config";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader'
import ErrorDetails from '../../../../Components/Shared/ErrorSummary';
import { useAppSelector } from "../../../../state/app/hooks";
import { getProcessorDetails, setProcessorDetails } from '../../../../state/slice/Processor/Processor.slice';
import { getProcessorListDetails, setProcessorListDetailsData } from "../../../../state/slice/ProcessorList/ProcessorList.slice";
import { IProcessor, IProcessorList, ISecurityAssurance, IApplication, IControllerList } from "../../../../state/models/app.interface";
import { fetchInterceptor, getCookie, resetSecurityAssuranceState, submitDSASectionStatus, submitSpinner } from '../../../../Helper/Utility';
import { DeleteDSAChildRecord_URL } from "../../../../config/api-endpoints.config";
import { useAppDispatch } from '../../../../state/app/hooks';
import { AppDispatch } from '../../../../state/app/store';
import Loader from "../../../../Components/Loader/Loader";
import { getSecurityAssuranceDetails, setSecurityAssuranceDetailsData } from "../../../../state/slice/SecurityAssurance/SecurityAssurance.slice";
import GoBack from "../../../../Components/Shared/GoBack";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getControllerListDetails } from "../../../../state/slice/ControllerList/ControllerList.slice";

/* To delete the details of the data processor information they have added to the application */

const DeleteProcessor: React.FC = () => {
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [deleteValue, setDeleteValue] = useState('');
    const ProcessorListDetailsData: IProcessorList = useAppSelector(getProcessorListDetails);
    const initialProcessorListState: IProcessorList = ProcessorListDetailsData || {};
    const [processorListData,] = useState(initialProcessorListState);
    const processorDetailsData: IProcessor = useAppSelector(getProcessorDetails);
    const initialState: IProcessor = processorDetailsData || {};
    const [processorData,] = useState(initialState);
    const [deleteProcessorErrorMessage, setDeleteProcessorErrorMessage] = useState("");
    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};

    const initialControllerListState: IControllerList = useAppSelector(getControllerListDetails) || {};
    const [controllerListData,] = useState(initialControllerListState);

    const [controllerIsProccessorCount,] = useState((controllerListData.controllerList?.filter(controller => controller.isControllerProcessingData === true))?.length || 0);

    /* To dispatch the setProcessorDetails Redux action */
    const saveProcessorDataInStore = (stateData?: IProcessor) => {
        dispatch(setProcessorDetails({ ...processorData, ...stateData }));
    }

    /* To dispatch the setProcessorListDetailsData Redux action */
    const saveProcessorListDataInStore = (stateData?: IProcessorList) => {
        dispatch(setProcessorListDetailsData({ ...processorListData, ...stateData }));
    }

    /* To reset the processor data which is deleted and update store */
    const securityAssuranceDetailsData: ISecurityAssurance = useAppSelector(getSecurityAssuranceDetails);
    const initiailSecurityAssuranceState: ISecurityAssurance = securityAssuranceDetailsData || {};
    const [securityAssuranceData, setSecurityAssuranceData] = useState(initiailSecurityAssuranceState);
    const saveSecurityAssuranceDataInStore = (stateData?: ISecurityAssurance) => {
        dispatch(setSecurityAssuranceDetailsData({ ...securityAssuranceData, ...stateData }));
    }

    const resetProcessorData = () => {
        let updatedStateSA = resetSecurityAssuranceState();
        setSecurityAssuranceData(updatedStateSA);
        saveSecurityAssuranceDataInStore(updatedStateSA);
        let updatedState: IProcessor = {};
        updatedState = {
            processorId: "",
            isDirtyProcessor: false,
            aboutProcessorSectionStatus: "",
            dpaRegistrationNumber: "",
            dpaOrganisationName: "",
            dpaRegistrationExpiryDate: "",
            isProcessorProcessingData: false,
            dpDay: "",
            dpMonth: "",
            dpYear: "",
            fileUpload: [initialStateOfFileUpload],
            dsaApplicationId: "",
            contractNumber: "",
            alternativeOrganisationName: "",
            alternativeStreet1: "",
            alternativeStreet2: "",
            alternativeStreet3: "",
            alternativeCity: "",
            alternativeCounty: "",
            alternativePostCode: "",
            alternativeCountry: "",
            processordataOrgGuidId: "",
            processordataOrgDesc: "",
            processorIndex: 0,
            dpaId: "",
            isNewProcessor: true,
            processorProcessingData: "",
            saList: {}
        }
        saveProcessorDataInStore(updatedState);
    }

    /* Remove the deleted processor from processorListData and update store*/
    const resetProcessorList = () => {
        let filteredProcessorList: IProcessor[];
        const processorList = processorListData.processorList ? processorListData.processorList : [];
        filteredProcessorList = processorList.filter((processor: IProcessor) => processor.processorId !== processorData.processorId)
        saveProcessorListDataInStore({ ...processorListData, processorList: (filteredProcessorList as [IProcessor]) });
    }


    /* To handle API call with the deleted processor details and navigate to processordata */
    const handleDelete = (processorId: string) => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "Id": processorId,
            "SectionId": sectionOptions.DataProcessor
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(DeleteDSAChildRecord_URL, requestOptions)
            .then(() => {
                resetProcessorList();
                resetProcessorData();
                updateSectionStatus();
            })
            .catch(() => navigate(routeName.serviceError));
    }

    /*  Updating the Section status on Delete*/
    const updateSectionStatus = async () => {
        const statusResponse = await submitDSASectionStatus(dsaApplicationData.id!, controllerSection.aboutProcessor, taskListSectionStatus.inProgress);

        if (statusResponse) {
            if (processorListData.processorList && processorListData.processorList?.length > 1) {
                navigate(routeName.processorDetails);
            } else {
                if (controllerIsProccessorCount >= 1) {
                    navigate(routeName.solelyProcessor);
                } else {
                    navigate(routeName.processorData);
                }
            }
        }
        else {
            navigate(routeName.rootRoute);
        }
    }

    const handleNavigation = () => {
        if (deleteValue === dsaContent.yes) {
            handleDelete(processorData.processorId!);
        } else {
            navigate(routeName.processorDetails);
        }
    }

    /* Deletion and navigation based on the Radio Values(Yes/No) */
    const handleDeleteContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateDeleteFields();
        if (isValid) {
            submitSpinner(e, handleNavigation);

        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    /* Validate if atleast one option(Yes/No) is selected */
    const validateDeleteFields = () => {
        let isValid: boolean = true;

        if (deleteValue === '') {
            setDeleteProcessorErrorMessage(`${dsaControllerErrorMessage.deleteController_ErrorMessage} ${processorData.processordataOrgDesc}`);
            isValid = false;
        }
        return isValid;
    }

    /* Reset the error if the selected value is not blank */
    const resetDeleteError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setDeleteProcessorErrorMessage("");
        }
    }

    /* set the state value on change of Radio values(Yes/No) */
    const handleChangeDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteValue(e.target.value)
        resetDeleteError(e)
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} /> :
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack/>

                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    About the Processor</span>
                                    Are you sure you want to delete {processorData.processordataOrgDesc}?
                                </h1>
                                {deleteProcessorErrorMessage !== "" && <ErrorDetails errorDescription={deleteProcessorErrorMessage} errorHref="#deleteProcessor"></ErrorDetails>}

                                <Radios
                                    name="deleteprocessor"
                                    id="deleteProcessor"
                                    error={deleteProcessorErrorMessage}
                                >
                                    <Radios.Radio value={dsaContent.yes}
                                        onChange={handleChangeDelete}>
                                        {dsaContent.yes}
                                        <Hint>If you select yes, all details for {processorData.processordataOrgDesc} will be removed.</Hint>
                                    </Radios.Radio>
                                    <Radios.Radio value={dsaContent.no} onChange={handleChangeDelete}>{dsaContent.no}</Radios.Radio>
                                </Radios>

                                <div className="nhsuk-u-padding-bottom-8"></div>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleDeleteContinue}>{dsaContent.btnSaveAndContinue}</Button>

                            </div>
                        }
                    </div>
                </main>
            </div>
        </>
    )
}

export default DeleteProcessor;
