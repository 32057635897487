import { Label } from "nhsuk-react-components";
import React, { useEffect } from "react";
import { updatePageTitle } from "../../Helper/Utility";
import { pageTitles } from "../../config/page.config";

const Accessibility: React.FC = () => {

    useEffect(() => {
        updatePageTitle(pageTitles.accessibility);
    }, []);

    return (
        <div className="nhsuk-width-container">
            <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                <div className="nhsuk-grid-row">

                    <div className="nhsuk-grid-column-two-thirds">
                        <h1>Accessibility statement for the DARS Online website</h1>
                        <Label size="s">This accessibility statement applies to the DARS Online website.</Label>
                        <Label size="s">This website is run by NHS England. We want as many people as possible to be able to use this website. For example, that means you should be able to:</Label>
                        <ul>
                            <li>Zoom in up to 400% without the text spilling off the screen</li>
                            <li>Navigate most of the website using a keyboard or speech recognition software</li>
                            <li>Listen to most of the website using a screen reader (including the most recent versions of JAWS, NVDA and VoiceOver)</li>
                        </ul>
                        <Label size="s">We’ve also made the website text as simple as possible to understand. <a href='https://mcmw.abilitynet.org.uk/'>AbilityNet</a> has advice on making your device easier to use if you have a disability.</Label>
                        <div className="nhsuk-u-padding-bottom-1"></div>

                        <h2>How accessible this website is</h2>
                        <Label size="s">We know some parts of this website are not fully accessible:</Label>
                        <ul>
                            <li>Some headings are incorrectly labelled on some of the pages.</li>
                            <li>The reading order of some of the pages needs to be improved but users, using screen reader software are still able to access the page content.</li>
                        </ul>
                        <div className="nhsuk-u-padding-bottom-1"></div>

                        <h2>Feedback and contact information</h2>
                        <Label size="s">If you find any problems not listed on this page or think we’re not meeting accessibility requirements,</Label>
                        <Label size="s">Email: <a href='mailto:enquiries@nhsdigital.nhs.uk?subject='>enquiries@nhsdigital.nhs.uk</a> or complete the <a href='https://feedback.digital.nhs.uk/jfe/form/SV_bjyeZ6KBPc967cy'>DARS Online website feedback form</a>.</Label>
                        <Label size="s">If you need information on this website in a different format like accessible PDF, large print, easy read, audio recording or braille, email: <a href='mailto:enquiries@nhsdigital.nhs.uk?subject='>enquiries@nhsdigital.nhs.uk</a> or call 0300 303 5678</Label>


                        <div className="nhsuk-u-padding-bottom-1"></div>

                        <h2>Enforcement procedure</h2>
                        <Label size="s">The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the ‘accessibility regulations’). If you’re not happy with how we respond to your complaint, <a href='https://www.equalityadvisoryservice.com/'>contact the Equality Advisory and Support Service (EASS)</a>.</Label>

                        <div className="nhsuk-u-padding-bottom-1"></div>

                        <h2>Technical information about this website’s accessibility</h2>
                        <Label size="s">NHS England is committed to making its website accessible, in accordance with the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.</Label>

                        <div className="nhsuk-u-padding-bottom-1"></div>
                        <h3>Compliance status</h3>
                        <Label size="s">This website is partially compliant with the [<a href='https://www.w3.org/TR/WCAG21/'>Web Content Accessibility Guidelines version 2.1</a> AA standard or <a href='https://www.w3.org/TR/WCAG22/'>Web Content Accessibility Guidelines version 2.2</a> AA standard], due to the non-compliances’, listed below.</Label>


                        <div className="nhsuk-u-padding-bottom-1"></div>
                        <h2>Non-accessible content</h2>
                        <Label size="s">The content listed below is non-accessible for the following reasons.</Label>
                        <ul>
                            <li>Some headings are incorrectly labelled on some of the pages.</li>
                            <li>The reading order of some of the pages needs to be improved but users, using screen reader software are still able to access the page content.</li>
                        </ul>

                        <div className="nhsuk-u-padding-bottom-1"></div>
                        <h3>Disproportionate burden</h3>
                        <Label size="s">No disproportionate burden has been identified.</Label>

                        <div className="nhsuk-u-padding-bottom-1"></div>
                        <h3>Content that’s not within the scope of the accessibility regulations</h3>
                        <Label size="s">No content that’s not within the scope of the accessibility regulations has been identified.</Label>

                        <div className="nhsuk-u-padding-bottom-1"></div>
                        <h2>What we’re doing to improve accessibility</h2>
                        <Label size="s">We are planning to carry out further internal accessibility testing and use an external specialist agency to carry out an accessibility audit of the website.</Label>

                        <div className="nhsuk-u-padding-bottom-1"></div>
                        <h2>Preparation of this accessibility statement</h2>
                        <Label size="s">This statement was prepared on the 15 January 2024. It was last reviewed on the 15 January 2024.</Label>
                        <Label>This website was last accessibility tested on 7 November 2023.</Label>
                        <Label>Some of the most viewed page templates were tested by our website team.</Label>

                    </div>
                </div>
            </main>
        </div>
    )
}
export default Accessibility;

