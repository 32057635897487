import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { ISubmitApplication } from '../../models/app.interface'

const initialState: ISubmitApplication = {
    isConfirmChecked: false,
    aboutSubmitApplicationSectionStatus: ""
}

export const SubmitApplicationSlice = createSlice({
    name: 'SubmitApplication',
    initialState,
    reducers: {
        setSubmitApplicationDetailsData: (state: ISubmitApplication, action: PayloadAction<ISubmitApplication>) => {
            state = Object.assign(state, action.payload);
        },
        resetSubmitApplicationDetailsData: (state: ISubmitApplication, action: PayloadAction<ISubmitApplication>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setSubmitApplicationDetailsData, resetSubmitApplicationDetailsData } = SubmitApplicationSlice.actions;
export const getSubmitApplicationDetailsData = (state: RootState) => state.submitApplicationDetails;
export default SubmitApplicationSlice.reducer;