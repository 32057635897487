import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialStateOfFileUpload } from '../../../config/page.config'
import type { RootState } from '../../app/store'
import { IProcessor, IProcessorList, IFileUpload, ISecurityAssurance } from '../../models/app.interface'



const initialStateofSecurityAssurance: ISecurityAssurance = {
    SAType: "",
    SAId: "",
    ISOVersion: "",
    ISODateComplete: "",
    ISONotes: "",
    SLSPNotes: "",
    SLSPDateComplete: "",
    DSPT_ODS_Code: "",
    DSPT_Latest_Status: "",
    DSPT_Date_Published: "",
    DSPT_Comments:"",
    SectionType: "",
    DSPTDay: "",
    DSPTMonth: "",
    DSPTYear: "",
    ISODay: "",
    ISOMonth: "",
    ISOYear: "",
    SLSPDay: "",
    SLSPMonth: "",
    SLSPYear: "",
    SAIndex: 0
}

const initialStateOfProcessor: IProcessor = {
    isCompleted: false,
    processorId: "",
    isDirtyProcessor: false,
    fileUpload: [initialStateOfFileUpload],
    processorType: "",
    aboutProcessorSectionStatus: "",
    dsaApplicationId: "",
    contractNumber: "",
    alternativeOrganisationName: "",
    alternativeStreet1: "",
    alternativeStreet2: "",
    alternativeStreet3: "",
    alternativeCity: "",
    alternativeCounty: "",
    alternativePostCode: "",
    alternativeCountry: "",
    processordataOrgGuidId: "",
    processordataOrgDesc:"",
    dpaRegistrationNumber: "",
    dpaOrganisationName: "",
    dpaRegistrationExpiryDate: "",
    saList: initialStateofSecurityAssurance,
    temporaryFileList: [initialStateOfFileUpload],
    fileUploadActive: 0,
    evidenceFileSummary: []
}
const initialState: IProcessorList = {
    activeProcessor: 0,
    processorDetailsPage: "",
    processorList: [initialStateOfProcessor]
}

export const ProcessorListSlice = createSlice({
    name: 'dsaProcessorListDetails',
    initialState,
    reducers: {
        setProcessorListDetailsData: (state: IProcessorList, action: PayloadAction<IProcessorList>) => {
            state = Object.assign(state, action.payload);
        },
        resetProcessorlistDetailsData: (state: IProcessorList, action: PayloadAction<IProcessorList>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setProcessorListDetailsData, resetProcessorlistDetailsData } = ProcessorListSlice.actions;
export const getProcessorListDetails = (state: RootState) => state.processorListDetails;
export default ProcessorListSlice.reducer;