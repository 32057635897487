import React, { HTMLProps, useContext } from 'react';
import classNames from 'classnames';
import AttachmentContext from './AttachmentContext';

const AttachmentDetails: React.FC<HTMLProps<HTMLDivElement>> = ({ className, ...rest }) => {
    const { feature } = useContext(AttachmentContext);
    return (
        <div
            className={classNames('nhsuk-c-attachment__details', className)} {...rest}
        />
    );
};


export default AttachmentDetails;