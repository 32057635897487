import { Button, InsetText, SummaryList } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import { applicationChangeType, dataSetLevelOptionTypes, dsaContent, routeName, standardisationServiceLevelOptions } from "../../../../config/page.config";
import { checkApplicationChangeType } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IDataSet, IDataSetFieldGroupList, IDataSetFieldList, IHistoricAnnualData } from "../../../../state/models/app.interface";
import { getDataSetDetails, initialStateHistoricData, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";


const DataSetCheckYourAnswer: React.FC = () => {

    let navigate = useNavigate();
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);
    const dispatch: AppDispatch = useAppDispatch();

    const dataSetDetails: IDataSet = useAppSelector(getDataSetDetails);
    const initialDataSetState: IDataSet = dataSetDetails || {};
    const [dataSetData, setDataSetData] = useState(initialDataSetState);
    const [isExpender, setIsExpender] = useState(false);
    let hasFirstRecordFields: boolean = false;

    const saveDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }

    const renderFieldsData = (data: IDataSetFieldList[], parentItemIndex: number, showAll: boolean) => {
        return data?.map((items: IDataSetFieldList, i: number) => (
            <React.Fragment key={items?.fieldLogicalName}>
                {i > 3 ? hasFirstRecordFields = true : false}
                {i <= 3 && !showAll ? renderFieldInfo(items?.fieldName!, items?.fieldLogicalName!) :
                    <>{(i > 3 && parentItemIndex === 0 && showAll) ?
                        renderFieldInfo(items?.fieldName!, items?.fieldLogicalName!) :
                        (parentItemIndex > 0 && showAll) ?
                            renderFieldInfo(items?.fieldName!, items?.fieldLogicalName!) :
                            ""}</>}
            </React.Fragment>

        ))
    }
    const renderFieldInfo = (fieldName: string, logicalName: string) => {
        return (<p>- {fieldName}<strong> ({logicalName})</strong></p>);
    }

    const handleExpend = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setIsExpender(isExpender ? false : true)
    }

    const handledatasetchange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        let updatedState: IDataSet = {};
        updatedState = {
            ...dataSetData,
            temporaryFutureRelease: dataSetData?.futureRelease,
            temporaryHistoricAnnual: dataSetData?.historicAnnual,
            temporaryLatestRelease: dataSetData?.latestRelease,
            temporaryHistoricAnnualDataList: dataSetData?.historicAnnualDataList
        };
        setDataSetData(updatedState);
        saveDataInStore(updatedState);
        checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)]) ? navigate(routeName.dataDuration) : navigate(routeName.aggredatedRecord);
    }

    const getStandardizationServiceLevelOptionsSelected = () => {
        const {
            OneOffBespokeValue,
            RegularBespokeValue,
            RegularStandardValue,
            SystemAccessValue
        } = standardisationServiceLevelOptions;

        const selectedOption = String(dataSetData?.standardisationServiceLevelOptionsSelected);

        switch (selectedOption) {
            case OneOffBespokeValue:
            case RegularBespokeValue:
            case RegularStandardValue:
            case SystemAccessValue:
                return true;
            default:
                return false;
        }
    }

    useEffect(() => {
        let updatedState: IDataSet = {};
        updatedState = {
            ...dataSetData,
            temporaryFutureRelease: false,
            temporaryHistoricAnnual: false,
            temporaryLatestRelease: false,
            temporaryHistoricAnnualDataList: [initialStateHistoricData]
        };
        setDataSetData(updatedState);
        saveDataInStore(updatedState);
    }, [])


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-full">
                            <GoBack condition="dataset-details"></GoBack>
                            <h1>  <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the data &gt; {dataSetData.name} </span>
                                Check your answers
                            </h1>
                            {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Amendment)]) ?
                                null : <InsetText>
                                    <p>Check the information you have selected for this data set.</p>
                                    <p>If you select 'Change dataset details', your changes will not be saved until you have revisited each page, through to the question about linking to a participant group.</p>
                                </InsetText>}
                            <p><strong>{dataSetData.name}</strong>
                                <span className="nhsuk-float-right ">{((dataSetData.serviceTypeOneOff === true || dataSetData.serviceTypeRegularBespoke === true || dataSetData.serviceTypeRegularStandard === true) && (dataSetData.availableOnline !== undefined && dataSetData.availableOnline)) ? <Link to={""} onClick={handledatasetchange}>{checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)]) ? "Add additional years" : "Change dataset details"}</Link> : ""}</span></p>
                            <SummaryList>
                                {getStandardizationServiceLevelOptionsSelected() ?
                                    <>
                                        <SummaryList.Row>
                                            <SummaryList.Key>
                                                Link to cohort
                                            </SummaryList.Key>
                                            <SummaryList.Value>
                                                {dataSetData.linkedToCohort ? dsaContent.yes : dsaContent.no}
                                            </SummaryList.Value>
                                            <SummaryList.Actions>
                                            </SummaryList.Actions>
                                        </SummaryList.Row>
                                        {dataSetData?.latestRelease || dataSetData?.futureRelease || dataSetData?.historicAnnual ?

                                            <SummaryList.Row>
                                                <SummaryList.Key>
                                                    Data duration
                                                </SummaryList.Key>
                                                <SummaryList.Value>
                                                    {(dataSetData?.latestRelease && dataSetData.updatedDaily) ? <p>Latest Release</p> : (dataSetData?.latestRelease && !dataSetData.updatedDaily) ? <p>Year-to-date data</p> : ""}
                                                    {dataSetData?.futureRelease ? <p>Future release data</p> : ""}
                                                    {dataSetData?.historicAnnual ? <p>Historic annual data</p> : ""}
                                                </SummaryList.Value>
                                                <SummaryList.Actions>
                                                </SummaryList.Actions>
                                            </SummaryList.Row>
                                            : null}
                                        {dataSetData?.historicAnnualDataList !== null && dataSetData?.historicAnnualDataList !== undefined && dataSetData?.historicAnnualDataList!.length > 0 ?
                                            <SummaryList.Row>
                                                <SummaryList.Key>
                                                    Historic annual data
                                                </SummaryList.Key>
                                                <SummaryList.Value>
                                                    {dataSetData?.historicAnnualDataList?.map((items: IHistoricAnnualData, index: number) => (
                                                        <p key={"historic" + index}>- {items?.displayText}</p>
                                                    ))}
                                                </SummaryList.Value>
                                                <SummaryList.Actions>
                                                </SummaryList.Actions>
                                            </SummaryList.Row>
                                            : null}
                                        <SummaryList.Row>
                                            <SummaryList.Key>Type</SummaryList.Key>
                                            <SummaryList.Value>
                                                {String(dataSetData?.aggregatedOrRecordLevelData) === String(dataSetLevelOptionTypes.recordLevelValue) ? dsaContent.recordlevel : String(dataSetData?.aggregatedOrRecordLevelData) === String(dataSetLevelOptionTypes.aggregatedLevelValue) ? dsaContent.aggregatedlevel : ""}
                                            </SummaryList.Value>
                                            <SummaryList.Actions>
                                            </SummaryList.Actions>
                                        </SummaryList.Row>
                                        <SummaryList.Row>
                                            <SummaryList.Key>Fields</SummaryList.Key>
                                            <SummaryList.Value>
                                                {dataSetData?.groupByFieldsList?.map((item: IDataSetFieldGroupList, index: number) => (
                                                    <React.Fragment key={"0001" + index + 1}>
                                                        {index === 0 ? <><p>{item?.groupName}</p>
                                                            {renderFieldsData(item?.groupFieldList!, index, false)}
                                                        </> : ""}
                                                        {(hasFirstRecordFields && index === 0) || (!hasFirstRecordFields && index < 1) ? <p><a href="#" onClick={handleExpend}>{!isExpender ? "+Show all" : ""} </a></p> : ""}
                                                    </React.Fragment>
                                                ))}

                                                {isExpender ? <>
                                                    {dataSetData?.groupByFieldsList?.map((item: IDataSetFieldGroupList, index: number) => (
                                                        <React.Fragment key={"0002" + index}>
                                                            {index > 0 ? <p>{item?.groupName}</p> : ""}
                                                            {renderFieldsData(item?.groupFieldList!, index, true)}
                                                        </React.Fragment>
                                                    ))}</> : ""}
                                                <p><a href="#" onClick={handleExpend}>{!isExpender ? "" : "-Show all"} </a></p>
                                            </SummaryList.Value>
                                            <SummaryList.Actions>
                                            </SummaryList.Actions>
                                        </SummaryList.Row>
                                    </>
                                    : null
                                }
                            </SummaryList>
                        </div>
                        {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Amendment)]) ? <>
                            <div className="nhsuk-grid-row">
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <Button name="continue" id="nhsuk-button-spinner" onClick={() => navigate(routeName.datasetDetails)}>Back to data set(s)</Button>
                            </div>
                        </> :  null} 
                    </div>
                </main>
            </div>

        </>
    );
}
export default DataSetCheckYourAnswer;