import React, { useEffect, useState } from "react";
import { Button, ErrorSummary, Label, Radios } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { getCookie, submitSpinner, fetchInterceptor } from "../../../Helper/Utility";
import { AppDispatch } from '../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../state/app/hooks';
import { IDSFCContractDetails, IDSFCCustomerSignatory } from "../../../state/models/app.interface";
import { getDSFCContractDetails, setDSFCContractDetailsData } from '../../../state/slice/DSFC/DSFCContractDetails.slice';
import { SubmitCustomerSignatory_URL } from "../../../config/api-endpoints.config";
import { dsfcErrorMessage, dsfcContent, routeName, fileTypeSize } from "../../../config/page.config";
import "../../../Content/css/nhsuk-common.css";
import GoBack from "../../../Components/Shared/GoBack";
import InfoHeaderPanel from "../../../Components/InfoHeaderPanel/InfoHeaderPanel";

const DSFCSignatory: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const DSFCContractDetails: IDSFCContractDetails = useAppSelector(getDSFCContractDetails);
    const initialState: IDSFCContractDetails = DSFCContractDetails || {};
    const [dsfcData, setDSFCData] = useState(initialState);
    const dispatch: AppDispatch = useAppDispatch();

    const savedataInStore = (stateData?: IDSFCContractDetails) => {
        dispatch(setDSFCContractDetailsData({ ...dsfcData, ...stateData }));
    }

    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [signatoryError, setSignatoryError] = useState("");
    const [showButtonNext] = useState(true);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLInputElement;
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            if (target.name !== "undefined" && target.name !== null && target.name === dsfcContent.next) {
                submitSpinner(e, submitCustomerSignatory(e));
            }
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const ValidateFields = () => {
        let isValid: boolean = true;
        if (dsfcData.customerSignatory === '') {
            setSummaryLinkErrorMessage(`${dsfcErrorMessage.signatorySummaryLink_ErrorMessage}`);
            setSignatoryError(`${dsfcErrorMessage.signatorySummaryBody_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === dsfcContent.customerSignatory && e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setSignatoryError("");
        }
    }

    const submitCustomerSignatory = (event: React.MouseEvent<HTMLButtonElement>) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "CustomerSignatoryId": dsfcData.customerSignatory,
            "DSFCApplicationId": dsfcData.dsfcApplicationId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitCustomerSignatory_URL, requestOptions)
            .then(() => {
                if ((event.target as HTMLInputElement).name === dsfcContent.next) {
                        navigate(routeName.dsfcPreview);
                    }
               
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IDSFCContractDetails = {};

        dsfcData.signatoryDetalis?.filter(aa => aa.signatoryId == e.target.value).map(filteredName => (
            updatedState = {
                ...dsfcData,
                [e.target.name]: e.target.value,
                fullName: filteredName.fullName,
                emailAddress: filteredName.emailAddress,
                dsfcsignatory: true
            }
        ))
        resetError(e);
        setDSFCData(updatedState);
        savedataInStore(updatedState);
    }

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsfcErrorMessage.summaryTitle_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <p>{dsfcErrorMessage.signatorySummaryBody_ErrorMessage}</p>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#signatoryList">
                            {summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    const submitSignatory = () => {
        if (dsfcData.signatoryDetalis?.length === 1) {
            let updatedState: IDSFCContractDetails = {};

            updatedState = {
                ...dsfcData,
                customerSignatory: dsfcData.signatoryDetalis[0].signatoryId,
                fullName: dsfcData.signatoryDetalis[0].fullName,
                emailAddress: dsfcData.signatoryDetalis[0].emailAddress,
                dsfcsignatory: true
            }
            setDSFCData(updatedState);
            savedataInStore(updatedState)
        }
    }

    useEffect(() => {
        if (!dsfcData.dsfcdetails || dsfcData.dsfcsubmit) {
            navigate(routeName.dashboard);
        }
        if (dsfcData.signatoryDetalis?.length === 1) {
            // set signatory ID if only one signatory getting from API
            submitSignatory();
        }
    }, []);

    return (
        <>
            <InfoHeaderPanel>
                <InfoHeaderPanel.Item >
                    <strong>Reference number</strong> : {dsfcData?.contractNumber}
                </InfoHeaderPanel.Item>
            </InfoHeaderPanel>

            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-full">
                            <GoBack />
                            {dsfcData.signatoryDetalis?.length === 1 ?
                                <h1>Current signatory for a Data Sharing Framework Contract</h1> :
                                <h1>Select a signatory for the Data Sharing Framework Contract</h1>
                            }
                            <Label>
                                <p>If the signatory is not listed, email <a href={'mailto:data.applications@nhsdigital.nhs.uk?subject=DSFC - ' + dsfcData?.contractNumber}>data.applications@nhsdigital.nhs.uk</a> quoting 'DSFC', along with your reference number in the subject field.</p>
                            </Label>
                        </div>
                        <div className="nhsuk-grid-column-full">
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            {dsfcData.signatoryDetalis?.length === 1 ?
                                dsfcData.signatoryDetalis?.map((signatory: IDSFCCustomerSignatory, index: number = 0) => (
                                    <div key={index + 1}>

                                        <p></p>
                                        <br></br>
                                        <Label bold>
                                            {signatory.fullName}
                                        </Label>
                                        <br></br>
                                        <p></p>
                                        <p></p>
                                    </div>
                                ))
                                :
                                <>
                                    <br></br>
                                    <Radios
                                        name="signatoryList"
                                        id="signatoryList"
                                        error={signatoryError}>
                                        {
                                            dsfcData.signatoryDetalis?.map((signatory: IDSFCCustomerSignatory, index: number = 0) => (
                                                <Radios.Radio
                                                    name="customerSignatory"
                                                    key={index + 1}
                                                    value={signatory.signatoryId}
                                                    checked={dsfcData?.customerSignatory === signatory.signatoryId}
                                                    onChange={handleChange}
                                                >
                                                    {signatory.fullName}
                                                </Radios.Radio>
                                            ))
                                        }
                                    </Radios>
                                    <br></br>
                                    <br></br>
                                </>
                            }
                            {showButtonNext ?
                                <>
                                    <span><Button name="next" onClick={handleClick} id="nhsuk-button-spinner">{dsfcContent.btnTextNext}</Button></span>
                                </>
                                : null
                            }
                           
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default DSFCSignatory;