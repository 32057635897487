import { Button, Details, Textarea, ErrorSummary, Fieldset } from "nhsuk-react-components";
import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { submitSpinner, validateDateValue, getCookie, fetchInterceptor, handleUploadFile, validateFile, setInputFileCustom, getB64toBlobData, getEvidenceFileDetails, isValid } from "../../../../Helper/Utility";
import { IProcessor, IApplication, IProcessorList, IFileUpload, IEvidenceFileSummary, IApiResEvidenceFileSummary } from "../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getProcessorDetails, setProcessorDetails } from '../../../../state/slice/Processor/Processor.slice';
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { dsaProcessorErrorMessage, fileErrorMessage, dsaContent, fileTypeSize, documentType, routeName, buttonText } from "../../../../config/page.config";
import { getProcessorListDetails, setProcessorListDetailsData } from "../../../../state/slice/ProcessorList/ProcessorList.slice";
import { GetDSAFileById_URL } from "../../../../config/api-endpoints.config";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";

import ReviewDateSection from "../../../../Components/Shared/ReviewDateSection";
import FileUploadControl1 from "../../../../Components/FileUploadControl/FileUploadControl1";
import { usePrevLocation } from "../../../../Hooks/usePrevious";
import Loader from "../../../../Components/Loader/Loader";

/*
  Summary: Using this page, you can add the details related to SystemLevelSecurityPolicy and can also upload files. The Jira ticket linked to this is DDA-862.
*/
const SLSP: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    const location: unknown = useLocation();
    const prevLocation = usePrevLocation(location as Location);
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const { fileUploadActive = 0 } = useAppSelector(getProcessorDetails) || {};
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const processorDetailsData: IProcessor = useAppSelector(getProcessorDetails);
    const initialState: IProcessor = processorDetailsData || {};
    const [processorData, setData] = useState(initialState);
    const processorListDetailsData: IProcessorList = useAppSelector(getProcessorListDetails);
    const initialProcessorListState: IProcessorList = processorListDetailsData || {};
    const [processorListData] = useState(initialProcessorListState);
    const [dateReviewError, setDateReviewError] = useState("");
    /* To dispatch the setProcessorListDetailsData Redux action */
    const saveProcessorListDataInStore = (stateData?: IProcessorList) => {
        dispatch(setProcessorListDetailsData({ ...processorListData, ...stateData }));
    }

    let isDirtyProcessor = processorData.isDirtyProcessor || false;
    const [diDayError, setDiDayError] = useState(false);
    const [diMonthError, setDiMonthError] = useState(false);
    const [diYearError, setDiYearError] = useState(false);
    const [reviewDate, setReviewDate] = useState(false);
    const [fileLists, setFileLists] = useState<IFileUpload[]>([]);
    const [fileListsOnChange, setFileListsOnChange] = useState<File[]>([]);
    let showfileLists: IFileUpload[] = ([])
    const [selectedFile, setSelectedFile] = useState<File[] | []>([]);
    const [isSelected, setIsSelected] = useState(false);
    const [fileChosen, setfileChosen] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [uploadAnother, setUploadAnother] = useState(false);
    const [validFileError, setValidFileError] = useState("");
    const [isFileUploadedError, setIsFileUploadedError] = useState("");
    const [isFileUploadedErrorLink, setIsFileUploadedErrorLink] = useState("");
    const [validateChooseFile, setValidateChooseFile] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showdownload, setshowdownload] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(0);
    const [selectedApprovalEvidence, setSelectedApprovalEvidence] = useState('');
    const [applicantCommentsErrorMessage, setApplicantCommentsErrorMessage] = useState("");
    const [showReviewDate, setShowReviewDate] = useState(false);
    const [applicantComments, setApplicantComments] = useState(getComment(processorData.fileUpload![fileUploadActive]));
    const [approvalEvidenceId] = useState(processorData.fileUpload![fileUploadActive]?.ApprovalEvidenceID! !== '' ? processorData.fileUpload![fileUploadActive]?.ApprovalEvidenceID! : '');
    let [evidenceSummaryList, setEvidenceSummaryList] = useState<IEvidenceFileSummary[]>([]);
    const [showLoader, setShowLoader] = useState(true);
    const [selectedFileName, setSelectedFileName] = useState("");
   
    function getComment(uploadedFile: IFileUpload) {
        if (
          uploadedFile !== undefined &&
          uploadedFile.DocumentType === documentType.SLSP
        ) {
            return (uploadedFile?.ApplicantComments! !== "" ? uploadedFile?.ApplicantComments!: "")
        } else {
          return "";
        }
    };

    const isDateEntered = () => {
        return (processorData.saList?.SLSPDay !== "" &&  processorData?.saList?.SLSPMonth !== "" && processorData?.saList?.SLSPYear !== "");
    }

    /* To dispatch the setProcessorDetails Redux action */
    const saveDataInStore = (stateData?: IProcessor) => {
        dispatch(setProcessorDetails({ ...processorData, ...stateData }));
    }

    const handelUploadedFiles = (files: File[]) => {
        const uploaded = [...selectedFile!];
        files.forEach((file: File) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                return true;
            }
        })
        setSelectedFile(uploaded);
    }
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === dsaContent.file) {
            resetError(e);
            const chosenFiles = Array.prototype.slice.call(e.target.files)
            setValidateChooseFile(true);
            if (validateFile(e, fileTypeSize, setValidFileError, setIsFileUploadedError, fileErrorMessage, setIsSelected, false, selectedFileName!, evidenceSummaryList)) {
                handelUploadedFiles(chosenFiles);
                setfileChosen(true);
                setValidFileError("");
                setValidateChooseFile(false);
                let updatedState: IProcessor = {};
                updatedState = { ...processorData, [e.target.name]: e.target.value, isDirtyProcessor: true }
                setData(updatedState);
                saveDataInStore(updatedState);
                if (processorData.fileUpload![fileUploadActive!]?.FileName !== undefined) {
                    handleUpload(e, chosenFiles);
                }
            }
            else {
                setSelectedFile([]);
                setIsSelected(false);
            }
        }
        else {
            let updatedState: IProcessor = {};
            let updatedSAList= { ...processorData.saList, [e.target.name]: e.target.value };
            updatedState = { ...processorData, 'saList': updatedSAList, isDirtyProcessor: true }
            setData(updatedState);
            saveDataInStore(updatedState);
            resetError(e);
        }
        if (e.target.name === dsaContent.slspDay || e.target.name === dsaContent.slspMonth || e.target.name === dsaContent.slspYear) {
            resetError(e);
        }
    };

    let files = selectedFile ? Array.from(selectedFile) : [];

    const validateUploadedFile = (filesToValidate?: File[]) => {
        if ((uploadedFileOnChange?.length + filesToValidate!.length) > 6) {
            setValidFileError(`${fileErrorMessage.fileLength_ErrorMessage}`);
            setSelectedFile([]);
            setIsSelected(false);
            if (inputRef.current != null)
                inputRef.current.value = '';
            return false;
        }
        else if (filesToValidate?.length === 0 && processorData.fileUpload![fileUploadActive!]?.FileName === undefined) {
            setValidFileError(`${fileErrorMessage.fileRequiredToUpload_ErrorMessage}`);
            setSelectedFile([]);
            setIsSelected(false);
            return false;
        }
        return true;
    }

    /* To upload the file */    
    const handleUpload = (e: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<HTMLInputElement>, fileList?: File[]) => {
        fileList = fileList ? fileList : files;
        const IsValid: boolean = validateUploadedFile(fileList);
        if (IsValid) {
            handleUploadFile(e, fileLists, fileListsOnChange, setIsUploaded, selectedFile!, fileList!, setFileLists, setFileListsOnChange, setIsSelected, setSelectedFile, resetError, false, true, setUploadAnother, evidenceSummaryList!, setEvidenceSummaryList, setSelectedFileName, selectedFileId, applicantComments, fileUploadActive, 0, selectedApprovalEvidence);
        }
        setSelectedFileId(0);
        setSelectedApprovalEvidence('');
    }

    let finallyUploadedFiles: IFileUpload[] = fileLists ? Array.from(fileLists) : processorData.fileUpload!;       // submit
    let uploadedFileOnChange = fileListsOnChange ? Array.from(fileListsOnChange) : [];      // 2nd time change

    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IProcessor = {};
        let updatedSAList = { ...processorData.saList, [e.target.name]: e.target.value };
        updatedState = { ...processorData, 'saList': updatedSAList, isDirtyProcessor: true }
        setData(updatedState);
        saveDataInStore(updatedState);
    }




    ///* To reset the error */
    //const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
    //    const valueLength = e.target.value.length;
    //    if (valueLength >= 1) {
    //        if (e.target.id === dsaContent.file) {
    //            setIsFileUploadedError("");
    //            setValidFileError("");
    //            setIsFileUploadedErrorLink("");
    //        }
    //        if (e.target.name === dsaContent.slspDay) {
    //            setDateReviewError("");
    //            setDiDayError(false);
    //        }
    //        if (e.target.name === dsaContent.slspMonth) {
    //            setDateReviewError("");
    //            setDiMonthError(false);
    //        }
    //        if (e.target.name === dsaContent.slspYear) {
    //            setDateReviewError("");
    //            setDiYearError(false);
    //        }
    //    }
    //    if (e.target.name === dsaContent.upload) {
    //        setIsFileUploadedError("");
    //        setIsFileUploadedErrorLink("");
    //    }
    //}


    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const valueLength = e.target.value.length;
        if (valueLength >= 1) {
            if (e.target.id === dsaContent.file) {
                setIsFileUploadedError("");
                setValidFileError("");
                setIsFileUploadedErrorLink("");
            }
            if (e.target.name === dsaContent.slspDay || e.target.name === dsaContent.slspMonth || e.target.name === dsaContent.slspYear) {
                setDateReviewError("");
                setDiYearError(false);
                setDiDayError(false);
                setDiMonthError(false);
            }
            if (e.target.id === dsaContent.applicantComments) {
                setApplicantCommentsErrorMessage("");
            }
        }
        if (e.target.name === dsaContent.upload) {
            setIsFileUploadedError("");
            setIsFileUploadedErrorLink("");
        }
    }





    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields(e);
        if (isValid) {
            submitSpinner(e, submitSLSP);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }


    ///* To submit the details of the page */
    //const submitSLSP = () => {
    //    let updatedSAList = { ...processorData.saList, SLSPDateComplete: processorData.saList ? processorData.saList.SLSPDay + "/" + processorData.saList.SLSPMonth + "/" + processorData.saList.SLSPYear : "" };
    //    let updatedState: IProcessor = {};
    //    updatedState = {
    //        ...processorData,
    //        fileUpload: finallyUploadedFiles,
    //        'saList': updatedSAList
    //    }
    //    setData(updatedState);
    //    saveDataInStore(updatedState);

    //    if (processorListData.processorDetailsPage && !processorData?.isNewProcessor) {
    //        if (isDirtyProcessor) {
    //            const parameters: string = JSON.stringify({
    //                "UserAuthTokenId": authenticationTokenCookie,
    //                "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
    //                "IsSADirty": true,
    //                "FileList": uploadedFileOnChange,
    //                "DataProcessorId": processorData.processorId,
    //                "SecurityAssurance": updatedSAList,
    //                "SectionStatus": dsaApplicationData.aboutProcessorSectionStatus
    //            });
    //            const requestOptions: Object = {
    //                method: 'POST',
    //                headers: { 'Content-Type': fileTypeSize.fileTypeJson },
    //                body: parameters
    //            };
    //            fetchInterceptor(UpdateScreenDataProcessor_URL, requestOptions)
    //                .then(response => {
    //                    return response.json()
    //                })
    //                .then(() => {
    //                    let updatedState: IProcessorList = {
    //                        ...processorListData, processorDetailsPage: processorListData.processorDetailsPage
    //                    }
    //                    let sectionStatus: IApplication = {
    //                        ...dsaApplicationData, aboutProcessorSectionStatus: taskListSectionStatus.inProgress
    //                    }
    //                    setDsaApplicationData(sectionStatus);
    //                    saveApplicationDataInStore(sectionStatus);
    //                    saveProcessorListDataInStore(updatedState);
    //                    navigate(routeName.processorDetails);
    //                })
    //                .catch(() => navigate(routeName.serviceError));
    //        }
    //        else { navigate(routeName.processorDetails); }
    //    }
    //    else { navigate(routeName.dataProtectionRegistrationProcessor); }
    //}



    /* To submit the details of the page */
    const submitSLSP = () => {
        let updatedSAList = { ...processorData.saList, SLSPDateComplete: processorData.saList ? processorData.saList.SLSPDay + "/" + processorData.saList.SLSPMonth + "/" + processorData.saList.SLSPYear : "" };
        let updatedState: IProcessor = {};
        updatedState = {
            ...processorData,
            // fileUpload: finallyUploadedFiles,
            'saList': updatedSAList,
            evidenceFileSummary: evidenceSummaryList
        }
        setData(updatedState);
        saveDataInStore(updatedState);
        navigate(routeName.addAnotherprocessorSlsp);        
    }

    const uploadAnotherFile = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setUploadAnother(true);
    }
    ///* To Validate the each field's data */
    //const validateFields = (e: React.MouseEvent<HTMLButtonElement>) => {
    //    let isValid: boolean = true;
    //    const target = e.target as HTMLInputElement;
    //    if (finallyUploadedFiles.length <= 0 && target.name !== dsaContent.upload) {
    //        setIsFileUploadedErrorLink(`${fileErrorMessage.fileLink_ErrorMessage}`);
    //        setIsFileUploadedError(`${fileErrorMessage.fileUploded_ErrorMessage}`);
    //        isValid = false;
    //    }
    //    if (!fileChosen) {
    //        setIsFileUploadedErrorLink(`${fileErrorMessage.fileLink_ErrorMessage}`);
    //        setIsFileUploadedError(`${fileErrorMessage.fileRequiredSummaryBody_ErrorMessage}`);
    //        isValid = false;
    //    }
    //    if (validateChooseFile) {
    //        setIsFileUploadedError("");
    //    }
    //    let dateValueError = validateDateValue(Number(processorData.saList ? processorData.saList.SLSPDay : "0"), Number(processorData.saList ? processorData.saList.SLSPMonth : "0"), Number(processorData.saList ? processorData.saList.SLSPYear : "0"),false, reviewDate);
    //    if (dateValueError[0] !== "") {
    //        setDateReviewError(dateValueError[0]);
    //        let fields = dateValueError[1].split(",");
    //        for (let i = 0; i < fields.length; i++) {
    //            if (fields[i] === dsaContent.dateInputDayField) {
    //                setDiDayError(true);
    //            }
    //            if (fields[i] === dsaContent.dateInputMonthField) {
    //                setDiMonthError(true);
    //            }
    //            if (fields[i] === dsaContent.dateInputYearField) {
    //                setDiYearError(true);
    //            }
    //        }
    //        isValid = false;
    //    }
    //    return isValid;
    //}



    /* To Validate the each field's data */
    const validateFields = (e: React.MouseEvent<HTMLButtonElement>) => {
        let isValid: boolean = true;
        if (!(processorData.saList ? processorData.fileUpload![fileUploadActive]?.ApplicantComments! : null)) {
            setApplicantCommentsErrorMessage(`${dsaProcessorErrorMessage.applicantComments_ErrorMessage}`);
            isValid = false;
        }

        if ((processorData.fileUpload![fileUploadActive]?.FileName! === "" || processorData.fileUpload![fileUploadActive]?.FileName! === undefined) ) {
            setIsFileUploadedErrorLink(`${fileErrorMessage.fileLink_ErrorMessage}`);
            setIsFileUploadedError(`${fileErrorMessage.fileUploded_ErrorMessage}`);
            isValid = false;
        }
        if (!fileChosen) {
            setIsFileUploadedErrorLink(`${fileErrorMessage.fileLink_ErrorMessage}`);
            setIsFileUploadedError(`${fileErrorMessage.fileRequiredSummaryBody_ErrorMessage}`);
            isValid = false;
        }
        if (validateChooseFile) {
            setIsFileUploadedError("");
        }

        if (validFileError) {
            isValid = false;
        }

        let dateValueError = validateDateValue(Number(processorData.saList ? processorData.saList?.SLSPDay : "0"), Number(processorData.saList ? processorData.saList?.SLSPMonth : "0"), Number(processorData.saList ? processorData.saList?.SLSPYear : "0"), false, reviewDate);
        if (dateValueError[0] !== "" && showReviewDate) {
            setDateReviewError(dateValueError[0]);
            let fields = dateValueError[1].split(",");
            for (let i = 0; i < fields.length; i++) {
                if (fields[i] === dsaContent.dateInputDayField) {
                    setDiDayError(true);
                }
                if (fields[i] === dsaContent.dateInputMonthField) {
                    setDiMonthError(true);
                }
                if (fields[i] === dsaContent.dateInputYearField) {
                    setDiYearError(true);
                }
            }
            isValid = false;
        }
        return isValid;
    }




    ///* To show the error summary */
    //const errorSummary: JSX.Element = (
    //    <>
    //        <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
    //            <ErrorSummary.Title id="error-summary-title">{dsaProcessorErrorMessage.selectProcessorSummary_ErrorMessage}</ErrorSummary.Title>
    //            <ErrorSummary.Body>
    //                <ErrorSummary.List>
    //                    <ErrorSummary.Item href="#slspDateComplete">
    //                        {dateReviewError}
    //                    </ErrorSummary.Item>
    //                    <ErrorSummary.Item href="#fileupload">
    //                        {isFileUploadedErrorLink}
    //                    </ErrorSummary.Item>
    //                </ErrorSummary.List>
    //            </ErrorSummary.Body>
    //        </ErrorSummary>
    //    </>
    //)

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaProcessorErrorMessage.selectProcessorSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#applicantComments">
                            {applicantCommentsErrorMessage}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#slspDateComplete">
                            {dateReviewError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#fileupload">
                            {validFileError ? validFileError : isFileUploadedErrorLink}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )




    ///* To show the uploaded files on the page */
    //const showFileList = () => {
    //    if (processorData.fileUpload !== null && processorData.fileUpload != undefined) {
    //        const filterFile = processorData.fileUpload.filter(file => file.DocumentType === documentType.SLSP)
    //        showfileLists = Object.assign([], filterFile);
    //        if (showfileLists[0]?.FileName !== "") {
    //            setFileLists(showfileLists);
    //            finallyUploadedFiles = showfileLists;
    //            if (finallyUploadedFiles.length >= 1) {
    //                setIsSelected(true);
    //                setfileChosen(true);
    //            }
    //        }
    //    }
    //}

    /* To show the uploaded files on the page */
    const showFileList = () => {
        if (processorData.fileUpload !== null && processorData.fileUpload != undefined) {
            const filterFile = processorData?.fileUpload.length && processorData?.fileUpload?.filter(file => file?.DocumentType! === documentType.SLSP)
            showfileLists = Object.assign([], filterFile);
            if (showfileLists[0]?.FileName !== "") {
                setFileLists(showfileLists);
                finallyUploadedFiles = showfileLists;
                if (finallyUploadedFiles.length >= 1) {
                    setIsSelected(true);
                    setfileChosen(true);
                }
            }
        }
    }

    ///*File Download*/
    //const setFileUrl = (e: any) => {
    //    e.preventDefault();
    //    setSelectedIndex(e.currentTarget.id);
    //    setshowdownload(true);
    //    e.currentTarget.className += "nhsuk-disableLink";
    //    downloadfile(e, e.currentTarget.id);

    //}


    /*File Download*/
    const setFileUrl = (e: any) => {
        e.preventDefault();
        setSelectedIndex(e.currentTarget.id);
        setshowdownload(true);
        e.currentTarget.className += "nhsuk-disableLink";
        downloadfile(e, e.currentTarget.id);
    }

    const downloadfile = (e: any, fileId: any) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "FileId": fileId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: parameters
        };

        fetchInterceptor(GetDSAFileById_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data && data !== undefined) {
                    const b64Data: any = data.Data._buffer;
                    const blob: any = getB64toBlobData(b64Data, data.contentType, data.FileName);
                    const blobUrl: any = URL.createObjectURL(blob);
                    download(blobUrl, data.FileName);
                    setshowdownload(false);
                    e.target.removeAttribute('class');
                }

            })
            .catch(() => navigate('/serviceerror'));
    }

    const download = (path: any, filename: any) => {
        const anchor = document.createElement('a');
        anchor.href = path;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    const changeUpLoadedDocData = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, fileId: number, fileName: string, fileType: string, approvalEvidence: string) => {
        e.preventDefault();
        setUploadAnother(true);

        let fileElement = document.getElementById('file');
        if (fileElement != null) {
            setInputFileCustom(fileElement, fileName, fileType);
        }
        else {
            setTimeout(function () { setInputFileCustom(document.getElementById('file'), fileName, fileType) }, 500);
        }
        setSelectedFileId(fileId);
        setSelectedApprovalEvidence(approvalEvidence);
    }

    const handleChangeApplicantComments = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedStateofProcessorComment: IProcessor = {};
        setApplicantComments(e.target.value!);

        let updatedFileData: IFileUpload[] = []
        if (processorData.fileUpload?.length === 0 ||
            (processorData.fileUpload?.length !== 0 && processorData.fileUpload?.length === fileUploadActive && !processorData.fileUpload![fileUploadActive]?.ApplicantComments)) {
            //new array item
            updatedFileData = [...processorData.fileUpload!, ...[{ ...processorData.fileUpload![fileUploadActive], ...{ [e.target.name]: e.target.value, RecentlyModified: true } }]];
        } else {
            updatedFileData = processorData.fileUpload!.map((item, index) => {
                if (fileUploadActive === index) {
                    return { ...item, ...{ [e.target.name]: e.target.value, RecentlyModified: true } }
                } else return item;
            })
        }

        updatedStateofProcessorComment = { ...processorData, fileUpload: updatedFileData, isDirtyProcessor: true };
        finallyUploadedFiles = updatedFileData;

        setFileLists(finallyUploadedFiles);

        setData(updatedStateofProcessorComment);
        saveDataInStore(updatedStateofProcessorComment);
        resetError(e);
    }

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields(e);
        if (isValid) {
            submitSpinner(e, submitSLSP);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    const removeFileOnGoBack = () => {
        let updatedState: IProcessor = {};
        let fileUploadUpdated = [...processorData.temporaryFileList!];
        updatedState = {
            fileUpload: fileUploadUpdated?.filter((x) => isValid(x.FileName)),
            fileUploadActive: 0
        }
        setData(updatedState);
        saveDataInStore(updatedState);
    }

    const getEvidenceFileSummary = () => {
        let applicationId = isValid(dsaApplicationData.id) ? dsaApplicationData.id : "";
        if (isValid(applicationId)) {
            getEvidenceFileDetails(applicationId!).then((data) => {
                setEvidenceFileSummary(data);
            });
        }
    }

    const setEvidenceFileSummary = (response: IApiResEvidenceFileSummary[]) => {
        if (isValid(response)) {
            let evidenceFileSummary: IEvidenceFileSummary[] = [];
            response.map(({ FileId: fileId, FileName: fileName, MimeType: mimeType, Source: source }) => {
                evidenceFileSummary.push({ fileId, fileName, mimeType, source });
            });
            let updatedState: IProcessor = {};
            updatedState = {
                ...processorData,
                evidenceFileSummary
            }
            setData(updatedState);
            saveDataInStore(updatedState);
        }
        setShowLoader(false);

    }

    useEffect(() => {
        setShowReviewDate((prevLocation?.pathname !== routeName.addAnotherprocessorSlsp && processorData.fileUpload![fileUploadActive!]?.FileName === '') || processorData.fileUpload!.length === 0 || !isDateEntered());
        showFileList();
        setReviewDate(true);
        if (!isValid(processorData.evidenceFileSummary)) {
            getEvidenceFileSummary();
        }
        else {
            setEvidenceSummaryList(processorData.evidenceFileSummary!);
            setShowLoader(false);
        }
        setSelectedFileName(isValid(processorData.fileUpload) && isValid(processorData.fileUpload![fileUploadActive]) ? processorData.fileUpload![fileUploadActive].FileName! : "");
    }, []);

    useEffect(() => {
        if (!isValid(evidenceSummaryList)) {
            setEvidenceSummaryList(processorData.evidenceFileSummary!);
        }
    }, [processorData.evidenceFileSummary]);

    useEffect(() => {
        finallyUploadedFiles = fileLists ? Array.from(fileLists) : [];       // submit
        let updatedState: IProcessor = {};
        updatedState = {
            ...processorData,
            fileUpload: finallyUploadedFiles,
        }
        setData(updatedState);
        saveDataInStore(updatedState);
    }, [fileLists]);
    
    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {showLoader ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <>
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack onClick={removeFileOnGoBack} />
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the Processor
                            </span>
                            <h1>System Level Security Policy</h1>
                            {(isFileUploadedError !== "" || validFileError !== '' ||  dateReviewError ) ? errorSummary : null}
                        </div>

                        <div className="nhsuk-grid-column-two-thirds">
                            <Textarea
                                label="Provide your own description or name for the document (character limit 50)"
                                error={applicantCommentsErrorMessage}
                                name="ApplicantComments"
                                id={dsaContent.applicantComments}
                                onChange={handleChangeApplicantComments}
                                defaultValue={applicantComments}
                                maxLength={50}
                                rows={3}
                            />
                        </div>

                        <div className="nhsuk-grid-column-two-thirds">                            
                            <Fieldset>
                                <Fieldset.Legend>Documents required </Fieldset.Legend>
                                <Fieldset.Legend>- System Level Security Policy (SLSP) form</Fieldset.Legend>
                                <Fieldset.Legend>- Further evidence from the SLSP form </Fieldset.Legend>
                            </Fieldset>
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <div className="nhsuk-u-padding-bottom-3"></div>
                            <Details>
                                <Details.Summary>View file guidance </Details.Summary>
                                <Details.Text>
                                    <p>A file should not exceed 3 MB and should be one of the following acceptable file formats: PDF, Word, Excel. </p>
                                </Details.Text>
                            </Details>
                        </div>

                        <FileUploadControl1
                            inputRef={inputRef => inputRef}
                            errorAboveList={isFileUploadedError}
                            errorBelowList={validFileError}
                            isUploaded={isUploaded}
                            finallyUploadedFiles={fileUploadActive === 0 && processorData.fileUpload!.length === 0 ? undefined : processorData.fileUpload![fileUploadActive!] as any}
                            handleChange={handleChange}
                            handleUpload={handleUpload}
                            changeFile={changeUpLoadedDocData}
                            setFileUrl={setFileUrl}
                            showDownload={showdownload}
                            selectedIndex={selectedIndex}
                            setSelectedFileId={setSelectedFileId}
                            setSelectedApprovalEvidence={setSelectedApprovalEvidence}
                        />

                        {showReviewDate && <ReviewDateSection
                            handleChange={handleChange}
                            handleChangeTextarea={handleChangeTextarea}
                            dateReviewError={dateReviewError}
                            diDayError={diDayError}
                            diMonthError={diMonthError}
                            diYearError={diYearError}
                            data={{
                                day: processorData.saList ? processorData.saList?.SLSPDay : "",
                                month: processorData.saList ? processorData.saList?.SLSPMonth : "",
                                year: processorData.saList ? processorData.saList?.SLSPYear : "",
                                notes: processorData.saList ? processorData.saList?.SLSPNotes : ""
                            }}
                        />}

                        <div className="nhsuk-u-padding-bottom-7"></div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleSubmit}>{buttonText.btnTextContinue}</Button>
                                </div>
                            </>}
                    </div>
                </main>
            </div>
        </>
    )
}
export default SLSP