import React, { HTMLProps } from 'react';
import classNames from 'classnames';

const AttachmentThumbnail: React.FC<HTMLProps<HTMLDivElement>> = ({ className, ...rest }) => (
    <div className={classNames('nhsuk-attachment__thumbnail', className)} {...rest}>
        <a className="nhsuk-link" target="_self" aria-hidden="true">
            <svg className="nhsuk-attachment__thumbnail-image" version="1.1" viewBox="0 0 99 140" width="99" height="140" aria-hidden="true">
                <path d="M12 12h75v27H12zM12 59h9v9h-9zM12 77h9v9h-9zM12 95h9v9h-9zM12 113h9v9h-9zM30 59h57v9H30zM30 77h39v9H30zM30 95h57v9H30zM30 113h48v9H30z" strokeWidth="0" />
            </svg>
        </a></div>
)

export default AttachmentThumbnail;