import React, { HTMLProps } from "react";
import AttachmentDetails from "./AttachmentDetails";
import AttachmentLink from "./AttachmentLink";
import AttachmentThumbnail from "./AttachmentThumbnail";
import AttachmentHeading from "./AttachmentHeading";
import './nhsuk-attachment.css'
import AttachmentContext from "./AttachmentContext";
import AttachmentContent from "./AttachmentContent";
import AttachmentProperty from "./AttachmentProperty";


interface AttachmentProps extends HTMLProps<HTMLDivElement> {
    clickable?: boolean;
    feature?: boolean;
}

interface IAttachment extends React.FC<AttachmentProps> {
    Thumbnail: typeof AttachmentThumbnail;
    Link: typeof AttachmentLink;
    Details: typeof AttachmentDetails;
    Heading: typeof AttachmentHeading;
    Content: typeof AttachmentContent;
    Property: typeof AttachmentProperty;
}

const Attachment: IAttachment = ({
    className, clickable, children, feature, ...rest
}) => (
    <div  {...rest}>
        <AttachmentContext.Provider value={{ feature: Boolean(feature) }}>{children}</AttachmentContext.Provider>
    </div>

);
Attachment.defaultProps = {
    feature: false,
};

Attachment.Heading = AttachmentHeading;
Attachment.Link = AttachmentLink;
Attachment.Details = AttachmentDetails;
Attachment.Thumbnail = AttachmentThumbnail;
Attachment.Content = AttachmentContent;
Attachment.Property = AttachmentProperty;

export default Attachment;