import { controllerSection, taskListSectionStatus } from "../../../../config/page.config";
import { IApiResSectionList, ISectionStatus, ISectionStatusTaskList } from "../../../../state/models/app.interface";
import { Tag } from "nhsuk-react-components";
import { enableStatus } from "../../../../Helper/Utility";

interface DataAndParticipantProps  {
    headingNumber: number, 
    taskListStatus:ISectionStatus, 
    sectionStatus: ISectionStatusTaskList,
    taskEnableSectionMultiSelect: IApiResSectionList[],
    handleClickParticipant: (e: React.MouseEvent<HTMLAnchorElement>) => void,
    handleClickDataSet: (e: React.MouseEvent<HTMLAnchorElement>) => void,
    handleClickDSATask: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, callBack: (target?: HTMLElement) => void) => void,
    getEthicsApproval: () => void,
    readyToProceed1c:boolean
}

const DataAndParticipant: React.FC<DataAndParticipantProps> = ({
    headingNumber,
    taskListStatus,
    sectionStatus,
    taskEnableSectionMultiSelect,
    handleClickParticipant,
    handleClickDataSet,
    handleClickDSATask,
    getEthicsApproval,
    readyToProceed1c,
  }) => {
    const checkEnable = (sectionId: Number) => {
        return enableStatus(sectionId, taskEnableSectionMultiSelect);
    }
    return (
        <>
            <h3>{headingNumber}. Data and participants</h3>
            <p>We need to know what data you need and how often you need it, we'll then have a better understanding of:
            </p>
            <ul className="nhsuk-list nhsuk-list--bullet">
                <li>its levels of identifiability</li>
                <li>its legal basis</li>
                <li>the requested data-years</li>
                <li>the requested data-fields</li>
                <li>data minimisation </li>
            </ul>
            <p>We must ensure that the requested type of data aligns with your reason for requesting it and meets the requirement to process data lawfully, fairly and transparently. </p>
            <table className="nhsuk-table section-list-no-border">
                <tbody className="nhsuk-table__body">

                    {checkEnable(controllerSection.Participants) && <tr className="nhsuk-table__row">
                        <td className="nhsuk-table__cell">
                            <a href="#" onClick={handleClickParticipant}>About the participants</a>
                        </td>
                        <td className="nhsuk-table__cell">
                            {String(taskListStatus.Participants) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.Participants) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)}
                        </td>
                    </tr>}
                    {checkEnable(controllerSection.aboutData) && <tr className="nhsuk-table__row">
                        <td className="nhsuk-table__cell">
                            {(!sectionStatus.aboutthedata || !readyToProceed1c) ? "About the data" : <a href="#" onClick={handleClickDataSet}>About the data</a>}
                        </td>
                        <td className="nhsuk-table__cell">
                            {readyToProceed1c ? (String(taskListStatus.aboutData) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag>
                                : (String(taskListStatus.aboutData) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag>
                                    : String(taskListStatus.aboutData) === String(taskListSectionStatus.cannotStartYet) ? <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>
                                        : (String(taskListStatus.aboutData) === String(taskListSectionStatus.actionNeeded)) ? <Tag color="orange" className="nhsuk-float-right">ACTION NEEDED</Tag>
                                            : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)) : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>}
                        </td>
                    </tr>}
                    {checkEnable(controllerSection.EthicsApproval) && <tr className="nhsuk-table__row">
                        <td className="nhsuk-table__cell"><a href="#" onClick={(e) => handleClickDSATask(e, getEthicsApproval)}>Ethics Approval</a></td>
                        <td className="nhsuk-table__cell">
                            {String(taskListStatus.EthicsApproval) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.EthicsApproval) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)}

                        </td>
                    </tr>}
                </tbody>
            </table>
        </>
    )
}

export default DataAndParticipant;