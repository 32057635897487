import { InsetText, Button, Radios, Fieldset } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import GoBack from "../../../../Components/Shared/GoBack";
import { GetApplicationReasonList_URL, GetDSADetails_URL } from "../../../../config/api-endpoints.config";
import { getCookie, calculateDuration, fetchInterceptor, submitSpinner } from "../../../../Helper/Utility";
import { IApiResDSADetails, IApiResDSPTStstus, IApplication, IDSASummary, IDsptLatestStatus, IDsptLatestStatuses } from "../../../../state/models/app.interface";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { dsaContent, dsaSummaryErrorMessage, fileTypeSize, routeName, dataRequestReason } from "../../../../config/page.config";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import { getDSASummaryDetails, setDSASummaryDetailsData } from "../../../../state/slice/DSASummary/DSASummary.slice";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";

const DataRequestReason: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [loading, setLoading] = useState(false);
    const dispatch: AppDispatch = useAppDispatch();
    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};
    const dsaSummaryDetailsData: IDSASummary = useAppSelector(getDSASummaryDetails);
    const initialState: IDSASummary = dsaSummaryDetailsData || {};
    const [dsaSummaryData, setDsaSummaryData] = useState(initialState);
    const saveDSASummaryDataInStore = (stateData?: IDSASummary) => {
        dispatch(setDSASummaryDetailsData({ ...dsaSummaryData, ...stateData }));
    }
    const initialStateOfDsptLatestStatuses: IDsptLatestStatuses = {};
    const [dsptLatestStatusesData, setDsptLatestStatusesData] = useState(initialStateOfDsptLatestStatuses);

    const [dataRequestReasonErrorMessage, setDataRequestReasonErrorMessage] = useState("");

    const GetApplicationReasonList = () => {
        setLoading(true);
        const parameters: string = JSON.stringify(authenticationTokenCookie);
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetApplicationReasonList_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                SetApplicationReasonList(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const SetApplicationReasonList = (applicationReasonListResponse: IApiResDSPTStstus[]) => {
        let updatedState: IDsptLatestStatuses = {};
        let dsptLatestStatus: IDsptLatestStatus = {};
        let dsptLatestStateList: IDsptLatestStatus[] = [];
        applicationReasonListResponse.map((status: IApiResDSPTStstus) => {
            if (String(status.Value) !== String(dataRequestReason.submissionofAdvice)) {
                dsptLatestStatus = {
                    name: status.Name,
                    value: status.Value
                }
                dsptLatestStateList.push(dsptLatestStatus);
            }
        });
        updatedState = {
            dsptLatestStatus: dsptLatestStateList
        }
        setDsptLatestStatusesData(updatedState);
    }

    const GetDSADetailsinput = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetDSADetails_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setDSADetailsinput(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setDSADetailsinput = (response: IApiResDSADetails) => {
        let dsaSummary: IDSASummary = {};
        if (response) {
            dsaSummary.title = response.Title;
            dsaSummary.applicationReasonSelected = response.ApplicationReasonSelected ? response.ApplicationReasonSelected : dsaSummaryData.applicationReasonSelected;
            dsaSummary.dsaStartDate = response.DSAStartDate;
            dsaSummary.dsaEndDate = response.DSAEndDate;
            dsaSummary.duration = calculateDuration(response.DSAStartDate!, response.DSAEndDate!);
            dsaSummary.dsaDay = response.DSAStartDate?.split("/")[0] ? response.DSAStartDate?.split("/")[0] : "";
            dsaSummary.dsaMonth = response.DSAStartDate?.split("/")[1] ? response.DSAStartDate?.split("/")[1] : "";
            dsaSummary.dsaYear = response.DSAStartDate?.split("/")[2] ? response.DSAStartDate?.split("/")[2] : "";
        }
        setDsaSummaryData(dsaSummary);
        saveDSASummaryDataInStore(dsaSummary);
        setLoading(false);
    }

    const navigateDataRequest = () => { navigate(routeName.dataRequestProject); }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, navigateDataRequest);

        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const validateFields = () => {
        let isValid: boolean = true;
        if (!dsaSummaryData.applicationReasonSelected) {
            setDataRequestReasonErrorMessage(`${dsaSummaryErrorMessage.selectDataRequestReason_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const applicationReasonSelected: number = Number((e.target.value).split(` `)[0]);
        let updatedState: IDSASummary = {};
        updatedState = {
            ...dsaSummaryData,
            applicationReasonSelected: applicationReasonSelected
        }
        saveDSASummaryDataInStore(updatedState);
        setDsaSummaryData(updatedState);
        resetError(e);
    }

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setDataRequestReasonErrorMessage("");
        }
    }

    useEffect(() => {
        GetApplicationReasonList();
        GetDSADetailsinput();
    }, []);

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">

                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    Data request reason and project name</span>
                                    Data request reason
                                </h1>
                                {dataRequestReasonErrorMessage !== "" && <ErrorDetails errorDescription={dataRequestReasonErrorMessage} errorHref="#reasonRequest"></ErrorDetails>}

                                <InsetText>
                                    <p>
                                        Select 'Research (other)' only if your data request is neither HDRUK nor NIHR funded.
                                    </p>
                                </InsetText>

                                <Fieldset>
                                    <Radios
                                        name="DSPT_Latest_Status"
                                        id="reasonRequest"
                                        hint="Confirm the reason for your data request"
                                        error={dataRequestReasonErrorMessage}
                                    >
                                        {dsptLatestStatusesData.dsptLatestStatus?.map((status: IDsptLatestStatus, index: number = 0) => (
                                            <Radios.Radio
                                                name={dsaContent.dsptStatusField}
                                                key={index + 1}
                                                value={status.value}
                                                onChange={handleChange}
                                                checked={String(dsaSummaryData.applicationReasonSelected ? dsaSummaryData.applicationReasonSelected : "") === String(status.value)}
                                            >
                                                {status.name}
                                            </Radios.Radio>
                                        ))
                                        }
                                    </Radios>
                                </Fieldset>
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>
                        }
                    </div>
                </main>
            </div>

        </>)
}

export default DataRequestReason;