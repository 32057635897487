import { Details, InsetText, SummaryList, Tag } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from '../../../Components/Shared/ControllerHeader';
import { useAppSelector } from "../../../state/app/hooks";
import { IApplication, IDataSet, IDataSetList, IHistoricAnnualData, ISecurityAssurance, IDataSetFieldList, IParticipant, IDataSetFieldGroupList, IFunding, IFundingList, IParticipantLegalBasis, IParticipantContributor, IPurpose, IEvidenceList, IEvidence, IDSASummary, ISubmitApplication, IPrivacyNotice, IProcessAndStorageLocations, IObjectKeys, ISectionStatus, IApiResEvidence, IApiResEvidenceList, IApiResControllerList, IApiResController, IApiResDataSetFieldList, IApiResFundingSourceList, IApiResEthicsApprovalDetails, IApiResPrivacyNotice, IApiResLocationDetailsList, IApiResLocationDetails, IApiResDSADetails, IApiResSectionList, IApiResOutputsAndBenefits, ITaskEnableSectionMultiSelect, ISectionGroup, IGroupsectionflag, IApiResObjectiveAndProcessing, IApiResYieldedBenefits, IYieldedBenefits, IAPiResParticipantDetails, IApiResParticipantLegalBasis, IHolder } from "../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../state/slice/DSA/DSA.slice";
import { CheckAssociatedProductExist_URL, CheckCohortExist_URL, CheckFundingSourceExist_URL, GetDataControllerCount_URL, GetDataController_URL, GetOutputsAndBenefits_URL, GetObjectiveAndProcessing_URL, GetEvidenceList_URL, GetEthicsApproval_URL, GetCommercialPurposeAndSublicensing_URL, GetPrivacyNotice_URL, GetProcessingStorageLocations_URL, SubmitScreenDSAIntro_URL, GetDataProcessorCount_URL, GetYieldedBenefits_URL, GetCohortData_URL } from "../../../config/api-endpoints.config";
import { downloadPdfFile, getCookie, getDate, getKeyByValue, isCanNotStartYet, isCompleted, isNotStarted, setGeneratedBlobUrl, fetchInterceptor, isInProgress, loadSpinner, enableStatus, checkApplicationChangeType, checkStatusForAmend,resetSecurityAssuranceState } from '../../../Helper/Utility';
import { IController, IControllerList, IProcessor, IAutoComplete, IProcessorList } from "../../../state/models/app.interface";
import { AppDispatch } from '../../../state/app/store';
import { useAppDispatch } from '../../../state/app/hooks';
import { getControllerDetails, setControllerDetailsData } from "../../../state/slice/DSAAboutController/DSAAboutController.slice";
import { getProcessorDetails, setProcessorDetails } from "../../../state/slice/Processor/Processor.slice";
import { controllerSection, dataControllerTypeOptions, dsaContent, ProcessAndStorageLocationsValue, routeName, taskListSectionStatus, amendRenewExtendValue, sectionStatusTaskList, applicationChangeType, fileTypeSize, sectionGroupListConfig, initialStateOfFileUpload, applicationStatus, sectionOptions } from "../../../config/page.config";
import { getControllerListDetails, setControllerListDetailsData } from "../../../state/slice/ControllerList/ControllerList.slice";
import { getProcessorListDetails, setProcessorListDetailsData } from "../../../state/slice/ProcessorList/ProcessorList.slice";
import Loader from "../../../Components/Loader/Loader";
import { getSecurityAssuranceDetails, setSecurityAssuranceDetailsData } from "../../../state/slice/SecurityAssurance/SecurityAssurance.slice";
import { getDataSetDetails, setDataSetDetailsData } from "../../../state/slice/DataSet/DataSet.slice";
import { getDataSetListDetails, setDataSetListDetailsData } from "../../../state/slice/DataSetList/DataSetList.slice";
import { getParticipantDetails, setParticipantDetailsData } from "../../../state/slice/Participant/Participant.slice";
import { getFundingDetails, setFundingDetailsData } from "../../../state/slice/Funding/Funding.slice";
import { getFundingListDetails, setFundingListDetailsData } from "../../../state/slice/FundingList/FundingList.slice";
import { getPurposeDetails, setPurposeDetailsData } from "../../../state/slice/Purpose/Purpose.slice";
import { getEvidenceList, setEvidenceListData } from "../../../state/slice/EvidenceList/EvidenceList.slice";
import { getDSASummaryDetails, setDSASummaryDetailsData } from "../../../state/slice/DSASummary/DSASummary.slice";
import { getEvidenceDetails, setEvidenceDetailsData, resetEvidenceData } from "../../../state/slice/Evidence/Evidence.slice";
import { getProcessingStorageLocationsDetails, resetProcessingStorageLocationsData, setProcessingStorageLocationsData } from "../../../state/slice/ProcessingStorageLocations/ProcessingStorageLocations.slice";
import { getSubmitApplicationDetailsData, setSubmitApplicationDetailsData } from "../../../state/slice/SubmitApplication/SubmitApplication.slice";
import { getPrivacyNoticeDetails, setPrivacyNoticeDetailsData, resetPrivacyNoticeData } from "../../../state/slice/PrivacyNotice/PrivacyNotice.slice";
import GoBack from "../../../Components/Shared/GoBack";
import { resetDeleteDocumentData } from "../../../state/slice/shared/DeleteDocument/DeleteDocument.slice";
import GetHelp from "./GetHelp";
import ApplicationOverview from "./SectionList/ApplicationOverview";
import DataAndParticipant from "./SectionList/DataAndParticipant";
import ApplyingForData from "./SectionList/ApplyingForData";
import OrganisationsInvolved from "./SectionList/OrganisationsInvolved";
import { getYieldedBenefitsDetails, setYieldedBenefitsDetailsData } from "../../../state/slice/YieldedBenefits/YieldedBenefits.slice";

const DSATaskList: React.FC = () => {
    const navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();

    const initialControllerListState: IControllerList = useAppSelector(getControllerListDetails) || {};
    const [controllerListData, setControllerListData] = useState(initialControllerListState);
    const initialProcessorListState: IProcessorList = useAppSelector(getProcessorListDetails) || {};
    const [processorListData, setProcessorListData] = useState(initialProcessorListState);
    const initialApplicationState: IApplication = useAppSelector(getDSADetails) || {};
    const [dsaApplicationData, setdsaApplicationData] = useState(initialApplicationState || {});
    const initialControllerState: IController = useAppSelector(getControllerDetails) || {};
    const [controllerData, setcontrollerData] = useState(initialControllerState);
    const initialProcessorState: IProcessor = useAppSelector(getProcessorDetails) || {};
    const [processorData, setProcessorData] = useState(initialProcessorState);
    const initialDataSetState: IDataSet = useAppSelector(getDataSetDetails) || {};
    const [dataSetData, setDataSetData] = useState(initialDataSetState);
    const dataSetListData: IDataSetList = useAppSelector(getDataSetListDetails) || {};
    const fundingData: IFunding = useAppSelector(getFundingDetails) || {}
    const [purposeData, setPurposeData] = useState(useAppSelector(getPurposeDetails) || {});
    const [yieldedBenefitsData, setYieldedBenefitsData] = useState(useAppSelector(getYieldedBenefitsDetails) || {});
    const participantData: IParticipant = useAppSelector(getParticipantDetails) || {};
    const fundingListData: IFundingList = useAppSelector(getFundingListDetails) || {};
    const evidenceinitialState: IEvidenceList = useAppSelector(getEvidenceList) || {};
    const [evidenceListDetails, setEvidenceListDetails] = useState(evidenceinitialState);
    const dsaSubmitApplicationData: ISubmitApplication = useAppSelector(getSubmitApplicationDetailsData) || {};
    const initialDSAsummaryData: IDSASummary = useAppSelector(getDSASummaryDetails) || {};
    const [dsaSummaryData, setDSASummaryData] = useState(initialDSAsummaryData);
    const initiailSecurityAssuranceState: ISecurityAssurance = useAppSelector(getSecurityAssuranceDetails) || {};
    const [securityAssuranceData, setSecurityAssuranceData] = useState(initiailSecurityAssuranceState)
    const [applicationChangeTypeValue,] = useState(dsaApplicationData.applicationChangeType)
    const [taskEnableSectionMultiSelect, setTaskEnableSectionMultiSelect] = useState<IApiResSectionList[]>([]);

    //tasklist statuses
    const [sectionStatus, setDefaultSectionStatus] = useState(sectionStatusTaskList);
    const [sectionList, setSectionList] = useState<string[]>([]);
    const [sectionGroupList, setSectionGroupList] = useState<IGroupsectionflag>([]);
    const [filteredSectionList, setFilteredSectionList] = useState<IGroupsectionflag>([]);

    const saveYieldedBenefitsDataInStore = (stateData?: IYieldedBenefits) => {
        dispatch(setYieldedBenefitsDetailsData({ ...yieldedBenefitsData, ...stateData }));
    }
    const saveDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }
    const saveDataListInStore = (stateData?: IDataSetList) => {
        dispatch(setDataSetListDetailsData({ ...dataSetListData, ...stateData }));
    }
    const saveParticipantDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantData, ...stateData }));
    }
    const saveFundingDataInStore = (stateData?: IFunding) => {
        dispatch(setFundingDetailsData({ ...fundingData, ...stateData }));
    }
    const saveFundingListInStore = (stateData?: IFundingList) => {
        dispatch(setFundingListDetailsData({ ...fundingListData, ...stateData }));
    }
    const saveEvidenceListDataInStore = (stateData?: IEvidenceList) => {
        dispatch(setEvidenceListData({ ...evidenceListDetails, ...stateData }));
    }
    const saveSubmitApplicationDataInStore = (stateData?: ISubmitApplication) => {
        dispatch(setSubmitApplicationDetailsData({ ...dsaSubmitApplicationData, ...stateData }));
    }
    const saveControllerDataInStore = (stateData?: IController) => {
        dispatch(setControllerDetailsData({ ...controllerData, ...stateData }));
    }
    const saveControllerListDataInStore = (stateData?: IControllerList) => {
        dispatch(setControllerListDetailsData({ ...controllerListData, ...stateData }));
    }
    const saveProcessorListDataInStore = (stateData?: IProcessorList) => {
        dispatch(setProcessorListDetailsData({ ...processorListData, ...stateData }));
    }
    const saveProcessorDataInStore = (stateData?: IProcessor) => {
        dispatch(setProcessorDetails({ ...processorData, ...stateData }));
    }
    const savePurposeDataInStore = (stateData?: IPurpose) => {
        dispatch(setPurposeDetailsData({ ...purposeData, ...stateData }));
    }
    const saveDSASummaryDataInStore = (stateData?: IDSASummary) => {
        dispatch(setDSASummaryDetailsData({ ...dsaSummaryData, ...stateData }));
    }
    const saveSecurityAssuranceDataInStore = (stateData?: ISecurityAssurance) => {
        dispatch(setSecurityAssuranceDetailsData({ ...securityAssuranceData, ...stateData }));
    }
    const resetDeleteDocumentInStore = () => {
        dispatch(resetDeleteDocumentData());
    };

    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    const initialStatePrivacyNotice: IPrivacyNotice = useAppSelector(getPrivacyNoticeDetails) || {};
    const [privacyNoticeData] = useState(initialStatePrivacyNotice)
    const savePrivacyNoticeDataInStore = (stateData?: IPrivacyNotice) => {
        dispatch(setPrivacyNoticeDetailsData({ ...privacyNoticeData, ...stateData }));
    }
    const processingStorageLocationsData = useAppSelector(getProcessingStorageLocationsDetails);
    const saveProcessingStorageLocationsDataInStore = (stateData?: IProcessAndStorageLocations) => {
        dispatch(setProcessingStorageLocationsData({ ...processingStorageLocationsData, ...stateData }));
    }

    const evidenceInitialStateData: IEvidence = useAppSelector(getEvidenceDetails) || {};
    const [evidenceData] = useState(evidenceInitialStateData);
    const saveEvidenceDataInStore = (stateData?: IEvidence) => {
        dispatch(setEvidenceDetailsData({ ...evidenceData, ...stateData }));
    }

    let groupsectionflag: IGroupsectionflag = [];
    let updateTrueflag: IGroupsectionflag = [];
    sectionGroupListConfig.ParentSection.forEach((section: ISectionGroup) => {
        groupsectionflag.push({ name: section.SectionName, flag: false });
    });

    const defaultStatues: ISectionStatus = {
        aboutController: controllerData.aboutControllerSectionStatus || dsaApplicationData.aboutControllerSectionStatus,
        aboutProcessor: processorData.aboutProcessorSectionStatus || dsaApplicationData.aboutProcessorSectionStatus,
        aboutData: dataSetData.aboutDataSectionStatus || dsaApplicationData.aboutDataSetSectionStatus,
        Participants: participantData.aboutParticipantSectionStatus || dsaApplicationData.aboutParticipantSectionStatus,
        ExpectedOutputsAndBenefits: purposeData.aboutExpectedOutputsAndBenefitsSectionStatus || dsaApplicationData.aboutExpectedOutputsAndBenefitsSectionStatus,
        ObjectiveAndProcessingActivities: purposeData.aboutObjectiveAndProcessingSectionStatus || dsaApplicationData.aboutObjectiveAndProcessingSectionStatus,
        CommercialPurposesAndSublicensing: purposeData.aboutCommercialSublicensingSectionStatus || dsaApplicationData.commercialSublicensingSectionStatus,
        ProjectTitleReason: dsaSummaryData.aboutDSASummarySectionStatus || dsaApplicationData.projectTitleReasons,
        PrivacyNotice: privacyNoticeData.aboutPrivacyNoticeStatus || dsaApplicationData.aboutPrivacyNoticeSectionStatus,
        ProcessingLocations: dsaApplicationData.aboutProcessingLocationSectionStatus,
        StorageLocations: dsaApplicationData.aboutStorageLocationSectionStatus,
        Funding: fundingListData.aboutFundingSectionStatus || dsaApplicationData.aboutFundingSectionStatus,
        SubmitApplication: dsaSubmitApplicationData.aboutSubmitApplicationSectionStatus || dsaApplicationData.aboutSubmitApplicationSectionStatus,
        EthicsApproval: evidenceData.aboutEthicsApprovalStatus || dsaApplicationData.aboutEthicsApprovalSectionStatus,
        ListOfUploadedDocuments: evidenceListDetails.aboutEvidenceSectionStatus || dsaApplicationData.aboutEvidenceSectionStatus,
        YieldedBenefits: yieldedBenefitsData.aboutYieldedBenefits || dsaApplicationData.aboutYieldedBenefitsSectionStatus
    }

    const [taskListStatus, setTaskListStatus] = useState(updateDefaultStatus(defaultStatues));

    const [loading, setLoading] = useState(true);
    const [blobUrl, setBlobUrl] = useState('');
    const [downloadClicked, setDownloadClicked] = useState(false);
    const abortController = new AbortController();

    /*.........Handle Click..................... */

    function updateDefaultStatus(defaultStatues: any) {
        for (const task in defaultStatues) {
            if (defaultStatues[task] === 1001 && applicationChangeTypeValue !== undefined && String(applicationChangeTypeValue) !== applicationChangeType.new) {
                defaultStatues[task] = 1005;
            } else {
                defaultStatues[task] = defaultStatues[task];
            }
        }
        return defaultStatues;
    }

    const handleClickDSATask = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, callBack: (target?: HTMLElement) => void) => {
        updatePageNavigation();
        e.preventDefault();
        const target = e.target as HTMLElement;
        target.classList.add("nhsuk-link-spinner", "nhsuk_download_spinner");
        callBack && callBack(target);
    }
    const getPrivacyNotice = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: parameters
        };
        fetchInterceptor(GetPrivacyNotice_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setPrivacyNoticeDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setPrivacyNoticeDetails = (response: IApiResPrivacyNotice) => {
        if (response && response !== undefined) {
            let updatedState: IPrivacyNotice = {};
            updatedState.doesControllerEnsureCompliance = response.DoesControllerEnsureCompliance;
            updatedState.explanationForNonCompliance = response.ExplanationForNonCompliance;
            savePrivacyNoticeDataInStore(updatedState);
            navigate(routeName.privacyNotice);
        }
    }
    const handleClickController = (e: React.MouseEvent<HTMLAnchorElement>) => {
        updatePageNavigation();
        loadSpinner(e);
        getControllerDetailsData();
    }

    const updatePageNavigation = () => {
        let updateState: IApplication = {};
        updateState = {
            ...dsaApplicationData,
            returningJourneyType: getReturningJourneyType(),
        }
        setdsaApplicationData(updateState);
        saveApplicationDataInStore(updateState);
    }

    const handleClickDataSet = (e: React.MouseEvent<HTMLAnchorElement>) => {
        updatePageNavigation();
        loadSpinner(e);
        getDataSetDetailsData();
    }

    const handleClickParticipant = (e: React.MouseEvent<HTMLAnchorElement>) => {
        updatePageNavigation();
        loadSpinner(e);
        getParticipantDetailsData();
    }

    const handleClickObjectiveAndProcessing = (e: React.MouseEvent<HTMLAnchorElement>) => {
        updatePageNavigation();
        loadSpinner(e);
        getObjectiveAndProcessing();
    }

    const handleClickYieldedBenefits = (e: React.MouseEvent<HTMLAnchorElement>) => {
        updatePageNavigation();
        loadSpinner(e);
        getYieldedBenefits();
    }

    const handleDataRequestReason = () => {
        navigate(routeName.dataRequestReason);
    }

    const handleClickExpectedOutputs = (e: React.MouseEvent<HTMLAnchorElement>) => {
        updatePageNavigation();
        loadSpinner(e);
        getOutputsAndBenefits();
    }

    const handleClickDataRequestReason = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        updatePageNavigation();
        const target = e.target as HTMLElement;
        target.classList.add("nhsuk-link-spinner", "nhsuk_download_spinner");
        navigate(routeName.dataRequestReason);
    }

    const handleClickSubmitApplication = (e: React.MouseEvent<HTMLAnchorElement>) => {
        updatePageNavigation();

        loadSpinner(e);
        navigate(routeName.declaration);
    }

    const handleClickEvidence = (e: React.MouseEvent<HTMLAnchorElement>) => {
        updatePageNavigation();
        loadSpinner(e);
        getEvidenceListDetails();
    }
    const getEvidenceListDetails = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: parameters
        };
        fetchInterceptor(GetEvidenceList_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setEvidenceDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setEvidenceDetails = (data: IApiResEvidence) => {
        let participantEvidence: IEvidence = {};
        let evidenceList: IEvidence[] = [];
        let updateState: IEvidenceList = {}
        if (data.EvidenceList!.length > 0) {
            data.EvidenceList!.map((evidence: IApiResEvidenceList) => {
                participantEvidence = {
                    id: evidence.Id,
                    comments: evidence.Comments,
                    referenceNumber: evidence.ReferenceNumber,
                }
                evidenceList.push(participantEvidence)
            })
            updateState = {
                ...evidenceListDetails,
                evidenceList: evidenceList,
                isFromEvidence: true
            }
            setEvidenceListDetails(updateState);
            saveEvidenceListDataInStore(updateState);
            navigate(routeName.addAnotherApprovalEvidence)
        }
        else {
            navigate(routeName.uploadSupportingEvidence)
        }
    }

    const getEthicsApproval = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: parameters
        };
        fetchInterceptor(GetEthicsApproval_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setEthicsApprovalDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setEthicsApprovalDetails = (response: IApiResEthicsApprovalDetails) => {
        resetDataSetData();
        if (response && response !== undefined) {
            let updatedState: IEvidence = {};
            updatedState.isEthicsApproval = response.EthicsApprovalRequire;
            updatedState.ethicsApproval = response.EthicsApprovalInPlace;
            updatedState.noEthicsApproval = response.EthicsApprovalReason;
            updatedState.ethicsApprovalNotInPlace = response.EthicsApprovalPlaceReason;
            updatedState.evidenceApproval = response.EthicsApprovalRequire === true ? dsaContent.yes : dsaContent.no;
            updatedState.isEvidenceApproval = response.EthicsApprovalRequire === true ? true : false;
            updatedState.fileUploadEthics = response.FileList;
            updatedState.fileUploadActive = 0;
            updatedState.evidenceFileSummary = [];
            saveEvidenceDataInStore(updatedState);
            navigate(routeName.evidenceApproval);
            console.log(dataSetData.legalBasesIdentifiableData);
        }
    }

    /*....End Handle Click............... */

    /*................API Section.....................*/

    /* get the processor data count through an API call */
    const getDataProcessorCount = async () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        const response = await fetchInterceptor(GetDataProcessorCount_URL, requestOptions).then(response => {
            return response.json()
        });
        return response.HasDataProcessor;
    };

    /* Function to load when you click on 'About the Processor' from dsatasklist page */
    /* get the controller data through an API call */
    const getControllerData = ((target?: HTMLElement) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetDataController_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setControllerData(data, target!);
            })
            .catch(() => navigate(routeName.serviceError));
    });

    /* setting the controller data to state & store. navigating to the page with below logic:
     * 1- If atleast one processor is added, we will redirect to processdetails
     * 2- else If any controller is answered as 'Yes' for 'Is this controller processing the data', we will redirect to solely processor data
     * 3- If no controller is answered as 'Yes' for 'Is this controller processing the data', then redirects to processordata
     */
    const setControllerData = async (response: IApiResControllerList, target: HTMLElement) => {
        let updatedState: IControllerList = {};

        let dsaControllerDetail: IController = {};
        let dsaControllerDetailList: IController[] = [];

        if (response && response !== undefined && response.DataControllerList !== null) {
            response.DataControllerList.map((dsaController: IApiResController) => {
                dsaControllerDetail = {
                    controllerId: dsaController.DataControllerId,
                    isDirtyController: dsaController.IsControlleraProcessorDirty,
                    dpaRegistrationNumber: dsaController.DPARegisteredCode,
                    dpaOrganisationName: dsaController.DPARegisteredName,
                    dpaRegistrationExpiryDate: dsaController.DPARegisteredExpiry,
                    dpaId: dsaController.DpaId,
                    isControllerProcessingData: dsaController.IsControlleraProcessor,
                    controllerProcessingData: dsaController.IsControlleraProcessor ? dsaContent.controllerProcessingTheData : dsaContent.controllerNotProcessingTheData,
                    dsaApplicationId: dsaController.ApplicationId,
                    controllerdataOrgDesc: dsaController.OrganisationName,
                    controllerdataOrgGuidId: dsaController.OrganisationId,
                    isNewController: false,
                    isPayingForDSA: dsaController.IsPayingForDSA,
                    alternativeOrganisationName: dsaController.OrganisationName,
                    alternativeStreet1: dsaController.AddressLine1,
                    alternativeStreet2: dsaController.AddressLine2,
                    alternativeStreet3: dsaController.AddressLine3,
                    alternativeCity: dsaController.TownCity,
                    alternativeCounty: dsaController.County,
                    alternativePostCode: dsaController.Postcode,
                    alternativeCountry: dsaController.Country,
                    fileUpload: dsaController.FileList,
                    dpDay: dsaController.DPARegisteredExpiry?.split("/")[0] ? dsaController.DPARegisteredExpiry?.split("/")[0] : "",
                    dpMonth: dsaController.DPARegisteredExpiry?.split("/")[1] ? dsaController.DPARegisteredExpiry?.split("/")[1] : "",
                    dpYear: dsaController.DPARegisteredExpiry?.split("/")[2] ? dsaController.DPARegisteredExpiry?.split("/")[2] : "",
                    saList: dsaController.SecurityAssurance
                }
                dsaControllerDetailList.push(dsaControllerDetail);
            })

            updatedState = {
                ...controllerListData,
                controllerList: dsaControllerDetailList
            }
            setControllerListData(updatedState);
            saveControllerListDataInStore(updatedState);

            const filteredList = updatedState.controllerList?.filter(controller => controller.isControllerProcessingData === true) || [];
            //setControllerIsProccessorCount(filteredList.length);

            const hasDataProcessor = await getDataProcessorCount();
            if (hasDataProcessor) {
                let updatedProcessorList: IProcessorList = {
                    ...processorListData, processorDetailsPage: dsaContent.checkYourAnswersPageText
                }
                setProcessorListData(updatedProcessorList);
                saveProcessorListDataInStore(updatedProcessorList);
                navigate(routeName.processorDetails);
            } else {
                if (filteredList.length >= 1) {
                    navigate(routeName.solelyProcessor);
                } else {
                    navigate(routeName.processorData);
                }
            }

        }
        target.classList.remove("nhsuk-link-spinner", "nhsuk_download_spinner");
    }

    const getControllerDetailsData = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetDataControllerCount_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setControllerDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setControllerDetails = (response: IApiResController) => {
        if (response && response !== undefined && response.HasDataController === true) {                                    
            let updatedState: IControllerList = {
                ...controllerListData, controllerDetailsPage: dsaContent.checkYourAnswersPageText
            }
            saveControllerListDataInStore(updatedState);
            navigate(routeName.controllerDetails);
        }
        else {
            navigate(routeName.controllerData);
        }
    }

    const getDataSetDetailsData = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: parameters
        };
        fetchInterceptor(CheckAssociatedProductExist_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setDataSetDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setDataSetDetails = (response: IApiResDataSetFieldList) => {
        if (response && response !== undefined && response.HasAssociatedProduct === true) {
            navigate(routeName.datasetDetails);
        }
        else {
            navigate(routeName.datasetIntro);
        }
    }
    const getParticipantDetailsData = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "SectionId": sectionOptions.Participants
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetCohortData_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setDetails = (data: IAPiResParticipantDetails) => {
        if (data !== undefined && data !== null) {
            if (data.Journals.length > 0 && data.DataSetLegalBasisList.length > 0 && data.Cohort) {
                let participant: IParticipant = {};
                let participantlegal: IParticipantLegalBasis = {};
                let legalBasisList: IParticipantLegalBasis[] = [];
                //set the legalBasis List data for retrieving the filelist details.
                if (data.DataSetLegalBasisList.length > 0) {
                    let orderby = 0;
                    data.DataSetLegalBasisList.forEach((dsaApplication: IApiResParticipantLegalBasis) => {
                        if (dsaApplication.Checked && orderby === 0)
                            orderby = 1;
                        else if (dsaApplication.Checked)
                            orderby = orderby + 1;

                        participantlegal = {
                            value: dsaApplication.Value,
                            name: dsaApplication.Name,
                            checked: dsaApplication.Checked,
                            text: dsaApplication.Text,
                            crmId: dsaApplication.crmId,
                            processing: dsaApplication.Processing,
                            dissemination: dsaApplication.Dissemination,
                            otherLegalBasis: dsaApplication.OtherLegalBasis,
                            displayProcessing: dsaApplication.DisplayProcessing,
                            displayDissemination: dsaApplication.DisplayDissemination,
                            productLegalBasis: dsaApplication.ProductLegalBasis,
                            otherComments: dsaApplication.OtherComments,
                            orderBy: dsaApplication.OrderBy,
                            availableOnline: dsaApplication.AvailableOnline,
                            currentlyCheckedInCRM: dsaApplication.CurrentlyCheckedInCRM,
                            notes: dsaApplication.Notes,
                            sortBy: orderby
                        }
                        legalBasisList.push(participantlegal);

                    })
                }
                participant.dataSetLegalBasisList = legalBasisList;
                saveParticipantDataInStore(participant);
                navigate(routeName.participantSummary);
            }
            else { navigate(routeName.participantData); }
        }
    }


    const getObjectiveAndProcessing = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: parameters
        };
        fetchInterceptor(GetObjectiveAndProcessing_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data && data.ObjectiveForProcessing && data.ProcessingActivities)
                    navigate(routeName.objectiveProcessingCheckAnswer);
                else
                    navigate(routeName.objectiveProcessing);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const getYieldedBenefits = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: parameters
        };
        fetchInterceptor(GetYieldedBenefits_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setYieldedBenefits(data);
                navigate(routeName.yieldedBenefits);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setYieldedBenefits = (response: IApiResYieldedBenefits) => {
        let yieldedBenefits: IYieldedBenefits = {};
        if (response) {
            yieldedBenefits.yieldedBenefits = response.YieldedBenefits;
        }
        setYieldedBenefitsData(yieldedBenefits);
        saveYieldedBenefitsDataInStore(yieldedBenefits);
        setLoading(false);
    }

    /*Purpose Section*/
    const handleClickCommercialPurpose = (e: React.MouseEvent<HTMLAnchorElement>) => {
        updatePageNavigation();
        loadSpinner(e);
        if (String(taskListStatus.CommercialPurposesAndSublicensing) === String(taskListSectionStatus.completed) || String(taskListStatus.CommercialPurposesAndSublicensing) === String(taskListSectionStatus.inProgress)) {
            navigate(routeName.summaryCommercial);
        }
        else {
            const parameters: string = JSON.stringify({
                "UserAuthTokenId": authenticationTokenCookie,
                "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
            });
            const requestOptions: Object = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: parameters
            };
            fetchInterceptor(GetCommercialPurposeAndSublicensing_URL, requestOptions)
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    if (data && (data.ApplicationForCommercialPurposes || data.DoesSublicensingApply))
                        navigate(routeName.summaryCommercial);
                    else
                        navigate(routeName.commercial);
                })
                .catch(() => navigate(routeName.serviceError));
        }
    }
    /*Purpose Section*/
    const getOutputsAndBenefits = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: parameters
        };
        fetchInterceptor(GetOutputsAndBenefits_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data && data.SpecificOutputsExpected && data.ExpectedMeasurableBenefits) {
                    navigate(routeName.expectedCheckYourAnswer);
                }
                else {
                    setOutputsAndBenefits(data);
                }
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setOutputsAndBenefits = (response: IApiResOutputsAndBenefits) => {
        let purpose: IPurpose = {};
        if (response) {
            purpose.specificOutputsExpected = response.SpecificOutputsExpected;
            purpose.expectedMeasurableBenefits = response.ExpectedMeasurableBenefits;
            purpose.expectedCheckYourAnswerPage = "";
        }
        setPurposeData(purpose);
        savePurposeDataInStore(purpose);
        navigate(routeName.expectedOutputs);
    }
    /*...............End API section.................. */

    /*..........Reset Data................. */

    const resetControllerListData = () => {
        let updatedState: IControllerList = {};
        updatedState = {
            activeController: 0,
            controllerDetailsPage: "",
            controllerList: [controllerData]
        }
        setControllerListData(updatedState);
        saveControllerListDataInStore(updatedState);
    }

    const resetControllerData = () => {
        resetSecurityAssuranceData();
        let updatedState: IController = {};
        updatedState = {
            aboutControllerSectionStatus: "",
            isJointController: false,
            isSoleController: false,
            isCompleted: false,
            controllerId: "",
            isDirtyController: false,
            dpaRegistrationNumber: "",
            dpaOrganisationName: "",
            dpaRegistrationExpiryDate: "",
            isControllerProcessingData: false,
            dpDay: "",
            dpMonth: "",
            dpYear: "",
            fileUpload: [initialStateOfFileUpload],
            fileUploadActive: 0,
            dsaApplicationId: "",
            contractNumber: "",
            alternativeOrganisationName: "",
            alternativeStreet1: "",
            alternativeStreet2: "",
            alternativeStreet3: "",
            alternativeCity: "",
            alternativeCounty: "",
            alternativePostCode: "",
            alternativeCountry: "",
            controllerdataOrgGuidId: "",
            controllerdataOrgDesc: "",
            controllerIndex: 0,
            dpaId: "",
            controllerProcessingData: "",
            isNewController: false,
            saList: {},
            temporaryFileList: [initialStateOfFileUpload],
            evidenceFileSummary: []
        }
        setcontrollerData(updatedState);
        saveControllerDataInStore(updatedState);
    }

    const resetProcessorData = () => {
        resetSecurityAssuranceData();
        let updatedState: IProcessor = {};
        updatedState = {
            aboutProcessorSectionStatus: "",
            isCompleted: false,
            processorId: "",
            isDirtyProcessor: false,
            fileUpload: [initialStateOfFileUpload],
            dpDay: "",
            dpMonth: "",
            dpYear: "",
            dsaApplicationId: "",
            contractNumber: "",
            alternativeOrganisationName: "",
            alternativeStreet1: "",
            alternativeStreet2: "",
            alternativeStreet3: "",
            alternativeCity: "",
            alternativeCounty: "",
            alternativePostCode: "",
            alternativeCountry: "",
            processordataOrgDesc: "",
            processordataOrgGuidId: "",
            dpaRegistrationNumber: "",
            dpaOrganisationName: "",
            dpaRegistrationExpiryDate: "",
            listOrganisations: [initialStateOfProcessorOrg],
            isNewProcessor: false,
            dpaId: "",
            saList: {},
            temporaryFileList: [initialStateOfFileUpload],
            fileUploadActive: 0,
            evidenceFileSummary: []
        }
        setProcessorData(updatedState);
        saveProcessorDataInStore(updatedState);
    };

    const resetProcessorListData = () => {
        let updatedState: IProcessorList = {};
        updatedState = {
            activeProcessor: 0,
            processorDetailsPage: "",
            processorList: [processorData]
        }
        saveProcessorListDataInStore(updatedState);
    }

    const resetSecurityAssuranceData = () => {
        let updatedStateSA = resetSecurityAssuranceState();
        setSecurityAssuranceData(updatedStateSA);
        saveSecurityAssuranceDataInStore(updatedStateSA);
    }

    const initialStateOfProcessorOrg: IAutoComplete = {
        id: "",
        name: ""
    }

    const setFundingSourceDetailsData = (response: IApiResFundingSourceList) => {
        if (response && response !== undefined && response.HasFundingSource === true) {
            navigate(routeName.uploadDocumentDetails);
        }
        else {
            navigate(routeName.fundingType);
        }
    }

    const resetDataSetListData = () => {
        let filteredDataSetList: IDataSet[];
        const dataSetList = dataSetListData.associatedProduct ? dataSetListData.associatedProduct : [];
        filteredDataSetList = dataSetList.filter((dataset: IDataSet) => dataset.crmAssociatedProductId !== dataSetData.crmAssociatedProductId)
        saveDataListInStore({ ...dataSetListData, associatedProduct: (filteredDataSetList as [IDataSet]), activeDataSet: 0 });
    }

    const resetDataSetData = () => {
        let updatedState: IDataSet = {}
        updatedState = {
            aboutDataSectionStatus: "",
            dataDissemination: false,
            serviceType: "",
            description: "",
            crmUID: "",
            crmProductId: "",
            crmAssociatedProductId: "",
            name: "",
            aggregatedOrRecordLevelData: "",
            linkedToCohort: false,
            datasetFrequency: "",
            historicAnnualDataList: [initialStateHistoricData],
            serviceTypeOneOff: false,
            serviceTypeRegularBespoke: false,
            serviceTypeRegularStandard: false,
            serviceTypeSystemAccess: false,
            standardisationServiceLevelOptionsSelected: "",
            datasetCohort: "",
            selectedProductFieldsList: [initialStateFieldGroupList],
            firstPointer: 0,
            selectedPaginationPage: 0,
            updatedDaily: false,
            annualPeriodsExist: false,
            currentYTDExists: false,
            latestRelease: false,
            futureRelease: false,
            historicAnnual: false,
            initialHistoricAnnualDataList: [initialStateHistoricData],
            groupByFieldsList: [initialStateGroupFieldList],
            currentCRMSelectedDatasetFields: [initialStateFieldGroupList],
            existingHistoricAnnualDataList: [initialStateHistoricData],
            stepsMinimiseDataRequested: "",
            identifiableData: "",
            legalBasesIdentifiableData: "",
            dataSetLegalBasesList: [initialParticipantLegalBasis],
            currentDataSetLegalBasesList: [initialParticipantLegalBasis],
            dataSetSelectedLegalBasesOtherText: "",
            temporaryFutureRelease: false,
            temporaryHistoricAnnual: false,
            temporaryLatestRelease: false,
            temporaryHistoricAnnualDataList: [initialStateHistoricData]
        }
        saveDataInStore(updatedState);
    }

    const initialStateFieldGroupList: IDataSetFieldList = {
        id: "",
        groupFieldId: "",
        groupName: "",
        fieldName: "",
        fieldType: "",
        fieldLogicalName: "",
        sensitiveFlag: false,
        identifiableFlag: false,
        isGroupField: false,
        isPrimaryField: false,
        description: "",
        startDate: "",
        endDate: "",
        groupType: ""
    }

    const initialStateGroupFieldList: IDataSetFieldGroupList = {
        groupName: "",
        groupId: "",
        groupFieldList: [initialStateFieldGroupList]
    }

    const initialStateHistoricData: IHistoricAnnualData = {
        period: "",
        fileAvailabilityId: "",
        periodValue: "",
        displayText: "",
        selected: false,
        periodAreadyMappedToFileAvailabilityInCRM: false
    }

    const initialParticipantContributor: IParticipantContributor = {
        id: "",
        firstName: "",
        lastName: "",
        role: "",
        organisationName: "",
        email: "",
        makeValidation: "",
        mobile: 0,
        organisation: "",
        organisationId: "",
        userType: 0
    }

    const initialParticipantLegalBasis: IParticipantLegalBasis = {
        value: "",
        crmId: "",
        text: "",
        checked: false,
        name: "",
        availableOnline: false,
        currentlyCheckedInCRM: false,
        displayDissemination: false,
        displayProcessing: false,
        dissemination: false,
        notes: "",
        orderBy: 0,
        otherComments: "",
        otherLegalBasis: false,
        processing: false,
        productLegalBasis: false,
    }

    const resetParticipantData = () => {
        let updatedState: IParticipant = {}
        updatedState = {
            participantTypes: "",
            aboutParticipantSectionStatus: "",
            legalBasis_Other: "",
            dataProviderFirstName: "",
            dataProviderLastName: "",
            dataProviderRole: "",
            dataProviderOrganisationName: "",
            dataProviderOrganisationId: "",
            dataProviderEmail: "",
            dataProviderConfirmEmail:"",
            participantContributorList: [initialParticipantContributor],
            participantLegalBasisList: [initialParticipantLegalBasis],
            additionalLinkageCohortInformation: "",
            addressDate: false,
            applicationId: "",
            dataAsAtDate: false,
            dataProductionSubmissionSelected: 0,
            dataSetLegalBasisList: [initialParticipantLegalBasis],
            dataSetSelectedLegalBasisOtherText: "",
            dateOfBirth: false,
            dateOfDeath: false,
            emailAddress: false,
            existingAgreementUploadItems: [""],
            forename: false,
            gpPracticeCode: false,
            howManyValidationCredits: 0,
            journals: [initialParticipantContributor],
            journalToAdd: "",
            manualCohortValidation: false,
            maximumSize: 0,
            minimumSizeCohortValidationRate: 0,
            mobilePhoneNumber: false,
            nhsNumber: false,
            ongoingRecruitment: false,
            otherGivenName: false,
            postcode: false,
            productionCommence: "",
            refIfUsingAnotherExistingAgreement: "",
            sex: false,
            studyId: false,
            surname: false,
            telephoneNumber: false,
            typeOfCohort: 0,
            userAuthTokenId: "",
            participantcheckYourAnswerPage: '',
            fileUploadConsent: [initialStateOfFileUpload],
            fileUploadNHS: [initialStateOfFileUpload],
            fileCommentConsent: '',
            fileCommentNHS: '',
            isFromParticipant: false,
            isParticipantProvidingData: false,
            participantProvidingData: '',
            deleteParticipantId: '',
            recentlyAddedConsentId: '',
            recentlyAddedNHSHealthId: '',
            isAllLegalBasisAdded: false,
            deleteEvidenceId: '',
            fileUploadActive: 0,
            temporaryFileList: [initialStateOfFileUpload],
            isFromAddAnotheFileRecord: false,
            evidenceFileSummary: []
        }
        setParticipantDetailsData(updatedState);
        saveParticipantDataInStore(updatedState);
    }

    const resetFundingListData = () => {
        let filteredfundingList: IFunding[];
        const fundingLists = fundingListData.fundingList ? fundingListData.fundingList : [];
        filteredfundingList = fundingLists.filter((funding: IFunding) => funding.sourceId !== fundingData.sourceId)
        saveFundingListInStore({ ...fundingListData, fundingList: (filteredfundingList as [IFunding]), activefundingData: 0, aboutFundingSectionStatus: "", isFromFundingSummary: false });
    }

    const resetFundingData = () => {
        let updatedState: IFunding = {}
        updatedState = {
            fundingType: "",
            aboutFundingSectionStatus: "",
            fileUpload: [initialStateOfFileUpload],
            IsFundingSourceDirty: false,
            IsFundingFileUploadDirty: false,
            sourceId: "",
            fundingAwardingInstruction: "",
            referenceTitle: "",
            yearOfSubmissionDay: "",
            yearOfSubmissionMonth: "",
            yearOfSubmissionYear: "",
            uploadedFile: [initialStateOfFileUpload],
            recentlyAddedFunndingId: '',
            fileUploadActive: 0,
            temporaryFileList: [initialStateOfFileUpload],
            evidenceFileSummary: []
        }
        saveFundingDataInStore(updatedState);
    }

    const initialEvidence: IEvidence = {
        id: "",
        referenceNumber: "",
        comments: "",
        approvalsEvidenceType: "",
        fileUploadEthics: [initialStateOfFileUpload],
        temporaryFileList: [initialStateOfFileUpload],
        fileUploadActive: 0,
        evidenceFileSummary: []
    }

    const resetEvidenceList = () => {
        let updatedState: IEvidenceList = {}
        updatedState = {
            selectedType: '',
            evidenceList: [initialEvidence],
            fileUpload: [initialStateOfFileUpload],
            fileComment: '',
            aboutEvidenceSectionStatus: '',
            deleteEvidenceId: '',
            isFromEvidence: false,
            isFromAddAnotherApprovalEvidence: false,
            evidenceFileSummary:[]
        }
        saveEvidenceListDataInStore(updatedState);
    }

    const resetEvidenceDetailsData = () => {
        dispatch(resetEvidenceData());
    }

    const resetPurposeData = () => {
        let updatedState: IPurpose = {}
        updatedState = {
            isPurposeUpdated: false,
            applicationForCommercialPurposes: "",
            applicationForCommercialPurposesExplanation: "",
            doesSublicensingApply: "",
            duration: "",
            commercialSublicensingCheckYourAnswerPage: "",
            specificOutputsExpected: "",
            expectedMeasurableBenefits: "",
            expectedCheckYourAnswerPage: "",
            objectiveForProcessing: "",
            processingActivities: "",
            objectiveForProcessingCheckYourAnswerPage: "",
            aboutCommercialSublicensingSectionStatus: "",
        }
        savePurposeDataInStore(updatedState);
    }

    const resetYieldedBenefitsData = () => {
        let updatedState: IYieldedBenefits = {}
        updatedState = {
            yieldedBenefits: "",
            aboutYieldedBenefits: ""
        }
        saveYieldedBenefitsDataInStore(updatedState);
    }

    const resetDSASummaryData = () => {
        let updatedState: IDSASummary = {}
        updatedState = {
            firstYear: "",
            secondYear: "",
            thirdYear: "",
            title: "",
            dsaStartDate: "",
            dsaEndDate: "",
            applicationReasonSelected: 0,
            duration: "",
            dsaDay: "",
            dsaMonth: "",
            dsaYear: "",
            aboutDSASummarySectionStatus: "",
        }
        setDSASummaryData(updatedState);
        saveDSASummaryDataInStore(updatedState);
    }

    const resetProcessingStorageLocations = () => {
        dispatch(resetProcessingStorageLocationsData());
    }

    const resetSubmitApplicationData = () => {
        let updatedState: ISubmitApplication = {}
        updatedState = {
            isConfirmChecked: false
        }
        saveSubmitApplicationDataInStore(updatedState);
    }

    const resetPrivacyNotice = () => {
        dispatch(resetPrivacyNoticeData());
    }

    /*.....End Reset Data......... */

    const getSectionStatus = (applicationId: string) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": applicationId ? applicationId : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: parameters
        };
        fetchInterceptor(SubmitScreenDSAIntro_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setSectionStatus(data, applicationId);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setSectionStatus = (data: IApiResDSADetails, applicationId: string) => {
        const sectionData: IApiResSectionList[] = data.SectionList;
        setTaskEnableSectionMultiSelect(sectionData!)
        let applyingForData: boolean = false;
        let organisationsInvolved: boolean = false;
        let dataAndParticipant: boolean = false;
        let applicationOverview: boolean = false;
        const updatedStatues: IObjectKeys = {
            aboutController: "",
            aboutProcessor: "",
            aboutData: "",
            Participants: "",
            ProcessingLocations: "",
            StorageLocations: "",
            Funding: "",
            ExpectedOutputsAndBenefits: "",
            ObjectiveAndProcessingActivities: "",
            ListOfUploadedDocuments: "",
            SubmitApplication: "",
            CommercialPurposesAndSublicensing: "",
            PrivacyNotice: "",
            ProjectTitleReason: "",
            EthicsApproval: "",
            YieldedBenefits: ""
        }
        let copyUpdatedStatus: ISectionStatus;

        if (sectionData) {
            for (let i = 0; i < sectionData.length; i++) {
                let key = String(getKeyByValue(controllerSection, sectionData[i].SectionId));
                updatedStatues[key] = sectionData[i].Status;
                if (([controllerSection.aboutController, controllerSection.aboutProcessor, controllerSection.Funding, controllerSection.PrivacyNotice, controllerSection.ProcessingLocations, controllerSection.StorageLocations]).includes(sectionData[i].SectionId) && sectionData[i].Enable) {
                    organisationsInvolved = sectionData[i].Enable;
                }
                if (([controllerSection.aboutData, controllerSection.EthicsApproval, controllerSection.Participants]).includes(sectionData[i].SectionId) && sectionData[i].Enable) {
                    dataAndParticipant = sectionData[i].Enable;
                }
                if (([controllerSection.ExpectedOutputsAndBenefits, controllerSection.ObjectiveAndProcessingActivities, controllerSection.CommercialPurposesAndSublicensing, controllerSection.YieldedBenefits]).includes(sectionData[i].SectionId) && sectionData[i].Enable) {
                    applyingForData = sectionData[i].Enable;
                }
                if (([controllerSection.ListOfUploadedDocuments, controllerSection.SubmitApplication]).includes(sectionData[i].SectionId) && sectionData[i].Enable) {
                    applicationOverview = sectionData[i].Enable;
                }

            }
            sectionData.forEach(section => {
                if (section.Enable) {
                    sectionGroupListConfig.ParentSection.forEach((sectionItem: ISectionGroup, index: number) => {
                        if (section.SectionId && sectionItem.SectionIDs.includes(Number(section.SectionId))) {
                            if (!groupsectionflag[index].flag) {
                                groupsectionflag[index].flag = true;
                                updateTrueflag.push(groupsectionflag[index]);
                            }
                        }
                    });
                }
            })
        }

        copyUpdatedStatus = updatedStatues;
        setTaskListStatus(updateDefaultStatus(copyUpdatedStatus));
        manageSectionStatus(updatedStatues, sectionData);
        updateApplication(updatedStatues, data, applicationId, applyingForData, organisationsInvolved, dataAndParticipant, applicationOverview);
        handleClickApplicationSummary();
        setSectionGroupList(groupsectionflag);
        setFilteredSectionList(updateTrueflag);
        setLoading(false);
    }
    const getReturningJourneyType = () => (String(applicationChangeTypeValue) === String(applicationChangeType.Extension)) ? amendRenewExtendValue.extend : (String(applicationChangeTypeValue) === String(applicationChangeType.Renewal)) ? amendRenewExtendValue.renew : (String(applicationChangeTypeValue) === applicationChangeType.Amendment) ? amendRenewExtendValue.amend : '';

    const updateApplication = (updateStatues: ISectionStatus, data: IApiResDSADetails, applicationId: string, applyingForData: boolean, organisationsInvolved: boolean, dataAndParticipant: boolean, applicationOverview: boolean) => {
       let updatedState: IApplication = {};
        updatedState = {
            ...dsaApplicationData,
            id: applicationId,
            totalEstimatedCost: data.TotalChargeInclVat,
            section: data.SectionList as any,
            aboutControllerSectionStatus: updateStatues.aboutController,
            aboutProcessorSectionStatus: updateStatues.aboutProcessor,
            aboutDataSetSectionStatus: updateStatues.aboutData,
            aboutObjectiveAndProcessingSectionStatus: updateStatues.ObjectiveAndProcessingActivities,
            aboutExpectedOutputsAndBenefitsSectionStatus: updateStatues.ExpectedOutputsAndBenefits,
            commercialSublicensingSectionStatus: updateStatues.CommercialPurposesAndSublicensing,
            aboutFundingSectionStatus: updateStatues.Funding,
            aboutEthicsApprovalSectionStatus: updateStatues.EthicsApproval,
            aboutPrivacyNoticeSectionStatus: updateStatues.PrivacyNotice,
            aboutParticipantSectionStatus: updateStatues.Participants,
            aboutEvidenceSectionStatus: updateStatues.ListOfUploadedDocuments,
            aboutProcessingLocationSectionStatus: updateStatues.ProcessingLocations,
            aboutStorageLocationSectionStatus: updateStatues.StorageLocations,
            aboutYieldedBenefitsSectionStatus: updateStatues.YieldedBenefits,
            startDate: getDate(data.DSAStartDate),
            organisationsInvolved: organisationsInvolved,
            applyingForData: applyingForData,
            applicationOverview: applicationOverview,
            dataAndParticipant: dataAndParticipant,
            projectTitleReasons: updateStatues.ProjectTitleReason,
            returningFromExtendRenewAmend: (String(applicationChangeTypeValue) === applicationChangeType.Renewal || String(applicationChangeTypeValue) === applicationChangeType.Extension) ? dsaContent.isFromExtentorRenew : (String(applicationChangeTypeValue) === applicationChangeType.Amendment) ? dsaContent.isFromAmend : '',
            returningJourneyType: getReturningJourneyType(),
            readyToProceed1c: data.ReadyToProceed1c
        }
        setdsaApplicationData(updatedState);
        saveApplicationDataInStore(updatedState);
    }
    const manageSectionStatus = (updateStatues: ISectionStatus, sectionData: IApiResSectionList[]) => {
        if ((applicationChangeTypeValue === undefined || String(applicationChangeTypeValue) === applicationChangeType.new)) {
            const updateSectionStatus = {
                ...sectionStatus,
                processor: isCompleted(updateStatues.aboutController!),
                aboutthedata:isCompleted(updateStatues.ProjectTitleReason!),
                participants: isCompleted(updateStatues.aboutController!),
                submitApplication: (isCompleted(updateStatues.aboutController!) && isCompleted(updateStatues.aboutProcessor!) &&
                    isCompleted(updateStatues.PrivacyNotice!) && isCompleted(updateStatues.ProjectTitleReason!)
                    && isCompleted(updateStatues.ObjectiveAndProcessingActivities!) && isCompleted(updateStatues.ExpectedOutputsAndBenefits!)
                    && isCompleted(updateStatues.CommercialPurposesAndSublicensing!) && isCompleted(updateStatues.aboutData!)
                    && isCompleted(updateStatues.EthicsApproval!) && isCompleted(updateStatues.ListOfUploadedDocuments!)
                    && isCompleted(updateStatues.Funding!) && isCompleted(updateStatues.Participants!)
                    && isCompleted(updateStatues.ProcessingLocations!) && isCompleted(updateStatues.StorageLocations!)) ? true : false
            }
            setDefaultSectionStatus(updateSectionStatus);

            const updateStatus = {
                ...updateStatues,
                aboutProcessor: (isCompleted(updateStatues.aboutController!) && isCanNotStartYet(updateStatues.aboutProcessor!)) ? taskListSectionStatus.notStarted : updateStatues.aboutProcessor,
                aboutData: (isCompleted(updateStatues.ProjectTitleReason!) && isCanNotStartYet(updateStatues.aboutData!)) ? taskListSectionStatus.notStarted : updateStatues.aboutData,
                Participants: (isCompleted(updateStatues.aboutController!) && isCanNotStartYet(updateStatues.Participants!)) ? taskListSectionStatus.notStarted : updateStatues.Participants,
                SubmitApplication: (isCompleted(updateStatues.aboutController!) && isCompleted(updateStatues.aboutProcessor!) &&
                    isCompleted(updateStatues.PrivacyNotice!) && isCompleted(updateStatues.ProjectTitleReason!)
                    && isCompleted(updateStatues.ObjectiveAndProcessingActivities!) && isCompleted(updateStatues.ExpectedOutputsAndBenefits!)
                    && isCompleted(updateStatues.CommercialPurposesAndSublicensing!) && isCompleted(updateStatues.aboutData!)
                    && isCompleted(updateStatues.EthicsApproval!) && isCompleted(updateStatues.ListOfUploadedDocuments!)
                    && isCompleted(updateStatues.Funding!) && isCompleted(updateStatues.Participants!)
                    && isCompleted(updateStatues.ProcessingLocations!) && isCompleted(updateStatues.StorageLocations!)? taskListSectionStatus.notStarted : taskListSectionStatus.cannotStartYet)

            }
            setTaskListStatus(updateDefaultStatus(updateStatus));
        }
        else if ((String(applicationChangeTypeValue) === applicationChangeType.Renewal || String(applicationChangeTypeValue) === applicationChangeType.Extension)) {
            const updateSectionStatus = {
                ...sectionStatus,
                processor: isCompleted(updateStatues.aboutController!),
                aboutthedata: isCompleted(updateStatues.ProjectTitleReason!),
                participants: isCompleted(updateStatues.aboutController!),
                expectedOutputsBenefits: isCompleted(updateStatues.ObjectiveAndProcessingActivities!),
                commercialPurposesSublicensing: isCompleted(updateStatues.ObjectiveAndProcessingActivities!) && isCompleted(updateStatues.ExpectedOutputsAndBenefits!) ? true : false,
                submitApplication: (String(applicationChangeTypeValue) === String(applicationChangeType.Extension)) ?
                    (isCompleted(updateStatues.ProjectTitleReason!) && isCompleted(updateStatues.aboutController!)
                        && isCompleted(updateStatues.aboutProcessor!) 
                        && isCompleted(updateStatues.YieldedBenefits!)                        
                        && !isInProgress(updateStatues.ListOfUploadedDocuments!)
                        ? true : false)
                    :
                    (isCompleted(updateStatues.ProjectTitleReason!) && isCompleted(updateStatues.aboutController!)
                        && isCompleted(updateStatues.aboutProcessor!) 
                        && isCompleted(updateStatues.YieldedBenefits!)                       
                        && isCompleted(updateStatues.aboutData!) 
                        && !isInProgress(updateStatues.ListOfUploadedDocuments!))
                        ? true : false
            }
            setDefaultSectionStatus(updateSectionStatus);

            const updateStatus = {
                ...updateStatues,
                aboutProcessor: (isCompleted(updateStatues.aboutController!) && isCanNotStartYet(updateStatues.aboutProcessor!)) ? taskListSectionStatus.makeChanges : updateStatues.aboutProcessor,
                aboutData: (isCompleted(updateStatues.ProjectTitleReason!) && isCanNotStartYet(updateStatues.aboutData!)) ? taskListSectionStatus.actionNeeded : updateStatues.aboutData,
                Participants: (isCompleted(updateStatues.aboutController!) && isCanNotStartYet(updateStatues.Participants!)) ? taskListSectionStatus.makeChanges : updateStatues.Participants,
                SubmitApplication: (String(applicationChangeTypeValue) === String(applicationChangeType.Extension)) ?
                    (isCompleted(updateStatues.ProjectTitleReason!) && isCompleted(updateStatues.aboutController!) && isCompleted(updateStatues.aboutProcessor!)
                       && isCompleted(updateStatues.YieldedBenefits!) 
                        && !isInProgress(updateStatues.ListOfUploadedDocuments!) ? taskListSectionStatus.actionNeeded : taskListSectionStatus.cannotStartYet)
                    :
                    (isCompleted(updateStatues.ProjectTitleReason!) && isCompleted(updateStatues.aboutController!) && isCompleted(updateStatues.aboutProcessor!)
                        && isCompleted(updateStatues.YieldedBenefits!) && isCompleted(updateStatues.aboutData!)
                        && !isInProgress(updateStatues.ListOfUploadedDocuments!)
                        ? taskListSectionStatus.actionNeeded : taskListSectionStatus.cannotStartYet)

            }
            setTaskListStatus(updateDefaultStatus(updateStatus));
        }
        else if (String(applicationChangeTypeValue) === applicationChangeType.Amendment) {
            const updateSectionStatus = {
                ...sectionStatus,
                processor: isCompleted(updateStatues.aboutController!),
                aboutthedata: isCompleted(updateStatues.ProjectTitleReason!),
                participants: enableParticipantLink(updateStatues),
                expectedOutputsBenefits: isCompleted(updateStatues.ObjectiveAndProcessingActivities!),
                commercialPurposesSublicensing: isCompleted(updateStatues.ObjectiveAndProcessingActivities!) && isCompleted(updateStatues.ExpectedOutputsAndBenefits!) ? true : false,
                submitApplication: Boolean(setSubmitApplicationStatus(dsaContent.submitApplicationLink,sectionData))
            }
            setDefaultSectionStatus(updateSectionStatus);

            const updateStatus = {
                ...updateStatues,
                aboutProcessor: (isCompleted(updateStatues.aboutController!) && isCanNotStartYet(updateStatues.aboutProcessor!)) ? taskListSectionStatus.makeChanges : updateStatues.aboutProcessor,
                aboutData: (isCompleted(updateStatues.ProjectTitleReason!) && isCanNotStartYet(updateStatues.aboutData!)) ? taskListSectionStatus.actionNeeded : updateStatues.aboutData,
                Participants: (isCompleted(updateStatues.aboutController!) && isCanNotStartYet(updateStatues.Participants!)) ? taskListSectionStatus.makeChanges : updateStatues.Participants,
                SubmitApplication: String(setSubmitApplicationStatus(dsaContent.submitApplicationStatus, sectionData))
            }
            setTaskListStatus(updateDefaultStatus(updateStatus));
        }
    }

    const enableParticipantLink = (updateStatues: ISectionStatus) => {
        let status: boolean = true;
        if (dsaApplicationData.organisationsInvolved && dsaApplicationData.applyingForData && dsaApplicationData.dataAndParticipant) {
            status = isCompleted(updateStatues.aboutController!);
        }
        else if (dsaApplicationData.organisationsInvolved && dsaApplicationData.dataAndParticipant) {
            status = isCompleted(updateStatues.aboutController!);
        }

        return status;
    }
    
    const setSubmitApplicationStatus = (enableType: string, sectionData: IApiResSectionList[]) => {
        let status: boolean = false;
        let result: boolean | string;
        status = checkStatusForAmend(controllerSection.aboutController, sectionData) 
            && checkStatusForAmend(controllerSection.aboutProcessor, sectionData) 
            && checkStatusForAmend(controllerSection.PrivacyNotice, sectionData) 
            && checkStatusForAmend(controllerSection.ProcessingLocations, sectionData)
            && checkStatusForAmend(controllerSection.StorageLocations, sectionData)
            && checkStatusForAmend(controllerSection.Funding, sectionData)
            && checkStatusForAmend(controllerSection.ProjectTitleReason, sectionData)
            && checkStatusForAmend(controllerSection.YieldedBenefits, sectionData) 
            && checkStatusForAmend(controllerSection.ObjectiveAndProcessingActivities, sectionData)
            && checkStatusForAmend(controllerSection.ExpectedOutputsAndBenefits, sectionData) 
            && checkStatusForAmend(controllerSection.CommercialPurposesAndSublicensing, sectionData)
            && checkStatusForAmend(controllerSection.aboutData, sectionData)
            && checkStatusForAmend(controllerSection.EthicsApproval, sectionData) 
            && checkStatusForAmend(controllerSection.ListOfUploadedDocuments, sectionData) 

        if (enableType === dsaContent.submitApplicationLink)
            result = (status) ? true : false
        else
            result = (status) ? taskListSectionStatus.actionNeeded : taskListSectionStatus.cannotStartYet

        return result;
    }
    
    const handleClickApplicationSummary = () => {
        downloadPdfFile(abortController, dsaApplicationData?.id!, dsaApplicationData?.referenceNumber!, setBlobUrl);
    }
    const handleClickProcessingStorageLocations = (e: React.MouseEvent<HTMLAnchorElement>, locationRecordType?: number) => {
        loadSpinner(e);
        getProcessingStorageLocations(locationRecordType);
    }

    const getProcessingStorageLocations = (locationRecordType?: number) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "LocationRecordType": locationRecordType
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetProcessingStorageLocations_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {

                setProcessingStorageLocations(data, locationRecordType);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setProcessingStorageLocations = (response: IApiResLocationDetailsList, locationRecordType?: number) => {
        let updateState: IProcessAndStorageLocations = {};
        if (response.LocationList.length) {
            const { LocationId: locationId, LocationRegion: locationRegion } = response.LocationList[0] as IApiResLocationDetails;
            updateState = {
                ...processingStorageLocationsData,
                locationRegion,
                locationId,
                locationRecordType
            }
        } else {
            updateState = {
                ...processingStorageLocationsData,
                locationRecordType
            }
        }
        saveProcessingStorageLocationsDataInStore(updateState);
        setLoading(false);
        navigate(routeName.selectLocation);
    }

    const handleClickFunding = (e: React.MouseEvent<HTMLAnchorElement>) => {
        loadSpinner(e);
        getFundingDetailsData();
    }
    const getFundingDetailsData = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(CheckFundingSourceExist_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setFundingSourceDetailsData(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const handleDownload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        event.preventDefault();
        setDownloadClicked(true);
    }

    const setSectionListSequence = () => {
        const sectionFlags: IObjectKeys[] = [
            { 'OrganisationsInvolved': dsaApplicationData.organisationsInvolved! },
            { 'ApplyingForData': true },
            { 'ApplicationOverview': true }
        ];

        const sectionList: string[] = sectionFlags.reduce<string[]>((acc, item) => {
            // Adding to list iff selection in choose section page
            for (const key in item) {
                if (item[key] === true) {
                    acc.push(key)
                }
            }
            return acc;
        }, []);

        if (dsaApplicationData.dataAndParticipant === true) {
            if (dsaApplicationData.organisationsInvolved !== true && dsaApplicationData.applyingForData !== true) {
                sectionList.splice(0, 0, 'DataAndParticipant')
            }
            else if (sectionList.length > 2) {
                sectionList.splice(2, 0, 'DataAndParticipant')
            }
            else {
                sectionList.splice(1, 0, 'DataAndParticipant')
            }
        }
        setSectionList(sectionList);
    }
    const getNumber = (name: string): number => {
        if (String(applicationChangeTypeValue) === String(applicationChangeType.Extension)) {
            const extendFilterSectionList = filteredSectionList.filter(item => item.name !== 'DataAndParticipant');
            const index = extendFilterSectionList.findIndex(item => item.name === name);
            return index !== -1 ? index + 1 : 0;
        }
        else {
            const index = filteredSectionList.findIndex(item => item.name === name);
            return index !== -1 ? index + 1 : 0;
        }

    }

    const renderSectionList = (sectionType: string, headingNumber: number) => {
        switch (sectionType) {
            case 'OrganisationsInvolved':
                return (
                    <OrganisationsInvolved
                        headingNumber={headingNumber}
                        taskListStatus={taskListStatus}
                        sectionStatus={sectionStatus}
                        taskEnableSectionMultiSelect={taskEnableSectionMultiSelect}
                        handleClickController={handleClickController}
                        handleClickDSATask={handleClickDSATask}
                        getPrivacyNotice={getPrivacyNotice}
                        getControllerData={getControllerData}
                        handleClickProcessingStorageLocations={handleClickProcessingStorageLocations}
                        handleClickFunding={handleClickFunding}
                        description={"We need some information about your organisation, who controls and processes the data."}
                    />
                );
            case 'ApplyingForData':
                return (
                    <ApplyingForData
                        headingNumber={headingNumber}
                        taskListStatus={taskListStatus}
                        sectionStatus={sectionStatus}
                        taskEnableSectionMultiSelect={taskEnableSectionMultiSelect}
                        dsaApplicationData={dsaApplicationData}
                        handleClickDataRequestReason={handleClickDataRequestReason}
                        handleClickObjectiveAndProcessing={handleClickObjectiveAndProcessing}
                        handleClickExpectedOutputs={handleClickExpectedOutputs}
                        handleClickCommercialPurpose={handleClickCommercialPurpose}
                        handleClickYieldedbenefits={handleClickYieldedBenefits}
                    />
                );
            case 'ApplicationOverview':
                return (
                    <ApplicationOverview
                        headingNumber={headingNumber}
                        taskListStatus={taskListStatus}
                        sectionStatus={sectionStatus}
                        taskEnableSectionMultiSelect={taskEnableSectionMultiSelect}
                        handleClickEvidence={handleClickEvidence}
                        handleClickSubmitApplication={handleClickSubmitApplication}
                        readyToProceed1c={dsaApplicationData.readyToProceed1c!}
                    />
                );
            case 'DataAndParticipant':
                return (
                    <DataAndParticipant
                        headingNumber={headingNumber}
                        taskListStatus={taskListStatus}
                        sectionStatus={sectionStatus}
                        taskEnableSectionMultiSelect={taskEnableSectionMultiSelect}
                        handleClickParticipant={handleClickParticipant}
                        handleClickDataSet={handleClickDataSet}
                        handleClickDSATask={handleClickDSATask}
                        getEthicsApproval={getEthicsApproval}
                        readyToProceed1c={dsaApplicationData.readyToProceed1c!}
                    />
                );
            default:
                break;
        }
    };
    const hideGroup = (name: string): boolean => {
        const visible = sectionGroupList.find(item => item.name === name);
        return visible ? visible.flag : false;
    }
    const handleGoBack = () => {
        if (String(applicationChangeTypeValue) === applicationChangeType.Amendment) {
            let updatedState: IApplication = {};
            updatedState = {
                isFromApplyDsa: true
            }
            setdsaApplicationData(updatedState);
            saveApplicationDataInStore(updatedState);
        }
    }
    const getGoBackLink = () => {
        return dsaApplicationData.isFromApplyDsa ? dsaContent.applyDsa :
            (String(applicationChangeTypeValue) === applicationChangeType.Renewal || String(applicationChangeTypeValue) === applicationChangeType.Extension) ?
                dsaContent.manageDsaIntro : (String(applicationChangeTypeValue) === applicationChangeType.Amendment) ? dsaContent.amendIntro : dsaContent.dsaIntro;
    }
    const checkEnable = (sectionId: Number) => {
        return enableStatus(sectionId, taskEnableSectionMultiSelect);
    }
    

    useEffect(() => {
        resetControllerData();
        resetControllerListData();
        resetProcessorData();
        resetProcessorListData();
        resetDataSetListData();
        resetDataSetData();
        resetParticipantData();
        resetFundingData();
        resetFundingListData();
        resetPurposeData();
        resetEvidenceList();
        resetDSASummaryData();
        resetEvidenceDetailsData();
        resetProcessingStorageLocations();
        resetSubmitApplicationData();
        resetPrivacyNotice();
        resetDeleteDocumentInStore();
        updatePageNavigation();
        getSectionStatus(dsaApplicationData.id!);
        resetYieldedBenefitsData();
    }, []);

    useEffect(() => {
        setSectionListSequence();
    }, [sectionGroupList]);
    useEffect(() => {
        if (downloadClicked && blobUrl) {
            setGeneratedBlobUrl(blobUrl);
            setDownloadClicked(false);
        }
    }, [downloadClicked, blobUrl]);

    useEffect(() => {
        return (() => {
            abortController.abort();
        });
    }, [navigate]);

    const handleMainHeading = () => {
        {
            return String(applicationChangeTypeValue) === String(applicationChangeType.Renewal) ? "to renew" :
                String(applicationChangeTypeValue) === String(applicationChangeType.Extension) ? "to extend" : "for"
        }
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ? <Loader loadingText={dsaContent.txtLoading} /> :
                            <>
                                <div className="nhsuk-grid-row">
                                    <div className="nhsuk-grid-column-full nhsuk-u-margin-top-1">
                                        <div className="nhsuk-grid-column-one-half">
                                            <div className="nhsuk-back-link nhsuk-u-margin-bottom-0">
                                                <GoBack onClick={handleGoBack} condition={getGoBackLink()} />
                                            </div>
                                        </div>
                                        <div className="nhsuk-grid-column-one-half">
                                            <div className="nhsuk-back-link nhsuk-u-margin-bottom-0 download-application-summary nhsuk-u-text-align-right">
                                                <a href="#" target="_blank" className={downloadClicked ? "nhsuk-link-spinner nhsuk_download_spinner" : ""} onClick={handleDownload}>Download application summary</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    (applicationChangeTypeValue === undefined || String(applicationChangeTypeValue) === applicationChangeType.new) &&
                                    <>

                                        <div className="nhsuk-grid-column-two-thirds">
                                            <h1>
                                                <span className="nhsuk-caption-l"><span className="nhsuk-u-visually-hidden"> - </span>
                                                </span>
                                                Application for a Data Sharing Agreement</h1>

                                            <p>Complete the following sections. The information you submit in this application will be assessed to determine if you can get a Data Sharing Agreement (DSA).</p>
                                        </div>
                                        <div className="nhsuk-grid-column-two-thirds">
                                            <div className="nhsuk-section-list-sidebar-block">
                                                <br />
                                                {hideGroup('OrganisationsInvolved') && <>< h3 > {getNumber('OrganisationsInvolved')}. About the organisations involved</h3>
                                                    <p>We need some information about your organisation, who controls, processes and stores the data, and who will be signing for your organisation. </p>
                                                    <table className="nhsuk-table section-list-no-border">
                                                        <tbody className="nhsuk-table__body">
                                                            {checkEnable(controllerSection.aboutController) && <tr className="nhsuk-table__row section-list-border-tp">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="#" className="nhsuk_downloadOnclick" onClick={handleClickController}>About the Controller </a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.aboutController) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.aboutController) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag>)}
                                                                </td>
                                                            </tr>}
                                                            {checkEnable(controllerSection.PrivacyNotice) && <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="#" onClick={(e) => handleClickDSATask(e, getPrivacyNotice)}>Privacy Notice</a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.PrivacyNotice) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.PrivacyNotice) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.PrivacyNotice) === String(taskListSectionStatus.notStarted)) ? <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag> : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)}
                                                                </td>
                                                            </tr>}
                                                            {checkEnable(controllerSection.aboutProcessor) && <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    {!sectionStatus.processor ? "About the Processor" : <a href="#" onClick={(e) => handleClickDSATask(e, getControllerData)}>About the Processor</a>}
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.aboutProcessor) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.aboutProcessor) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.aboutProcessor) === String(taskListSectionStatus.notStarted)) ? <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag> : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)}
                                                                </td>
                                                            </tr>}
                                                            {checkEnable(controllerSection.ProcessingLocations) && <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="#" onClick={(e) => handleClickProcessingStorageLocations(e, ProcessAndStorageLocationsValue?.Processor)}>Processing Locations</a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.ProcessingLocations) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.ProcessingLocations) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.ProcessingLocations) === String(taskListSectionStatus.notStarted)) ? <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag> : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)}
                                                                </td>
                                                            </tr>}
                                                            {checkEnable(controllerSection.StorageLocations) && <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="#" onClick={(e) => handleClickProcessingStorageLocations(e, ProcessAndStorageLocationsValue?.Storage)}>Storage Locations</a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.StorageLocations) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.StorageLocations) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.StorageLocations) === String(taskListSectionStatus.notStarted)) ? <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag> : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)}
                                                                </td>
                                                            </tr>}
                                                            {checkEnable(controllerSection.Funding) && <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="#" onClick={handleClickFunding}>Funding</a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.Funding) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.Funding) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.Funding) === String(taskListSectionStatus.notStarted)) ? <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag> : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)}
                                                                </td>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                </>}
                                                {hideGroup('ApplyingForData') && <> <h3>{getNumber('ApplyingForData')}. Why you are applying for the data</h3>
                                                    <p>We need to know why you are requesting data from NHS England so that we can assess whether your request meets legal requirements. Data use must benefit healthcare provision, adult social care, or the promotion of health.</p>

                                                    <table className="nhsuk-table section-list-no-border">
                                                        <tbody className="nhsuk-table__body">
                                                            {checkEnable(controllerSection.ProjectTitleReason) && <tr className="nhsuk-table__row section-list-border-tp">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="#" onClick={(e) => handleClickDSATask(e, handleDataRequestReason)}>Data request reason and project name</a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.ProjectTitleReason) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.ProjectTitleReason) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag>)}
                                                                </td>
                                                            </tr>}

                                                            {checkEnable(controllerSection.ObjectiveAndProcessingActivities) && <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="#" onClick={handleClickObjectiveAndProcessing}>Objective(s) and processing activities</a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.ObjectiveAndProcessingActivities) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.ObjectiveAndProcessingActivities) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.ObjectiveAndProcessingActivities) === String(taskListSectionStatus.notStarted)) ? <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag> : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)}
                                                                </td>
                                                            </tr>}

                                                            {checkEnable(controllerSection.ExpectedOutputsAndBenefits) && <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="#" onClick={handleClickExpectedOutputs}>Expected outputs and benefits</a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.ExpectedOutputsAndBenefits) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.ExpectedOutputsAndBenefits) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.ExpectedOutputsAndBenefits) === String(taskListSectionStatus.notStarted)) ? <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag> : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)}
                                                                </td>
                                                            </tr>}
                                                            {checkEnable(controllerSection.CommercialPurposesAndSublicensing) && <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="#" onClick={handleClickCommercialPurpose}>Commercial purposes and sub-licensing</a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.CommercialPurposesAndSublicensing) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.CommercialPurposesAndSublicensing) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.CommercialPurposesAndSublicensing) === String(taskListSectionStatus.notStarted)) ? <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag> : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)}
                                                                </td>
                                                            </tr>}

                                                        </tbody>
                                                    </table>
                                                </>}
                                                {hideGroup('DataAndParticipant') && <> <h3>{getNumber('DataAndParticipant')}. Data and participants</h3>
                                                    <p>We need to know what data you need and how often you need it, we'll then have a better understanding of:
                                                    </p>
                                                    <ul className="nhsuk-list nhsuk-list--bullet">
                                                        <li>its levels of identifiability</li>
                                                        <li>its legal basis </li>
                                                        <li>the requested data-years</li>
                                                        <li>the requested data-fields</li>
                                                        <li>data minimisation </li>
                                                    </ul>
                                                    <p>We must ensure that the requested type of data aligns with your reason for requesting it and meets the requirement to process data lawfully, fairly and transparently. </p>

                                                    <table className="nhsuk-table section-list-no-border">
                                                        <tbody className="nhsuk-table__body">

                                                            {checkEnable(controllerSection.Participants) && <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    {!sectionStatus.participants ? "About the participants" : <a href="#" onClick={handleClickParticipant}>About the participants</a>}
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.Participants) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.Participants) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.Participants) === String(taskListSectionStatus.notStarted)) ? <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag> : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)}
                                                                </td>
                                                            </tr>}
                                                            {checkEnable(controllerSection.aboutData) && <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    {(!sectionStatus.aboutthedata || !dsaApplicationData.readyToProceed1c) ? "About the data" : <a href="#" onClick={handleClickDataSet}>About the data</a>}
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {dsaApplicationData.readyToProceed1c ? (String(taskListStatus.aboutData) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.aboutData) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.aboutData) === String(taskListSectionStatus.notStarted)) ? <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag> : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)) : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag> }
                                                                </td>
                                                            </tr>}
                                                            {checkEnable(controllerSection.EthicsApproval) && <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="#" onClick={(e) => handleClickDSATask(e, getEthicsApproval)}>Ethics approval</a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.EthicsApproval) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.EthicsApproval) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.EthicsApproval) === String(taskListSectionStatus.notStarted)) ? <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag> : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)}
                                                                </td>
                                                            </tr>}
                                                        </tbody>
                                                    </table>

                                                </>}
                                                {hideGroup('ApplicationOverview') && <> <h3>{getNumber('ApplicationOverview')}. Application overview</h3>
                                                    <p>Based on the information you have given in the previous sections, we've provided an estimated cost for accessing the requested data, a list of uploaded documents and a summary of the application. </p>

                                                    <table className="nhsuk-table section-list-no-border">
                                                        <tbody className="nhsuk-table__body">
                                                            {checkEnable(controllerSection.ListOfUploadedDocuments) && <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="" onClick={handleClickEvidence}>List of uploaded documents</a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.ListOfUploadedDocuments) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.ListOfUploadedDocuments) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.ListOfUploadedDocuments) === String(taskListSectionStatus.notStarted)) ? <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag> : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)}
                                                                </td>
                                                            </tr>}
                                                            {checkEnable(controllerSection.SubmitApplication) && <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    {(!sectionStatus.submitApplication || !dsaApplicationData.readyToProceed1c) ? "Submit application" : <a href="" onClick={handleClickSubmitApplication}>Submit application</a>}
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {dsaApplicationData.readyToProceed1c ? (String(taskListStatus.SubmitApplication) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.SubmitApplication) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.SubmitApplication) === String(taskListSectionStatus.notStarted)) ? <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag> : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)) : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>}
                                                                </td>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                </>}

                                            </div>
                                        </div>

                                    </>
                                }

                                {
                                    (String(applicationChangeTypeValue) === applicationChangeType.Renewal || String(applicationChangeTypeValue) === applicationChangeType.Extension) &&
                                    <>

                                        <div className="nhsuk-grid-column-two-thirds">

                                            <h1>
                                                <span className="nhsuk-caption-l">
                                                    <span className="nhsuk-u-visually-hidden"> - </span>
                                                </span>
                                                {checkApplicationChangeType(String(applicationChangeTypeValue), [String(applicationChangeType.Renewal)]) ? "Application to renew a Data Sharing Agreement" : checkApplicationChangeType(String(applicationChangeTypeValue), [String(applicationChangeType.Extension)]) ? "Application to extend a Data Sharing Agreement" : "Application for a Data Sharing Agreement"}
                                            </h1>

                                            {
                                                checkApplicationChangeType(String(applicationChangeTypeValue), [String(applicationChangeType.Extension)]) ? (<>
                                                    <h3>Extend:</h3>
                                                    <p>
                                                        You can now make changes to the following sections. The changes you submit will be assessed to determine if you can get an extension to the DSA.
                                                    </p>
                                                </>) : (
                                                    <p>You can now make changes to the following sections. The changes you submit will be assessed to determine if you can renew the DSA.</p>
                                                )
                                            }
                                        </div>
                                        <div className="nhsuk-grid-column-two-thirds">
                                            <div className="nhsuk-section-list-sidebar-block">
                                                <br />
                                                <>  <h3>1. About the organisations involved</h3>

                                                    {checkApplicationChangeType(String(applicationChangeTypeValue), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? <p>We need you to check and update security and data protection information for the organisation(s) who control and process the data.</p> : <p>We need some information about your organisation, who controls processes and stores the data, and who will be signing for your organisation. </p>}

                                                    <table className="nhsuk-table section-list-no-border">
                                                        <tbody className="nhsuk-table__body">
                                                            <tr className="nhsuk-table__row section-list-border-tp">
                                                                <td className="nhsuk-table__cell ">
                                                                    <a href="#" className="nhsuk_downloadOnclick" onClick={handleClickController}>About the Controller </a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {/*  <StatusTag status={taskListStatus.aboutController!} />*/}
                                                                    {String(taskListStatus.aboutController) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag>
                                                                        : (String(taskListStatus.aboutController) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag>
                                                                            : (String(taskListStatus.aboutController) === String(taskListSectionStatus.actionNeeded)) ? <Tag color="orange" className="nhsuk-float-right">ACTION NEEDED</Tag>
                                                                                : <Tag color="white" className="nhsuk-float-right">MAKE CHANGES</Tag>)}                                                                </td>
                                                            </tr>
                                                            <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="#" onClick={(e) => handleClickDSATask(e, getControllerData)}>About the Processor</a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.aboutProcessor) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.aboutProcessor) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.aboutProcessor) === String(taskListSectionStatus.actionNeeded)) ? <Tag color="orange" className="nhsuk-float-right">ACTION NEEDED</Tag> : <Tag color="white" className="nhsuk-float-right">MAKE CHANGES</Tag>)}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table></>

                                                <> <h3>2. Why you are applying for the data</h3>
                                                    {checkApplicationChangeType(String(applicationChangeTypeValue), [String(applicationChangeType.Renewal)]) ?
                                                        <p>We need to know about any benefits that have been realised and how long you want the DSA for.</p> :
                                                        (checkApplicationChangeType(String(applicationChangeTypeValue), [String(applicationChangeType.Extension)])) ?
                                                            <p> We need to know about any benefits that have been realised and how long you want to extend the DSA for.</p> :
                                                            <p>We need to know why you are requesting data from NHS England so that we can assess whether your request meets legal requirements. Data use must benefit healthcare provision, adult social care, or the promotion of health.</p>}


                                                    <table className="nhsuk-table section-list-no-border">
                                                        <tbody className="nhsuk-table__body">
                                                            <tr className="nhsuk-table__row section-list-border-tp">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="#" onClick={(e) => handleClickDSATask(e, handleDataRequestReason)}>Data request reason and project name</a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {
                                                                        String(taskListStatus.ProjectTitleReason) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag>
                                                                            : (String(taskListStatus.ProjectTitleReason) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag>
                                                                                : (String(taskListStatus.ProjectTitleReason) === String(taskListSectionStatus.actionNeeded)) ? <Tag color="orange" className="nhsuk-float-right">ACTION NEEDED</Tag>
                                                                                    : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)
                                                                    }                                                                </td>
                                                            </tr>

                                                            <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="#" onClick={handleClickYieldedBenefits}>Yielded benefits</a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.YieldedBenefits) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.YieldedBenefits) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.YieldedBenefits) === String(taskListSectionStatus.actionNeeded)) ? <Tag color="orange" className="nhsuk-float-right">ACTION NEEDED</Tag> : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </>
                                                {
                                                    String(applicationChangeTypeValue) !== String(applicationChangeType.Extension) ?
                                                        <>
                                                            <> <h3>3. Data</h3>
                                                                <p>We need to know what additional data you need.  We must ensure that the requested data aligns with your reason for requesting it and meets the requirements to process data lawfully, fairly and transparently.</p>

                                                                <table className="nhsuk-table section-list-no-border">
                                                                    <tbody className="nhsuk-table__body">
                                                                        <tr className="nhsuk-table__row">
                                                                            <td className="nhsuk-table__cell">
                                                                                {(!sectionStatus.aboutthedata || !dsaApplicationData.readyToProceed1c) ? "About the data" : <a href="#" onClick={handleClickDataSet}>About the data</a>}
                                                                            </td>
                                                                            <td className="nhsuk-table__cell">
                                                                                {dsaApplicationData.readyToProceed1c ? (String(taskListStatus.aboutData) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.aboutData) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : (String(taskListStatus.aboutData) === String(taskListSectionStatus.notStarted)) ? <Tag color="white" className="nhsuk-float-right"> NOT STARTED</Tag> : (String(taskListStatus.aboutData) === String(taskListSectionStatus.makeChanges)) ? <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag> : (String(taskListStatus.aboutData) === String(taskListSectionStatus.actionNeeded)) ? <Tag color="orange" className="nhsuk-float-right">ACTION NEEDED</Tag> : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)) : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </>

                                                        </> : ""}

                                                <> <h3>{String(applicationChangeTypeValue) !== String(applicationChangeType.Extension) ? 4 : 3}. Application overview</h3>
                                                    <p>We need you to upload any supporting documents and submit the application.</p>

                                                    <table className="nhsuk-table section-list-no-border">
                                                        <tbody className="nhsuk-table__body">
                                                            <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    <a href="" onClick={handleClickEvidence}>List of uploaded documents</a>
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {String(taskListStatus.ListOfUploadedDocuments) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.ListOfUploadedDocuments) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : <Tag color="white" className="nhsuk-float-right">MAKE CHANGES</Tag>)}
                                                                </td>
                                                            </tr>
                                                            <tr className="nhsuk-table__row">
                                                                <td className="nhsuk-table__cell">
                                                                    {(!sectionStatus.submitApplication || !dsaApplicationData.readyToProceed1c) ? "Submit application" : <a href="" onClick={handleClickSubmitApplication}>Submit application</a>}
                                                                </td>
                                                                <td className="nhsuk-table__cell">
                                                                    {dsaApplicationData.readyToProceed1c ?  (String(taskListStatus.SubmitApplication) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag>
                                                                        : (String(taskListStatus.SubmitApplication) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag>
                                                                            : (String(taskListStatus.SubmitApplication) === String(taskListSectionStatus.actionNeeded)) ? <Tag color="orange" className="nhsuk-float-right">ACTION NEEDED</Tag>
                                                                                : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)) : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag> }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </>
                                            </div>
                                        </div>
                                    </>
                                }

                                {
                                    String(applicationChangeTypeValue) === applicationChangeType.Amendment &&
                                    <>

                                        <div className="nhsuk-grid-column-two-thirds">
                                            <h1>
                                                <span className="nhsuk-caption-l"><span className="nhsuk-u-visually-hidden"> - </span>
                                                </span>
                                                Application to amend a Data Sharing Agreement</h1>
                                            <InsetText>
                                                <p>You can now make changes to the following sections. The changes you submit will be assessed to determine if you can get an updated DSA. </p>
                                            </InsetText>
                                        </div>
                                        <div className="nhsuk-grid-column-two-thirds">
                                            <div className="nhsuk-section-list-sidebar-block">
                                                <br />
                                                {sectionList.length && sectionList.map((item, headingNumber) => {
                                                    return renderSectionList(item, headingNumber + 1);
                                                })}
                                            </div>
                                        </div>
                                    </>
                                }
                                <GetHelp applicationChangeTypeValue={applicationChangeTypeValue!} />
                                {
                                    (applicationChangeTypeValue === undefined || String(applicationChangeTypeValue) === applicationChangeType.new) ?
                                        <>
                                            <div className="nhsuk-grid-column-full">
                                                <div className="nhsuk-u-padding-bottom-2"></div>
                                                <Details>
                                                    <Details.Summary>What do these statuses mean?</Details.Summary>
                                                    <Details.Text>
                                                        <SummaryList>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key><Tag color="white">{applicationStatus[1014]}</Tag></SummaryList.Key>
                                                                <SummaryList.Value>This section of the application has not been started yet.</SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key><Tag color="blue">{applicationStatus[1001]}</Tag></SummaryList.Key>
                                                                <SummaryList.Value>This section of the application has been started but not completed.</SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key><Tag>{applicationStatus[1015]}</Tag></SummaryList.Key>
                                                                <SummaryList.Value>You have answered a question to say that this section has been completed. But you will still be able to make changes to this section, if you need to, before submitting the application.</SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key><Tag color="grey">{applicationStatus[1016]}</Tag></SummaryList.Key>
                                                                <SummaryList.Value><span>Opening this section is dependent upon you completing a previous section of the application.</span><br />
                                                                    <span>For example:</span><br />
                                                                    <span>Opening the 'About the Processor' section is dependent upon completing the 'About the Controller' section.</span><br />
                                                                    <span>Opening 'About the participants' section is dependent upon completing the 'About the Controller' section.</span><br />
                                                                    <span>And opening 'About the data' section is dependent upon the 'Data request reason and project name' section.</span>
                                                                </SummaryList.Value>
                                                            </SummaryList.Row>                                                            
                                                        </SummaryList>
                                                    </Details.Text>
                                                </Details>
                                            </div>

                                        </> : checkApplicationChangeType(String(applicationChangeTypeValue), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension), String(applicationChangeType.Amendment)]) ? <>
                                            <div className="nhsuk-grid-column-full">
                                                <div className="nhsuk-u-padding-bottom-2"></div>

                                                <Details>
                                                    <Details.Summary>What do these statuses mean?</Details.Summary>
                                                    <Details.Text>
                                                        <SummaryList>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key><Tag color="white">{applicationStatus[1017]}</Tag></SummaryList.Key>
                                                                <SummaryList.Value>You have not yet answered a question to say that this section has been completed.</SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key><Tag color="blue">{applicationStatus[1001]}</Tag></SummaryList.Key>
                                                                <SummaryList.Value>This section of the application has been started but not completed.</SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key><Tag>{applicationStatus[1015]}</Tag></SummaryList.Key>
                                                                <SummaryList.Value>You have answered a question to say that this section has been completed. But you will still be able to make changes to this section, if you need to, before submitting the application.</SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key><Tag color="grey">{(applicationStatus[1016]).replace(/&nbsp;/g, ' ')}</Tag></SummaryList.Key>
                                                                {String(applicationChangeTypeValue) === String(applicationChangeType.Extension) ?
                                                                    <SummaryList.Value>Opening this section is dependent upon you completing a previous section of the application.</SummaryList.Value>
                                                                    : <SummaryList.Value>Opening this section is dependent upon you completing a previous section of the application or it cannot be opened yet because the system is still copying it over from the previous version.</SummaryList.Value>}
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key><Tag color="white">{applicationStatus[1014]}</Tag></SummaryList.Key>
                                                                <SummaryList.Value>This section of the application has not been started yet.</SummaryList.Value>
                                                            </SummaryList.Row>
                                                            <SummaryList.Row>
                                                                <SummaryList.Key><Tag color="orange">{(applicationStatus[1004]).replace(/&nbsp;/g, ' ')}</Tag></SummaryList.Key>
                                                                <SummaryList.Value>Application has been sent back to the applicant for changes</SummaryList.Value>
                                                            </SummaryList.Row>
                                                        </SummaryList>
                                                    </Details.Text>
                                                </Details>
                                            </div>
                                        </> : null}
                            </>}
                    </div>
                </main >
            </div >
        </>
    )
}
export default DSATaskList;