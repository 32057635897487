import { BackLink } from 'nhsuk-react-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { dsaContent, goBackLink } from '../../config/page.config';
import { isContainsJson, setSessionStorage } from '../../Helper/Utility';

const GoBack = (props: { condition?: string, onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void; }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPageName: string = location.pathname.split("/").pop()!;

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        props.onClick && props.onClick!(e);
        if (props.condition) {
            const hasValue = isContainsJson(goBackLink, currentPageName);
            if (hasValue) {
                const prevPage = goBackLink.find(item => {
                    return item.currentPage === currentPageName
                })?.previousPage;

                const prevPageName = prevPage?.find(item => {
                    return item.condition === props.condition
                })?.pageName;

                if (prevPageName) {
                    navigate('/' + prevPageName, { replace: true });
                }
                else {
                    navigate(-1);
                }
            }
            else {
                navigate(-1);
            }
        }
        else {
            navigate(-1);
        }
        setSessionStorage(dsaContent.eventType, dsaContent.txtBack);
    };

    return (
        <BackLink href="#" onClick={handleClick}>Go back</BackLink>
    )
}
export default GoBack;