import React, { useEffect, useState } from "react";
import { Button, ErrorSummary, Fieldset, Input, InsetText, Label, Radios, Details } from "nhsuk-react-components";
import { useNavigate, useLocation } from "react-router-dom";
import { IAPiResDSFCCustomerSignatory, IAPIResDSFCCustomerSignatoryList, IDSFCContractDetails, IDSFCCustomerSignatory, ILocationStateProps } from "../../../state/models/app.interface";
import { getDSFCContractDetails, setDSFCContractDetailsData } from '../../../state/slice/DSFC/DSFCContractDetails.slice';
import { AppDispatch } from '../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../state/app/hooks';
import { AddressDetailsSubmit_URL } from "../../../config/api-endpoints.config";
import { getCookie, validateAlphabets, validateNumericAlphabets, getB64toBlobData, submitSpinner, fetchInterceptor } from "../../../Helper/Utility";
import { dsfcErrorMessage, dsfcContent, routeName, fileTypeSize } from "../../../config/page.config";
import "../../../Content/css/nhsuk-common.css";
import InfoHeaderPanel from "../../../Components/InfoHeaderPanel/InfoHeaderPanel";
import GoBack from "../../../Components/Shared/GoBack";

const DSFCDetails: React.FC = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const [comingFromPreviewChange] = useState((location.state as ILocationStateProps)?.dsfcPreviewChange);
    const [comingFromDashboard] = useState((location.state as ILocationStateProps)?.dashboard);
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const DSFCContractDetailsData: IDSFCContractDetails = useAppSelector(getDSFCContractDetails);
    const initialState: IDSFCContractDetails = DSFCContractDetailsData || {};
    const [dsfcData, setDSFCData] = useState(initialState);
    const [btnText, setBtnText] = useState(dsfcContent.btnTextNext);
    const dispatch: AppDispatch = useAppDispatch();

    const savedataInStore = (stateData?: IDSFCContractDetails) => {
        dispatch(setDSFCContractDetailsData({ ...dsfcData, ...stateData }));
    }

    const [isICOLinkError, setIsICOLinkError] = useState("");
    const [isICOError, setIsICOError] = useState("");
    const [alternativeOrganisationNameLinkError, setAlternativeOrganisationNameLinkError] = useState("");
    const [alternativeOrganisationNameError, setAlternativeOrganisationNameError] = useState("");
    const [alternativeStreet1LinkError, setAlternativeStreet1LinkError] = useState("");
    const [alternativeStreet1Error, setAlternativeStreet1Error] = useState("");
    const [alternativeStreet2LinkError, setAlternativeStreet2LinkError] = useState("");
    const [alternativeStreet2Error, setAlternativeStreet2Error] = useState("");
    const [alternativeStreet3LinkError, setAlternativeStreet3LinkError] = useState("");
    const [alternativeStreet3Error, setAlternativeStreet3Error] = useState("");
    const [townOrCityLinkError, setTownOrCityLinkError] = useState("");
    const [townOrCityError, setTownOrCityError] = useState("");
    const [countyLinkError, setCountyLinkError] = useState("");
    const [countyError, setCountyError] = useState("");
    const [postCodeLinkError, setPostCodeLinkError] = useState("");
    const [postCodeError, setPostCodeError] = useState("");
    const [countryLinkError, setCountryLinkError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [showButtonNext, setShowButtonNext] = useState(true);
    const [showButtonsaveandClose, setShowButtonsaveandClose,] = useState(true);
    const [showRenewContent, setShowRenewContent] = useState(false);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (ValidateFields()) {
            submitSpinner(e, submitAddressDetails(e));
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    if (comingFromDashboard) {
        setShowRenewContent(true);
    }

    const submitAddressDetails = (event: React.MouseEvent<HTMLButtonElement>) => {
        const target = event.target as HTMLInputElement
        if (target.name !== "undefined" && target.name !== null && target.name === dsfcContent.saveAndClose) {
            setShowButtonNext(false);
        }
        else if (target.name !== "undefined" && target.name !== null && target.name === dsfcContent.save) {
            setShowButtonsaveandClose(false);
        }

        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "VMScreenDSFCAddress": {
                "DSFCApplicationId": dsfcData.dsfcApplicationId,
                "CompaniesHouseICO": dsfcData.companiesHouseICO,
                "CompaniesHouseICOAlternativeAddress": "",
                "AlternativeOrganisation": dsfcData.alternativeOrganisationName,
                "AlternativeStreet1": dsfcData.alternativeStreet1,
                "AlternativeStreet2": dsfcData.alternativeStreet2,
                "AlternativeStreet3": dsfcData.alternativeStreet3,
                "AlternativeCity": dsfcData.alternativeCity,
                "AlternativeCounty": dsfcData.alternativeCounty,
                "AlternativePostCode": dsfcData.alternativePostCode,
                "AlternativeCountry": dsfcData.alternativeCountry
            }
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(AddressDetailsSubmit_URL, requestOptions)
            .then(response => {
                    return response.json()
            })
            .then(data => {
                setCustomerSignatoryDetails(data);
                if (comingFromPreviewChange && target.name === dsfcContent.save) {
                    navigate(routeName.dsfcPreview);
                }
                else if (target.name === dsfcContent.save) {
                    navigate(routeName.dsfcSignatory);
                }
                else if (target.name === dsfcContent.saveAndClose) {
                    navigate(routeName.dashboard);
                }
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setCustomerSignatoryDetails = (signatoryResponse: IAPIResDSFCCustomerSignatoryList) => {
        let blobUrl: string = "#";

        if (signatoryResponse.VersionTemplateFile != null) {
            const contentType: string = signatoryResponse.VersionTemplateFile.mimeType;
            const fileName: string = signatoryResponse.VersionTemplateFile.fileName;
            const b64Data: string = signatoryResponse.VersionTemplateFile.document;
            const blob: Blob = getB64toBlobData(b64Data, contentType, fileName);
            blobUrl = URL.createObjectURL(blob);
        }

        const signatoryList: IAPIResDSFCCustomerSignatoryList = signatoryResponse;
        let updatedState: IDSFCContractDetails = {};
        let customerSigantory: IDSFCCustomerSignatory = {};
        let signatoryDetailsList: IDSFCCustomerSignatory[] = [];
        let dsfcsignatory = false;
        let fullName = '';
        let emailAddress = '';
        let customerSigantoryId = '';

        signatoryList.Contacts.map((signatory: IAPiResDSFCCustomerSignatory) => {
            customerSigantory = {
                signatoryId: signatory.Id,
                fullName: signatory.FullName,
                emailAddress: signatory.EmailAddress1
            }
            signatoryDetailsList.push(customerSigantory);
            if (signatoryResponse.CustomerSignatoryId === signatory.Id) {
                fullName = signatory.FullName;
                emailAddress = signatory.EmailAddress1;
                dsfcsignatory = true;
                customerSigantoryId = signatory.Id;
            } 
        })

        updatedState = {
            ...dsfcData,
            dsfcdetails: true,
            signatoryDetalis: signatoryDetailsList,
            versionTemplateFileURL: blobUrl,
            customerSignatory: customerSigantoryId,
            fullName: fullName,
            emailAddress: emailAddress,
            dsfcsignatory: dsfcsignatory
        }
        setDSFCData(updatedState);
        savedataInStore(updatedState);
    }

    const ValidateFields = () => {
        let isValid: boolean = true;

        if (!dsfcData.companiesHouseICO) {
            setIsICOLinkError(`${dsfcErrorMessage.companiesHouseICOLink_ErrorMessage}`);
            setIsICOError(`${dsfcErrorMessage.companiesHouseICO_ErrorMessage}`);
            isValid = false;
        }

        if (dsfcData.companiesHouseICO === dsfcContent.no) {
            if (!dsfcData.alternativeStreet1) {
                setAlternativeStreet1LinkError(`${dsfcErrorMessage.alternativeStreet1Link_ErrorMessage}`);
                setAlternativeStreet1Error(`${dsfcErrorMessage.alternativeStreet1Link_ErrorMessage}`);
                isValid = false;
            }
            else if (dsfcData.alternativeStreet1) {
                if (!validateNumericAlphabets(dsfcData.alternativeStreet1)) {
                    setAlternativeStreet1LinkError(`${dsfcErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                    setAlternativeStreet1Error(`${dsfcErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                    isValid = false;
                }
            }

            if (dsfcData.alternativeStreet2) {
                if (!validateNumericAlphabets(dsfcData.alternativeStreet2)) {
                    setAlternativeStreet2LinkError(`${dsfcErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                    setAlternativeStreet2Error(`${dsfcErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                    isValid = false;
                }
            }

            if (dsfcData.alternativeStreet3) {
                if (!validateNumericAlphabets(dsfcData.alternativeStreet3)) {
                    setAlternativeStreet3LinkError(`${dsfcErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                    setAlternativeStreet3Error(`${dsfcErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                    isValid = false;
                }
            }

            if (!dsfcData.alternativeCity) {
                setTownOrCityLinkError(`${dsfcErrorMessage.alternativeCityLink_ErrorMessage}`);
                setTownOrCityError(`${dsfcErrorMessage.alternativeCityLink_ErrorMessage}`);
                isValid = false;
            } else if (dsfcData.alternativeCity) {
                if (!validateAlphabets(dsfcData.alternativeCity)) {
                    setTownOrCityLinkError(`${dsfcErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                    setTownOrCityError(`${dsfcErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                    isValid = false;
                }
            }

            if (!dsfcData.alternativeCounty) {
                setCountyLinkError(`${dsfcErrorMessage.alternativeCountyLink_ErrorMessage}`);
                setCountyError(`${dsfcErrorMessage.alternativeCountyLink_ErrorMessage}`);
                isValid = false;
            } else if (dsfcData.alternativeCounty) {
                if (!validateAlphabets(dsfcData.alternativeCounty)) {
                    setCountyLinkError(`${dsfcErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                    setCountyError(`${dsfcErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                    isValid = false;
                }
            }

            if (!dsfcData.alternativePostCode) {
                setPostCodeLinkError(`${dsfcErrorMessage.alternativePostCodeLink_ErrorMessage}`);
                setPostCodeError(`${dsfcErrorMessage.alternativePostCodeLink_ErrorMessage}`);
                isValid = false;
            }
            else if (dsfcData.alternativePostCode) {
                if (!validateNumericAlphabets(dsfcData.alternativePostCode)) {
                    setPostCodeLinkError(`${dsfcErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                    setPostCodeError(`${dsfcErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                    isValid = false;
                }
            }

            if (!dsfcData.alternativeCountry) {
                setCountryLinkError(`${dsfcErrorMessage.alternativeCountryLink_ErrorMessage}`);
                setCountryError(`${dsfcErrorMessage.alternativeCountryLink_ErrorMessage}`);
                isValid = false;
            } else if (dsfcData.alternativeCountry) {
                if (!validateAlphabets(dsfcData.alternativeCountry)) {
                    setCountryLinkError(`${dsfcErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                    setCountryError(`${dsfcErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                    isValid = false;
                }
            }
        }
        return isValid;
    }

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueLength = e.target.value.length;

        if (e.target.name === dsfcContent.isICO && e.target.value !== "") {
            setIsICOLinkError("");
            setIsICOError("");
        }

        if (e.target.name === dsfcContent.isICO && e.target.value === dsfcContent.yes) {
            setAlternativeOrganisationNameLinkError("");
            setAlternativeOrganisationNameError("");
            setAlternativeStreet1LinkError("");
            setAlternativeStreet1Error("");
            setAlternativeStreet2LinkError("");
            setAlternativeStreet2Error("");
            setAlternativeStreet3LinkError("")
            setAlternativeStreet3Error("");
            setTownOrCityLinkError("")
            setTownOrCityError("");
            setCountyLinkError("");
            setCountyError("");
            setPostCodeLinkError("");
            setPostCodeError("");
            setCountryLinkError("");
            setCountryError("");
        }

        if (dsfcData.companiesHouseICO === dsfcContent.no) {

            if (valueLength >= 1) {

                if (e.target.name === dsfcContent.alternativeStreet1) {
                    setAlternativeStreet1LinkError("");
                    setAlternativeStreet1Error("");
                }

                if (e.target.name === dsfcContent.alternativeCity) {
                    setTownOrCityLinkError("");
                    setTownOrCityError("");
                }

                if (e.target.name === dsfcContent.alternativeCounty) {
                    setCountyLinkError("");
                    setCountyError("");
                }

                if (e.target.name === dsfcContent.alternativePostCode) {
                    setPostCodeLinkError("");
                    setPostCodeError("");
                }

                if (e.target.name === dsfcContent.alternativeCountry) {
                    setCountryLinkError("");
                    setCountryError("");
                }
            }

            if (e.target.name === dsfcContent.alternativeOrganisationName) {
                setAlternativeOrganisationNameLinkError("");
                setAlternativeOrganisationNameError("");
            }

            if (e.target.name === dsfcContent.alternativeStreet2) {
                setAlternativeStreet2LinkError("");
                setAlternativeStreet2Error("");
            }
            if (e.target.name === dsfcContent.alternativeStreet3) {
                setAlternativeStreet3LinkError("");
                setAlternativeStreet3Error("");
            }
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IDSFCContractDetails = {};
        updatedState = { ...dsfcData, [e.target.name]: e.target.value }

        if (e.target.name === dsfcContent.isICO && e.target.value === dsfcContent.no) {
            updatedState = {
                ...dsfcData,
                isICO: true,
                companiesHouseICO: dsfcContent.no
            }
        }
        if (e.target.name === dsfcContent.isICO && e.target.value === dsfcContent.yes) {
            updatedState = {
                ...dsfcData,
                isICO: false,
                companiesHouseICO: dsfcContent.yes,
                alternativeOrganisationName: "",
                alternativeStreet1: "",
                alternativeStreet2: "",
                alternativeStreet3: "",
                alternativeCity: "",
                alternativeCounty: "",
                alternativePostCode: "",
                alternativeCountry: ""
            }
        }

        resetError(e);
        setDSFCData(updatedState);
        savedataInStore(updatedState);
    };

    const addressForm: JSX.Element = (
        <>
            <Label size="s">Add the Organisation name and address that is legally registered with the ICO</Label>
            <div>
                <Input
                    id="alternativeOrganisationName"
                    name="alternativeOrganisationName"
                    label="Organisation name (optional)"
                    error={alternativeOrganisationNameError}
                    value={dsfcData?.alternativeOrganisationName}
                    onChange={handleChange}
                />

                <Input
                    id="alternativeStreet1"
                    name="alternativeStreet1"
                    label="Address Line 1"
                    error={alternativeStreet1Error}
                    value={dsfcData?.alternativeStreet1}
                    onChange={handleChange}
                />

                <Input
                    id="alternativeStreet2"
                    name="alternativeStreet2"
                    label="Address Line 2 (optional)"
                    error={alternativeStreet2Error}
                    value={dsfcData?.alternativeStreet2}
                    onChange={handleChange}
                />

                <Input
                    id="alternativeStreet3"
                    name="alternativeStreet3"
                    label="Address Line 3 (optional)"
                    error={alternativeStreet3Error}
                    value={dsfcData?.alternativeStreet3}
                    onChange={handleChange}
                />

                <Input
                    id="city"
                    name="alternativeCity"
                    label="Town or city"
                    error={townOrCityError}
                    value={dsfcData?.alternativeCity}
                    onChange={handleChange}
                />

                <Input
                    id="county"
                    name="alternativeCounty"
                    label="County"
                    error={countyError}
                    value={dsfcData?.alternativeCounty}
                    onChange={handleChange}
                />

                <Input
                    id="postalCode"
                    name="alternativePostCode"
                    label="Postcode"
                    error={postCodeError}
                    value={dsfcData?.alternativePostCode}
                    onChange={handleChange}
                />

                <Input
                    id="country"
                    name="alternativeCountry"
                    label="Country"
                    error={countryError}
                    value={dsfcData?.alternativeCountry}
                    onChange={handleChange}
                />
            </div>
        </>
    );

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsfcErrorMessage.summaryTitle_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <p>{dsfcData.companiesHouseICO !== "" ? dsfcErrorMessage.dsfcDetailsSummaryBody_ErrorMessage : dsfcErrorMessage.dsfcDetailsRegisteredOrganisation_ErrorMessage} </p>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#icoAddress">
                            {isICOLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeOrganisationName">
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeStreet1">
                            {alternativeStreet1LinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeStreet2">
                            {alternativeStreet2LinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeStreet3">
                            {alternativeStreet3LinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#city">
                            {townOrCityLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#county">
                            {countyLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#postalCode">
                            {postCodeLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#country">
                            {countryLinkError}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    );

    useEffect(() => {
        if (!dsfcData.dsfcintro || dsfcData.dsfcsubmit || !dsfcData.dashboard) {
            navigate(routeName.dashboard);
        }
        (dsfcData && dsfcData.isRenewal) ? setBtnText(dsfcContent.btnSaveAndContinue) : setBtnText(dsfcContent.btnTextNext)
    }, []);

    return (
        <>

            <InfoHeaderPanel>
                <InfoHeaderPanel.Item >
                    <strong>Reference number</strong> : {dsfcData?.contractNumber}
                </InfoHeaderPanel.Item>
            </InfoHeaderPanel>

            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-full">
                            <GoBack />
                            {dsfcData.isRenewal ?
                                <>
                                    <h1>Address of organisation</h1>
                                </>
                                : <h1>Address of your organisation</h1>
                            }
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            {
                                isICOError !== "" ||
                                    alternativeStreet1Error !== "" ||
                                    alternativeStreet2Error !== "" ||
                                    alternativeStreet3Error !== "" ||
                                    countryError !== "" ||
                                    townOrCityError !== "" ||
                                    countyError !== "" ||
                                    postCodeError !== "" ? errorSummary : null
                            }
                            <Label>{dsfcData.isRenewal}</Label>

                            {dsfcData.isRenewal ?
                                <>
                                    <InsetText>
                                        <p>
                                            You have told us that you would like to renew your Data Sharing Framework Contract (DSFC) because it&apos;s either due to expire or we have made you aware that the terms and conditions have changed.
                                        </p>
                                    </InsetText>
                                </>
                                : null}

                            <Label size="s">
                                {dsfcData?.organisation ? <>{dsfcData.organisation} <br></br></> : null}
                                {dsfcData?.organisationAddress ? <> {dsfcData?.organisationAddress.split('\n').map((str, index) => <div key={index + 1}>{str}</div>)} </> : null}                            </Label>

                            <Fieldset>
                                {dsfcData.isRenewal ?
                                    <>
                                        <Fieldset.Legend>Is this the name and address as legally registered with the <a href="https://ico.org.uk/ESDWebPages/Search" target="_blank">Information Commissioners Office (ICO)</a>? </Fieldset.Legend>
                                    </>
                                    : <Fieldset.Legend>These are the details you have given us. Is this the name and address of the organisation that is legally registered with the Information Commissioner&apos;s Office (ICO)?</Fieldset.Legend>
                                }
                                <Radios name="example" id="icoAddress" hint="" error={isICOError}>
                                    <Radios.Radio name="isICO" checked={dsfcData?.companiesHouseICO === dsfcContent.yes} value={dsfcContent.yes} onChange={handleChange}>Yes</Radios.Radio>
                                    <Radios.Radio name="isICO" checked={dsfcData?.companiesHouseICO === dsfcContent.no} value={dsfcContent.no} onChange={handleChange} conditional={addressForm}>No</Radios.Radio>
                                </Radios>
                            </Fieldset>
                            {!dsfcData.isRenewal ?
                                <>
                                    <Details>
                                        <Details.Summary>How do I check with the Information Commissioner&apos;s Office?</Details.Summary>
                                        <Details.Text>
                                            <p>Search the <a href="https://ico.org.uk/ESDWebPages/Search" target="_blank">Information Commissioner&apos;s Office</a> (ICO) website for your organisations details.</p>
                                        </Details.Text>
                                    </Details>
                                </>
                                : null}
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            {showButtonNext ?
                                <>
                                    <Button name="save" onClick={handleClick} id="nhsuk-button-spinner">{btnText} </Button>
                                </>
                                : null}
                            {showButtonsaveandClose && !dsfcData.isRenewal ?
                                <>
                                    <span> <Button secondary name="saveAndClose" onClick={handleClick} id="nhsuk-button-spinner">{dsfcContent.btnTextSaveClose}</Button></span>
                                </>
                                : null}
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default DSFCDetails;

