import React, { useEffect, useState } from "react";
import { Button, Table, InsetText } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IApiResFundingSourceDetails, IApiResFundingSourceList, IApplication, IFileUpload, IFunding, IFundingList } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { amendRenewExtendValue, controllerSection, dsaContent, fileTypeSize, fundingSourceTypeOptions, initialStateOfFileUpload, routeName, taskListSectionStatus } from "../../../../config/page.config";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { fetchInterceptor, getB64toBlobData, getCookie, getKeyByValue, getTaskListType, submitDSASectionStatus, submitSpinner } from "../../../../Helper/Utility";
import Loader from "../../../../Components/Loader/Loader";
import { getFundingDetails, setFundingDetailsData } from "../../../../state/slice/Funding/Funding.slice";
import { getFundingListDetails, setFundingListDetailsData } from "../../../../state/slice/FundingList/FundingList.slice";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { GetDSAFileById_URL, GetFundingSources_URL } from "../../../../config/api-endpoints.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ShowAll from "../../../../Components/ShowAll/ShowAll";

const UploadedDocumentDetails: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;

    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};

    const dispatch: AppDispatch = useAppDispatch();

    const initialFundingState: IFunding = useAppSelector(getFundingDetails) || {};
    const [fundingData, setFundingData] = useState(initialFundingState);

    const initialfundingListState: IFundingList = useAppSelector(getFundingListDetails) || {};
    const [fundingListData, setFundingListData] = useState(initialfundingListState);

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showdownload, setshowdownload] = useState(false);

    const saveFundingDataInStore = (stateData?: IFunding) => {
        dispatch(setFundingDetailsData({ ...fundingData, ...stateData }));
    }

    const saveFundingDataListInStore = (stateData?: IFundingList) => {
        dispatch(setFundingListDetailsData({ ...fundingListData, ...stateData }));
    }
    const [loading, setLoading] = useState(false);
    const [isExpender, setIsExpender] = useState(false);
    const [sourceID, setSourceID] = useState("");

    const getFundingData = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetFundingSources_URL, requestOptions)
            .then(response => {
                    return response.json()
            })
            .then(data => {
                setFundingsData(data);
            })
            .catch(() => navigate(routeName.serviceError));

    }
    const setFundingsData = (response: IApiResFundingSourceList) => {
        if (response && !response.HasFundingSource) {
            resetFundingData();
            navigate(routeName.fundingType);
        } else if (response && response !== undefined && response?.FundingSourceList !== null && response?.FundingSourceList.length > 0) {
            const fundingList = response.FundingSourceList;
            let updatedState: IFundingList = {};
            let fundingDetails: IFunding = {};
            let fundingDetailsList: IFunding[] = [];
            let fundinglist: IFunding[] = [];
            if (fundingList.length > 0) {
                fundingList.map((funding: IApiResFundingSourceDetails) => {
                    fundingDetails = {
                        fileUpload: funding?.FileList,
                        fundingType: funding?.FundingSourceType,
                        sourceId: funding?.SourceId,
                        fundingAwardingInstruction: funding?.FundingAwardingInstitution,
                        referenceTitle: funding?.ReferenceTitleProjectActivity,
                        yearOfSubmissionDay: funding?.YearSubmissionAward?.split("/")[0] ? funding?.YearSubmissionAward?.split("/")[0] : "",
                        yearOfSubmissionMonth: funding?.YearSubmissionAward?.split("/")[1] ? funding?.YearSubmissionAward?.split("/")[1] : "",
                        yearOfSubmissionYear: funding?.YearSubmissionAward?.split("/")[2] ? funding?.YearSubmissionAward?.split("/")[2] : "",
                        isUploadedSummaryPage: true,
                        IsFundingSourceDirty: false,
                        isFundingInfoDirty: false,
                        comments: funding.Comments
                    }
                    fundingDetailsList.push(fundingDetails);
                    if (funding?.SourceId === fundingData?.recentlyAddedFunndingId) {
                        let updatedState: IFunding = {};
                        updatedState = {
                            ...fundingData,
                            isUploadedSummaryPage: true,
                            uploadedFile: funding?.FileList,
                            fileUpload: funding?.FileList,
                        }
                        setFundingData(updatedState);
                        saveFundingDataInStore(updatedState);
                    }
                })
                fundinglist = Object.assign([], fundinglist);
                fundinglist = fundingDetailsList;
                updatedState = {
                    ...fundingListData,
                    fundingList: fundingDetailsList,
                    activefundingData: fundinglist.length
                }
                setFundingListData(updatedState);
                saveFundingDataListInStore(updatedState);
            }
        }
        setLoading(false);
    }
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        submitSpinner(e, handleClickNavigate);
    }

    const handleClickNavigate = () => {
        submitDSASectionStatus(dsaApplicationData?.id!, controllerSection.Funding, taskListSectionStatus.completed)
            .then((data: Response) => {
                if (data) {
                    let updatedState: IFundingList = {
                        ...fundingListData,
                        aboutFundingSectionStatus: taskListSectionStatus.completed
                    }
                    setFundingListData(updatedState);
                    saveFundingDataListInStore(updatedState);
                    navigate(routeName.dsaTaskList);
                }
            });

    }

   
    const setData = (fundingIndex: number) => {
        let updatedState: IFunding = {};
        let selectedFundingDetails = fundingListData?.fundingList ? fundingListData.fundingList[fundingIndex] : {};
        updatedState = {
            fundingType: selectedFundingDetails.fundingType,
            fileUpload: [initialStateOfFileUpload],
            aboutFundingSectionStatus: selectedFundingDetails.aboutFundingSectionStatus,
            IsFundingSourceDirty: selectedFundingDetails.IsFundingSourceDirty,
            IsFundingFileUploadDirty: selectedFundingDetails.IsFundingFileUploadDirty,
            sourceId: selectedFundingDetails.sourceId,
            fundingAwardingInstruction: selectedFundingDetails.fundingAwardingInstruction,
            referenceTitle: selectedFundingDetails.referenceTitle,
            yearOfSubmissionDay: selectedFundingDetails.yearOfSubmissionDay,
            yearOfSubmissionMonth: selectedFundingDetails.yearOfSubmissionMonth,
            yearOfSubmissionYear: selectedFundingDetails.yearOfSubmissionYear,
            isFundingInfoDirty: selectedFundingDetails.isFundingInfoDirty,
            isUploadedSummaryPage: selectedFundingDetails.isUploadedSummaryPage,
            uploadedFile: selectedFundingDetails.fileUpload,
            recentlyAddedFunndingId: '',
            comments: selectedFundingDetails.comments
        }
        setFundingData(updatedState);
        saveFundingDataInStore(updatedState);
    }

    const handleClickNavigation = (navigatePage: string, fundingIndex: number, e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setData(fundingIndex);
        let updatedState: IFundingList = {};
        updatedState = {
            ...fundingListData,
            activefundingData: fundingIndex,
            isFromFundingSummary: true
        }
        setFundingListData(updatedState);
        saveFundingDataListInStore(updatedState);

        let updatedStateFunding: IFunding = {};
        const fundingDataToUpdate: IFunding = fundingListData?.fundingList![fundingIndex];
        if (fundingDataToUpdate) {
            updatedStateFunding = {
                ...fundingDataToUpdate,
                recentlyAddedFunndingId:''
            }
            setFundingData(updatedStateFunding);
            saveFundingDataInStore(updatedStateFunding);
        }
        navigate(navigatePage);
    }

    const navigateToFunding = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        resetFundingData();
        let updatedState: IFundingList = {};
        updatedState = {
            ...fundingListData,
            isFromFundingSummary: true
        }
        setFundingListData(updatedState);
        saveFundingDataListInStore(updatedState);
        navigate(routeName.fundingType);
    }

    const resetFundingData = () => {
        let updatedState: IFunding = {}
        updatedState = {
            fundingType: "",
            aboutFundingSectionStatus: "",
            fileUpload: [initialStateOfFileUpload],
            IsFundingSourceDirty: false,
            IsFundingFileUploadDirty: false,
            sourceId: "",
            fundingAwardingInstruction: "",
            referenceTitle: "",
            yearOfSubmissionDay: "",
            yearOfSubmissionMonth: "",
            yearOfSubmissionYear: "",
            isFundingInfoDirty: false,
            isUploadedSummaryPage: false,
            comments: "",
            uploadedFile: [initialStateOfFileUpload],
            recentlyAddedFunndingId: '',
            fileUploadActive: 0 
                    }
        saveFundingDataInStore(updatedState);
    }

    /*File Download*/
    const setFileUrl = (e: any) => {
        e.preventDefault();
        setSelectedIndex(e.currentTarget.id);
        setshowdownload(true);
        e.currentTarget.className += "nhsuk-disableLink";
        downloadfile(e, e.currentTarget.id);

    }
    const downloadfile = (e: any, fileId: any) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "FileId": fileId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: parameters
        };

        fetchInterceptor(GetDSAFileById_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data && data !== undefined) {
                    const b64Data: any = data.Data._buffer;
                    const blob: any = getB64toBlobData(b64Data, data.contentType, data.FileName);
                    const blobUrl: any = URL.createObjectURL(blob);
                    download(blobUrl, data.FileName);
                    setshowdownload(false);
                    e.target.removeAttribute('class');
                }

            })
            .catch(() => navigate('/serviceerror'));

    }
    const download = (path: any, filename: any) => {
        const anchor = document.createElement('a');
        anchor.href = path;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);


    };
    /*File Download*/

    const handleExpend = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, sourceId: string) => {
        e.preventDefault();
        setSourceID(sourceId);
        setIsExpender(isExpender ? false : true)
    }

    useEffect(() => {
        getFundingData();
    }, []);
    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ? (
                            <Loader loadingText={dsaContent.txtLoading} />
                        ) : (
                            <div className="nhsuk-grid-column-full">
                                {
                                    <GoBack condition={getTaskListType(dsaApplicationData.returningJourneyType!)} />
                                }
                                <span className="nhsuk-caption-l nhsuk-caption--bottom">Funding </span>
                                    <h1>Uploaded document(s)</h1>
                                <InsetText>
                                    <p>Here, you can see the documents you have uploaded</p>
                                </InsetText>
                                <Table>
                                    <Table.Head>
                                        <Table.Row>
                                            <Table.Cell>Uploaded funding document</Table.Cell>
                                            <Table.Cell>Funding source type</Table.Cell>
                                            <Table.Cell>Funding awarding institution</Table.Cell>
                                            <Table.Cell>Reference and title of project </Table.Cell>
                                            <Table.Cell>Date of submission or award</Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                        </Table.Row>
                                    </Table.Head>
                                    <Table.Body>
                                        {fundingListData?.fundingList?.map((items: IFunding, index: number) => (
                                            <Table.Row key={index}>
                                                <Table.Cell>
                                                    {items?.fileUpload?.map((data: IFileUpload, index: number) => (
                                                        <p key={"01" + index}>
                                                            {index <= 2 ? 
                                                        <>
                                                            <p>{String(data?.ApplicantComments)}</p>
                                                            <a href="#" id={String(data?.FileId)} onClick={(e) => setFileUrl(e)}>{data.FileName}</a>
                                                            {(showdownload && String(data?.FileId) === String(selectedIndex)) ?
                                                                <span className="nhsuk-loader__container loading-results ">
                                                                    <span className="nhsuk-loader-file"></span>
                                                                    Downloading file
                                                                </span> : null}
                                                                </>   : null}

                                                            {index === 3 ? <p><a href="#" onClick={(e) => { handleExpend(e, items.sourceId!) }}>{!isExpender || (items.sourceId !== sourceID && isExpender) ? "+Show all" : ""} </a></p> : ""}

                                                            
                                                        </p>
                                                    ))}
                                                    {items.sourceId === sourceID ? 
                                                        <>
                                                        {isExpender ? <>
                                                        {
                                                            items?.fileUpload!.map((fl: IFileUpload, index: number) => {
                                                                return (
                                                                    <div key={index + 1}>
                                                                        {index > 2 ?
                                                                            <p>
                                                                                <div className="nhsuk-u-margin-top-3">{fl?.ApplicantComments}</div>
                                                                                <a href="#" id={String(fl?.FileId)} onClick={(e) => setFileUrl(e)} > {fl?.FileName}</a>
                                                                                {(showdownload && String(fl?.FileId) === String(selectedIndex)) ?
                                                                                    <span className="nhsuk-loader__container loading-results ">
                                                                                        <span className="nhsuk-loader-file"></span>
                                                                                        Downloading file
                                                                                    </span> : null}
                                                                            </p>
                                                                            : null}
                                                                    </div>)

                                                            })
                                                        }</> : ""}
                                                    <p><a href="#" onClick={(e) => { handleExpend(e, items.sourceId!) }}>{!isExpender  ? "" : "-Show all"} </a></p> 
                                                        </> : null }
                                                </Table.Cell>

                                              
                                              
                                                <Table.Cell>{getKeyByValue(fundingSourceTypeOptions, String(items.fundingType))}</Table.Cell>
                                                <Table.Cell>{items?.fundingAwardingInstruction}</Table.Cell>
                                                <Table.Cell>{items?.referenceTitle}</Table.Cell>
                                                <Table.Cell>
                                                    {items?.yearOfSubmissionDay && items?.yearOfSubmissionMonth && items?.yearOfSubmissionYear ?
                                                        <>
                                                            {items?.yearOfSubmissionDay +
                                                                "/" +
                                                                items?.yearOfSubmissionMonth +
                                                                "/" +
                                                                items?.yearOfSubmissionYear}
                                                        </> : ""}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <a
                                                        href="#"
                                                        onClick={(e) => handleClickNavigation(routeName.fundingType, index, e)}>
                                                        Change
                                                    </a>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <a
                                                        href="#"
                                                        onClick={(e) => handleClickNavigation(routeName.deleteFunding, index, e)}>
                                                        Delete
                                                    </a>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <p>
                                    <a href="#" onClick={navigateToFunding}>
                                        Add another funding source type
                                    </a>
                                </p>
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>
                                    {dsaContent.btnTextContinue}
                                </Button>

                            </div>
                        )}
                    </div>
                </main>
            </div>
        </>
    );
}
export default UploadedDocumentDetails;