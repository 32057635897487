import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../state/app/hooks";
import { IApiResDSFCApplicationsApproval, IDSFCContractDetails } from "../../state/models/app.interface";
import { AppDispatch } from "../../state/app/store";
import { getDSFCContractDetails, setDSFCContractDetailsData } from '../../state/slice/DSFC/DSFCContractDetails.slice';
import { fetchInterceptor, getCookie, urlQueryParameter } from "../../Helper/Utility";
import { LoadScreenDSFCApproval_URL } from "../../config/api-endpoints.config";
import { useNavigate } from "react-router-dom";
import { dsaContent, fileTypeSize, routeName } from "../../config/page.config";

const RedirectController: React.FC = () => {
    const navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const DSFCContractDetailsData: IDSFCContractDetails = useAppSelector(getDSFCContractDetails);
    const initialState: IDSFCContractDetails = DSFCContractDetailsData || {};
    const [dsfcData, setDSFCData] = useState(initialState);
    const dispatch: AppDispatch = useAppDispatch();
    const savedataInStore = (stateData?: IDSFCContractDetails) => {
        dispatch(setDSFCContractDetailsData({ ...dsfcData, ...stateData }));
    }
    const setDSFCApproveDetails = (response: IApiResDSFCApplicationsApproval) => {
        const dsfcApprove: IApiResDSFCApplicationsApproval = response;
        let updatedState: IDSFCContractDetails = {};
        updatedState = {
            ...dsfcData,
            dsfcApplicationId: dsfcApprove.Id,
            contractNumber: dsfcApprove.ContractNumber,
            startDate: dsfcApprove.StartDate,
            endDate: dsfcApprove.EndDate,
            version: dsfcApprove.Edition.Name
        }
        setDSFCData(updatedState);
        savedataInStore(updatedState);
    }
    const fetchDSFCApproveData = (appId: string) => {

        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "DSFCApplicationId": appId
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(LoadScreenDSFCApproval_URL, requestOptions)
            .then(response => {
                    return response.json()
            })
            .then(data => {
                setDSFCApproveDetails(data);
                navigate(routeName.dsfcApprove);
            })
            .catch(() => navigate(routeName.serviceError));

    }
    const setRedirectionURLData = () => {
        const queryString = window.location.search;
        if (queryString != null && queryString != "") {
            const { userType, appId } = urlQueryParameter(queryString);
            if (appId && userType?.trim().toLowerCase() === dsaContent.approver) {
                if (authenticationTokenCookie) {
                    fetchDSFCApproveData(appId);
                }
            }
            else {
                navigate(routeName.dashboard);
            }
        }
    }
    useEffect(() => {
        setRedirectionURLData();
    }, []);
    return (
        <></>
    )
}

export default RedirectController;