import { useNavigate } from 'react-router-dom';
import ContainerTemplate from '../../../../Components/Container/ContainerTemplate';
import AddAnotherRecord from '../../../../Components/Shared/AddAnotherRecord';
import {
  fetchInterceptor,
  getCookie,
  getUploadFileName,
  isValid,
  submitSpinner,
  validateDateValue,
} from '../../../../Helper/Utility';
import { UpdateScreenDataProcessor_URL } from '../../../../config/api-endpoints.config';
import {
  buttonText,
  dsaContent,
  dsaProcessorErrorMessage,
  fileTypeSize,
  initialStateOfFileUpload,
  routeName,
  taskListSectionStatus,
} from '../../../../config/page.config';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { IApplication, IDeleteDocument, IProcessor } from '../../../../state/models/app.interface';
import { getProcessorListDetails } from '../../../../state/slice/ProcessorList/ProcessorList.slice';
import { getDSADetails, setDSAApplicationDetailsData } from '../../../../state/slice/DSA/DSA.slice';
import {
  getProcessorDetails,
  setProcessorDetails,
} from '../../../../state/slice/Processor/Processor.slice';
import { AppDispatch } from '../../../../state/app/store';
import ReviewDateSection from '../../../../Components/Shared/ReviewDateSection';
import { useEffect, useState } from 'react';
import {
  getDeleteDocumentData,
  setDeleteDocumentData,
} from '../../../../state/slice/shared/DeleteDocument/DeleteDocument.slice';
import { ErrorSummary } from 'nhsuk-react-components';

const AddAnotherProcessorSLSP: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();

  const authenticationTokenCookie: string = getCookie('authenticationToken')!;
  const { id: dsaApplicationId, aboutProcessorSectionStatus } =
    useAppSelector<IApplication>(getDSADetails);

    const { fileUpload, isNewProcessor, isDirtyProcessor, processorId, saList, fileUploadActive, evidenceFileSummary }: IProcessor =
    useAppSelector(getProcessorDetails); //processorData
  const { processorDetailsPage } = useAppSelector(getProcessorListDetails); //processorListData
  const { fileIndex, isDeleted } = useAppSelector(getDeleteDocumentData);

  const saveApplicationDataInStore = (stateData: IApplication) => {
    dispatch(setDSAApplicationDetailsData(stateData));
  };

  const saveProcessorDataInStore = (stateData: IProcessor) => {
    dispatch(setProcessorDetails(stateData));
  };

  const saveDeleteDocumentInStore = (stateData: IDeleteDocument) => {
    dispatch(setDeleteDocumentData(stateData));
  };

  const [diDayError, setDiDayError] = useState(false);
  const [diMonthError, setDiMonthError] = useState(false);
  const [diYearError, setDiYearError] = useState(false);
    const [dateReviewError, setDateReviewError] = useState('');
    const [fileRequiredError, setFileRequiredError] = useState('');

  /* To show the error summary */
    const errorSummaryEL: JSX.Element = (
    <>
      <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
        <ErrorSummary.Title id="error-summary-title">
          {dsaProcessorErrorMessage.selectProcessorSummary_ErrorMessage}
        </ErrorSummary.Title>
        <ErrorSummary.Body>
          <ErrorSummary.List>
                      <ErrorSummary.Item href="#slspDateComplete">{dateReviewError}</ErrorSummary.Item>
                      <ErrorSummary.Item>{fileRequiredError}</ErrorSummary.Item>
          </ErrorSummary.List>
        </ErrorSummary.Body>
      </ErrorSummary>
    </>
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetError(e);
    let updatedStateofProcessor: IProcessor = {};

    let updatedSAList = { ...saList, [e.target.name]: e.target.value };
    updatedStateofProcessor = { saList: updatedSAList, isDirtyProcessor: true };
    saveProcessorDataInStore(updatedStateofProcessor);
  };

  const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let updatedStateofProcessor: IProcessor = {};

    let updatedSAList = { ...saList, [e.target.name]: e.target.value };
    updatedStateofProcessor = { saList: updatedSAList, isDirtyProcessor: true };
    saveProcessorDataInStore(updatedStateofProcessor);
  };

  const resetError = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const valueLength = e.target.value.length;
    if (valueLength >= 1) {
      const { name } = e.target;
      if (
        name === dsaContent.slspDay ||
        name === dsaContent.slspMonth ||
        name === dsaContent.slspYear
      ) {
        setDateReviewError('');
        setDiYearError(false);
        setDiDayError(false);
        setDiMonthError(false);
      }
    }
  };

  const validateFields = (e: React.MouseEvent<HTMLButtonElement>) => {
      let isValidFields: boolean = true;
    let dateValueError = validateDateValue(
      Number(saList ? saList?.SLSPDay : '0'),
      Number(saList ? saList?.SLSPMonth : '0'),
      Number(saList ? saList?.SLSPYear : '0'),
      false,
      true
    );
    if (dateValueError[0] !== '') {
      setDateReviewError(dateValueError[0]);
      let fields = dateValueError[1].split(',');
      for (let i = 0; i < fields.length; i++) {
        if (fields[i] === dsaContent.dateInputDayField) {
          setDiDayError(true);
        }
        if (fields[i] === dsaContent.dateInputMonthField) {
          setDiMonthError(true);
        }
        if (fields[i] === dsaContent.dateInputYearField) {
          setDiYearError(true);
        }
      }
      isValidFields = false;
    }
    if (!isValid(fileUpload?.find((x) => isValid(x.FileName)))) {
        setFileRequiredError(dsaProcessorErrorMessage.UploadFile_ErrorMessage!);
        isValidFields = false;
    }
    return isValidFields;
  };

  const handleContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
    const isValid: boolean = validateFields(e);
    if (isValid) {
      submitSpinner(e, handleSubmit);
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleSubmit = () => {
    const updatedSAList = {
      ...saList,
      SLSPDateComplete: saList
        ? saList.SLSPDay + '/' + saList.SLSPMonth + '/' + saList.SLSPYear
        : '',
    };
    if (processorDetailsPage && !isNewProcessor) {
      if (isDirtyProcessor) {
        const recentlyUploaded = fileUpload!.filter(file => file?.RecentlyModified! === true);
        const parameters: string = JSON.stringify({
          UserAuthTokenId: authenticationTokenCookie,
          ApplicationId: dsaApplicationId !== '' ? dsaApplicationId : '',
          FileList: recentlyUploaded,
          DataProcessorId: processorId,
          SecurityAssurance: updatedSAList,
          IsSADirty: true,
          SectionStatus: aboutProcessorSectionStatus,
        });
        const requestOptions: Object = {
          method: 'POST',
          headers: { 'Content-Type': fileTypeSize.fileTypeJson },
          body: parameters,
        };
        fetchInterceptor(UpdateScreenDataProcessor_URL, requestOptions)
          .then((response) => {
            return response.json();
          })
          .then(() => {
            let sectionStatus: IApplication = {
              aboutProcessorSectionStatus: taskListSectionStatus.inProgress,
            };
            saveApplicationDataInStore(sectionStatus);
            navigate(routeName.processorDetails);
          })
          .catch(() => navigate(routeName.serviceError));
      } else {
        navigate(routeName.processorDetails);
      }
    } else {
      navigate(routeName.dataProtectionRegistrationProcessor);
    }
  };

  const deleteRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
    e.preventDefault();
    const documentData: IDeleteDocument = {
      fileIndex: index,
      fileId: fileUpload![index].FileId!,
      approvalEvidenceID: fileUpload![index].ApprovalEvidenceID!,
      isDeleted: false,
    };

    saveDeleteDocumentInStore(documentData);
    navigate(routeName.deleteDocument);
  };

  const editRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
    e.preventDefault();
    let updatedState: IProcessor = {};
    updatedState = {
      isDirtyProcessor: true,
        fileUploadActive: index,
        temporaryFileList: fileUpload
    };
    saveProcessorDataInStore(updatedState);
    navigate(routeName.processorSlsp);
  };

  const addAnotherRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
    e.preventDefault();
    let updatedState: IProcessor = {};
    updatedState = {
      isDirtyProcessor: true,
        fileUploadActive: fileUpload!.length,
        temporaryFileList: fileUpload
    };
    saveProcessorDataInStore(updatedState);
    navigate(routeName.processorSlsp);
  };
    useEffect(() => {
        let updatedState: IProcessor = {};
        updatedState = {
            temporaryFileList: [initialStateOfFileUpload]
        };
        if (isValid(evidenceFileSummary)) {
            let evidenceSummary = evidenceFileSummary?.filter((x) => isValid(x.fileId));
            fileUpload?.map(({ FileName: fileName, ContentType: mimeType }) =>
                isValid(fileName) && (evidenceSummary = [...evidenceSummary!, { fileName, mimeType }])
            );
            updatedState.evidenceFileSummary = evidenceSummary;
        }
        saveProcessorDataInStore(updatedState);
    }, [])
  useEffect(() => {
    if (isDeleted) {
      const documentData: IDeleteDocument = {
        isDeleted: false,
      };
      saveDeleteDocumentInStore(documentData);

        let updatedDocData = [...fileUpload!];
        var oldFileName = getUploadFileName(updatedDocData[fileIndex!].FileName!);
        updatedDocData.splice(fileIndex!, 1);
        let updatedState: IProcessor = {};
        updatedState = {
        isDirtyProcessor: true,
            fileUpload: updatedDocData,
            fileUploadActive: fileUpload?.length === 1 ? 0 : fileUploadActive,
            evidenceFileSummary: evidenceFileSummary?.filter((x) => x.fileName !== oldFileName)
        };
        saveProcessorDataInStore(updatedState);
    }
  }, [isDeleted]);

  return (
    <ContainerTemplate
          title={'SLSP'}
          heading={'Uploaded document(s)'}
          actionButtonText={buttonText.btnSaveAndContinue}
          onActionButtonClick={handleContinue}
    >
          <>
              {(dateReviewError || fileRequiredError) && errorSummaryEL}
          </>
      <AddAnotherRecord
        linkTo={routeName.processorSlsp}
        linkText="Add another document"
        records={fileUpload!} //list of files
        deleteRecord={deleteRecord}
        editRecord={editRecord}
        addAnotherRecord={addAnotherRecord}
      />
      <div className="nhsuk-grid-row">
        <ReviewDateSection
          handleChange={handleChange}
          handleChangeTextarea={handleChangeTextarea}
          dateReviewError={dateReviewError}
          diDayError={diDayError}
          diMonthError={diMonthError}
          diYearError={diYearError}
          data={{
            day: saList ? saList?.SLSPDay : '',
            month: saList ? saList?.SLSPMonth : '',
            year: saList ? saList?.SLSPYear : '',
            notes: saList ? saList?.SLSPNotes : '',
          }}
        />
      </div>
    </ContainerTemplate>
  );
};

export default AddAnotherProcessorSLSP;
