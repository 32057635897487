import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialStateOfFileUpload } from '../../../config/page.config';
import type { RootState } from '../../app/store'
import { IEvidence} from '../../models/app.interface'

export const initialState: IEvidence = {
    id: "",
    referenceNumber: "",
    comments: "",
    approvalsEvidenceType: "",
    isEvidenceApproval: false,
    evidenceApproval: "",
    isEthicsApproval: false,
    ethicsApproval: "",
    noEthicsApproval: "",
    ethicsApprovalNotInPlace: "",
    fileUploadEthics: [initialStateOfFileUpload],
    fileUploadApprovalEvidence: [initialStateOfFileUpload],
    fileUploadActive: 0,
    temporaryFileList: [initialStateOfFileUpload],
    evidenceFileSummary: []
}

export const EvidenceSlice = createSlice({
    name: 'Evidence',
    initialState,
    reducers: {
        setEvidenceDetailsData: (state: IEvidence, action: PayloadAction<IEvidence>) => {
            state = Object.assign(state, action.payload);
        },
        resetEvidenceData: () => initialState,
       
    }
});

export const { setEvidenceDetailsData, resetEvidenceData } = EvidenceSlice.actions;
export const getEvidenceDetails = (state: RootState) => state.evidenceDetails;
export default EvidenceSlice.reducer;