import { configureStore } from '@reduxjs/toolkit'
import DSFCContractDetailsSlice from '../slice/DSFC/DSFCContractDetails.slice'
import DSASlice from '../slice/DSA/DSA.slice'
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import HeaderSlice from '../slice/Header/Header.slice';
import ControllerSlice from '../slice/DSAAboutController/DSAAboutController.slice';
import NewsSlice from '../slice/News/News.slice';
import ControllerListSlice from '../slice/ControllerList/ControllerList.slice';
import ProcessorSlice from '../slice/Processor/Processor.slice';
import ProcessorListSlice from '../slice/ProcessorList/ProcessorList.slice';
import SecurityAssuranceSlice from '../slice/SecurityAssurance/SecurityAssurance.slice';
import DataSetSlice from '../slice/DataSet/DataSet.slice';
import DataSetListSlice from '../slice/DataSetList/DataSetList.slice';
import ParticipantSlice from '../slice/Participant/Participant.slice';
import DSASummarySlice from '../slice/DSASummary/DSASummary.slice';
import FundingSlice from '../slice/Funding/Funding.slice';
import FundingListSlice from '../slice/FundingList/FundingList.slice';
import PurposeSlice from '../slice/Purpose/Purpose.slice';
import EvidenceListSlice from '../slice/EvidenceList/EvidenceList.slice';
import EvidenceSlice from '../slice/Evidence/Evidence.slice';
import PrivacyNoticeSlice from '../slice/PrivacyNotice/PrivacyNotice.slice';
import ProcessingStorageLocationsSlice from '../slice/ProcessingStorageLocations/ProcessingStorageLocations.slice';
import SubmitApplicationSlice from '../slice/SubmitApplication/SubmitApplication.slice';
import { routeName } from '../../config/page.config';
import DeleteDocumentSlice from '../slice/shared/DeleteDocument/DeleteDocument.slice';
import YieldedBenefitsSlice from '../slice/YieldedBenefits/YieldedBenefits.slice';
import DSFCDashboardSlice  from '../slice/Dashboard/Dashboard.slice';

const persistConfig = {
    key: 'root',
    storage
};

const persist_reducers = combineReducers({
    dsfcContractDetails: DSFCContractDetailsSlice,
    dsaDetails: DSASlice,
    userDetails: HeaderSlice,
    controllerDetails: ControllerSlice,
    newsDetails: NewsSlice,
    processorListDetails: ProcessorListSlice,
    processorDetails: ProcessorSlice,
    controllerListDetails: ControllerListSlice,
    securityAssuranceDetails: SecurityAssuranceSlice,
    dataSetDetails: DataSetSlice,
    dataSetListDetails: DataSetListSlice,
    participantDetails: ParticipantSlice,
    dsaSummaryDetails: DSASummarySlice,
    fundingDetails: FundingSlice,
    fundingListDetails: FundingListSlice,
    purposeDetails: PurposeSlice,
    evidenceList: EvidenceListSlice,
    evidenceDetails: EvidenceSlice,
    processingStorageLocationsDetails: ProcessingStorageLocationsSlice,
    privacyNoticeDetails: PrivacyNoticeSlice,
    submitApplicationDetails: SubmitApplicationSlice,
    deleteDocumentDetails: DeleteDocumentSlice,
    yieldedBenefitsDetails: YieldedBenefitsSlice,
    dfscDashboardDetails: DSFCDashboardSlice
});

const rootReducer = (state: any, action: any) => {
    if (action.type === routeName.resetUserDetailData) {
        storage.removeItem('persist:root')
        return persist_reducers(undefined, action);
    }
    return persist_reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
})


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch