import React from 'react';

export interface IAttachmentContext {
    feature: boolean;
}

const AttachmentContext = React.createContext<IAttachmentContext>({
    feature: false,
});

export default AttachmentContext;