import { InsetText, Button } from "nhsuk-react-components";
import React, { useState } from "react";
import GoBack from "../../../../Components/Shared/GoBack";
import { useNavigate } from "react-router-dom";
import { dsaContent, fileTypeSize, participantTypeOptions, routeName, taskListSectionStatus } from "../../../../config/page.config";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader';
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { AppDispatch } from "../../../../state/app/store";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { IApplication, IParticipant } from "../../../../state/models/app.interface";
import { getParticipantDetails, setParticipantDetailsData } from "../../../../state/slice/Participant/Participant.slice";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { SubmitScreenCohortType_URL } from "../../../../config/api-endpoints.config";

const NoExistingAgreement: React.FC = () => {
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const participantDetailsData: IParticipant = useAppSelector(getParticipantDetails);
    const initialState: IParticipant = participantDetailsData || {};
    const [participantData, setParticipantData] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    const [isFromAnswerPage] = useState(participantData.participantcheckYourAnswerPage);
    /* To dispatch the setParticipantDetailsData Redux action */

    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantData, ...stateData }));
    }
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        submitSpinner(e, SubmitScreenCohortType);
    }

    const SubmitScreenCohortType = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "Cohort": true,
            "TypeOfCohort": participantData.participantTypes,
            "RefIfUsingAnotherExistingAgreement": null,
            "SectionStatus": dsaApplicationData.aboutParticipantSectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenCohortType_URL, requestOptions)
            .then(() => {
                    let updatedState: IParticipant = {};
                    updatedState = { ...participantData, participantcheckYourAnswerPage: isFromAnswerPage }
                    setParticipantData(updatedState);
                    saveDataInStore(updatedState);
                    let sectionStatus: IApplication = {
                        ...dsaApplicationData, aboutParticipantSectionStatus: taskListSectionStatus.inProgress
                    }
                    setDsaApplicationData(sectionStatus);
                    saveApplicationDataInStore(sectionStatus);
                    if (isFromAnswerPage !== undefined && isFromAnswerPage === dsaContent.checkYourAnswersPageText && participantData.cohort) {
                        navigate(routeName.participantSummary)
                    }
                    else {
                        if (String(participantData.participantTypes) === String(participantTypeOptions.new)) {
                            navigate(routeName.legalBasis);
                        }
                    }
                
            })
            .catch(() => navigate(routeName.serviceError));
        setLoading(false);
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-three-quarters">
                            <GoBack condition={participantData?.existingAgreementUploadItems!.length > 0 ? undefined : "participant-types"} />
                            <h1>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    About the Participants</span>
                                There is no existing Participant group that can be selected
                            </h1>
                            <InsetText>
                                <p>
                                    If you believe this is wrong, email <a href={'mailto:data.applications@nhsdigital.nhs.uk?subject=' + dsaApplicationData?.referenceNumber + ' - Participant group'}>data.applications@nhsdigital.nhs.uk</a> quoting your NIC number along with &apos;Participant group&apos; in the subject field.
                                </p>
                            </InsetText>
                            <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>)
}
export default NoExistingAgreement;