import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackLink, Button, Radios } from "nhsuk-react-components";
import { dsaContent, dsaControllerErrorMessage, fileTypeSize, initialStateOfFileUpload, routeName, sectionOptions } from "../../../../config/page.config";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader'
import ErrorDetails from '../../../../Components/Shared/ErrorSummary';
import { useAppSelector } from "../../../../state/app/hooks";
import { getProcessorDetails, setProcessorDetails } from '../../../../state/slice/Processor/Processor.slice';
import { getProcessorListDetails, setProcessorListDetailsData } from "../../../../state/slice/ProcessorList/ProcessorList.slice";
import { IProcessor, IProcessorList, IFileUpload, ISecurityAssurance } from "../../../../state/models/app.interface";
import { fetchInterceptor, getCookie, resetSecurityAssuranceState } from '../../../../Helper/Utility';
import { DeleteDSAChildRecord_URL } from "../../../../config/api-endpoints.config";
import Loader from "../../../../Components/Loader/Loader";
import { useAppDispatch } from '../../../../state/app/hooks';
import { AppDispatch } from '../../../../state/app/store';
import { getSecurityAssuranceDetails, setSecurityAssuranceDetailsData } from "../../../../state/slice/SecurityAssurance/SecurityAssurance.slice";

const AddAnotherProcessor: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [addProcessorValue, setAddProcessorValue] = useState('');
    const processorDetailsData: IProcessor = useAppSelector(getProcessorDetails);
    const initialState: IProcessor = processorDetailsData || {};
    const [processorData,] = useState(initialState);
    const processorListDetailsData: IProcessorList = useAppSelector(getProcessorListDetails);
    const initialProcessorListState: IProcessorList = processorListDetailsData || {};
    const [processorListData,] = useState(initialProcessorListState);
    const [addProcessorErrorMessage, setAddProcessorErrorMessage] = useState("");

    const saveProcessorDataInStore = (stateData?: IProcessor) => {
        dispatch(setProcessorDetails({ ...processorData, ...stateData }));
    }

    const saveProcessorListDataInStore = (stateData?: IProcessorList) => {
        dispatch(setProcessorListDetailsData({ ...processorListData, ...stateData }));
    }
    const securityAssuranceDetailsData: ISecurityAssurance = useAppSelector(getSecurityAssuranceDetails);
    const initiailSecurityAssuranceState: ISecurityAssurance = securityAssuranceDetailsData || {};
    const [securityAssuranceData, setSecurityAssuranceData] = useState(initiailSecurityAssuranceState);
    const saveSecurityAssuranceDataInStore = (stateData?: ISecurityAssurance) => {
        dispatch(setSecurityAssuranceDetailsData({ ...securityAssuranceData, ...stateData }));
    }
    /*Variable Declarations*/

    const resetProcessorData = () => {
        let updatedStateSA = resetSecurityAssuranceState();
        setSecurityAssuranceData(updatedStateSA);
        saveSecurityAssuranceDataInStore(updatedStateSA);

        let updatedState: IProcessor = {};
        updatedState = {
            processorId: "",
            isDirtyProcessor: false,
            aboutProcessorSectionStatus: "",
            dpaRegistrationNumber: "",
            dpaOrganisationName: "",
            dpaRegistrationExpiryDate: "",
            isProcessorProcessingData: false,
            dpDay: "",
            dpMonth: "",
            dpYear: "",
            fileUpload: [initialStateOfFileUpload],
            dsaApplicationId: "",
            contractNumber: "",
            alternativeOrganisationName: "",
            alternativeStreet1: "",
            alternativeStreet2: "",
            alternativeStreet3: "",
            alternativeCity: "",
            alternativeCounty: "",
            alternativePostCode: "",
            alternativeCountry: "",
            processordataOrgGuidId: "",
            processordataOrgDesc: "",
            processorIndex: 0,
            dpaId: "",
            isNewProcessor: true,
            processorProcessingData: "",
            saList: {}
        }
        saveProcessorDataInStore(updatedState);
    }

    
    const resetProcessorList = () => {
        let filteredProcessorList: IProcessor[];
        const processorList = processorListData.processorList ? processorListData.processorList : [];
        filteredProcessorList = processorList.filter((processor: IProcessor) => processor.processorId !== processorData.processorId)
        saveProcessorListDataInStore({ ...processorListData, processorList: (filteredProcessorList as [IProcessor]) });
    }

    const handleDelete = (contollerId: string) => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "Id": contollerId,
            "SectionId": sectionOptions.DataProcessor
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(DeleteDSAChildRecord_URL, requestOptions)
            .then(() => {
                    resetProcessorList();
                    resetProcessorData();
                    navigate(routeName.processorData);
              
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const handleAddProcessorContinue = () => {
        const IsValid: boolean = ValidateAddProcessorFields();
        if (IsValid) {
            if (addProcessorValue === dsaContent.yes) {
                handleDelete(processorData.processorId!);
            } else {
                navigate(routeName.processorDetails);
            }
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const ValidateAddProcessorFields = () => {
        let isValid: boolean = true;

        if (addProcessorValue === '') {
            setAddProcessorErrorMessage(`${dsaControllerErrorMessage.addAnotherController_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const resetAddProcessorError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setAddProcessorErrorMessage("");
        }
    }

    const handleChangeAddProcessor = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAddProcessorValue(e.target.value)
        resetAddProcessorError(e)
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} /> :

                            <div className="nhsuk-grid-column-two-thirds">
                                <BackLink href="#" onClick={() => { navigate(routeName.deleteProcessor) }}>{dsaContent.goback}</BackLink>

                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    About the Processor</span>
                                    Do you want to add another Processor?
                                </h1>
                                {addProcessorErrorMessage !== "" && <ErrorDetails errorDescription={addProcessorErrorMessage} errorHref="#addAnotherProcessor"></ErrorDetails>}

                                <Radios
                                    name="addAnotherProcessor"
                                    id="addAnotherProcessor"
                                    error={addProcessorErrorMessage}
                                >
                                    <Radios.Radio value={dsaContent.yes} onChange={handleChangeAddProcessor}>{dsaContent.yes}</Radios.Radio>
                                    <Radios.Radio value={dsaContent.no} onChange={handleChangeAddProcessor}>{dsaContent.no}</Radios.Radio>
                                </Radios>

                                <div className="nhsuk-u-padding-bottom-8"></div>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleAddProcessorContinue}>{dsaContent.btnSaveAndContinue}</Button>
                               
                            </div>
                        }
                    </div>
                </main>
            </div>
        </>
    )
}

export default AddAnotherProcessor;
