import { Fieldset, InsetText, Radios } from 'nhsuk-react-components';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContainerTemplate from '../../../../Components/Container/ContainerTemplate';
import ErrorDetails from '../../../../Components/Shared/ErrorSummary';
import { SubmitProcessingStorageLocation_URL } from '../../../../config/api-endpoints.config';
import { locations, processAndStoragelocationsErrorMessage, dsaContent, ProcessAndStorageLocationsValue, taskListSectionStatus, controllerSection, amendRenewExtendValue, routeName, fileTypeSize, } from '../../../../config/page.config';
import { fetchInterceptor, getCookie, submitDSASectionStatus, submitSpinner, } from '../../../../Helper/Utility';
import { useAppSelector, useAppDispatch } from '../../../../state/app/hooks';
import { AppDispatch } from '../../../../state/app/store';
import { IApplication, ILocations, IProcessAndStorageLocations, } from '../../../../state/models/app.interface';
import { getDSADetails } from '../../../../state/slice/DSA/DSA.slice';
import { getProcessingStorageLocationsDetails, setProcessingStorageLocationsData, } from '../../../../state/slice/ProcessingStorageLocations/ProcessingStorageLocations.slice';

const SelectLocations: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  let authenticationTokenCookie: string = getCookie('authenticationToken')!;
  const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};
  const processingStorageLocationsData = useAppSelector(getProcessingStorageLocationsDetails);
  const { locationRecordType, locationId, locationRegion } = processingStorageLocationsData;
  const defaultLocationRegion = locationRegion;
  const [selectedLocation, setSelectedLocation] = useState(String(processingStorageLocationsData.selectedLocation !== '' ? processingStorageLocationsData.selectedLocation : processingStorageLocationsData.locationRegion));
  const [radioDirty, setRadioDirty] = useState(false);
  const isProcessing = (locationRecordType: number) => (locationRecordType === ProcessAndStorageLocationsValue?.Processor);

  const saveProcessingStorageLocationsDataInStore = useCallback((stateData?: IProcessAndStorageLocations) => {
      dispatch(setProcessingStorageLocationsData({ ...processingStorageLocationsData, ...stateData }));
    }, [processingStorageLocationsData, dispatch]
  );

  const getRadioOptions = (locations: ILocations, selectedValue?: string) => {
    return Object.entries(locations).map(([key, value]) => (
      <Radios.Radio
        key={Number(value)}
        value={value}
        checked={selectedValue === value}
        onChange={handleOnChange}>
        {key}
      </Radios.Radio>
    ));
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setRadioDirty(false);
    setSelectedLocation(value);
    saveProcessingStorageLocationsDataInStore({ selectedLocation: value });
  };

  const handleContinue = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (selectedLocation === '' || selectedLocation === 'undefined') setRadioDirty(true);
    else submitSpinner(event, submitProcessingStorageLocation);
  };

  const submitProcessingStorageLocation = () => {
    const parameters: string = JSON.stringify({
      UserAuthTokenId: authenticationTokenCookie,
      ApplicationId: dsaApplicationData.id !== '' ? dsaApplicationData.id : '',
      RecordType: !defaultLocationRegion ? locationRecordType : undefined,
      LocationId: locationId,
      LocationRegion: Number(selectedLocation),
    });

    const requestOptions: Object = {
      method: 'POST',
      headers: { 'Content-Type': fileTypeSize.fileTypeJson },
      body: parameters,
    };
      fetchInterceptor(SubmitProcessingStorageLocation_URL, requestOptions)
      .then((response) => {
          return response.json();
      })
      .then((data) => {
        setProcessingStorageLocation(data);
        redirectToTaskList();
      })

      .catch(() => navigate(routeName.serviceError));
  };

  const redirectToTaskList = async () => {
    const controllerSectionName = isProcessing(locationRecordType!)
      ? controllerSection.ProcessingLocations
      : controllerSection.StorageLocations;
    const response = await submitDSASectionStatus(
      dsaApplicationData?.id!,
      controllerSectionName,
      taskListSectionStatus.completed
    );
    if (response) {
      navigate(routeName.dsaTaskList);
    }
  };

  const setProcessingStorageLocation = (data: string) => {
    let updateState: IProcessAndStorageLocations = {};
    updateState = {
      ...processingStorageLocationsData,
      locationId: data,
    };
    saveProcessingStorageLocationsDataInStore(updateState);
  };

  const loadErrorMessage = (_locationRecordType: number) => {
    return isProcessing(_locationRecordType!) ? processAndStoragelocationsErrorMessage.processingLocationErrorMessage : processAndStoragelocationsErrorMessage.storageLocationErrorMessage;
  };

  return (
    <ContainerTemplate
      title={`${isProcessing(locationRecordType!) ? 'Processing' : 'Storage'} Locations`}
      heading={`Where will you ${isProcessing(locationRecordType!) ? 'process' : 'store'} data covered by this Data Sharing Agreement?`}
      actionButtonText={dsaContent.btnTextContinue}
      onActionButtonClick={handleContinue}>
      {radioDirty ? (
        <ErrorDetails
          errorDescription={loadErrorMessage(locationRecordType!)}
          errorHref="#locations"></ErrorDetails>
      ) : (
        <></>
      )}
      <Fieldset>
        <Radios
          name="locations"
          id="locations"
          hint={isProcessing(locationRecordType!) ? "Select one option to tell us where you will process the data (territory of use)" : "Select one option to tell us where you will store the data"}
          error={radioDirty ? loadErrorMessage(locationRecordType!) : false}>
          {getRadioOptions(locations, selectedLocation)}
        </Radios>
      </Fieldset>
    </ContainerTemplate>
  );
};

export default SelectLocations;
