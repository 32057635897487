import { Label, ReviewDate } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { updatePageTitle } from "../../Helper/Utility";
import { dsaContent, pageTitles, pageConfigList, initialStatePageConfig } from "../../config/page.config";
import GoBack from "../../Components/Shared/GoBack";
import { GetPageConfigPublic_URL } from "../../config/api-endpoints.config";
import { IPageConfig } from "../../state/models/app.interface";
import Loader from "../../Components/Loader/Loader";
import useFetch from "../../Hooks/useFetch";

const Help: React.FC = () => {
    const [pageData, setPageData] = useState<IPageConfig>(initialStatePageConfig);

    const parameters = {
        "Id": pageConfigList.Help
    }
    const avoidOauthToken = true;   // Fetch interceptor not required for public calls
    const { response, loading }: any = useFetch(GetPageConfigPublic_URL, "POST", {}, parameters, undefined, undefined, avoidOauthToken );

    useEffect(() => {
        updatePageTitle(pageTitles.help);       
    }, []);

    useEffect(() => {
        response && setPageData(response);
    }, [response]);

    return (
        <div className="nhsuk-width-container">
            <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                <div className="nhsuk-grid-row">
                    {loading ?
                        <Loader loadingText={dsaContent.txtLoading} />
                        : <div className="nhsuk-grid-column-two-thirds">
                            <GoBack />
                            <h1>Information to help you </h1>
                            <Label size="s">To help you, we have provided some quick links, an email address and a contact number. The links will open in a new window.  </Label>
                            <Label size="s">Here, you can learn more about the: </Label>
                            <ul>
                                <li>
                                    <a href="https://digital.nhs.uk/services/data-access-request-service-dars/data-access-request-service-dars-process" target="_blank">Data Access Request Service</a>
                                </li>
                                <li>
                                    <a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/dars-how-to-make-an-application" target="_blank">application guidance</a>
                                </li>
                                <li>
                                    <a href="https://digital.nhs.uk/services/data-access-request-service-dars/process/data-access-request-service-dars-pre-application-checklist" target="_blank">pre-application checklist</a>
                                </li>
                                <li>
                                    <a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance#standards-of-information-expected-in-a-data-access-application" target="_blank">standards of information expected</a>
                                </li>
                                <li>
                                    <a href="https://digital.nhs.uk/services/data-access-request-service-dars/data-uses-register" target="_blank">Data Uses Register</a> (Includes extracts from successful applications)
                                </li>
                            </ul>
                            <Label size="s">Information within these links below will help you understand: </Label>
                            <ul>
                                <li>
                                    what <a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-products-and-services" target="_blank">data</a> is available
                                </li>
                                <li>
                                    what <a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/dars-how-to-make-an-application#legal-basis-for-providing-the-data" target="_blank">legal basis</a> will apply
                                </li>
                                <li>
                                    the <a href="https://digital.nhs.uk/services/data-access-request-service-dars/data-access-request-service-dars-charges" target="_blank">charges</a> that will apply for the data you are requesting
                                </li>
                            </ul>
                            <Label size="s">
                                If you wish to apply for tabulated or aggregated data, please contact us before applying for data via the Data Access Request Service at <a href='mailto:enquiries@nhsdigital.nhs.uk?subject=DARS Tabulation Request'>enquiries@nhsdigital.nhs.uk</a> quoting ‘DARS Tabulation Request'.
                            </Label>
                            <Label size="s">
                                If you need any support with your request for data, contact NHS England on 0300 303 5678, or email <a href='mailto:enquiries@nhsdigital.nhs.uk'>enquiries@nhsdigital.nhs.uk </a>
                            </Label>
                            <Label size="s"> The user interface of DARS Online has recently been updated. We are keen to hear your feedback and would like to know if you have had any issues using the website.</Label>

                            <Label size="s"> You can send us feedback by completing the  <a href="https://feedback.digital.nhs.uk/jfe/form/SV_bjyeZ6KBPc967cy" target="_blank"> DARS Online website feedback form.</a></Label>
                            <div className="nhsuk-u-padding-bottom-1"></div>
                            <ReviewDate
                                lastReviewed={pageData.PageLastReviewed}
                                nextReview={pageData.NextReviewDue}
                            />
                        </div>}
                </div>
            </main>
        </div>
    )
}
export default Help;

