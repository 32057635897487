import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from '../../Pages/Dashboard/Dashboard';
import DSFCDetails from '../../Pages/DSFC/DSFCDetails/DSFCDetails';
import DSFCIntroduction from '../../Pages/DSFC/DSFCIntroduction/DSFCIntroduction';
import DSFCPreview from '../../Pages/DSFC/DSFCPreview/DSFCPreview';
import DSFCSignatory from '../../Pages/DSFC/DSFCSignatory/DSFCSignatory';
import DSFCSubmit from '../../Pages/DSFC/DSFCSubmit/DSFCSubmit';
import DSFCApprove from '../../Pages/DSFC/DSFCApprove/DSFCApprove';
import DSFCAgree from '../../Pages/DSFC/DSFCAgree/DSFCAgree';
import DSFCLatestSignedContract from '../../Pages/DSFC/DSFCLatestSignedContract/DSFCLatestSignedContract';
import ApplyDSA from '../../Pages/DSA/ApplyDSA/ApplyDSA'
import ActiveDSA from '../../Pages/DSA/ActiveDSA/ActiveDSA'
import RedirectController from '../../Pages/RedirectController/RedirectController';
import Home from '../../Pages/Home/Home';
import ProtectedRoute from './ProtectedRoutes';
import DSABeforeYouStart from '../../Pages/DSA/DSABeforeYouStart/DSABeforeYouStart';
import DownloadApplyDSA from '../../Pages/DSA/DownloadApplyDSA/DownloadApplyDSA';
import Help from '../../Pages/Help/Help';
import ContactUs from '../../Pages/ContactUs/ContactUs';
import AboutUs from '../../Pages/AboutUs/AboutUs';
import SignOut from '../../Pages/SignOut/SignOut';
import ControllerData from '../../Pages/DSA/Controller/ControllerData/ControllerData';
import DSPTDetails from '../../Pages/DSA/Controller/DSPTDetails/DSPTDetails';
import DataProtectionRegistration from '../../Pages/DSA/Controller/DataProtectionRegistration/DataProtectionRegistration';
import ISO from '../../Pages/DSA/Controller/ISO/ISO';
import ProcessingData from '../../Pages/DSA/Controller/ProcessingData/ProcessingData';
import SecurityAssurance from '../../Pages/DSA/Controller/SecurityAssurance/SecurityAssurance';
import SignDSA from '../../Pages/DSA/Controller/SignDSA/SignDSA';
import PayForThisService from '../../Pages/DSA/Controller/PayForThisService/PayForThisService';
import NoPermission from '../../Pages/NoPermission/NoPermission';
import ManageDSA from '../../Pages/DSA/ManageDSA/ManageDSA';
import LatestNews from '../../Pages/LatestNews/LatestNews';
import NewsDetails from '../../Pages/News/NewsDetails';
import DownloadActiveDSA from '../../Pages/DSA/DownloadActiveDSA/DownloadActiveDSA';
import DSFCDownload from '../../Pages/DSFC/DSFCDownload/DSFCDownload';
import HaveYouCompletedThisSection from '../../Pages/DSA/Controller/HaveYouCompletedThisSection/HaveYouCompletedThisSection';
import AddOrganisationDetails from '../../Pages/DSA/Controller/AddOrganisationDetails/AddOrganisationDetails';
import ControllerDetails from '../../Pages/DSA/Controller/ControllerDetails/ControllerDetails';
import DeleteController from '../../Pages/DSA/Controller/DeleteController/DeleteController';
import AddAnotherController from '../../Pages/DSA/Controller/AddAnotherController/AddAnotherController';
import SLSP from '../../Pages/DSA/Controller/SLSP/SLSP';
/* Processor imports */
import ProcessorISO from '../../Pages/DSA/Processor/ProcessorISO/ProcessorISO';
import ProcessorSecurityAssurance from '../../Pages/DSA/Processor/ProcessorSecurityAssurance/ProcessorSecurityAssurance';
import DeleteProcessor from '../../Pages/DSA/Processor/DeleteProcessor/DeleteProcessor';
import AddAnotherProcessor from '../../Pages/DSA/Processor/AddAnotherProcessor/AddAnotherProcessor';
import ProcessorData from '../../Pages/DSA/Processor/ProcessorData/ProcessorData';
import AddProcessorOrgDetails from '../../Pages/DSA/Processor/AddProcessorOrgDetails/AddProcessorOrgDetails';
import ProcessorDetails from '../../Pages/DSA/Processor/ProcessorDetails/ProcessorDetails';
import ProcessorSLSP from '../../Pages/DSA/Processor/ProcessorSLSP/ProcessorSLSP';
import SolelyProcessor from '../../Pages/DSA/Processor/SolelyProcessor/SolelyProcessor';
import HaveYouAddedAllProcessors from '../../Pages/DSA/Processor/HaveYouAddedAllProcessors/HaveYouAddedAllProcessors';
import ProcessorDSPTDetails from '../../Pages/DSA/Processor/ProcessorDSPTDetails/ProcessorDSPTDetails';
import DataProtectionRegistrationProcessor from '../../Pages/DSA/Processor/ProcessorDataProtectionRegistration/ProcessorDataProtectionRegistration';
import SelectDataSet from '../../Pages/DSA/DataSet/SelectDataSet/SelectDataSet';
import DeleteDataSet from '../../Pages/DSA/DataSet/DeleteDataSet/DeleteDataSet';
import HaveYouAddedAllDataSet from '../../Pages/DSA/DataSet/HaveYouAddedAllDataset/HaveYouAddedAllDataSet';
import DataSetIntro from '../../Pages/DSA/DataSet/DataSetIntro/DataSetIntro';
import AggregatedOrRecord from '../../Pages/DSA/DataSet/AggregatedOrRecord/AggregatedOrRecord';
import DataSetToCohort from '../../Pages/DSA/DataSet/DataSetToCohort/DataSetToCohort';
import DatasetFrequency from '../../Pages/DSA/DataSet/DatasetFrequency/DatasetFrequency';
import SelectDataSetFields from '../../Pages/DSA/DataSet/SelectDataSetFields/SelectDataSetFields';
import HistoricAnnualData from '../../Pages/DSA/DataSet/HistoricAnnualData/HistoricAnnualData';
import DataSetDetails from '../../Pages/DSA/DataSet/DataSetDetails/DataSetDetails';
import DSAApprove from '../../Pages/DSA/DSAApprove/DSAApprove';
import DSAConfirmation from '../../Pages/DSA/Confirmation/Confirmation'
import DataDuration from '../../Pages/DSA/DataSet/DataDuration/DataDuration';
import ParticipantData from '../../Pages/DSA/Participant/ParticipantData/ParticipantData';
import ParticipantType from '../../Pages/DSA/Participant/ParticipantTypes/ParticipantTypes';
import ExsistingType from '../../Pages/DSA/Participant/ExistingType/ExistingType';
import LegalBasis from '../../Pages/DSA/Participant/LegalBasis/LegalBasis';
import ParticipantSummary from '../../Pages/DSA/Participant/Summary/Summary';
import HaveAllRelevantParticipantData from '../../Pages/DSA/Participant/HaveAllRelevantParticipantData/HaveAllRelevantParticipantData';
import WhoWillProvide from '../../Pages/DSA/Participant/WhoWillProvide/WhoWillProvide';
import AddCohortContributor from '../../Pages/DSA/Participant/AddCohortContributor/AddCohortContributor';
import DeleteParticipant from '../../Pages/DSA/Participant/DeleteParticipant/DeleteParticipant';
import ProposedDSATerm from '../../Pages/DSA/DSASummary/ProposedDSATerm/ProposedDSATerm';
import FundingType from '../../Pages/DSA/Funding/FundingType/FundingType'
import FundingDocument from '../../Pages/DSA/Funding/FundingDocument/FundingDocument';
import FundingInformation from '../../Pages/DSA/Funding/FundingInformation/FundingInformation';
import UploadedDocumentDetails from '../../Pages/DSA/Funding/UploadedDocumentDetails/UploadedDocumentDetails';
import DataSetCheckYourAnswer from '../../Pages/DSA/DataSet/DataSetCheckYourAnswer/DataSetCheckYourAnswer';
import PageNotFound from '../Errors/PageNotFound';
import ServiceUnavailable from '../Errors/ServiceUnavailable';
import ServiceError from '../Errors/ServiceError';
import DataRequestReason from '../../Pages/DSA/DSASummary/DataRequestReason/DataRequestReason';
import DataRequestProject from '../../Pages/DSA/DSASummary/DataRequestProject/DataRequestProject';
import DeleteFunding from '../../Pages/DSA/Funding/DeleteFunding/DeleteFunding';
import Commercial from '../../Pages/DSA/Purpose/Commercial/Commercial';
import CommercialPurpose from '../../Pages/DSA/Purpose/CommercialPurpose/CommercialPurpose';
import Sublicensing from '../../Pages/DSA/Purpose/Sublicensing/Sublicensing';
import SublicensingDuration from '../../Pages/DSA/Purpose/SublicensingDuration/SublicensingDuration';
import ProposedDSADate from '../../Pages/DSA/DSASummary/ProposedDSADate/ProposedDSADate';
import LinkParticipantData from '../../Pages/DSA/Participant/LinkingParticipant/LinkingParticipant';
import UploadConsent from '../Shared/UploadDocument';
import UploadedFiles from '../Shared/UploadedFiles';
import UploadNHSHealth from '../../Pages/DSA/Participant/UploadNHShealth/UploadNHSHealth';
import ExpectedOutputs from '../../Pages/DSA/Purpose/ExpectedOutputs/ExpectedOutputs';
import ExpectedBenefits from '../../Pages/DSA/Purpose/ExpectedBenefits/ExpectedBenefits';
import HaveYouCompletedTheExpectedSection from '../../Pages/DSA/Purpose/HaveYouCompletedTheExpectedSection/HaveYouCompletedTheExpectedSection';
import ExpectedCheckYourAnswers from '../../Pages/DSA/Purpose/ExpectedCheckYourAnswers/ExpectedCheckYourAnswers';
import DataMinimisation from '../../Pages/DSA/DataSet/DataMinimisation/DataMinimisation';
import IdentifiableData from '../../Pages/DSA/DataSet/IdentifiableData/IdentifiableData';
import LegalBasesIdentifiableData from '../../Pages/DSA/DataSet/LegalBasesIdentifiableData/LegalBasesIdentifiableData';
import ObjectiveAndProcessing from '../../Pages/DSA/Purpose/ObjectiveAndProcessing/ObjectiveAndProcessing';
import ProcessingActivities from '../../Pages/DSA/Purpose/ProcessingActivities/ProcessingActivities';
import ObjectiveAndProcessingCheckYourAnswer from '../../Pages/DSA/Purpose/ObjectiveAndProcessingCheckYourAnswer/ObjectiveAndProcessingCheckYourAnswer';
import HaveYouCompletedObjectiveAndProcessing from '../../Pages/DSA/Purpose/HaveYouCompletedObjectiveAndProcessing/HaveYouCompletedObjectiveAndProcessing';
import UploadSupportingEvidence from '../../Pages/DSA/Evidence/UploadSupportingEvidence/UploadSupportingEvidence';
import EvidenceType from '../../Pages/DSA/Evidence/EvidenceType/EvidenceType';
import EvidenceApproval from '../../Pages/DSA/Evidence/EvidenceApproval/EvidenceApproval';
import EthicsApproval from '../../Pages/DSA/Evidence/EthicsApproval/EthicsApproval';
import NoEthicsApproval from '../../Pages/DSA/Evidence/NoEthicsApproval/NoEthicsApproval';
import EthicsApprovalNotInPlace from '../../Pages/DSA/Evidence/EthicsApprovalNotInPlace/EthicsApprovalNotInPlace';
import DeleteDocument from '../Shared/DeleteDocument'
import LegalBases from '../../Pages/DSA/DataSet/LegalBases/LegalBases';
import PrivacyNotice from '../../Pages/DSA/PrivacyNotice/PrivacyNotice/PrivacyNotice';
import SelectLocations from '../../Pages/DSA/Locations/SelectLocations/SelectLocations';
import NoPrivacyNotice from '../../Pages/DSA/PrivacyNotice/NoPrivacyNotice/NoPrivacyNotice';
import Declaration from '../../Pages/DSA/SubmitApplication/Declaration/Declaration';
import DSASubmit from '../../Pages/DSA/SubmitApplication/DSASubmit/DSASubmit';
import SummaryCommmercial from '../../Pages/DSA/Purpose/SummaryCommercial/SummaryCommercial';
import HaveYouCompletedCommercial from '../../Pages/DSA/Purpose/HaveYouCompletedCommercial/HaveYouCompletedCommercial';
import ManageBeforeYouStart from '../../Pages/DSA/Manage/ManageBeforeYouStart/ManageBeforeYouStart';
import AmendBeforeYouStart from '../../Pages/DSA/ManageDSA/Amend/AmendBeforeYouStart/AmendBeforeYouStart';
import ChooseSection from '../../Pages/DSA/ManageDSA/Amend/ChooseSection/ChooseSection';
import Guidance from '../../Pages/Guidance/Guidance';
import ScrollToTop from '../Shared/ScrollToTop';
import App from '../../App';
import ClosingAgreement from '../../Pages/DSA/Manage/ClosingAgreement/ClosingAgreement';
import NoExistingAgreement from '../../Pages/DSA/Participant/NoExistingAgreement/NoExistingAgreement';
import { routeName } from '../../config/page.config';
import AddAnotherSLSP from '../../Pages/DSA/Controller/AddAnotherSLSP/AddAnotherSLSP';
import AddAnotherFileRecord from '../Shared/AddAnotherFileRecord';
import ApprovalEvidenceData from '../../Pages/DSA/DataSet/ApprovalEvidenceData/ApprovalEvidenceData';
import AddAnotherProcessorSLSP from '../../Pages/DSA/Processor/AddAnotherProcessorSLSP/AddAnotherProcessorSLSP';
import AddAnotherEthicsReview from '../Shared/AddAnotherEthicsReview';
import AddAnotherFunding from '../../Pages/DSA/Funding/AddAnotherFunding/AddAnotherFunding';
import DSATaskList from '../../Pages/DSA/DSATaskList/DSATaskList';
import AddAnotherApprovalEvidence from '../../Pages/DSA/Evidence/AddAnotherApprovalEvidence/AddAnotherApprovalEvidence';
import HaveYouCompletedYieldedBenefits from '../../Pages/DSA/Purpose/HaveYouCompletedYieldedBenefits/HaveYouCompletedYieldedBenefits';
import YieldedBenefits from '../../Pages/DSA/Purpose/YieldedBenefits/YieldedBenefits';
import Accessibility from '../../Pages/Accessibility/Accessibility';
import PONumber from '../../Pages/DSA/PONumber/PONumber';

const RouteConfig: React.FC = () => {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<App />}>
                    {/** Public Routes */}
                    <Route path="/" element={<Home />} />
                    <Route path="/help" element={<Help />} />
                    <Route path="/latest-news" element={<LatestNews />} />
                    <Route path="/latest-news/news-details" element={<NewsDetails />} />
                    <Route path="/guidance" element={<Guidance />} />
                    <Route path="/contactus" element={<ContactUs />} />
                    <Route path={routeName.accessibility} element={<Accessibility />} />
                    <Route path="/aboutus" element={<AboutUs />} />
                    <Route path="/signout" element={<SignOut />} />
                    <Route path="/dsfcdownload" element={<DSFCDownload />} />
                    <Route path="/serviceunavailable" element={<ServiceUnavailable />} />
                    <Route path="/serviceerror" element={<ServiceError />} />
                    <Route path="*" element={<PageNotFound />} />

                    {/** Private Routes */}
                    <Route path={routeName.noPermission} element={<NoPermission />} />
                    <Route path="/dsfc">
                        <Route path={routeName.dashboard} element={<Dashboard />} />
                    </Route>
                    <Route path="/" element={<ProtectedRoute />}>
                        <Route path={routeName.redirectController} element={<RedirectController />} />
                        <Route path={routeName.deleteDocument} element={<DeleteDocument />} />
                        <Route path="/dsfc">
                            <Route path={routeName.dashboard} element={<Dashboard />} />
                            <Route path={routeName.dsfcIntro} element={<DSFCIntroduction />} />
                            <Route path={routeName.dsfcDetails} element={<DSFCDetails />} />
                            <Route path={routeName.dsfcSignatory} element={<DSFCSignatory />} />
                            <Route path={routeName.dsfcPreview} element={<DSFCPreview />} />
                            <Route path={routeName.dsfcSubmitContract} element={<DSFCSubmit />} />
                            <Route path={routeName.dsfcApprove} element={<DSFCApprove />} />
                            <Route path={routeName.dsfcAgree} element={<DSFCAgree />} />
                            <Route path={routeName.latestSignedContract} element={<DSFCLatestSignedContract />} />
                        </Route>
                        <Route path="/dsa">
                            <Route path={routeName.activeDsa} element={<ActiveDSA />} />
                            <Route path={routeName.applyDsa} element={<ApplyDSA />} />
                            <Route path={routeName.managedsa} element={<ManageDSA />} />
                            <Route path={routeName.manageBeforeYouStart} element={<ManageBeforeYouStart />} />
                            <Route path={routeName.amendBeforeYouStart} element={<AmendBeforeYouStart />} />
                            <Route path={routeName.dsaBeforeYouStart} element={<DSABeforeYouStart />} />
                            <Route path={routeName.dsaTaskList} element={<DSATaskList />} />
                            <Route path={routeName.downloadApplyDsa} element={<DownloadApplyDSA />} />
                            <Route path={routeName.controllerData} element={<ControllerData />} />
                            <Route path={routeName.dsptDetails} element={<DSPTDetails />} />
                            <Route path={routeName.dataProtectionRegistration} element={<DataProtectionRegistration />} />
                            <Route path={routeName.dsaIso} element={<ISO />} />
                            <Route path={routeName.processingData} element={<ProcessingData />} />
                            <Route path={routeName.securityAssurance} element={<SecurityAssurance />} />
                            <Route path={routeName.dsaSign} element={<SignDSA />} />
                            <Route path={routeName.payForThisService} element={<PayForThisService />} />
                            <Route path={routeName.downloadActiveDsa} element={<DownloadActiveDSA />} />
                            <Route path={routeName.completedControllerSection} element={<HaveYouCompletedThisSection />} />
                            <Route path={routeName.addOrganisationDetails} element={<AddOrganisationDetails />} />
                            <Route path={routeName.controllerDetails} element={<ControllerDetails />} />
                            <Route path={routeName.deleteController} element={<DeleteController />} />
                            <Route path={routeName.addAnotherController} element={<AddAnotherController />} />
                            <Route path={routeName.dsaSlsp} element={<SLSP />} />
                            <Route path={routeName.addAnotherSlsp} element={<AddAnotherSLSP />} />
                            <Route path={routeName.addAnotherprocessorSlsp} element={<AddAnotherProcessorSLSP />} />
                            <Route path={routeName.processorData} element={<ProcessorData />} />
                            <Route path={routeName.addProcessorOrganisation} element={<AddProcessorOrgDetails />} />
                            <Route path={routeName.processorDetails} element={<ProcessorDetails />} />
                            <Route path={routeName.processorIso} element={<ProcessorISO />} />
                            <Route path={routeName.processorSecurityAssurance} element={<ProcessorSecurityAssurance />} />
                            <Route path={routeName.deleteProcessor} element={<DeleteProcessor />} />
                            <Route path={routeName.addAnotherController} element={<AddAnotherProcessor />} />
                            <Route path={routeName.processorSlsp} element={<ProcessorSLSP />} />
                            <Route path={routeName.solelyProcessor} element={<SolelyProcessor />} />
                            <Route path={routeName.addedAllProcessors} element={<HaveYouAddedAllProcessors />} />
                            <Route path={routeName.processorDsptDetails} element={<ProcessorDSPTDetails />} />
                            <Route path={routeName.dataProtectionRegistrationProcessor} element={<DataProtectionRegistrationProcessor />} />
                            <Route path={routeName.selectDataset} element={<SelectDataSet />} />
                            <Route path={routeName.deleteDataset} element={<DeleteDataSet />} />
                            <Route path={routeName.addedAllDataSet} element={<HaveYouAddedAllDataSet />} />
                            <Route path={routeName.datasetIntro} element={<DataSetIntro />} />
                            <Route path={routeName.aggredatedRecord} element={<AggregatedOrRecord />} />
                            <Route path={routeName.dsaCohort} element={<DataSetToCohort />} />
                            <Route path={routeName.datasetFrequency} element={<DatasetFrequency />} />
                            <Route path={routeName.selectDatasetField} element={<SelectDataSetFields />} />
                            <Route path={routeName.historicAnnualData} element={<HistoricAnnualData />} />
                            <Route path={routeName.datasetDetails} element={<DataSetDetails />} />
                            <Route path={routeName.dsaArrove} element={<DSAApprove />} />
                            <Route path={routeName.dsaConfirmation} element={<DSAConfirmation />} />
                            <Route path={routeName.dataDuration} element={<DataDuration />} />
                            <Route path={routeName.participantData} element={<ParticipantData />} />
                            <Route path={routeName.participantTypes} element={<ParticipantType />} />
                            <Route path={routeName.existingType} element={<ExsistingType />} />
                            <Route path={routeName.legalBasis} element={<LegalBasis />} />
                            <Route path={routeName.participantSummary} element={<ParticipantSummary />} />
                            <Route path={routeName.relevantParticipantData} element={<HaveAllRelevantParticipantData />} />
                            <Route path={routeName.whoWillProvide} element={<WhoWillProvide />} />
                            <Route path={routeName.addCohortContributor} element={<AddCohortContributor />} />
                            <Route path={routeName.deleteParticipant} element={<DeleteParticipant />} />
                            <Route path={routeName.proposedDsaTerm} element={<ProposedDSATerm />} />
                            <Route path={routeName.fundingType} element={<FundingType />} />
                            <Route path={routeName.fundingDocument} element={<FundingDocument />} />
                            <Route path={routeName.fundingInformation} element={<FundingInformation />} />
                            <Route path={routeName.addAnotherFunding} element={<AddAnotherFunding />} />
                            <Route path={routeName.addAnotherEthicsReview} element={<AddAnotherEthicsReview />} />  
                            <Route path={routeName.uploadDocumentDetails} element={<UploadedDocumentDetails />} />
                            <Route path={routeName.datasetCheckYourAnswer} element={<DataSetCheckYourAnswer />} />
                            <Route path={routeName.dataRequestReason} element={<DataRequestReason />} />
                            <Route path={routeName.dataRequestProject} element={<DataRequestProject />} />
                            <Route path={routeName.deleteFunding} element={<DeleteFunding />} />
                            <Route path={routeName.commercial} element={<Commercial />} />
                            <Route path={routeName.commercialPurpose} element={<CommercialPurpose />} />
                            <Route path={routeName.dsaSublicensing} element={<Sublicensing />} />
                            <Route path={routeName.sublicensingDuration} element={<SublicensingDuration />} />
                            <Route path={routeName.summaryCommercial} element={<SummaryCommmercial />} />
                            <Route path={routeName.completedCommercialSection} element={<HaveYouCompletedCommercial />} />
                            <Route path={routeName.proposedDate} element={<ProposedDSADate />} />
                            <Route path={routeName.linkingParticipant} element={<LinkParticipantData />} />
                            <Route path={routeName.uploadConsent} element={<UploadConsent />} />
                            <Route path={routeName.addAnotherFileRecord} element={<AddAnotherFileRecord />} />
                            <Route path={routeName.uploadedFiles} element={<UploadedFiles />} />
                            <Route path={routeName.uploadNhsHealth} element={<UploadNHSHealth />} />
                            <Route path={routeName.expectedOutputs} element={<ExpectedOutputs />} />
                            <Route path={routeName.expectedBenefits} element={<ExpectedBenefits />} />
                            <Route path={routeName.expectedCheckYourAnswer} element={<ExpectedCheckYourAnswers />} />
                            <Route path={routeName.completedExpectedSection} element={<HaveYouCompletedTheExpectedSection />} />
                            <Route path={routeName.dataMinimisation} element={<DataMinimisation />} />
                            <Route path={routeName.identifiableData} element={<IdentifiableData />} />
                            <Route path={routeName.legalBasesIdentifiableData} element={<LegalBasesIdentifiableData />} />
                            <Route path={routeName.objectiveProcessing} element={<ObjectiveAndProcessing />} />
                            <Route path={routeName.processingActivities} element={<ProcessingActivities />} />
                            <Route path={routeName.objectiveProcessingCheckAnswer} element={<ObjectiveAndProcessingCheckYourAnswer />} />
                            <Route path={routeName.completedObjectiveProcessing} element={<HaveYouCompletedObjectiveAndProcessing />} />
                            <Route path={routeName.uploadSupportingEvidence} element={<UploadSupportingEvidence />} />
                            <Route path={routeName.evidenceType} element={<EvidenceType />} />
                            <Route path={routeName.evidenceApproval} element={<EvidenceApproval />} />
                            <Route path={routeName.ethicsApproval} element={<EthicsApproval />} />
                            <Route path={routeName.noEthicsApproval} element={<NoEthicsApproval />} />
                            <Route path={routeName.ethicsApprovalNotInPlace} element={<EthicsApprovalNotInPlace />} />
                            <Route path={routeName.deleteDocument} element={<DeleteDocument />} />
                            <Route path={routeName.legalBases} element={<LegalBases />} />
                            <Route path={routeName.selectLocation} element={<SelectLocations />} />
                            <Route path={routeName.privacyNotice} element={<PrivacyNotice />} />
                            <Route path={routeName.noPrivacyNotice} element={<NoPrivacyNotice />} />
                            <Route path={routeName.selectLocation} element={<SelectLocations />} />
                            <Route path={routeName.declaration} element={<Declaration />} />
                            <Route path={routeName.dsaSubmit} element={<DSASubmit />} />
                            <Route path={routeName.chooseAmendSection} element={<ChooseSection />} />
                            <Route path={routeName.closingAgreement} element={<ClosingAgreement />} />
                            <Route path={routeName.noExistingAgreement} element={<NoExistingAgreement />} />
                            <Route path={routeName.approvalEvidenceData} element={<ApprovalEvidenceData />} />
                            <Route path={routeName.addAnotherApprovalEvidence} element={<AddAnotherApprovalEvidence />} />
                            <Route path={routeName.completedYieldedBenefits} element={<HaveYouCompletedYieldedBenefits />} />
                            <Route path={routeName.yieldedBenefits} element={<YieldedBenefits />} />
                            <Route path={routeName.poNumber} element={<PONumber />} />
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </Router>
    );
}

export default RouteConfig;