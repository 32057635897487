import React, { useEffect, useState } from "react";
import { Button, InsetText, Label, Fieldset, Radios, ErrorSummary } from "nhsuk-react-components";
import { applicationChangeType, dsaContent, dsaErrorMessage, routeName } from "../../../config/page.config";
import InfoHeaderPanel from "../../../Components/InfoHeaderPanel/InfoHeaderPanel";
import { IApplication, IUserDetails } from "../../../state/models/app.interface";
import { useAppDispatch, useAppSelector } from "../../../state/app/hooks";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../../state/slice/Header/Header.slice";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../state/slice/DSA/DSA.slice";
import { AppDispatch } from "../../../state/app/store";
import GoBack from "../../../Components/Shared/GoBack";
import { submitSpinner } from "../../../Helper/Utility";

const ManageDSA: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const initialStateofUserData: IUserDetails = useAppSelector(getUserDetails) || {};
    const [userData,] = useState(initialStateofUserData);
    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [applicationChangeValue, setApplicationChangeValue] = useState("");
    const saveDSAApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IApplication = {};
        updatedState = {
            ...dsaApplicationData,
            applicationChangeType: e.target.value
        }
        setDSAApplicationDetailsData(updatedState);
        saveDSAApplicationDataInStore(updatedState);
        setApplicationChangeValue(e.target.value);
        setSummaryLinkErrorMessage("");
    }

    const handleNavigation = () => {
        if (String(dsaApplicationData.applicationChangeType) === applicationChangeType.Extension || String(dsaApplicationData.applicationChangeType) === applicationChangeType.Renewal)
            navigate(routeName.manageBeforeYouStart);
        else if (String(dsaApplicationData.applicationChangeType) === applicationChangeType.Amendment)
            navigate(routeName.amendBeforeYouStart);
        else if (String(dsaApplicationData.applicationChangeType) === applicationChangeType.Close)
            navigate(routeName.closingAgreement);
    }

    /* To handle Navigation */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (applicationChangeValue != null && applicationChangeValue != '') {
            submitSpinner(e, handleNavigation);
        }
        else {
            setSummaryLinkErrorMessage(`${dsaErrorMessage.manageDsa_ErrorMessage}`);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#Manage">{summaryLinkErrorMessage}</ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    const setApplicationChangeTypeForSate = () => {
        if (applicationChangeValue === '' && ((String(dsaApplicationData.applicationChangeType) === String(applicationChangeType.Extension) || String(dsaApplicationData.applicationChangeType) === String(applicationChangeType.Renewal) || String(dsaApplicationData.applicationChangeType) === String(applicationChangeType.Amendment)))) {
            setApplicationChangeValue(dsaApplicationData?.applicationChangeType ? dsaApplicationData?.applicationChangeType : '')
        }
    }
    useEffect(() => {
        setApplicationChangeTypeForSate()
    }, [])
    /* To handle Navigation */
    return (
        <>
            <InfoHeaderPanel>
                <InfoHeaderPanel.Item >
                    <strong>Reference Number</strong> : {dsaApplicationData.referenceNumber}
                </InfoHeaderPanel.Item>
            </InfoHeaderPanel>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-full">
                            <GoBack condition={dsaContent.activeDsa} />
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                {userData?.userOrgName}
                            </span>
                            <h1>Manage your Data Sharing Agreement</h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <InsetText>
                                <p>
                                    <Label>Changes you make can be viewed and managed under 'Manage applications'</Label>
                                </p>
                            </InsetText>
                            <Label size="s">Select which action you want to take on this Data Sharing Agreement (DSA)</Label>
                            <Fieldset>
                                <Radios name="Manage" id="Manage" hint="" error={summaryLinkErrorMessage}>
                                    <Radios.Radio name="Manage" checked={String(dsaApplicationData.applicationChangeType) === applicationChangeType.Extension} value={applicationChangeType.Extension} onChange={handleChange}>Extend my DSA - retaining the data I have and not requiring any new data</Radios.Radio>
                                    <Radios.Radio name="Manage" checked={String(dsaApplicationData.applicationChangeType) === applicationChangeType.Renewal}
                                        value={applicationChangeType.Renewal} onChange={handleChange} >Renew my DSA - requesting more data from the same data set(s)</Radios.Radio>
                                    <Radios.Radio name="Manage" checked={String(dsaApplicationData.applicationChangeType) === applicationChangeType.Amendment} value={applicationChangeType.Amendment} onChange={handleChange} >Amend my DSA - changing anything else in my DSA</Radios.Radio>
                                    <Radios.Radio name="Manage" checked={String(dsaApplicationData.applicationChangeType) === applicationChangeType.Close} value={applicationChangeType.Close} onChange={handleChange}>Close my DSA - closing my DSA</Radios.Radio>
                                </Radios>
                            </Fieldset>
                            <br />
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnSaveAndContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default ManageDSA;
