import React from 'react';
import classNames from 'classnames';
import type { AsElementLink } from './LinkTypes';

const AttachmentLink: React.FC<AsElementLink<HTMLAnchorElement>> = ({
    className,
    asElement: Component = 'a',
    ...rest
}) => (
    <Component className={classNames('nhsuk-link nhs-attachment__link', className)} {...rest} />
);

export default AttachmentLink;