import { Button, SummaryList } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import GoBack from "../../../../Components/Shared/GoBack";
import { GetCommercialPurposeAndSublicensing_URL } from "../../../../config/api-endpoints.config";
import { fetchInterceptor, getCookie, getTaskListType, submitSpinner } from "../../../../Helper/Utility";
import { IApiResCommercialPurposeAndSublicensing, IApplication, IPurpose } from "../../../../state/models/app.interface";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { dsaContent, fileTypeSize, routeName } from "../../../../config/page.config";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getPurposeDetails, setPurposeDetailsData } from "../../../../state/slice/Purpose/Purpose.slice";
import ExpandCollapse from "../../../../Components/ExpandCollapse/ExpandCollapse";

const SummaryCommercial: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [loading, setLoading] = useState(false);
    const dispatch: AppDispatch = useAppDispatch();
    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};
    const [purposeData, setPurposeData] = useState<IPurpose>(useAppSelector(getPurposeDetails) || {});
    const savePurposeDataInStore = (stateData?: IPurpose) => {
        dispatch(setPurposeDetailsData({ ...purposeData, ...stateData }));
    }

    /*Get, Set data on load*/
    const getCommercialPurposeAndSublicensing = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetCommercialPurposeAndSublicensing_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setCommercialPurposeAndSublicensing(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setCommercialPurposeAndSublicensing = (response: IApiResCommercialPurposeAndSublicensing) => {
        let purpose: IPurpose = {};
        if (response) {
            purpose.applicationForCommercialPurposes = response.ApplicationForCommercialPurposes === true ? dsaContent.yes : dsaContent.no;
            purpose.applicationForCommercialPurposesExplanation = response.ApplicationForCommercialPurposesExplanation;
            purpose.doesSublicensingApply = response.DoesSublicensingApply === true ? dsaContent.yes : dsaContent.no;
            purpose.duration = response.Duration;
            purpose.commercialSublicensingCheckYourAnswerPage = "";
        }
        setPurposeData(purpose);
        savePurposeDataInStore(purpose);
        setLoading(false);
    }
    /*Get, Set data on load*/

    /*Manage Navigation */
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, pageNavigation: string) => {
        e.preventDefault();
        let updatedState: IPurpose = {};
        updatedState = {
            ...purposeData,
            commercialSublicensingCheckYourAnswerPage: pageNavigation
        }
        setPurposeData(updatedState);
        savePurposeDataInStore(updatedState);
        navigate(pageNavigation)
    }

    const navigateCompletedCommercialSection = () => {
        navigate(routeName.completedCommercialSection);
    }

    const handleClickContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        submitSpinner(e, navigateCompletedCommercialSection);
    }
    /*Manage Navigation */

    useEffect(() => {
        getCommercialPurposeAndSublicensing();
    }, []);

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">

                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <div className="nhsuk-grid-column-two-thirds">
                                {
                                    <GoBack condition={getTaskListType(dsaApplicationData.returningJourneyType!)} />
                                }
                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    Commercial purposes and sub-licensing</span>
                                    Check your answers
                                </h1>
                                <SummaryList>
                                    <SummaryList.Row>
                                        <SummaryList.Key>
                                            <h3>Commercial purposes</h3>
                                        </SummaryList.Key>
                                        <SummaryList.Value>
                                        </SummaryList.Value>
                                        <SummaryList.Actions>
                                        </SummaryList.Actions>
                                    </SummaryList.Row>
                                    <SummaryList.Row>
                                        <SummaryList.Key>
                                            Would any shared data from NHS England be used for commercial purposes?
                                        </SummaryList.Key>
                                        <SummaryList.Value>
                                            {purposeData.applicationForCommercialPurposes ? purposeData.applicationForCommercialPurposes : ""}
                                        </SummaryList.Value>
                                        <SummaryList.Actions>
                                            <a href="" onClick={(e) => handleClick(e,routeName.commercial)}>Change</a>
                                        </SummaryList.Actions>
                                    </SummaryList.Row>
                                    {purposeData.applicationForCommercialPurposes === dsaContent.yes ?
                                        <SummaryList.Row>
                                            <SummaryList.Key>
                                                Provide details on how any shared data from NHS England would be used for commercial purposes
                                            </SummaryList.Key>
                                            <SummaryList.Value>
                                                {purposeData.applicationForCommercialPurposesExplanation ? <ExpandCollapse content={purposeData.applicationForCommercialPurposesExplanation!} /> : ""}
                                            </SummaryList.Value>
                                            <SummaryList.Actions>
                                                <a href="" onClick={(e) => handleClick(e,routeName.commercialPurpose)}>Change</a>
                                            </SummaryList.Actions>
                                        </SummaryList.Row>
                                        : null}
                                </SummaryList>
                                <SummaryList>
                                    <SummaryList.Row>
                                        <SummaryList.Key>
                                            <h3> Sub-licensing</h3>
                                        </SummaryList.Key>
                                        <SummaryList.Value>
                                        </SummaryList.Value>
                                        <SummaryList.Actions>
                                        </SummaryList.Actions>
                                    </SummaryList.Row>
                                    <SummaryList.Row>
                                        <SummaryList.Key>
                                            Will you be sub-licensing the data?
                                        </SummaryList.Key>
                                        <SummaryList.Value>
                                            {purposeData.doesSublicensingApply ? purposeData.doesSublicensingApply : ""}
                                        </SummaryList.Value>
                                        <SummaryList.Actions>
                                            <a href="" onClick={(e) => handleClick(e,routeName.dsaSublicensing)}>Change</a>
                                        </SummaryList.Actions>
                                    </SummaryList.Row>
                                    {purposeData.doesSublicensingApply === dsaContent.yes ?
                                        <SummaryList.Row>
                                            <SummaryList.Key>
                                                How long do you intend to share data with an organisation(s) not mentioned in the Data Sharing Agreement?
                                            </SummaryList.Key>
                                            <SummaryList.Value>
                                                {purposeData.duration ? <ExpandCollapse content={purposeData.duration} /> : ""}
                                            </SummaryList.Value>
                                            <SummaryList.Actions>
                                                {<Link to={routeName.sublicensingDuration}>Change </Link>}
                                            </SummaryList.Actions>
                                        </SummaryList.Row>
                                        : null}
                                </SummaryList>
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClickContinue} >{dsaContent.btnTextContinue}</Button>
                            </div>
                        }
                    </div>
                </main>
            </div>

        </>)
}

export default SummaryCommercial;