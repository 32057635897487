import { Button} from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ExpandCollapse from "../../../../Components/ExpandCollapse/ExpandCollapse";
import Loader from "../../../../Components/Loader/Loader";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import { GetOutputsAndBenefits_URL } from "../../../../config/api-endpoints.config";
import { dsaContent, fileTypeSize, routeName } from "../../../../config/page.config";
import { fetchInterceptor, getCookie, getTaskListType, submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApiResOutputsAndBenefits, IApplication, IPurpose } from "../../../../state/models/app.interface";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getPurposeDetails, setPurposeDetailsData } from "../../../../state/slice/Purpose/Purpose.slice";

const ExpectedCheckYourAnswers: React.FC = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [purposeData, setPurposeData] = useState(useAppSelector(getPurposeDetails) || {});

    const savePurposeDataInStore = (stateData?: IPurpose) => {
        dispatch(setPurposeDetailsData({ ...purposeData, ...stateData }));
    }

    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};

    const handleCLick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, pageNavigation: string) => {
        e.preventDefault();
        let updatedState: IPurpose = {};
        updatedState = {
            ...purposeData,
            expectedCheckYourAnswerPage: pageNavigation

        }
        setPurposeData(updatedState);
        savePurposeDataInStore(updatedState);
        navigate(pageNavigation)
    }

    const navigatecompletedExpected = () => {
        navigate(routeName.completedExpectedSection)
    }

    const handleClickContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        submitSpinner(e, navigatecompletedExpected);
    }

    const getOutputsAndBenefits = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetOutputsAndBenefits_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setOutputsAndBenefits(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setOutputsAndBenefits = (response: IApiResOutputsAndBenefits) => {
        let purpose: IPurpose = {};
        if (response) {
            purpose.specificOutputsExpected = response.SpecificOutputsExpected;
            purpose.expectedMeasurableBenefits = response.ExpectedMeasurableBenefits;
            purpose.expectedCheckYourAnswerPage = "";
        }
        setPurposeData(purpose);
        savePurposeDataInStore(purpose);
        setLoading(false);
    }

    useEffect(() => {
        getOutputsAndBenefits();
    }, []);

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <div className="nhsuk-grid-column-full">
                                {
                                    <GoBack condition={getTaskListType(dsaApplicationData.returningJourneyType!)} />
                                }
                                <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                    Expected outputs and benefits
                                </span>
                                <h1>Check your answers</h1>
                                <table role="table" className="nhsuk-table-responsive">
                                    <tbody className="nhsuk-table__body">
                                        <tr role="row" className="nhsuk-table__row">
                                            <th role="columnheader" scope="col">
                                                Outputs
                                            </th>
                                            <td role="cell" className="nhsuk-table__cell">
                                                {purposeData.specificOutputsExpected ? <ExpandCollapse content={purposeData.specificOutputsExpected!} /> : ""}
                                            </td>
                                            <td role="cell" className="nhsuk-table__cell">
                                                <a href="" onClick={(e) => handleCLick(e, routeName.expectedOutputs)}>Change</a>
                                            </td>
                                        </tr>
                                        <tr role="row" className="nhsuk-table__row">
                                            <th role="columnheader" scope="col">
                                                Benefits
                                            </th>
                                            <td role="cell" className="nhsuk-table__cell">
                                                {purposeData.expectedMeasurableBenefits ? <ExpandCollapse content={purposeData.expectedMeasurableBenefits!} /> : ""}
                                            </td>
                                            <td role="cell" className="nhsuk-table__cell">
                                                {<Link to={routeName.expectedBenefits}>Change </Link>}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClickContinue} >{dsaContent.btnTextContinue}</Button>
                            </div>
                        }
                    </div>
                </main>
            </div>

        </>
    );
}
export default ExpectedCheckYourAnswers