import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Radios } from "nhsuk-react-components";
import { dsaContent, dsaControllerErrorMessage, routeName } from "../../../../config/page.config";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader'
import ErrorDetails from '../../../../Components/Shared/ErrorSummary';
import { useAppSelector } from "../../../../state/app/hooks";
import { getControllerDetails } from "../../../../state/slice/DSAAboutController/DSAAboutController.slice";
import { IController } from "../../../../state/models/app.interface";
import GoBack from "../../../../Components/Shared/GoBack";
import {submitSpinner } from "../../../../Helper/Utility";
import { getControllerListDetails } from "../../../../state/slice/ControllerList/ControllerList.slice";

const AddAnotherController: React.FC = () => {
    let navigate = useNavigate();
    const [addControllerValue, setAddControllerValue] = useState('');
    const controllerDetailsData: IController = useAppSelector(getControllerDetails);
    const initialState: IController = controllerDetailsData || {};
    const [controllerData] = useState(initialState);
    const [addControllerErrorMessage, setAddControllerErrorMessage] = useState("");
       
    const [controllerListData, ] = useState(useAppSelector(getControllerListDetails) || {});
    const handleControllerNavigation = () => {
        if (addControllerValue === dsaContent.yes) {
            navigate(routeName.controllerData);
        } else if (controllerData.isPayingForDSA === true && controllerListData.controllerList && controllerListData.controllerList?.length>1) {
            navigate(routeName.payForThisService);
        } else {
            navigate(routeName.controllerDetails);
        }
    }

    const handleAddControllerContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateAddControllerFields();
        if (isValid) {
            submitSpinner(e, handleControllerNavigation);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const validateAddControllerFields = () => {
        let isValid: boolean = true;

        if (addControllerValue === '') {
            setAddControllerErrorMessage(`${dsaControllerErrorMessage.addAnotherController_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const resetAddControllerError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setAddControllerErrorMessage("");
        }
    }

    const handleChangeAddController = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAddControllerValue(e.target.value)
        resetAddControllerError(e)
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack condition="controller-details" />
                            <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                About the Controller</span>
                                Do you want to add another Controller?
                            </h1>
                            {addControllerErrorMessage !== "" && <ErrorDetails errorDescription={addControllerErrorMessage} errorHref="#addAnotherController"></ErrorDetails>}

                            <Radios
                                name="addAnotherController"
                                id="addAnotherController"
                                error={addControllerErrorMessage}
                            >
                                <Radios.Radio value={dsaContent.yes} onChange={handleChangeAddController}>{dsaContent.yes}</Radios.Radio>
                                <Radios.Radio value={dsaContent.no} onChange={handleChangeAddController}>{dsaContent.no}</Radios.Radio>
                            </Radios>

                            <div className="nhsuk-u-padding-bottom-8"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleAddControllerContinue}>{dsaContent.btnSaveAndContinue}</Button>

                        </div>

                    </div>
                </main>
            </div>
        </>
    )
}

export default AddAnotherController;
