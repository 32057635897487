import React, { useState } from "react";
import { Button, ErrorSummary, InsetText, Radios } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../../state/app/store";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { IApplication, IPrivacyNotice } from "../../../../state/models/app.interface";
import { getPrivacyNoticeDetails, setPrivacyNoticeDetailsData } from "../../../../state/slice/PrivacyNotice/PrivacyNotice.slice";
import { amendRenewExtendValue, controllerSection, DoesPrivacyNoteMeetTheCriteriaTypeOptions, dsaContent, dsaErrorMessage, privacyNoticeErrorMessage, taskListSectionStatus, routeName, fileTypeSize } from "../../../../config/page.config";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import { fetchInterceptor, getCookie, submitDSASectionStatus, submitSpinner } from "../../../../Helper/Utility";
import { SubmitScreenPrivacyNotice_URL } from "../../../../config/api-endpoints.config";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";


const PrivacyNotice: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [ethicsApprovalErrorMessage, setEthicsApprovalErrorMessage] = useState("");
    const initialState: IPrivacyNotice = useAppSelector(getPrivacyNoticeDetails) || {};
    const [privacyNoticeData, setPrivacyNoticeData] = useState(initialState)

    const savePrivacyNoticeDataInStore = (stateData?: IPrivacyNotice) => {
        dispatch(setPrivacyNoticeDetailsData({ ...privacyNoticeData, ...stateData }));
    }
    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IPrivacyNotice = {};
        if (e.target.value !== null && e.target.value !== undefined) {

            updatedState = {
                ...privacyNoticeData,
                doesControllerEnsureCompliance: e.target.value
            }
            setPrivacyNoticeData(updatedState);
            savePrivacyNoticeDataInStore(updatedState);
            resetError(e);
        }
    }

    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setEthicsApprovalErrorMessage("");
        }
    }

    const navigatenoPrivacyNotice = () => {
        navigate(routeName.noPrivacyNotice);
}

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            if (String(privacyNoticeData.doesControllerEnsureCompliance) === String(DoesPrivacyNoteMeetTheCriteriaTypeOptions.Yes))
                submitSpinner(e, submitPrivacyNotice);
            else {
                submitSpinner(e, navigatenoPrivacyNotice);
               
            }
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    const submitPrivacyNotice = () => {
        const updatedState: IPrivacyNotice = {

            ...privacyNoticeData,
            explanationForNonCompliance: ''
        }
        setPrivacyNoticeData(updatedState);
        savePrivacyNoticeDataInStore(updatedState);

        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "ExplanationForNonCompliance": updatedState.explanationForNonCompliance,
            "DoesControllerEnsureCompliance": privacyNoticeData.doesControllerEnsureCompliance
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitScreenPrivacyNotice_URL, requestOptions)
            .then(() => {
                setPrivacySectionStatus();
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setPrivacySectionStatus = () => {
        submitDSASectionStatus(dsaApplicationData?.id!, controllerSection.PrivacyNotice, taskListSectionStatus.completed)
            .then((data: object) => {
                if (data) {
                    let updatedState: IPrivacyNotice = {
                        ...privacyNoticeData,
                        aboutPrivacyNoticeStatus: taskListSectionStatus.completed
                    }
                    setPrivacyNoticeData(updatedState);
                    savePrivacyNoticeDataInStore(updatedState);
                    navigate(routeName.dsaTaskList);
                }
            });
    }

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href={"#" + dsaContent.privacyNotice}>{summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    /* To Validate the field's data */
    const validateFields = () => {
        let isValid: boolean = true;
        if (!privacyNoticeData.doesControllerEnsureCompliance) {
            setSummaryLinkErrorMessage(`${privacyNoticeErrorMessage.privacyNotice_ErrorMessage}`);
            setEthicsApprovalErrorMessage(`${privacyNoticeErrorMessage.privacyNotice_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <h1>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    Privacy notice</span>
                                The UK General Data Protection Regulation Privacy Notice </h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <InsetText>
                                <p>
                                    Confirm the statement below by selecting 'Yes'. If you select 'No', you will be asked to provide more information.
                                </p>
                            </InsetText>
                            <p>Select 'Yes' to confirm that the Controller(s) listed within your application agree that they will ensure that a UK General Data Protection Regulation (GDPR) Privacy Notice relating to the data within the application will be maintained throughout the life of this agreement.</p>
                            <Radios
                                name={dsaContent.privacyNotice}
                                id={dsaContent.privacyNotice}
                                error={ethicsApprovalErrorMessage}>
                                <Radios.Radio name={dsaContent.privacyNotice} checked={String(privacyNoticeData.doesControllerEnsureCompliance) === String(DoesPrivacyNoteMeetTheCriteriaTypeOptions.Yes)} value={DoesPrivacyNoteMeetTheCriteriaTypeOptions.Yes} onChange={handleChange}>{dsaContent.yes}</Radios.Radio>
                                <Radios.Radio name={dsaContent.privacyNotice} checked={String(privacyNoticeData.doesControllerEnsureCompliance) === String(DoesPrivacyNoteMeetTheCriteriaTypeOptions.No)} value={DoesPrivacyNoteMeetTheCriteriaTypeOptions.No} onChange={handleChange}>{dsaContent.no}</Radios.Radio>
                            </Radios>
                            <div className="nhsuk-u-padding-bottom-4">
                            </div>
                            <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                        </div>
                    </div>
                </main>
            </div>
        </>)
}
export default PrivacyNotice;
