import React, { useState } from "react";
import { Button, Radios, ErrorSummary } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IDataSet, IApplication } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";
import { dataSetErrorMessage, dsaContent, taskListSectionStatus, routeName } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { submitProductData, submitSpinner } from "../../../../Helper/Utility";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";

const DataSetToCohort: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [datasetcohortErrorMessage, setdatasetcohortErrorMessage] = useState("");
    const dataSetDetailsData: IDataSet = useAppSelector(getDataSetDetails);
    const initialState: IDataSet = dataSetDetailsData || {};
    const [dataSetData, setDataSetData] = useState(initialState);

    /* To dispatch the setDataSetDetailsData Redux action */
    const saveDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IDataSet = {};
        updatedState = {
            ...dataSetData,
            [e.target.name]: (e.target.value === dsaContent.yes) ? true : (e.target.value === dsaContent.no) ? false : null,
            datasetCohort: e.target.value
        }
        resetError(e);
        setDataSetData(updatedState);
        saveDataInStore(updatedState);
    }
    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setdatasetcohortErrorMessage("");
        }
    }
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, handleProductSubmit());
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    /* To submit the Dissemination details of the page : common function in Utility page*/
    const handleProductSubmit = () => {
        submitProductData(dsaApplicationData, dataSetData).then((data: Response) => {
            if (data) {
                let updatedState: IDataSet = {
                    ...dataSetData,
                    firstPointer: 1,
                    selectedPaginationPage: 1
                }
                let sectionStatus: IApplication = {
                    ...dsaApplicationData, aboutDataSetSectionStatus: taskListSectionStatus.inProgress
                }
                setDsaApplicationData(sectionStatus);
                saveApplicationDataInStore(sectionStatus);
                setDataSetData(updatedState);
                saveDataInStore(updatedState);
                navigate(routeName.datasetDetails);
            }
        });
    }
      

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dataSetErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#linkedToCohort">{summaryLinkErrorMessage}</ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary></>
    )
    /* To Validate the each field's data */
    const ValidateFields = () => {
        let isValid: boolean = true;
        if (!dataSetData.datasetCohort) {
            setSummaryLinkErrorMessage(`${dataSetErrorMessage.datasetCohortmandatory_ErrorMessage}`);
            setdatasetcohortErrorMessage(`${dataSetErrorMessage.datasetCohortmandatory_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <h1>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom ">About the data &gt; {dataSetData.name}</span>
                                Do you want to link this data set to a participant group you are providing?</h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <Radios
                                name={dsaContent.linkedToCohort}
                                id={dsaContent.linkedToCohort}
                                error={datasetcohortErrorMessage}>
                                <Radios.Radio name={dsaContent.linkedToCohort} checked={dataSetData.datasetCohort === dsaContent.yes} value={dsaContent.yes} onChange={handleChange}>{dsaContent.yes}</Radios.Radio>
                                <Radios.Radio name={dsaContent.linkedToCohort} checked={dataSetData.datasetCohort === dsaContent.no} value={dsaContent.no} onChange={handleChange}>{dsaContent.no}</Radios.Radio>
                            </Radios>
                            <div className="nhsuk-u-padding-bottom-4">
                            </div>
                            <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default DataSetToCohort;