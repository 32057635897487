import { Button, Fieldset, InsetText, Radios } from "nhsuk-react-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitDSASectionStatus_URL } from "../../../../config/api-endpoints.config";
import { controllerSection, dsaContent, fileTypeSize, routeName, taskListSectionStatus, yieldedBenefitsErrorMessageConfig } from "../../../../config/page.config";
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IYieldedBenefits } from "../../../../state/models/app.interface";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getYieldedBenefitsDetails, setYieldedBenefitsDetailsData } from "../../../../state/slice/YieldedBenefits/YieldedBenefits.slice";

const HaveYouCompletedYieldedBenefits: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;   

    const yieldedBenefitsDetailsData: IYieldedBenefits = useAppSelector(getYieldedBenefitsDetails);
    const initialStateYB: IYieldedBenefits = yieldedBenefitsDetailsData || {};
    const [yieldedBenefitsData, setYieldedBenefitsData] = useState(initialStateYB);

    const saveYieldedBenefitsDataInStore = (stateData?: IYieldedBenefits) => {
        dispatch(setYieldedBenefitsDetailsData({ ...yieldedBenefitsData, ...stateData }));
    }

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);
    const [sectionStatus, setSectionStatus] = useState("");
    const [confirmationErrorMessage, setConfirmationErrorMessage] = useState("");
    const [selected, setSelected] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSectionStatus(e.target.value);
        setSelected(true);
        resetError(e);
    };

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setConfirmationErrorMessage("");
        }
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, submitYieldedBenefitsDetails);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    const submitYieldedBenefitsDetails = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "SectionId": controllerSection.YieldedBenefits,
            "SectionStatus": sectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitDSASectionStatus_URL, requestOptions)
            .then(() => {
                    setYieldedBenefitsStatus(sectionStatus);
                    navigate(routeName.dsaTaskList);
               
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setYieldedBenefitsStatus = (status: string) => {
        let updatedState: IYieldedBenefits = {};
        updatedState = {
            ...yieldedBenefitsData,
            aboutYieldedBenefits: status
        }
        setYieldedBenefitsData(updatedState);
        saveYieldedBenefitsDataInStore(updatedState);
    }

    const validateFields = () => {
        let isValid: boolean = true;
        if (!selected) {
            setConfirmationErrorMessage(`${dsaContent.selectSectionCompleteLink_RenewErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                Yielded benefits</span>
                                Is this section complete?
                            </h1>
                            {confirmationErrorMessage !== "" && <ErrorDetails errorDescription={confirmationErrorMessage} errorHref="#radioSectionComplete"></ErrorDetails>}
                            <InsetText>
                                <p>
                                    If you select 'Yes' the status of this section will change to 'completed', but you can return and make changes if you need to.
                                </p>
                            </InsetText>
                            <Fieldset>
                                <Radios name="radioSectionComplete" id="radioSectionComplete" error={confirmationErrorMessage}>
                                    <Radios.Radio name="sectionComplete" value={taskListSectionStatus.completed} onChange={handleChange}>{dsaContent.yes}</Radios.Radio>
                                    <Radios.Radio name="sectionComplete" value={taskListSectionStatus.inProgress} onChange={handleChange}>{dsaContent.noIWillBeBackLater}</Radios.Radio>
                                </Radios>
                            </Fieldset>
                            <div className="nhsuk-u-padding-bottom-6"></div>
                            <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default HaveYouCompletedYieldedBenefits
