import { Button, Fieldset, InsetText, Radios, Hint, ErrorSummary } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getEvidenceFileDetails, isValid, submitSpinner } from "../../../../Helper/Utility";
import InfoHeaderPanel from "../../../../Components/InfoHeaderPanel/InfoHeaderPanel";
import { dsaProcessorErrorMessage, dataControllerTypeOptions, dsaContent, routeName } from "../../../../config/page.config";
import { IProcessor, IApplication, IProcessorList, ISecurityAssurance, IApiResEvidenceFileSummary, IEvidenceFileSummary } from "../../../../state/models/app.interface";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getProcessorListDetails } from "../../../../state/slice/ProcessorList/ProcessorList.slice";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getProcessorDetails, setProcessorDetails } from '../../../../state/slice/Processor/Processor.slice';
import { getSecurityAssuranceDetails } from "../../../../state/slice/SecurityAssurance/SecurityAssurance.slice";
import GoBack from "../../../../Components/Shared/GoBack";
import Loader from "../../../../Components/Loader/Loader";

/*
  Summary: Using this page, customer can select the type of Security Assurance they need. The Jira ticket linked to this is DDA-336.
*/
const ProcessorSecurityAssurance: React.FC = () => {
    /*Variable Declarations*/
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [isSecurityAssuranceErrorMessage, setIsSecurityAssuranceErrorMessage] = useState("");
    const processorDetailsData: IProcessor = useAppSelector(getProcessorDetails);
    const initialState: IProcessor = processorDetailsData || {};
    const [processorData, setData] = useState(initialState);
    /* To dispatch the setProcessorDetails Redux action */
    const saveDataInStore = (stateData?: IProcessor) => {
        dispatch(setProcessorDetails({ ...processorData, ...stateData }));
    }
    const processorListDetailsData: IProcessorList = useAppSelector(getProcessorListDetails);
    const initialProcessorListState: IProcessorList = processorListDetailsData || {};
    const [processorListData] = useState(initialProcessorListState);
    let isDirtyProcessor = processorData.isDirtyProcessor || false;
    const securityAssuranceDetailsData: ISecurityAssurance = useAppSelector(getSecurityAssuranceDetails);
    const initiailSecurityAssuranceState: ISecurityAssurance = securityAssuranceDetailsData || {};
    const [securityAssuranceData] = useState(initiailSecurityAssuranceState);
    const [showLoader, setShowLoader] = useState(true);
    /*Variable Declarations*/

    /*Update value in store and reset validation error onChange*/
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedStateSA: ISecurityAssurance = {};
        let updatedStateofProcessor: IProcessor = {};
        updatedStateSA = { ...securityAssuranceData, [e.target.name]: e.target.value };
        updatedStateofProcessor = { ...processorData, 'saList': updatedStateSA, isDirtyProcessor: true }
        setData(updatedStateofProcessor);
        saveDataInStore(updatedStateofProcessor);
        resetError(e);
    }
    /*Update value in store and reset validation error onChange*/
    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setIsSecurityAssuranceErrorMessage("");
        }
    }
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            const target = e.target as HTMLInputElement
            if (target.name !== "undefined" && target.name !== null && target.name === dsaContent.saveAndContinue) {
                submitSpinner(e, submitSecurityAssurance);
            }
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    /* To submit the details of the page */
    const submitSecurityAssurance = () => {
        if (processorListData.processorDetailsPage && !processorData?.isNewProcessor) {
            if (isDirtyProcessor) {
                let updatedState: IProcessor = {};
                let updatedSAList = { ...processorData.saList, SAId: "" }
                let fileNames = processorData.fileUpload?.map((item: any) => item.FileName);
                updatedState = { ...processorData, saList: updatedSAList, evidenceFileSummary: processorData.evidenceFileSummary?.filter((x) => !fileNames?.includes(x.fileName)) }
                setData(updatedState);
                saveDataInStore(updatedState);
                if (String(processorData.saList ? processorData.saList?.SAType : "") === String(dataControllerTypeOptions.dsptSecurityAssuranceValue)) {
                    navigate(routeName.processorDsptDetails);
                }
                else if (String(processorData.saList ? processorData.saList?.SAType : "") === String(dataControllerTypeOptions.isoSecurityAssuranceValue)) {
                    navigate(routeName.processorIso);
                }
                else if (String(processorData.saList ? processorData.saList?.SAType : "") === String(dataControllerTypeOptions.slspSecurityAssuranceValue)) {
                    navigate(routeName.processorSlsp);
                }
            }
            else {
                navigate(routeName.processorDetails);
            }
        }
        else {
            if (String(processorData.saList ? processorData.saList.SAType : "") === String(dataControllerTypeOptions.dsptSecurityAssuranceValue)) {
                navigate(routeName.processorDsptDetails);
            }
            else if (String(processorData.saList ? processorData.saList.SAType : "") === String(dataControllerTypeOptions.isoSecurityAssuranceValue)) {
                navigate(routeName.processorIso);
            }
            else if (String(processorData.saList ? processorData.saList.SAType : "") === String(dataControllerTypeOptions.slspSecurityAssuranceValue)) {
                navigate(routeName.processorSlsp);
            }
        }
    }

    const getEvidenceFileSummary = () => {
        let applicationId = isValid(dsaApplicationData.id) ? dsaApplicationData.id : "";
        if (isValid(applicationId)) {
            getEvidenceFileDetails(applicationId!).then((data) => {
                setEvidenceFileSummary(data);
            });
        }
    }

    const setEvidenceFileSummary = (response: IApiResEvidenceFileSummary[]) => {
        if (isValid(response)) {
            let evidenceFileSummary: IEvidenceFileSummary[] = [];
            response.map(({ FileId: fileId, FileName: fileName, MimeType: mimeType, Source: source }) => {
                evidenceFileSummary.push({ fileId, fileName, mimeType, source });
            });
            let updatedState: IProcessor = {};
            updatedState = { ...processorData, evidenceFileSummary }
            setData(updatedState);
            saveDataInStore(updatedState);
        }
        setShowLoader(false);
    }

    useEffect(() => {
        if (!isValid(processorData.evidenceFileSummary) || isValid(processorData.evidenceFileSummary?.filter((x) => !isValid(x.fileId)))) {
            getEvidenceFileSummary();
        }
        else {
            setShowLoader(false);
        }
    }, []);
    /* To submit the details of the page */

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaProcessorErrorMessage.selectProcessorSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#saType">
                            {summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    /* To show the error summary */

    /* To Validate the each field's data */
    const ValidateFields = () => {
        let isValid: boolean = true;
        if (!(processorData.saList ? processorData.saList.SAType : "")) {
            setSummaryLinkErrorMessage(`${dsaProcessorErrorMessage.selectSecurityAssuranceLinkProcessor_ErrorMessage}`);
            setIsSecurityAssuranceErrorMessage(`${dsaProcessorErrorMessage.selectSecurityAssurance_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }
    /* To Validate the each field's data */
    /*Radio Populate*/
    const renderRadio = (renderOptionsList: string[]) => {
        return renderOptionsList && renderOptionsList?.map((option: string, index: number) => {
            return <React.Fragment key={option}>  {(index > 1) ? <Radios.Divider>or</Radios.Divider> : <></>}
                <Radios.Radio name="SAType" checked={String(processorData.saList ? processorData.saList.SAType : "") === String(option)} value={option} onChange={handleChange}>{getContent(option)}
                    {(index > 1) ? <Hint><p>You should only select 'System Level Security Policy (SLSP)' in exceptional circumstances. Contact <a href={'mailto:data.applications@nhsdigital.nhs.uk?subject=SecurityAssurance - DARS Online Application Issue ' + dsaApplicationData?.referenceNumber}>Data.applications@nhsdigital.nhs.uk</a> before selecting this option. Your NIC number will appear in the subject field for reference.</p>
                        <p>If you don't know what security assurance to provide, contact your Data Protection Officer (DPO) or Information Governance (IG) team for help.</p>
                        <p>If you don't think you have security assurance, contact your Case Officer.</p>
                    </Hint> : ""}
                </Radios.Radio>
            </React.Fragment>
        });
    }
    const getContent = (option: string) => {
        switch (option) {
            case dataControllerTypeOptions.dsptSecurityAssuranceValue: return "Data Security and Protection Toolkit (DSPT)";
            case dataControllerTypeOptions.isoSecurityAssuranceValue: return "ISO/IEC 27001 Information Security management standard";
            case dataControllerTypeOptions.slspSecurityAssuranceValue: return "System Level Security Policy (SLSP)";
        }
    }
    /*Radio Populate*/


    return (
        <>
            <InfoHeaderPanel>
                <InfoHeaderPanel.Item >
                    <strong>Reference Number</strong> : {dsaApplicationData.referenceNumber}
                </InfoHeaderPanel.Item>
                <InfoHeaderPanel.Item>
                    <strong>Total Estimated Cost</strong> : {dsaApplicationData.totalEstimatedCost}
                </InfoHeaderPanel.Item>
            </InfoHeaderPanel>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {showLoader ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <>
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the Processor
                            </span>
                            <h1>What security assurance will you provide for this Processor?</h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <InsetText>
                                <p>For a Processor to be given access to data from NHS England, they must provide security assurance.</p>                                
                            </InsetText>
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <Fieldset className="nhsuk-space-bottom-double">
                                <Fieldset className="nhsuk-space-bottom-double">
                                    <Radios name="SAType" id="saType" hint="" error={isSecurityAssuranceErrorMessage}>
                                        {dataControllerTypeOptions && renderRadio([dataControllerTypeOptions.dsptSecurityAssuranceValue!, dataControllerTypeOptions.isoSecurityAssuranceValue!, dataControllerTypeOptions.slspSecurityAssuranceValue!])}
                                    </Radios>
                                </Fieldset>
                            </Fieldset>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                                </div>
                            </>}
                    </div>
                </main>
            </div>
        </>
    )
}
export default ProcessorSecurityAssurance;