import { InsetText, Table, Button } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import GoBack from "../../../../Components/Shared/GoBack";
import { Route, useNavigate } from "react-router-dom";
import { dsaContent, fileTypeSize, routeName } from "../../../../config/page.config";
import { getCookie, submitSpinner, fetchInterceptor } from '../../../../Helper/Utility';
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from '../../../../state/app/store';
import { IApiResParticipantContributor, IAPiResParticipantDetails, IApiResParticipantLegalBasis, IApplication, IParticipant, IParticipantContributor, IParticipantLegalBasis } from "../../../../state/models/app.interface";
import { getParticipantDetails, setParticipantDetailsData } from "../../../../state/slice/Participant/Participant.slice";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader'
import { GetCohortData_URL } from "../../../../config/api-endpoints.config";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import Loader from "../../../../Components/Loader/Loader";

const AddCohortContributor: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const participantDataDetails: IParticipant = useAppSelector(getParticipantDetails);
    const initialState: IParticipant = participantDataDetails || {};
    const [participantDetails, setParticipantDetails] = useState(initialState)
    const [isFromAnswerPage] = useState(participantDetails?.participantcheckYourAnswerPage);
    const saveParticipantDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantDetails, ...stateData }));
    }
    const [loading, setLoading] = useState(true);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        submitSpinner(e, handleClickNavigate);
    }
    const handleClickNavigate = () => {
        navigate(routeName.participantSummary);
          }

    const handleDelete = (navigatePage: string, journalID: string = "", e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        let updatedState: IParticipant = {};
        updatedState = {
            ...participantDetails,
            deleteParticipantId: journalID,
        }
        saveParticipantDataInStore(updatedState);
        navigate(navigatePage);
    }

    const getCohortDetailsData = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetCohortData_URL, requestOptions)
            .then(response => {
                    return response.json()
            })
            .then(data => {
                setDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setDetails = (data: IAPiResParticipantDetails) => {
        let participant: IParticipant = { ...participantDataDetails };
        let participantlegal: IParticipantLegalBasis = {};
        let legalBasisList: IParticipantLegalBasis[] = [];
        let participantjournal: IParticipantContributor = {};
        let journalsList: IParticipantContributor[] = [];
        let existingAgreement: string[] = [];
        const { Cohort, TypeOfCohort, RefIfUsingAnotherExistingAgreement, OngoingRecruitment, AdditionalLinkageCohortInformation, MaximumSize, StudyId,
            DateOfDeath, NhsNumber, Surname, DateOfBirth, AddressDate, Forename, GpPracticeCode, OtherGivenName, DataAsAtDate, Sex, TelephoneNumber, Postcode,
            MobilePhoneNumber, EmailAddress, DataSetSelectedLegalBasisOtherText, MinimumSizeCohortValidationRate, ManualCohortValidation, HowManyValidationCredits,
            ProductionCommence, DataProductionSubmissionSelected } = data;

        participant.cohort = Cohort
        participant.typeOfCohort = TypeOfCohort
        participant.refIfUsingAnotherExistingAgreement = RefIfUsingAnotherExistingAgreement
        participant.ongoingRecruitment = OngoingRecruitment
        participant.additionalLinkageCohortInformation = AdditionalLinkageCohortInformation
        participant.maximumSize = MaximumSize
        participant.studyId = StudyId
        participant.dateOfDeath = DateOfDeath
        participant.nhsNumber = NhsNumber
        participant.surname = Surname
        participant.dateOfBirth = DateOfBirth
        participant.addressDate = AddressDate
        participant.forename = Forename
        participant.gpPracticeCode = GpPracticeCode
        participant.otherGivenName = OtherGivenName
        participant.dataAsAtDate = DataAsAtDate
        participant.sex = Sex
        participant.telephoneNumber = TelephoneNumber
        participant.postcode = Postcode
        participant.mobilePhoneNumber = MobilePhoneNumber
        participant.emailAddress = EmailAddress
        participant.dataSetSelectedLegalBasisOtherText = DataSetSelectedLegalBasisOtherText
        participant.minimumSizeCohortValidationRate = MinimumSizeCohortValidationRate
        participant.manualCohortValidation = ManualCohortValidation
        participant.howManyValidationCredits = HowManyValidationCredits
        participant.productionCommence = ProductionCommence
        participant.dataProductionSubmissionSelected = DataProductionSubmissionSelected

        if (data.Journals.length > 0) {
            data.Journals.forEach((dsaApplication: IApiResParticipantContributor) => {
                participantjournal = {
                    id: dsaApplication.Id,
                    firstName: dsaApplication.FirstName,
                    lastName: dsaApplication.LastName,
                    organisation: dsaApplication.Organisation,
                    role: dsaApplication.Role,
                    email: dsaApplication.Email
                }
                journalsList.push(participantjournal)
            })
        }
        participant.participantContributorList = journalsList;

        if (data.DataSetLegalBasisList.length > 0) {
            let orderby = 0;
            data.DataSetLegalBasisList.forEach((dsaApplication: IApiResParticipantLegalBasis) => {
                if (dsaApplication.Checked && orderby === 0)
                    orderby = 1;
                else if (dsaApplication.Checked)
                    orderby = orderby + 1;

                participantlegal = {
                    value: dsaApplication.Value,
                    name: dsaApplication.Name,
                    checked: dsaApplication.Checked,
                    text: dsaApplication.Text,
                    crmId: dsaApplication.crmId,
                    processing: dsaApplication.Processing,
                    dissemination: dsaApplication.Dissemination,
                    otherLegalBasis: dsaApplication.OtherLegalBasis,
                    displayProcessing: dsaApplication.DisplayProcessing,
                    displayDissemination: dsaApplication.DisplayDissemination,
                    productLegalBasis: dsaApplication.ProductLegalBasis,
                    otherComments: dsaApplication.OtherComments,
                    orderBy: dsaApplication.OrderBy,
                    availableOnline: dsaApplication.AvailableOnline,
                    currentlyCheckedInCRM: dsaApplication.CurrentlyCheckedInCRM,
                    notes: dsaApplication.Notes,
                    sortBy: orderby
                }

                legalBasisList.push(participantlegal);
            })

        }
        participant.dataSetLegalBasisList = legalBasisList;

        if (data.ExistingAgreementUploadItems.length > 0) {
            data.ExistingAgreementUploadItems.forEach((agreement: string) => {
                existingAgreement.push(agreement)
            })
            participant.existingAgreementUploadItems = existingAgreement;
        }
        setParticipantDetails(participant)
        saveParticipantDataInStore(participant)
        setLoading(false)
    }

    const participantDataList = participantDetails?.participantContributorList;
    const handleClickAddAnother = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        resetParticipantData();
        navigate(routeName.whoWillProvide);
    }

    const resetParticipantData = () => {
        let updatedState: IParticipant = {}
        updatedState = {
            dataProviderFirstName: "",
            dataProviderLastName: "",
            dataProviderRole: "",
            dataProviderOrganisationName: "",
            dataProviderOrganisationNameNotListed:"",
            dataProviderOrganisationId: "",
            dataProviderEmail: "",
            dataProviderConfirmEmail: "",

        }
        saveParticipantDataInStore(updatedState);
    }

    useEffect(() => {
        getCohortDetailsData();
    }, []);

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">

                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            (
                                <div className="nhsuk-grid-column-three-quarters">
                                    <GoBack condition={participantDetails.participantcheckYourAnswerPage === dsaContent.checkYourAnswersPageText ? routeName.participantSummary : routeName.linkingParticipant} />
                                    <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                        About the participants</span>
                                        You have added the below Participant Data Provider(s)
                                    </h1>
                                    <InsetText>
                                        <p>
                                            This information will be used to ensure that the Participant Data Provider has secure means of transferring the data.
                                        </p>
                                        <p>If you need help relating to the Participant Data Provider, email <a href="mailto:data.applications@nhsdigital.nhs.uk?subject=Participant Data Contributor" target="_blank">data.applications@nhsdigital.nhs.uk</a> quoting 'Participant Data Contributor' in the subject field. </p>

                                    </InsetText>
                                    <Table responsive>
                                        <Table.Head>
                                            {participantDataList && participantDataList.length > 0 ?
                                                <Table.Row>
                                                    <Table.Cell>First Name</Table.Cell>
                                                    <Table.Cell>Last Name</Table.Cell>
                                                    <Table.Cell>Role</Table.Cell>
                                                    <Table.Cell>Organisation Name</Table.Cell>
                                                    <Table.Cell>Email</Table.Cell>
                                                    <Table.Cell>&nbsp;</Table.Cell>
                                                </Table.Row>
                                                : null}
                                        </Table.Head>
                                        <Table.Body>
                                            {
                                                participantDetails.participantContributorList?.map((journalDetail: IParticipantContributor, index: number) => (
                                                    <Table.Row key={index + 1}>
                                                        <Table.Cell>{journalDetail?.firstName}</Table.Cell>
                                                        <Table.Cell>{journalDetail?.lastName}</Table.Cell>
                                                        <Table.Cell>{journalDetail?.role}</Table.Cell>
                                                        <Table.Cell>{journalDetail?.organisation}</Table.Cell>
                                                        <Table.Cell><a href={'mailto:' + journalDetail?.email} target="_blank">{journalDetail?.email}</a></Table.Cell>
                                                        <Table.Cell>
                                                            <a href="#" onClick={(e) => handleDelete("/dsa/delete-participant", journalDetail!.id, e)}>Delete</a>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                                )
                                            }
                                        </Table.Body>
                                    </Table>
                                    <div className="nhsuk-u-padding-bottom-4"></div>
                                    <p><a href="#" onClick={handleClickAddAnother}>Add another Data Participant Contributor</a></p>
                                    <div className="nhsuk-u-padding-bottom-7"></div>
                                    <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                                </div>
                            )}
                    </div>
                </main>
            </div>

        </>)
}

export default AddCohortContributor;