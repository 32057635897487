import React, { useState } from "react";
import { Button, Radios, Hint, ErrorSummary } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { IDataSet } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";
import { dataSetErrorMessage, dsaContent, dataSetLevelOptionTypes, routeName } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import { submitSpinner } from "../../../../Helper/Utility";

const AggregatedOrRecord: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch(); const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [aggregatedOrrecordErrorMessage, setAggregatedOrrecordErrorMessage] = useState("");
    const dataSetDetailsData: IDataSet = useAppSelector(getDataSetDetails);
    const initialState: IDataSet = dataSetDetailsData || {};
    const [dataSetData, setDataSetData] = useState(initialState);
    /* To dispatch the setDataSetDetailsData Redux action */
    const saveDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IDataSet = {};
        updatedState = {
            ...dataSetData,
            [e.target.name]: e.target.value
        }
        resetError(e);
        setDataSetData(updatedState);
        saveDataInStore(updatedState);
    }
    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setAggregatedOrrecordErrorMessage("");
        }
    }

    const navigateDataDuration = () => {
        navigate(routeName.dataDuration);
}

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, navigateDataDuration);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dataSetErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#aggregatedOrRecordLevelData">{summaryLinkErrorMessage}</ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary></>
    )
    /* To Validate the each field's data */
    const validateFields = () => {
        let isValid: boolean = true;
        if (!dataSetData.aggregatedOrRecordLevelData) {
            setSummaryLinkErrorMessage(`${dataSetErrorMessage.datasetLevelmandatory_ErrorMessage}`);
            setAggregatedOrrecordErrorMessage(`${dataSetErrorMessage.datasetLevelmandatory_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <h1>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom ">About the data &gt; {dataSetData.name}</span>
                                Do you want aggregated or record level data?</h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <Radios
                                name={dsaContent?.aggregatedOrRecordLevelData}
                                id={dsaContent?.aggregatedOrRecordLevelData}
                                error={aggregatedOrrecordErrorMessage}>
                                <Radios.Radio name={dsaContent?.aggregatedOrRecordLevelData} checked={String(dataSetData?.aggregatedOrRecordLevelData) === String(dataSetLevelOptionTypes.recordLevelValue)} value={dataSetLevelOptionTypes.recordLevelValue} onChange={handleChange}>
                                    {dsaContent.recordlevel}
                                    <Hint>
                                        <p>Record level data typically relates to a single individual. There may be one or many records per individual. Such data would usually carry a risk of re-identification, and use of such data would be subject to strict controls.</p>
                                    </Hint>
                                </Radios.Radio>
                                <Radios.Radio name={dsaContent?.aggregatedOrRecordLevelData} checked={String(dataSetData?.aggregatedOrRecordLevelData) === String(dataSetLevelOptionTypes.aggregatedLevelValue)} value={dataSetLevelOptionTypes.aggregatedLevelValue} onChange={handleChange}>
                                    {dsaContent.aggregatedlevel}
                                    <Hint>
                                        <p>Aggregate data would typically be 'counts' of an event - for example, how many people had a particular operation over a specific time period. Aggregate data (without small numbers suppressed) is not always anonymous data, therefore may also be subject to specific controls.</p>
                                    </Hint>
                                </Radios.Radio>
                            </Radios>
                            <div className="nhsuk-u-padding-bottom-4"></div>
                            <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default AggregatedOrRecord;