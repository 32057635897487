import { useNavigate } from 'react-router-dom';
import ContainerTemplate from '../../Components/Container/ContainerTemplate';
import AddAnotherRecord from '../../Components/Shared/AddAnotherRecord';
import { fetchInterceptor, getCookie, getUploadFileName, isValid, submitDSASectionStatus, submitSpinner, } from '../../Helper/Utility';
import { SubmitEthicsApproval_URL } from '../../config/api-endpoints.config';
import { buttonText, controllerSection, dsaContent, EthicsApprovalInPlaceTypeOptions, fileTypeSize, initialStateOfFileUpload, routeName, taskListSectionStatus } from '../../config/page.config';
import { useAppDispatch, useAppSelector } from '../../state/app/hooks';
import { IApplication, IDeleteDocument, IEvidence } from '../../state/models/app.interface';
import { getDSADetails, setDSAApplicationDetailsData } from '../../state/slice/DSA/DSA.slice';
import { AppDispatch } from '../../state/app/store';
import { useEffect, useState } from 'react';
import { getEvidenceDetails, setEvidenceDetailsData } from '../../state/slice/Evidence/Evidence.slice';
import { getDeleteDocumentData, setDeleteDocumentData } from '../../state/slice/shared/DeleteDocument/DeleteDocument.slice';

const AddAnotherEthicsReview: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();

    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});

    const initialState: IEvidence = useAppSelector(getEvidenceDetails) || {};
    const [evidenceData, setevidenceData] = useState(initialState)
    const { fileIndex, isDeleted } = useAppSelector(getDeleteDocumentData);
    const saveEvidenceDataInStore = (stateData?: IEvidence) => {
        dispatch(setEvidenceDetailsData({ ...evidenceData, ...stateData }));
    }

    const { evidenceFileSummary, fileUploadEthics, fileUploadActive }: IEvidence = useAppSelector(getEvidenceDetails);
    const saveDeleteDocumentInStore = (stateData: IDeleteDocument) => {
        dispatch(setDeleteDocumentData(stateData));
    };

    const handleContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        submitSpinner(e, submitEthicsApproval);
    };
    const submitEthicsApproval = () => {
        const recentlyUploaded = fileUploadEthics!.filter(file => file?.RecentlyModified! === true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "EthicsApprovalRequire": evidenceData.isEvidenceApproval,
            "EthicsApprovalInPlace": evidenceData.ethicsApproval,
            "EthicsApprovalReason": evidenceData.noEthicsApproval,
            "EthicsApprovalPlaceReason": evidenceData.ethicsApprovalNotInPlace,
            "FileList": recentlyUploaded
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };
        console.log(parameters);
        fetchInterceptor(SubmitEthicsApproval_URL, requestOptions)
            .then(() => {
                setEthicsApprovalStatus();
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setEthicsApprovalStatus = () => {
        submitDSASectionStatus(dsaApplicationData?.id!, controllerSection.EthicsApproval, taskListSectionStatus.completed)
            .then((data: Response) => {
                if (data) {
                    let updatedState: IEvidence = {
                        ...evidenceData,
                        aboutEthicsApprovalStatus: taskListSectionStatus.completed
                    }
                    setevidenceData(updatedState);
                    saveEvidenceDataInStore(updatedState);
                    resetEvidenceStore();
                    navigate(routeName.dsaTaskList);
                }
            });
    }

    const deleteRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
        e.preventDefault();

        const documentData: IDeleteDocument = {
            fileIndex: index,
            fileId: fileUploadEthics![index].FileId!,
            approvalEvidenceID: fileUploadEthics![index].ApprovalEvidenceID!,
            isDeleted: false,
        };
        saveDeleteDocumentInStore(documentData);
        navigate(routeName.deleteDocument);
    };

    const editRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
        e.preventDefault();
        let updatedState: IEvidence = {};
        updatedState = {
            fileUploadActive: index,
            temporaryFileList: evidenceData.fileUploadEthics
        };
        saveEvidenceDataInStore(updatedState);
        navigate(routeName.uploadConsent);
    };

    const addAnotherRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
        e.preventDefault();
        let updatedState: IEvidence = {};
        updatedState = {
            fileUploadActive: fileUploadEthics!.length,
            temporaryFileList: evidenceData.fileUploadEthics
        };
        saveEvidenceDataInStore(updatedState);
        navigate(routeName.uploadConsent);
    };

    const resetEvidenceStore = () => {
        let updatedState: IEvidence = {};
        updatedState = {
            ...evidenceData,
            fileUploadEthics: [initialStateOfFileUpload],
        }
        setevidenceData(updatedState)
        saveEvidenceDataInStore(updatedState)
    }
    useEffect(() => {
        let updatedState: IEvidence = {};
        updatedState = {
            ...evidenceData,
            temporaryFileList: [initialStateOfFileUpload]
        };
        setevidenceData(updatedState);
        saveEvidenceDataInStore(updatedState);
    }, [])
    useEffect(() => {
        if (isDeleted) {
            const documentData: IDeleteDocument = {
                isDeleted: false,
            };
            saveDeleteDocumentInStore(documentData);

            let updatedDocData = [...fileUploadEthics!];
            var oldFileName = getUploadFileName(updatedDocData[fileIndex!].FileName!);
            updatedDocData.splice(fileIndex!, 1);
            let updatedState: IEvidence = {};
            updatedState = {
                ...evidenceData,
                fileUploadEthics: updatedDocData,
                fileUploadActive: fileUploadEthics?.length === 1 ? 0 : fileUploadActive,
                evidenceFileSummary: evidenceFileSummary?.filter((x) => x.fileName !== oldFileName)
            };
            setevidenceData(updatedState);
            saveEvidenceDataInStore(updatedState);
        }
    }, [isDeleted]);

    const handleGoBack = () => {
        return (String(evidenceData.ethicsApproval) === String(EthicsApprovalInPlaceTypeOptions.Yes) &&
            isValid(evidenceData.fileUploadEthics!)) ? dsaContent.gobackEthicsApproval : dsaContent.tasklist;
    }

    return (
        <ContainerTemplate
            title="Ethics approval"
            heading={'Uploaded document(s)'}
            actionButtonText={buttonText.btnSaveAndContinue}
            onActionButtonClick={handleContinue}
            goBackCondition={handleGoBack()}
        >
            <></>
            <AddAnotherRecord
                linkTo={routeName.uploadConsent}
                linkText="Add another document"
                summaryData="If you need to upload another document, select 'Add another document'."
                records={fileUploadEthics!}
                deleteRecord={deleteRecord}
                editRecord={editRecord}
                addAnotherRecord={addAnotherRecord}
            />
        </ContainerTemplate>
    );
};

export default AddAnotherEthicsReview;
