import { Button, InsetText, Details, Textarea } from "nhsuk-react-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitScreenOutputsAndBenefits_URL } from "../../../../config/api-endpoints.config";
import { dsaContent, fileTypeSize, purposeErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IPurpose } from "../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getPurposeDetails, setPurposeDetailsData } from "../../../../state/slice/Purpose/Purpose.slice";

const ExpectedBenefits: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const [purposeData, setPurposeData] = useState(useAppSelector(getPurposeDetails) || {});
    const [expectedBenefitErrorMessage, setExpectedBenefitErrorMessage] = useState("");
    const savePurposeDataInStore = (stateData?: IPurpose) => {
        dispatch(setPurposeDetailsData({ ...purposeData, ...stateData }));
    }

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IPurpose = {};
        updatedState = {
            ...purposeData,
            expectedMeasurableBenefits: e.target.value,
            isPurposeUpdated: true
        }
        setPurposeData(updatedState);
        savePurposeDataInStore(updatedState);
        resetError(e);
    }

    const resetError = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== "") {
            setExpectedBenefitErrorMessage("");
        }
    };

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = validateFields();
        if (IsValid) {
            submitSpinner(e, submitExpectedBenefit());
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const validateFields = () => {
        let isValid: boolean = true;
        if (!purposeData.expectedMeasurableBenefits || purposeData.expectedMeasurableBenefits.trim() === "") {
            setExpectedBenefitErrorMessage(`${purposeErrorMessage.selectExpectedBenefit_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const submitExpectedBenefit = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "SpecificOutputsExpected": purposeData.specificOutputsExpected,
            "ExpectedMeasurableBenefits": purposeData.expectedMeasurableBenefits,
            "SectionStatus": dsaApplicationData.aboutExpectedOutputsAndBenefitsSectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenOutputsAndBenefits_URL, requestOptions)
            .then(() => {
                    let sectionStatus: IApplication = {
                        ...dsaApplicationData, aboutObjectiveAndProcessingSectionStatus: taskListSectionStatus.inProgress
                    }
                    setDsaApplicationData(sectionStatus);
                    saveApplicationDataInStore(sectionStatus);
                    navigate(routeName.expectedCheckYourAnswer);
                
            })
            .catch(() => navigate(routeName.serviceError));
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                Expected outputs and benefits
                            </span>
                            <h1>Benefits</h1>
                            {expectedBenefitErrorMessage !== "" && <ErrorDetails errorDescription={expectedBenefitErrorMessage} errorHref="#expectedMeasurableBenefits"></ErrorDetails>}
                            <InsetText>
                                <p>On this page, tell us what the expected measurable benefits are to health and social care. </p>
                            </InsetText>
                            <Details>
                                <Details.Summary>Guidance </Details.Summary>
                                <Details.Text>
                                    <p>Explain what public benefits relating to health and social care are expected to be achieved as an outcome of the proposed uses of data. It might be that the research findings: </p>
                                    <ul>
                                        <li>are expected to contribute to evidence-based decision-making for policymakers, local decision-makers (such as doctors), and patients, to improve care, treatment and the experience of healthcare users relevant to the subject matter of the study </li>
                                        <li>help the healthcare system to better understand health and care needs of populations </li>
                                        <li>help identify improvement of treatments or interventions or healthcare system-design to improve health and care experience or outcomes </li>
                                        <li>advance understanding of regional and national trends in health and social care needs</li>
                                        <li>advance understanding of the need for, or effectiveness of, preventative health and care measures for populations or conditions such as obesity and diabetes </li>
                                        <li>inform planning health services and programmes, for example, to improve equity of access, experience and outcomes </li>
                                        <li>inform decisions on how to effectively allocate and evaluate funding according to health needs </li>
                                        <li>provide a mechanism for checking quality of care which could include identifying areas of good practice to adopt, or areas of poorer practice which should be addressed </li>
                                        <li>support knowledge creation or exploratory research, along with the innovations and developments that might result from that exploratory work </li>
                                    </ul>
                                    <p>Or it might be that: </p>
                                    <ul>
                                        <li>the services provided to clients are expected to identify improvement opportunities which the client may then exploit by making changes to systems, processes, resources, or infrastructure to improve patient experience and care </li>
                                    </ul>
                                    <p>Explain what specific benefits to the public are expected as an outcome, subject to the findings. </p>
                                    <p>Explain how it is envisaged that the proposed outputs will lead to those public benefits. It might be that: </p>
                                    <ul>
                                        <li>it is hoped that through media publication(s) of findings, the findings of your research will add to the body of evidence that is considered by organisations and individual care practitioners charged with making policy decisions or decisions on treatment for or within the NHS </li>
                                    </ul>
                                    <p>Explain what actions will be taken from the outputs to optimise potential public benefits. For example, describe what effort will be taken to advertise the findings to a wider audience if needed, and tell us if any relevant charities or societies have been made aware of what is proposed. </p>

                                </Details.Text>
                            </Details>
                            <Textarea
                                label="Provide information on the expected benefits in the text box below "
                                name="Expected Benefits"
                                id="expectedMeasurableBenefits"
                                onChange={handleChangeTextarea}
                                defaultValue={purposeData.expectedMeasurableBenefits ? purposeData.expectedMeasurableBenefits : ""}
                                maxLength={32000}
                                rows={5}
                                error={expectedBenefitErrorMessage}
                            />

                            <div className="nhsuk-u-padding-bottom-8"></div>
                            <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick} >{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );

}
export default ExpectedBenefits