import { Button } from 'nhsuk-react-components';
import ControllerHeader from '../Shared/ControllerHeader';
import GoBack from '../Shared/GoBack';
import { dsaContent } from '../../config/page.config';
import Loader from '../Loader/Loader';

const ContainerTemplate: React.FunctionComponent<{
  showLoader?: boolean;
  children: JSX.Element[];
  goBackCondition?: string;
  title?: string;
  heading: string;
  actionButtonText?: string | null;
  onActionButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({
  showLoader = false,
  children,
  goBackCondition,
  title,
  heading,
  actionButtonText,
  onActionButtonClick,
}) => {
  const condition = goBackCondition ? { condition: goBackCondition } : null;

  return (
    <>
      <ControllerHeader></ControllerHeader>
      <div className="nhsuk-width-container">
        <main className="nhsuk-main-wrapper " id="maincontent" role="main">
          <div className="nhsuk-grid-row">
            {showLoader ? (
              <Loader loadingText={dsaContent.txtLoading} />
            ) : (
              <div className="nhsuk-grid-column-two-thirds">
                <>
                  <GoBack {...condition} />
                  {title && <span className="nhsuk-caption-l nhsuk-caption--bottom">{title}</span>}
                  <h1>{heading}</h1>
                  {children}
                  <div className="nhsuk-u-padding-bottom-8"></div>
                  {actionButtonText && (
                    <Button
                      name="saveAndContinue"
                      id="nhsuk-button-spinner"
                      onClick={onActionButtonClick}
                    >
                      {actionButtonText}
                    </Button>
                  )}
                </>
              </div>
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default ContainerTemplate;
