import React, { useEffect, useState } from "react";
import { Button, ErrorSummary, Select } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { EvidenceTypeAttributes, IEvidenceList } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getEvidenceList, setEvidenceListData } from "../../../../state/slice/EvidenceList/EvidenceList.slice";
import { dsaContent, dsaErrorMessage, evidenceTypeOptions, evidenceErrorMessage, routeName, initialStateOfFileUpload } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import Loader from "../../../../Components/Loader/Loader";
import { mappedEvidence, submitSpinner } from "../../../../Helper/Utility";

const EvidenceType: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [loading] = useState(false);
    const evidenceList: IEvidenceList = useAppSelector(getEvidenceList);
    const evidenceinitialState: IEvidenceList = evidenceList || {};
    const [evidenceListDetails, setevidenceListDetails] = useState(evidenceinitialState)
   
    const saveEvidenceDataInStore = (stateData?: IEvidenceList) => {
        dispatch(setEvidenceListData({ ...evidenceListDetails, ...stateData }));
    }
   
    /* To dispatch the setEvidencedata to Redux action */
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let updatedState: IEvidenceList = {};
        if (e.target.value !== null && e.target.value !== undefined) {
            updatedState = {
                ...evidenceListDetails,
                selectedType: e.target.value,
                isFromEvidence: true,
                fileComment: "",
                fileUpload: [initialStateOfFileUpload]
            }
            resetError(e);
            setevidenceListDetails(updatedState);
            saveEvidenceDataInStore(updatedState);
        }
    }
    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
        }
    }

    const navigateUploadConsent = () => {
        navigate(routeName.uploadConsent)
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        let isValid = validateFields();
        if (isValid) {
            submitSpinner(e, navigateUploadConsent);
        }
    }
    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#selectevidence">{summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    /* To Validate the field's data */
    const validateFields = () => {
        let isValid: boolean = false;
        if (evidenceListDetails?.selectedType != '')
            isValid = true;
        if (!isValid) {
            setSummaryLinkErrorMessage(`${evidenceErrorMessage.evidenceType_ErrorMessage}`);
        }
        return isValid;
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            {loading ?
                (<div className="nhsuk-grid-column-full nhsuk-u-margin-top-4">
                    <Loader loadingText={dsaContent.txtLoading} />
                </div>) : null}
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {!loading ?
                            (<div className="nhsuk-grid-column-two-thirds">
                                <GoBack condition={(!evidenceListDetails.isFromAddAnotherApprovalEvidence) ? "upload-supporting-evidence" : "add-another-approval-evidence"} ></GoBack>
                                <h1>
                                    What type of document will you be uploading?
                                </h1>
                                <p>Types of document</p>
                                {summaryLinkErrorMessage !== "" ? errorSummary : null}
                                <Select id="selectevidence" defaultValue={evidenceListDetails.selectedType} onChange={handleChange} error={summaryLinkErrorMessage} >
                                <Select.Option value=''>Please select...</Select.Option>
                                    {
                                        Object.values(mappedEvidence).map((option: EvidenceTypeAttributes, index) => (
                                            <Select.Option key={index + 1} value={option.value}>{option.name}</Select.Option>
                                    ))
                                    }
                                </Select>
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                            </div>) : (null)}
                    </div>
                </main>
            </div>
        </>)
}
export default EvidenceType;
