import { Link } from "react-router-dom";
import { applicationChangeType, routeName } from "../../../config/page.config";

const GetHelp: React.FC<{applicationChangeTypeValue: string}> = ({applicationChangeTypeValue}) => {
  
    return (
        <div className="nhsuk-grid-column-one-third">
            <div className="nhsuk-help-sidebar-block"><br />
                {
                    (applicationChangeTypeValue === undefined ||  String(applicationChangeTypeValue) === applicationChangeType.new) && <>
                        <h6>Guidance</h6>
                        <p>Read information about <a href={routeName.dsaBeforeYouStart}>requesting access to data</a> before you start.</p>
                    </>
                }
                <h6>Get help</h6>
                <p><strong>Telephone</strong> <br />0300 303 5678 <br />Monday to Friday, from 9am to 5pm </p>
                <p><strong>Email</strong><br /><a href="mailto:enquiries@nhsdigital.nhs.uk">enquiries@nhsdigital.nhs.uk</a></p>
                <p></p>
            </div>
        </div>
    )
}
export default GetHelp;