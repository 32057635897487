import React from 'react';

export interface ISummaryCardContext {
  feature: boolean;
}

const SummaryCardContext = React.createContext<ISummaryCardContext>({
  feature: false,
});

export default SummaryCardContext;