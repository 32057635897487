import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialStateOfFileUpload } from '../../../config/page.config'
import type { RootState } from '../../app/store'
import { IProcessor, IFileUpload, IAutoComplete, ISecurityAssurance } from '../../models/app.interface'


const initialStateOfProcessorOrg: IAutoComplete = {
    id: "",
    name: ""
}

const initialStateofSecurityAssurance: ISecurityAssurance = {
    SAType: "",
    SAId: "",
    ISOVersion: "",
    ISODateComplete: "",
    ISONotes: "",
    SLSPNotes: "",
    SLSPDateComplete: "",
    DSPT_ODS_Code: "",
    DSPT_Latest_Status: "",
    DSPT_Date_Published: "",
    DSPT_Comments: "",
    SectionType: "",
    DSPTDay: "",
    DSPTMonth: "",
    DSPTYear: "",
    ISODay: "",
    ISOMonth: "",
    ISOYear: "",
    SLSPDay: "",
    SLSPMonth: "",
    SLSPYear: "",
    SAIndex: 0
}

const initialState: IProcessor = {
    isCompleted: false,
    processorId: "",
    isDirtyProcessor: false,
    fileUpload: [initialStateOfFileUpload],
    processorType: "",
    aboutProcessorSectionStatus: "",
    dsaApplicationId: "",
    contractNumber: "",
    alternativeOrganisationName: "",
    alternativeStreet1: "",
    alternativeStreet2: "",
    alternativeStreet3: "",
    alternativeCity: "",
    alternativeCounty: "",
    alternativePostCode: "",
    alternativeCountry: "",
    processordataOrgGuidId: "",
    dpaRegistrationNumber: "",
    dpaOrganisationName: "",
    processordataOrgDesc:"",
    dpaRegistrationExpiryDate: "",
    listOrganisations: [initialStateOfProcessorOrg],
    saList: initialStateofSecurityAssurance,
    temporaryFileList: [initialStateOfFileUpload],
    fileUploadActive: 0,
    evidenceFileSummary: []
}

export const ProcessorSlice = createSlice({
    name: 'dsaProcessorDetails',
    initialState,
    reducers: {
        setProcessorDetails: (state: IProcessor, action: PayloadAction<IProcessor>) => {
            state = Object.assign(state, action.payload);
        },
        resetProcessorDetails: (state: IProcessor, action: PayloadAction<IProcessor>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setProcessorDetails, resetProcessorDetails } = ProcessorSlice.actions;
export const getProcessorDetails = (state: RootState) => state.processorDetails;
export default ProcessorSlice.reducer;