import React, { useState } from "react";
import { Button, Label } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { destroyStoreData, submitSpinner } from "../../../../Helper/Utility";
import { useAppSelector } from "../../../../state/app/hooks";
import { IApplication } from "../../../../state/models/app.interface";
import SummaryCard from "../../../../Components/SummaryCard/SummaryCard";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { routeName } from "../../../../config/page.config";

const DSASubmit: React.FC = () => {

    const navigate = useNavigate();
    const initialApplicationState: IApplication = useAppSelector(getDSADetails) || {};
    const [dsaData] = useState(initialApplicationState);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        submitSpinner(e, destroyStoreData);
        navigate(routeName.applyDsa);
    };


    return (
        <>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper" id="maincontent" role="main">
                    <div className="nhsuk-u-padding-bottom-4"></div>
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <SummaryCard blue>
                                <SummaryCard.Content>
                                    <SummaryCard.Heading>Application submitted</SummaryCard.Heading>
                                    <SummaryCard.Description>
                                        Reference Number
                                        <span className="nhsuk-summarycard__description2">{dsaData?.referenceNumber}</span>
                                    </SummaryCard.Description>
                                </SummaryCard.Content>
                            </SummaryCard>
                            <Label size="s">We have sent you a confirmation email.</Label>
                            <Label size="m">What happens next</Label>
                            <Label size="s">We've sent your application to NHS England's Data Access Request Service (DARS) application team. Your application will be reviewed, and we will contact you once the initial review is complete. </Label>
                            <Label size="s">You can see the progress of your application by logging into DARS Online. </Label>
                            <Label size="s">If you want to speak to someone about your application, call us on 0300 303 5678 or email <a href={'mailto:enquiries@nhsdigital.nhs.uk?subject=' + dsaData?.referenceNumber}>enquiries@nhsdigital.nhs.uk</a> quoting your reference number in the subject field.</Label>
                            <Label size="s"><a href="https://feedback.digital.nhs.uk/jfe/form/SV_bjyeZ6KBPc967cy" target="_blank">What did you think of this service? </a></Label>
                        </div>
                        <div className="nhsuk-grid-column-full">
                            <Button onClick={handleClick} id="nhsuk-button-spinner">Go to DARS Online</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default DSASubmit;