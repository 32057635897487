const Loader: React.FC<{ loadingText: string | undefined }> = ({ loadingText }) => {
  return (
    <div className="nhsuk-grid-column-full">
       <div className="nhsuk-loader__container">
         <h1 className="nhsuk-heading-l">{loadingText}</h1>
       </div>
        <div className="nhsuk-loader__container">
            <div className="nhsuk-loader">
       </div>
         </div>
        </div>
  )
}

export default Loader;