import { useNavigate } from 'react-router-dom';

import { fetchInterceptor, getCookie, getKeyByValue, getUploadFileName, isValid, submitDSASectionStatus, submitSpinner, } from '../../../../Helper/Utility';
import { GetFileList_URL } from '../../../../config/api-endpoints.config';
import { buttonText, controllerSection, dsaContent, fileTypeSize, initialStateOfFileUpload, routeName, sectionOptionsDesc, taskListSectionStatus } from '../../../../config/page.config';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { IApiResEvidence, IApplication, IDeleteDocument, IEvidence, IEvidenceList, IFileUpload } from '../../../../state/models/app.interface';
import { getDSADetails } from '../../../../state/slice/DSA/DSA.slice';
import { AppDispatch } from '../../../../state/app/store';
import { useEffect, useState } from 'react';
import { getEvidenceDetails, setEvidenceDetailsData } from '../../../../state/slice/Evidence/Evidence.slice';
import { getDeleteDocumentData, setDeleteDocumentData } from '../../../../state/slice/shared/DeleteDocument/DeleteDocument.slice';
import ContainerTemplate from '../../../../Components/Container/ContainerTemplate';
import { getEvidenceList, initialEvidence, setEvidenceListData } from '../../../../state/slice/EvidenceList/EvidenceList.slice';
import AddAnotherListRecordProps from '../../../../Components/Shared/AddAnotherListRecord';

const AddAnotherApprovalEvidence: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();

    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});

    const [evidenceListDetails, setEevidenceListDetails] = useState(useAppSelector(getEvidenceList) || {});

    const initailEvidenceList: IEvidence = initialEvidence || {};
    const [evidenceListDetailsData] = useState([initailEvidenceList]);

    const initialStateEvidence: IEvidence = useAppSelector(getEvidenceDetails) || {};
    const [evidenceData, setEvidenceData] = useState(initialStateEvidence)

    const [evidenceListResult, setEvidenceListResult] = useState([initialEvidence]);

    const { fileIndex, isDeleted } = useAppSelector(getDeleteDocumentData);

    const { fileUpload, fileUploadActive, evidenceFileSummary }: IEvidenceList = useAppSelector(getEvidenceList);

    const [loading, setLoading] = useState(false);


    const saveDeleteDocumentInStore = (stateData: IDeleteDocument) => {
        dispatch(setDeleteDocumentData(stateData));
    };

    const saveEvidenceListDataInStore = (stateData?: IEvidenceList) => {
        dispatch(setEvidenceListData({ ...evidenceListDetailsData, ...stateData }));
    }

    const saveEvidenceDataInStore = (stateData?: IEvidence) => {
        dispatch(setEvidenceDetailsData({ ...evidenceData, ...stateData }));
    }

    const handleContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        submitSpinner(e, handleClickNavigate);
    };
    const handleClickNavigate = () => {
        let UpdateSectionStatus = taskListSectionStatus.completed;
        submitDSASectionStatus(dsaApplicationData?.id!, controllerSection.ListOfUploadedDocuments, UpdateSectionStatus)
            .then((data: Response) => {
                if (data) {
                    let updateState: IEvidenceList = {}
                    updateState = {
                        ...evidenceListDetails,
                        aboutEvidenceSectionStatus: taskListSectionStatus.completed
                    }
                    setEevidenceListDetails(updateState);
                    saveEvidenceListDataInStore(updateState);
                    navigate(routeName.dsaTaskList);
                }
            });

    }

    const deleteRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
        e.preventDefault();

        const documentData: IDeleteDocument = {
            fileIndex: index,
            fileId: fileUpload![index].FileId!,
            approvalEvidenceID: fileUpload![index].ApprovalEvidenceID!,
            isDeleted: false,
        };
        saveDeleteDocumentInStore(documentData);
        navigate(routeName.deleteDocument);
    };

    const editRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
        e.preventDefault();
        let updatedState: IEvidence = {};
        updatedState = {
            fileUploadActive: index,
        };
        saveEvidenceDataInStore(updatedState);
        navigate(routeName.uploadConsent);
    };

    const addAnotherRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
        e.preventDefault();
        let updatedState: IEvidence = {};
        updatedState = {
            fileUploadActive: fileUpload!.length,
        };
        saveEvidenceDataInStore(updatedState);

        let updatedStateEvidenceList: IEvidenceList = {}
        updatedStateEvidenceList = {
            ...evidenceListDetails,
            selectedType: '',
            evidenceList: [initialEvidence],
            fileUpload: [initialStateOfFileUpload],
            fileComment: '',
            isFromAddAnotherApprovalEvidence: true
        }
        saveEvidenceListDataInStore(updatedStateEvidenceList);
        navigate(routeName.evidenceType);
    };

    const getEvidenceListDetails = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetFileList_URL, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                setEvidenceDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setEvidenceDetails = (data: IApiResEvidence) => {
        let approvalEvidenceData: IFileUpload;
        let approvalEvidenceDataList: IFileUpload[] = [];
        let evidenceList: IEvidence[] = [];
        let updateState: IEvidenceList = {}
        if (data.FileList!.length > 0) {
            data.FileList!.map((evidence: IFileUpload) => {
                approvalEvidenceData = {
                    ApprovalEvidenceID: evidence.ApprovalEvidenceID,
                    ApplicantComments: evidence.ApplicantComments,
                    DocumentType: Number(evidence.DocumentType == 0 ? evidence.SelectedEvidenceUpload : evidence.DocumentType),
                    ApprovalEvidenceType: evidence.ApprovalEvidenceType,
                    SectionID: evidence.SectionID,
                    Data: evidence.Data,
                    FileId: evidence.FileId,
                    SectionType: String(getKeyByValue(sectionOptionsDesc, String(evidence.SectionID))),
                    FileName: evidence.FileName
                }
                approvalEvidenceDataList.push(approvalEvidenceData!);

            })
        }
        else {
            updateState = {
                ...evidenceListDetails,
                fileUpload: [initialStateOfFileUpload],
                fileComment: ''
            }
            setEvidenceListResult(evidenceList)
            setEevidenceListDetails(updateState);
            saveEvidenceListDataInStore(updateState)
            if (evidenceListDetails.isFromEvidence) {
                navigate(routeName.evidenceType);
            }
        }

        updateState = {
            ...evidenceListDetails,
            fileUpload: approvalEvidenceDataList
        }
        setEvidenceListResult(evidenceList.sort(function (a: IEvidence, b: IEvidence) { return a.sectionId! - b.sectionId! }))
        setEevidenceListDetails(updateState);
        saveEvidenceListDataInStore(updateState);
        setLoading(false);
    }


    useEffect(() => {
        getEvidenceListDetails();
    }, []);

    useEffect(() => {
        if (isDeleted) {
            const documentData: IDeleteDocument = {
                isDeleted: false,
            };
            saveDeleteDocumentInStore(documentData);

            let updatedDocData = [...fileUpload!];
            var oldFileName = getUploadFileName(updatedDocData[fileIndex!].FileName!);
            updatedDocData.splice(fileIndex!, 1);
            let evidenceSummaryFilteredList = evidenceFileSummary!.filter((x) => x.fileName !== oldFileName);
            let updatedState: IEvidence = {};
            updatedState = {
                fileUploadApprovalEvidence: updatedDocData,
                fileUploadActive: fileUpload?.length === 1 ? 0 : fileUploadActive
            };

            if (isValid(evidenceFileSummary) && isValid(evidenceData.evidenceFileSummary)) {
                updatedState = {
                    ...evidenceData,
                    evidenceFileSummary: evidenceSummaryFilteredList
                }
            }

            saveEvidenceDataInStore(updatedState);

            if (isValid(evidenceFileSummary) && isValid(evidenceListDetails.evidenceFileSummary)) {
                let updatedEvidenceList: IEvidenceList = {};
                updatedEvidenceList = {
                    ...evidenceListDetails,
                    evidenceFileSummary: evidenceSummaryFilteredList
                };
                saveEvidenceListDataInStore(updatedEvidenceList);
            }
        }
    }, [isDeleted]);

    return (
        <>
            <ContainerTemplate
                showLoader = {loading}
                title="List of uploaded documents"
                heading={'Uploaded document(s)'}
                actionButtonText={buttonText.btnSaveAndContinue}
                onActionButtonClick={handleContinue}
                goBackCondition={dsaContent.tasklist}
            > 
                <AddAnotherListRecordProps
                    linkTo={routeName.uploadConsent}
                    linkText="Add another document"
                    summaryData="If you need to upload another document, select 'Add another document'."
                    records={evidenceListDetails.fileUpload!}
                    deleteRecord={deleteRecord}
                    editRecord={editRecord}
                    addAnotherListRecord={addAnotherRecord}
                /><></>
            </ContainerTemplate >
        </>
    );
};

export default AddAnotherApprovalEvidence;
