import React, { useEffect, useState } from "react";
import { Button, Label } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from '../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../state/app/hooks';
import { IApiResDSFCContractDetails, IDSFCContractDetails } from "../../../state/models/app.interface";
import { getDSFCContractDetails, setDSFCContractDetailsData } from '../../../state/slice/DSFC/DSFCContractDetails.slice';
import { DSFCIntroSubmit_URL } from "../../../config/api-endpoints.config";
import { applicationChangeType, dsfcContent, fileTypeSize, routeName } from "../../../config/page.config";
import { getCookie, submitSpinner, fetchInterceptor } from "../../../Helper/Utility";
import "../../../Content/css/nhsuk-common.css";
import InfoHeaderPanel from "../../../Components/InfoHeaderPanel/InfoHeaderPanel";
import GoBack from "../../../Components/Shared/GoBack";

const DSFCIntroduction: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const DSFCContractDetails: IDSFCContractDetails = useAppSelector(getDSFCContractDetails);
    const initialState: IDSFCContractDetails = DSFCContractDetails || {};
    const [dsfcData, setDSFCData] = useState(initialState);
    const dispatch: AppDispatch = useAppDispatch();
    const savedataInStore = (stateData?: IDSFCContractDetails) => {
        dispatch(setDSFCContractDetailsData({ ...dsfcData, ...stateData }));
    }

    const [showButton, setShowButton] = useState(true);
    const [btnText, setBtnText] = useState(dsfcContent.btnTextStart);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setShowButton(false);
        if (localStorage.getItem(dsfcContent.newDSFCInProgress) === dsfcContent.false) {
            localStorage.setItem(dsfcContent.newDSFCInProgress, dsfcContent.true);
            submitSpinner(e, submitDsfcInro);
        }
        else {
            navigate(routeName.serviceError)
        }
    };

    const submitDsfcInro = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "DARSDSFCRequest": {
                "DSFCApplicationId": dsfcData.dsfcApplicationId !== "" ? dsfcData.dsfcApplicationId : "",
                "ApplicationChangeType": applicationChangeType.new
            }
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(DSFCIntroSubmit_URL, requestOptions)
            .then(response => {
                    return response.json()
            })
            .then(data => {
                setDSFCDetails(data);
                navigate(routeName.dsfcDetails)
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setDSFCDetails = (dsfcDetails: IApiResDSFCContractDetails) => {
        let updatedState: IDSFCContractDetails = {};
        updatedState = {
            ...dsfcData,
            dsfcApplicationId: dsfcDetails.DSFCApplicationId,
            organisation: dsfcDetails.Organisation,
            contractNumber: dsfcDetails.ContractNumber,
            contractDuration: dsfcDetails.ContractDuration,
            version: dsfcDetails.Version,
            organisationAddress: dsfcDetails.OrganisationAddress,
            companiesHouseICO: dsfcDetails.CompaniesHouseICO.toLowerCase(),
            alternativeOrganisationName: dsfcDetails.CompaniesHouseICO.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativeOrganisation : "",
            alternativeStreet1: dsfcDetails.CompaniesHouseICO.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativeStreet1 : "",
            alternativeStreet2: dsfcDetails.CompaniesHouseICO.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativeStreet2 : "",
            alternativeStreet3: dsfcDetails.CompaniesHouseICO.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativeStreet3 : "",
            alternativeCity: dsfcDetails.CompaniesHouseICO.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativeCity : "",
            alternativeCounty: dsfcDetails.CompaniesHouseICO.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativeCounty : "",
            alternativePostCode: dsfcDetails.CompaniesHouseICO.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativePostCode : "",
            alternativeCountry: dsfcDetails.CompaniesHouseICO.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativeCountry : "",
            dsfcintro: true,
            editionName: dsfcDetails.EditionName.trim()
        }
        setDSFCData(updatedState);
        savedataInStore(updatedState);
    }

    useEffect(() => {
        if (!dsfcData.dashboard || dsfcData.dsfcsubmit) {
            navigate(routeName.dashboard);
        }
        (dsfcData && dsfcData.dsfcApplicationId !== "" && dsfcData.dsfcApplicationId !== undefined) ?
            setBtnText(dsfcContent.btnTextContinue) : setBtnText(dsfcContent.btnTextStart)
    }, []);

    return (
        <>
            {(dsfcData && dsfcData.dsfcApplicationId !== "" && dsfcData.dsfcApplicationId !== undefined) ?
                <InfoHeaderPanel>
                    <InfoHeaderPanel.Item >
                        <strong>Reference number</strong> : {dsfcData?.contractNumber}
                    </InfoHeaderPanel.Item>
                </InfoHeaderPanel>
                : null}

            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack/>
                            <h1>Why you need a Data Sharing Framework Contract</h1>
                            <p> The Data Sharing Framework Contract (DSFC) creates a framework of legally binding terms and conditions. The terms and conditions apply each time NHS England agrees to share data with a recipient.</p>
                            <p>You can use this online service to apply for a DSFC.</p>
                         <Label size="m">Before you start</Label>
                            <h5>Ensure you have:
                                <Label size="s">
                                    <ul>
                                        <li>your organisation's registered name and address as it appears in the Information Commissioners Office (ICO) Data Protection Register</li>
                                        <li>the name of the person who will sign the DSFC on behalf of your organisation </li>
                                    </ul>
                                </Label>
                            </h5>
                            <Button onClick={handleClick} id="nhsuk-button-spinner">{btnText} </Button>
                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}

export default DSFCIntroduction;
