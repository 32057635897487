import { useRef, useState } from "react";
import { expandCollapse } from "../../config/page.config";
import { IFileUpload } from "../../state/models/app.interface";



interface ShowAllControlProps {
    uploadedFiles?: IFileUpload[];
    showDownload?: boolean; //showDownload
    selectedIndex?: number;
    isExpender?: boolean;
    handleExpend: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    setFileUrl: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    fileLength?: number;
}


const ShowAll: React.FC<ShowAllControlProps> = ({
    uploadedFiles,
    showDownload,
    selectedIndex,
    isExpender,
    handleExpend,
    setFileUrl,
    fileLength = expandCollapse.defaultFileLength,

}) => {
    return (
        <>
            {uploadedFiles && uploadedFiles !== undefined && uploadedFiles.length > 0 ?
                <p>
                    {
                        uploadedFiles!.map((fl: IFileUpload, index: number) => {
                            return (<div key={index + 1}>
                                <p>
                                    {index < fileLength ?
                                        <>

                                            <div className="nhsuk-u-margin-top-3">{fl?.ApplicantComments}</div>
                                            <a href="#" id={String(fl?.FileId)} onClick={(e) => setFileUrl(e)} > {fl?.FileName}</a>
                                            {(showDownload && String(fl?.FileId) === String(selectedIndex)) ?
                                                <span className="nhsuk-loader__container loading-results ">
                                                    <span className="nhsuk-loader-file"></span>
                                                    Downloading file
                                                </span> : null}
                                        </> : null
                                    }
                                    {index === 3 ? <p><a href="#" onClick={handleExpend}>{!isExpender ? "+Show all" : ""} </a></p> : ""}

                                </p>
                            </div>)

                        })
                    }

                    {isExpender ? <>
                        {
                            uploadedFiles!.map((fl: IFileUpload, index: number) => {
                                return (
                                    <div key={index + 1}>
                                        {index >= fileLength ?
                                            <p>
                                                <div className="nhsuk-u-margin-top-3">{fl?.ApplicantComments}</div>
                                                <a href="#" id={String(fl?.FileId)} onClick={(e) => setFileUrl(e)} > {fl?.FileName}</a>
                                                {(showDownload && String(fl?.FileId) === String(selectedIndex)) ?
                                                    <span className="nhsuk-loader__container loading-results ">
                                                        <span className="nhsuk-loader-file"></span>
                                                        Downloading file
                                                    </span> : null}
                                            </p>
                                            : null}
                                    </div>)

                            })
                        }</> : ""}
                    {uploadedFiles.length > 2 ? <p><a href="#" onClick={handleExpend}>{!isExpender ? "" : "-Show all"} </a></p> : ""}

                </p>
                : null}
        </>
    );
};

export default ShowAll;