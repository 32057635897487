import React from 'react';
import { ErrorSummary } from "nhsuk-react-components";
import { ErrorProps } from "../../state/models/app.interface";
import { dsaControllerErrorMessage } from "../../config/page.config";

const ErrorDetails: React.FC<ErrorProps> = ({
    errorTitle = dsaControllerErrorMessage.selectControllerSummary_ErrorMessage,
    errorDescription,
    errorHref,
    errorMessage
    }: ErrorProps) => {
   
    return (
        <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
            <ErrorSummary.Title id="error-summary-title">{errorTitle}</ErrorSummary.Title>
            <ErrorSummary.Body>
                {errorMessage && <p>{errorMessage}</p>}
                <ErrorSummary.List>
                    <ErrorSummary.Item href={errorHref} >
                        {errorDescription}
                    </ErrorSummary.Item>
                </ErrorSummary.List>
            </ErrorSummary.Body>
        </ErrorSummary>
    )
}
export default ErrorDetails;