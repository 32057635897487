import { DateInput, Textarea } from 'nhsuk-react-components';
import { dsaContent } from '../../config/page.config';
import { calculateExampleDate } from '../../Helper/Utility';
import { ChangeEvent } from 'react';

type dataType = {
  day?: string;
  month?: string;
  year?: string;
  notes?: string;
};

interface ReviewDateSectionProps {
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeTextarea: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  dateReviewError: string;
  diDayError: boolean;
  diMonthError: boolean;
  diYearError: boolean;
  data: dataType;
}

const ReviewDateSection: React.FC<ReviewDateSectionProps> = ({
  handleChange,
  handleChangeTextarea,
  dateReviewError,
  diDayError,
  diMonthError,
  diYearError,
  data,
}) => {
  return (
    <>
      <div className="nhsuk-grid-column-two-thirds">
        <div className="nhsuk-u-padding-bottom-5"></div>
        <DateInput
          id="slspDateComplete"
          hint={`For example, ${calculateExampleDate(dsaContent.exampleYearForPast)}`}
          label="Last review date"
          name="SLSPDateComplete"
          error={dateReviewError}
        >
          <DateInput.Day
            id={dsaContent.slspDay}
            name={dsaContent.slspDay}
            onChange={handleChange}
            defaultValue={data.day!}
            error={diDayError}
          />
          <DateInput.Month
            id={dsaContent.slspMonth}
            name={dsaContent.slspMonth}
            onChange={handleChange}
            defaultValue={data.month!}
            error={diMonthError}
          />
          <DateInput.Year
            id={dsaContent.slspYear}
            name={dsaContent.slspYear}
            onChange={handleChange}
            defaultValue={data.year!}
            error={diYearError}
          />
        </DateInput>
      </div>
      <div className="nhsuk-grid-column-two-thirds">
        <Textarea
          label="Additional information (Optional)"
          name="SLSPNotes"
          id="slspNotes"
          onChange={handleChangeTextarea}
          defaultValue={data.notes}
          maxLength={4000}
          rows={5}
        />
      </div>
      <div className="nhsuk-u-padding-bottom-7"></div>

    </>
  );
};

export default ReviewDateSection;
