import React, { useCallback, useEffect, useState } from "react";
import { Header, SkipLink } from 'nhsuk-react-components';
import { checkservicemaintenance, checkServiceMaintenance, fetchInterceptor, getCookie, getOauthToken, isContainsJson, setSessionStorage, urlQueryParameter } from '../../Helper/Utility';
import { anonymousPages, approverRolesValues, debounceDuration, dsaContent, fileTypeSize, inactivityDuration, routeName, securityRolesValues, signInSignOut } from "../../config/page.config";
import { CheckServiceMaintenanceStatus_URL, GetUserDetails_URL } from "../../config/api-endpoints.config";
import { IApproverRolesValues, IDSFCContractDetails, INews, IRoutePath, IRouteRestriction, ISecurityRole, IUserDetails } from "../../state/models/app.interface";
import { getUserDetails, resetUserDetailsData, setUserDetailsData } from "../../state/slice/Header/Header.slice";
import { useAppDispatch, useAppSelector } from "../../state/app/hooks";
import { AppDispatch } from "../../state/app/store";
import '../../Content/css/nhsuk-common.css'
import { getNewsDetails, setNewsDetailsData } from "../../state/slice/News/News.slice";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { getDSFCContractDetails, setDSFCContractDetailsData } from "../../state/slice/DSFC/DSFCContractDetails.slice";
import { pageRoute } from "../../config/routeRestriction.config";

interface IUserDetailsResponse {
    Applicant: IApplicant
    Organisation: IOrganisation
    SecurityRoles: ISecurityRole[]
}

interface IApplicant {
    FullName: string,
    DARSApproverRoleType: number
}

interface IOrganisation {
    AccountName: string
}

const HeaderBasic: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();

    const { isAuthenticated, hasSecurityRole, userFullName, dsfcApproverSecurity, dsaApproverSecurity, dsaApplicantSecurity, dsfcApplicantSecurity, dsfcApprover, dsfcApplicantApprover } = useAppSelector(getUserDetails);
    const DSFCContractDetailsData: IDSFCContractDetails = useAppSelector(getDSFCContractDetails) || {};
    const [dsfcData, setDSFCData] = useState(DSFCContractDetailsData);
    const [headerSignInLink, setHeaderSignInLink] = useState(false);

    const resetUserDataInStore = () => {
        dispatch(resetUserDetailsData());
    }

    const saveUserDataInStore = (stateData: IUserDetails) => {
        dispatch(setUserDetailsData(stateData));
    }

    const saveDSFCDataInStore = (stateData: IDSFCContractDetails) => {
        dispatch(setDSFCContractDetailsData({ ...dsfcData, ...stateData }));
    }

    let authenticationTokenCookie = getCookie('authenticationToken'),
        inactivityTimer: ReturnType<typeof setTimeout>,
        debounceTimer: ReturnType<typeof setTimeout>;



    const signIn = async () => {
        if (authenticationTokenCookie) {
            const token = await getOauthToken();
            if (token) {
                serviceMaintenanceStatus();
                getUserData();
            } else {
                navigate(routeName.serviceError);
            }
        }
    }

    const signOut = (event?: React.MouseEvent<HTMLElement>, redirectionURL?: string) => {
        event?.preventDefault();
        authenticationTokenCookie = null;
        document.cookie = `authenticationToken=${authenticationTokenCookie}; expires= ${new Date(Date.now()).toUTCString()};`;
        resetUserDataInStore();
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = redirectionURL ? redirectionURL : process.env.REACT_APP_DARS_SSO_LOGOUT_URL!;
    }

    const getUserData = () => {
        const parameters: string = JSON.stringify(authenticationTokenCookie);
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetUserDetails_URL, requestOptions)
            .then((userDetailsResponse: Response) => {
                return userDetailsResponse.json();
            })
            .then((userDetailsResponse: IUserDetailsResponse) => {
                setProfileDetails(userDetailsResponse);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const getUserRoles = (userDetailsResponse: IUserDetailsResponse): IUserDetails => {
        const userRoles: IUserDetails = {
            dsfcApproverSecurity: false,
            dsaApproverSecurity: false,
            dsaApplicantSecurity: false,
            dsfcApplicantSecurity: false,
            dsaApprover: false,
            dsfcApprover: false,
            dsfcApplicantApprover: false,
        };

        // Setting Aprrover Roles (dsfcApprover, dsaApprover, dsfcApplicantApprover)
        if (userDetailsResponse.Applicant && userDetailsResponse.Applicant.DARSApproverRoleType) {
            for (const roleKey in approverRolesValues) {
                const roleValue = approverRolesValues[roleKey as keyof IApproverRolesValues];
                if (roleValue === userDetailsResponse.Applicant.DARSApproverRoleType) {
                    (userRoles as any)[roleKey] = true;
                    break;
                }
            }
        }

        // Setting Security Roles (dsfcApproverSecurity, dsaApproverSecurity, dsaApplicantSecurity, dsfcApplicantSecurity)
        userDetailsResponse.SecurityRoles && userDetailsResponse.SecurityRoles.forEach((record: ISecurityRole) => {
            if (!userRoles.dsaApplicantSecurity && record.Role === securityRolesValues.dsaApplicantSecurity) {
                userRoles.dsaApplicantSecurity = true;
            }

            if (!userRoles.dsaApproverSecurity && record.Role === securityRolesValues.dsaApproverSecurity) {
                userRoles.dsaApproverSecurity = true;
            }

            if (!userRoles.dsfcApplicantSecurity && record.Role === securityRolesValues.dsfcApplicantSecurity) {
                userRoles.dsfcApplicantSecurity = true;
            }

            if (!userRoles.dsfcApproverSecurity && record.Role === securityRolesValues.dsfcApproverSecurity) {
                userRoles.dsfcApproverSecurity = true;
            }
        });

        return userRoles;
    }

    const hasDsaRoles = (): boolean | undefined => {
        return (dsaApproverSecurity || dsaApplicantSecurity);
    };

    const hasDsfcRoles = (): boolean | undefined => {
        return (dsaApproverSecurity || dsaApplicantSecurity || dsfcApproverSecurity || dsfcApplicantSecurity || dsfcApprover || dsfcApplicantApprover);
    };

    const setProfileDetails = (userDetailsResponse: IUserDetailsResponse) => {
        let updatedState: IUserDetails = {};
        if (userDetailsResponse !== null) {
            const userRoles = getUserRoles(userDetailsResponse);
            updatedState = {
                userFullName: userDetailsResponse.Applicant.FullName,
                userOrgName: userDetailsResponse.Organisation.AccountName,
                pageLoader: false,
                ...userRoles
            }
            saveUserDataInStore(updatedState);
        }
    }

    const userRedirected = () => {
        const { pathname } = location;

        const menuPattern = getMenuPattern(pathname);
        if (menuPattern && menuPattern !== dsaContent.noPermission) {
            const activeMenuElement = getActiveMenuElement(menuPattern);
            setMenuActive(activeMenuElement as HTMLElement);
        } else if (menuPattern === "" && isAuthenticated) {
            navigate(getUrlFor(routeName.applyDsa));
        }
    }

    const getMenuPattern = (pathName: string) => {
        let menuPattern: undefined | string;
        if (pathName === routeName.activeDsa) {
            menuPattern = dsaContent.activeDsa;
        } else {
            menuPattern = pathName.split('/')[1];
        }
        return menuPattern;
    }

    const getActiveMenuElement = (menuPattern: undefined | string) => {
        const menuItems = document.querySelectorAll(".nhsuk-header__navigation-list li a");
        for (let i = 0, len = menuItems.length; i < len; i++) {
            const navigationMenu = menuItems[i].getAttribute("data-href") || '';
            if (menuPattern === navigationMenu) {
                return (menuItems[i]);
            }
        }
    }

    const setHeaderSignIn = () => {
        const current = location.pathname.split('/')[1];
        anonymousPages.includes(current) ? setHeaderSignInLink(true) : setHeaderSignInLink(false);
    }

    const newsDetailsData: INews = useAppSelector(getNewsDetails);

    const initialNewsState: INews = newsDetailsData || {};
    const [newsData, setNewsData] = useState(initialNewsState);
    const resetNewsInStore = (stateData?: INews) => {
        dispatch(setNewsDetailsData({ ...newsData, ...stateData }));
    }
    /* Reset News */
    const resetNewsData = (e: React.MouseEvent<HTMLElement>) => {
        let current = window.location.pathname.split('/')[1];
        if (current === "") return;
        if (newsData!.newsList?.length! > 1) {
            let updatedNewsState: INews = {};
            updatedNewsState = {
                ...newsData,
                newsList: [{}],
                hasMoreRecords: false,
                firstPointer: 1,
                lastPointer: 1,
                selectedNews: 1
            }
            setNewsData(updatedNewsState);
            resetNewsInStore(updatedNewsState);
        }
        redirectToPath(e);
    };
    /* Reset News */

    const serviceMaintenanceStatus = useCallback(() => {
        if (location.pathname === routeName.serviceUnavailable) return;
        const parameters: string = JSON.stringify(authenticationTokenCookie);
        const requestOptions: Object = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: parameters,
        };
        fetchInterceptor(CheckServiceMaintenanceStatus_URL, requestOptions)
            .then((response: Response) => {
                return response.json();
            })
            .then((data) => {
                checkServiceMaintenance(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }, [authenticationTokenCookie, navigate]);

    const setMenuActive = (currentTarget:HTMLElement): void => {
        if (currentTarget?.parentNode) {
            document.querySelector('.nhsuk-header__navigation-item__item--current') &&
                document
                    .querySelector('.nhsuk-header__navigation-item__item--current')
                    ?.classList.remove('nhsuk-header__navigation-item__item--current');

            (currentTarget?.parentNode as HTMLInputElement).classList.add('nhsuk-header__navigation-item__item--current');
        }
    };

    const redirectToPath = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        const currentTarget = event.currentTarget;
        const targetUrl = currentTarget.getAttribute("href");
        setMenuActive(currentTarget);
        navigate(getUrlFor(targetUrl!));
    }

    const setRedirectionURL = () => {
        if (location.pathname.toLowerCase() === routeName.redirectController) {

            const { userType, appId } = urlQueryParameter(location.search);
            if (appId && userType?.trim().toLowerCase() === dsaContent.approver) {
                let updatedState: IDSFCContractDetails = {};
                updatedState = {
                    ...dsfcData,
                    redirectionURL: location.pathname + location.search
                }
                setDSFCData(updatedState);
                saveDSFCDataInStore(updatedState);
            }
        }
    };

    const getUrlFor = (urlParam: string): string => {
        let url: undefined | string;
        switch(urlParam) {
            case "/":
            case routeName.applyDsa:
            case routeName.activeDsa:
                url = isAuthenticated ? (hasDsaRoles() ? urlParam : routeName.noPermission) : "/";
                break;
            case routeName.dashboard:
                url = isAuthenticated ? urlParam  : "/";
                break;
            default:
                url = urlParam;
                break;
        }
        return url;
    };

    const resetInactivityTimer = () => {
        clearTimeout(inactivityTimer);
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            document.cookie = `authenticationToken=${authenticationTokenCookie}; expires=${new Date(Date.now() + 10000000).toUTCString()}; path=/`;
            inactivityTimer = setTimeout(signOut, inactivityDuration);
        }, debounceDuration);
    };

    const handleUserActivity = () => {
        resetInactivityTimer();
    };

    const addRemoveEvents = (action: string) => {
        const userActivityEvents = ["mousemove", "keydown", "scroll"];
        userActivityEvents.forEach((event) => {
            if (action === dsaContent.eventActionAdd) {
                resetInactivityTimer();
                window.addEventListener(event, handleUserActivity);
            } else {
                window.removeEventListener(event, handleUserActivity);
            }
        });
    }
    useEffect(() => {
        checkservicemaintenance();
    }, []);
    useEffect(() => {
        setRedirectionURL();
        if (isAuthenticated) {
            signIn();
            addRemoveEvents(dsaContent.eventActionAdd);
            if (location.pathname === routeName.serviceError) {
                signOut();
                return;
            }
        }
        return () => {
            addRemoveEvents(dsaContent.eventActionRemove);
            clearTimeout(inactivityTimer);
            clearTimeout(debounceTimer);
        };
    }, [isAuthenticated]);

    useEffect(() => {
        setHeaderSignIn();
        userRedirected();
        getUrl();
    }, [navigate]);

    useEffect(() => {
        if (!isAuthenticated) return;
        if (['/', routeName.noPermission].includes(location.pathname)) {
            navigate(getUrlFor(routeName.applyDsa));
        }
    }, [isAuthenticated]);

    const getUrl = async () => {
        const currentPageName = location.pathname.split("/").pop();
        const hasValue = isContainsJson(pageRoute, currentPageName!);
        const previousPageUrl = sessionStorage.getItem(dsaContent.previousPageUrl);
        const eventType = sessionStorage.getItem(dsaContent.eventType);
        if (hasValue) {
            if (previousPageUrl === routeName.serviceError.split("/").pop()) {
                setPreviousPageUrl(currentPageName!);
            }
            else {
                const allRoutes = pageRoute.find((item: IRouteRestriction) => {
                    return item.currentPage === currentPageName
                });
                const routePath = allRoutes?.routePath;
                if (routePath) {
                    const isPresent = routePath?.find((item: IRoutePath) => {
                        return (item.previousPage === previousPageUrl && item.eventType.includes(eventType!))
                    });
                    const nextPath = allRoutes?.nextPath;
                    if (isPresent) {
                        setPreviousPageUrl(currentPageName!);
                    }
                    else if (nextPath) {
                        navigate(nextPath);
                    }
                    else {
                        navigate(routeName.applyDsa);
                    }
                }
                else {
                    setPreviousPageUrl(currentPageName!);
                }
            }
        }
        else {
            setPreviousPageUrl(currentPageName!);
        }
        clearEventType();
    }

    const setPreviousPageUrl = (currentPageName: string) => {
        setSessionStorage(dsaContent.previousPageUrl, currentPageName);
    }
    const clearEventType = () => {
        setSessionStorage(dsaContent.eventType, '');
    }

    return (
        <>
            <SkipLink>
                Skip to main content
            </SkipLink>
            <Header>
                <Header.Container>
                    <Header.Logo href="/" onClick={redirectToPath} />
                    <Header.Content>
                        <Header.MenuToggle />
                        {isAuthenticated && <Header.ServiceName aria-disabled className="nhsuk-header-signin-out nhsuk-desktop-only nhsuk-username-link">{userFullName}</Header.ServiceName >}
                        {!isAuthenticated && headerSignInLink && <Header.ServiceName onClick={signIn} href={process.env.REACT_APP_DARS_SSO_LOGIN_URL} className="nhsuk-header-signin-out nhsuk-desktop-only" >{signInSignOut.signInText}</Header.ServiceName>}
                        {isAuthenticated && <Header.ServiceName onClick={signOut} href="#" className="nhsuk-header__link_underline nhsuk-header-signin-out nhsuk-desktop-only" >{signInSignOut.signOutText}</Header.ServiceName>}
                    </Header.Content>
                    <Header.ServiceName href="/" onClick={redirectToPath}>
                        Data Access Request Service
                    </Header.ServiceName>
                </Header.Container>
                <Header.NavContainer>
                    <Header.NavTitle>
                        {userFullName ? <span>{userFullName}</span> : <span>&nbsp;</span>}
                        <Header.NavMenuClose />
                    </Header.NavTitle>


                    <Header.NavItemList className={!isAuthenticated ? "nhsuk-menu-ul-float-right" : ""} >
                        {
                            isAuthenticated && <>
                                <Header.NavItem href={routeName.applyDsa} data-href="dsa" onClick={redirectToPath}>Manage applications</Header.NavItem>
                                <Header.NavItem href={routeName.activeDsa} data-href="active-dsa" onClick={redirectToPath}>Manage DSAs</Header.NavItem>
                                <Header.NavItem href={routeName.dashboard} data-href="dsfc" onClick={redirectToPath}>Data Sharing Framework Contract</Header.NavItem>
                            </>
                        }
                        <Header.NavItem href={routeName.help} data-href="help" onClick={redirectToPath}>Help</Header.NavItem>
                        <Header.NavItem href={routeName.latestNews} data-href="latest-news" onClick={resetNewsData}>Updates</Header.NavItem>
                        <Header.NavItem href={routeName.guidance} data-href="guidance" onClick={redirectToPath}>Guidance</Header.NavItem>
                        {
                            isAuthenticated ?
                                <Header.NavItem href="#" onClick={signOut} mobileOnly>{signInSignOut.signOutText}</Header.NavItem > :
                                <Header.NavItem href={process.env.REACT_APP_DARS_SSO_LOGIN_URL} mobileOnly>{signInSignOut.signInText}</Header.NavItem >
                        }

                    </Header.NavItemList>
                </Header.NavContainer>
            </Header>
        </>
    )
}
export default HeaderBasic;