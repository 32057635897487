import { Button,Table } from "nhsuk-react-components";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { controllerSection, dsaContent, dsfcContent, evidenceTypeOptions, fileTypeSize, initialStateOfFileUpload, routeName, sectionOptionsDesc, taskListSectionStatus } from "../../../../config/page.config";
import { fetchInterceptor, filterEvidenceType, getCookie, getKeyByValue, getNameForOption, getUploadedFiles, submitDSASectionStatus, submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IEvidenceList, IParticipant, EvidenceTypeAttributes, IApiResEvidence, IEvidence, IApiResEvidenceList, IAPiResParticipantDetails, IParticipantLegalBasis, IDataSet } from "../../../../state/models/app.interface";
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";
import { getEvidenceList, setEvidenceListData } from "../../../../state/slice/EvidenceList/EvidenceList.slice";
import { getParticipantDetails, setParticipantDetailsData } from "../../../../state/slice/Participant/Participant.slice";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { GetEvidenceList_URL} from "../../../../config/api-endpoints.config";
import Loader from "../../../../Components/Loader/Loader";

const ApprovalEvidenceData: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();

    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData] = useState(dsaApplicationDetails || {});

    const evidenceList: IEvidenceList = useAppSelector(getEvidenceList);
    const evidenceinitialState: IEvidenceList = evidenceList || {};
    const [evidenceListDetails, setEvidenceListDetails] = useState(evidenceinitialState);

    const participantDetailsData: IParticipant = useAppSelector(getParticipantDetails);
    const initialState: IParticipant = participantDetailsData || {};
    const [participantData] = useState(initialState);
    const [loading, setLoading] = useState(false);

    const [isFromParticipantPage] = useState(participantData?.isFromParticipant ? true : false)
    const [isFromParticipantSummaryPage] = useState(participantData?.participantcheckYourAnswerPage! !== "" ? true : false);

    const [dataSetData, setDataSetData] = useState<IDataSet>(useAppSelector(getDataSetDetails) || {});
    const saveParticipantDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantData, ...stateData }));
    }
    const saveEvidenceDataInStore = (stateData?: IEvidenceList) => {
        dispatch(setEvidenceListData({ ...evidenceListDetails, ...stateData }));
    }

    const saveDataSetDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        submitSpinner(e, handlenavigate);
    }

    const handlenavigate = () => {
        getUploadedFiles(dsaApplicationData.id, isFromParticipantPage).then((data) => {
            if (data && data.FileList.length > 0) {
                setUploadedDetails(data);
                navigate(routeName.addAnotherFileRecord);
            }
            else {
                if (isFromParticipantPage || isFromParticipantSummaryPage) {
                    navigate(routeName.participantSummary);
                }
                else {
                    let UpdateSectionStatus = taskListSectionStatus.completed;
                    submitDSASectionStatus(dsaApplicationData?.id!, controllerSection.aboutData, UpdateSectionStatus)
                        .then((data: Response) => {
                            if (data) {
                                let updateState: IDataSet = {}
                                updateState = {
                                    ...dataSetData,
                                    aboutDataSectionStatus: taskListSectionStatus.completed
                                }
                                setDataSetData(updateState);
                                saveDataSetDataInStore(updateState);
                                navigate(routeName.dsaTaskList);
                            }
                        });
                    navigate(routeName.dsaTaskList);
                }
            }

        })
    }

    const handleAnchorClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        let updatedStateEvidence: IEvidenceList = {};
        updatedStateEvidence = {
            ...evidenceListDetails,
            isAddAnotherAE: dsfcContent.yes
        }
        setEvidenceListDetails(updatedStateEvidence);
        saveEvidenceDataInStore(updatedStateEvidence);
        getUploadedFiles(dsaApplicationData.id, isFromParticipantPage).then((data) => {
            if (data && data.FileList.length > 0) {
                setUploadedDetails(data);
            }
            let updatedState: IParticipant = {};
            updatedState.fileUploadConsent = [initialStateOfFileUpload];
            setParticipantDetailsData(updatedState)
            saveParticipantDataInStore(updatedState);
            navigate(routeName.uploadConsent);
        });
    }

    const checkParticipantLBType = (value: string) => {
        return participantData.dataSetLegalBasisList!.find((element) => {
            return (value === element.value && element.checked)!;
        });
    }

    const filterParticipantLB = () => {
        let multiAEArray: number[] = [];
        Object.values(evidenceTypeOptions).forEach((option: EvidenceTypeAttributes, index) => {
            if (option?.legalBasis?.filter((value) => value !== undefined && checkParticipantLBType(value)).length! > 0) {
                multiAEArray.push(option.value);
            }
        })
        return multiAEArray;
    }

    const getEvidenceListDetails = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "ApprovalEvidenceType": isFromParticipantPage ? filterParticipantLB() : filterEvidenceType(dataSetData.legalBasesIdentifiableData)
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetEvidenceList_URL, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                setEvidenceDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setEvidenceDetails = (data: IApiResEvidence) => {
        if (data !== null && data !== undefined) {
            let participantEvidence: IEvidence = {};
            let evidenceList: IEvidence[] = [];
            let updateState: IEvidenceList = {}
            if (data.EvidenceList!.length > 0) {
                data.EvidenceList!.map((evidence: IApiResEvidenceList) => {
                    if (evidence.SectionId !== undefined && evidence.SectionId !== null && evidence.SectionId !== 0) {
                        participantEvidence = {
                            id: evidence.Id,
                            comments: evidence.ApplicantComments,
                            referenceNumber: evidence.ReferenceNumber,
                            approvalsEvidenceType: getNameForOption(evidence?.ApprovalsEvidenceType!),
                            sectionType: String(getKeyByValue(sectionOptionsDesc, String(evidence.SectionId))),
                            sectionId: evidence.SectionId

                        }
                        evidenceList.push(participantEvidence)
                    }
                })
                updateState = {
                    ...evidenceListDetails,
                    evidenceList: evidenceList
                }
                setEvidenceListDetails(updateState);
                saveEvidenceDataInStore(updateState)
            }
            else {
                navigate(routeName.uploadConsent);
            }
            setLoading(false)
        }

    }

    const setUploadedDetails = (response: IAPiResParticipantDetails) => {
        if (response && response !== undefined) {
            let updatedState: IParticipant = {};
            updatedState.fileUploadConsent = response.FileList;
            setParticipantDetailsData(updatedState)
            saveParticipantDataInStore(updatedState);
        }
        setLoading(false)
    }


    useEffect(() => {
        getEvidenceListDetails();
    }, [])


    return (
        <>
            <ControllerHeader />
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    {!loading ?
                        <div className="nhsuk-grid-row">
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                    {isFromParticipantPage || isFromParticipantSummaryPage ? "About the participants" : "About the data"}
                                </span>

                                <h1>Uploaded document(s)</h1> </div>
                            {(evidenceListDetails.evidenceList?.length! > 0) ? <>
                                <div className="nhsuk-grid-column-two-thirds" >
                                    <Table responsive>
                                        <Table.Head>
                                            <Table.Row>
                                                <Table.Cell>Evidence Type</Table.Cell>
                                                <Table.Cell>Comments</Table.Cell>
                                                <Table.Cell>Document Section</Table.Cell>
                                            </Table.Row>
                                        </Table.Head>
                                        <Table.Body>
                                            {evidenceListDetails.evidenceList?.map((evidence, index: number) => (
                                                <Table.Row key={index + 1}>
                                                    <Table.Cell>{evidence?.approvalsEvidenceType ? evidence?.approvalsEvidenceType : null}</Table.Cell>
                                                    <Table.Cell>{evidence?.comments ? evidence?.comments : null}</Table.Cell>
                                                    <Table.Cell>{evidence?.sectionType ? evidence?.sectionType : null}</Table.Cell>
                                                </Table.Row>
                                            ))
                                            }
                                        </Table.Body>
                                    </Table>
                                </div> </> : null}

                            <div className="nhsuk-grid-column-two-thirds" >
                                <div className="nhsuk-u-padding-bottom-5"></div>
                                <a href="#" onClick={(e) => { handleAnchorClick(e) }} >Add another document</a>
                            </div>

                            <div className="nhsuk-grid-column-two-thirds">
                                <div className="nhsuk-u-padding-bottom-5"></div>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>
                        </div>
                        :
                        <div className="nhsuk-grid-row">
                            <Loader loadingText={dsaContent.txtLoading} />
                        </div>
                    }
                </main>
            </div>
        </>
    )
}
export default ApprovalEvidenceData;
