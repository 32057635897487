import React, { HTMLProps, ReactNode } from 'react';
import classNames from 'classnames';


import { Container } from 'nhsuk-react-components';
import { AsElementLink } from '../Attachment/LinkTypes';

import "./InfoHeaderPanel.css";

type Item = React.FC<AsElementLink<HTMLParagraphElement>>;

const Item: Item = ({
    className, children, asElement: Component = 'p', ...rest
}) => (
    <li className="nhsuk-infoheaderpanel__item">
        <Component className={classNames('nhsuk-infoheaderpanel__link', className)} {...rest}>
            {children}
        </Component>
    </li>
);

const Back: Item = ({ className, asElement: Component = 'p', ...rest }) => (
    <p className={classNames('nhsuk-infoheaderpanel__back', className)}>
        <Component className="nhsuk-infoheaderpanel__backlink" {...rest} />
    </p>
);

interface InfoHeaderPanel extends React.FC<HTMLProps<HTMLDivElement>> {
    Item: Item;
    Back: Item;
}

type SplitChildren = {
    ItemChildren: Array<ReactNode>;
    OtherChildren: Array<ReactNode>;
};

const InfoHeaderPanel: InfoHeaderPanel = ({ className, children, ...rest }) => {
    // Split off any "Item" components
    const { ItemChildren, OtherChildren } = React.Children.toArray(children).reduce<SplitChildren>(
        (prev, child) => {
            if (child && typeof child === 'object' && 'type' in child && child.type === Item) {
                prev.ItemChildren.push(child);
            } else {
                prev.OtherChildren.push(child);
            }
            return prev;
        },
        {
            ItemChildren: [],
            OtherChildren: [],
        },
    );

    return (
        <nav className={classNames('nhsuk-infoheaderpanel', className)} {...rest}>
            <Container>
                <ol className="nhsuk-infoheaderpanel__list">{ItemChildren}</ol>
                {OtherChildren}
            </Container>
        </nav>
    );
};

InfoHeaderPanel.Item = Item;
InfoHeaderPanel.Back = Back;

InfoHeaderPanel.defaultProps = {
    'aria-label': 'InfoHeaderPanel',
};

export default InfoHeaderPanel;