import { taskListSectionStatus, controllerSection } from "../../../../config/page.config";
import { IApiResSectionList, IApplication, ISectionStatus, ISectionStatusTaskList } from "../../../../state/models/app.interface";
import { Tag } from "nhsuk-react-components";
import { enableStatus } from "../../../../Helper/Utility";

interface ApplicationOverviewProps  {
    headingNumber: number, 
    taskListStatus:ISectionStatus, 
    sectionStatus: ISectionStatusTaskList,
    taskEnableSectionMultiSelect: IApiResSectionList[],
    handleClickEvidence: (e: React.MouseEvent<HTMLAnchorElement>) => void,
    handleClickSubmitApplication: (e: React.MouseEvent<HTMLAnchorElement>) => void,
    readyToProceed1c: boolean
}

const ApplicationOverview: React.FC<ApplicationOverviewProps> = ({ headingNumber, taskListStatus, sectionStatus, taskEnableSectionMultiSelect, handleClickEvidence, handleClickSubmitApplication,readyToProceed1c}) => {
    const checkEnable = (sectionId: Number) => {
        return enableStatus(sectionId, taskEnableSectionMultiSelect);
    }
    return (
        <>
            <h3>{headingNumber}. Application overview</h3>
            <p>We need you to upload any supporting documents and submit the application.</p>

            <table className="nhsuk-table section-list-no-border">
                <tbody className="nhsuk-table__body">
                    {checkEnable(controllerSection.ListOfUploadedDocuments) && <tr className="nhsuk-table__row">
                        <td className="nhsuk-table__cell">
                            <a href="" onClick={handleClickEvidence}>List of uploaded documents</a></td>
                        <td className="nhsuk-table__cell">
                            {String(taskListStatus.ListOfUploadedDocuments) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.ListOfUploadedDocuments) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)}
                        </td>
                    </tr>}
                        <tr className="nhsuk-table__row">
                            <td className="nhsuk-table__cell">
                            {(!sectionStatus.submitApplication || !readyToProceed1c) ? "Submit application" : <a href="" onClick={handleClickSubmitApplication}>Submit application</a>}
                            </td>
                            <td className="nhsuk-table__cell">
                            {readyToProceed1c ?(String(taskListStatus.SubmitApplication) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag>
                                    : (String(taskListStatus.SubmitApplication) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag>
                                        : (String(taskListStatus.SubmitApplication) === String(taskListSectionStatus.actionNeeded)) ? <Tag color="orange" className="nhsuk-float-right">ACTION NEEDED</Tag>
                                        : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>)) : <Tag color="grey" className="nhsuk-float-right">CANNOT START YET</Tag>}
                            </td>
                        </tr> 
                </tbody>
            </table>
        </>
    )
}

export default ApplicationOverview;
