import React, { HTMLProps, useContext } from 'react';
import classNames from 'classnames';
import HeadingLevel, { HeadingLevelType } from '../SummaryCard/HeadingLevel';
import AttachmentContext from './AttachmentContext';

interface AttachmentHeadingProps extends HTMLProps<HTMLHeadingElement> {
    headingLevel?: HeadingLevelType;
}

const AttachmentHeading: React.FC<AttachmentHeadingProps> = ({ className, ...rest }) => {
    const { feature } = useContext(AttachmentContext);
    return (
        <HeadingLevel
            className={classNames(
                'nhsuk-attachment__title',
                className,
            )}
            {...rest}
        />
    );
};

AttachmentHeading.defaultProps = {
    headingLevel: 'h4',
};

export default AttachmentHeading;