import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Radios, Hint } from "nhsuk-react-components";
import { controllerSection, dataSetErrorMessage, dsaContent, fileTypeSize, routeName, sectionOptions, taskListSectionStatus } from "../../../../config/page.config";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader'
import ErrorDetails from '../../../../Components/Shared/ErrorSummary';
import { fetchInterceptor, getCookie, submitDSASectionStatus, submitSpinner } from '../../../../Helper/Utility';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { AppDispatch } from '../../../../state/app/store';
import Loader from "../../../../Components/Loader/Loader";
import GoBack from "../../../../Components/Shared/GoBack";
import { IDataSet, IDataSetList, IHistoricAnnualData, IDataSetFieldList, IDataSetFieldGroupList, IApplication } from "../../../../state/models/app.interface";
import { DeleteDSAChildRecord_URL } from "../../../../config/api-endpoints.config";
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";
import { getDataSetListDetails, setDataSetListDetailsData } from "../../../../state/slice/DataSetList/DataSetList.slice";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
/* Controller Delete Page*/
const DeleteDataSet: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const [deleteValue, setDeleteValue] = useState('');
    const [deleteDataSetErrorMessage, setDeleteDataSetErrorMessage] = useState("");
    const [resetList, setResetList] = useState(false);

    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};

    const dataSetDetails: IDataSet = useAppSelector(getDataSetDetails);
    const initialDataSetState: IDataSet = dataSetDetails || {};
    const [dataSetData, setDataSetData] = useState(initialDataSetState);

    const dataSetListDetails: IDataSetList = useAppSelector(getDataSetListDetails);
    const initialDataSetListState: IDataSetList = dataSetListDetails || {};
    const [dataSetListData, setDataSetListData] = useState(initialDataSetListState);
    let dataSetLength: number = 0;


    /* To dispatch the setDataSetDetailsData Redux action */
    const saveDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }

    const saveDataListInStore = (stateData?: IDataSetList) => {
        dispatch(setDataSetListDetailsData({ ...dataSetListData, ...stateData }));
    }

    /*Delete section*/
    const resetDataSetListData = () => {
        let filteredDataSetList: IDataSet[];
        const dataSetList = dataSetListData.associatedProduct ? dataSetListData.associatedProduct : [];
        filteredDataSetList = dataSetList.filter((dataset: IDataSet) => dataset.crmAssociatedProductId !== dataSetData.crmAssociatedProductId)
        dataSetLength = filteredDataSetList.length;
        saveDataListInStore({ ...dataSetListData, associatedProduct: (filteredDataSetList as [IDataSet]) });

    }
    const resetDataSetData = () => {
        let updatedState: IDataSet = {}
        updatedState = {
            dataDissemination: false,
            serviceType: "",
            description: "",
            crmUID: "",
            crmProductId: "",
            crmAssociatedProductId: "",
            name: "",
            aggregatedOrRecordLevelData: "",
            linkedToCohort: false,
            datasetFrequency: "",
            historicAnnualDataList: [initialStateHistoricData],
            serviceTypeOneOff: false,
            serviceTypeRegularBespoke: false,
            serviceTypeRegularStandard: false,
            serviceTypeSystemAccess: false,
            standardisationServiceLevelOptionsSelected: "",
            aboutDataSectionStatus: "",
            datasetCohort: "",
            selectedProductFieldsList: [initialStateFieldGroupList],
            firstPointer: 0,
            selectedPaginationPage: 0,
            updatedDaily: false,
            annualPeriodsExist: false,
            currentYTDExists: false,
            latestRelease: false,
            futureRelease: false,
            historicAnnual: false,
            initialHistoricAnnualDataList: [initialStateHistoricData],
            groupByFieldsList: [initialStateGroupFieldList],
            currentCRMSelectedDatasetFields: [initialStateFieldGroupList],
            existingHistoricAnnualDataList: [initialStateHistoricData]
        }
        saveDataInStore(updatedState);
        setResetList(true);
    }
    const initialStateFieldGroupList: IDataSetFieldList = {
        id: "",
        groupFieldId: "",
        groupName: "",
        fieldName: "",
        fieldType: "",
        fieldLogicalName: "",
        sensitiveFlag: false,
        identifiableFlag: false,
        isGroupField: false,
        isPrimaryField: false,
        description: "",
        startDate: "",
        endDate: "",
        groupType: ""
    }
    const initialStateGroupFieldList: IDataSetFieldGroupList = {
        groupName: "",
        groupId: "",
        groupFieldList: [initialStateFieldGroupList]
    }
    const initialStateHistoricData: IHistoricAnnualData = {
        period: "",
        fileAvailabilityId: "",
        periodValue: "",
        displayText: "",
        selected: false,
        periodAreadyMappedToFileAvailabilityInCRM: false,
        alreadyHeld: false,
        version:""
    }

    const handleDelete = (crmAssociatedProductId: string) => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "Id": crmAssociatedProductId,
            "SectionId": sectionOptions.AboutTheData
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(DeleteDSAChildRecord_URL, requestOptions)
            .then(() => {
                    resetDataSetListData();
                    resetDataSetData();
                    updateSectionStatus();
            })
            .catch(() => navigate(routeName.serviceError));
    }

    /*  Updating the Section status on Delete*/
    const updateSectionStatus = async () => {
        const statusResponse = await submitDSASectionStatus(dsaApplicationData.id!, controllerSection.aboutData, taskListSectionStatus.inProgress);

        if (statusResponse && dataSetLength > 0) {
            navigate(routeName.datasetDetails);
        }
        else {
            navigate(routeName.selectDataset);
        }
    }

    const navigateDataSetDetails = () => {
        if (deleteValue === dsaContent.yes) {
            handleDelete(dataSetData?.crmAssociatedProductId!);
        } else {
            navigate(routeName.datasetDetails);
        }
}

    const handleDeleteContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateDeleteFields();
        if (isValid) {
            submitSpinner(e, navigateDataSetDetails); 
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const validateDeleteFields = () => {
        let isValid: boolean = true;

        if (deleteValue === '') {
            setDeleteDataSetErrorMessage(`${dataSetErrorMessage.deleteDataSet_ErrorMessagePart1}${dataSetData.name}${dataSetErrorMessage.deleteDataSet_ErrorMessagePart2}`);
            isValid = false;
        }
        return isValid;
    }

    const resetDeleteError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setDeleteDataSetErrorMessage("");
        }
    }

    const handleChangeDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteValue(e.target.value)
        resetDeleteError(e)
    }
    /*Delete section*/
    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} /> :
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    About the data</span>
                                    Are you sure you want to delete '{dataSetData.name}' data set?
                                </h1>
                                {deleteDataSetErrorMessage !== "" && <ErrorDetails errorDescription={deleteDataSetErrorMessage} errorHref="#deleteController"></ErrorDetails>}

                                <Radios
                                    name="deletecontroller"
                                    id="deleteController"
                                    error={deleteDataSetErrorMessage}
                                >
                                    <Radios.Radio value={dsaContent.yes} onChange={handleChangeDelete} >
                                        {dsaContent.yes}
                                        <Hint>If you select yes, all details for '{dataSetData.name}' will be removed. </Hint>
                                    </Radios.Radio>
                                    <Radios.Radio value={dsaContent.no} onChange={handleChangeDelete}>{dsaContent.no}</Radios.Radio>
                                </Radios>

                                <div className="nhsuk-u-padding-bottom-8"></div>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleDeleteContinue}>{dsaContent.btnTextContinue}</Button>

                            </div>
                        }
                    </div>
                </main>
            </div>
        </>
    )
}

export default DeleteDataSet;
