import { Button, Label } from "nhsuk-react-components";
import React, { useEffect } from "react";
import { dsaContent, pageTitles } from "../../config/page.config";
import { closeWindow, updatePageTitle } from "../../Helper/Utility";

const ContactUs: React.FC = () => {

    useEffect(() => {
        updatePageTitle(pageTitles.contactUs);
    }, []);

    return (
        <div className="nhsuk-width-container">
            <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                <div className="nhsuk-grid-row">
                    <div className="nhsuk-grid-column-two-thirds">
                        <h1>Contact us </h1>
                        <Label size="m" className="nhsuk-label-font-normal-500">Contact us about the Data Access Request Service (DARS)</Label>

                        <Label size="s"> The user interface of DARS Online has recently been updated. We are keen to hear your feedback and would like to know if you have had any issues using the website.</Label>

                        <Label size="s"> You can send us feedback by completing the  <a href="https://feedback.digital.nhs.uk/jfe/form/SV_bjyeZ6KBPc967cy" target="_blank"> DARS Online website feedback form.</a></Label>
                        <Label size="s">
                            If you need help with making a new application for data, email <a href='mailto:enquiries@nhsdigital.nhs.uk?subject=DARS application'>enquiries@nhsdigital.nhs.uk</a> quoting 'DARS application' in the subject field.
                        </Label>
                        <Label size="s">
                            If you need help with an existing Data Sharing Agreement (DSA), information on suspended, closed or cancelled DSAs, or cannot find the information you need about a specific service, email <a href='mailto:data.applications@nhsdigital.nhs.uk'>data.applications@nhsdigital.nhs.uk</a>
                        </Label>
                        <Label size="s">
                            Our NHS England customer service centre is open 9 am to 5 pm, Monday to Friday, except on public holidays. We are unable to assist with enquiries about the wider NHS.
                        </Label>
                        <Label size="s">
                            NHS England runs services which support the NHS. Read <a href="https://digital.nhs.uk/services/a-to-z" target="_blank">more about our services.</a>
                        </Label>
                        <Label size="m" className="nhsuk-label-font-normal-500">Press enquiries</Label>
                        <Label size="s" className="nhsuk-space-bottom-double">If you are a journalist or member of the media, email <a href='mailto:media@nhsdigital.nhs.net'>media@nhsdigital.nhs.net</a></Label>
                        <Button onClick={closeWindow}>{dsaContent.btnTextClose}</Button>
                    </div>
                </div>
            </main>
        </div>
    )
}
export default ContactUs;