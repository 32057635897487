import { Button, Label, InsetText, Input, ErrorSummary } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IProcessor, IApplication, IAutoComplete, IProcessorList, IAutoCompleteData, IPostOrganization, IControllerList } from "../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { OrganisationLookup_URL, UpdateScreenDataProcessor_URL } from "../../../../config/api-endpoints.config";
import { getCookie, submitSpinner, getTaskListType, fetchInterceptor, checkApplicationChangeType } from "../../../../Helper/Utility";
import { dsaContent, dsaProcessorErrorMessage, dsaErrorMessage, entity, taskListSectionStatus, routeName, fileTypeSize, emptyGuid, applicationChangeType } from '../../../../config/page.config';
import { getProcessorDetails, setProcessorDetails } from '../../../../state/slice/Processor/Processor.slice';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { AppDispatch } from '../../../../state/app/store';
import { getProcessorListDetails, setProcessorListDetailsData } from "../../../../state/slice/ProcessorList/ProcessorList.slice";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import useDebounce from "../../../../Hooks/useDebounce";
import { getControllerListDetails } from "../../../../state/slice/ControllerList/ControllerList.slice";

/*
 Summary: On this page, the user will add the organization for the processor data. Here,
          the user can You have added the below Processor(s)organisation either using ODS code or Organisation name. Therefore, 
          if the user cannot find their organization in the list. Then you can add your organisation using 
          this link "enter details of your organisation" 
*/

const ProcessorData: React.FC = () => {
    let navigate = useNavigate();
    const [searchOrgNameLinkError, setsearchOrgNameLinkError] = useState("");
    const [searchOrgNameError, setsearchOrgNameError] = useState("");
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [organizationList, setorganizationList] = useState<IPostOrganization[]>([]);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    const initialControllerListState: IControllerList = useAppSelector(getControllerListDetails) || {};
    const [controllerListData,] = useState(initialControllerListState);

    const [controllerIsProccessorCount,] = useState((controllerListData.controllerList?.filter(controller => controller.isControllerProcessingData === true))?.length || 0);

    const [autocompleteErrorMsg, setautocompleteErrorMsg] = useState("nhsuk-autocomplete-items");
    const [filteredSuggestions, setFilteredSuggestions] = useState<IAutoComplete[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showspinner, setshowspinner] = useState("");
    const [iconcontainerSpinner, seticoncontainerSpinner] = useState("nhsuk-icon_container");
    let activeSuggestion: number = -1;
    let suggestionsListComponent: JSX.Element = <></>;

    const initialState: IProcessor = useAppSelector(getProcessorDetails);
    const [processorData, setProcessorData] = useState(initialState);
    const dispatch: AppDispatch = useAppDispatch();
    const saveDataInStore = (stateData?: IProcessor) => {
        dispatch(setProcessorDetails({ ...processorData, ...stateData }));
    }
    const [selectedId, setSelectedId] = useState(processorData.processordataOrgGuidId ? processorData.processordataOrgGuidId : "");
    const processorListDetailsData: IProcessorList = useAppSelector(getProcessorListDetails);
    const initialProcessorListState: IProcessorList = processorListDetailsData || {};
    const [processorListData] = useState(initialProcessorListState);
    const processorIndex = processorListData.activeProcessor || 0;

    const [orgCount] = useState((processorData?.isNewProcessor === true ? processorListData?.processorList?.length : 0) || 0);
    let isDirtyProcessor = processorData.isDirtyProcessor || false;
    const saveProcessorListDataInStore = (stateData?: IProcessorList) => {
        dispatch(setProcessorListDetailsData({ ...processorListData, ...stateData }));
    }
    let suggestions = organizationList;

    const [selectedText, setSelectedText] = useState("");
    const [userquery, setuserquery] = useState("");

    const [showProcessorOrganisations, setShowProcessorOrganisations] = useState(false);
    const [showSearch, setShowSearch] = useState(true);

    const handleAddAnotherProcessorLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowProcessorOrganisations(false);
        setShowSearch(true);
    }

    const getOrganisationData = async () => {
        try {
            setShowSuggestions(false);
            setshowspinner("nhsuk-loader-three");
            const parameters: string = JSON.stringify({
                "UserAuthTokenId": authenticationTokenCookie,
                "SearchString": processorData.processordataOrgDesc
            });
            const requestOptions: Object = {
                method: 'POST',
                headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
                body: parameters
            };
            await fetchInterceptor(OrganisationLookup_URL, requestOptions)
                .then(response => {
                    setshowspinner("");
                    return response.json()
                })
                .then(data => {
                    setShowSuggestions(false);
                    setorganizationList(data);
                    suggestions = data;
                    bindSuggestionList();
                })
                .catch(() => navigate(routeName.serviceError));
        }
        catch (e: any) {
            console.log("error response", e.response);
        }
    };

    const resetError = (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement> | React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLInputElement>) => {
        const selectedOrgId = e.currentTarget.id;
        const target = e.target as HTMLInputElement
        setautocompleteErrorMsg("nhsuk-autocomplete-items");
        seticoncontainerSpinner("nhsuk-icon_container");
        if (target.value !== undefined && target.value !== null) {
            const valueLength = target.value.length;
            if (valueLength >= 1) {
                setsearchOrgNameError("");
                setsearchOrgNameLinkError("");
            }
        }
        if (selectedOrgId !== undefined && selectedOrgId !== null && selectedOrgId !== '') {
            setsearchOrgNameError("");
            setsearchOrgNameLinkError("");
        }

    }

    const ValidateFields = () => {
        let isValid: boolean = true;
        if (!processorData.processordataOrgDesc && !showProcessorOrganisations) {
            setsearchOrgNameLinkError(`${dsaProcessorErrorMessage.organisationSearchMandatory_ErrorMessage}`);
            setsearchOrgNameError(`${dsaProcessorErrorMessage.organisationSearchMandatory_ErrorMessage}`);
            isValid = false;
        }
        if (processorData.processordataOrgDesc && selectedId === '' && processorData.processordataOrgGuidId === '' && ((showProcessorOrganisations && orgCount < Number(maxCount)) || (!showProcessorOrganisations && orgCount < Number(maxCount)))) {
            setsearchOrgNameLinkError(`${dsaProcessorErrorMessage.organisationNotSelected_ErrorMessage}`);
            setsearchOrgNameError(`${dsaProcessorErrorMessage.organisationNotSelected_ErrorMessage}`);
            isValid = false;
        }
        if (processorData.processordataOrgDesc && selectedId === '' && processorData.processordataOrgGuidId === '' && ((showProcessorOrganisations && orgCount >= Number(maxCount)) || (!showProcessorOrganisations && orgCount >= Number(maxCount)))) {
            setsearchOrgNameLinkError(`${dsaProcessorErrorMessage.secondOrganisationNotSelected_ErrorMessage}`);
            setsearchOrgNameError(`${dsaProcessorErrorMessage.secondOrganisationNotSelected_ErrorMessage}`);
            isValid = false;
        }
        if (!isValid) {
            setautocompleteErrorMsg("nhsuk-autocomplete-items nhsuk-autocomplete-error");
            seticoncontainerSpinner("nhsuk-icon-height");
        }
        return isValid;
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (ValidateFields()) {
            submitSpinner(e, submitProcessorSelectOrg);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const submitProcessorSelectOrg = () => {
        if (selectedId !== '' && selectedId !== undefined && selectedId !== null) {
            if (processorListData.processorDetailsPage && !processorData.isNewProcessor) {
                if (isDirtyProcessor) {
                    const processorId = processorListData.processorList ? processorListData.processorList[processorIndex].processorId : null;
                    const parameters: string = JSON.stringify({
                        "UserAuthTokenId": authenticationTokenCookie,
                        "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
                        "IsOrganisationDirty": true,
                        "OrganisationId": selectedId,
                        "DataProcessorId": processorId,
                        "SectionStatus": dsaApplicationData.aboutProcessorSectionStatus
                    });
                    const requestOptions: Object = {
                        method: 'POST',
                        headers: { 'Content-Type': fileTypeSize.fileTypeJson },
                        body: parameters
                    };
                    fetchInterceptor(UpdateScreenDataProcessor_URL, requestOptions)
                        .then(response => {
                            return response.json()
                        })
                        .then(() => {
                            let sectionStatus: IApplication = {
                                ...dsaApplicationData, aboutProcessorSectionStatus: taskListSectionStatus.inProgress
                            }
                            setDsaApplicationData(sectionStatus);
                            saveApplicationDataInStore(sectionStatus);
                            navigate(routeName.processorDetails);
                        })
                        .catch(() => navigate(routeName.serviceError));
                }
                else { navigate(routeName.processorDetails); }
            }
            else {
                let updatedState: IProcessor = {};
                if (selectedId !== '' && selectedId !== undefined && selectedId !== null) {
                    updatedState = {
                        ...processorData,
                        processordataOrgGuidId: selectedId,
                        processordataOrgDesc: selectedText
                    }
                    setProcessorData(updatedState);
                    saveDataInStore(updatedState);
                    navigate(routeName.processorSecurityAssurance);
                }

            }

        }
        else if (processorListData.processorDetailsPage) {
            let updatedState: IProcessorList = {
                ...processorListData, processorDetailsPage: dsaContent.checkYourAnswersPageText
            }
            saveProcessorListDataInStore(updatedState);
            navigate(routeName.processorDetails);
        }
        else {
            navigate(routeName.processorSecurityAssurance);
        }
    }
    /* AutoComplete code started */
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowSuggestions(false);
        resetError(e);
        setSelectedId('');
        setSelectedText('');
        activeSuggestion = -1;
        setuserquery(e.currentTarget.value);
        let updatedState: IProcessor = {};
        updatedState = { ...processorData, processordataOrgDesc: e.currentTarget.value, processordataOrgGuidId: '', isDirtyProcessor: true }
        setProcessorData(updatedState);
        saveDataInStore(updatedState);
        setshowspinner("");
        setFilteredSuggestions([]);
        if (e.currentTarget.value === "") {
            setShowProcessorOrganisations(false);
        }
    };

    const bindSuggestionList = () => {

        if ((processorData.processordataOrgDesc ? processorData.processordataOrgDesc.length : "") >= 3) {
            if (suggestions !== null && suggestions !== undefined) {
                const filteredSuggestions = suggestions.map((suggestion: IAutoCompleteData) => {
                    const getListData: IAutoComplete = { id: suggestion?.Id!, name: suggestion.Name! };
                    return getListData;
                });
                setFilteredSuggestions(filteredSuggestions);
                setShowSuggestions(true);
                setShowProcessorOrganisations(true);
                setshowspinner("");
            }

        }
        else {
            setFilteredSuggestions([]);
            setShowSuggestions(false);
            setshowspinner("");
            setShowProcessorOrganisations(false);
        }
    }

    const handleOnselect = (e: React.MouseEvent<HTMLElement>) => {
        if (suggestions !== undefined && suggestions !== null) {
            const selectedOrgDetails = suggestions?.filter((post: IAutoCompleteData) => {
                if (post.Id === e.currentTarget.id) {
                    return post?.Name
                }
            });
            setFilteredSuggestions([]);
            setShowSuggestions(false);
            let updatedState: IProcessor = {};
            updatedState = { ...processorData, processordataOrgDesc: selectedOrgDetails[0]?.Name, processordataOrgGuidId: selectedOrgDetails[0]?.Id }
            setProcessorData(updatedState);
            saveDataInStore(updatedState);
            setSelectedId(selectedOrgDetails ? selectedOrgDetails[0]?.Id : "");
            setSelectedText(selectedOrgDetails ? selectedOrgDetails[0]?.Name : "");
            resetError(e);
            setshowspinner("");
        }

    };

    function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {

        if (filteredSuggestions !== undefined && filteredSuggestions !== null && filteredSuggestions.length) {
            if (e.keyCode === 13) {
                setSelectedId('');
                setShowSuggestions(false);
                let updatedState: IProcessor = {};
                updatedState = { ...processorData, processordataOrgDesc: filteredSuggestions[activeSuggestion].name, processordataOrgGuidId: filteredSuggestions[activeSuggestion].id }
                setProcessorData(updatedState);
                saveDataInStore(updatedState);
                setSelectedId(filteredSuggestions[activeSuggestion].id);
                setSelectedText(filteredSuggestions[activeSuggestion].name);
                resetError(e);
            }
            // User pressed the up arrow
            else if (e.keyCode === 38) {
                activeSuggestion--;
                addActiveonElement();
            }
            // User pressed the down arrow
            else if (e.keyCode === 40) {
                if (filteredSuggestions !== undefined || filteredSuggestions !== null) {
                    activeSuggestion++;
                    addActiveonElement();
                }
            }
        }
    };

    const removeActiveElement = (elements: HTMLCollection) => {
        for (var j = 0; j < elements.length; j++) {
            elements[j].classList.remove("nhsuk-autocomplete-active");
        }
    }

    function addActiveonElement() {
        let autopupulatenameList: HTMLElement = document.getElementById("myInputautocomplete-list")!;
        if (autopupulatenameList !== undefined && autopupulatenameList !== null) {
            let childlist = autopupulatenameList.getElementsByTagName("div");
            if (childlist !== undefined && childlist !== null && childlist.length > 0) {
                removeActiveElement(childlist);
                if (activeSuggestion >= childlist.length) { activeSuggestion = 0 }
                if (activeSuggestion < 0) { activeSuggestion = childlist.length - 1 };
                childlist[activeSuggestion].classList.add("nhsuk-autocomplete-active");
            }
        }
    }
    const resetSuggestion = () => {
        let updatedState: IProcessor = {};
        updatedState = { ...processorData, processordataOrgDesc: "" }
        setProcessorData(updatedState);
        saveDataInStore(updatedState);
    }

    const resetData = () => {
        if (String(processorData.processordataOrgGuidId) === String(emptyGuid) || processorData.processordataOrgGuidId === "") { resetSuggestion(); }
    }
    const resetField = () => {
        if (String(processorData.processordataOrgGuidId) === String(emptyGuid) && processorListData.processorDetailsPage) { resetSuggestion(); }
    }

    const maxCount = entity.validSave.find(item => {
        return item.processor?.maxCount
    })?.processor?.maxCount

    if (showSuggestions && processorData?.processordataOrgDesc) {
        if (filteredSuggestions !== undefined && filteredSuggestions !== null) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <div id="myInputautocomplete-list" className={autocompleteErrorMsg}>

                        {filteredSuggestions.map((suggestion: IAutoComplete, index) => {

                            return (
                                <div onClick={handleOnselect} id={suggestion.id} key={index} >
                                    {suggestion.name}
                                </div>
                            );
                        })}
                    </div>
                );
            }
            else if (filteredSuggestions.length === 0) {
                setshowspinner("");
            }
        }
    }

    /* AutoComplete Code ended */

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.summaryTitle_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        < ErrorSummary.Item href="#organisationSearch">
                            {searchOrgNameLinkError}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    );

    const searchQuery = useDebounce(userquery);
    useEffect(() => {
        resetField();
        if (userquery.length >= 3 && searchQuery) {
            getOrganisationData();
        }
    }, [searchQuery])

    useEffect(() => {
        resetData();
        if (orgCount >= Number(maxCount)) { setShowSearch(false) }
        if (orgCount > 0) { setShowProcessorOrganisations(true) }
        if (processorListData.processorDetailsPage === dsaContent.checkYourAnswersPageText) {
            setShowProcessorOrganisations(false);
            setShowSearch(true);
        }
    }, [])

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">

                        <div className="nhsuk-grid-column-two-thirds">
                            {
                                <GoBack condition={((processorListData.processorList && processorListData.processorList.length === 0)
                                    || (processorListData.processorList && processorListData.processorList.length === 1 && processorListData.processorList[0].processorId === "")) ?
                                    ((controllerIsProccessorCount >= 1) ? dsaContent.solelyProcessor : getTaskListType(dsaApplicationData.returningJourneyType!)) 
                                    : dsaContent.processorDetails} />
                            }
                            <span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                About the Processor</span>
                            {orgCount < Number(maxCount) &&
                                <h1>Which organisation is the Processor for the data?</h1>
                            }
                            {orgCount >= Number(maxCount) ? <h1>You have added the below Processor(s)</h1> : null}

                            {searchOrgNameError !== "" ? errorSummary : null}

                            {orgCount >= Number(maxCount) ?
                                <>
                                    <InsetText>
                                        <p>
                                            If you do not need to add another Processor, select 'Continue'.
                                        </p>
                                    </InsetText>
                                    <table className="nhsuk-table ctrl-border">
                                        <tbody className="nhsuk-table__body">
                                            {
                                                processorListData?.processorList?.slice(0).reverse()?.map((processorOrg: IProcessor, index: number) =>
                                                    <tr role="row" className="nhsuk-table__row" key={index}>
                                                        <td className="nhsuk-table__cell">{index + 1}. {processorOrg.processordataOrgDesc}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </> : null
                            }
                            {showSearch && <>
                                <div className="nhsuk-input--width-30">
                                    <div className="nhsuk-input_container">
                                        <Input type="text" autoComplete="off" label={dsaContent.lbl_orgSearch} className="nhsuk-input" name="organisationSearch" id="organisationSearch" onChange={onChange} onKeyDown={handleOnKeyDown} value={processorData?.processordataOrgDesc} error={searchOrgNameError} />                                                <div className={iconcontainerSpinner} >
                                            <div className={showspinner}>
                                            </div>
                                        </div>
                                    </div>
                                    {suggestionsListComponent}
                                </div>
                            </>
                            }
                            {showSearch &&
                                <InsetText>
                                    <p>
                                        I can't find the organisation
                                    </p>
                                    <p>
                                        <Label>If the organisation is not listed, <span><Link to={routeName.addProcessorOrganisation}>enter details of your organisation.</Link></span> Once the application has been submitted, the organisation's details will be verified and stored in DARS for future use.</Label>
                                    </p>
                                </InsetText>
                            }
                            {orgCount >= Number(maxCount) ?
                                checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? "" :
                                    !showSearch ? <><Label><a href="#" onClick={handleAddAnotherProcessorLink}>{dsaContent.addAnotherProcessorLink}</a></Label>
                                        <div className="nhsuk-u-padding-bottom-9"></div></> : "" : ""}
                            {!showSearch ?
                                <>
                                    <Button name="continue" id="nhsuk-buttonorganis-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button></>
                                : <Button name="saveAndContinue" id="nhsuk-buttonorganis-spinner" onClick={handleClick}>{dsaContent.btnSaveAndContinue}</Button>
                            }
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default ProcessorData;
