import React, { useState } from "react";
import { Button, Fieldset, Radios, InsetText, ErrorSummary } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import InfoHeaderPanel from "../../../../Components/InfoHeaderPanel/InfoHeaderPanel";
import { controllerDetails, controllerSection, dsaContent, fileTypeSize, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { useAppSelector, useAppDispatch } from "../../../../state/app/hooks";
import { IController, IApplication, IControllerList } from "../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getControllerDetails, setControllerDetailsData } from "../../../../state/slice/DSAAboutController/DSAAboutController.slice";
import { AppDispatch } from '../../../../state/app/store';
import { dsaControllerErrorMessage } from "../../../../config/page.config";
import { getCookie, submitDSASectionStatus, submitSpinner, fetchInterceptor } from "../../../../Helper/Utility";
import { getControllerListDetails, setControllerListDetailsData } from "../../../../state/slice/ControllerList/ControllerList.slice";
import { UpdateScreenDataController_URL } from "../../../../config/api-endpoints.config";
import GoBack from "../../../../Components/Shared/GoBack";

/*Select if the data controller is going to process the data*/

const ProcessingDSA: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();

    const controllerDetailsData: IController = useAppSelector(getControllerDetails);
    const initialState: IController = controllerDetailsData || {};
    const [controllerData, setData] = useState(initialState);

    const controllerListDetailsData: IControllerList = useAppSelector(getControllerListDetails);
    const initialControllerListState: IControllerList = controllerListDetailsData || {};
    const [controllerListData] = useState(initialControllerListState);
    const controllerIndex = controllerListData.activeController || 0;
   
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [isProcessingDataErrorMessage, setIsProcessingDataErrorMessage] = useState("");

    const authenticationTokenCookie: string = getCookie('authenticationToken')!;

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData, setDsaApplicationData] = useState(initialApplicationState);
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    let isDirtyController = controllerData.isDirtyController || false;

    const saveDataInStore = (stateData?: IController) => {
        dispatch(setControllerDetailsData({ ...controllerData, ...stateData }));
    }

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === dsaContent.targetIsProcessingDataText) {
            setSummaryLinkErrorMessage("");
            setIsProcessingDataErrorMessage("");
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IController = {};
        updatedState = { ...controllerData, [e.target.name]: e.target.value }
        if (e.target.value === dsaContent.controllerProcessingTheData) {
            updatedState = {
                ...controllerData,
                isControllerProcessingData: true,
                controllerProcessingData: dsaContent.controllerProcessingTheData,
                isDirtyController: true
            }
        }
        if (e.target.value === dsaContent.controllerNotProcessingTheData) {
            updatedState = {
                ...controllerData,
                isControllerProcessingData: false,
                controllerProcessingData: dsaContent.controllerNotProcessingTheData,
                isDirtyController: true
            }
        }
        resetError(e);
        setData(updatedState);
        saveDataInStore(updatedState);
    };

    const ValidateFields = () => {
        let isValid: boolean = true;

        if (!controllerData.controllerProcessingData) {
            setSummaryLinkErrorMessage(`${dsaControllerErrorMessage.selectYesToProcessData_ErrorMessage}`);
            setIsProcessingDataErrorMessage(`${dsaControllerErrorMessage.selectYesToProcessData_ErrorMessage}`);
            isValid = false;
        }

        return isValid;
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, submitData);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const saveControllerListDataInStore = (stateData?: IControllerList) => {
        dispatch(setControllerListDetailsData({ ...controllerListData, ...stateData }));
    }

    const submitData = () => {
        if (controllerListData.controllerDetailsPage && !controllerData?.isNewController) {
            if (isDirtyController) {
                const controllerId = controllerListData.controllerList ? controllerListData.controllerList[controllerIndex].controllerId : null;
                const parameters: string = JSON.stringify({
                    "UserAuthTokenId": authenticationTokenCookie,
                    "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
                    "IsControlleraProcessorDirty": true,
                    "IsControlleraProcessor": controllerData.isControllerProcessingData,
                    "DataControllerId": controllerId,
                    "SectionStatus": dsaApplicationData.aboutControllerSectionStatus
                });
                const requestOptions: Object = {
                    method: 'POST',
                    headers: { 'Content-Type': fileTypeSize.fileTypeJson },
                    body: parameters
                };
                fetchInterceptor(UpdateScreenDataController_URL, requestOptions)
                    .then(response => {
                            return response.json()
                    })
                    .then(() => {
                        updateSectionStatus();
                        let updatedState: IControllerList = {
                            ...controllerListData, controllerDetailsPage: controllerListData.controllerDetailsPage
                        }
                        let sectionStatus: IApplication = {
                            ...dsaApplicationData, aboutControllerSectionStatus: taskListSectionStatus.inProgress
                        }
                        setDsaApplicationData(sectionStatus);
                        saveApplicationDataInStore(sectionStatus);
                        saveControllerListDataInStore(updatedState);
                        navigate(routeName.controllerDetails);
                    })
                    .catch(() => navigate(routeName.serviceError));
            }
            else { navigate(routeName.controllerDetails); }
        }
        else {
            navigate(routeName.securityAssurance);
        }
    }

    const updateSectionStatus = async () => {
            await submitDSASectionStatus(dsaApplicationData.id!, controllerSection.aboutProcessor, taskListSectionStatus.inProgress);
    }

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaControllerErrorMessage.selectControllerSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#isControllerProcessingData">
                            {summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    return (
        <>
            <InfoHeaderPanel>
                <InfoHeaderPanel.Item >
                    <strong>Reference Number</strong> : {dsaApplicationData.referenceNumber}
                </InfoHeaderPanel.Item>
                <InfoHeaderPanel.Item>
                    <strong>Total Estimated Cost</strong> : {dsaApplicationData.totalEstimatedCost}
                </InfoHeaderPanel.Item>
            </InfoHeaderPanel>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack />
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the Controller
                            </span>
                            <h1>Is this Controller going to be processing the data?</h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <InsetText>
                                <p>Processing means using personal data in any way, including; collecting, storing, retrieving, consulting, disclosing or sharing with someone else, erasing, or destroying personal data.</p>
                               </InsetText>
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <Fieldset className="nhsuk-space-bottom-double">
                                <Radios
                                    name={dsaContent.targetIsProcessingDataText}
                                    id="isControllerProcessingData"
                                    error={isProcessingDataErrorMessage}>
                                    <Radios.Radio
                                        name={dsaContent.targetIsProcessingDataText}
                                        checked={controllerData?.controllerProcessingData === dsaContent.controllerProcessingTheData}
                                        value={dsaContent.controllerProcessingTheData}
                                        onChange={handleChange}>Yes</Radios.Radio>
                                    <Radios.Radio
                                        name={dsaContent.targetIsProcessingDataText}
                                        checked={controllerData?.controllerProcessingData === dsaContent.controllerNotProcessingTheData}
                                        value={dsaContent.controllerNotProcessingTheData}
                                        onChange={handleChange}>No</Radios.Radio>
                                </Radios>
                            </Fieldset>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default ProcessingDSA;