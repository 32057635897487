import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { IUserDetails } from "../../models/app.interface";

const initialState: IUserDetails = {
    userFullName: "",
    userOrgName: "",
    isAuthenticated: false,
    hasSecurityRole: false,
    dsfcApproverSecurity: false,
    dsaApproverSecurity: false,
    dsaApplicantSecurity: false,
    dsfcApplicantSecurity: false,
    dsaApprover: false,
    dsfcApprover: false,
    dsfcApplicantApprover: false,
    pageLoader: true,
};

export const HeaderSlice = createSlice({
    name: "header",
    initialState,
    reducers: {
        setUserDetailsData: (state: IUserDetails, action: PayloadAction<IUserDetails>) => {
            state = Object.assign(state, action.payload);
        },
        resetUserDetailsData: () => initialState,
    },
});

export const { setUserDetailsData, resetUserDetailsData } = HeaderSlice.actions;
export const getUserDetails = (state: RootState) => state.userDetails;
export default HeaderSlice.reducer;
