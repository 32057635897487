import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { IFileUpload } from '../../state/models/app.interface';
import { dsaContent } from '../../config/page.config';
import { Button, ErrorMessage, Table } from 'nhsuk-react-components';

interface FileUploadControlProps {
  errorAboveList: string; // isFileUploadedError
  errorBelowList: string; // validFileError
  fileExist: boolean; // isSelected
  isUploaded: boolean;
  allowedFileExtension?: string;
  finallyUploadedFiles: IFileUpload[];
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleUpload: (e: React.MouseEvent<HTMLButtonElement>) => void;
  changeFile?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number,
    fileId: number,
    fileName: string,
    fileType: string
  ) => void;
  setFileUrl?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  uploadAnotherFile?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  showDownload?: boolean; //showDownload
  selectedIndex?: number;
   uploadAnother?: boolean;
    setSelectedFileId?: Dispatch<SetStateAction<number>>
}

const changeUploadedFile = (fileId: number, setSelectedFileId: Dispatch<SetStateAction<number>>) => {
    const fileElement = document.getElementById(dsaContent.file!);
    if (fileElement) {
        fileElement?.click();
        setSelectedFileId(fileId);
    }
}

const FileUploadControl: React.FC<FileUploadControlProps> = ({
  errorAboveList,
  errorBelowList,
  fileExist,
  isUploaded,
  allowedFileExtension,
  finallyUploadedFiles,
  handleChange,
  handleUpload,
  changeFile,
  setFileUrl,
  showDownload,
  selectedIndex,
  uploadAnother,
    uploadAnotherFile,
    setSelectedFileId
}) => {
  return (
    <div className="nhsuk-grid-column-two-thirds" id="fileupload">
      <ErrorMessage>{errorAboveList}</ErrorMessage>
      {fileExist || finallyUploadedFiles.length >= 1 ? (
        <>
          <p>Files you have uploaded</p>
          <Table responsive>
            <Table.Body>
              {finallyUploadedFiles.map((file: IFileUpload, index: number = 1) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    {' '}
                    {file.FileId! > 0 && setFileUrl ? (
                      <a href="#" id={String(file?.FileId)} onClick={(e) => setFileUrl(e)}>
                        {file.FileName}
                      </a>
                    ) : (
                      file.FileName
                    )}
                    {showDownload && String(file?.FileId) === String(selectedIndex) ? (
                      <span className="nhsuk-loader__container loading-results ">
                        <span className="nhsuk-loader-file"></span>
                        Downloading file
                      </span>
                    ) : null}
                  </Table.Cell>
                    <Table.Cell>
                      <a
                        href="#"
                        onClick={(e) =>
                            changeUploadedFile(file.FileId!, setSelectedFileId!)
                        }
                      >
                        Change
                      </a>
                    </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
                  {uploadAnotherFile && !fileExist && (
            <>
              <div className="nhsuk-u-padding-bottom-4"></div>
              <p>
                <a href="#" onClick={(event) => uploadAnotherFile(event)}>
                  Upload another file
                </a>
              </p>
            </>
          )}
        </>
      ) : null}
      <ErrorMessage> {errorBelowList}</ErrorMessage>
          <div className={fileExist ? "hide" : ""}>
          <input type="file" id={dsaContent.file} name={dsaContent.file} onChange={handleChange} />
          <p></p>
          <p>
            <Button
              secondary
              name={dsaContent.upload}
              id={dsaContent.upload}
              onClick={handleUpload}
            >
              Upload{' '}
            </Button>
            {isUploaded ? (
              <span className="nhsuk-loader__container loading-results">
                <span className="nhsuk-loader-file"></span>
                Uploading
              </span>
            ) : null}
          </p>
        </div>
    </div>
  );
};
export default FileUploadControl;
