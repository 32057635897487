import { Button, DateInput, Details, Input, Label, InsetText, ErrorSummary } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader';
import { controllerDetails, dsaControllerErrorMessage, dsaContent, taskListSectionStatus, routeName, fileTypeSize } from "../../../../config/page.config";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { IController, IApplication, IControllerList } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { getControllerDetails, setControllerDetailsData } from "../../../../state/slice/DSAAboutController/DSAAboutController.slice";
import { getCookie, submitSpinner, validateDateValue, calculateExampleDate, fetchInterceptor } from "../../../../Helper/Utility";
import { SubmitScreenDataController_URL, UpdateScreenDataController_URL } from "../../../../config/api-endpoints.config";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getControllerListDetails, setControllerListDetailsData } from "../../../../state/slice/ControllerList/ControllerList.slice";
import GoBack from "../../../../Components/Shared/GoBack";

/*
  Save controller data.
*/

const DataProtectionRegistration: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const [expiryDate, setExpiryDate] = useState(false);

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    const controllerListDetailsData: IControllerList = useAppSelector(getControllerListDetails);
    const initialControllerListState: IControllerList = controllerListDetailsData || {};
    const [controllerListData] = useState(initialControllerListState);
    const saveControllerListDataInStore = (stateData?: IControllerList) => {
        dispatch(setControllerListDetailsData({ ...controllerListData, ...stateData }));
    }
    const controllerIndex = controllerListData.activeController || 0;

    const dsaControllerDetailsData: IController = useAppSelector(getControllerDetails);
    const initialState: IController = dsaControllerDetailsData || {};
    const [controllerData, setControllerData] = useState(initialState);
    const saveDataInStore = (stateData?: IController) => {
        dispatch(setControllerDetailsData({ ...controllerData, ...stateData }));
    }
    let isDirtyController = controllerData.isDirtyController || false;

    const [registrationNumberLinkError, setRegistrationNumberLinkError] = useState("");
    const [organisationNameLinkError, setOrganisationNameLinkError] = useState("");
    const [datePublishedError, setDatePublishedError] = useState("");
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [diDayError, setDiDayError] = useState(false);
    const [diMonthError, setDiMonthError] = useState(false);
    const [diYearError, setDiYearError] = useState(false);
    const customErrorMessage = { customDateMonthYearMissingDateExpired_ErrorMessage: dsaControllerErrorMessage.customDateMonthYearMissingDateExpired_ErrorMessage };

    /*Variable Declarations*/

    /*Store value of input*/
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IController = {};
        updatedState = { ...controllerData, [e.target.name]: e.target.value, isDirtyController: true }
        resetError(e);
        setControllerData(updatedState);
        saveDataInStore(updatedState);
    };
    const submitExpiryDate = () => {
        let updatedState: IController = {};
        updatedState = {
            ...controllerData,
            dpaRegistrationExpiryDate: controllerData?.dpDay + "/" + controllerData?.dpMonth + "/" + controllerData?.dpYear
        }
        setControllerData(updatedState);
        saveDataInStore(updatedState);
    }
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === dsaContent.registrationNumberText && e.target.value !== "") {
            setRegistrationNumberLinkError("");
        }
        if (e.target.name === dsaContent.organisationNameText && e.target.value !== "") {
            setOrganisationNameLinkError("");
        }
        if (e.target.name === dsaContent.dpInputDayField) {
            setDatePublishedError("");
            setDiDayError(false);
        }
        if (e.target.name === dsaContent.dpInputMonthField) {
            setDatePublishedError("");
            setDiMonthError(false);
        }
        if (e.target.name === dsaContent.dpInputYearField) {
            setDatePublishedError("");
        }
    }
    /*Store value of input*/

    /*Submit controller data*/
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (ValidateFields()) {
            submitSpinner(e, submitRegistrationDetails);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };
    const ValidateFields = () => {
        let isValid: boolean = true;
        submitExpiryDate();

        if (!controllerData?.dpaRegistrationNumber) {
            setRegistrationNumberLinkError(`${dsaControllerErrorMessage.enterRegistrationNumber_ErrorMessage}`);
            isValid = false;
        }

        if (!controllerData?.dpaOrganisationName) {
            setOrganisationNameLinkError(`${dsaControllerErrorMessage.enterOrganisationName_ErrorMessage}`);
            isValid = false;
        }

        let dateValueError = validateDateValue(Number(controllerData?.dpDay), Number(controllerData?.dpMonth), Number(controllerData?.dpYear), expiryDate, false, false, customErrorMessage);
        if (dateValueError[0] !== "") {
            setDatePublishedError(dateValueError[0]);
            let fields = dateValueError[1].split(",");
            for (let i = 0; i < fields.length; i++) {
                if (fields[i] === dsaContent.dpInputDayField) {
                    setDiDayError(true);
                }
                if (fields[i] === dsaContent.dpInputMonthField) {
                    setDiMonthError(true);
                }
                if (fields[i] === dsaContent.dpInputYearField) {
                    setDiYearError(true);
                }
            }
            isValid = false;
        }
        return isValid;
    }

    const submitRegistrationDetails = () => {
        if (controllerListData.controllerDetailsPage && !controllerData.isNewController) {
            if (isDirtyController) {
                const controllerId = controllerListData.controllerList ? controllerListData.controllerList[controllerIndex].controllerId : null;
                const parameters: string = JSON.stringify({
                    "UserAuthTokenId": authenticationTokenCookie,
                    "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
                    "DPARegisteredExpiry": controllerData?.dpDay + "/" + controllerData?.dpMonth + "/" + controllerData?.dpYear,
                    "DPARegisteredName": controllerData.dpaOrganisationName,
                    "DPARegisteredCode": controllerData.dpaRegistrationNumber,
                    "DataControllerId": controllerId,
                    "DpaId": controllerData.dpaId,
                    "IsDpaDirty": true
                });
                const requestOptions: Object = {
                    method: 'POST',
                    headers: { 'Content-Type': fileTypeSize.fileTypeJson },
                    body: parameters
                };
                fetchInterceptor(UpdateScreenDataController_URL, requestOptions)
                    .then(response => {
                            return response.json()
                    })
                    .then(() => {
                        let updatedState: IControllerList = {
                            ...controllerListData, controllerDetailsPage: controllerListData.controllerDetailsPage
                        }
                        saveControllerListDataInStore(updatedState);
                        navigate(routeName.controllerDetails);
                    })
                    .catch(() => navigate(routeName.serviceError));

            }
            else { navigate(routeName.controllerDetails); }
        }
        else
        {
                submitControllerData();          
        }
    }

    const submitControllerData = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "DataControllerId": "",
            "OrganisationName": controllerData.alternativeOrganisationName,
            "OrganisationId": controllerData.controllerdataOrgGuidId,
            "AssuranceTypeSelectedISO": null,
            "AssuranceTypeSelectedOther": null,
            "DataProcessorSelectedISO": null,
            "DataProcessorSelectedOther": null,
            "IGToolkitVersion": "",
            "IGToolkitDateComplete": "",
            "IGToolkitODSCode": "",
            "IGToolkitNotes": "",
            "IGToolkitScore": null,
            "OtherVersion": "",
            "OtherDateComplete": null,
            "OtherNotes": "",
            "DPARegisteredCode": controllerData.dpaRegistrationNumber,
            "DPARegisteredName": controllerData.dpaOrganisationName,
            "DPARegisteredExpiry": controllerData?.dpDay + "/" + controllerData?.dpMonth + "/" + controllerData?.dpYear,
            "FileList": controllerData.fileUpload,
            "IsPayingForDSA": false,
            "PurchaseOrderNumber": null,
            "PurchaseOrderNotRequired": false,
            "YouWillBeSignAgreement": false,
            "YouKnowBehalfOrganisationSignAgreement": false,
            "Title": null,
            "FirstName": null,
            "Lastname": null,
            "Email": null,
            "SigneeOrganisation": null,
            "JobTitle": null,
            "PhoneNumber": null,
            "ContactName": null,
            "ContactId": null,
            "ChangeType": null,
            "IsControlleraProcessorDirty": controllerData.isDirtyController,
            "IsControlleraProcessor": controllerData.isControllerProcessingData,
            "AddressLine1": controllerData.alternativeStreet1,
            "AddressLine2": controllerData.alternativeStreet2,
            "AddressLine3": controllerData.alternativeStreet3,
            "TownCity": controllerData.alternativeCity,
            "County": controllerData.alternativeCounty,
            "Postcode": controllerData.alternativePostCode,
            "Country": controllerData.alternativeCountry,
            "SecurityAssurance": controllerData.saList,
            "SectionStatus": dsaApplicationData.aboutControllerSectionStatus
        });

        const requestOptions: object = {
            method: 'post',
            headers: { 'content-type': fileTypeSize.fileTypeJson, 'accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitScreenDataController_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data) {
                    let updatedState: IControllerList = {
                        ...controllerListData, controllerDetailsPage: dsaContent.summaryPageText
                    }
                    let sectionStatus: IApplication = {
                        ...dsaApplicationData, aboutControllerSectionStatus: taskListSectionStatus.inProgress
                    }
                    setDsaApplicationData(sectionStatus);
                    saveApplicationDataInStore(sectionStatus);
                    saveControllerListDataInStore(updatedState);
                    navigate(routeName.controllerDetails);
                }
            })
            .catch(() => navigate(routeName.serviceError));
    }
    /*Submit controller data*/

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaControllerErrorMessage.selectControllerSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#dpaRegistrationNumber">
                            {registrationNumberLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#dpaOrganisationName">
                            {organisationNameLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#dpaRegistrationExpiryDate">
                            {datePublishedError}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    useEffect(() => {
        setExpiryDate(true);
    }, [])

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack/>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the Controller
                            </span>

                            <h1>Data protection <br /> registration for the <br /> Controller</h1>
                            {registrationNumberLinkError !== "" || organisationNameLinkError !== "" || datePublishedError ? errorSummary : null}

                            <InsetText>
                                <Label size="s">
                                    Find the details of your organisation's data protection registration
                                    <div className="nhsuk-u-padding-bottom-4"></div>
                                    You can view the data protection public register on the <a href="https://ico.org.uk/ESDWebPages/Search" target="_blank">ICO website (opens in a new window).</a>
                                    <div className="nhsuk-u-padding-bottom-4"></div>
                                    If you can't find your organisation's details on the data protection public register, contact your Information Governance (IG) team for help
                                </Label>
                            </InsetText>
                            <Label size="s">We need proof of your current registration with the Information Commissioners Office (ICO)</Label>
                        </div>

                        <div className="nhsuk-grid-column-two-thirds">
                            <Input className="nhsuk-input nhsuk-input--width-10"
                                id="dpaRegistrationNumber"
                                name={dsaContent.registrationNumberText}
                                label="Registration Number"
                                value={controllerData?.dpaRegistrationNumber}
                                error={registrationNumberLinkError}
                                onChange={handleChange}
                            />
                            <Input className="nhsuk-input nhsuk-input--width-20"
                                id="dpaOrganisationName"
                                name={dsaContent.organisationNameText}
                                label="Organisation name"
                                value={controllerData?.dpaOrganisationName}
                                error={organisationNameLinkError}
                                hint="The name registered as data Controller on the data protection register"
                                onChange={handleChange}
                            />
                            <div className="nhsuk-u-padding-bottom-4"></div>

                            <DateInput id="dpaRegistrationExpiryDate" hint={`For example, ${calculateExampleDate(dsaContent.exampleYearForFuture)}`} label="Date registration expires" name="dsptRegistrationExpiryDate" error={datePublishedError}>
                                <DateInput.Day id="dpDay" name="dpDay" onChange={handleChange} value={controllerData.dpDay} error={diDayError} />
                                <DateInput.Month id="dpMonth" name="dpMonth" onChange={handleChange} value={controllerData.dpMonth} error={diMonthError} />
                                <DateInput.Year id="dpYear" name="dpYear" onChange={handleChange} value={controllerData.dpYear} error={diYearError} />
                            </DateInput>
                            <div className="nhsuk-u-padding-bottom-4"></div>
                        </div>

                        <div className="nhsuk-grid-column-two-thirds">
                            <div className="nhsuk-u-padding-bottom-6"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{controllerDetails.btnSaveAndContinue}</Button>
                        </div>
                    </div>
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <Details>
                                <Details.Summary>Find the details of your organisation's data protection registration</Details.Summary>
                                <Details.Text>You can view the data protection public register on the <a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/key-definitions/controllers-and-processors/" target="_blank">ICO website</a>.
                                </Details.Text>
                            </Details>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default DataProtectionRegistration;