import { Button, Label, InsetText, Input, ErrorSummary } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader'
import { IController, IApplication, IAutoComplete, IControllerList, IAutoCompleteData, IPostOrganization } from "../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { OrganisationLookup_URL, UpdateScreenDataController_URL } from "../../../../config/api-endpoints.config";
import { getCookie, submitSpinner, getTaskListType, fetchInterceptor, checkApplicationChangeType } from "../../../../Helper/Utility";
import { applicationChangeType, dsaContent, dsaControllerErrorMessage, emptyGuid, entity, fileTypeSize, routeName, taskListSectionStatus } from '../../../../config/page.config';

import { getControllerDetails, setControllerDetailsData } from '../../../../state/slice/DSAAboutController/DSAAboutController.slice';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { AppDispatch } from '../../../../state/app/store';
import { getControllerListDetails, setControllerListDetailsData } from "../../../../state/slice/ControllerList/ControllerList.slice";
import GoBack from "../../../../Components/Shared/GoBack";
import useDebounce from "../../../../Hooks/useDebounce";

/*
 Summary: On this page, the user will add the organization for the controller data. Here,
          the user can You have added the below Controller(s)organisation either using ODS code or Organisation name. Therefore, 
          if the user cannot find their organization in the list. Then you can add your organisation using 
          this link "enter details of your organisation" 
*/

const ControllerData: React.FC = () => {
    let navigate = useNavigate();
    const [searchOrgNameLinkError, setsearchOrgNameLinkError] = useState("");
    const [searchOrgNameError, setsearchOrgNameError] = useState("");
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;

    const [organizationList, setorganizationList] = useState<IPostOrganization[]>([]);

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData, setDsaApplicationData] = useState(initialApplicationState);
    const [autocompleteErrorMsg, setautocompleteErrorMsg] = useState("nhsuk-autocomplete-items");
    const [filteredSuggestions, setFilteredSuggestions] = useState<IAutoComplete[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const [showspinner, setshowspinner] = useState("");
    const [iconcontainerSpinner, seticoncontainerSpinner] = useState("nhsuk-icon_container");
    let activeSuggestion: number = -1;

    let suggestionsListComponent: JSX.Element = <></>;

    const initialState: IController = useAppSelector(getControllerDetails);
    const [controllerData, setControllerData] = useState(initialState);
    const dispatch: AppDispatch = useAppDispatch();
    const [selectedId, setSelectedId] = useState(controllerData.controllerdataOrgGuidId ? controllerData.controllerdataOrgGuidId : "");

    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const saveDataInStore = (stateData?: IController) => {
        dispatch(setControllerDetailsData({ ...controllerData, ...stateData }));
    }
    const controllerListDetailsData: IControllerList = useAppSelector(getControllerListDetails);
    const initialControllerListState: IControllerList = controllerListDetailsData || {};
    const [controllerListData] = useState(initialControllerListState);
    const controllerIndex = controllerListData.activeController || 0;

    const [orgCount] = useState((controllerData?.isNewController === true ? controllerListData?.controllerList?.length : 0) || 0);
    let isDirtyController = controllerData.isDirtyController || false;
    const saveControllerListDataInStore = (stateData?: IControllerList) => {
        dispatch(setControllerListDetailsData({ ...controllerListData, ...stateData }));
    }
    let suggestions = organizationList;

    const [selectedText, setSelectedText] = useState("");
    const [userquery, setuserquery] = useState("");
    const [showControllerOrganisations, setShowControllerOrganisations] = useState(false);
    const [showSearch, setShowSearch] = useState(true);
    const [showAddControllerLink, setShowAddControllerLink] = useState(true);

    const handleAddAnotherControllerLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowControllerOrganisations(false);
        setShowSearch(true);
        setShowAddControllerLink(false);
    }

    const getOrganisationData = async () => {
        try {
            setShowSuggestions(false);
            setshowspinner("nhsuk-loader-three");
            const parameters: string = JSON.stringify({
                "UserAuthTokenId": authenticationTokenCookie,
                "SearchString": controllerData.controllerdataOrgDesc
            });
            const requestOptions: Object = {
                method: 'POST',
                headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
                body: parameters
            };
            await fetchInterceptor(OrganisationLookup_URL, requestOptions)
                .then(response => {
                    setshowspinner("");
                    return response.json();
                })
                .then(data => {
                    setShowSuggestions(false);
                    setorganizationList(data);
                    suggestions = data;
                    bindSuggestionList();
                })
                .catch(() => navigate(routeName.serviceError));
        }
        catch (e: any) {
            console.log("error response", e.response);
        }
    };

    const resetError = (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>) => {
        const selectedOrgId = e.currentTarget.id;
        setautocompleteErrorMsg("nhsuk-autocomplete-items");
        seticoncontainerSpinner("nhsuk-icon_container");
        const targetElement = e.target as HTMLInputElement;
        if (targetElement.value !== undefined && targetElement.value !== null) {
            const valueLength = targetElement.value.length;
            if (valueLength >= 1) {
                setsearchOrgNameError("");
                setsearchOrgNameLinkError("");
            }
        }
        if (selectedOrgId !== undefined && selectedOrgId !== null && selectedOrgId !== '') {
            setsearchOrgNameError("");
            setsearchOrgNameLinkError("");
        }
    }

    const ValidateFields = () => {
        let isValid: boolean = true;
        if (!controllerData.controllerdataOrgDesc && !showControllerOrganisations) {
            setsearchOrgNameLinkError(`${dsaControllerErrorMessage.organisationSearchMandatory_ErrorMessage}`);
            setsearchOrgNameError(`${dsaControllerErrorMessage.organisationSearchMandatory_ErrorMessage}`);
            isValid = false;
        }
        if (controllerData.controllerdataOrgDesc && selectedId === '' && controllerData.controllerdataOrgGuidId === '' && ((showControllerOrganisations && orgCount < Number(minCount)) || (!showControllerOrganisations && orgCount < Number(minCount)))) {
            setsearchOrgNameLinkError(`${dsaControllerErrorMessage.organisationNotSelected_ErrorMessage}`);
            setsearchOrgNameError(`${dsaControllerErrorMessage.organisationNotSelected_ErrorMessage}`);
            isValid = false;
        }          
        if (controllerData.controllerdataOrgDesc && selectedId === '' && controllerData.controllerdataOrgGuidId === '' && ((showControllerOrganisations && orgCount >= Number(minCount)) || (!showControllerOrganisations && orgCount >= Number(minCount)))) {
            setsearchOrgNameLinkError(`${dsaControllerErrorMessage.secondOrganisationNotSelected_ErrorMessage}`);
            setsearchOrgNameError(`${dsaControllerErrorMessage.secondOrganisationNotSelected_ErrorMessage}`);
            isValid = false;
        }
        if (!isValid) {
            setautocompleteErrorMsg("nhsuk-autocomplete-items nhsuk-autocomplete-error");
            seticoncontainerSpinner("nhsuk-icon-height");
        }
        return isValid;
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (ValidateFields()) {
            submitSpinner(e, submitControllerSelectOrg);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const submitControllerSelectOrg = () => {
        if (selectedId !== '' && selectedId !== undefined && selectedId !== null) {
            if (controllerListData.controllerDetailsPage && !controllerData.isNewController) {
                if (isDirtyController) {
                    const controllerId = controllerListData.controllerList ? controllerListData.controllerList[controllerIndex].controllerId : null;
                    const parameters: string = JSON.stringify({
                        "UserAuthTokenId": authenticationTokenCookie,
                        "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
                        "IsOrganisationDirty": true,
                        "OrganisationId": selectedId,
                        "DataControllerId": controllerId,
                        "SectionStatus": dsaApplicationData.aboutControllerSectionStatus
                    });
                    const requestOptions: Object = {
                        method: 'POST',
                        headers: { 'Content-Type': fileTypeSize.fileTypeJson },
                        body: parameters
                    };
                    fetchInterceptor(UpdateScreenDataController_URL, requestOptions)
                        .then(response => {
                            return response.json()
                        })
                        .then(() => {
                            let sectionStatus: IApplication = {
                                ...dsaApplicationData, aboutControllerSectionStatus: taskListSectionStatus.inProgress
                            }
                            setDsaApplicationData(sectionStatus);
                            saveApplicationDataInStore(sectionStatus);
                            navigate(routeName.controllerDetails);
                        })
                        .catch(() => navigate(routeName.serviceError));
                }
                else { navigate(routeName.controllerDetails); }
            }
            else {
                let updatedState: IController = {};
                if (selectedId !== '' && selectedId !== undefined && selectedId !== null) {
                    updatedState = {
                        ...controllerData,
                        controllerdataOrgGuidId: selectedId,
                        controllerdataOrgDesc: selectedText                     
                    }
                    setControllerData(updatedState);
                    saveDataInStore(updatedState);
                    navigate(routeName.processingData);
                }
            }
        }
        else if (controllerListData.controllerDetailsPage) {
            if (controllerData.controllerdataOrgDesc !== "" && controllerData.controllerdataOrgDesc !== null && controllerData.controllerdataOrgDesc !== undefined) {
                let updatedState: IControllerList = {
                    ...controllerListData, controllerDetailsPage: dsaContent.checkYourAnswersPageText
                }
                saveControllerListDataInStore(updatedState);
            }
            navigate(routeName.payForThisService);
        }
        else {
            navigate(routeName.processingData);
        }
    }
    /* AutoComplete code started */
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowSuggestions(false);
        resetError(e);
        setSelectedId('');
        setSelectedText('');
        activeSuggestion = -1;
        setuserquery(e.currentTarget.value);
        let updatedState: IController = {};
        updatedState = { ...controllerData, controllerdataOrgDesc: e.currentTarget.value, controllerdataOrgGuidId: '', isDirtyController: true }
        setControllerData(updatedState);
        saveDataInStore(updatedState);
        setshowspinner("");
        setFilteredSuggestions([]);
        if (e.currentTarget.value === "") {
            setShowControllerOrganisations(false);
        }
    };

    const bindSuggestionList = () => {
        if ((controllerData.controllerdataOrgDesc ? controllerData.controllerdataOrgDesc.length : "") >= 3) {
            if (suggestions !== null && suggestions !== undefined) {
                const filteredSuggestions = suggestions?.map((suggestion: IAutoCompleteData) => {
                    const getListData: IAutoComplete = { id: suggestion?.Id!, name: suggestion.Name! };
                    return getListData;
                });
                setFilteredSuggestions(filteredSuggestions);
                setShowSuggestions(true);
                setShowControllerOrganisations(true);
                setshowspinner("");
            }
        }
        else {
            setFilteredSuggestions([]);
            setShowSuggestions(false);
            setshowspinner("");
            setShowControllerOrganisations(false);
        }
    }

    const handleOnselect = (e: React.MouseEvent<HTMLElement>) => {
        if (suggestions !== undefined && suggestions !== null) {
            const selectedOrgDetails = suggestions?.filter((post: IAutoCompleteData) => {
                if (post.Id === e.currentTarget.id) {
                    return post?.Name
                }
            });
            setFilteredSuggestions([]);
            setShowSuggestions(false);
            let updatedState: IController = {};
            updatedState = { ...controllerData, controllerdataOrgDesc: selectedOrgDetails[0]?.Name, controllerdataOrgGuidId: selectedOrgDetails[0]?.Id }
            setControllerData(updatedState);
            saveDataInStore(updatedState);
            setSelectedId(selectedOrgDetails ? selectedOrgDetails[0]?.Id : "");
            setSelectedText(selectedOrgDetails ? selectedOrgDetails[0]?.Name : "");
            resetError(e);
            setshowspinner("");
        }
    };

    function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (filteredSuggestions !== undefined && filteredSuggestions !== null && filteredSuggestions.length) {
            if (e.keyCode === 13) {
                setSelectedId('');
                setShowSuggestions(false);
                let updatedState: IController = {};
                updatedState = { ...controllerData, controllerdataOrgDesc: filteredSuggestions[activeSuggestion].name, controllerdataOrgGuidId: filteredSuggestions[activeSuggestion].id }
                setControllerData(updatedState);
                saveDataInStore(updatedState);
                setSelectedId(filteredSuggestions[activeSuggestion].id);
                setSelectedText(filteredSuggestions[activeSuggestion].name);
                resetError(e);
            }
            // User pressed the up arrow
            else if (e.keyCode === 38) {
                activeSuggestion--;
                addActiveonElement();
            }
            // User pressed the down arrow
            else if (e.keyCode === 40) {
                if (filteredSuggestions !== undefined || filteredSuggestions !== null) {
                    activeSuggestion++;
                    addActiveonElement();
                }
            }
        }
    };

    const removeActiveElement = (elements: HTMLCollection) => {
        for (var j = 0; j < elements.length; j++) {
            elements[j].classList.remove("nhsuk-autocomplete-active");
        }
    }

    function addActiveonElement() {
        let autopupulatenameList: HTMLElement = document.getElementById("myInputautocomplete-list")!;
        if (autopupulatenameList !== undefined && autopupulatenameList !== null) {
            let childlist = autopupulatenameList!.getElementsByTagName("div");
            if (childlist !== undefined && childlist !== null && childlist.length > 0) {
                removeActiveElement(childlist);
                if (activeSuggestion >= childlist.length) { activeSuggestion = 0 }
                if (activeSuggestion < 0) { activeSuggestion = childlist.length - 1 };
                childlist[activeSuggestion].classList.add("nhsuk-autocomplete-active");
            }
        }
    }

    const resetSuggestion = () => {
        let updatedState: IController = {};
        updatedState = { ...controllerData, controllerdataOrgDesc: "" }
        setControllerData(updatedState);
        saveDataInStore(updatedState);
    }

    const resetData = () => {
        if (String(controllerData.controllerdataOrgGuidId) === String(emptyGuid) || controllerData.controllerdataOrgGuidId === "") { resetSuggestion(); }
    }

    const resetField = () => {
        if (String(controllerData.controllerdataOrgGuidId) === String(emptyGuid) && controllerListData.controllerDetailsPage) { resetSuggestion(); }
    }
    const maxCount = entity.validSave.find(item => {
        return item.controller?.maxCount
    })?.controller?.maxCount

    const minCount = entity.validSave.find(item => {
        return item.controller?.minCount
    })?.controller?.minCount

    if (showSuggestions && controllerData?.controllerdataOrgDesc) {
        if (filteredSuggestions !== undefined && filteredSuggestions !== null) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <div id="myInputautocomplete-list" className={autocompleteErrorMsg}>

                        {filteredSuggestions.map((suggestion: IAutoComplete, index) => {
                            return (
                                <div onClick={handleOnselect} id={suggestion.id} key={index} >
                                    {suggestion.name}
                                </div>
                            );
                        })}
                    </div>
                );
            }
            else if (filteredSuggestions.length === 0) {
                setshowspinner("");
            }
        }
    }
    /* AutoComplete Code ended */

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaControllerErrorMessage.summaryTitle_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        < ErrorSummary.Item href="#organisationSearch">
                            {searchOrgNameLinkError}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    );


    const searchQuery = useDebounce(userquery);
    useEffect(() => {
        resetField();
        if (userquery.length >= 3 && searchQuery) {
            getOrganisationData();
        }
    }, [searchQuery])

    useEffect(() => {
        resetData();
        if (orgCount >= Number(maxCount)) { setShowSearch(false) };
        if (orgCount > 0) { setShowControllerOrganisations(true) };
        if (controllerListData.controllerDetailsPage === dsaContent.checkYourAnswersPageText) { setShowSearch(true); setShowAddControllerLink(false); setShowControllerOrganisations(false); };
    }, [])

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            {
                                <GoBack condition={(controllerListData.controllerList?.length === 0 || (controllerListData.controllerList && controllerListData.controllerList.length ===1 && controllerListData.controllerList[0].controllerId === "")) ? getTaskListType(dsaApplicationData.returningJourneyType!): dsaContent.controllerDetails} />
                            }
                            <span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                About the Controller</span>
                            {orgCount < Number(minCount) &&
                                <h1>Which organisation is a Controller for the data?</h1>
                            }                           
                            {orgCount >= Number(maxCount) ? <h1>You have added the below Controller(s)</h1> : null}

                            {searchOrgNameError !== "" ? errorSummary : null}

                            {orgCount >= Number(maxCount) ?
                                checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? "" :
                                <InsetText>
                                    <p>
                                        If you do not need to add another Controller, select 'Continue'.
                                    </p>
                                </InsetText> : null
                            }
                            {orgCount === minCount &&
                                <p className="nhsuk-body-l">The below Controller(s) have been added</p>
                            }
                            {orgCount >= Number(minCount) &&
                                <>
                                    <table className="nhsuk-table ctrl-border">
                                        <tbody className="nhsuk-table__body">
                                            {
                                                controllerListData?.controllerList?.slice(0).reverse()?.map((controllerOrg: IController, index: number) =>
                                                    <tr role="row" className="nhsuk-table__row" key={index}>
                                                        <td className="nhsuk-table__cell">{index + 1}. {controllerOrg.controllerdataOrgDesc}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </>
                            }

                            {orgCount < Number(minCount) &&
                                <div className="nhsuk-hint">
                                    <p>The Controller is the body that takes legal responsibility for the data requested from NHS England.</p>
                                    <p>They can have a say in what the purpose of processing the data is, or how the processing is carried out.
                                    </p> </div>
                            }
                            {showSearch && <>
                                <div className="nhsuk-input--width-30">
                                    <div className="nhsuk-input_container">
                                        <Input type="text" autoComplete="off" label={dsaContent.lbl_orgSearch} className="nhsuk-input" name="organisationSearch" id="organisationSearch" onChange={onChange} onKeyDown={handleOnKeyDown} value={controllerData?.controllerdataOrgDesc} error={searchOrgNameError} />                                                <div className={iconcontainerSpinner} >
                                            <div className={showspinner}>
                                            </div>
                                        </div>
                                    </div>
                                    {suggestionsListComponent}
                                </div>
                            </>
                            }
                            {showSearch &&
                                <InsetText>
                                    <p>
                                        I can't find the organisation
                                    </p>
                                    <p>
                                        <Label>If the organisation is not listed, <span><Link to={routeName.addOrganisationDetails}>enter details of your organisation.</Link></span> Once the application has been submitted, the organisation's details will be verified and stored in DARS for future use.</Label>
                                    </p>
                                </InsetText>
                            }

                            {orgCount >= Number(maxCount) && showAddControllerLink ?
                                checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal),String(applicationChangeType.Extension)]) ? "" :
                                <><Label><a href="#" onClick={handleAddAnotherControllerLink}>{dsaContent.addAnotheControllerLink}</a></Label>
                                        <div className="nhsuk-u-padding-bottom-9"></div></> :""}
                            {!showSearch ?
                                <>
                                    <Button name="continue" id="nhsuk-buttonorganis-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button></>
                                : <Button name="saveAndContinue" id="nhsuk-buttonorganis-spinner" onClick={handleClick}>{dsaContent.btnSaveAndContinue}</Button>
                            }

                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default ControllerData;
