import React, { useState } from "react";
import { Button, Label, ErrorSummary, InsetText, Checkboxes, Table, Input, Hint } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IParticipant, IApplication, IParticipantLegalBasis, EvidenceTypeAttributes } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getParticipantDetails, setParticipantDetailsData } from "../../../../state/slice/Participant/Participant.slice";
import { participantErrorMessage, dsaContent, dsaErrorMessage, legalBasisValue, routeName, fileTypeSize, controllerSection, evidenceTypeOptions, participantTypeOptions, taskListSectionStatus } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { getCookie, submitSpinner, fetchInterceptor } from "../../../../Helper/Utility";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { DeleteFileApprovalEvidenceIsLegalBasisChanged_URL, SubmitScreenCohortLegalBases_URL } from "../../../../config/api-endpoints.config";

const LegalBasis: React.FC = () => {
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [participantsErrorMessage, setParticipantErrorMessage] = useState("");
    const participantDetailsData: IParticipant = useAppSelector(getParticipantDetails);
    const initialState: IParticipant = participantDetailsData || {};
    const [participantData, setParticipantData] = useState(initialState);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const isFromAnswerPage: string = participantData?.participantcheckYourAnswerPage!;
    const [isUncheckConsentid] = useState(participantData?.dataSetLegalBasisList?.filter(x => x.value === legalBasisValue.consentValue)[0].checked);
    const [isUncheckNhsId] = useState(participantData?.dataSetLegalBasisList?.filter(x => x.value === legalBasisValue.nhsHealthValue)[0].checked);
    // const [isLBchange, setisLBChange] = useState(false);
    /* To dispatch the setParticipantDetailsData Redux action */
    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantData, ...stateData }));
    }

    const checkParticipantLBType = (value: string) => {
        return participantData.dataSetLegalBasisList!.find((element) => {
            return (value === element.value && element.checked !== true)!;
        });
    }

    const filterParticipantLB = () => {
        let multiAEArray: number[] = [];
        Object.values(evidenceTypeOptions).forEach((option: EvidenceTypeAttributes, index) => {
            if (option?.legalBasis?.filter((value) => value !== undefined && checkParticipantLBType(value)).length! > 0) {
                multiAEArray.push(option.value);
            }
        })
        return multiAEArray;
    }

    console.log('filterParticipantLB', filterParticipantLB());

    const handleChange = (e: React.MouseEvent<HTMLElement>) => {
        const target = e.target as HTMLInputElement;
        let updatedState: IParticipant = {};
        let participantLegal: IParticipantLegalBasis = {};
        let legalBasisList: IParticipantLegalBasis[] = [];
        if (target.value !== null && target.value !== undefined) {
            participantData?.dataSetLegalBasisList?.forEach((dsaApplication: IParticipantLegalBasis) => {
                if (target.value === dsaApplication.value) {
                    participantLegal = {
                        value: dsaApplication.value,
                        name: dsaApplication.name,
                        checked: target.checked,
                        text: dsaApplication.text,
                        crmId: dsaApplication.crmId,
                        processing: dsaApplication.processing,
                        dissemination: dsaApplication.dissemination,
                        otherLegalBasis: dsaApplication.otherLegalBasis,
                        displayProcessing: dsaApplication.displayProcessing,
                        displayDissemination: dsaApplication.displayDissemination,
                        productLegalBasis: dsaApplication.productLegalBasis,
                        otherComments: dsaApplication.otherComments,
                        orderBy: dsaApplication.orderBy,
                        availableOnline: dsaApplication.availableOnline,
                        currentlyCheckedInCRM: dsaApplication.currentlyCheckedInCRM,
                        notes: dsaApplication.notes,
                    }
                    legalBasisList.push(participantLegal);
                } else {
                    legalBasisList.push(dsaApplication);
                }
            })
            let otherLegalBasis = legalBasisList?.filter(x => x.value === legalBasisValue.otherLegalBasisValue)[0];
            let dataSetSelectedLegalBasisOtherText = participantData.dataSetSelectedLegalBasisOtherText;
            if (!otherLegalBasis?.checked) {
                dataSetSelectedLegalBasisOtherText = "";
            }
            updatedState = {
                ...participantData,
                dataSetLegalBasisList: legalBasisList,
                dataSetSelectedLegalBasisOtherText: dataSetSelectedLegalBasisOtherText
            }
            resetError(e);
            setParticipantData(updatedState);
            saveDataInStore(updatedState);
        }
    }
    const HandleOtherTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IParticipant = {};
        let dataSetSelectedLegalBasisOtherText = participantData.dataSetSelectedLegalBasisOtherText;
        if (e.target.name === dsaContent.dataSetSelectedLegalBasisOtherText) {
            dataSetSelectedLegalBasisOtherText = e.target.value
        }
        updatedState = {
            ...participantData,
            dataSetSelectedLegalBasisOtherText: dataSetSelectedLegalBasisOtherText
        }
        resetError(e);
        setParticipantData(updatedState);
        saveDataInStore(updatedState);
    };

    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>) => {
        const target = e.target as HTMLInputElement;
        if (target.value !== "") {
            setSummaryLinkErrorMessage("");
            setParticipantErrorMessage("");
        }
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, submitConsent);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const submitConsent = async () => {
        const isDeleted = await checkExsistingFileofLB();
        if (isDeleted) {
            submitScreenCohortLegalBases();
        }
    }

    const submitScreenCohortLegalBases = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "DataSetLegalBasisList": participantData.dataSetLegalBasisList,
            "DataSetSelectedLegalBasisOtherText": participantData.dataSetSelectedLegalBasisOtherText,
            "SectionStatus": dsaApplicationData.aboutParticipantSectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenCohortLegalBases_URL, requestOptions)
            .then(() => {
                let oldParticipantdata: IParticipant = {}
                oldParticipantdata = participantData;
                let consentLegalBasis = participantData?.dataSetLegalBasisList?.filter(x => x.value === legalBasisValue.consentValue)[0];
                let nhsLegalBasis = participantData?.dataSetLegalBasisList?.filter(x => x.value === legalBasisValue.nhsHealthValue)[0];
                let otherLegalBasis = participantData?.dataSetLegalBasisList?.filter(x => x.value === legalBasisValue.otherLegalBasisValue)[0];
                let updatedState: IParticipant = {};
                updatedState = {
                    ...participantData,
                    isFromParticipant: true,
                    recentlyAddedConsentId: oldParticipantdata.recentlyAddedConsentId,
                    fileCommentConsent: oldParticipantdata.fileCommentConsent,
                    fileUploadConsent: oldParticipantdata.fileUploadConsent,
                    recentlyAddedNHSHealthId: oldParticipantdata.recentlyAddedNHSHealthId,
                    fileUploadNHS: oldParticipantdata.fileUploadNHS,
                    fileCommentNHS: oldParticipantdata.fileCommentNHS,
                    fileUploadActive: 0
                }
                setParticipantData(updatedState);
                saveDataInStore(updatedState);
                let sectionStatus: IApplication = {
                    ...dsaApplicationData, aboutParticipantSectionStatus: taskListSectionStatus.inProgress
                }
                setDsaApplicationData(sectionStatus);
                saveApplicationDataInStore(sectionStatus);
               
                if (consentLegalBasis?.value === legalBasisValue.consentValue && consentLegalBasis.checked) {  // && !isUncheckConsentid
                    // navigate(routeName.uploadConsent);
                    navigate(routeName.approvalEvidenceData);
                } else if (nhsLegalBasis?.value === legalBasisValue.nhsHealthValue && nhsLegalBasis.checked) {  // && !isUncheckNhsId
                    // navigate(routeName.uploadNhsHealth);
                    navigate(routeName.approvalEvidenceData);

                } else if (consentLegalBasis?.checked || nhsLegalBasis?.checked) {
                    navigate(routeName.participantSummary);
                }
                else if (otherLegalBasis?.checked && isFromAnswerPage !== dsaContent.checkYourAnswersPageText) {
                    navigate(routeName.linkingParticipant);
                }
                else
                    navigate(routeName.participantSummary);
                // }
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const checkExsistingFileofLB = async () => {
        const recordToDelete = filterParticipantLB();
        if (recordToDelete.length) {
            const parameters: string = JSON.stringify({
                "UserAuthTokenId": authenticationTokenCookie,
                "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
                "SectionId": controllerSection.Participants,
                "ApprovalEvidencetype": filterParticipantLB()               
            });
            const requestOptions: Object = {
                method: 'POST',
                headers: { 'Content-Type': fileTypeSize.fileTypeJson },
                body: parameters
            };
            const response = await fetchInterceptor(DeleteFileApprovalEvidenceIsLegalBasisChanged_URL, requestOptions).then(response => {
                return response.ok;
            });
            return response;
        } else {
            return true;
        }
    }

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#selectLegalBasis">{summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    /* To Validate the each field's data */
    const validateFields = () => {
        let isValid: boolean = false;
        let isValidOther: boolean = true;
        participantData?.dataSetLegalBasisList?.forEach((dsaApplication: IParticipantLegalBasis) => {
            if (dsaApplication.checked)
                isValid = true;
        })
        let otherlegalbasis = participantData.dataSetLegalBasisList?.filter(x => x.text?.toLowerCase() === dsaContent.otherText)[0];
        if (otherlegalbasis?.checked && (participantData.dataSetSelectedLegalBasisOtherText === '' || participantData.dataSetSelectedLegalBasisOtherText === undefined || participantData.dataSetSelectedLegalBasisOtherText === null)) {
            isValidOther = false;
        }
        else {
            isValidOther = true;
        }
        if (!isValid) {
            setSummaryLinkErrorMessage(`${participantErrorMessage.legalBasisMandatory_ErrorMessage}`);
            setParticipantErrorMessage(`${participantErrorMessage.legalBasisMandatory_ErrorMessage}`);
        }
        else if (!isValidOther) {
            setSummaryLinkErrorMessage(`${participantErrorMessage.legalBasisOtherMandatory_ErrorMessage}`);
            setParticipantErrorMessage(`${participantErrorMessage.legalBasisOtherMandatory_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack condition={participantData.participantcheckYourAnswerPage === dsaContent.checkYourAnswersPageText ? routeName.participantSummary : participantData.participantTypes === participantTypeOptions.existing ? dsaContent.exisitngTypesPage : dsaContent.participantTypesPage
                            }></GoBack>
                            <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                About the participants</span>
                                Duty of confidentiality
                            </h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <InsetText>
                                <p>Under UK common law you have a duty of confidentiality to the participants. This duty of confidentiality can be met by ensuring that the participants have consented to and therefore reasonably expect you to share their data with NHS England.</p>
                                <p>It can be set aside under certain legal gateways.</p>
                                <p>Select consent if some or all of your participants have consented to their data being shared with NHS England.</p>
                                <p> If only some or none have consented, select the legal gateway that sets aside your duty of confidentiality.</p>
                                
                            </InsetText>
                            <Checkboxes name="selectLegalBasis" id="selectLegalBasis" error={summaryLinkErrorMessage}>
                                <p className="nhsuk-u-margin-bottom-1">Choose your legal basis or bases</p>
                                <Table responsive>
                                    <Table.Head>
                                        <Table.Row>
                                            <Table.Cell>Participants</Table.Cell>
                                            <Table.Cell>Legal Basis</Table.Cell>
                                        </Table.Row>
                                    </Table.Head>
                                    <Table.Body>
                                        {
                                            participantData.dataSetLegalBasisList != null ? (
                                                participantData.dataSetLegalBasisList.map((legalbasis, index) => (
                                                    <Table.Row key={index} >
                                                        <Table.Cell>
                                                            <Checkboxes.Box value={legalbasis?.value} checked={legalbasis?.checked} onClick={handleChange}>
                                                                <Label></Label>
                                                            </Checkboxes.Box>
                                                        </Table.Cell>
                                                        <Table.Cell  >{legalbasis.text}
                                                            {
                                                                legalbasis.name?.toLowerCase() == 'other' ? (
                                                                    <>
                                                                        <Hint>Provide information in the text box </Hint>
                                                                        <div className="nhsuk-u-padding-bottom-1"></div>
                                                                        <Input type="text" autoComplete="off" className="nhsuk-input nhsuk-input--width-20" name={dsaContent.dataSetSelectedLegalBasisOtherText} onChange={HandleOtherTextChange} id={dsaContent.dataSetSelectedLegalBasisOtherText} value={participantData?.dataSetSelectedLegalBasisOtherText} />
                                                                    </>
                                                                ) : (null)

                                                            }
                                                        </Table.Cell>
                                                    </Table.Row>
                                                ))
                                            ) : (null)
                                        }
                                    </Table.Body>
                                </Table>
                            </Checkboxes>
                            <div className="nhsuk-u-padding-bottom-4"></div>
                            <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>

                    </div>
                </main>
            </div>
        </>)
}
export default LegalBasis;


