import { InsetText, Table, Button } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { submitSpinner, getCookie, fetchInterceptor, submitDSASectionStatus, getKeyByValue, getTaskListType } from '../../Helper/Utility';
import { amendRenewExtendValue, controllerSection, dsaContent, evidenceTypeOptions, fileTypeSize, initialStateOfFileUpload, routeName, sectionOptions, taskListSectionStatus } from "../../config/page.config";
import { useAppDispatch, useAppSelector } from "../../state/app/hooks";
import { AppDispatch } from '../../state/app/store';
import { IParticipant, IEvidence, IEvidenceList, IDataSet, IApplication, IFileUpload, IApiResEvidence, IApiResEvidenceList, IEvidenceTypeOptions, IDeleteDocument } from "../../state/models/app.interface";
import { getParticipantDetails, setParticipantDetailsData } from "../../state/slice/Participant/Participant.slice";
import ControllerHeader from '../../Components/Shared/ControllerHeader'
import { getDSADetails } from "../../state/slice/DSA/DSA.slice";
import { GetCohortData_URL, GetEvidenceList_URL } from "../../config/api-endpoints.config";
import Loader from "../../Components/Loader/Loader";
import { getEvidenceList, setEvidenceListData, initialEvidence } from "../../state/slice/EvidenceList/EvidenceList.slice";
import { getDataSetDetails } from "../../state/slice/DataSet/DataSet.slice";
import GoBack from "./GoBack";
import { getDeleteDocumentData, setDeleteDocumentData } from "../../state/slice/shared/DeleteDocument/DeleteDocument.slice";
import { getEvidenceDetails } from "../../state/slice/Evidence/Evidence.slice";

const UploadedFiles: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [participantDetails, setParticipantDetails] = useState(useAppSelector(getParticipantDetails) || {})
    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};
    const [loading, setLoading] = useState(true);
    const [evidenceListDetails, setEevidenceListDetails] = useState(useAppSelector(getEvidenceList) || {});
    const [evidenceListResult, setEvidenceListResult] = useState([initialEvidence]);
    const initailEvidenceList: IEvidence = initialEvidence || {};
    const [evidenceListDetailsData] = useState([initailEvidenceList]);
    const [isFromParticipant] = useState(participantDetails.isFromParticipant);
    const [dataSetData, setDataSetData] = useState<IDataSet>(useAppSelector(getDataSetDetails) || {});
    const [isFromDataSetPage] = useState(dataSetData?.legalBasesIdentifiableData ? true : false || false);
    const evidenceList: IEvidenceList = useAppSelector(getEvidenceList);
    const evidenceinitialState: IEvidenceList = evidenceList || {};
    const [evidenceListData] = useState(evidenceinitialState)
    const [isFromEvidence] = useState(evidenceListData.isFromEvidence);
    const saveDeleteDocumentInStore = (stateData: IDeleteDocument) => {
        dispatch(setDeleteDocumentData(stateData));
    };
    //const { evidenceListData.evidenceList }: IEvidenceList = useAppSelector(getEvidenceList);
    const { fileIndex, isDeleted } = useAppSelector(getDeleteDocumentData);
    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantDetails, ...stateData }));
    }
    const saveEvidenceDataInStore = (stateData?: IEvidenceList) => {
        dispatch(setEvidenceListData({ ...evidenceListDetailsData, ...stateData }));
    }
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        submitSpinner(e, handleClickNavigate);
    }
    const handleClickNavigate = () => {
        if (isFromParticipant)
            navigate(routeName.participantSummary);
        else if (isFromDataSetPage) {
            navigate(routeName.addedAllDataSet);
        }
        else {
            let UpdateSectionStatus = evidenceListResult.length == 0 ? taskListSectionStatus.inProgress : taskListSectionStatus.completed
            submitDSASectionStatus(dsaApplicationData?.id!, controllerSection.ListOfUploadedDocuments, UpdateSectionStatus)
                .then((data: Response) => {
                    if (data) {
                        let updateState: IEvidenceList = {}
                        updateState = {
                            ...evidenceListDetails,
                            aboutEvidenceSectionStatus: taskListSectionStatus.completed
                        }
                        setEevidenceListDetails(updateState);
                        saveEvidenceDataInStore(updateState);
                        navigate(routeName.dsaTaskList);
                    }
                });
        }
    }

    const getEvidenceListData = () => {
        if (participantDetails.isFromParticipant)
            getCohortDetailsData()
        else
            getEvidenceListDetails()
    }

    const getEvidenceListDetails = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetEvidenceList_URL, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                setEvidenceDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setEvidenceDetails = (data: IApiResEvidence) => {
        let participantEvidence: IEvidence = {};
        let evidenceList: IEvidence[] = [];
        let updateState: IEvidenceList = {}
        if (data.EvidenceList!.length > 0) {
            data.EvidenceList!.map((evidence: IApiResEvidenceList) => {
                participantEvidence = {
                    id: evidence.Id,
                    comments: evidence.Comments,
                    referenceNumber: evidence.ReferenceNumber,
                    approvalsEvidenceType: getNameForOption(evidence?.ApprovalsEvidenceType!),
                    sectionType: String(getKeyByValue(sectionOptions, String(evidence.SectionId))),
                    sectionId: evidence.SectionId

                }
                evidenceList.push(participantEvidence)
            })
        } else {
            updateState = {
                ...evidenceListDetails,
                fileUpload: [initialStateOfFileUpload],
                fileComment: ''
            }
            setEvidenceListResult(evidenceList)
            setEevidenceListDetails(updateState);
            saveEvidenceDataInStore(updateState)
            if (evidenceListDetails.isFromEvidence) {
                navigate(routeName.evidenceType);
            } else {
                navigate(routeName.legalBasesIdentifiableData);
            }
        }
        updateState = {
            ...evidenceListDetails,
            evidenceList: evidenceList
                    }
        setEvidenceListResult(evidenceList.sort(function (a: IEvidence, b: IEvidence) { return a.sectionId! - b.sectionId! }))
        setEevidenceListDetails(updateState);
        saveEvidenceDataInStore(updateState)
        setLoading(false)
    }
    const getCohortDetailsData = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetCohortData_URL, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                setDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const getNameForOption = (evidenceTypeValue: String) => {
        const filteredKey = Object.keys(evidenceTypeOptions).find((key) => Number(evidenceTypeValue) === evidenceTypeOptions[key as keyof IEvidenceTypeOptions].value);
        return filteredKey ? evidenceTypeOptions[filteredKey as keyof IEvidenceTypeOptions].name : "";
    }

    const setDetails = (data: IApiResEvidence) => {
        let participant: IParticipant = {};
        let participantEvidence: IEvidence = {};
        let evidenceList: IEvidence[]=[];
        let otherTextValue = '';
        if (data.EvidenceList != null && data.EvidenceList.length > 0) {
            data.EvidenceList.map((evidence: IApiResEvidenceList) => {
                participantEvidence = {
                    id: evidence.Id,
                    comments: evidence.Comments,
                    referenceNumber: evidence.ReferenceNumber,
                    approvalsEvidenceType: getNameForOption(evidenceListDetails?.selectedType!),
                }
                evidenceList.push(participantEvidence)
            })
        }
        else if (isFromParticipant) {
            resetParticipantStore();
            setLoading(false)
            navigate(routeName.legalBasis);
        }
        if (data !== undefined && data !== null) {
            otherTextValue = data.DataSetSelectedLegalBasisOtherText!;
        };
        participant = {
            ...participantDetails,
            evidenceList: evidenceList,
            dataSetSelectedLegalBasisOtherText: otherTextValue
        }
        setEvidenceListResult(evidenceList)
        setParticipantDetails(participant)
        saveDataInStore(participant)
        setLoading(false)
    }
    const handleDelete = (evidenceId: string = "", sectionType: number, e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (participantDetails.isFromParticipant) {
            let updatedStateParticipant: IParticipant = {};
            updatedStateParticipant = {
                ...participantDetails,
                deleteEvidenceId: evidenceId,
                sectionType: sectionType
            }
            setParticipantDetails(updatedStateParticipant)
            saveDataInStore(updatedStateParticipant)
        } else {
            let updatedState: IEvidenceList = {};
            updatedState = {
                ...evidenceListDetails,
                deleteEvidenceId: evidenceId,
                sectionType: sectionType
            }
            saveEvidenceDataInStore(updatedState);
        }
        navigate(routeName.deleteDocument);
    }

    const resetParticipantStore = () => {
        let updatedState: IParticipant = {};
        updatedState = {
            ...participantDetails,
            fileCommentConsent: "",
            fileCommentNHS: "",
            fileUploadConsent: [initialStateOfFileUpload],
            fileUploadNHS: [initialStateOfFileUpload],           
            recentlyAddedConsentId: '',
            recentlyAddedNHSHealthId: '',
        }
        setParticipantDetails(updatedState)
        saveDataInStore(updatedState)
    }

    const handleAddAnother = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        resetParticipantStore();
        let updatedState: IDataSet = {}
        updatedState = {
            legalBasesIdentifiableData: ""
        }
        setDataSetData(updatedState);
        navigate(routeName.legalBasesIdentifiableData);
    }
    const handleAddAnotherEvidence = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        let updatedState: IEvidenceList = {}
        updatedState = {
            ...evidenceListDetails,
            selectedType: '',
            evidenceList: [initialEvidence],
            fileUpload: [initialStateOfFileUpload],
            fileComment: ''
        }
        saveEvidenceDataInStore(updatedState);
        navigate(routeName.evidenceType);
    }
    const addAnotherLegalBasis = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        resetParticipantStore();
        navigate(routeName.legalBasis)
    }

    useEffect(() => {
        getEvidenceListData();
    }, []);
    useEffect(() => {
        if (isDeleted) {
            const documentData: IDeleteDocument = {
                isDeleted: false,
            };
            saveDeleteDocumentInStore(documentData);
            if (isFromEvidence) {
                let updatedDocData = [...evidenceListData.evidenceList!];
                updatedDocData.splice(fileIndex!, 1);
                let updatedState: IEvidenceList = {};
                updatedState = {
                    evidenceList: updatedDocData,
                };
                saveEvidenceDataInStore(updatedState);
            }
        }
    }, [isDeleted]);

    return (
        <>
            <ControllerHeader></ControllerHeader>
            {loading ?
                (<div className="nhsuk-grid-column-full nhsuk-u-margin-top-4">
                    <Loader loadingText={dsaContent.txtLoading} />
                </div>) : null}
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            (null) : (
                                <div className="nhsuk-grid-column-three-quarters">
                                    <GoBack condition={isFromParticipant ? dsaContent.legalbasis : (isFromDataSetPage ? dsaContent.legalbasesidentifiabledata : (isFromEvidence ? getTaskListType(dsaApplicationData.returningJourneyType!) : ""))} />
                                    <h1>
                                        {
                                            isFromParticipant ? (
                                                <span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                                    About the participants</span>
                                            ) : (null)
                                        }
                                        Uploaded document(s)
                                    </h1>
                                    <InsetText>
                                        {
                                            (isFromParticipant || isFromDataSetPage) ? (<p>
                                                If you need to upload another document, select 'Add another legal basis'.
                                            </p>) : (<p>
                                                If you need to upload another document, select 'Add another document'.
                                            </p>)
                                        }
                                    </InsetText>
                                    <p>Here you can see your uploaded documents</p>
                                    <Table responsive>
                                        <Table.Head>
                                            <Table.Row>
                                                <Table.Cell>Document reference</Table.Cell>
                                                {(isFromParticipant || isFromDataSetPage)
                                                    ?
                                                    <>
                                                        <Table.Cell>Evidence Type</Table.Cell>
                                                    </>
                                                    :
                                                    <>
                                                        <Table.Cell>Document Type</Table.Cell>
                                                        <Table.Cell>Document Section</Table.Cell>
                                                    </>
                                                }

                                                <Table.Cell>{''}</Table.Cell>
                                            </Table.Row>
                                        </Table.Head>
                                        <Table.Body>
                                            {
                                                evidenceListResult != null ? (
                                                    evidenceListResult.map((evidence, index: number) => (
                                                        <Table.Row key={index + 1}>
                                                            <Table.Cell>{evidence?.comments}</Table.Cell>
                                                            {(isFromParticipant || isFromDataSetPage)
                                                                ?
                                                                <>
                                                                    <Table.Cell>{evidence?.approvalsEvidenceType}</Table.Cell>
                                                                </>
                                                                :
                                                                <>
                                                                    <Table.Cell>{evidence?.approvalsEvidenceType}</Table.Cell>
                                                                    <Table.Cell>{evidence?.sectionType ? evidence?.sectionType : null}</Table.Cell>
                                                                </>
                                                            }
                                                            
                                                            <Table.Cell>
                                                                <a href="" onClick={(e) => handleDelete(evidence.id, evidence.sectionId!, e)}>Delete</a>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))
                                                ) : (null)
                                            }
                                        </Table.Body>
                                    </Table>
                                    <div className="nhsuk-u-padding-bottom-4"></div>
                                    {
                                        isFromDataSetPage ? (
                                            <>
                                                <p><a href="#" onClick={(e) => handleAddAnother(e)}>Add another Legal Basis</a></p>
                                            </>
                                        )
                                            : (isFromEvidence ? <>
                                                <p><a href="#" onClick={(e) => handleAddAnotherEvidence(e)}>Add another document</a></p>
                                            </> : <><p><a href="#" onClick={(e) => addAnotherLegalBasis(e)}>Add another Legal Basis</a></p></>
                                            )
                                    }
                                    <div className="nhsuk-u-padding-bottom-7">
                                    </div>
                                    <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                                </div>
                            )
                        }
                    </div>
                </main>
            </div>

        </>)
}

export default UploadedFiles;

