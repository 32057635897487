import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Label, SummaryList, Checkboxes, Button, ErrorSummary } from "nhsuk-react-components";
import { DisplayDSFCFile_URL, SubmitDSFCApproval_URL } from "../../../config/api-endpoints.config";
import { getCookie, getDate, getB64toBlobData, submitSpinner, fetchInterceptor } from "../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../state/app/hooks";
import { IApiResFiles, IDSFCContractDetails } from "../../../state/models/app.interface";
import { getDSFCContractDetails, setDSFCContractDetailsData } from "../../../state/slice/DSFC/DSFCContractDetails.slice";
import { dsaContent, dsfcContent, dsfcErrorMessage, fileTypeSize, routeName } from "../../../config/page.config";
import Attachment from "../../../Components/Attachment/Attachment";
import { AppDispatch } from "../../../state/app/store";
import "../../../Content/css/nhsuk-common.css";
import Loader from "../../../Components/Loader/Loader";
import GoBack from "../../../Components/Shared/GoBack";

const DSFCApprover: React.FC = () => {
    const navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!
    const DSFCContractDetailsData: IDSFCContractDetails = useAppSelector(getDSFCContractDetails);
    const initialState: IDSFCContractDetails = DSFCContractDetailsData || {};
    const [dsfcData, setDSFCData] = useState(initialState);
    const [showButtonAgreeContract, setShowButtonAgreeContract] = useState(true);
    const [showButtonClose, setShowButtonClose] = useState(true);
    const [isConfirmChecked, setIsConfirmChecked] = useState("");
    const [confirmError, setConfirmError] = useState("");
    const [pdfURL, setPdfURL] = useState("");
    const dispatch: AppDispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const savedataInStore = (stateData?: IDSFCContractDetails) => {
        dispatch(setDSFCContractDetailsData({ ...dsfcData, ...stateData }));
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (isConfirmChecked === dsfcContent.yes) {
            const target = e.target as HTMLInputElement;
            submitSpinner(e, submitDsfcApproval);
            setConfirmError("");
            if (target.name !== "undefined" && target.name !== null && target.name === dsfcContent.close) {
                setShowButtonAgreeContract(false);
            }
            else if (target.name !== "undefined" && target.name !== null && target.name === dsfcContent.agreeContract) {
                setShowButtonClose(false);
            }
        }
        else {
            setConfirmError(`${dsfcErrorMessage.approveConfirm_ErrorMessage}`);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };


    const handleChange = () => {
        if (isConfirmChecked === "" || isConfirmChecked === dsfcContent.no) {
            setIsConfirmChecked(dsfcContent.yes);
            setConfirmError("");
        }
        else if (isConfirmChecked === "" || isConfirmChecked === dsfcContent.yes) {
            setIsConfirmChecked(dsfcContent.no);
        }
    }

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsfcErrorMessage.summaryTitle_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <p>{dsfcErrorMessage.approveSummaryBody_ErrorMessage}</p>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#confirm">
                            {dsfcErrorMessage.approveLink_ErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    const getDsfcPDFFile = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "DSFCApplicationId": dsfcData.dsfcApplicationId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeBlob },
            body: parameters
        };

        fetchInterceptor(DisplayDSFCFile_URL, requestOptions)
            .then(response => {
                    return response.json();
            })
            .then((response) => {
                setDsfcPDFFile(response);
                setLoading(false);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setDsfcPDFFile = (response: IApiResFiles) => {
        const contentType: string = fileTypeSize.fileTypePDF;
        const fileName: string = `DSFC_${dsfcData.contractNumber}.pdf`;
        const b64Data: string = response._buffer;
        const blob: Blob = getB64toBlobData(b64Data, contentType, fileName);
        const blobUrl: string = URL.createObjectURL(blob);
        setPdfURL(blobUrl);
    }

    const submitDsfcApproval = () => {
        let updatedState: IDSFCContractDetails = {};
        updatedState = { ...dsfcData, dsfcapprove: true, dsfcagree: true }
        setDSFCData(updatedState);
        savedataInStore(updatedState);

        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "DSFCApplicationId": dsfcData.dsfcApplicationId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeBlob },
            body: parameters
        };

        fetchInterceptor(SubmitDSFCApproval_URL, requestOptions)
            .then(() => {
                let updatedState: IDSFCContractDetails = {};
                updatedState = {
                    ...dsfcData,
                    isFullySigned: true
                }
                setDSFCData(updatedState);
                savedataInStore(updatedState);
                navigate(routeName.dsfcAgree, { state: { pdfURL: pdfURL } });
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const removeRedirectionURL = () => {
        let updatedState: IDSFCContractDetails = {};
        updatedState = {
            ...dsfcData,
            redirectionURL: ""
        }
        setDSFCData(updatedState);
        savedataInStore(updatedState);
    }

    useEffect(() => {
        if (dsfcData.dsfcagree) {
            navigate(routeName.dashboard);
        }
        getDsfcPDFFile();
        removeRedirectionURL();
    }, []);

    return (
        <>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <>
                                <div className="nhsuk-grid-column-two-thirds">
                                    <GoBack />
                                    <h1>Agree your Data Sharing Framework Contract</h1>
                                    {confirmError !== "" ? errorSummary : null}
                                    <Label>Once you&apos;ve read your contract, you&apos;ll be ready to accept it.</Label>
                                    <div className="nhsuk-u-padding-bottom-3"></div>
                                    <Label>By selecting 'Accept contract', you confirm that the details provided in the application are true and complete and that you accept the contract's terms and conditions.</Label>
                                    <div className="nhsuk-u-padding-bottom-3"></div>
                                    <Label size="m">Your contract</Label>
                                    <SummaryList noBorder>
                                        <SummaryList.Row>
                                            <SummaryList.Key>Reference number</SummaryList.Key>
                                            <SummaryList.Value>{dsfcData?.contractNumber}</SummaryList.Value>
                                        </SummaryList.Row>
                                        <SummaryList.Row>
                                            <SummaryList.Key>Start and end date</SummaryList.Key>
                                            <SummaryList.Value>{getDate(dsfcData?.startDate)} - {getDate(dsfcData?.endDate)}</SummaryList.Value>
                                        </SummaryList.Row>
                                    </SummaryList>
                                    <div className="nhsuk-u-padding-bottom-3"></div>
                                    <Attachment>
                                        <Attachment.Thumbnail>
                                        </Attachment.Thumbnail>
                                        <Attachment.Details>
                                            <Attachment.Heading>
                                                <p><Attachment.Link href={pdfURL} target="_blank">Data Sharing Framework Contract {dsfcData?.version}</Attachment.Link></p>
                                            </Attachment.Heading>
                                            <Attachment.Content >
                                                <Attachment.Property>PDF</Attachment.Property>,   <Attachment.Property>less than 1 MB</Attachment.Property>
                                            </Attachment.Content>
                                        </Attachment.Details>
                                    </Attachment>
                                </div>
                                <div className="nhsuk-grid-column-full">

                                    <Checkboxes name="confirm" id="confirm" error={confirmError}>
                                        <Checkboxes.Box value={isConfirmChecked} checked={isConfirmChecked === "yes" ? true : false} onChange={handleChange}>I confirm the details provided in the application are true and complete, and accept the terms and conditions as described in the document.</Checkboxes.Box>
                                    </Checkboxes>

                                    {showButtonAgreeContract ?
                                        <>
                                            <Button name="agreeContract" onClick={handleClick} id="nhsuk-button-spinner">{dsfcContent.btnTextAcceptContract}</Button>
                                        </>
                                        : null}
                                    {showButtonClose ?
                                        <>
                                            <span> <Button secondary name="close" onClick={() => navigate(routeName.dashboard)} id="nhsuk-button-spinner">{dsfcContent.btnTextClose}</Button></span>
                                        </>
                                        : null}
                                </div>
                            </>
                        }
                    </div>
                </main>
            </div>
        </>
    )
}
export default DSFCApprover;