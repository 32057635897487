import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { applicationChangeType, dsaContent, fileTypeSize, routeName } from "../../../../config/page.config";
import { Button, Label, WarningCallout } from "nhsuk-react-components";
import { IApplication, IUserDetails } from "../../../../state/models/app.interface";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { fetchInterceptor, getCookie, getTaskListType, submitSpinner } from "../../../../Helper/Utility";
import { UpdateApplicationChangeType_URL } from "../../../../config/api-endpoints.config";
import { AppDispatch } from "../../../../state/app/store";
import { getUserDetails } from "../../../../state/slice/Header/Header.slice";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";

const ManageBeforeYouStart: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const [btnText, setBtnText] = useState(dsaContent.btnTextStart);
    const authenticationTokenCookie: string = getCookie('authenticationToken') || "";
    const userData: IUserDetails = useAppSelector(getUserDetails) || {};
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData, setDSAApplicationData] = useState(initialApplicationState);
    const saveDSAApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    
    /* To handle Navigation */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (btnText === dsaContent.btnTextStart) { submitSpinner(e, updateApplicationChangeType); }
        else { navigate(routeName.dsaTaskList) }
    };
    /* Update Application Type */
    const updateApplicationChangeType = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "ApplicationChangeType": dsaApplicationData.applicationChangeType,
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(UpdateApplicationChangeType_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setApplicationId(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    /* Set Application Id into store*/
    const setApplicationId = (applicationId: string) => {
        if (applicationId) {
            let updatedState: IApplication = {};
            updatedState = {
                ...dsaApplicationData,
                id: applicationId
            }
            setDSAApplicationDetailsData(updatedState);
            saveDSAApplicationDataInStore(updatedState);
            navigate(routeName.dsaTaskList)
        }
    }

    /* To render the extend page details  */
    const renderExtension: JSX.Element = (
        <>
            <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                {userData?.userOrgName}</span>
                Retaining the data you have
            </h1>
            <WarningCallout>
                <WarningCallout.Label>Retaining the data you have</WarningCallout.Label>
                <p>On the next page you will be able to select and submit the changes that you would like to make.</p>
                <p>Any changes you make to your Data Sharing Agreement (DSA) may mean that it needs to go through the full approval process. The time it takes to process changes may depend on the number of adjustments you make. </p>
                <p>Once you have submitted your changes, you will need to wait for them to be processed before submitting any further changes.</p>
            </WarningCallout>
            <Label size="s">You have chosen to retain the data you have and not require any new data.</Label>
            <Label size="s">During your application, you will only see the sections that you are able to change as part of your request to retain the data you have. If you want to change something in a section that has not been made available to you, contact your Case Officer.</Label>
            <Label size="s">Where you do not make any changes to a particular section, your answers will stay the same as they were when you first applied for a DSA.</Label>
            <Label size="s">Confirmation that you can retain the data will only be provided once your application has been approved.</Label>           
        </>
    )



    /* To render the Renewal page details  */
    const renderRenewal: JSX.Element = (
        <>
            <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                {userData?.userOrgName}</span>
                Requesting more data
            </h1>
            <WarningCallout>
                <WarningCallout.Label>Requesting more data</WarningCallout.Label>
                <p>Before you apply and submit your request for more data, review the information below to help you decide if you need to make any changes to your Data Sharing Agreement (DSA).</p>
                <p>Any changes you make to your DSA may mean that it needs to go through the full approval process. The time it takes to process changes may depend on the number of adjustments you make. </p>
                <p>If you make any changes and submit them, they will need to be processed before you can make any new amendments.</p>
            </WarningCallout>
            <Label size="s">You have selected, 'I would like to request more data from the same data set'.</Label>
            <Label size="s">During your application, you will only see the sections that you are able to change as part of your request for more data from the same data set. If you want to change something in a section that has not been made available to you, contact your Case Officer.</Label>
            <Label size="s">Where you do not make any changes to a particular section, your answers will stay the same as they were when you first applied for a DSA.</Label>
            <Label size="s">More data from the same data set will only be released once your application has been approved.</Label>
            <Label size="m">Before you start</Label>
            <Label size="s">If you are making additional changes to your DSA, you may find it helpful to read the information links found within <a href={routeName.help} target="_blank">'Help'</a> (opens in a new window).</Label>
            <Label size="s">If you need any support with your request for data, email <a href={'mailto:data.applications@nhsdigital.nhs.uk?subject=' + dsaApplicationData?.referenceNumber}>data.applications@nhsdigital.nhs.uk</a> quoting your NIC number in the subject field.</Label>
        </>
    )

    useEffect(() => {
        (dsaApplicationData.returningFromExtendRenewAmend === dsaContent.isFromExtentorRenew) ?
            setBtnText(dsaContent.btnTextContinue) : setBtnText(dsaContent.btnTextStart)

    }, []);
    return (
        <>
            {(dsaApplicationData && dsaApplicationData.id !== "" && dsaApplicationData.id !== undefined) ?
                <ControllerHeader></ControllerHeader>
                : null}
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-full">
                            {
                                <GoBack condition={String(dsaApplicationData.returningFromExtendRenewAmend) === dsaContent.isFromExtentorRenew ? dsaContent.applyDsa : dsaContent.managedsa} />
                            }
                        </div>
                        <div className="nhsuk-grid-column-three-quarters">
                            <div className="nhsuk-u-padding-bottom-4"></div>
                            {
                                dsaApplicationData.applicationChangeType === applicationChangeType.Extension
                                    ? (
                                        <React.Fragment>
                                            {renderExtension}
                                            <Label size="m">After you've submitted your application</Label>
                                            <Label size="s" bold>The DARS team will:</Label>
                                        </React.Fragment>)
                                    : (
                                        <React.Fragment>
                                            {renderRenewal}
                                            < Label size="m">After you've submitted your application</Label>
                                            <Label size="s" bold>Our team will:</Label>
                                        </React.Fragment>

                                    )

                            }
                            <ul>
                                <li>review your application</li>
                                <li>work with you to collect any missing details</li>
                            </ul>
                            <br></br>
                            <Button id="nhsuk-button-spinner" onClick={handleClick}>{btnText}</Button>
                        </div>
                    </div>
                </main >
            </div >
        </>
    )
}
export default ManageBeforeYouStart;
