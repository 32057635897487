import React, { useEffect, useState } from 'react';
import HeaderBasic from './Components/Header/HeaderBasic';
import FooterBasic from './Components/Footer/FooterBasic';
import { getCookie } from './Helper/Utility'
import { Outlet } from 'react-router';

const App: React.FC = () => {
    return (
        <>
            <HeaderBasic />
            <Outlet />
            <FooterBasic />
        </>
    );
}

export default App;