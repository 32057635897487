import { Button, DateInput, Input, Label, InsetText, ErrorSummary } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader';
import { controllerDetails, dsaControllerErrorMessage, dsaContent, taskListSectionStatus, routeName } from "../../../../config/page.config";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { IProcessor, IApplication, IProcessorList } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { getProcessorDetails, setProcessorDetails } from "../../../../state/slice/Processor/Processor.slice";
import { getCookie, submitSpinner, validateDateValue, calculateExampleDate, fetchInterceptor } from "../../../../Helper/Utility";
import { SubmitScreenDataProcessor_URL, UpdateScreenDataProcessor_URL } from "../../../../config/api-endpoints.config";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getProcessorListDetails, setProcessorListDetailsData } from "../../../../state/slice/ProcessorList/ProcessorList.slice";
import GoBack from "../../../../Components/Shared/GoBack";

/*
 To enter Data Protection Act Registration details of the processor and save complete processor data on API.
*/

const DataProtectionRegistrationProcessor: React.FC = () => {

    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const dsaProcessorDetailsData: IProcessor = useAppSelector(getProcessorDetails);
    const initialState: IProcessor = dsaProcessorDetailsData || {};
    const [processorData, setProcessorData] = useState(initialState);

    /* To dispatch the setProcessorDetails Redux action */
    const saveDataInStore = (stateData?: IProcessor) => {
        dispatch(setProcessorDetails({ ...processorData, ...stateData }));
    }

    /* To dispatch the setProcessorListDetailsData Redux action */
    const saveProcessorListDataInStore = (stateData?: IProcessorList) => {
        dispatch(setProcessorListDetailsData({ ...processorListData, ...stateData }));
    }

    const processorListDetailsData: IProcessorList = useAppSelector(getProcessorListDetails);
    const initialProcessorListState: IProcessorList = processorListDetailsData || {};
    const [processorListData, ] = useState(initialProcessorListState);
    const processorIndex = processorListData.activeProcessor || 0;
    let isDirtyProcessor = processorData.isDirtyProcessor || false; 
    const [registrationNumberLinkError, setRegistrationNumberLinkError] = useState("");
    const [organisationNameLinkError, setOrganisationNameLinkError] = useState("");
    const [datePublishedError, setDatePublishedError] = useState("");
    const [expiryDate, setExpiryDate] = useState(false);
    const [diDayError, setDiDayError] = useState(false);
    const [diMonthError, setDiMonthError] = useState(false);
    const [diYearError, setDiYearError] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IProcessor = {};
        updatedState = { ...processorData, [e.target.name]: e.target.value, isDirtyProcessor: true }
        resetError(e);
        setProcessorData(updatedState);
        saveDataInStore(updatedState);
    };

    /* Calling submitRegistrationDetails function if selected value is valid */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (ValidateFields()) {
            submitSpinner(e, submitRegistrationDetails);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    /* set the dpaRegistrationExpiryDate field to the state and update in store */
    const submitExpiryDate = () => {
        let updatedState: IProcessor = {};
        updatedState = {
            ...processorData,
            dpaRegistrationExpiryDate: processorData?.dpDay + "/" + processorData?.dpMonth + "/" + processorData?.dpYear
        }
        setProcessorData(updatedState);
        saveDataInStore(updatedState);
    }

    /* API call to submit the processor data based on new processor/existing processor */
    const submitRegistrationDetails = () => {
        if (processorListData.processorDetailsPage && !processorData.isNewProcessor) {
            if (isDirtyProcessor) {
                const processorId = processorListData.processorList ? processorListData.processorList[processorIndex].processorId : null;
                const parameters: string = JSON.stringify({
                    "UserAuthTokenId": authenticationTokenCookie,
                    "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
                    "DPARegisteredExpiry": processorData?.dpDay + "/" + processorData?.dpMonth + "/" + processorData?.dpYear,
                    "DPARegisteredName": processorData.dpaOrganisationName,
                    "DPARegisteredCode": processorData.dpaRegistrationNumber,
                    "DataProcessorId": processorId,
                    "DpaId": processorData.dpaId,
                    "IsDpaDirty": true,
                    "SectionStatus": dsaApplicationData.aboutProcessorSectionStatus
                });
                const requestOptions: Object = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: parameters
                };
                fetchInterceptor(UpdateScreenDataProcessor_URL, requestOptions)
                    .then(response => {
                            return response.json()
                    })
                    .then(() => {
                        let updatedState: IProcessorList = {
                            ...processorListData, processorDetailsPage: processorListData.processorDetailsPage
                        }
                        let sectionStatus: IApplication = {
                            ...dsaApplicationData, aboutProcessorSectionStatus: taskListSectionStatus.inProgress
                        }
                        setDsaApplicationData(sectionStatus);
                        saveApplicationDataInStore(sectionStatus);
                        saveProcessorListDataInStore(updatedState);
                        navigate(routeName.processorDetails);
                    })
                    .catch(() => navigate(routeName.serviceError));

            }
            else { navigate(routeName.processorDetails); }
        } else {
            const parameters: string = JSON.stringify({
                "UserAuthTokenId": authenticationTokenCookie,
                "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
                "DataProcessorType": processorData.processorType,
                "DataProcessorId": "",
                "OrganisationName": processorData.alternativeOrganisationName,
                "OrganisationId": processorData.processordataOrgGuidId,
                "AssuranceTypeSelectedISO": null,
                "AssuranceTypeSelectedOther": null,
                "DataProcessorSelectedISO": null,
                "DataProcessorSelectedOther": null,
                "IGToolkitVersion": "",
                "IGToolkitDateComplete": "",
                "IGToolkitODSCode": "",
                "IGToolkitNotes": "",
                "IGToolkitScore": null,
                "OtherVersion": "",
                "OtherDateComplete": null,
                "OtherNotes": "",
                "DPARegisteredCode": processorData.dpaRegistrationNumber,
                "DPARegisteredName": processorData.dpaOrganisationName,
                "DPARegisteredExpiry": processorData?.dpDay + "/" + processorData?.dpMonth + "/" + processorData?.dpYear,
                "FileList": processorData.fileUpload,
                "IsPayingForDSA": false,
                "PurchaseOrderNumber": null,
                "PurchaseOrderNotRequired": false,
                "YouWillBeSignAgreement": false,
                "YouKnowBehalfOrganisationSignAgreement": false,
                "Title": null,
                "FirstName": null,
                "Lastname": null,
                "Email": null,
                "SigneeOrganisation": null,
                "JobTitle": null,
                "PhoneNumber": null,
                "ContactName": null,
                "ContactId": null,
                "ChangeType": null,
                "IsProcessoraProcessorDirty": processorData.isDirtyProcessor,
                "AddressLine1": processorData.alternativeStreet1,
                "AddressLine2": processorData.alternativeStreet2,
                "AddressLine3": processorData.alternativeStreet3,
                "TownCity": processorData.alternativeCity,
                "County": processorData.alternativeCounty,
                "Postcode": processorData.alternativePostCode,
                "Country": processorData.alternativeCountry,
                "SecurityAssurance": processorData.saList,
                "SectionStatus": dsaApplicationData.aboutProcessorSectionStatus
            });

            const requestOptions: object = {
                method: 'post',
                headers: { 'content-type': 'application/json', 'accept': 'application/json' },
                body: parameters
            };

            fetchInterceptor(SubmitScreenDataProcessor_URL, requestOptions)
                .then(response => {
                        return response.json()
                })
                .then(data => {
                    if (data) {
                        let updatedState: IProcessorList = {
                            ...processorListData, processorDetailsPage: dsaContent.summaryPageText
                        }
                        let sectionStatus: IApplication = {
                            ...dsaApplicationData, aboutProcessorSectionStatus: taskListSectionStatus.inProgress
                        }
                        setDsaApplicationData(sectionStatus);
                        saveApplicationDataInStore(sectionStatus);
                        saveProcessorListDataInStore(updatedState);
                        navigate(routeName.processorDetails);
                    }
                })
                .catch(() => navigate(routeName.serviceError));
        }
    }

    /* Validate the input fields and setting the error message */
    const ValidateFields = () => {
        let isValid: boolean = true;

        submitExpiryDate();

        if (!processorData?.dpaRegistrationNumber) {
            setRegistrationNumberLinkError(`${dsaControllerErrorMessage.enterRegistrationNumber_ErrorMessage}`);
            isValid = false;
        }

        if (!processorData?.dpaOrganisationName) {
            setOrganisationNameLinkError(`${dsaControllerErrorMessage.enterOrganisationName_ErrorMessage}`);
            isValid = false;
        }

        let dateValueError = validateDateValue(Number(processorData?.dpDay), Number(processorData?.dpMonth), Number(processorData?.dpYear), expiryDate);
        if (dateValueError[0] !== "") {
            setDatePublishedError(dateValueError[0]);
            let fields = dateValueError[1].split(",");
            for (let i = 0; i < fields.length; i++) {
                if (fields[i] === dsaContent.dpInputDayField) {
                    setDiDayError(true);
                }
                if (fields[i] === dsaContent.dpInputMonthField) {
                    setDiMonthError(true);
                }
                if (fields[i] === dsaContent.dpInputYearField) {
                    setDiYearError(true);
                }
            }
            isValid = false;
        }
        return isValid;
    }

    /* Reset the error if the entered value for the input is not blank */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === dsaContent.registrationNumberText && e.target.value !== "") {
            setRegistrationNumberLinkError("");
        }
        if (e.target.name === dsaContent.organisationNameText && e.target.value !== "") {
            setOrganisationNameLinkError("");
        }
        if (e.target.name === dsaContent.dpInputDayField) {
            setDatePublishedError("");
            setDiDayError(false);
        }
        if (e.target.name === dsaContent.dpInputMonthField) {
            setDatePublishedError("");
            setDiMonthError(false);
        }
        if (e.target.name === dsaContent.dpInputYearField) {
            setDatePublishedError("");
        }
    }

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaControllerErrorMessage.selectControllerSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#dpaRegistrationNumber">
                            {registrationNumberLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#dpaOrganisationName">
                            {organisationNameLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#dpaRegistrationExpiryDate">
                            {datePublishedError}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    useEffect(() => {
        setExpiryDate(true);
    }, [])

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                           <GoBack/>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the Processor
                            </span>

                            <h1>Data protection <br /> registration for the <br /> Processor</h1>
                            {registrationNumberLinkError !== "" || organisationNameLinkError !== "" || datePublishedError ? errorSummary : null}

                            <InsetText>
                                <Label size="s">
                                    Find the details of your organisation's data protection registration.
                                    <div className="nhsuk-u-padding-bottom-4"></div>                                    
                                    You can view the data protection public register on the <a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/key-definitions/controllers-and-processors/" target="_blank">Information Commissioners Office (ICO) website (opens in a new window).</a>
                                    <div className="nhsuk-u-padding-bottom-4"></div>
                                    If you can't find your organisation's details on the data protection public register, contact your Information Governance (IG) team for help.
                                </Label>
                            </InsetText>

                            <Label size="s">We need proof of your current registration with the Information Commissioners Office ICO</Label>
                        </div>

                        <div className="nhsuk-grid-column-two-thirds">
                            <Input
                                id="dpaRegistrationNumber"
                                name={dsaContent.registrationNumberText}
                                label="Registration number"
                                value={processorData?.dpaRegistrationNumber}
                                error={registrationNumberLinkError}
                                onChange={handleChange}
                            />

                            <Input
                                id="dpaOrganisationName"
                                name={dsaContent.organisationNameText}
                                label="Organisation name"
                                value={processorData?.dpaOrganisationName}
                                error={organisationNameLinkError}
                                hint="The name registered as data Processor on the data protection register"
                                onChange={handleChange}
                            />

                            <div className="nhsuk-u-padding-bottom-4"></div>
                            <DateInput id="dpaRegistrationExpiryDate" hint={`For example, ${calculateExampleDate(dsaContent.exampleYearForFuture)}`} label="Date registration expires" name="dsptRegistrationExpiryDate" error={datePublishedError}>
                                <DateInput.Day id="dpDay" name="dpDay" onChange={handleChange} value={processorData.dpDay} error={diDayError} />
                                <DateInput.Month id="dpMonth" name="dpMonth" onChange={handleChange} value={processorData.dpMonth} error={diMonthError} />
                                <DateInput.Year id="dpYear" name="dpYear" onChange={handleChange} value={processorData.dpYear} error={diYearError} />
                            </DateInput>

                            <div className="nhsuk-u-padding-bottom-4"></div>
                        </div>

                        <div className="nhsuk-grid-column-two-thirds">
                            <div className="nhsuk-u-padding-bottom-6"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{controllerDetails.btnSaveAndContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default DataProtectionRegistrationProcessor;