import { Button, Details, InsetText, Label, ReviewDate, Select } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import { dsaContent, fileTypeSize, initialStatePageConfig, pageConfigList, routeName } from "../../../../config/page.config";
import { IApiResFrequencies, IApiResFrequenciesList, IApiResProductDetails, IApplication, IDataSet, IDataSetFieldGroupList, IDataSetFieldList, IDataSetFrequency, IDataSetList, IHistoricAnnualData, IPageConfig } from "../../../../state/models/app.interface";
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";
import { fetchInterceptor, getCookie, numberToWords, submitSpinner } from "../../../../Helper/Utility";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { AppDispatch } from "../../../../state/app/store";
import { GetPageConfig_URL, GetProductList_URL, SubmitScreenProductList_URL } from "../../../../config/api-endpoints.config";
import Loader from "../../../../Components/Loader/Loader";
import { getDataSetListDetails } from "../../../../state/slice/DataSetList/DataSetList.slice";
import useFetch from "../../../../Hooks/useFetch";
/*Select dataset to dataset start journey*/
const SelectDataSet: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [loading, setLoading] = useState(true);

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData, setDsaApplicationData] = useState(initialApplicationState);
    const saveDSAApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    const [frequencyDataList, setfrequencyDataList] = useState<IDataSetFrequency[]>([]);
    const dataSetDetails: IDataSet = useAppSelector(getDataSetDetails);
    const initialDataSetState: IDataSet = dataSetDetails || {};
    const [dataSetData, setDataSetData] = useState<IDataSet>(initialDataSetState);

    const dataSetListDetails: IDataSetList = useAppSelector(getDataSetListDetails);
    const initialDataSetListState: IDataSetList = dataSetListDetails || {};
    const [dataSetListData] = useState(initialDataSetListState);

    const [nextDataSetIndex] = useState(dataSetListData?.activeDataSet ? dataSetListData.activeDataSet + 1 : 1)
    const [selectedDataSet, setSelectedDataSet] = useState("");
    const [pageData, setPageData] = useState<IPageConfig>(initialStatePageConfig);

    const parameters = {
        "UserAuthTokenId": authenticationTokenCookie,
        "Id": pageConfigList.DatasetSelectionPage
    }
    const { response, loading: reviewDateLoader }:any = useFetch(GetPageConfig_URL, "POST", {}, parameters);

    /* To dispatch the setDataSetDetailsData Redux action */
    const saveDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }
    /*Variable Declarations*/

    /*get set DataSet details*/
    const getDataSet = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetProductList_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setDataSetDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setDataSetDetails = (dataSetList: IApiResProductDetails[]) => {
        let updatedState: IApplication = {};
        let dataSetDetails: IDataSet = {};
        let dataSetDetailsList: IDataSet[] = [];
        if (dataSetList.length > 0) {
            dataSetList.map((dataSet: IApiResProductDetails) => {
                dataSetDetails = {
                    description: dataSet.Description,
                    crmUID: dataSet.CRMUID
                }
                dataSetDetailsList.push(dataSetDetails);
            })
            updatedState = {
                ...dsaApplicationData,
                dataSets: dataSetDetailsList
            }

            if (dataSetData.crmUID === "") {
                dataSetDetails = {
                    crmUID: dataSetDetailsList[0].crmUID,
                    description: dataSetDetailsList[0].description,
                    name: dataSetDetailsList[0].description
                };
                setSelectedDataSet(dataSetDetailsList[0].crmUID!);
            }
            else {
                dataSetDetails = {
                    crmUID: dataSetData.crmUID,
                    description: dataSetData.description,
                    name: dataSetData.description
                };
                setSelectedDataSet(dataSetData?.crmUID || "");
            }
            setDataSetData(dataSetDetails);
            saveDataInStore(dataSetDetails);
            setDsaApplicationData(updatedState);
            saveDSAApplicationDataInStore(updatedState);
        }
        setLoading(false);
    }

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value !== undefined && e.target.value !== null && e.target.value !== "") {
            let updatedState: IDataSet = {};
            updatedState = {
                ...dataSetData,
                crmUID: e.target.value
            }
            setDataSetData(updatedState);
            saveDataInStore(updatedState);
        }
    }

    const getSubmitProductList = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "DataSetSelected": dataSetData.crmUID
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenProductList_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setSubmitProductList(data);
                navigate(routeName.aggredatedRecord);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    let frequencies: IDataSetFrequency = {};
    let frequencyDataArr: IDataSetFrequency[] = [...frequencyDataList];
    const setSubmitProductList = (response: IApiResFrequenciesList) => {
        if (response !== undefined && response !== null && response!) {
            let updatedState: IDataSet = {};
            if (response?.Frequencies !== undefined && response?.Frequencies !== null && response?.Frequencies!) {
                response?.Frequencies?.map((item: IApiResFrequencies ) => {
                    frequencies = { value: item?.Value };
                    frequencyDataArr = Object.assign([], frequencyDataArr);
                    frequencyDataArr.push(frequencies);
                    setfrequencyDataList(frequencyDataArr);
                })
            }
            updatedState = {
                ...dataSetData,
                crmProductId: response?.CRMProductId,
                serviceTypeOneOff: response?.ServiceTypeOneOff,
                serviceTypeRegularBespoke: response?.ServiceTypeRegularBespoke,
                serviceTypeRegularStandard: response?.ServiceTypeRegularStandard,
                serviceTypeSystemAccess: response?.ServiceTypeSystemAccess,
                frequencylist: frequencyDataArr,
                description: response?.Name,
                name: response?.Name,
                dataDissemination: true,
                standardisationServiceLevelOptionsSelected: "",
                serviceType: dsaContent.dataDissemination,
                updatedDaily: response?.UpdatedDaily,
                crmAssociatedProductId: "",
                aggregatedOrRecordLevelData: "",
                linkedToCohort: false,
                datasetFrequency: "",
                historicAnnualDataList: [initialStateHistoricData],
                aboutDataSectionStatus: "",
                datasetCohort: "",
                selectedProductFieldsList: [initialStateFieldGroupList],
                firstPointer: 0,
                selectedPaginationPage: 0,
                annualPeriodsExist: false,
                currentYTDExists: false,
                latestRelease: false,
                futureRelease: false,
                historicAnnual: false,
                initialHistoricAnnualDataList: [initialStateHistoricData],
                groupByFieldsList: [initialStateGroupFieldList]
            }
            setDataSetData(updatedState);
            saveDataInStore(updatedState);
        }
    }

    const initialStateFieldGroupList: IDataSetFieldList = {
        id: "",
        groupFieldId: "",
        groupName: "",
        fieldName: "",
        fieldType: "",
        fieldLogicalName: "",
        sensitiveFlag: false,
        identifiableFlag: false,
        isGroupField: false,
        isPrimaryField: false,
        description: "",
        startDate: "",
        endDate: "",
        groupType: ""
    }

    const initialStateOfDataSetFrequency: IDataSetFrequency = {
        value: ""
    }
    const initialStateHistoricData: IHistoricAnnualData = {
        period: "",
        fileAvailabilityId: "",
        periodValue: "",
        displayText: ""
    }

    const initialStateGroupFieldList: IDataSetFieldGroupList = {
        groupName: "",
        groupId: "",
        groupFieldList: [initialStateFieldGroupList]
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        submitSpinner(e, getSubmitProductList);
    }

    useEffect(() => {
        getDataSet();
    }, [])

    useEffect(() => {
        response && setPageData(response);
    }, [response]);

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading || reviewDateLoader ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                    About the data
                                </span>
                                <h1>Select {numberToWords(nextDataSetIndex)?.toLowerCase()} data set</h1>
                                <InsetText>
                                    <p>For each data set you select, we'll ask you some questions relating to it.</p>
                                    <p>If the data set you require is not listed, contact NHS England on 0300 303 5678, or email <a href='mailto:enquiries@nhsdigital.nhs.uk'>enquiries@nhsdigital.nhs.uk</a></p>
                                </InsetText>
                                <Label>Select data set</Label>
                                <Details>
                                    <Details.Summary>Data set details</Details.Summary>
                                    <Details.Text>
                                        <p>Choose a data set to add to this application. Once you've answered the questions relating to your data set, you will be able to select additional data sets. </p>
                                        <p>Further information about available data sets can be found in the <a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-products-and-services/data-set-catalogue" target="_blank">data set catalogue (opens in a new window)</a>. </p>
                                        <p>Here you can learn about <a href="https://digital.nhs.uk/services/data-access-request-service-dars/data-access-request-service-dars-charges" target="_blank">Data Access Request Service (DARS) charges (opens in a new window)</a> </p>
                                        <p>Applications for National Disease Registration Service (NDRS) data can now be made through NHS England's Data Access Request Service (DARS). If you have any questions relating to the data available, email <a href="mailto:data.applications@nhsdigital.nhs.uk">data.applications@nhsdigital.nhs.uk</a> </p>
                                    </Details.Text>
                                </Details>
                                <Select id="select-1" defaultValue={selectedDataSet} onChange={handleChange}>
                                    {
                                        dsaApplicationData.dataSets?.map((dataSet: IDataSet) => (
                                            <Select.Option value={dataSet.crmUID} key={dataSet.crmUID} selected={String(dataSet.crmUID) === String(dataSetData.crmUID)} >{dataSet.description}</Select.Option>
                                        )
                                        )
                                    }
                                </Select>
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                                <div className="nhsuk-u-padding-bottom-1"></div>
                                <ReviewDate
                                    lastReviewed={pageData.PageLastReviewed}
                                    nextReview={pageData.NextReviewDue}
                                />
                            </div>
                        }
                    </div>
                </main>
            </div>
        </>
    );
}
export default SelectDataSet;
