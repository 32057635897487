import { Label } from "nhsuk-react-components";
import React from "react";
import Loader from "../../Components/Loader/Loader";
import { dsaContent } from "../../config/page.config";
import { getUserDetails } from "../../state/slice/Header/Header.slice";
import { useAppSelector } from "../../state/app/hooks";

const NoPermission: React.FC = () => {
    const { pageLoader } = useAppSelector(getUserDetails);

    return (
        <div className="nhsuk-width-container">
            <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                <div className="nhsuk-grid-row">
                { 
                    pageLoader ?
                        <Loader loadingText={dsaContent.txtLoading} /> :
                        <div className="nhsuk-grid-column-two-thirds">
                            <h1>Welcome to the Data Access Request Service (DARS) Single Sign-on (SSO) page</h1>
                            <Label size="s">It looks like you have registered for an account with DARS online using Single Sign-on.</Label>
                            <Label size="s">If you are a first-time user of DARS online and have not received an email confirming you have access to the site, email <a href='mailto:enquiries@nhsdigital.nhs.uk?subject=New data request'>enquiries@nhsdigital.nhs.uk</a> quoting 'New data request' in the subject line. A member of the Data Services Team will contact you to discuss the request and if appropriate, provide access to DARS online.</Label>
                                <Label size="s">Not all requests for data will progress to the application stage. It may be that your requirement can be satisfied through another NHS England service, existing published data, or that you don't have the legal basis to access the data. </Label>
                            <Label size="s">
                                Once the DARS team have confirmed that your request will need to go through the Data Access request Service, you can begin your application. Make sure you use the same email address that you registered during the Single sign-on process and that you provided to NHS England.
                            </Label>
                            <Label size="s">
                                If you are using the previously registered email address and can't access DARS online, email <a href='mailto:data.applications@nhsdigital.nhs.uk'>data.applications@nhsdigital.nhs.uk</a> quoting the reference number you were given by NHSD or DARS regarding this case or contact your Case Officer.
                            </Label>
                        </div>
                }
                </div>
            </main>
        </div>
    )
}
export default NoPermission;