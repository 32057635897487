import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Label, SummaryList } from "nhsuk-react-components";
import { downloadPdfFile, setGeneratedBlobUrl } from "../../../Helper/Utility";
import { useAppSelector } from "../../../state/app/hooks";
import { IApplication, IUserDetails } from "../../../state/models/app.interface";
import { getDSADetails, } from "../../../state/slice/DSA/DSA.slice";
import Attachment from "../../../Components/Attachment/Attachment";
import { getUserDetails } from "../../../state/slice/Header/Header.slice";
import "../../../Content/css/nhsuk-common.css";
import Loader from '../../../Components/Loader/Loader';
import { dsaContent } from '../../../config/page.config'
import GoBack from "../../../Components/Shared/GoBack";

const DownloadActiveDSA: React.FC = () => {
    const navigate = useNavigate();
    const [blobUrl, setBlobUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const UserDetailsData: IUserDetails = useAppSelector(getUserDetails);
    const initialStateofUserData: IUserDetails = UserDetailsData || {};
    const [userData, setUserData] = useState(initialStateofUserData);
    const dsaAgreementDetails: IApplication = useAppSelector(getDSADetails);
    const initialAgreementState: IApplication = dsaAgreementDetails || {};
    const [dsaAgreementData] = useState(initialAgreementState);
    const [downloadClicked, setDownloadClicked] = useState(false);
    const abortController = new AbortController();

    const getVersionNumber = () => dsaAgreementData?.referenceNumber?.split('-')[dsaAgreementData?.referenceNumber?.split('-').length - 1];

    const getDsaPDFFile = () => {
        downloadPdfFile(abortController,dsaAgreementData?.id!, dsaAgreementData?.referenceNumber!, setBlobUrl);
    }

    const handleDownload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        event.preventDefault();
        setDownloadClicked(true);
    }

    useEffect(() => {
        getDsaPDFFile();
        setUserData(UserDetailsData);
        setLoading(false);
    }, [UserDetailsData]);

    useEffect(() => {
        if (downloadClicked && blobUrl) {
            setGeneratedBlobUrl(blobUrl);
            setDownloadClicked(false);
        }
    }, [downloadClicked, blobUrl]);

    useEffect(() => {
        return (() => {
            abortController.abort();
        });
    }, [navigate]);

    return (
        <>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            : null}
                        {!loading ?

                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack />
                                <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                    {userData?.userOrgName}
                                </span>
                                <p></p>
                                <p></p>
                                <h1>Your Active Data Sharing Agreement </h1>
                                <Label size="m">Your agreement</Label>
                                <SummaryList noBorder>
                                    <SummaryList.Row>
                                        <SummaryList.Key>Reference number</SummaryList.Key>
                                        <SummaryList.Value>{dsaAgreementData?.referenceNumber}</SummaryList.Value>
                                    </SummaryList.Row>
                                    <SummaryList.Row>
                                        <SummaryList.Key>Title</SummaryList.Key>
                                        <SummaryList.Value>{dsaAgreementData?.title ? dsaAgreementData?.title : '-'}</SummaryList.Value>
                                    </SummaryList.Row>
                                    <SummaryList.Row>
                                        <SummaryList.Key>Start and end date</SummaryList.Key>
                                        <SummaryList.Value>{dsaAgreementData?.startDate ? dsaAgreementData?.startDate : '-'} - {dsaAgreementData?.endDate ? dsaAgreementData?.endDate : '-'}</SummaryList.Value>
                                    </SummaryList.Row>
                                </SummaryList>
                                <Label>Once you have downloaded your Active Data Sharing Agreement (DSA), consider changing the file name to something that will help you easily locate it. </Label>
                                <p></p>
                                <p></p>
                                <Attachment>
                                    <Attachment.Thumbnail>
                                    </Attachment.Thumbnail>
                                    <Attachment.Details>
                                        <Attachment.Heading>
                                            <p>
                                                <Attachment.Link href="#" target="_blank" className={downloadClicked ? "nhsuk-link-spinner nhsuk_download_spinner" : ""} onClick={handleDownload}>Data Sharing Agreement {getVersionNumber()}</Attachment.Link>
                                            </p>
                                        </Attachment.Heading>
                                        <Attachment.Content >
                                            <Attachment.Property>PDF</Attachment.Property>,   <Attachment.Property>less than 1 MB</Attachment.Property>
                                        </Attachment.Content>
                                    </Attachment.Details>
                                </Attachment>
                            </div>
                            : null}
                    </div>
                </main>
            </div>
        </>
    )
}
export default DownloadActiveDSA;