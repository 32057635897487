import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Radios} from "nhsuk-react-components";
import { controllerSection, dsaContent, dsaControllerErrorMessage, fileTypeSize, initialStateOfFileUpload, routeName, sectionOptions, taskListSectionStatus } from "../../../../config/page.config";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader'
import ErrorDetails from '../../../../Components/Shared/ErrorSummary';
import { useAppSelector } from "../../../../state/app/hooks";
import { getControllerDetails, setControllerDetailsData } from "../../../../state/slice/DSAAboutController/DSAAboutController.slice";
import { getControllerListDetails, setControllerListDetailsData } from "../../../../state/slice/ControllerList/ControllerList.slice";
import { IApplication, IController, IControllerList, ISecurityAssurance } from "../../../../state/models/app.interface";
import { getCookie, submitDSASectionStatus, fetchInterceptor, submitSpinner, resetSecurityAssuranceState } from '../../../../Helper/Utility';
import { DeleteDSAChildRecord_URL } from "../../../../config/api-endpoints.config";
import { useAppDispatch } from '../../../../state/app/hooks';
import { AppDispatch } from '../../../../state/app/store';
import Loader from "../../../../Components/Loader/Loader";
import { getSecurityAssuranceDetails, setSecurityAssuranceDetailsData } from "../../../../state/slice/SecurityAssurance/SecurityAssurance.slice";
import GoBack from "../../../../Components/Shared/GoBack";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
/* Controller Delete Page*/
const DeleteController: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const [deleteValue, setDeleteValue] = useState('');

    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};

    const initialControllerListState: IControllerList = useAppSelector(getControllerListDetails) || {};
    const [controllerListData, setControllerListData] = useState(initialControllerListState);
    
    const controllerDetailsData: IController = useAppSelector(getControllerDetails);
    const initialState: IController = controllerDetailsData || {};
    const [controllerData,] = useState(initialState);

    const [deleteControllerErrorMessage, setDeleteControllerErrorMessage] = useState("");
    const [controllerLength] = useState(controllerListData?.controllerList?.length || 0);

    const saveControllerDataInStore = (stateData?: IController) => {
        dispatch(setControllerDetailsData({ ...controllerData, ...stateData }));
    }

    const saveControllerListDataInStore = (stateData?: IControllerList) => {
        dispatch(setControllerListDetailsData({ ...controllerListData, ...stateData }));
    }
    const securityAssuranceDetailsData: ISecurityAssurance = useAppSelector(getSecurityAssuranceDetails);
    const initiailSecurityAssuranceState: ISecurityAssurance = securityAssuranceDetailsData || {};
    const [securityAssuranceData, setSecurityAssuranceData] = useState(initiailSecurityAssuranceState);
    const saveSecurityAssuranceDataInStore = (stateData?: ISecurityAssurance) => {
        dispatch(setSecurityAssuranceDetailsData({ ...securityAssuranceData, ...stateData }));
    }    
    const resetControllerData = () => {        
        let updatedStateSA = resetSecurityAssuranceState();
        setSecurityAssuranceData(updatedStateSA);
        saveSecurityAssuranceDataInStore(updatedStateSA);

        let updatedState: IController = {};
        updatedState = {
            controllerId: "",
            isDirtyController: true,            
            aboutControllerSectionStatus: "",
            dpaRegistrationNumber: "",
            dpaOrganisationName: "",
            dpaRegistrationExpiryDate: "",
            isControllerProcessingData: false,
            dpDay: "",
            dpMonth: "",
            dpYear: "",
            fileUpload: [initialStateOfFileUpload],
            dsaApplicationId: "",
            contractNumber: "",
            alternativeOrganisationName: "",
            alternativeStreet1: "",
            alternativeStreet2: "",
            alternativeStreet3: "",
            alternativeCity: "",
            alternativeCounty: "",
            alternativePostCode: "",
            alternativeCountry: "",
            controllerdataOrgGuidId: "",
            controllerdataOrgDesc: "",
            controllerIndex: 0,
            dpaId: "",
            isNewController: true,
            controllerProcessingData: "",
            saList: {},

        }
        saveControllerDataInStore(updatedState);
    }

    const resetControllerList = (deletedControllerId?: string) => {
        let filteredControllerList: IController[] = [];
        const controllerList = controllerListData.controllerList ? controllerListData.controllerList : [];
        filteredControllerList = controllerList.filter((controller: IController) => controller.controllerId !== deletedControllerId);
        let updateState: IControllerList = {
            ...controllerListData,
            controllerList: filteredControllerList as [IController]  
        }
        setControllerListData(updateState);
        saveControllerListDataInStore(updateState);
    }


    /*Variable Declarations*/

    /*Delete section*/
    const handleDelete = (contollerId: string) => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "Id": contollerId,
            "SectionId": sectionOptions.DataController,
            "ApplicationId" : dsaApplicationData.id!==""?dsaApplicationData.id:""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(DeleteDSAChildRecord_URL, requestOptions)
            .then(() => {
                resetControllerData();
                resetControllerList(controllerData.controllerId);
                resetControllerData();
                updateSectionStatus();
            })
            .catch(() => navigate(routeName.serviceError));
    }

    /*  Updating the Section status on Delete*/
    const updateSectionStatus = async () => {
        const statusResponse = await submitDSASectionStatus(dsaApplicationData.id!, controllerSection.aboutController, taskListSectionStatus.inProgress);
        if (statusResponse && controllerData.isControllerProcessingData) {
            await submitDSASectionStatus(dsaApplicationData.id!, controllerSection.aboutProcessor, taskListSectionStatus.inProgress);
        }
        if (statusResponse && controllerLength >= 2 && controllerListData.controllerDetailsPage === dsaContent.checkYourAnswersPageText) {
            navigate(routeName.addAnotherController);
        }
        else if (statusResponse && controllerLength === 2 && controllerListData.controllerDetailsPage === dsaContent.summaryPageText) {
            navigate(routeName.controllerDetails)
        }
        else {
            navigate(routeName.controllerData);
        }
    }

    const navigateDelete = () => {
        if (deleteValue === dsaContent.yes) {
            handleDelete(controllerData.controllerId!);
        } else {
            navigate(routeName.controllerDetails);
        }
    }

    const handleDeleteContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateDeleteFields();
        if (isValid) {
            submitSpinner(e, navigateDelete);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const validateDeleteFields = () => {
        let isValid: boolean = true;

        if (deleteValue === '') {
            setDeleteControllerErrorMessage(`${dsaControllerErrorMessage.deleteController_ErrorMessage} ${controllerData.controllerdataOrgDesc}`);
            isValid = false;
        }
        return isValid;
    }

    const resetDeleteError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setDeleteControllerErrorMessage("");
        }
    }

    const handleChangeDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteValue(e.target.value)
        resetDeleteError(e);
        let updatedState: IController = {};
        updatedState = { ...controllerData, isDirtyController: true }
        saveControllerDataInStore(updatedState);
    }
    /*Delete section*/
    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} /> :
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    About the Controller</span>
                                    Are you sure you want to delete {controllerData.controllerdataOrgDesc}?
                                </h1>
                                {deleteControllerErrorMessage !== "" && <ErrorDetails errorDescription={deleteControllerErrorMessage} errorHref="#deleteController"></ErrorDetails>}

                                <Radios
                                    name="deletecontroller"
                                    id="deleteController"
                                    error={deleteControllerErrorMessage}
                                >
                                    <Radios.Radio value={dsaContent.yes}
                                        onChange={handleChangeDelete}>
                                        {dsaContent.yes}
                                    </Radios.Radio>
                                    <Radios.Radio value={dsaContent.no} onChange={handleChangeDelete}>{dsaContent.no}</Radios.Radio>
                                </Radios>

                                <div className="nhsuk-u-padding-bottom-8"></div>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleDeleteContinue}>{dsaContent.btnSaveAndContinue}</Button>

                            </div>
                        }
                    </div>
                </main>
            </div>
        </>
    )
}

export default DeleteController;
