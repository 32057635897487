import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { INews, INewsList } from '../../models/app.interface'

const initialStateOfNewsList: INewsList = {
    contentType: "",
    content: "",
    contentId: "",
    date: "",
    summary: "",
    title: "",
    logicalName: "",
    id: ""
}

const initialState: INews = {
    newsList: [initialStateOfNewsList],
    selectedNews: 1,
    hasMoreRecords: false,
    firstPointer: 1,
    lastPointer:1
}

export const Newslice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setNewsDetailsData: (state: INews, action: PayloadAction<INews>) => {
            state = Object.assign(state, action.payload);
        },
        resetNewsDetailsData: (state: INews, action: PayloadAction<INews>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setNewsDetailsData, resetNewsDetailsData } = Newslice.actions;
export const getNewsDetails = (state: RootState) => state.newsDetails;
export default Newslice.reducer;