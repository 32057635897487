import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialStateOfFileUpload } from '../../../config/page.config'
import type { RootState } from '../../app/store'
import { IEvidence, IEvidenceList, IFileUpload } from '../../models/app.interface'

export const initialEvidence: IEvidence = {
    id: "",
    referenceNumber: "",
    comments: "",
    approvalsEvidenceType: "",
    temporaryFileList: [initialStateOfFileUpload]
}

const initialState: IEvidenceList = {
    selectedType: '',
    evidenceList: [initialEvidence],
    fileComment: '',
    fileUpload: [initialStateOfFileUpload],
    aboutEvidenceSectionStatus: '',
    deleteEvidenceId: '',
    isFromEvidence: false,
    isFromAddAnotherApprovalEvidence: false,
    evidenceFileSummary:[]
}
export const EvidenceListSlice = createSlice({
    name: 'EvidenceList',
    initialState,
    reducers: {
        setEvidenceListData: (state: IEvidenceList, action: PayloadAction<IEvidenceList>) => {
            state = Object.assign(state, action.payload);
        },
        resetEvidenceListData: (state: IEvidenceList, action: PayloadAction<IEvidenceList>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setEvidenceListData, resetEvidenceListData } = EvidenceListSlice.actions;
export const getEvidenceList = (state: RootState) => state.evidenceList;
export default EvidenceListSlice.reducer;