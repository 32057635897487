import { useEffect } from "react";
import { pageTitles } from "../../config/page.config";
import { updatePageTitle } from "../../Helper/Utility";

const ServiceUnavailable: React.FC = () => {

    useEffect(() => {
        updatePageTitle(pageTitles.serviceUnavailable);
    }, []);

    return (
        <div className="nhsuk-width-container">
            <main
                className="nhsuk-main-wrapper nhsuk-main-wrapper--l"
                id="maincontent"
                role="main"
            >
                <div className="nhsuk-grid-row">
                    <div className="nhsuk-grid-column-two-thirds">
                        <div className="nhsuk-u-padding-bottom-4"></div>
                        <h1>
                            Sorry, the service is temporarily unavailable
                        </h1>
                        <p className="nhsuk-body">
                            DARS Online is currently unavailable whilst we make changes to the user interface. DARS online will be available again at 9:30am Monday 15th January.
                        </p>
                        <p className="nhsuk-body">
                            There is no need for you to do anything and following the change you can continue to use DARS Online as usual to complete and submit applications.
                        </p>
                        <p className="nhsuk-body">
                            Any applications for data, already started or in place, will be moved to the new system.
                        </p>
                        <p className="nhsuk-body">
                            The changes we are making include:
                            <ul>
                                <li>a new NHS look and feel</li>
                                <li>new navigation to make it easier to find and manage applications</li>
                                <li>new application progress indicators</li>
                                <li>a new application layout for working through each section</li>
                                <li>changes to the way questions are worded to make them more understandable</li>
                                <li>new guidance to help explain the questions</li>
                                <li>new document upload functionality, allowing supporting documents to be uploaded within the relevant sections of the application</li>
                            </ul>
                        </p>
                        <p className="nhsuk-body">
                            If you need help, contact NHS England on 0300 303 5678, or email <a href="mailto:enquiries@nhsdigital.nhs.uk">enquiries@nhsdigital.nhs.uk
                            </a>
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ServiceUnavailable;