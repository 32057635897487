import React, { useState } from "react";
import { Button, ErrorSummary, Input, DateInput, Hint } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IApplication, IFileUpload, IFunding } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { dsaContent, dsaControllerErrorMessage, dsaErrorMessage, fileTypeSize, fundingErrorMessage, initialStateOfFileUpload, maxLength, routeName, taskListSectionStatus } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { calculateExampleDate, fetchInterceptor, getCookie, submitSpinner, validateDateValue } from "../../../../Helper/Utility";
import { getFundingDetails, setFundingDetailsData } from "../../../../state/slice/Funding/Funding.slice";
import { SubmitScreenFundingSource_URL, UpdateScreenFundingSource_URL } from "../../../../config/api-endpoints.config";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";

const FundingInformation: React.FC = () => {
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const initialFundingState: IFunding = useAppSelector(getFundingDetails) || {};
    const [fundingData, setfundingData] = useState(initialFundingState);

    /* To dispatch the setFundingDetailsData Redux action */
    const saveDataInStore = (stateData?: IFunding) => {
        dispatch(setFundingDetailsData({ ...fundingData, ...stateData }));
    }

    const [dateExpiresError, setDateExpiresError] = useState("");
    const [diDayError, setDiDayError] = useState(false);
    const [diMonthError, setDiMonthError] = useState(false);
    const [diYearError, setDiYearError] = useState(false);
    const [fundingInfo] = useState(true);
    const [fundingAwardingInstructionLinkError, setFundingAwardingInstructionLinkError] = useState("");
    const [fundingAwardingInstructionError, setFundingAwardingInstructionError] = useState("");
    const [referenceTitleLinkError, setReferenceTitleLinkError] = useState("");
    const [referenceTitleError, setReferenceTitleError] = useState("");
    const customErrorMessage = { customDateMonthYearMissingDateExpired_ErrorMessage: dsaControllerErrorMessage.customDateMonthYearMissing_ErrorMessage };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IFunding = {};
        if (e.target.value !== null && e.target.value !== undefined) {
            updatedState = { ...fundingData, [e.target.name]: e.target.value, isFundingInfoDirty: fundingData.isUploadedSummaryPage ? true : false }
            setfundingData(updatedState);
            saveDataInStore(updatedState);
        }
        resetError(e);
    }
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, createUpdateFundingData);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
   
    const submitData = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "FileList": fundingData.fileUpload,
            "FundingSourceType": fundingData.fundingType,
            "FundingAwardingInstitution": fundingData.fundingAwardingInstruction,
            "ReferenceTitleProjectActivity": fundingData.referenceTitle,
            "YearSubmissionAward": fundingData.yearOfSubmissionDay + "/" + fundingData.yearOfSubmissionMonth + "/" + fundingData.yearOfSubmissionYear,
            "SectionStatus": dsaApplicationData.aboutFundingSectionStatus,
            "Comments": fundingData.comments
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenFundingSource_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                updateFundingDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const updateFundingDetails = (response: string) => {
        let sectionStatus: IApplication = {
            ...dsaApplicationData, aboutFundingSectionStatus: taskListSectionStatus.inProgress
        }
        setDsaApplicationData(sectionStatus);
        saveApplicationDataInStore(sectionStatus);
        let updatedState: IFunding = {};
        updatedState = {
            ...fundingData,
            fileUpload: [initialStateOfFileUpload],
            uploadedFile: fundingData.fileUpload,
            isUploadedSummaryPage: true,
            isFundingInfoDirty: true,
            recentlyAddedFunndingId: response
        }
        setfundingData(updatedState);
        saveDataInStore(updatedState);
        navigate(routeName.uploadDocumentDetails);
    }

    const updateData = (sourceId: string) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "FileList": fundingData.fileUpload,
            "FundingSourceType": fundingData.fundingType,
            "FundingAwardingInstitution": fundingData.fundingAwardingInstruction,
            "ReferenceTitleProjectActivity": fundingData.referenceTitle,
            "YearSubmissionAward": fundingData.yearOfSubmissionDay + "/" + fundingData.yearOfSubmissionMonth + "/" + fundingData.yearOfSubmissionYear,
            "IsFundingSourceDirty": fundingData.IsFundingSourceDirty,
            "IsFundingFileUploadDirty": fundingData.IsFundingFileUploadDirty,
            "IsFundingInfoDirty": fundingData.isFundingInfoDirty,
            "SourceId": sourceId,
            "SectionStatus": dsaApplicationData.aboutFundingSectionStatus,
            "Comments": fundingData.comments,
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(UpdateScreenFundingSource_URL, requestOptions)
            .then(() => {
                updateFundingDatainStore()
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const updateFundingDatainStore = () => {
        let sectionStatus: IApplication = {
            ...dsaApplicationData, aboutFundingSectionStatus: taskListSectionStatus.inProgress
        }
        setDsaApplicationData(sectionStatus);
        saveApplicationDataInStore(sectionStatus);
        let updatedState: IFunding = {};
        let uploadedFiles: IFileUpload[] = [];
        let newlyuploadedFiles: IFileUpload[] = [];
        let oldFiles: IFileUpload[] = [];
        if (fundingData.uploadedFile !== null && fundingData.uploadedFile != undefined) {
            let uploadedFile: IFileUpload[] = Object.assign([], fundingData.uploadedFile);
            if (uploadedFile[0]?.FileName !== "")
                newlyuploadedFiles = [...uploadedFile]
        }
        if (fundingData.fileUpload !== null && fundingData.fileUpload != undefined) {
            let uploadedFile: IFileUpload[] = Object.assign([], fundingData.fileUpload);
            if (uploadedFile[0]?.FileName !== "")
                oldFiles = [...uploadedFile]
        }
        uploadedFiles = [...oldFiles, ...newlyuploadedFiles]
        updatedState = {
            ...fundingData,
            fileUpload: [initialStateOfFileUpload],
            uploadedFile: uploadedFiles,
            isUploadedSummaryPage: true,
            isFundingInfoDirty: true,
        }
        setfundingData(updatedState);
        saveDataInStore(updatedState);
        navigate(routeName.uploadDocumentDetails);
    }

    const createUpdateFundingData = () => {
        if (fundingData.recentlyAddedFunndingId !== undefined && fundingData.recentlyAddedFunndingId != '' && fundingData.isUploadedSummaryPage)
            updateData(fundingData.recentlyAddedFunndingId);
        else if (fundingData.sourceId !== undefined && fundingData.sourceId != '' && fundingData.isUploadedSummaryPage)
            updateData(fundingData.sourceId);
        else {
            submitData();
        }
    }

    const fundingForm: JSX.Element = (
        <>
            <Input
                id={dsaContent.fundingAwardingInstruction}
                name={dsaContent.fundingAwardingInstruction}
                label={dsaContent.lbl_fundingAwardingInstruction}
                error={fundingAwardingInstructionError}
                value={fundingData?.fundingAwardingInstruction}
                onChange={handleChange}
                maxLength={maxLength.awardingInstitution}
            />
            <Input
                id={dsaContent.referenceTitle}
                name={dsaContent.referenceTitle}
                label={dsaContent.lbl_referenceTitle}
                error={referenceTitleError}
                value={fundingData?.referenceTitle}
                onChange={handleChange}
                maxLength={maxLength.referenceAndTitleProject}
            />
            <DateInput id={dsaContent?.yearofSubmissionDateComplete} hint={`For example, ${calculateExampleDate(dsaContent.exampleYearForCurrent, dsaContent.exampleMonthForPast)}`} label={dsaContent.lbl_yearOfSubmission} name={dsaContent?.yearofSubmissionDateComplete} error={dateExpiresError}>
                <DateInput.Day id={dsaContent.yearOfSubmissionDay} name={dsaContent.yearOfSubmissionDay} onChange={handleChange} value={fundingData?.yearOfSubmissionDay} error={diDayError} />
                <DateInput.Month id={dsaContent.yearOfSubmissionMonth} name={dsaContent.yearOfSubmissionMonth} onChange={handleChange} value={fundingData?.yearOfSubmissionMonth} error={diMonthError} />
                <DateInput.Year id={dsaContent.yearOfSubmissionYear} name={dsaContent.yearOfSubmissionYear} onChange={handleChange} value={fundingData?.yearOfSubmissionYear} error={diYearError} />
            </DateInput>
        </>
    );

    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueLength = e.target.value.length;
        if (valueLength >= 1) {
            if (e.target.name === dsaContent.fundingAwardingInstruction) {
                setFundingAwardingInstructionError("");
                setFundingAwardingInstructionLinkError("");
            }
            if (e.target.name === dsaContent.referenceTitle) {
                setReferenceTitleError("");
                setReferenceTitleLinkError("");
            }
            if (e.target.name === dsaContent.yearOfSubmissionDay || e.target.name === dsaContent.yearOfSubmissionMonth || e.target.name === dsaContent.yearOfSubmissionYear) {
                setDateExpiresError("");
                setDiMonthError(false);
                setDiYearError(false);
                setDiDayError(false);
            }
        }
    }

    const ValidateFields = () => {
        let isValid: boolean = true;
        if (!fundingData.fundingAwardingInstruction) {
            setFundingAwardingInstructionError(`${fundingErrorMessage.fundingAwardingInstruction_ErrorMessage}`);
            setFundingAwardingInstructionLinkError(`${fundingErrorMessage.fundingAwardingInstruction_ErrorMessage}`);
            isValid = false;
        }
        if (!fundingData.referenceTitle) {
            setReferenceTitleError(`${fundingErrorMessage.referenceTitle_ErrorMessage}`);
            setReferenceTitleLinkError(`${fundingErrorMessage.referenceTitle_ErrorMessage}`);
            isValid = false;
        }
        let dateValueError = validateDateValue(Number(fundingData.yearOfSubmissionDay), Number(fundingData.yearOfSubmissionMonth), Number(fundingData.yearOfSubmissionYear), false, false, fundingInfo, customErrorMessage);

        if (dateValueError[0] !== "") {
            setDateExpiresError(dateValueError[0]);
            let fields = dateValueError[1].split(",");
            for (let i = 0; i < fields.length; i++) {
                if (fields[i] === dsaContent.dateInputDayField) {
                    setDiDayError(true);
                }
                if (fields[i] === dsaContent.dateInputMonthField) {
                    setDiMonthError(true);
                }
                if (fields[i] === dsaContent.dateInputYearField) {
                    setDiYearError(true);
                }
            }
            isValid = false;
        }
        return isValid;
    }

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href={"#" + dsaContent.fundingAwardingInstruction}>
                            {fundingAwardingInstructionLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href={"#" + dsaContent.referenceTitle}>
                            {referenceTitleLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href={"#" + dsaContent.yearofSubmissionDateComplete}>
                            {dateExpiresError}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <h1>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom ">Funding</span>
                                Funding information
                            </h1>
                            {dateExpiresError || fundingAwardingInstructionError || referenceTitleError
                                ? errorSummary
                                : null}
                            <Hint>Use the text boxes below to provide us with your funding information.</Hint>
                            <div className="nhsuk-input--width-30">{fundingForm}</div>
                            <div className="nhsuk-u-padding-bottom-4"></div>
                            <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>
                                {dsaContent.btnTextContinue}
                            </Button>

                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}
export default FundingInformation;


