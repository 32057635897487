import { Button, DateInput, ErrorSummary, Fieldset, Input, InsetText, Label, Radios, Textarea } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import { GetDSPTLatestStatusList_URL, UpdateScreenDataController_URL } from "../../../../config/api-endpoints.config";
import { dsaContent, dsaControllerErrorMessage, fileTypeSize, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { calculateExampleDate, fetchInterceptor, getCookie, submitSpinner, validateDateValue } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IController, IApplication, IDsptLatestStatus, IDsptLatestStatuses, IControllerList, IApiResDSPTStstus } from "../../../../state/models/app.interface";
import { getControllerListDetails, setControllerListDetailsData } from "../../../../state/slice/ControllerList/ControllerList.slice";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getControllerDetails, setControllerDetailsData } from "../../../../state/slice/DSAAboutController/DSAAboutController.slice";
/*
  Summary: Using this page, user can add the details related to DSPT for Controllers.
*/
const DSPTDetails: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const controllerListDetailsData: IControllerList = useAppSelector(getControllerListDetails);
    const initialControllerListState: IControllerList = controllerListDetailsData || {};
    const [controllerListData] = useState(initialControllerListState);
    const saveControllerListDataInStore = (stateData?: IControllerList) => {
        dispatch(setControllerListDetailsData({ ...controllerListData, ...stateData }));
    }

    const dsaControllerDetailsData: IController = useAppSelector(getControllerDetails);
    const initialState: IController = dsaControllerDetailsData || {};
    const [controllerData, setControllerData] = useState(initialState);
    const saveControllerDataInStore = (stateData?: IController) => {
        dispatch(setControllerDetailsData({ ...controllerData, ...stateData }));
    }

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    const initialStateOfDsptLatestStatuses: IDsptLatestStatuses = {};
    const [dsptLatestStatusesData, setDsptLatestStatusesData] = useState(initialStateOfDsptLatestStatuses);
    const [loading, setLoading] = useState(true);

    const [dsptOdsCodeError, setDsptOdsCodeError] = useState("");
    const [dsptStatusError, setDsptStatusError] = useState("");
    const [datePublishedError, setDatePublishedError] = useState("");
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [diDayError, setDiDayError] = useState(false);
    const [diMonthError, setDiMonthError] = useState(false);
    const [diYearError, setDiYearError] = useState(false);

    let isDirtyController = controllerData.isDirtyController || false;
    /*Variable Declarations*/

    /*Update value in store and reset validation error onChange*/
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedStateofController: IController = {};
        let updatedSAList = { ...controllerData.saList, [e.target.name]: e.target.value };

        updatedStateofController = { ...controllerData, 'saList': updatedSAList, isDirtyController: true }
        setControllerData(updatedStateofController);
        saveControllerDataInStore(updatedStateofController);
        resetError(e);
    }

    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedStateofController: IController = {};
        let updatedSAList= { ...controllerData.saList, [e.target.name]: e.target.value };

        updatedStateofController = { ...controllerData, 'saList': updatedSAList, isDirtyController: true }
        setControllerData(updatedStateofController);
        saveControllerDataInStore(updatedStateofController);
        resetError(e);
    };

    const resetError = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => {
        const valueLength = e.target.value.length;
        if (valueLength >= 1) {
            if (e.target.name === dsaContent.dsptOdsCodeField) {
                setDsptOdsCodeError("");
            }
            if (e.target.name === dsaContent.dateInputDayField || e.target.name === dsaContent.dateInputMonthField || e.target.name === dsaContent.dateInputYearField) {
                setDatePublishedError("");
                setDiYearError(false);
                setDiDayError(false);
                setDiMonthError(false);
            }            
            if (e.target.name === dsaContent.dsptStatusField) {
                setDsptStatusError("");
            }
        }
    }
    /*Update value in store and reset validation error onChange*/

    /*Validate and Submit*/
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (validateFields()) {
            submitSpinner(e, submitDsptDetails);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    const validateFields = () => {
        let isValid: boolean = true;
        if (!(controllerData.saList ? controllerData.saList.DSPT_ODS_Code : null)) {
            setDsptOdsCodeError(`${dsaControllerErrorMessage.dsptOdsCode_ErrorMessage}`);
            isValid = false;
        }
        if (!(controllerData.saList ? controllerData.saList.DSPT_Latest_Status : null)) {
            setDsptStatusError(`${dsaControllerErrorMessage.dsptStatus_ErrorMessage}`);
            isValid = false;
        }
        let dateValueError = validateDateValue(Number(controllerData.saList ? controllerData.saList.DSPTDay : "0"), Number(controllerData.saList ? controllerData.saList.DSPTMonth : "0"), Number(controllerData.saList ? controllerData.saList.DSPTYear : "0"));
        if (dateValueError[0] !== "") {
            setDatePublishedError(dateValueError[0]);
            let fields = dateValueError[1].split(",");
            for (let i = 0; i < fields.length; i++) {
                if (fields[i] === dsaContent.dateInputDayField) {
                    setDiDayError(true);
                }
                if (fields[i] === dsaContent.dateInputMonthField) {
                    setDiMonthError(true);
                }
                if (fields[i] === dsaContent.dateInputYearField) {
                    setDiYearError(true);
                }
            }
            isValid = false;
        }
        return isValid;
    }
    const submitDsptDetails = () => {
        let updatedStateofController: IController = {};
        let updatedSAList = { ...controllerData.saList, DSPT_Date_Published: controllerData.saList ? controllerData.saList.DSPTDay + "/" + controllerData.saList.DSPTMonth + "/" + controllerData.saList.DSPTYear : "" };
        updatedStateofController = { ...controllerData, 'saList': updatedSAList }
        setControllerData(updatedStateofController);
        saveControllerDataInStore(updatedStateofController);
        if (controllerListData.controllerDetailsPage && !controllerData?.isNewController) {
            if (isDirtyController) {
                const parameters: string = JSON.stringify({
                    "UserAuthTokenId": authenticationTokenCookie,
                    "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
                    "DataControllerId": controllerData.controllerId,
                    "SecurityAssurance": updatedSAList,
                    "IsSADirty": true,
                    "SectionStatus": dsaApplicationData.aboutControllerSectionStatus
                });
                const requestOptions: Object = {
                    method: 'POST',
                    headers: { 'Content-Type': fileTypeSize.fileTypeJson },
                    body: parameters
                };
                fetchInterceptor(UpdateScreenDataController_URL, requestOptions)
                    .then(response => {
                            return response.json()
                    })
                    .then(() => {
                        let updatedState: IControllerList = {
                            ...controllerListData, controllerDetailsPage: controllerListData.controllerDetailsPage
                        }
                        let sectionStatus: IApplication = {
                            ...dsaApplicationData, aboutControllerSectionStatus: taskListSectionStatus.inProgress
                        }
                        setDsaApplicationData(sectionStatus);
                        saveApplicationDataInStore(sectionStatus);
                        saveControllerListDataInStore(updatedState);
                        navigate(routeName.controllerDetails);
                    })
                    .catch(() => navigate(routeName.serviceError));
            }
            else { navigate(routeName.controllerDetails); }
        }
        else {
            navigate(routeName.dataProtectionRegistration);
        }
    }
    /*Validate and Submit*/

    /*Get, Set DSPT Status*/
    const getDsptStatus = () => {
        const parameters: string = JSON.stringify(authenticationTokenCookie);
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetDSPTLatestStatusList_URL, requestOptions)
            .then(response => {
                    return response.json()
            })
            .then(data => {
                setDsptStatus(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setDsptStatus = (dsptStatusResponse: IApiResDSPTStstus[]) => {
        let updatedState: IDsptLatestStatuses = {};
        let dsptLatestStatus: IDsptLatestStatus = {};
        let dsptLatestStateList: IDsptLatestStatus[] = [];
        dsptStatusResponse.map((status: IApiResDSPTStstus) => {
            dsptLatestStatus = {
                name: status.Name,
                value: status.Value
            }
            dsptLatestStateList.push(dsptLatestStatus);
        });
        updatedState = {
            dsptLatestStatus: dsptLatestStateList
        }
        setDsptLatestStatusesData(updatedState);
        setLoading(false);
    }
    /*Get, Set DSPT Status*/

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaControllerErrorMessage.selectControllerSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#dsptOdsCode">
                            {dsptOdsCodeError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#dsptStatus">
                            {dsptStatusError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#dsptDatePublished">
                            {datePublishedError}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    useEffect(() => {
        getDsptStatus();
    }, [])

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack/>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                    About the Controller
                                </span>
                                <h1>Data Security and Protection Toolkit details</h1>
                                {dsptOdsCodeError !== "" || dsptStatusError !== "" || datePublishedError ? errorSummary : null}
                                <InsetText>
                                    <Label size="s">Your selected Data Security and Protection Toolkit (DSPT) must match the DSPT information we have on record for your organisation. If these do not match it may lead to a delay in the acceptance of your application. </Label>
                                    <Label size="s">Each department within a university can have a different security policy depending on the type of information they access. Universities can have multiple DSPTs across their organisation, usually split between schools and departments. If the organisation is a university and you are not sure which DSPT to add (and therefore do not know the Organisation's Data Service code), contact your Data Protection Officer (DPO) or Information Governance (IG) team for help.</Label>
                                    <Label size="s">Find the DSPT status and supporting information for the organisation on the <a href="https://www.dsptoolkit.nhs.uk/OrganisationSearch" target="_blank">DSPT organisation search</a> (opens in a new window).</Label>
                                    <Label size="s">If you need further help, email <a href={"mailto:exeter.helpdesk@nhs.net;data.applications@nhsdigital.nhs.uk?subject=DSPT - DARS Online Application Issue " + dsaApplicationData?.referenceNumber}>exeter.helpdesk@nhs.net.</a> The email will open with your NIC number and 'DSPT - DARS Online Application Issue' in the subject field.</Label>
                                    <Label size="s">Selecting the exeter.helpdesk email address will drop the <a href="mailto:data.applications@nhsdigital.nhs.uk">data.applications@nhsdigital.nhs.uk</a> email address in the 'To' field of your email. Keeping the data applications team informed in this way will help to enable a smooth application process.</Label>
                                </InsetText>
                                <Input id="dsptOdsCode" className="nhsuk-input nhsuk-input--width-20"
                                    name={dsaContent.dsptOdsCodeField}
                                    label="Organisation's Data Service (ODS) code"
                                    value={controllerData.saList ? controllerData.saList.DSPT_ODS_Code : ""}
                                    error={dsptOdsCodeError}
                                    onChange={handleChange}
                                />
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <Fieldset>
                                    <Fieldset.Legend>Status</Fieldset.Legend>
                                    <Radios
                                        name="DSPT_Latest_Status"
                                        id="dsptStatus"
                                        hint=""
                                        error={dsptStatusError}
                                    >
                                        {dsptLatestStatusesData.dsptLatestStatus?.map((status: IDsptLatestStatus, index: number = 0) => (
                                            <Radios.Radio
                                                name={dsaContent.dsptStatusField}
                                                key={index + 1}
                                                value={status.value}
                                                onChange={handleChange}
                                                checked={String(controllerData.saList ? controllerData.saList.DSPT_Latest_Status : "") === String(status.value)}
                                            >
                                                {status.name}
                                            </Radios.Radio>
                                        ))
                                        }
                                    </Radios>
                                </Fieldset>
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <DateInput id="dsptDatePublished" hint={`For example, ${calculateExampleDate(dsaContent.exampleYearForPast)}`} label="Date published" name="DSPT_Date_Published" error={datePublishedError}>
                                    <DateInput.Day id={dsaContent.dateInputDayField} name={dsaContent.dateInputDayField} onChange={handleChange} value={controllerData.saList ? controllerData.saList.DSPTDay : ""} error={diDayError} />
                                    <DateInput.Month id={dsaContent.dateInputMonthField} name={dsaContent.dateInputMonthField} onChange={handleChange} value={controllerData.saList ? controllerData.saList.DSPTMonth : ""} error={diMonthError} />
                                    <DateInput.Year id={dsaContent.dateInputYearField} name={dsaContent.dateInputYearField} onChange={handleChange} value={controllerData.saList ? controllerData.saList.DSPTYear : ""} error={diYearError} />
                                </DateInput>
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <Textarea
                                    label="Additional DSPT submission (optional)"
                                    hint="If there is more than one school or department within the university that will be involved in the agreement, you must add them to the application. Provide details in the below text box."
                                    id="additional-detail"
                                    name="DSPT_Comments"
                                    rows={5}
                                    onChange={handleChangeTextarea}
                                    defaultValue={controllerData.saList ? controllerData.saList.DSPT_Comments: ""}
                                />
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>
                        }
                    </div>
                </main>
            </div>
        </>
    );
}

export default DSPTDetails;