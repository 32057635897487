import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { IDSFCContractDetails } from '../../models/app.interface'
import { IDSFCCustomerSignatory } from '../../models/app.interface'
const initialStateOfSignatory: IDSFCCustomerSignatory = {
    signatoryId: "",
    fullName: "",
    emailAddress: ""
}
const initialState: IDSFCContractDetails = {
    isICO: false,
    dsfcApplicationId: "",
    contractNumber: "",
    contractDuration: "",
    version: "",
    organisation: "",
    organisationAddress: "",
    companiesHouseICO: "",
    alternativeOrganisationName: "",
    alternativeStreet1: "",
    alternativeStreet2: "",
    alternativeStreet3: "",
    alternativeCity: "",
    alternativeCounty: "",
    alternativePostCode: "",
    alternativeCountry: "",
    signatoryDetalis: [initialStateOfSignatory],
    customerSignatory: "",
    emailAddress: "",
    dashboard: false,
    dsfcintro: false,
    dsfcdetails: false,
    dsfcsignatory: false,
    dsfcpreview: false,
    dsfcsubmit: false,
    dsfcapprove: false,
    dsfcagree: false,
    startDate: "",
    endDate: "",
    editionName: "",
    redirectionURL: "",
    versionTemplateFileURL: "",
    isRenewal: false,
    isFullySigned: false
}
export const DSFCContractDetailsSlice = createSlice({
    name: 'dsfcContractDetails',
    initialState,
    reducers: {
        setDSFCContractDetailsData: (state: IDSFCContractDetails, action: PayloadAction<IDSFCContractDetails>) => {
            state = Object.assign(state, action.payload);
        },
        resetDSFCContractDetailsData: (state: IDSFCContractDetails, action: PayloadAction<IDSFCContractDetails>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});
export const { setDSFCContractDetailsData, resetDSFCContractDetailsData } = DSFCContractDetailsSlice.actions;
export const getDSFCContractDetails = (state: RootState) => state.dsfcContractDetails;
export default DSFCContractDetailsSlice.reducer;