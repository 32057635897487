import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Radios, Hint } from "nhsuk-react-components";
import { dsaContent, fileTypeSize, initialStateOfFileUpload, participantErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader'
import ErrorDetails from '../../../../Components/Shared/ErrorSummary';
import { fetchInterceptor, getCookie, submitSpinner } from '../../../../Helper/Utility';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { AppDispatch } from '../../../../state/app/store';
import Loader from "../../../../Components/Loader/Loader";
import GoBack from "../../../../Components/Shared/GoBack";
import { IApplication, IFileUpload, IParticipant, IParticipantContributor, IParticipantLegalBasis } from "../../../../state/models/app.interface";
import { DeleteCohortContributor_URL } from "../../../../config/api-endpoints.config";
import { getParticipantDetails, setParticipantDetailsData } from "../../../../state/slice/Participant/Participant.slice";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";

const DeleteParticipant: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [deleteValue, setDeleteValue] = useState('');
    const [participantsErrorMessage, setParticipantErrorMessage] = useState("");
    const participantDetailsData: IParticipant = useAppSelector(getParticipantDetails);
    const initialState: IParticipant = participantDetailsData || {};
    const [participantData] = useState(initialState);
    /* To dispatch the setParticipantDetailsData Redux action */
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantData, ...stateData }));
    }

    const getDisplayName = () => {
        const participantDataList = participantData?.participantContributorList;

        return (participantDataList && participantDataList.length > 0)
            ? participantDataList.filter(x => x.id === participantData.deleteParticipantId).map(data => {
                return data.firstName + ' ' + data.lastName
            }) : "";
    }

    /*Delete section*/
    const initialParticipantContributor: IParticipantContributor = {
        id: "",
        firstName: "",
        lastName: "",
        role: "",
        organisationName: "",
        email: "",
        makeValidation: "",
        mobile: 0,
        organisation: "",
        organisationId: "",
        userType: 0
    }

    const initialParticipantLegalBasis: IParticipantLegalBasis = {
        value: "",
        crmId: "",
        text: "",
        checked: false,
        name: "",
        availableOnline: false,
        currentlyCheckedInCRM: false,
        displayDissemination: false,
        displayProcessing: false,
        dissemination: false,
        notes: "",
        orderBy: 0,
        otherComments: "",
        otherLegalBasis: false,
        processing: false,
        productLegalBasis: false,
    }

    const resetParticipantData = () => {
        let updatedState: IParticipant = {}
        updatedState = {
            participantTypes: "",
            aboutParticipantSectionStatus: "",
            legalBasis_Other: "",
            dataProviderFirstName: "",
            dataProviderLastName: "",
            dataProviderRole: "",
            dataProviderOrganisationName: "",
            dataProviderOrganisationId: "",
            dataProviderEmail: "",
            dataProviderConfirmEmail:"",
            participantContributorList: [initialParticipantContributor],
            participantLegalBasisList: [initialParticipantLegalBasis],
            additionalLinkageCohortInformation: "",
            addressDate: false,
            applicationId: "",
            dataAsAtDate: false,
            dataProductionSubmissionSelected: 0,
            dataSetLegalBasisList: [initialParticipantLegalBasis],
            dataSetSelectedLegalBasisOtherText: "",
            dateOfBirth: false,
            dateOfDeath: false,
            emailAddress: false,
            existingAgreementUploadItems: [""],
            forename: false,
            gpPracticeCode: false,
            howManyValidationCredits: 0,
            journals: [initialParticipantContributor],
            journalToAdd: "",
            manualCohortValidation: false,
            maximumSize: 0,
            minimumSizeCohortValidationRate: 0,
            mobilePhoneNumber: false,
            nhsNumber: false,
            ongoingRecruitment: false,
            otherGivenName: false,
            postcode: false,
            productionCommence: "",
            refIfUsingAnotherExistingAgreement: "",
            sex: false,
            studyId: false,
            surname: false,
            telephoneNumber: false,
            typeOfCohort: 0,
            userAuthTokenId: "",
            participantcheckYourAnswerPage: '',
            fileUploadConsent: [initialStateOfFileUpload],
            fileUploadNHS: [initialStateOfFileUpload],
            fileCommentConsent: '',
            fileCommentNHS: '',
            isFromParticipant: false,
            isParticipantProvidingData: false,
            participantProvidingData: '',
            deleteParticipantId: '',
        }
        saveDataInStore(updatedState);
    }
    
    const handleDelete = (deleteParticipantId: string) => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "Id": deleteParticipantId,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "SectionStatus": dsaApplicationData.aboutParticipantSectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(DeleteCohortContributor_URL, requestOptions)
            .then(() => {
                let sectionStatus: IApplication = {
                    ...dsaApplicationData, aboutParticipantSectionStatus: taskListSectionStatus.inProgress
                }
                setDsaApplicationData(sectionStatus);
                saveApplicationDataInStore(sectionStatus);
                    if (participantData.participantContributorList!.length > 1) {
                        resetParticipantData();
                        navigate(routeName.addCohortContributor);
                    }
                    else {
                        resetParticipantData();
                        navigate(routeName.whoWillProvide);
                    }
                
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const handleNavigation = () => {
        if (deleteValue === dsaContent.yes) {
            handleDelete(participantData?.deleteParticipantId!);
        } else {
            navigate(routeName.addCohortContributor);
        }
}

    const handleDeleteContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateDeleteFields();
        if (isValid) {
            submitSpinner(e, handleNavigation);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const validateDeleteFields = () => {
        let isValid: boolean = true;

        if (deleteValue === '') {
            setParticipantErrorMessage(`${participantErrorMessage.deleteParticipant_ErrorMessage}${getDisplayName()}?'`);
            isValid = false;
        }
        return isValid;
    }

    const resetDeleteError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setParticipantErrorMessage("");
        }
    }

    const handleChangeDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteValue(e.target.value)
        resetDeleteError(e)
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} /> :
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    About the Participants</span>
                                    Are you sure you want to delete Participant Data Provider {getDisplayName()}?
                                </h1>
                                {participantsErrorMessage !== "" && <ErrorDetails errorDescription={participantsErrorMessage} errorHref="#deleteparticipant"></ErrorDetails>}
                                <Radios
                                    name="deleteparticipant"
                                    id="deleteparticipant"
                                    error={participantsErrorMessage}
                                >
                                    <Radios.Radio value={dsaContent.yes} onChange={handleChangeDelete} >
                                        {dsaContent.yes}
                                        <Hint>If you select yes, all details for this Participant Data Provider will be removed.</Hint>
                                    </Radios.Radio>
                                    <Radios.Radio value={dsaContent.no} onChange={handleChangeDelete}>{dsaContent.no}</Radios.Radio>
                                </Radios>
                                <div className="nhsuk-u-padding-bottom-8"></div>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleDeleteContinue}>{dsaContent.btnTextContinue}</Button>

                            </div>
                        }
                    </div>
                </main>
            </div>
        </>
    )
}

export default DeleteParticipant;
