import { Button, Details, InsetText, SummaryList, Table, Tag } from "nhsuk-react-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetActiveAndExpiredDSAList_URL } from "../../../config/api-endpoints.config";
import { applicationStatus, dsaContent, fileTypeSize, pageTitles, routeName } from "../../../config/page.config";
import { fetchInterceptor, getCookie, getDate, updatePageTitle } from "../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../state/app/hooks";
import { AppDispatch } from "../../../state/app/store";
import { IApiResActiveAgreementList, IApiResActiveAgreementDetails, IApplication, IHolder, ITaskEnableSectionMultiSelect, IUserDetails } from "../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData, setDSADetailsData } from "../../../state/slice/DSA/DSA.slice";
import { getUserDetails } from "../../../state/slice/Header/Header.slice";

const ActiveDSA: React.FC = () => {

    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;

    const UserDetailsData: IUserDetails = useAppSelector(getUserDetails);
    const initialStateofUserData: IUserDetails = UserDetailsData || {};
    const [userData, setUserData] = useState(initialStateofUserData);

    const dsaAgreementDetails: IApplication = useAppSelector(getDSADetails);
    const initialAgreementState: IApplication = dsaAgreementDetails || {};
    const [dsaAgreementData, setDSAAgreementData] = useState(initialAgreementState);

    const initialState: IHolder = {};
    const [dsaData, setDSAData] = useState(initialState);
    const { dsaApproverSecurity, dsaApplicantSecurity } = useAppSelector(getUserDetails);

    const [hasActiveDSAs, setHasActiveDSAs] = useState(false);

    const [showDSAs, setShowDSAs] = useState(false);

    const saveDSADataInStore = (stateData?: IHolder) => {
        dispatch(setDSADetailsData({ ...dsaData, ...stateData }));
    }

    const resetdataInStore = (stateData?: IHolder) => {
        dispatch(setDSADetailsData({ ...dsaData, ...stateData }));
    }

    const saveDSAAgreementDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaAgreementData, ...stateData }));
    }

    const resetDSAAgreementdataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaAgreementData, ...stateData }));
    }
    const resetStoreData = () => {
        let updatedState: IHolder = {};
        updatedState = {
            ...dsaData,
            applicationDetails: [{}],
            existingApplicationDetails: [{}],
            activeAgreementDetails: [{}],
            id: "",
            referenceNumber: ""
        }
        resetdataInStore(updatedState);
        let updatedAppState: IApplication = {};
        updatedAppState = {
            ...dsaAgreementData,
            id: "",
            referenceNumber: "",
            title: "",
            status: "",
            startDate: "",
            endDate: "",
            firstSubmitDate: "",
            aboutControllerSectionStatus: "",
            aboutProcessorSectionStatus: "",
            aboutDataSetSectionStatus: "",
            commercialSublicensingSectionStatus: "",
            section: [{}],
            aboutParticipantSectionStatus: "",
            aboutFundingSectionStatus: "",
            aboutPrivacyNoticeSectionStatus: "",
            aboutEthicsApprovalSectionStatus: "",
            aboutProcessingLocationSectionStatus: "",
            aboutStorageLocationSectionStatus: "",
            aboutExpectedOutputsAndBenefitsSectionStatus: "",
            aboutObjectiveAndProcessingSectionStatus: "",
            aboutYieldedBenefitsSectionStatus:"",
            projectTitleReasons: "",
            duration: 0,
            numberOfSignatures: 0,
            numberOfSignees: 0,
            dataControllerId: "",
            aboutEvidenceSectionStatus: "",
            aboutSubmitApplicationSectionStatus: "",
            applicationChangeType: "",
            taskEnableSectionMultiSelect: [initialStateOfTaskEnable],
            applyingForData: false,
            organisationsInvolved: false,
            dataAndParticipant: false,
            returningJourneyType: ""
        }
        resetDSAAgreementdataInStore(updatedAppState);
    };
    const initialStateOfTaskEnable: ITaskEnableSectionMultiSelect = {
        value: 0
    }
    const getExistingDSADetails = () => {
        const parameters: string = JSON.stringify(authenticationTokenCookie);

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetActiveAndExpiredDSAList_URL, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                setDSADetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    };

    const setDSADetails = (response: IApiResActiveAgreementDetails) => {
        const dsaList = response;
        let updatedState: IHolder = {};

        let dsaActiveAgreementDetail: IApplication = {};
        let dsaActiveAgreementDetailsList: IApplication[] = [];
        if (response.ActiveAgreementList.length > 0) {
            dsaList.ActiveAgreementList.map((dsaActiveAgreement: IApiResActiveAgreementList) => {
                dsaActiveAgreementDetail = {
                    id: dsaActiveAgreement.Id,
                    referenceNumber: dsaActiveAgreement.ReferenceNumber,
                    title: dsaActiveAgreement.ApplicationTitle,
                    endDate: getDate(dsaActiveAgreement.DsaEndDate),
                    startDate: getDate(dsaActiveAgreement.DsaStartDate),
                    status: applicationStatus[dsaActiveAgreement?.ApplicationStatusCustom!],
                    firstSubmitDate: getDate(dsaActiveAgreement.CustomerSubmittedDate),
                    applicationChangeType: dsaActiveAgreement.ApplicationChangeType,
                    isCurrent: dsaActiveAgreement.IsCurrent
                }
                dsaActiveAgreementDetailsList.push(dsaActiveAgreementDetail);
            })

            updatedState = {
                ...dsaData,
                activeAgreementDetails: dsaActiveAgreementDetailsList
            }
            setDSAData(updatedState);
            saveDSADataInStore(updatedState);
            setHasActiveDSAs(true);
        }
        setShowDSAs(true);

    }

    const setStatusTag = (status: string) => {
        switch (status) {
            case applicationStatus[1008]:
                return (
                    <Tag>{status.replace(/&nbsp;/g, ' ')}</Tag>
                );
            case applicationStatus[1009]:
                return (
                    <Tag color="orange">{status.replace(/&nbsp;/g, ' ')}</Tag>
                );
            case applicationStatus[1010]:
                return (
                    <Tag color="grey">{status.replace(/&nbsp;/g, ' ')}</Tag>
                );
        }
    }

    const navigateToBeforeYouStart = () => {
        navigate(routeName.dsaBeforeYouStart);
    }

    const manageDSALink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, applicationId: string, referenceNumber: string, applicationChangeValue?: string) => {
        e.preventDefault();
        let updatedState: IApplication = {};
        updatedState = {
            ...dsaAgreementData,
            id: applicationId,
            referenceNumber: referenceNumber,
            applicationChangeType: "",
            returningFromExtendRenewAmend: "",
            isFromApplyDsa: false
        }
        setDSAAgreementData(updatedState);
        saveDSAAgreementDataInStore(updatedState);
        navigate(routeName.managedsa);
    }

    const openDSALink = (e: React.MouseEvent<HTMLAnchorElement>, applicationId: string, referenceNumber: string, title: string, endDate: string, startDate: string) => {
        e.preventDefault();
        let updatedState: IApplication = {};
        updatedState = {
            ...dsaAgreementData,
            id: applicationId,
            referenceNumber: referenceNumber,
            title: title,
            endDate: endDate,
            startDate: startDate,
        }
        setDSAAgreementData(updatedState);
        saveDSAAgreementDataInStore(updatedState);
        navigate(routeName.downloadActiveDsa);
    }

    useEffect(() => {
        resetStoreData();
        getExistingDSADetails();
        setUserData(UserDetailsData);
    }, [UserDetailsData]);

    useEffect(() => {
        updatePageTitle(pageTitles.activedsa);
    }, []);

    return (
        <div className="nhsuk-width-container">
            <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                <div className="nhsuk-grid-row">
                    {!showDSAs ?
                        <div className="nhsuk-grid-column-full">
                            <div className="nhsuk-loader__container">
                                <h1 className="nhsuk-heading-l">Loading results</h1>
                            </div>
                            <div className="nhsuk-loader__container">
                                <div className="nhsuk-loader">
                                </div>
                            </div>
                        </div>
                        : (hasActiveDSAs ?
                            <div className="nhsuk-width-container">
                                <div className="nhsuk-u-padding-bottom-4"></div>

                                <h1> <span className="nhsuk-caption-l nhsuk-caption--bottom">{userData?.userOrgName}</span>
                                    Manage Data Sharing <br/> Agreements
                                </h1>
                                <div className="nhsuk-hint">
                                    <p>Select the &apos;Manage&apos; link to create an extension, renewal and amendment application for that DSA.</p>
                                    <p>Once created you can manage extension, renewal and amendment applications on the &apos;Manage applications&apos; page.</p>
                                </div>
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <Table responsive>
                                    <Table.Head>
                                        <Table.Row>
                                            <Table.Cell>Reference number</Table.Cell>
                                            <Table.Cell>Title</Table.Cell>
                                            <Table.Cell>First submitted</Table.Cell>
                                            <Table.Cell>Expiry date</Table.Cell>
                                            <Table.Cell>Status</Table.Cell>
                                            <Table.Cell>Action</Table.Cell>
                                        </Table.Row>
                                    </Table.Head>
                                    <Table.Body>
                                        {
                                            dsaData.activeAgreementDetails?.map((dsaActiveAgreementDetail: IApplication, index: number) => (
                                                <Table.Row key={index + 1} id={dsaActiveAgreementDetail?.id}>
                                                    <Table.Cell>{dsaActiveAgreementDetail?.referenceNumber ? dsaActiveAgreementDetail?.referenceNumber : '-'}</Table.Cell>
                                                    <Table.Cell className="nhsuk-word-break">{dsaActiveAgreementDetail?.title ? dsaActiveAgreementDetail?.title : '-'}</Table.Cell>
                                                    <Table.Cell>{dsaActiveAgreementDetail?.firstSubmitDate ? dsaActiveAgreementDetail?.firstSubmitDate : '-'}</Table.Cell>
                                                    <Table.Cell>{dsaActiveAgreementDetail?.endDate ? dsaActiveAgreementDetail?.endDate : '-'}</Table.Cell>
                                                    <Table.Cell className="nhsuk-u-nowrap">{setStatusTag(dsaActiveAgreementDetail?.status!)}</Table.Cell>
                                                    <Table.Cell className="nhsuk-u-text-align-right">
                                                        <a href="#" className="nhsuk_downloadOnclick" onClick={(e) => openDSALink(e, dsaActiveAgreementDetail?.id!, dsaActiveAgreementDetail?.referenceNumber!, dsaActiveAgreementDetail?.title!, dsaActiveAgreementDetail?.endDate!, dsaActiveAgreementDetail?.startDate!)}>Download&nbsp;(PDF)</a>
                                                        <br />
                                                        {dsaActiveAgreementDetail.isCurrent
                                                            ? <a href="#" onClick={(e) => manageDSALink(e, dsaActiveAgreementDetail?.id!, dsaActiveAgreementDetail?.referenceNumber!, dsaActiveAgreementDetail?.applicationChangeType)}>Manage</a>
                                                            : ""}

                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                                <div className="nhsuk-u-padding-bottom-4"></div>                               

                                <Details>
                                    <Details.Summary>What do these statuses mean?</Details.Summary>
                                    <Details.Text>
                                        <SummaryList>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag>{applicationStatus[1008]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>You have an active Data Sharing Agreement (DSA)</SummaryList.Value>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag color="orange">{applicationStatus[1009]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Your agreement is due to expire</SummaryList.Value>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag color="grey">{applicationStatus[1010]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Your agreement has expired</SummaryList.Value>
                                            </SummaryList.Row>
                                        </SummaryList>
                                    </Details.Text>
                                </Details>
                            </div> :
                            <div className="nhsuk-width-container">

                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <h1>
                                    Active Data Sharing <br /> Agreements </h1>
                                <InsetText>
                                    <p>
                                        It looks like you do not currently have any active Data Sharing Agreements (DSAs). If you think this is wrong, email <a href='mailto:data.applications@nhsdigital.nhs.uk'>data.applications@nhsdigital.nhs.uk</a> quoting 'Active DSA', along with your DSA reference number in the subject field. A DSA reference number usually begins with 'DARS-NIC'. </p>
                                    <p>
                                        New applications for data can be found in 'Manage applications'.
                                    </p>
                                </InsetText>
                                <div className="nhsuk-u-padding-bottom-7"></div>                                
                            </div>)}
                </div>
            </main>
        </div>
    )
}
export default ActiveDSA;