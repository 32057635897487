import React, { useState, useEffect, useRef } from "react";
import "./Sticky.css";
import { debounce } from "../../Helper/Utility";

interface StickyProps {
  children: React.ReactChild;
  stickFrom?: number;
}

const Sticky: React.FunctionComponent<StickyProps> = ({ children, stickFrom = 200 }) => {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    window.addEventListener("scroll", debounce(handleScroll));
    return () => {
        window.removeEventListener("scroll", () => debounce(handleScroll));
    };
  }, []);

  const handleScroll = () => {
    if (ref && ref.current && ref.current.getBoundingClientRect()) {
      setSticky(
        window.pageYOffset > stickFrom &&
          window.innerHeight + window.pageYOffset <= ref.current.offsetTop
      );
    }
  };

  return (
    <div className={`nhsuk-sticky_wrapper ${isSticky ? "nhsuk-sticky" : ""}`} ref={ref}>
      <div className="nhsuk-sticky_inner">{children}</div>
    </div>
  );
};

export default Sticky;
