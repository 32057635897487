import React, {useRef, useState } from 'react';
import {expandCollapse } from '../../config/page.config';

interface expandCollapseProps {
    content: string,
    charLimit?: number
}

const ExpandCollapse: React.FC<expandCollapseProps> = ({ content, charLimit = expandCollapse.defaultCharLimit }) => {
    const [isExpand, setIsExpand] = useState(false);
    const ref = useRef<null | HTMLDivElement>(null); 

    const toggleText = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setIsExpand(!isExpand);
        ref.current!.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div ref={ref}>
            {(content!.length > charLimit) ?
                <><p className="nhsuk-whitepre-wrap">{isExpand ? content : content?.substring(0, charLimit) + '...'}</p>
                    <a onClick={toggleText} href="#" className="nhsuk-expend-collapse">{isExpand ? expandCollapse.hide : expandCollapse.show}</a></>
                : <p className="nhsuk-whitepre-wrap">{content}</p>}
        </div>)
}
export default ExpandCollapse;