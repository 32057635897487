const PageNotFound: React.FC = () => {
  return (
    <div className="nhsuk-width-container">
      <main
        className="nhsuk-main-wrapper nhsuk-main-wrapper--l"
        id="maincontent"
        role="main"
      >
        <div className="nhsuk-grid-row">
          <div className="nhsuk-grid-column-two-thirds">
            <div className="nhsuk-u-padding-bottom-4"></div>

            <h1>Page not found</h1>
            <p className="nhsuk-body">
              If you typed the web address, check it is correct.
            </p>
            <p className="nhsuk-body">
              If you pasted the web address, check you copied the entire
              address.
            </p>
            <p className="nhsuk-body">
              If the web address is correct or you selected a link or button,
              contact NHS England on 0300 303 5678, or email{" "}
              <a href="mailto:enquiries@nhsdigital.nhs.uk">
                enquiries@nhsdigital.nhs.uk
              </a>
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PageNotFound;
