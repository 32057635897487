const API_VERSION = process.env.REACT_APP_API_VERSION;
const DARS_API_HOST = process.env.REACT_APP_DARS_API_HOST === undefined ? '/' : (process.env.REACT_APP_DARS_API_HOST + '/');
export const GetOauthToken_URL = process.env.REACT_APP_GET_OAUTH_TOKEN_URL || '';
export const GetApplicationReason_URL = `${DARS_API_HOST}weatherforecast/GetApplicationReasonOptions`;
export const GetWeather_URL = `${DARS_API_HOST}weatherforecast`;
export const DSFCIntroSubmit_URL = `${DARS_API_HOST}api/dsfcapplication/SubmitScreenDSFCIntro`;
export const AddressDetailsSubmit_URL = `${DARS_API_HOST}api/dsfcapplication/SubmitScreenDSFCAddress`;
export const SubmitCustomerSignatory_URL = `${DARS_API_HOST}api/dsfcapplication/SubmitCustomerSignatory`;
export const SubmitDSFC_URL = `${DARS_API_HOST}api/dsfcapplication/SubmitDSFC`;
export const DisplayDSFCFile_URL = `${DARS_API_HOST}api/file/DisplayDSFCFile`;
export const LoadScreenDSFCApproval_URL = `${DARS_API_HOST}api/dsfcapplication/LoadScreenDSFCApproval`;
export const SubmitDSFCApproval_URL = `${DARS_API_HOST}api/dsfcapplication/SubmitDSFCApproval`;
export const LoadScreenDSFC_URL = `${DARS_API_HOST}api/dsfcapplication/LoadScreenDSFC`;
export const UploadFile_URL = `${DARS_API_HOST}api/values/UploadFile`;
export const DSFCList_URL = `${DARS_API_HOST}api/dashboard/GetDSFCList`;
export const GetNewAndExistingDSAList_URL = `${DARS_API_HOST}api/dashboard/GetNewAndExistingDSAList`;
export const GetUserDetails_URL = `${DARS_API_HOST}api/dashboard/GetUserDetails`;
export const SubmitScreenDSAIntro_URL = `${DARS_API_HOST}api/application/SubmitScreenDSAIntro`;
export const GetActiveAndExpiredDSAList_URL = `${DARS_API_HOST}api/dashboard/GetActiveAndExpiredDSAList`;
export const GetDSACount_URL = `${DARS_API_HOST}api/dashboard/GetDSACount`;
export const GetDataController_URL = `${DARS_API_HOST}api/application/GetDataController`;
export const DisplayDSAFile_URL = `${DARS_API_HOST}api/file/DisplayDSAFile`;
export const GetDocumentSession_URL = `${DARS_API_HOST}api/file/GetDocumentSession`;
export const SubmitDSASectionStatus_URL = `${DARS_API_HOST}api/application/SubmitDSASectionStatus`;
export const GetDSPTLatestStatusList_URL = `${DARS_API_HOST}api/application/GetDSPTLatestStatusList`;
export const OrganisationLookup_URL = `${DARS_API_HOST}api/application/OrganisationLookup`;
export const SubmitScreenDSFCRenewal_URL = `${DARS_API_HOST}api/dsfcapplication/SubmitScreenDSFCRenewal`;
export const SubmitScreenDataController_URL = `${DARS_API_HOST}api/application/SubmitScreenDataController`;
export const GetDataControllerCount_URL = `${DARS_API_HOST}api/application/GetDataControllerCount`;
export const DeleteDSAChildRecord_URL = `${DARS_API_HOST}api/application/DeleteDSAChildRecord`;
export const UpdateScreenDataController_URL = `${DARS_API_HOST}api/application/UpdateScreenDataController`;
export const GetDSAFileById_URL = `${DARS_API_HOST}api/application/GetDSAFileById`;
export const DeleteDocument_URL = `${DARS_API_HOST}api/application/DeleteDocument`;
export const UpdateScreenDataProcessor_URL = `${DARS_API_HOST}api/application/UpdateScreenDataProcessor`;
export const SubmitScreenDataProcessor_URL = `${DARS_API_HOST}api/application/SubmitScreenDataProcessor`;
export const GetDataProcessorFileById_URL = `${DARS_API_HOST}api/application/GetDataProcessorFileById`;
export const DeleteDataProcessor_URL = `${DARS_API_HOST}api/application/DeleteDataProcessor`;
export const GetDataProcessor_URL = `${DARS_API_HOST}api/application/GetDataProcessor`;
export const GetDataProcessorCount_URL = `${DARS_API_HOST}api/application/GetDataProcessorCount`;
export const SubmitScreenDSADate_URL = `${DARS_API_HOST}api/application/SubmitScreenDSADate`;
export const GetProductList_URL = `${DARS_API_HOST}api/application/GetProductList`; 
export const SubmitScreenProductList_URL = `${DARS_API_HOST}api/application/SubmitScreenProductList`; 
export const GetProductFields_URL = `${DARS_API_HOST}api/application/GetProductFields`; 
export const SubmitScreenProduct_DataDuration_URL = `${DARS_API_HOST}api/application/SubmitScreenProduct_DataDuration`;
export const GetAssociatedProductList_URL = `${DARS_API_HOST}api/application/GetAssociatedProductList`; 
export const CheckAssociatedProductExist_URL = `${DARS_API_HOST}api/application/CheckAssociatedProductExist`; 
export const ApproveApplication_URL = `${DARS_API_HOST}api/application/ApproveApplication`;
export const ScreenProduct_DataDuration_URL = `${DARS_API_HOST}api/application/ScreenProduct_DataDuration`;
export const CheckCohortExist_URL = `${DARS_API_HOST}api/application/CheckCohortExist`;
export const GetCohortData_URL = `${DARS_API_HOST}api/application/GetCohortData`; 
export const SubmitScreenCohortContributor_URL = `${DARS_API_HOST}api/application/SubmitScreenCohortContributor`; 
export const SubmitScreenCohortType_URL = `${DARS_API_HOST}api/application/SubmitScreenCohortType`; 
export const SubmitScreenCohortLegalBases_URL = `${DARS_API_HOST}api/application/SubmitScreenCohortLegalBases`;
export const DeleteCohortContributor_URL = `${DARS_API_HOST}api/application/DeleteCohortContributor`;
export const SubmitScreenProduct_URL = `${DARS_API_HOST}api/application/SubmitScreenProduct`; 
export const GetApplicationReasonList_URL = `${DARS_API_HOST}api/application/GetApplicationReasonList`;
export const SubmitScreenFundingSource_URL = `${DARS_API_HOST}api/application/SubmitScreenFundingSource`;
export const GetFundingSources_URL = `${DARS_API_HOST}api/application/GetFundingSources`;
export const GetDSADetails_URL = `${DARS_API_HOST}api/application/GetDSADetails`;
export const CheckFundingSourceExist_URL = `${DARS_API_HOST}api/application/CheckFundingSourceExist`;
export const UpdateScreenFundingSource_URL = `${DARS_API_HOST}api/application/UpdateScreenFundingSource`;
export const CheckServiceMaintenanceStatus_URL = `${DARS_API_HOST}api/dashboard/CheckServiceMaintenanceStatus`;
export const SubmitScreenDSADetails_URL = `${DARS_API_HOST}api/application/SubmitScreenDSADetails`;
export const SubmitScreenCohortData_URL = `${DARS_API_HOST}api/application/SubmitScreenCohortData`;
export const SubmitScreenEvidenceCreate_URL = `${DARS_API_HOST}api/application/SubmitScreenEvidenceCreate`;
export const SubmitScreenOutputsAndBenefits_URL = `${DARS_API_HOST}api/application/SubmitScreenOutputsAndBenefits`;
export const GetOutputsAndBenefits_URL = `${DARS_API_HOST}api/application/GetOutputsAndBenefits`
export const GetEvidenceList_URL = `${DARS_API_HOST}api/application/GetEvidenceList`;
export const GetProductLegalCover_URL = `${DARS_API_HOST}api/application/GetProductLegalCover`;
export const GetObjectiveAndProcessing_URL = `${DARS_API_HOST}api/application/GetObjectiveAndProcessing`;
export const GetYieldedBenefits_URL = `${DARS_API_HOST}api/application/GetYieldedBenefits`;
export const SubmitScreenObjectiveAndProcessing_URL = `${DARS_API_HOST}api/application/SubmitScreenObjectiveAndProcessing`;
export const SubmitScreenYieldedBenefits_URL = `${DARS_API_HOST}api/application/SubmitScreenYieldedBenefits`;
export const GetEthicsApproval_URL = `${DARS_API_HOST}api/application/GetEthicsApproval`;
export const SubmitEthicsApproval_URL = `${DARS_API_HOST}api/application/SubmitEthicsApproval`;
export const SubmitScreenCommonLawDuty_URL = `${DARS_API_HOST}api/application/SubmitScreenCommonLawDuty`;
export const SubmitScreenPrivacyNotice_URL = `${DARS_API_HOST}api/application/SubmitScreenPrivacyNotice`;
export const GetPrivacyNotice_URL = `${DARS_API_HOST}api/application/GetPrivacyNotice`;
export const SubmitDSA_URL = `${DARS_API_HOST}api/application/SubmitDSA`;
export const SubmitScreenCommercialPurposeAndSublicensing_URL = `${DARS_API_HOST}api/application/SubmitScreenCommercialPurposeAndSublicensing`;
export const GetCommercialPurposeAndSublicensing_URL = `${DARS_API_HOST}api/application/GetCommercialPurposeAndSublicensing`;
export const UpdateApplicationChangeType_URL = `${DARS_API_HOST}api/application/UpdateApplicationChangeType`;
export const SubmitDSATaskEnableSection_URL = `${DARS_API_HOST}api/application/SubmitDSATaskEnableSection`;
export const CloseDSAApplication_URL = `${DARS_API_HOST}api/application/CloseDSAApplication`;
export const GetProcessingStorageLocations_URL = `${DARS_API_HOST}api/application/GetProcessingStorageLocations`;
export const SubmitProcessingStorageLocation_URL = `${DARS_API_HOST}api/application/SubmitProcessingStorageLocation`;
export const DisplayApprovedDSFCFile_URL = `${DARS_API_HOST}api/file/DisplayApprovedDSFCFile`;
export const GetPageConfig_URL = `${DARS_API_HOST}api/dashboard/GetPageConfig`;
export const GetFileListBySectionId_URL = `${DARS_API_HOST}api/application/GetFileListBySectionId`;
export const DeleteFileApprovalEvidenceIsLegalBasisChanged_URL = `${DARS_API_HOST}api/application/DeleteFileApprovalEvidenceIsLegalBasisChanged`;
export const GetFileList_URL = `${DARS_API_HOST}api/application/GetFileList`;
export const GetFileSummaries_URL = `${DARS_API_HOST}api/file/GetFileSummaries`;

//Public APIs
export const GetGuidanceList_URL = `${DARS_API_HOST}api/public/GetGuidanceList`;
export const GetPageConfigPublic_URL = `${DARS_API_HOST}api/public/GetPublicPageConfig`;
export const GetDARSLatestNews_URL = `${DARS_API_HOST}api/public/GetDARSLatestNews`;
export const GetDARSLatestNewsById_URL = `${DARS_API_HOST}api/public/GetDARSLatestNewsById`;

