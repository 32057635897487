import { Button, DateInput, Details, Textarea, Input, ErrorSummary, Fieldset } from "nhsuk-react-components";
import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { submitSpinner, validateDateValue, fetchInterceptor, getCookie, calculateExampleDate, handleUploadFile, validateFile, getB64toBlobData, setInputFileCustom, getEvidenceFileDetails, isValid } from "../../../../Helper/Utility";
import { IProcessor, IApplication, IProcessorList, IFileUpload, IEvidenceFileSummary, IApiResEvidenceFileSummary } from "../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getProcessorDetails, setProcessorDetails } from '../../../../state/slice/Processor/Processor.slice';
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { dsaProcessorErrorMessage, fileErrorMessage, dsaContent, fileTypeSize, documentType, taskListSectionStatus, routeName, initialStateOfFileUpload, dsaControllerErrorMessage } from "../../../../config/page.config";
import { getProcessorListDetails, setProcessorListDetailsData } from "../../../../state/slice/ProcessorList/ProcessorList.slice";
import { GetDSAFileById_URL, UpdateScreenDataProcessor_URL } from "../../../../config/api-endpoints.config";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import FileUploadControl1 from "../../../../Components/FileUploadControl/FileUploadControl1";
import Loader from "../../../../Components/Loader/Loader";

/*
  Summary: Using this page, you can add the details related to ISO and can also upload files. The Jira ticket linked to this is DDA-863.
*/
const ProcessorISO: React.FC = () => {

    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const processorDetailsData: IProcessor = useAppSelector(getProcessorDetails);
    const initialState: IProcessor = processorDetailsData || {};
    const [processorData, setProcessorData] = useState(initialState);
    const processorListDetailsData: IProcessorList = useAppSelector(getProcessorListDetails);
    const initialProcessorListState: IProcessorList = processorListDetailsData || {};
    const [processorListData] = useState(initialProcessorListState);
    /* To dispatch the setProcessorListDetailsData Redux action */
    const saveProcessorListDataInStore = (stateData?: IProcessorList) => {
        dispatch(setProcessorListDetailsData({ ...processorListData, ...stateData }));
    }

    let isDirtyProcessor = processorData.isDirtyProcessor || false;
    const [dateExpiresError, setDateExpiresError] = useState("");
    const [diDayError, setDiDayError] = useState(false);
    const [diMonthError, setDiMonthError] = useState(false);
    const [diYearError, setDiYearError] = useState(false);
    const [expiryDate, setExpiryDate] = useState(false);
    let [fileLists, setFileLists] = useState<IFileUpload[]>([]);
    let [fileListsOnChange, setFileListsOnChange] = useState<File[]>([]);
    let [showfileLists] = useState<IFileUpload[]>([]);
    const [selectedFile, setSelectedFile] = useState<File[] |[]>([]);
    const [isSelected, setIsSelected] = useState(false);
    const [uploadAnother, setUploadAnother] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [fileChosen, setfileChosen] = useState(false);
    const [validFileError, setValidFileError] = useState("");
    const [isVersionErrorLink, setIsVersionErrorLink] = useState("");
    const [isVersionError, setIsVersionError] = useState("");
    const [isFileUploadedError, setIsFileUploadedError] = useState("");
    const [isFileUploadedErrorLink, setIsFileUploadedErrorLink] = useState("");
    const [validateChooseFile, setValidateChooseFile] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showdownload, setshowdownload] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(0);
    const [selectedApprovalEvidence, setSelectedApprovalEvidence] = useState('');
    let [evidenceSummaryList, setEvidenceSummaryList] = useState<IEvidenceFileSummary[]>([]);
    const [showLoader, setShowLoader] = useState(true);
    const [selectedFileName, setSelectedFileName] = useState("");

    const [applicantCommentsErrorMessage, setApplicantCommentsErrorMessage] = useState("");
    const { fileUploadActive = 0 } = useAppSelector(getProcessorDetails) || {};
    const [applicantComments, setApplicantComments] = useState(getComment(processorData.fileUpload![fileUploadActive]));    
    const [approvalEvidenceId] = useState(processorData.fileUpload![fileUploadActive]?.ApprovalEvidenceID! !== '' ? processorData.fileUpload![fileUploadActive]?.ApprovalEvidenceID! : '');
    const customErrorMessage = { customDateMonthYearMissingDateExpired_ErrorMessage: dsaControllerErrorMessage.dateMonthYearMissingInitialCertification_ErrorMessage };

    function getComment(uploadedFile: IFileUpload) {
        if (
            uploadedFile !== undefined &&
            uploadedFile.DocumentType === documentType.ISO
        ) {
            return (uploadedFile?.ApplicantComments! !== "" ? uploadedFile?.ApplicantComments!: "")
        } else {
            return "";
        }
    };


    /* To dispatch the setProcessorDetails Redux action */
    const saveDataInStore = (stateData?: IProcessor) => {
        dispatch(setProcessorDetails({ ...processorData, ...stateData }));
    }

    const handelUploadedFiles = (files: File[]) => {
        const uploaded = [...selectedFile!];
        files.forEach((file: File) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                return true;
            }
        })
        setSelectedFile(uploaded);
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === dsaContent.file) {
            resetError(e);
            const chosenFiles = Array.prototype.slice.call(e.target.files);
            setValidateChooseFile(true);
            if (validateFile(e, fileTypeSize, setValidFileError, setIsFileUploadedError, fileErrorMessage, setIsSelected, false, selectedFileName!, evidenceSummaryList)) {
                handelUploadedFiles(chosenFiles);
                setfileChosen(true);
                setValidFileError("");
                setValidateChooseFile(false);
                let updatedState: IProcessor = {};
                updatedState = { ...processorData, [e.target.name]: e.target.value, isDirtyProcessor: true }
                setProcessorData(updatedState);
                saveDataInStore(updatedState);
                if (processorData.fileUpload![fileUploadActive!]?.FileName !== undefined) {
                    handleUpload(e, chosenFiles);
                };
            }
            else {
                setSelectedFile([]);
                setIsSelected(false);
            }

        }
        else {
            let updatedState: IProcessor = {};
            let updatedSAList = { ...processorData.saList, [e.target.name]: e.target.value };
            updatedState = { ...processorData, 'saList': updatedSAList, isDirtyProcessor: true }

            setProcessorData(updatedState);
            saveDataInStore(updatedState);
            //resetError(e);
        }
        if (e.target.name === dsaContent.version) {
            resetError(e);
        }
        if (e.target.name === dsaContent.isoDay || e.target.name === dsaContent.isoMonth || e.target.name === dsaContent.isoYear) {
            resetError(e);
        }
    };


    const files = selectedFile ? Array.from(selectedFile) : [];

    const validateUploadedFile = (filesToValidate?: File[]) => {
        if ((uploadedFileOnChange?.length + filesToValidate!.length) > 6) {
            setValidFileError(`${fileErrorMessage.fileLength_ErrorMessage}`);
            setSelectedFile([]);
            setIsSelected(false);
            if (inputRef.current != null)
                inputRef.current.value = '';
            return false;
        }
        else if (filesToValidate?.length === 0 && processorData.fileUpload![fileUploadActive!]?.FileName === undefined) {
            setValidFileError(`${fileErrorMessage.fileRequiredToUpload_ErrorMessage}`);
            setSelectedFile([]);
            setIsSelected(false);
            return false;
        }
        return true;
    }

    /* To upload the file */
    const handleUpload = (e: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<HTMLInputElement>, fileList?: File[]) => {
        fileList = fileList ? fileList : files;
        const IsValid: boolean = validateUploadedFile(fileList);
        if (IsValid) {
            handleUploadFile(e, fileLists, fileListsOnChange, setIsUploaded, selectedFile!, fileList!, setFileLists, setFileListsOnChange, setIsSelected, setSelectedFile, resetError, true, false, setUploadAnother, evidenceSummaryList!, setEvidenceSummaryList, setSelectedFileName, selectedFileId, applicantComments, fileUploadActive, 0, selectedApprovalEvidence);
        }
        setSelectedFileId(0);
        setSelectedApprovalEvidence('');
    }
    let finallyUploadedFiles: IFileUpload[] = fileLists ? Array.from(fileLists) : processorData.fileUpload!;       // submit
    let uploadedFileOnChange = fileListsOnChange ? Array.from(fileListsOnChange) : [];

    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IProcessor = {};
        let updatedSAList= { ...processorData.saList, [e.target.name]: e.target.value };
        updatedState = { ...processorData, 'saList': updatedSAList, isDirtyProcessor: true }
        setProcessorData(updatedState);
        saveDataInStore(updatedState);
    }
    
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = validateFields(e);
        if (IsValid) {
            submitSpinner(e, submitISO);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    /* To submit the details of the page */
    const submitISO = () => {
        let updatedSAList = { ...processorData.saList, ISODateComplete: processorData.saList ? processorData.saList.ISODay + "/" + processorData.saList.ISOMonth + "/" + processorData.saList.ISOYear : "" };
        let updatedState: IProcessor = {};
        updatedState = {
            ...processorData,
            fileUpload: finallyUploadedFiles,
            'saList': updatedSAList,
            evidenceFileSummary: evidenceSummaryList
        }
        setProcessorData(updatedState);
        saveDataInStore(updatedState);


        if (processorListData.processorDetailsPage && !processorData?.isNewProcessor) {
            if (isDirtyProcessor) {
                const parameters: string = JSON.stringify({
                    "UserAuthTokenId": authenticationTokenCookie,
                    "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
                    "IsSADirty": true,
                    "FileList": finallyUploadedFiles,
                    "DataProcessorId": processorData.processorId,
                    "SecurityAssurance": updatedSAList,
                    "SectionStatus": dsaApplicationData.aboutProcessorSectionStatus
                });
                const requestOptions: Object = {
                    method: 'POST',
                    headers: { 'Content-Type': fileTypeSize.fileTypeJson },
                    body: parameters
                };
                fetchInterceptor(UpdateScreenDataProcessor_URL, requestOptions)
                    .then(response => {
                        return response.json()
                    })
                    .then(() => {
                        let updatedState: IProcessorList = {
                            ...processorListData, processorDetailsPage: processorListData.processorDetailsPage
                        }
                        let sectionStatus: IApplication = {
                            ...dsaApplicationData, aboutProcessorSectionStatus: taskListSectionStatus.inProgress
                        }
                        setDsaApplicationData(sectionStatus);
                        saveApplicationDataInStore(sectionStatus);
                        saveProcessorListDataInStore(updatedState);
                        navigate(routeName.processorDetails);
                    })
                    .catch(() => navigate(routeName.serviceError));
            }
            else { navigate(routeName.processorDetails); }
        }
        else { navigate(routeName.dataProtectionRegistrationProcessor); }
    }

    /* To Validate the each field's data */
    const validateFields = (e: React.MouseEvent<HTMLButtonElement>) => {
        let isValid: boolean = true;
        if (!(processorData.saList ? processorData.fileUpload![fileUploadActive]?.ApplicantComments! : null)) {
            setApplicantCommentsErrorMessage(`${dsaProcessorErrorMessage.applicantComments_ErrorMessage}`);
            isValid = false;
        }
        if ((processorData.fileUpload![fileUploadActive]?.FileName! === "" || processorData.fileUpload![fileUploadActive]?.FileName! === undefined) ) {
            setIsFileUploadedErrorLink(`${fileErrorMessage.fileLink_ErrorMessage}`);
            setIsFileUploadedError(`${fileErrorMessage.fileUploded_ErrorMessage}`);
            isValid = false;
        }
        if (!fileChosen) {
            setIsFileUploadedErrorLink(`${fileErrorMessage.fileLink_ErrorMessage}`);
            setIsFileUploadedError(`${fileErrorMessage.fileRequiredSummaryBody_ErrorMessage}`);
            isValid = false;
        }
        if (validateChooseFile) {
            setIsFileUploadedError("");
        }
        if (validFileError) {
            isValid = false;
        }

        if (!(processorData.saList ? processorData.saList.ISOVersion : null)) {
            setIsVersionErrorLink(`${dsaProcessorErrorMessage.enterVersion_ErrorMessage}`);
            setIsVersionError(`${dsaProcessorErrorMessage.enterVersion_ErrorMessage}`);
            isValid = false;
        }
        let dateValueError = validateDateValue(Number(processorData.saList ? processorData.saList.ISODay : "0"), Number(processorData.saList ? processorData.saList.ISOMonth : "0"), Number(processorData.saList ? processorData.saList.ISOYear : "0"), false, false, false, customErrorMessage);
        if (dateValueError[0] !== "") {
            setDateExpiresError(dateValueError[0]);
            let fields = dateValueError[1].split(",");
            for (let i = 0; i < fields.length; i++) {
                if (fields[i] === dsaContent.dateInputDayField) {
                    setDiDayError(true);
                }
                if (fields[i] === dsaContent.dateInputMonthField) {
                    setDiMonthError(true);
                }
                if (fields[i] === dsaContent.dateInputYearField) {
                    setDiYearError(true);
                }
            }
            isValid = false;
        }
        return isValid;
    }

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaProcessorErrorMessage.selectProcessorSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#applicantComments">
                            {applicantCommentsErrorMessage}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#isoVersion">
                            {isVersionErrorLink}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#isoDateComplete">
                            {dateExpiresError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#fileupload">
                            {validFileError ? validFileError : isFileUploadedErrorLink}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    
    /* To show the uploaded files on the page */
    const showFileList = () => {

        if (processorData.fileUpload !== null && processorData.fileUpload != undefined) {
            const filterFile = processorData?.fileUpload.length && processorData.fileUpload?.filter(file => file.DocumentType === documentType.ISO)
            showfileLists = Object.assign([], filterFile);
            if (showfileLists[0]?.FileName !== "") {
                setFileLists(showfileLists);
                finallyUploadedFiles = showfileLists;
                if (finallyUploadedFiles.length >= 1) {
                    setIsSelected(true);
                    setfileChosen(true);
                }
            }
        }
    }

    const checkFile = (uploadedFile: IFileUpload) => {
        if (uploadedFile !== null && uploadedFile !== undefined && uploadedFile.DocumentType === documentType.ISO) {
            return true;
        } else {
            return false;
        }
    }

    const setFileUrl = (e: any) => {
        e.preventDefault();
        setSelectedIndex(e.currentTarget.id);
        setshowdownload(true);
        e.currentTarget.className += "nhsuk-disableLink";
        downloadfile(e, e.currentTarget.id);
    }

    const downloadfile = (e: any, fileId: any) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "FileId": fileId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: parameters
        };

        fetchInterceptor(GetDSAFileById_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data && data !== undefined) {
                    const b64Data: any = data.Data._buffer;
                    const blob: any = getB64toBlobData(b64Data, data.contentType, data.FileName);
                    const blobUrl: any = URL.createObjectURL(blob);
                    download(blobUrl, data.FileName);
                    setshowdownload(false);
                    e.target.removeAttribute('class');
                }

            })
            .catch(() => navigate('/serviceerror'));

    }
    const download = (path: any, filename: any) => {
        const anchor = document.createElement('a');
        anchor.href = path;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);


    };

    const changeUpLoadedDocData = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, fileId: number, fileName: string, fileType: string, approvalEvidence: string) => {
        e.preventDefault();
        setUploadAnother(true);
        let fileElement = document.getElementById('file');
        if (fileElement != null) {
            setInputFileCustom(fileElement, fileName, fileType);
        }
        else {
            setTimeout(function () { setInputFileCustom(document.getElementById('file'), fileName, fileType) }, 500);
        }
        setSelectedFileId(fileId);
        setSelectedApprovalEvidence(approvalEvidence);
    }


    const handleChangeApplicantComments = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedStateofProcessorComment: IProcessor = {};
        setApplicantComments(e.target.value!);

        let updatedFileData: IFileUpload[] = []
        if (processorData.fileUpload?.length === 0 ||
            (processorData.fileUpload?.length !== 0 && processorData.fileUpload?.length === fileUploadActive && !processorData.fileUpload![fileUploadActive]?.ApplicantComments)) {
            //new array item
            updatedFileData = [...processorData.fileUpload!, ...[{ ...processorData.fileUpload![fileUploadActive], ...{ [e.target.name]: e.target.value } }]];
        }
        else {
            updatedFileData = processorData.fileUpload!.map((item, index) => {
                if (fileUploadActive === index) {
                    return { ...item, ...{ [e.target.name]: e.target.value } }
                } else return item;
            })
        }

        updatedStateofProcessorComment = { ...processorData, fileUpload: updatedFileData, isDirtyProcessor: true };
        finallyUploadedFiles = updatedFileData;

        setFileLists(finallyUploadedFiles);
        setProcessorData(updatedStateofProcessorComment);
        saveDataInStore(updatedStateofProcessorComment);
        resetError(e);
    }

    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const valueLength = e.target.value.length;
        if (valueLength >= 1) {
            if (e.target.name === dsaContent.version) {
                setIsVersionError("");
                setIsVersionErrorLink("");
            }
            if (e.target.id === dsaContent.file) {
                setIsFileUploadedError("");
                setValidFileError("");
                setIsFileUploadedErrorLink("");
            }
            if (e.target.name === dsaContent.isoDay || e.target.name === dsaContent.isoMonth || e.target.name === dsaContent.isoYear) {
                setDateExpiresError("");
                setDiDayError(false);
                setDiMonthError(false);
                setDiYearError(false);
            }
            if (e.target.id === dsaContent.applicantComments) {
                setApplicantCommentsErrorMessage("");
            }
        }
        if (e.target.name === dsaContent.upload) {
            setIsFileUploadedError("");
            setIsFileUploadedErrorLink("");
        }
    }

    const getEvidenceFileSummary = () => {
        let applicationId = isValid(dsaApplicationData.id) ? dsaApplicationData.id : "";
        if (isValid(applicationId)) {
            getEvidenceFileDetails(applicationId!).then((data) => {
                setEvidenceFileSummary(data);
            });
        }
    }

    const setEvidenceFileSummary = (response: IApiResEvidenceFileSummary[]) => {
        if (isValid(response)) {
            let evidenceFileSummary: IEvidenceFileSummary[] = [];
            response.map(({ FileId: fileId, FileName: fileName, MimeType: mimeType, Source: source }) => {
                evidenceFileSummary.push({ fileId, fileName, mimeType, source });
            });
            let updatedState: IProcessor = {};
            updatedState = { ...processorData, evidenceFileSummary };
            setProcessorData(updatedState);
            saveDataInStore(updatedState);
        }
        setShowLoader(false);

    }

    useEffect(() => {
        showFileList();
        setExpiryDate(true);
        if (!isValid(processorData.evidenceFileSummary)) {
            getEvidenceFileSummary();
        }
        else {
            setEvidenceSummaryList(processorData.evidenceFileSummary!);
            setShowLoader(false);
        }
        setSelectedFileName(isValid(processorData.fileUpload) && isValid(processorData.fileUpload![fileUploadActive]) ? processorData.fileUpload![fileUploadActive].FileName! : "");
    }, [])

    useEffect(() => {
        if (!isValid(evidenceSummaryList)) {
            setEvidenceSummaryList(processorData.evidenceFileSummary!);
        }
    }, [processorData.evidenceFileSummary]);

    useEffect(() => {
        finallyUploadedFiles = fileLists ? Array.from(fileLists) : [];  // submit
        let updatedState: IProcessor = {};
        updatedState = {
            ...processorData,
            fileUpload: finallyUploadedFiles,
        }
        setProcessorData(updatedState);
        saveDataInStore(updatedState);
    }, [fileLists]);

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {showLoader ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <>
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the Processor
                            </span>
                            <h1>ISO/IEC 27001 Information Security management standard</h1>
                            {validFileError || isVersionErrorLink !== "" || isFileUploadedError !== "" || dateExpiresError ? errorSummary : null}
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <Textarea
                                label="Provide your own description or name for the document (character limit 50)"
                                error={applicantCommentsErrorMessage}
                                name="ApplicantComments"
                                id={dsaContent.applicantComments}
                                onChange={handleChangeApplicantComments}
                                defaultValue={applicantComments}
                                maxLength={50}
                                rows={3}
                            />
                            <Input id="isoVersion" name={dsaContent.version} label="Version"
                                className="nhsuk-input--width-20"
                                error={isVersionError}
                                value={processorData.saList ? processorData.saList.ISOVersion : ""}
                                onChange={handleChange} />
                            <div className="nhsuk-u-padding-bottom-5"></div>
                            <DateInput id="isoDateComplete" hint={`For example, ${calculateExampleDate(dsaContent.exampleOneYearForPast)}`} label="Initial certification date" name="ISODateComplete" error={dateExpiresError}>
                                <DateInput.Day id={dsaContent.isoDay} name={dsaContent.isoDay} onChange={handleChange} value={processorData.saList ? processorData.saList.ISODay : ""} error={diDayError} />
                                <DateInput.Month id={dsaContent.isoMonth} name={dsaContent.isoMonth} onChange={handleChange} value={processorData.saList ? processorData.saList.ISOMonth : ""} error={diMonthError} />
                                <DateInput.Year id={dsaContent.isoYear} name={dsaContent.isoYear} onChange={handleChange} value={processorData.saList ? processorData.saList.ISOYear : ""} error={diYearError} />
                            </DateInput>
                        </div>

                        <div className="nhsuk-u-padding-bottom-3"></div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <Fieldset>
                                <Fieldset.Legend>Document required </Fieldset.Legend>
                                <Fieldset.Legend>- ISO27001 Certificate  </Fieldset.Legend>
                            </Fieldset>
                            <div className="nhsuk-u-padding-bottom-2"></div>
                            <Details>
                                <Details.Summary>View file guidance </Details.Summary>
                                <Details.Text>
                                    <p>A file should not exceed 3 MB and should be one of the following acceptable file formats: PDF, Word, Excel. </p>
                                </Details.Text>
                            </Details>
                        </div>

                        <FileUploadControl1
                            errorAboveList={isFileUploadedError}
                            errorBelowList={validFileError}
                            isUploaded={isUploaded}
                            finallyUploadedFiles={fileUploadActive === 0 && processorData.fileUpload!.length === 0 ? undefined : checkFile(processorData.fileUpload![fileUploadActive!]) ? processorData.fileUpload![fileUploadActive!] as any : initialStateOfFileUpload} handleChange={handleChange}
                            handleUpload={handleUpload}
                            changeFile={changeUpLoadedDocData}
                            setFileUrl={setFileUrl}
                            showDownload={showdownload}
                            selectedIndex={selectedIndex}
                            setSelectedFileId={setSelectedFileId}
                            setSelectedApprovalEvidence={setSelectedApprovalEvidence}
                        />

                        <div className="nhsuk-grid-column-two-thirds">
                            <Textarea
                                label="Additional ISO27001 submission (Optional)"
                                name="ISONotes"
                                id="isoNotes"
                                onChange={handleChangeTextarea}
                                defaultValue={processorData.saList ? processorData.saList.ISONotes : ""}
                                maxLength={4000}
                                rows={5}
                            />
                        </div>
                        <div className="nhsuk-u-padding-bottom-7"></div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnSaveAndContinue}</Button>
                                </div>
                            </>}
                    </div>
                </main>
            </div>
        </>
    )
}
export default ProcessorISO;