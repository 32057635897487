import { Button, Fieldset, Radios } from "nhsuk-react-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitScreenCommonLawDuty_URL } from "../../../../config/api-endpoints.config";
import { dataSetErrorMessage, dsaContent, fileTypeSize, routeName } from "../../../../config/page.config";
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IDataSet } from "../../../../state/models/app.interface";
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";

const IdentifiableData: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [identifiableDataErrorMessage, setIdentifiableDataErrorMessage] = useState("");
    const [dataSetData, setDataSetData] = useState<IDataSet>(useAppSelector(getDataSetDetails) || {});
    /* To dispatch the setDataSetDetailsData Redux action */
    const saveDataSetDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);

    /* Function to set the state value on change of Radios(yes/no) */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IDataSet = {};
        updatedState = {
            ...dataSetData,
            [e.target.name]: e.target.value
        }
        setDataSetData(updatedState);
        saveDataSetDataInStore(updatedState);
        resetError(e);
    }
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setIdentifiableDataErrorMessage("");
        }
    };
    /* Function to set the state value on change of Radios(yes/no) */

    /* To handle Onclick */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, submitIdentifiableData());
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    /* To Validate the each field's data */
    const ValidateFields = () => {
        let isValid: boolean = true;
        if (!dataSetData.identifiableData) {
            setIdentifiableDataErrorMessage(`${dataSetErrorMessage.identifiableDataErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const submitIdentifiableData = () => {
        if (dataSetData.identifiableData === dsaContent.yes) {
            navigate(routeName.legalBasesIdentifiableData);
        }
        else {
            submitLegalBasesIdentifiableData();
        }
    }
    
    const submitLegalBasesIdentifiableData = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "CommonLawDutyOfConfidentialityType": dataSetData.legalBasesIdentifiableData
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenCommonLawDuty_URL, requestOptions)
            .then(() => {
                navigate(routeName.addedAllDataSet);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the data
                            </span>
                            <h1>Can the data you are requesting be used to identify individuals within a Participant group?</h1>
                            {identifiableDataErrorMessage !== "" && <ErrorDetails errorDescription={identifiableDataErrorMessage} errorHref="#radioIdentifiableData"></ErrorDetails>}
                            <Fieldset>
                                <Radios name="radioIdentifiableData" id="radioIdentifiableData" error={identifiableDataErrorMessage}>
                                    <Radios.Radio name="identifiableData" onChange={handleChange} value={dsaContent.yes} checked={dataSetData.identifiableData === dsaContent.yes}>Yes</Radios.Radio>
                                    <Radios.Radio name="identifiableData" onChange={handleChange} value={dsaContent.no} checked={dataSetData.identifiableData === dsaContent.no}>No</Radios.Radio>
                                </Radios>
                            </Fieldset>
                            <div className="nhsuk-u-padding-bottom-8"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default IdentifiableData