import { Button, ErrorSummary, Fieldset, Hint, Radios } from "nhsuk-react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitDSASectionStatus_URL } from "../../../../config/api-endpoints.config";
import { amendRenewExtendValue, applicationChangeType, controllerSection, dataSetErrorMessage, dsaContent, dsaErrorMessage, fileTypeSize, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { checkApplicationChangeType, fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IDataSet } from "../../../../state/models/app.interface";
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";

const HaveYouAddedAllDataSet: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);
    const dataSetDetails: IDataSet = useAppSelector(getDataSetDetails);
    const initialDataSetState: IDataSet = dataSetDetails || {};
    const [dataSetData, setDataSetData] = useState(initialDataSetState);
    const saveDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }

    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [sectionStatus, setSectionStatus] = useState("");
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [isSectionDataSetErrorMessage, setIsSectionDataSetErrorMessage] = useState("");
    const [selected, setSelected] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSectionStatus(e.target.value);
        setSelected(true);
        resetError(e);
    };

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setIsSectionDataSetErrorMessage("");
        }
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, submitDataSetDetails);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    const submitDataSetDetails = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "SectionId": controllerSection.aboutData,
            "SectionStatus": sectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitDSASectionStatus_URL, requestOptions)
            .then(() => {
                setDataSetStatus(sectionStatus);
                navigate(routeName.dsaTaskList);

            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setDataSetStatus = (aboutDataSectionStatus: string) => {
        let updatedState: IDataSet = {};
        updatedState = {
            ...dataSetData,
            aboutDataSectionStatus: aboutDataSectionStatus
        }
        setDataSetData(updatedState);
        saveDataInStore(updatedState);
    }

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#radioSectionComplete">
                            {summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )


    const ValidateFields = () => {
        let isValid: boolean = true;
        if (!selected) {
            if (checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)])) {
                setSummaryLinkErrorMessage(`${dsaContent.selectSectionCompleteLink_RenewErrorMessage}`);
                setIsSectionDataSetErrorMessage(`${dsaContent.selectSectionCompleteLink_RenewErrorMessage}`);
            }
            else {
                setSummaryLinkErrorMessage(`${dataSetErrorMessage.selectDataSetSectionCompleteBody_ErrorMessage}`);
                setIsSectionDataSetErrorMessage(`${dataSetErrorMessage.selectDataSetSectionCompleteBody_ErrorMessage}`);
            }
            isValid = false;
        }
        return isValid;
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack condition="add-another-dataset-record"></GoBack>
                            <h1>{checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? dsaContent.isThisSectionComplete : dsaContent.haveYouAddedAllDataset}</h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? <p>If you select 'Yes' the status of this section will change to 'completed', but you can return and make changes if you need to.</p> : null}

                            <Fieldset>
                                <Radios name="radioSectionComplete" id="radioSectionComplete" error={isSectionDataSetErrorMessage}>
                                    <Radios.Radio name="sectionComplete" value={taskListSectionStatus.completed} onChange={handleChange}>{checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? dsaContent.yes : dsaContent.yesIHaveAddedAllDataset}
                                        {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.new), String(applicationChangeType.Amendment)]) ? <Hint>Once the application is submitted, our Data Production Team will arrange a meeting to discuss the information provided. We recommend that someone who will use the data also attends that meeting. </Hint> : null} </Radios.Radio>
                                    <Radios.Radio name="sectionComplete" value={taskListSectionStatus.inProgress} onChange={handleChange}>{checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? dsaContent.noIWillComeBackLater : dsaContent.noIWillAddThemLater}</Radios.Radio>
                                </Radios>
                            </Fieldset>
                            <div className="nhsuk-u-padding-bottom-6"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default HaveYouAddedAllDataSet;