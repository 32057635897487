import { Button, Label, SummaryList } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { applicationChangeType, dataControllerTypeOptions, dataProcessorTypeOptions, documentType, dsaContent, dsptStatus, fileTypeSize, initialStateOfFileUpload, routeName, saType, taskListSectionStatus } from "../../../../config/page.config";
import { useAppSelector } from "../../../../state/app/hooks";
import { GetDataProcessor_URL, GetDSAFileById_URL } from "../../../../config/api-endpoints.config";
import { IApiResController, IApiResControllerList, IApplication, IController, ISecurityAssurance } from "../../../../state/models/app.interface";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getCookie, submitSpinner, numberToWords, getB64toBlobData, getTaskListType, fetchInterceptor, checkApplicationChangeType, resetSecurityAssuranceState } from '../../../../Helper/Utility';
import { IProcessor, IProcessorList, IFileUpload } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch } from '../../../../state/app/hooks';
import { getProcessorDetails, setProcessorDetails } from "../../../../state/slice/Processor/Processor.slice";
import Loader from "../../../../Components/Loader/Loader";
import { getProcessorListDetails, setProcessorListDetailsData } from "../../../../state/slice/ProcessorList/ProcessorList.slice";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { getSecurityAssuranceDetails, setSecurityAssuranceDetailsData } from "../../../../state/slice/SecurityAssurance/SecurityAssurance.slice";
import GoBack from "../../../../Components/Shared/GoBack";
import ShowAll from "../../../../Components/ShowAll/ShowAll";

const ProcessorDetails: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);
    const processorDetailsData: IProcessor = useAppSelector(getProcessorDetails);
    const initialState: IProcessor = processorDetailsData || {};
    const [processorData, setProcessortData] = useState(initialState);
    const [showdownload, setshowdownload] = useState(false);
    const processorListDetailsData: IProcessorList = useAppSelector(getProcessorListDetails);
    const initialProcessorListState: IProcessorList = processorListDetailsData || {};
    const [processorListData, setProcessorListData] = useState(initialProcessorListState);
    const [processordataList, setprocessordataList] = useState<IProcessor[]>();
    const [selectedIndex, setSelectedIndex] = useState(0);

    const saveProcessorDataInStore = (stateData?: IProcessor) => {
        dispatch(setProcessorDetails({ ...processorData, ...stateData }));
    }

    const saveProcessorListDataInStore = (stateData?: IProcessorList) => {
        dispatch(setProcessorListDetailsData({ ...processorListData, ...stateData }));
    }

    const securityAssuranceDetailsData: ISecurityAssurance = useAppSelector(getSecurityAssuranceDetails);
    const initiailSecurityAssuranceState: ISecurityAssurance = securityAssuranceDetailsData || {};
    const [securityAssuranceData, setSecurityAssuranceData] = useState(initiailSecurityAssuranceState);
    const saveSecurityAssuranceDataInStore = (stateData?: ISecurityAssurance) => {
        dispatch(setSecurityAssuranceDetailsData({ ...securityAssuranceData, ...stateData }));
    }

    const [activeProcessorIndex, setActiveProcessorIndex] = useState(processorListData?.processorList?.length || 0);
    const [isExpender, setIsExpender] = useState(false);
    /*Variable Declarations*/

    /*Get Set Processor data*/
    const getProcessorData = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetDataProcessor_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setProcessorDetailsData(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setProcessorDetailsData = (response: IApiResControllerList) => {
        let processorlist: IController[] = [];
        let updatedState: IProcessorList = {};

        let dsaProcessorDetail: IProcessor = {};
        let dsaProcessorDetailList: IProcessor[] = [];
        if (response && response !== undefined && response.DataProcessorList!) {
            setActiveProcessorIndex(response.DataProcessorList.length);
            response.DataProcessorList.map((dsaProcessor: IApiResController) => {
                let securityAssuranceDetail: ISecurityAssurance = {};
                securityAssuranceDetail = {
                    ...dsaProcessor.SecurityAssurance,
                    SAType: dsaProcessor.SecurityAssurance?.SAType,
                    SAId: dsaProcessor.SecurityAssurance?.SAId,
                    ISOVersion: dsaProcessor.SecurityAssurance?.ISOVersion,
                    ISODateComplete: dsaProcessor.SecurityAssurance?.ISODateComplete,
                    ISONotes: dsaProcessor.SecurityAssurance?.ISONotes,
                    SLSPNotes: dsaProcessor.SecurityAssurance?.SLSPNotes,
                    SLSPDateComplete: dsaProcessor.SecurityAssurance?.SLSPDateComplete,
                    DSPT_ODS_Code: dsaProcessor.SecurityAssurance?.DSPT_ODS_Code,
                    DSPT_Latest_Status: dsaProcessor.SecurityAssurance?.DSPT_Latest_Status,
                    DSPT_Date_Published: dsaProcessor.SecurityAssurance?.DSPT_Date_Published,
                    SectionType: dsaProcessor.SecurityAssurance?.SAType,
                    DSPTDay: dsaProcessor.SecurityAssurance?.DSPT_Date_Published?.split("/")[0] ? dsaProcessor.SecurityAssurance?.DSPT_Date_Published?.split("/")[0] : "",
                    DSPTMonth: dsaProcessor.SecurityAssurance?.DSPT_Date_Published?.split("/")[1] ? dsaProcessor.SecurityAssurance?.DSPT_Date_Published?.split("/")[1] : "",
                    DSPTYear: dsaProcessor.SecurityAssurance?.DSPT_Date_Published?.split("/")[2] ? dsaProcessor.SecurityAssurance?.DSPT_Date_Published?.split("/")[2] : "",
                    ISODay: dsaProcessor.SecurityAssurance?.ISODateComplete?.split("/")[0] ? dsaProcessor.SecurityAssurance?.ISODateComplete?.split("/")[0] : "",
                    ISOMonth: dsaProcessor.SecurityAssurance?.ISODateComplete?.split("/")[1] ? dsaProcessor.SecurityAssurance?.ISODateComplete?.split("/")[1] : "",
                    ISOYear: dsaProcessor.SecurityAssurance?.ISODateComplete?.split("/")[2] ? dsaProcessor.SecurityAssurance?.ISODateComplete?.split("/")[2] : "",
                    SLSPDay: dsaProcessor.SecurityAssurance?.SLSPDateComplete?.split("/")[0] ? dsaProcessor.SecurityAssurance?.SLSPDateComplete?.split("/")[0] : "",
                    SLSPMonth: dsaProcessor.SecurityAssurance?.SLSPDateComplete?.split("/")[1] ? dsaProcessor.SecurityAssurance?.SLSPDateComplete?.split("/")[1] : "",
                    SLSPYear: dsaProcessor?.SecurityAssurance?.SLSPDateComplete?.split("/")[2] ? dsaProcessor.SecurityAssurance.SLSPDateComplete?.split("/")[2] : ""
                }
                dsaProcessorDetail = {
                    processorId: dsaProcessor.DataProcessorId,
                    isDirtyProcessor: dsaProcessor.IsProcessoraProcessorDirty,
                    aboutProcessorSectionStatus: "",
                    dpaRegistrationNumber: dsaProcessor.DPARegisteredCode,
                    dpaOrganisationName: dsaProcessor.DPARegisteredName,
                    dpaRegistrationExpiryDate: dsaProcessor.DPARegisteredExpiry,
                    dpaId: dsaProcessor.DpaId,
                    processorProcessingData: dsaProcessor.IsProcessoraProcessor ? dsaContent.processorProcessingTheData : dsaContent.processorNotProcessingTheData,
                    dpDay: dsaProcessor.DPARegisteredExpiry?.split("/")[0] ? dsaProcessor.DPARegisteredExpiry?.split("/")[0] : "",
                    dpMonth: dsaProcessor.DPARegisteredExpiry?.split("/")[1] ? dsaProcessor.DPARegisteredExpiry?.split("/")[1] : "",
                    dpYear: dsaProcessor.DPARegisteredExpiry?.split("/")[2] ? dsaProcessor.DPARegisteredExpiry?.split("/")[2] : "",
                    fileUpload: dsaProcessor.FileList,
                    dsaApplicationId: dsaProcessor.ApplicationId,
                    contractNumber: "",
                    processordataOrgDesc: dsaProcessor.OrganisationName,
                    processordataOrgGuidId: dsaProcessor.OrganisationId,
                    isNewProcessor: false,
                    alternativeOrganisationName: dsaProcessor.OrganisationName,
                    alternativeStreet1: dsaProcessor.AddressLine1,
                    alternativeStreet2: dsaProcessor.AddressLine2,
                    alternativeStreet3: dsaProcessor.AddressLine3,
                    alternativeCity: dsaProcessor.TownCity,
                    alternativeCounty: dsaProcessor.County,
                    alternativePostCode: dsaProcessor.Postcode,
                    alternativeCountry: dsaProcessor.Country,
                    saList: securityAssuranceDetail,
                    evidenceFileSummary: []
                }
                dsaProcessorDetailList.push(dsaProcessorDetail);
            })
            if (processorListData.processorDetailsPage === dsaContent.summaryPageText) {
                processorlist = Object.assign([], processorlist);
                processorlist.push(dsaProcessorDetailList[0]);
                setprocessordataList(processorlist);
            }
            else {
                processorlist = Object.assign([], processorlist);
                processorlist = dsaProcessorDetailList;
                setprocessordataList(processorlist);
            }
            updatedState = {
                ...processorListData,
                processorList: dsaProcessorDetailList
            }
            setProcessorListData(updatedState);
            saveProcessorListDataInStore(updatedState);
        }
        setLoading(false);
    }
    /*Get Set Processor data*/

    /*File Download*/
    const setFileUrl = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setSelectedIndex(Number(e.currentTarget.id));
        setshowdownload(true);
        e.currentTarget.className += "nhsuk-disableLink";
        downloadfile(e, e.currentTarget.id);

    }
    const downloadfile = (e: React.MouseEvent<HTMLAnchorElement>, fileId: string) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "FileId": fileId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(GetDSAFileById_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data && data !== undefined) {
                    const b64Data: string = data.Data._buffer;
                    const blob: Blob = getB64toBlobData(b64Data, data.contentType, data.FileName);
                    const blobUrl: string = URL.createObjectURL(blob);
                    download(blobUrl, data.FileName);
                    setshowdownload(false);
                    let targetElement = e.target as HTMLAnchorElement;
                    targetElement.removeAttribute('class');
                }

            })
            .catch(() => navigate(routeName.serviceError));

    }
    const download = (path: string, filename: string) => {
        const anchor = document.createElement('a');
        anchor.href = path;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);


    };
    /*File Download*/

    /*Navigate to next page*/
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        submitSpinner(e, handleClickNavigate);
    }
    const handleClickNavigate = () => {
        if (processorListData.processorDetailsPage === dsaContent.summaryPageText) {
            let updatedStateSA = resetSecurityAssuranceState();
            setSecurityAssuranceData(updatedStateSA);
            saveSecurityAssuranceDataInStore(updatedStateSA);

            let updatedState: IProcessor = {};
            updatedState = {
                processorId: "",
                isDirtyProcessor: false,
                aboutProcessorSectionStatus: "",
                dpaRegistrationNumber: "",
                dpaOrganisationName: "",
                dpaRegistrationExpiryDate: "",
                isProcessorProcessingData: false,
                dpDay: "",
                dpMonth: "",
                dpYear: "",
                fileUpload: [initialStateOfFileUpload],
                dsaApplicationId: "",
                contractNumber: "",
                alternativeOrganisationName: "",
                alternativeStreet1: "",
                alternativeStreet2: "",
                alternativeStreet3: "",
                alternativeCity: "",
                alternativeCounty: "",
                alternativePostCode: "",
                alternativeCountry: "",
                processordataOrgGuidId: "",
                processordataOrgDesc: "",
                processorIndex: 0,
                dpaId: "",
                isNewProcessor: true,
                processorProcessingData: "",
                saList: {}
            }
            setProcessorData(updatedState);
            saveProcessorDataInStore(updatedState);
            navigate(routeName.processorData);
        }
        else if (processorListData.processorDetailsPage === dsaContent.checkYourAnswersPageText) {
            if ((String(dsaApplicationData.aboutProcessorSectionStatus) !== String(taskListSectionStatus.completed))) {
                navigate(routeName.addedAllProcessors);
            }
            else {
                navigate(routeName.dsaTaskList);
            }
        }
    }

    /*Navigate to next page*/

    /*Edit page navigation*/
    const handleClickNavigation = (e: React.MouseEvent<HTMLAnchorElement>, navigatePage: string, processorIndex: number, processorDetailsPage: string, saId?: string) => {
        e.preventDefault();
        setProcessorData(processorIndex);
        let updatedState: IProcessorList = {};
        updatedState = {
            ...processorListData,
            activeProcessor: processorIndex,
            processorDetailsPage: processorDetailsPage
        }
        setProcessorListData(updatedState);
        saveProcessorListDataInStore(updatedState);

        if (saId !== "" && saId !== null && saId !== undefined) {
            let updatedStateSA: ISecurityAssurance = {};
            updatedStateSA = {
                SAId: saId,
                SAIndex: 0
            }
            setSecurityAssuranceData(updatedStateSA);
            saveSecurityAssuranceDataInStore(updatedStateSA);
        }
        navigate(navigatePage);
    }
    const setProcessorData = (processorIndex: any | number) => {
        let updatedState: IProcessor = {};
        updatedState = processorListData.processorList ? processorListData.processorList[processorIndex] : {};
        setProcessortData(updatedState);
        saveProcessorDataInStore(updatedState);
    }
    const handleExpend = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setIsExpender(isExpender ? false : true)
    }

    const handleNavigateAddProcessorLink = () => {
        let updatedStateSA = resetSecurityAssuranceState();
        setSecurityAssuranceData(updatedStateSA);
        saveSecurityAssuranceDataInStore(updatedStateSA);

        let updatedState: IProcessor = {};
        updatedState = {
            processorId: "",
            isDirtyProcessor: false,
            aboutProcessorSectionStatus: "",
            dpaRegistrationNumber: "",
            dpaOrganisationName: "",
            dpaRegistrationExpiryDate: "",
            isProcessorProcessingData: false,
            dpDay: "",
            dpMonth: "",
            dpYear: "",
            fileUpload: [initialStateOfFileUpload],
            dsaApplicationId: "",
            contractNumber: "",
            alternativeOrganisationName: "",
            alternativeStreet1: "",
            alternativeStreet2: "",
            alternativeStreet3: "",
            alternativeCity: "",
            alternativeCounty: "",
            alternativePostCode: "",
            alternativeCountry: "",
            processordataOrgGuidId: "",
            processordataOrgDesc: "",
            processorIndex: 0,
            dpaId: "",
            isNewProcessor: true,
            processorProcessingData: "",
            saList: {}
        }
        setProcessorData(updatedState);
        saveProcessorDataInStore(updatedState);
        navigate(routeName.processorData);
    }
    /*Edit page navigation*/

    useEffect(() => {
        getProcessorData();
    }, [])
    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {!loading ?
                            <div className="nhsuk-grid-column-two-thirds">
                                {
                                    <GoBack condition={getTaskListType(dsaApplicationData.returningJourneyType!)} />
                                }
                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    About the Processor</span>
                                    {processorListData.processorDetailsPage === dsaContent.summaryPageText ? "Check this processor details" : "Check your answers"}
                                </h1>
                                {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? "" : (processorListData.processorDetailsPage === dsaContent.checkYourAnswersPageText) &&
                                    <SummaryList.Row>
                                        <SummaryList.Actions><Label><a href="" onClick={handleNavigateAddProcessorLink}>{ dsaContent.addAnotherProcessorLink}</a></Label>
                                        </SummaryList.Actions>
                                    </SummaryList.Row>}
                                {
                                    processordataList?.map((processorData: IProcessor, index: number) => (
                                        <SummaryList key={index + 1}>
                                            <SummaryList.Row>
                                                <SummaryList.Key><h3>Processor {processorListData.processorDetailsPage === dsaContent.summaryPageText ? activeProcessorIndex : index + 1}</h3></SummaryList.Key>
                                                <SummaryList.Value></SummaryList.Value>
                                                <SummaryList.Actions>
                                                    {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal),String(applicationChangeType.Extension)]) ? null :
                                                        <a href="#" onClick={(e) => handleClickNavigation(e, "/dsa/delete-processor", index, processorListData.processorDetailsPage!)}>Delete</a>}
                                             </SummaryList.Actions>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key>Organisation acting as Processor {processorListData.processorDetailsPage === dsaContent.summaryPageText ? activeProcessorIndex : index + 1}</SummaryList.Key>
                                                <SummaryList.Value>
                                                    <p>{processorData.processordataOrgDesc}</p>
                                                    <p>{processorData.alternativeStreet1}</p>
                                                    <p>{processorData.alternativeStreet2}</p>
                                                    <p>{processorData.alternativeStreet3}</p>
                                                    <p>{processorData.alternativeCity}</p>
                                                    <p>{processorData.alternativeCounty}</p>
                                                    <p>{processorData.alternativePostCode}</p>
                                                    <p>{processorData.alternativeCountry}</p>
                                                </SummaryList.Value>
                                                <SummaryList.Actions>
                                                    {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension), String(applicationChangeType.Amendment)]) ? null :
                                                        <a href="#" onClick={(e) => handleClickNavigation(e, routeName.processorData, index, processorListData.processorDetailsPage!)}>Change</a>}
                                                </SummaryList.Actions>
                                            </SummaryList.Row>
                                            {/*Set Security Assurance Type*/}
                                            <SummaryList.Row>
                                                <SummaryList.Key>How will you provide security assurances for this Processor?</SummaryList.Key>
                                                <SummaryList.Value>
                                                    {processorData.saList?.SAType && saType[processorData.saList?.SAType]}
                                                </SummaryList.Value>
                                                <SummaryList.Actions>
                                                    <a href="#" onClick={(e) => handleClickNavigation(e, routeName.processorSecurityAssurance, index, processorListData.processorDetailsPage!, processorData.saList?.SAId)}>Change</a>
                                                </SummaryList.Actions>
                                            </SummaryList.Row>

                                            {/*Set Security Assurance Type*/}
                                            {/*Set DSPT*/}
                                            <SummaryList.Row>
                                                <SummaryList.Key>{String(processorData.saList?.SAType) === String(dataProcessorTypeOptions.dsptSecurityAssuranceValue) ? "Data Security and Protection Toolkit (DSPT) details" : (String(processorData.saList?.SAType) === String(dataControllerTypeOptions.isoSecurityAssuranceValue) ? "ISO/IEC 27001 Information Security Management Standard details " : (String(processorData.saList?.SAType) === String(dataControllerTypeOptions.slspSecurityAssuranceValue) ? "SLSP/System Level Security Policy" : ""))}</SummaryList.Key>
                                                <SummaryList.Value>
                                                    {String(processorData.saList?.SAType) === String(dataProcessorTypeOptions.dsptSecurityAssuranceValue) ?
                                                        <>
                                                            <p>{processorData.saList?.DSPT_ODS_Code}</p>
                                                            <p>{processorData.saList?.DSPT_Latest_Status ? dsptStatus[processorData.saList?.DSPT_Latest_Status] : null} </p>
                                                            <p>Published {processorData.saList?.DSPT_Date_Published}</p>
                                                        </> : (String(processorData.saList?.SAType) === String(dataProcessorTypeOptions.isoSecurityAssuranceValue) ?
                                                            <>
                                                                <p>{processorData.saList?.ISOVersion}</p>
                                                                <p>{processorData.saList?.ISODateComplete} </p>
                                                                <p>
                                                                    Uploaded Certificate </p>
                                                                <p>
                                                                    {
                                                                        processorData?.fileUpload?.filter(fl => fl.DocumentType === documentType.ISO).map((fl: IFileUpload, index: number) => {
                                                                            return (<div key={index + 1}>
                                                                                <div className="nhsuk-u-margin-top-3">{String(fl?.ApplicantComments)}</div>
                                                                                <a href="#" id={String(fl?.FileId)} onClick={(e) => setFileUrl(e)} > {fl?.FileName}
                                                                                </a>
                                                                                {(showdownload && String(fl?.FileId) === String(selectedIndex)) ?
                                                                                    <span className="nhsuk-loader__container loading-results ">
                                                                                        <span className="nhsuk-loader-file"></span>
                                                                                        Downloading file
                                                                                    </span> : null}
                                                                            </div>)

                                                                        })
                                                                    }
                                                                </p>
                                                            </> : (String(processorData.saList?.SAType) === String(dataProcessorTypeOptions.slspSecurityAssuranceValue) ?
                                                                <>
                                                                    <p>{processorData.saList?.SLSPDateComplete} </p>
                                                                    <p>
                                                                        Uploaded Certificate </p>
                                                                    <ShowAll
                                                                        uploadedFiles={processorData?.fileUpload!.length > 0 ? processorData?.fileUpload?.filter(fl => fl.DocumentType === documentType.SLSP) : undefined as any}
                                                                        showDownload={showdownload}
                                                                        selectedIndex={selectedIndex}
                                                                        isExpender={isExpender}
                                                                        handleExpend={handleExpend}
                                                                        setFileUrl={setFileUrl}
                                                                    />
                                                                </> : null)
                                                        )
                                                    }
                                                </SummaryList.Value>
                                                <SummaryList.Actions>
                                                    <a href="#" onClick={(e) => handleClickNavigation(e, String(processorData.saList?.SAType) === String(dataProcessorTypeOptions.dsptSecurityAssuranceValue) ? routeName.processorDsptDetails : String(processorData.saList?.SAType) === String(dataControllerTypeOptions.isoSecurityAssuranceValue) ? "/dsa/processor-iso" : String(processorData.saList?.SAType) === String(dataControllerTypeOptions.slspSecurityAssuranceValue) ? routeName.addAnotherprocessorSlsp : '', index, processorListData.processorDetailsPage!, processorData.saList?.SAId)}>Change</a>
                                                </SummaryList.Actions>
                                            </SummaryList.Row>
                                            {/*Set DSPT*/}
                                            {/*Set Additional DSPT */}

                                            {/*Set Additional DSPT */}
                                            <SummaryList.Row>
                                                <SummaryList.Key>Data protection registration for the Processor</SummaryList.Key>
                                                <SummaryList.Value><p>{processorData.dpaRegistrationNumber}</p>
                                                    <p>{processorData.dpaOrganisationName}</p>
                                                    <p>Expires {processorData.dpaRegistrationExpiryDate}</p>
                                                </SummaryList.Value>
                                                <SummaryList.Actions>
                                                    <a href="#" onClick={(e) => handleClickNavigation(e, routeName.dataProtectionRegistrationProcessor, index, processorListData.processorDetailsPage!)}>Change</a>
                                                </SummaryList.Actions>
                                            </SummaryList.Row>
                                        </SummaryList>
                                    )
                                    )
                                }
                                <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>
                            : <Loader loadingText={dsaContent.txtLoading} />}
                    </div>
                </main>
            </div>

        </>
    );
}
export default ProcessorDetails;
