import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dsaContent, fileTypeSize, routeName } from "../../../config/page.config";
import { Button, InsetText, Label } from "nhsuk-react-components";
import { fetchInterceptor, getCookie, getDate, submitSpinner } from "../../../Helper/Utility";
import {IApiResDSADetails, IApplication } from "../../../state/models/app.interface";
import { useAppDispatch, useAppSelector } from "../../../state/app/hooks";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../state/slice/DSA/DSA.slice";
import { SubmitScreenDSAIntro_URL } from "../../../config/api-endpoints.config";
import { AppDispatch } from "../../../state/app/store";
import InfoHeaderPanel from "../../../Components/InfoHeaderPanel/InfoHeaderPanel";

const DSABeforeYouStart: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const [btnText, setBtnText] = useState(dsaContent.btnTextStart);
    const [showButton, setShowButton] = useState(true);
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData, setDSAApplicationData] = useState(initialApplicationState);
    const saveDSAApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setShowButton(false);
        submitSpinner(e, submitDsaIntro);
    };

    const submitDsaIntro = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitScreenDSAIntro_URL, requestOptions)
            .then(response => {
                    return response.json()
            })
            .then(data => {
                setDSADetails(data);
                navigate(routeName.dsaTaskList);
            })
            .catch(() => navigate(routeName.serviceError));

    }

    const setDSADetails = (dsaDetails: IApiResDSADetails) => {
        let updatedState: IApplication = {};
        updatedState = {
            ...dsaApplicationData,
            id: dsaDetails.ApplicationId,
            referenceNumber: dsaDetails.AppReferenceNumber,
            totalEstimatedCost: dsaDetails.TotalChargeInclVat,
            startDate: getDate(dsaDetails.DSAStartDate)
        }
        setDSAApplicationData(updatedState);
        saveDSAApplicationDataInStore(updatedState);
    }

    useEffect(() => {
        (dsaApplicationData && dsaApplicationData.id !== "" && dsaApplicationData.id !== undefined) ?
            setBtnText(dsaContent.btnTextContinue) : setBtnText(dsaContent.btnTextStart)
    }, []);

    return (
        <>
            {(dsaApplicationData && dsaApplicationData.id !== "" && dsaApplicationData.id !== undefined) ?
                <InfoHeaderPanel>
                    <InfoHeaderPanel.Item >
                        <strong>Reference number</strong> : {dsaApplicationData?.referenceNumber}
                    </InfoHeaderPanel.Item>
                    <InfoHeaderPanel.Item>
                        <strong>Total Estimated Cost</strong> : {dsaApplicationData.totalEstimatedCost}
                    </InfoHeaderPanel.Item>
                </InfoHeaderPanel>
                : null}
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <div className="nhsuk-u-padding-bottom-4"></div>
                            <h1>Requesting access to data</h1>
                            <Label size="m" className="nhsuk-label-font-normal-500">The Data Access Request Service (DARS) enables clinicians, researchers and commissioners to request the data they need to help improve NHS services.</Label>
                            <InsetText>
                                <p>
                                    <Label>If you wish to apply for tabulated or aggregated data, please contact us before applying for Data via the Data Access Request Service at <a href={'mailto:enquiries@nhsdigital.nhs.uk?subject=DARS Tabulation Request'}>enquiries@nhsdigital.nhs.uk</a> quoting 'DARS Tabulation Request'.</Label>
                                </p>
                            </InsetText>
                            <Label size="m">You can use this service if the Controller requesting the data: </Label>
                            <Label size="s">
                                <ul>
                                    <li>wants to apply for record level data</li>
                                    <li>has an active registration with the Information Commissioner's Office (ICO) under the UK General Data Protection Regulation (GDPR).</li>
                                </ul>
                            </Label>
                            <Label size="s"> <p>Data will only be released under a Data Sharing Agreement (DSA) once the Controller organisation has a Data Sharing Framework Contract (DSFC) in place. </p>
                            </Label>
                            <Label size="m">Before you start:</Label>
                            <Label>The links will open in a new window.</Label>
                            <Label size="s" className="nhsuk-label-font-normal-600">Make sure you read the:</Label>
                            <ul>
                                <li><a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/dars-how-to-make-an-application" target="_blank">application guidance</a></li>
                                <li><a href="https://digital.nhs.uk/services/data-access-request-service-dars/process/data-access-request-service-dars-pre-application-checklist" target="_blank">Data Access Request Service (DARS): pre-application checklist - NHS Digital</a></li>
                                <li><a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance#standards-of-information-expected-in-a-data-access-application" target="_blank">standards of information expected</a></li>
                            </ul>
                            <Label size="s" className="nhsuk-label-font-normal-600">Use the below links to help you understand:</Label>
                            <ul>
                                <li>what <a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-products-and-services" target="_blank">data</a> you are requesting</li>
                                <li>what <a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/dars-how-to-make-an-application#legal-basis-for-providing-the-data" target="_blank">legal basis</a> will apply</li>
                                <li>the <a href="https://digital.nhs.uk/services/data-access-request-service-dars/data-access-request-service-dars-charges" target="_blank">charges</a> that will apply for the data you are requesting</li>
                            </ul>
                            <Label size="s">You can see some examples of applications that have been approved in our <a href="https://digital.nhs.uk/services/data-access-request-service-dars/data-uses-register" target="_blank">Data Uses Register</a>. These previously accepted applications provide examples of answers to questions that will support your application process.</Label>
                            <Label size="s">Not all requests for data will progress to the application stage. It may be that your requirement can be satisfied through existing published data, or you don't have the legal basis to access the data.</Label>
                            <Label size="m">During your application</Label>
                            <Label size="s" className="nhsuk-label-font-normal-600">We will ask you for:</Label>
                            <ul>
                                <li>evidence that Controllers and Processors are complying with the minimum-security standards that support the legal bases required for your application</li>
                                <li>details on the purpose of your data access request</li>
                                <li>details on what will be produced (output) as a result of data processing, along with the clearly stated benefits</li>
                                <li>information on how any output benefits the health and social care system</li>
                                <li>evidence of any funding</li>
                                <li>information on the organisations acting as the Controllers, and Processors, and details of data storage locations</li>
                            </ul>
                            <Label size="s">If you need support with your request for data, you can contact NHS England on 0300 303 5678, or email <a href={'mailto:enquiries@nhsdigital.nhs.uk'}>enquiries@nhsdigital.nhs.uk</a></Label>
                            <Label size="m">After you've submitted your application</Label>
                            <Label size="s" bold>  Our team will:</Label>
                            <ul>
                                <li>review your application</li>
                                <li>work with you to collect any missing details</li>
                            </ul>
                            <Label size="s">
                                The time that it takes for your application to be signed and agreed is dependent on the complexity of your data request.
                            </Label>
                            <br></br>
                            <Button id="nhsuk-button-spinner" onClick={handleClick} >{btnText}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default DSABeforeYouStart;
