import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import {IDSASummary} from '../../models/app.interface'

const initialState: IDSASummary = {
    duration: "",
    title: "",
    dsaStartDate: "",
    dsaEndDate: "",
    applicationReasonSelected: 0,
    dsaDay: "",
    dsaMonth: "",
    dsaYear: ""
}

export const DSASummarySlice = createSlice({
    name: 'DSASummary',
    initialState,
    reducers: {
        setDSASummaryDetailsData: (state: IDSASummary, action: PayloadAction<IDSASummary>) => {
            state = Object.assign(state, action.payload);
        },
        resetDSASummaryDetailsData: (state: IDSASummary, action: PayloadAction<IDSASummary>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setDSASummaryDetailsData, resetDSASummaryDetailsData } = DSASummarySlice.actions;
export const getDSASummaryDetails = (state: RootState) => state.dsaSummaryDetails;
export default DSASummarySlice.reducer;