import React from 'react';
import { useAppSelector } from "../../state/app/hooks";
import { IApplication} from '../../state/models/app.interface';
import { getDSADetails } from "../../state/slice/DSA/DSA.slice";

const ControllerHeader: React.FC = () => {

    const { referenceNumber, totalEstimatedCost }: IApplication = useAppSelector(getDSADetails);

    return (
        <nav className="nhsuk-infoheaderpanel" aria-label="InfoHeaderPanel">
            <div className="nhsuk-width-container">
                <ol className="nhsuk-infoheaderpanel__list">
                    <li className="nhsuk-infoheaderpanel__item nhsuk-infoheaderpanel__item-width">
                        <p className="nhsuk-infoheaderpanel__link">
                            <strong>Reference Number</strong>: {referenceNumber}
                        </p>
                    </li>
                    {totalEstimatedCost &&
                        <li className="nhsuk-infoheaderpanel__item nhsuk-infoheaderpanel__item-width">
                            <p className="nhsuk-infoheaderpanel__link nhsuk-float-right ">
                                <strong>Total Estimated Cost</strong>: {totalEstimatedCost}
                            </p>
                        </li>
                    }
                </ol>
            </div>
        </nav>
    )
}
export default ControllerHeader;