import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { dsaContent, fileTypeSize, routeName } from "../config/page.config";
import { fetchInterceptor } from "../Helper/Utility";

type FetchResponse<T> = {
  loading: boolean;
  response: T | null;
};

type SuccessCallback = (statusCode: number) => void;
type ErrorCallback = (error: string, statusCode: number | undefined) => void;

function useFetch<T>(
  url: string,
  method?: string,
  headers?: HeadersInit,
  body?: object,
  successCallback?: SuccessCallback,
  errorCallback?: ErrorCallback,
  avoidOauthToken?: boolean,
  retryAttempts?: number | undefined
): FetchResponse<T> {
  const navigate = useNavigate();
  const allowedResponses = [200, 201, 204];

  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<T | null>(null);

  const setError = (errorCallback?: ErrorCallback, errorType?: string, statusCode?: number) => {
    if (errorCallback) {
      const error = errorType ? errorType : "";
      const status = statusCode ? statusCode : undefined;
      errorCallback(error, status);
    } else {
        navigate(routeName.serviceError);
    }
  };

  const fetchData = useCallback(async (): Promise<void> => {
      try {
          const response = await fetchInterceptor(url, {
        method: method || "GET",
        headers: {
          "Content-Type": fileTypeSize.fileTypeJson,
            Accept: fileTypeSize.fileTypeJson,
          ...headers,
        },
        body: body ? JSON.stringify(body) : null,
      }, avoidOauthToken, retryAttempts);
      const { status } = response;
      if (response.ok) {
        const responseData = await response.json();
        if (!allowedResponses.includes(status)) {
          setError(errorCallback,dsaContent.invalidStatus, status);
        } else {
          setResponse(responseData);
          successCallback && successCallback(status);
        }
      } else {
        setError(errorCallback, dsaContent.invalidRequest, status);
      }
    } catch (e) {
      setError(errorCallback, dsaContent.reject);
    } finally {
      setLoading(false);
    }
  }, [url, method, body, headers, successCallback, errorCallback]);

  useEffect(() => {
    fetchData();
  }, []);

  return { response, loading };
}

export default useFetch;
