import React, { useState } from "react";
import { Button, ErrorSummary, InsetText, Hint, Input, Details } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IParticipant, IApplication, IParticipantContributor, IAutoCompleteData, IAutoComplete } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getParticipantDetails, setParticipantDetailsData } from "../../../../state/slice/Participant/Participant.slice";
import { participantErrorMessage, dsaContent, dsaErrorMessage, routeName, fileTypeSize, emptyGuid, taskListSectionStatus } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import AutoComplete from "../../../../Components/Shared/AutoComplete";
import { SubmitScreenCohortContributor_URL } from "../../../../config/api-endpoints.config"
import { getCookie, submitSpinner, validateEmail, fetchInterceptor } from "../../../../Helper/Utility";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";

const WhoWillProvide: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const participantDetailsData: IParticipant = useAppSelector(getParticipantDetails);
    const initialState: IParticipant = participantDetailsData || {};
    const [participantData, setParticipantData] = useState(initialState);
    /* To dispatch the setParticipantDetailsData Redux action */
    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantData, ...stateData }));
    }

    const [dataProviderFirstNameLinkError, setDataProviderFirstNameLinkError] = useState("");
    const [dataProviderFirstNameError, setDataProviderFirstNameError] = useState("");
    const [dataProviderLastNameLinkError, setDataProviderLastNameLinkError] = useState("");
    const [dataProviderLastNameError, setDataProviderLastNameError] = useState("");
    const [dataProviderRoleLinkError, setDataProviderRoleLinkError] = useState("");
    const [dataProviderRoleError, setDataProviderRoleError] = useState("");
    const [dataProviderOrganisationLinkError, setDataProviderOrganisationLinkError] = useState("");
    const [dataProviderOrganisationError, setDataProviderOrganisationError] = useState("");
    const [dataProviderEmailLinkError, setDataProviderEmailLinkError] = useState("");
    const [dataProviderConfirmEmailLinkError, setDataProviderConfirmEmailLinkError] = useState("");
    const [dataProviderEmailError, setDataProviderEmailError] = useState("");
    const [dataProviderConfirmEmailError, setDataProviderConfirmEmailError] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IParticipant = {};
        updatedState = {
            ...participantData, [e.target.name]: e.target.value
        }
        resetError(e);
        setParticipantData(updatedState);
        saveDataInStore(updatedState);
    };

    const handleSelectSuggestion = (suggestion: IAutoCompleteData) => {
        let updatedState: IParticipant = {};
        let organizationName = '';
        let organizationId = '';
        if (suggestion != null) {
            organizationName = suggestion?.Name || suggestion?.name!
            organizationId = suggestion?.Id || suggestion?.id!
        }
        updatedState = {
            ...participantData, dataProviderOrganisationName: organizationName,
            dataProviderOrganisationId: organizationId
        }
        setParticipantData(updatedState);
        saveDataInStore(updatedState);
    }

    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueLength = e.target.value.length;
        if (valueLength >= 1) {
            if (e.target.name === dsaContent.dataProviderFirstName) {
                setDataProviderFirstNameError("");
                setDataProviderFirstNameLinkError("");
            }

            if (e.target.name === dsaContent.dataProviderLastName) {
                setDataProviderLastNameError("");
                setDataProviderLastNameLinkError("");
            }

            if (e.target.name === dsaContent.dataProviderRole) {
                setDataProviderRoleError("");
                setDataProviderRoleLinkError("");
            }

            if (e.target.name === dsaContent.dataProviderOrganisationName) {
                setDataProviderOrganisationError("");
                setDataProviderOrganisationLinkError("");
            }

            if (e.target.name === dsaContent.dataProviderEmail) {
                setDataProviderEmailError("");
                setDataProviderEmailLinkError("");
            }
            if (e.target.name === dsaContent.dataProviderConfirmEmail) 
            {
                setDataProviderConfirmEmailError("");
                setDataProviderConfirmEmailLinkError("");
            }
        }
        if (participantData.dataProviderConfirmEmail?.trim() !== '' && participantData.dataProviderEmail?.trim() !== '' && String(participantData.dataProviderConfirmEmail?.trim()) === String(participantData.dataProviderEmail?.trim())) {
            setDataProviderConfirmEmailError("");
            setDataProviderConfirmEmailLinkError("");
        }
    }

    const providerDataForm: JSX.Element = (
        <>
            <div>
                <Input
                    id={dsaContent.dataProviderFirstName}
                    name={dsaContent.dataProviderFirstName}
                    label={dsaContent.lbl_dataProviderFirstName}
                    error={dataProviderFirstNameError}
                    value={participantData?.dataProviderFirstName}
                    onChange={handleChange}
                />
                <Input
                    id={dsaContent.dataProviderLastName}
                    name={dsaContent.dataProviderLastName}
                    label={dsaContent.lbl_dataProviderLastName}
                    error={dataProviderLastNameError}
                    value={participantData?.dataProviderLastName}
                    onChange={handleChange}
                />
                <Input
                    id={dsaContent.dataProviderRole}
                    name={dsaContent.dataProviderRole}
                    label={dsaContent.lbl_dataProviderRole}
                    hint={dsaContent.hint_dataProviderRole}
                    error={dataProviderRoleError}
                    value={participantData?.dataProviderRole}
                    onChange={handleChange}
                />
                <AutoComplete onSelectSuggestion={handleSelectSuggestion} id={dsaContent.dataProviderOrganisationName}
                    name={dsaContent.dataProviderOrganisationName} label={dsaContent.lbl_dataProviderOrganisationName}
                    hint={dsaContent.hint_dataProviderOrganisationName}
                    errorMessage={dataProviderOrganisationError} userInputValue={participantData?.dataProviderOrganisationName}
                    onKeyChange={handleChange}
                ></AutoComplete>
                <Details>
                    <Details.Summary>I cant find the organisation</Details.Summary>
                    <Details.Text>
                        <p>If the organisation is not listed, enter the name of your organisation.</p>
                        <Input
                            id={dsaContent.dataProviderOrganisationNameNotListed}
                            name={dsaContent.dataProviderOrganisationNameNotListed}
                            error={dataProviderOrganisationError}
                            value={participantData?.dataProviderOrganisationNameNotListed}
                            onChange={handleChange}
                        />
                        <p>Once the application has been submitted, the organisation's details will be verified and stored in DARS for future use.</p>
                    </Details.Text>
                </Details>
                <Input
                    id={dsaContent.dataProviderEmail}
                    name={dsaContent.dataProviderEmail}
                    label={dsaContent.lbl_dataProviderEmail}
                    hint={dsaContent.hint_dataProviderEmail}
                    error={dataProviderEmailError}
                    value={participantData?.dataProviderEmail}
                    onChange={handleChange}
                />
                <Input
                    id={dsaContent.dataProviderConfirmEmail}
                    name={dsaContent.dataProviderConfirmEmail}
                    label={dsaContent.lbl_dataProviderConfirmEmail}
                    hint={dsaContent.hint_dataProviderConfirmEmail}
                    error={dataProviderConfirmEmailError}
                    value={participantData?.dataProviderConfirmEmail}
                    onChange={handleChange}
                    onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                        e.preventDefault();
                        return false;
                    }}
                />
            </div>
        </>
    );

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{participantErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href={"#" + dsaContent.dataProviderFirstName}>
                            {dataProviderFirstNameLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href={"#" + dsaContent.dataProviderLastName}>
                            {dataProviderLastNameLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href={"#" + dsaContent.dataProviderRole}>
                            {dataProviderRoleLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href={"#" + dsaContent.dataProviderOrganisationName}>
                            {dataProviderOrganisationLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href={"#" + dsaContent.dataProviderEmail}>
                            {dataProviderEmailLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href={"#" + dsaContent.dataProviderConfirmEmail}>
                            {dataProviderConfirmEmailLinkError}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    );
    /* To Validate the each field's data */

    const ValidateFields = () => {
        let isValid: boolean = true;
        if (!participantData.dataProviderFirstName) {
            setDataProviderFirstNameError(`${participantErrorMessage.dataProviderFirstNameMandatory_ErrorMessage}`);
            setDataProviderFirstNameLinkError(`${participantErrorMessage.dataProviderFirstNameMandatory_ErrorMessage}`);
            isValid = false;
        }
        if (!participantData.dataProviderLastName) {
            setDataProviderLastNameError(`${participantErrorMessage.dataProviderLastNameMandatory_ErrorMessage}`);
            setDataProviderLastNameLinkError(`${participantErrorMessage.dataProviderLastNameMandatory_ErrorMessage}`);
            isValid = false;
        }
        if (!participantData.dataProviderRole) {
            setDataProviderRoleError(`${participantErrorMessage.dataProviderRoleMandatory_ErrorMessage}`);
            setDataProviderRoleLinkError(`${participantErrorMessage.dataProviderRoleMandatory_ErrorMessage}`);
            isValid = false;
        }
        if (participantData.dataProviderOrganisationId === '' && !participantData.dataProviderOrganisationNameNotListed) {
            setDataProviderOrganisationError(`${participantErrorMessage.dataProviderOrganisationNameMandatory_ErrorMessage}`);
            setDataProviderOrganisationLinkError(`${participantErrorMessage.dataProviderOrganisationNameMandatory_ErrorMessage}`);
            isValid = false;
            let updatedState: IParticipant = {};
            updatedState = {
                ...participantData,
                dataProviderOrganisationName: '',
                dataProviderOrganisationNameNotListed: ''
            }
            setParticipantData(updatedState);
            saveDataInStore(updatedState);
        }
        if (!participantData.dataProviderEmail) {
            setDataProviderEmailError(`${participantErrorMessage.dataProviderEmailMandatory_ErrorMessage}`);
            setDataProviderEmailLinkError(`${participantErrorMessage.dataProviderEmailMandatory_ErrorMessage}`);
            isValid = false;
        }
        else if (!validateEmail(participantData.dataProviderEmail)) {
            setDataProviderEmailError(`${dsaErrorMessage.validEmail_ErrorMessage}`);
            setDataProviderEmailLinkError(`${dsaErrorMessage.validEmail_ErrorMessage}`);
            isValid = false;
        }
        if (!participantData.dataProviderConfirmEmail) {
            setDataProviderConfirmEmailError(`${participantErrorMessage.dataProviderConfirmEmailMandatory_ErrorMessage}`);
            setDataProviderConfirmEmailLinkError(`${participantErrorMessage.dataProviderConfirmEmailMandatory_ErrorMessage}`);
            isValid = false;
        }
        else if (!validateEmail(participantData.dataProviderConfirmEmail!)) {
            setDataProviderConfirmEmailError(`${dsaErrorMessage.validConfirmEmail_ErrorMessage}`);
            setDataProviderConfirmEmailLinkError(`${dsaErrorMessage.validConfirmEmail_ErrorMessage}`);
            isValid = false;
        }
        else if (participantData.dataProviderConfirmEmail?.trim() !== '' && participantData.dataProviderEmail?.trim() !== '' && (String(participantData.dataProviderConfirmEmail?.trim()) !== String(participantData.dataProviderEmail?.trim()))) {
            setDataProviderConfirmEmailError(`${participantErrorMessage.dataProviderEmailMatch_ErrorMessage}`);
            setDataProviderConfirmEmailLinkError(`${participantErrorMessage.dataProviderEmailMatch_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, submitContributorData);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const submitContributorData = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "JournalToAdd": {
                "FirstName": participantData?.dataProviderFirstName,
                "LastName": participantData?.dataProviderLastName,
                "Role": participantData?.dataProviderRole,
                "Organisation": participantData?.dataProviderOrganisationName !== '' && participantData?.dataProviderOrganisationName !== undefined ? participantData?.dataProviderOrganisationName : participantData?.dataProviderOrganisationNameNotListed,
                "OrganisationId": participantData?.dataProviderOrganisationId !== '' ? participantData?.dataProviderOrganisationId : emptyGuid,
                "Email": participantData?.dataProviderEmail               
            },
             "SectionStatus": dsaApplicationData.aboutParticipantSectionStatus
        });

        const requestOptions: object = {
            method: 'post',
            headers: { 'content-type': fileTypeSize.fileTypeJson, 'accept': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenCohortContributor_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data) {
                    setParticipantContributorData(data);
                    let sectionStatus: IApplication = {
                        ...dsaApplicationData, aboutParticipantSectionStatus: taskListSectionStatus.inProgress
                    }
                    setDsaApplicationData(sectionStatus);
                    saveApplicationDataInStore(sectionStatus);
                    navigate(routeName.addCohortContributor);
                }
            })
            .catch((data) =>
                console.log(data));
    }

    let contributorArr = participantData?.participantContributorList?.filter((x: IParticipantContributor) => x !== undefined && x !== null && x?.id !== "").length !== 0 ? participantData?.participantContributorList : [];

    const setParticipantContributorData = (response: string) => {
        if (response && response !== "") {
            let updatedState: IParticipant;
            const contributorList: IParticipantContributor = {
                id: response,
                firstName: participantData?.dataProviderFirstName,
                lastName: participantData?.dataProviderLastName,
                role: participantData?.dataProviderRole,
                organisationName: participantData?.dataProviderOrganisationName,
                OrgasationId: participantData?.dataProviderOrganisationId,
                email: participantData?.dataProviderEmail
            }
            contributorArr = Object.assign([], contributorArr);
            contributorArr.push(contributorList);
            updatedState = {
                ...participantData,
                participantContributorList: contributorArr
            }
            setParticipantData(updatedState);
            saveDataInStore(updatedState);
        }
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack />
                            <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                About the participants</span>
                                Who will provide Participant data to NHS England?
                            </h1>
                            {
                                dataProviderFirstNameError !== "" ||
                                    dataProviderLastNameError !== "" ||
                                    dataProviderRoleError !== "" ||
                                    dataProviderOrganisationError !== "" ||
                                    dataProviderEmailError !== "" ||
                                    dataProviderConfirmEmailError !== "" ? errorSummary : null
                            }
                            <InsetText>
                                <p>
                                    Information provided in this section will be used to ensure that the Participant Data Provider has system access that enables them to supply Participant data.</p>
                                <p>If you need help relating to the Participant Data Provider, email <a href="mailto:data.applications@nhsdigital.nhs.uk?subject=Participant Data Provider">data.applications@nhsdigital.nhs.uk</a> quoting 'Participant Data Provider' in the subject field. </p>
                            </InsetText>
                            <div className="nhsuk-input--width-30">
                                <Hint>Enter details of your Participant Data Provider </Hint>
                                {providerDataForm}
                            </div>
                            <div className="nhsuk-u-padding-bottom-4"></div>
                            <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                        </div>
                    </div>
                </main>
            </div>
        </>)
}
export default WhoWillProvide;
