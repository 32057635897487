import React, { useState } from "react";
import { Button, ErrorSummary, InsetText, Radios } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IEvidence } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getEvidenceDetails, setEvidenceDetailsData } from "../../../../state/slice/Evidence/Evidence.slice";
import { dsaContent, dsaErrorMessage, evidenceErrorMessage, routeName } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { submitSpinner } from "../../../../Helper/Utility";

const EvidenceApproval: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [evidenceApprovalErrorMessage, setEvidenceApprovalErrorMessage] = useState("");
    const initialState: IEvidence = useAppSelector(getEvidenceDetails) || {};
    const [evidenceData, setEvidenceData] = useState(initialState)

    const saveEvidenceDataInStore = (stateData?: IEvidence) => {
        dispatch(setEvidenceDetailsData({ ...evidenceData, ...stateData }));
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IEvidence = {};
        if (e.target.value !== null && e.target.value !== undefined) {
            updatedState = {
                ...evidenceData, [e.target.name]: (e.target.value === dsaContent.yes) ? true : false,
                evidenceApproval: e.target.value,
                noEthicsApproval: "",
                isEthicsApproval: true
            }
            setEvidenceData(updatedState);
            saveEvidenceDataInStore(updatedState);
            resetError(e);
        }
    }

    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setEvidenceApprovalErrorMessage("");
        }
    }

    const handleNavigation = () => {
        if (evidenceData.isEvidenceApproval) {
            navigate(routeName.ethicsApproval);
        } else {
            navigate(routeName.noEthicsApproval);
        }
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, handleNavigation);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href={"#" + dsaContent.evidenceApproval}>{summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    /* To Validate the field's data */
    const validateFields = () => {
        let isValid: boolean = true;
        if (!evidenceData.evidenceApproval) {
            setSummaryLinkErrorMessage(`${evidenceErrorMessage.evidenceApproval_ErrorMessage}`);
            setEvidenceApprovalErrorMessage(`${evidenceErrorMessage.evidenceApproval_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack condition={dsaContent.tasklist}></GoBack>
                            <h1>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom ">Ethics approval</span>
                                Does your purpose require ethics approval?</h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <InsetText>
                                <p>Ethics approval is typically required when an application includes a request for identifiable data</p>
                            </InsetText>
                            <Radios
                                name={dsaContent.evidenceApproval}
                                id={dsaContent.evidenceApproval}
                                error={evidenceApprovalErrorMessage}>
                                <Radios.Radio name={dsaContent.evidenceApproval} checked={evidenceData.evidenceApproval === dsaContent.yes} value={dsaContent.yes} onChange={handleChange}>{dsaContent.yes}</Radios.Radio>
                                <Radios.Radio name={dsaContent.evidenceApproval} checked={evidenceData.evidenceApproval === dsaContent.no} value={dsaContent.no} onChange={handleChange}>{dsaContent.no}</Radios.Radio>
                            </Radios>
                            <div className="nhsuk-u-padding-bottom-4">
                            </div>
                            <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                        </div>
                    </div>
                </main>
            </div>
        </>)
}
export default EvidenceApproval;
