import { Footer } from 'nhsuk-react-components';
import { routeName } from '../../config/page.config';

const FooterBasic: React.FC = () => {
    return (
        <>
            <div id="restOfThePage" style={{ height: '1vh' }} />
            <Footer>
                <Footer.List columns>
                    <Footer.ListItem href={routeName.aboutUs} target="_blank">About us</Footer.ListItem>
                    <Footer.ListItem href={ routeName.accessibility} target="_blank">Accessibility</Footer.ListItem>
                    <Footer.ListItem href="https://digital.nhs.uk/about-nhs-digital/contact-us/freedom-of-information" target="_blank">Freedom of information</Footer.ListItem>
                    <Footer.ListItem href="https://digital.nhs.uk/about-nhs-digital/privacy-and-cookies" target="_blank">Privacy and cookies</Footer.ListItem>
                    <Footer.ListItem href="https://www.nhs.uk/our-policies/cookies-policy/" target="_blank">Change cookie settings</Footer.ListItem>
                    <Footer.ListItem href={routeName.contactUs} target="_blank">Contact us</Footer.ListItem>
                    <Footer.ListItem href="https://digital.nhs.uk/about-nhs-digital/terms-and-conditions" target="_blank">Terms and conditions </Footer.ListItem>
                    <Footer.ListItem href="https://digital.nhs.uk/data" target="_blank">Data</Footer.ListItem>
                </Footer.List>
                <Footer.Copyright>&copy; Crown copyright</Footer.Copyright>
            </Footer>
        </>
    )
}

export default FooterBasic;