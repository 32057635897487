import React, { useState } from "react";
import { Button, ErrorSummary, Input } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IProcessor, IApplication, IProcessorList } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { validateAlphabets, validateNumericAlphabets, submitSpinner, getCookie, fetchInterceptor } from "../../../../Helper/Utility";
import { dsaContent, dsaErrorMessage, fileTypeSize, routeName } from "../../../../config/page.config";
import '../../../../Content/css/nhsuk-common.css';
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getProcessorDetails, setProcessorDetails } from '../../../../state/slice/Processor/Processor.slice';
import { getProcessorListDetails, setProcessorListDetailsData } from "../../../../state/slice/ProcessorList/ProcessorList.slice";
import { UpdateScreenDataProcessor_URL } from "../../../../config/api-endpoints.config";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";

/*
  Summary: Using this page, you can add the details of an organization as a processor
           (which aren't found in organization search).
*/

const AddProcessorOrgDetails: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const DSAProcessorDetailsData: IProcessor = useAppSelector(getProcessorDetails);
    const initialState: IProcessor = DSAProcessorDetailsData || {};
    const [processorData, setprocessorData] = useState(initialState);
    const dispatch: AppDispatch = useAppDispatch();
    const savedataInStore = (stateData?: IProcessor) => {
        dispatch(setProcessorDetails({ ...processorData, ...stateData }));
    }
    const processorListDetailsData: IProcessorList = useAppSelector(getProcessorListDetails);
    const initialProcessorListState: IProcessorList = processorListDetailsData || {};
    const [processorListData] = useState(initialProcessorListState);

    const saveProcessorListDataInStore = (stateData?: IProcessorList) => {
        dispatch(setProcessorListDetailsData({ ...processorListData, ...stateData }));
    }

    const isDirtyProcessor = processorData.isDirtyProcessor || false;
    const [alternativeOrganisationNameLinkError, setAlternativeOrganisationNameLinkError] = useState("");
    const [alternativeOrganisationNameError, setAlternativeOrganisationNameError] = useState("");
    const [alternativeStreet1LinkError, setAlternativeStreet1LinkError] = useState("");
    const [alternativeStreet1Error, setAlternativeStreet1Error] = useState("");
    const [alternativeStreet2LinkError, setAlternativeStreet2LinkError] = useState("");
    const [alternativeStreet2Error, setAlternativeStreet2Error] = useState("");
    const [alternativeStreet3LinkError, setAlternativeStreet3LinkError] = useState("");
    const [alternativeStreet3Error, setAlternativeStreet3Error] = useState("");
    const [townOrCityLinkError, setTownOrCityLinkError] = useState("");
    const [townOrCityError, setTownOrCityError] = useState("");
    const [countyLinkError, setCountyLinkError] = useState("");
    const [countyError, setCountyError] = useState("");
    const [postCodeLinkError, setPostCodeLinkError] = useState("");
    const [postCodeError, setPostCodeError] = useState("");
    const [countryLinkError, setCountryLinkError] = useState("");
    const [countryError, setCountryError] = useState("");
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);

    const ValidateFields = () => {
        let isValid: boolean = true;
        if (!processorData.alternativeOrganisationName) {
            setAlternativeOrganisationNameLinkError(`${dsaErrorMessage.alternativeOrganisationNameLink_ErrorMessage}`);
            setAlternativeOrganisationNameError(`${dsaErrorMessage.alternativeOrganisationNameLink_ErrorMessage}`);
            isValid = false;
        }
        if (!processorData.alternativeStreet1) {
            setAlternativeStreet1LinkError(`${dsaErrorMessage.alternativeStreet1Link_ErrorMessage}`);
            setAlternativeStreet1Error(`${dsaErrorMessage.alternativeStreet1Link_ErrorMessage}`);
            isValid = false;
        }
        else if (processorData.alternativeStreet1) {
            if (!validateNumericAlphabets(processorData.alternativeStreet1)) {
                setAlternativeStreet1LinkError(`${dsaErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                setAlternativeStreet1Error(`${dsaErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                isValid = false;
            }
        }

        if (processorData.alternativeStreet2) {
            if (!validateNumericAlphabets(processorData.alternativeStreet2)) {
                setAlternativeStreet2LinkError(`${dsaErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                setAlternativeStreet2Error(`${dsaErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                isValid = false;
            }
        }

        if (processorData.alternativeStreet3) {
            if (!validateNumericAlphabets(processorData.alternativeStreet3)) {
                setAlternativeStreet3LinkError(`${dsaErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                setAlternativeStreet3Error(`${dsaErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                isValid = false;
            }
        }

        if (!processorData.alternativeCity) {
            setTownOrCityLinkError(`${dsaErrorMessage.alternativeCityLink_ErrorMessage}`);
            setTownOrCityError(`${dsaErrorMessage.alternativeCityLink_ErrorMessage}`);
            isValid = false;
        } else if (processorData.alternativeCity) {
            if (!validateAlphabets(processorData.alternativeCity)) {
                setTownOrCityLinkError(`${dsaErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                setTownOrCityError(`${dsaErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                isValid = false;
            }
        }
        if (processorData.alternativeCounty) {
            if (!validateAlphabets(processorData.alternativeCounty)) {
                setCountyLinkError(`${dsaErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                setCountyError(`${dsaErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                isValid = false;
            }
        }

        if (!processorData.alternativePostCode) {
            setPostCodeLinkError(`${dsaErrorMessage.alternativePostCodeLink_ErrorMessage}`);
            setPostCodeError(`${dsaErrorMessage.alternativePostCodeLink_ErrorMessage}`);
            isValid = false;
        }
        else if (processorData.alternativePostCode) {
            if (!validateNumericAlphabets(processorData.alternativePostCode)) {
                setPostCodeLinkError(`${dsaErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                setPostCodeError(`${dsaErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                isValid = false;
            }
        }

        if (!processorData.alternativeCountry) {
            setCountryLinkError(`${dsaErrorMessage.alternativeCountryLink_ErrorMessage}`);
            setCountryError(`${dsaErrorMessage.alternativeCountryLink_ErrorMessage}`);
            isValid = false;
        } else if (processorData.alternativeCountry) {
            if (!validateAlphabets(processorData.alternativeCountry)) {
                setCountryLinkError(`${dsaErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                setCountryError(`${dsaErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                isValid = false;
            }
        }

        return isValid;
    }

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueLength = e.target.value.length;

        if (valueLength >= 1) {

            if (e.target.name === dsaContent.alternativeStreet1) {
                setAlternativeStreet1LinkError("");
                setAlternativeStreet1Error("");
            }

            if (e.target.name === dsaContent.alternativeCity) {
                setTownOrCityLinkError("");
                setTownOrCityError("");
            }

            if (e.target.name === dsaContent.alternativeCounty) {
                setCountyLinkError("");
                setCountyError("");
            }

            if (e.target.name === dsaContent.alternativePostCode) {
                setPostCodeLinkError("");
                setPostCodeError("");
            }

            if (e.target.name === dsaContent.alternativeCountry) {
                setCountryLinkError("");
                setCountryError("");
            }
        }

        if (e.target.name === dsaContent.alternativeOrganisationName) {
            setAlternativeOrganisationNameLinkError("");
            setAlternativeOrganisationNameError("");
        }

        if (e.target.name === dsaContent.alternativeStreet2) {
            setAlternativeStreet2LinkError("");
            setAlternativeStreet2Error("");
        }
        if (e.target.name === dsaContent.alternativeStreet3) {
            setAlternativeStreet3LinkError("");
            setAlternativeStreet3Error("");
        }
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IProcessor = {};
        updatedState = { ...processorData, [e.target.name]: e.target.value, isDirtyProcessor: true }
        resetError(e);
        setprocessorData(updatedState);
        savedataInStore(updatedState);
    };
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (ValidateFields()) {
            submitSpinner(e, submitProcessorOrgDetails);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const submitProcessorOrgDetails = () => {
        let updateState: object = {};
        updateState = {
            ...processorData,
            alternativeOrganisationName: processorData.alternativeOrganisationName,
            alternativeStreet1: processorData.alternativeStreet1,
            alternativeStreet2: processorData.alternativeStreet2,
            alternativeStreet3: processorData.alternativeStreet3,
            alternativeCity: processorData.alternativeCity,
            alternativeCounty: processorData.alternativeCounty,
            alternativePostCode: processorData.alternativePostCode,
            alternativeCountry: processorData.alternativeCountry
        }
        setprocessorData(updateState);
        savedataInStore(updateState);
        if (processorListData.processorDetailsPage && !processorData?.isNewProcessor) {
            if (isDirtyProcessor) {
                const parameters: string = JSON.stringify({
                    "UserAuthTokenId": authenticationTokenCookie,
                    "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
                    "AddressLine1": processorData.alternativeStreet1,
                    "AddressLine2": processorData.alternativeStreet2,
                    "AddressLine3": processorData.alternativeStreet3,
                    "TownCity": processorData.alternativeCity,
                    "County": processorData.alternativeCounty,
                    "Postcode": processorData.alternativePostCode,
                    "Country": processorData.alternativeCountry,
                    "DataProcessorId": processorData.processorId,
                    "OrganisationName": processorData.alternativeOrganisationName,
                    "IsOrganisationDirty": true,
                    "OrganisationId": ""
                });
                const requestOptions: Object = {
                    method: 'POST',
                    headers: { 'Content-Type': fileTypeSize.fileTypeJson },
                    body: parameters
                };
                fetchInterceptor(UpdateScreenDataProcessor_URL, requestOptions)
                    .then(response => {
                            return response.json()
                    })
                    .then(() => {
                        let updatedState: IProcessorList = {
                            ...processorListData, processorDetailsPage: processorListData.processorDetailsPage
                        }
                        saveProcessorListDataInStore(updatedState);
                        navigate(routeName.processorDetails);
                    })
                    .catch(() => navigate(routeName.serviceError));
            }
            else { navigate(routeName.processorDetails); }
        }
        else { navigate(routeName.processorSecurityAssurance); }
    }

    const addressForm: JSX.Element = (
        <>
            <div>
                <Input
                    id={dsaContent.alternativeOrganisationName}
                    name={dsaContent.alternativeOrganisationName}
                    label={dsaContent.lbl_organisationName}
                    error={alternativeOrganisationNameError}
                    value={processorData?.alternativeOrganisationName}
                    onChange={handleChange}
                />

                <Input
                    id={dsaContent.alternativeStreet1}
                    name={dsaContent.alternativeStreet1}
                    label={dsaContent.lbl_addressLine1}
                    error={alternativeStreet1Error}
                    value={processorData?.alternativeStreet1}
                    onChange={handleChange}
                />

                <Input
                    id={dsaContent.alternativeStreet2}
                    name={dsaContent.alternativeStreet2}
                    label={dsaContent.lbl_addressLine2}
                    error={alternativeStreet2Error}
                    value={processorData?.alternativeStreet2}
                    onChange={handleChange}
                />

                <Input
                    id={dsaContent.alternativeStreet3}
                    name={dsaContent.alternativeStreet3}
                    label={dsaContent.lbl_addressLine3}
                    error={alternativeStreet3Error}
                    value={processorData?.alternativeStreet3}
                    onChange={handleChange}
                />

                <Input
                    id={dsaContent.alternativeCity}
                    name={dsaContent.alternativeCity}
                    label={dsaContent.lbl_townorCity}
                    error={townOrCityError}
                    value={processorData?.alternativeCity}
                    onChange={handleChange}
                />

                <Input
                    id={dsaContent.alternativePostCode}
                    name={dsaContent.alternativePostCode}
                    label={dsaContent.lbl_postcode}
                    error={postCodeError}
                    value={processorData?.alternativePostCode}
                    onChange={handleChange}
                />
                <Input
                    id={dsaContent.alternativeCounty}
                    name={dsaContent.alternativeCounty}
                    label={dsaContent.lbl_county}
                    error={countyError}
                    value={processorData?.alternativeCounty}
                    onChange={handleChange}
                />


                <Input
                    id={dsaContent.alternativeCountry}
                    name={dsaContent.alternativeCountry}
                    label={dsaContent.lbl_country}
                    error={countryError}
                    value={processorData?.alternativeCountry}
                    onChange={handleChange}
                />
            </div>
        </>
    );

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.summaryTitle_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#alternativeOrganisationName">
                            {alternativeOrganisationNameLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeStreet1">
                            {alternativeStreet1LinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeStreet2">
                            {alternativeStreet2LinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeStreet3">
                            {alternativeStreet3LinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeCity">
                            {townOrCityLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativePostCode">
                            {postCodeLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeCounty">
                            {countyLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeCountry">
                            {countryLinkError}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    );


    return (
        <> <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-full">
                           <GoBack/>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the Processor
                            </span>
                            <h1>Add organisation details</h1>
                        </div>

                        <div className="nhsuk-grid-column-two-thirds nhsuk-input--width-30">
                            {
                                alternativeStreet1Error !== "" ||
                                    alternativeStreet2Error !== "" ||
                                    alternativeStreet3Error !== "" ||
                                    countryError !== "" ||
                                    townOrCityError !== "" ||
                                    countyError !== "" ||
                                    postCodeError !== "" ? errorSummary : null
                            }

                            {addressForm}
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnSaveAndContinue}</Button>

                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}
export default AddProcessorOrgDetails;