import React, { useState, useEffect } from "react";
import { Button, Details, InsetText, SummaryList, Table, Tag } from "nhsuk-react-components";
import { AppDispatch } from '../../state/app/store';
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../state/app/hooks';
import { IApiResDSFCApplicationsApproval, IApiResDSFCContractDetails, IApiResDSFCDashboard, IDARSSecurityRoles, IDSFCContractDashboard, IDSFCContractDetails, IDSFCDashboard, ISecurityRole, IUserDetails } from "../../state/models/app.interface";
import { setDSFCContractDetailsData } from "../../state/slice/DSFC/DSFCContractDetails.slice";
import { setDSFCDashboardData } from "../../state/slice/Dashboard/Dashboard.slice";
import { getCookie, fetchInterceptor, getDate, updatePageTitle } from "../../Helper/Utility";
import { LoadScreenDSFCApproval_URL, LoadScreenDSFC_URL, DSFCList_URL, SubmitScreenDSFCRenewal_URL } from "../../config/api-endpoints.config";
import { getUserDetails } from "../../state/slice/Header/Header.slice";
import { applicationChangeType, contractStatus, dsfcContent, fileTypeSize, pageTitles, routeName } from "../../config/page.config";

const Dashboard: React.FC = () => {

    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;

    const UserDetailsData: IUserDetails = useAppSelector(getUserDetails);
    const initialStateofUserData: IUserDetails = UserDetailsData || {};
    const [userData, setUserData] = useState(initialStateofUserData);
    /* DSFC */
    const initialState: IDSFCContractDetails = {};
    const [dsfcData, setDSFCData] = useState(initialState);
    const [hasActiveContract, setHasActiveContract] = useState(false);
    const { dsfcApproverSecurity, dsaApproverSecurity, dsaApplicantSecurity, dsfcApplicantSecurity, dsfcApprover, dsfcApplicantApprover } = useAppSelector(getUserDetails);


    const resetdataInStore = (stateData?: IDSFCContractDetails) => {
        dispatch(setDSFCContractDetailsData({ ...dsfcData, ...stateData }));
    }
    const savedataInStore = (stateData?: IDSFCContractDetails) => {
        dispatch(setDSFCContractDetailsData({ ...dsfcData, ...stateData }));
    }

    const resetStoreData = () => {
        let updatedState: IDSFCContractDetails = {};
        updatedState = {
            ...dsfcData,
            isICO: false,
            dsfcApplicationId: "",
            contractNumber: "",
            contractDuration: "",
            version: "",
            organisation: "",
            organisationAddress: "",
            companiesHouseICO: "",
            alternativeOrganisationName: "",
            alternativeStreet1: "",
            alternativeStreet2: "",
            alternativeStreet3: "",
            alternativeCity: "",
            alternativeCounty: "",
            alternativePostCode: "",
            alternativeCountry: "",
            signatoryDetalis: [{}],
            customerSignatory: "",
            fullName: "",
            emailAddress: "",
            editionName: "",
            dashboard: true,
            dsfcintro: false,
            dsfcdetails: false,
            dsfcsignatory: false,
            dsfcpreview: false,
            dsfcsubmit: false,
            dsfcapprove: false,
            dsfcagree: false,
            startDate: "",
            endDate: "",
            redirectionURL: "",
            versionTemplateFileURL: "",
            isRenewal: false
        }
        resetdataInStore(updatedState);
    };

    const handleClick = (event: React.SyntheticEvent<HTMLAnchorElement>, contractId: String = ``) => {
        event?.preventDefault();
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "DSFCApplicationId": contractId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(LoadScreenDSFCApproval_URL, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                setDSFCApproveDetails(data);
                navigate(routeName.dsfcApprove)
            })
            .catch(() => navigate(routeName.serviceError));
    };

    const setDSFCApproveDetails = (dsfcApprove: {
        Id: string,
        ContractNumber: string,
        StartDate: string,
        EndDate: string,
        Edition: {Name:string}
    }) => {
        let updatedState: IDSFCContractDetails = {};
        updatedState = {
            ...dsfcData,
            dsfcApplicationId: dsfcApprove.Id,
            contractNumber: dsfcApprove.ContractNumber,
            startDate: dsfcApprove.StartDate,
            endDate: dsfcApprove.EndDate,
            version: dsfcApprove.Edition.Name
        }

        setDSFCData(updatedState);
        savedataInStore(updatedState);
    }
    const onClick = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "DSFCApplicationId": process.env.REACT_APP_DSFC_APPLICATION_ID
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(LoadScreenDSFC_URL, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                setSignedDSFCDetails(data);
                navigate(routeName.latestSignedContract)
            })
            .catch(() => navigate(routeName.serviceError));
    };

    const setSignedDSFCDetails = (signedDSFC: {
        Id: string,
        ContractNumber: string,
        StartDate: string,
        EndDate: string,
        Edition: { Name: string }
    }) => {
        let updatedState: IDSFCContractDetails = {};
        updatedState = {
            ...dsfcData,
            dsfcApplicationId: signedDSFC.Id,
            contractNumber: signedDSFC.ContractNumber,
            startDate: signedDSFC.StartDate,
            endDate: signedDSFC.EndDate,
            version: signedDSFC.Edition.Name
        }

        setDSFCData(updatedState);
        savedataInStore(updatedState);
    }

    /*Dashboard*/
    const [showDSFCs, setShowDSFCs] = useState(false);
    const [hasDSFCs, setHasDSFCs] = useState(false);
    const initialDSFCDashboardState: IDSFCDashboard = {};
    const [dsfcDashboardData, setDashboardDSFCData] = useState(initialDSFCDashboardState);
    const saveDSFCDashboardDataInStore = (stateData?: IDSFCDashboard) => {
        dispatch(setDSFCDashboardData({ ...stateData, ...setDashboardDSFCData }));
    }

    const getDSFCDashboardDetails = () => {
        const parameters: string = JSON.stringify(authenticationTokenCookie);

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(DSFCList_URL, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                setDSFCDashboardDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    };

    const setDSFCDashboardDetails = (dsfcList: IApiResDSFCDashboard) => {
        if (dsfcList.DSFCApplicationList.length > 0 || (dsfcList.DSFCApprovalList.length > 0)) {
            let updatedState: IDSFCDashboard = {};
            let dsfcContractDetail: IDSFCContractDashboard = {};
            let dsfcContractDetailsList: IDSFCContractDashboard[] = [];
            let dsfcApprovalDetail: IDSFCContractDashboard = {};
            let dsfcApprovalDetailList: IDSFCContractDashboard[] = [];               
            let dsfcApplicantSecurityRoleList: IDARSSecurityRoles[] = [];
            dsfcList.DSFCApplicationList.length > 0 && dsfcList.DSFCApplicationList.map((dsfcContract: IApiResDSFCApplicationsApproval) => {
                dsfcContractDetail = {
                    dsfcContId: dsfcContract.Id,
                    dsfcContContractNumber: dsfcContract.ContractNumber,
                    dsfcContOrganization: dsfcContract.Organisation.Name,
                    dsfcContEndDate: getDate(dsfcContract.EndDate),
                    dsfcContStatus: contractStatus[dsfcContract.DSFCAppCustomStatus],
                    dsfcConVersion: dsfcContract.Edition.Name,
                    dsfcIsEligibleForRenewal: dsfcContract.IsEligibleForRenewal
                }
                dsfcContractDetailsList.push(dsfcContractDetail);
            })
            dsfcList.DSFCApprovalList.length > 0 && dsfcList.DSFCApprovalList.map((dsfcApprovalContract: IApiResDSFCApplicationsApproval) => {
                dsfcApprovalDetail = {
                    dsfcContId:dsfcApprovalContract.Id,
                    dsfcContContractNumber: dsfcApprovalContract.ContractNumber,
                    dsfcContOrganization: dsfcApprovalContract.Organisation.Name,
                    dsfcContEndDate: getDate(dsfcApprovalContract.EndDate),
                    dsfcContStatus: contractStatus[dsfcApprovalContract.DSFCAppCustomStatus],
                    dsfcConVersion: dsfcApprovalContract.Edition.Name,
                    dfscCurrentUserIsSignatory : dsfcApprovalContract.CurrentUserIsSignatory

                }
                dsfcApprovalDetailList.push(dsfcApprovalDetail);
            })
            dsfcList.DSFCApplicationDARSSecurityRoleList.length > 0 && dsfcList.DSFCApplicationDARSSecurityRoleList.map(({Role: darsSecurityRole}: ISecurityRole) => {
               dsfcApplicantSecurityRoleList.push({darsSecurityRole});
            })

            updatedState = {
                ...dsfcDashboardData,
                dsfcContractDetails: dsfcContractDetailsList,
                dsfcAwaitingSignatureContractDetails: dsfcApprovalDetailList,
                darsApplicantSecurityRoleDetails: dsfcApplicantSecurityRoleList
            }
            setHasActiveContract(dsfcList.HasDSFCApplication);
            setDashboardDSFCData(updatedState);
            saveDSFCDashboardDataInStore(updatedState);
            setHasDSFCs(true);
        }
        setShowDSFCs(true);
    }
    const navigateToIntroduction = () => {
        navigate(routeName.dsfcIntro);
    }
    const setDFSCStatusTag = (status: string) => {
        switch (status) {
            case contractStatus[1001]:
                return (
                    <Tag color="blue">{status.replace(/&nbsp;/g, ' ')}</Tag>
                );
            case contractStatus[1002]:
            case contractStatus[1003]:
            case contractStatus[1005]:
                return (
                    <Tag>{status}</Tag>
                );
            case contractStatus[1004]:
            case contractStatus[1006]:
            case contractStatus[1008]:


                return (
                    <Tag color="orange">{status.replace(/&nbsp;/g, ' ')}</Tag>
                );
            case contractStatus[1007]:
                return (
                    <Tag color="grey">{status.replace(/&nbsp;/g, ' ')}</Tag>
                );
        }
    }
    const navigateTodsfcdownload = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,contractId: string, contractNumber: string, enddate: string, dsfcVersion: string, dsfcStatus: string) => {
        e.preventDefault();
        let updatedState: IDSFCContractDetails = {};
        updatedState = {
            ...dsfcData,
            dsfcApplicationId: contractId,
            contractNumber: contractNumber,
            endDate: enddate,
            version: dsfcVersion,
            isFullySigned: (dsfcStatus == contractStatus["1005"]) ? true: false
        }
        setDSFCData(updatedState);
        savedataInStore(updatedState);
        navigate(routeName.dsfcDownload);
    }
    const openDSFCLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, contractId: string, contractNumber: string) => {
        e.preventDefault();
        let updatedState: IDSFCContractDetails = {};
        updatedState = {
            ...dsfcData,
            dsfcApplicationId: contractId,
            contractNumber: contractNumber
        }
        setDSFCData(updatedState);
        savedataInStore(updatedState);
        navigate(routeName.dsfcIntro);
    }

    const renewal = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, contractId: string) => {
        e.preventDefault();
        let updatedState: IDSFCContractDetails = {};
        updatedState = {
            ...dsfcData,
            dashboard: true,
            dsfcintro: true,
            isRenewal: true,

        }
        setDSFCData(updatedState);
        savedataInStore(updatedState);
        if (localStorage.getItem(dsfcContent.renewalDSFCInProgress) === dsfcContent.false) {
            localStorage.setItem(dsfcContent.renewalDSFCInProgress, dsfcContent.true);

            const parameters: string = JSON.stringify({
                "UserAuthTokenId": authenticationTokenCookie,
                "DARSDSFCRequest": {
                    "DSFCApplicationId": contractId,
                    "ApplicationChangeType": applicationChangeType.Renewal
                }
            });
            const requestOptions: Object = {
                method: 'POST',
                headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
                body: parameters
            };
            fetchInterceptor(SubmitScreenDSFCRenewal_URL, requestOptions)
                .then(response => {

                    return response.json()

                })
                .then(data => {
                    setDSFCDetails(data);
                    navigate(routeName.dsfcDetails)
                })
                .catch(() => navigate(routeName.serviceError));
        }
    }
    const setDSFCDetails = (dsfcDetails: IApiResDSFCContractDetails) => {
        let updatedState: IDSFCContractDetails = {};
        updatedState = {
            ...dsfcData,
            dsfcApplicationId: dsfcDetails.DSFCApplicationId,
            organisation: dsfcDetails.Organisation,
            contractNumber: dsfcDetails.ContractNumber,
            contractDuration: dsfcDetails.ContractDuration,
            version: dsfcDetails.Version,
            organisationAddress: dsfcDetails.OrganisationAddress,
            companiesHouseICO: dsfcDetails?.CompaniesHouseICO?.toLowerCase(),
            alternativeStreet1: dsfcDetails?.CompaniesHouseICO?.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativeStreet1 : "",
            alternativeStreet2: dsfcDetails?.CompaniesHouseICO?.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativeStreet2 : "",
            alternativeStreet3: dsfcDetails?.CompaniesHouseICO?.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativeStreet3 : "",
            alternativeCity: dsfcDetails?.CompaniesHouseICO?.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativeCity : "",
            alternativeCounty: dsfcDetails?.CompaniesHouseICO?.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativeCounty : "",
            alternativePostCode: dsfcDetails?.CompaniesHouseICO?.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativePostCode : "",
            alternativeCountry: dsfcDetails?.CompaniesHouseICO?.trim().toLowerCase() === dsfcContent.no ? dsfcDetails.AlternativeCountry : "",
            dsfcintro: true,
            editionName: dsfcDetails?.EditionName?.trim()
        }
        setDSFCData(updatedState);
        savedataInStore(updatedState);
    }

    const hasDsfcRoles = (): boolean | undefined => {
        return (dsaApproverSecurity || dsaApplicantSecurity || dsfcApproverSecurity || dsfcApplicantSecurity || dsfcApprover || dsfcApplicantApprover);
    };

    useEffect(() => {
        resetStoreData();
        localStorage.setItem(dsfcContent.newDSFCInProgress, dsfcContent.false);
        localStorage.setItem(dsfcContent.renewalDSFCInProgress, dsfcContent.false);
        getDSFCDashboardDetails();
        setUserData(UserDetailsData);
        updatePageTitle(pageTitles.dashboard);
    }, [UserDetailsData]);

    return (
        <div className="nhsuk-width-container">
            <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                <div className="nhsuk-grid-row">
                    {!showDSFCs ?
                        <div className="nhsuk-grid-column-full">
                            <div className="nhsuk-loader__container">
                                <h1 className="nhsuk-heading-l">Loading results</h1>
                            </div>
                            <div className="nhsuk-loader__container">
                                <div className="nhsuk-loader">
                                </div>
                            </div>
                        </div>
                        : (dsfcApplicantSecurity || dsfcApproverSecurity)?
                            (hasDSFCs && (dsfcDashboardData.dsfcContractDetails!.length > 0 || (dsfcDashboardData.dsfcAwaitingSignatureContractDetails!.length > 0)) ?
                            <div className="nhsuk-width-container">
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                {dsfcDashboardData.dsfcAwaitingSignatureContractDetails!.length > 0 && dsfcApproverSecurity ? <>
                                    <h1>
                                        <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                            {userData?.userOrgName}
                                        </span>
                                        Approve Data Sharing Framework Contract </h1>
                                    <h3>Approve Application
                                        <div className="nhsuk-hint">
                                            Here, you can see the Data Sharing Framework Contract (DSFC) application that is awaiting approval.
                                        </div>
                                    </h3>
                                    <Table responsive>
                                        <Table.Head>
                                            <Table.Row>
                                                <Table.Cell>DSFC reference</Table.Cell>
                                                <Table.Cell>Version</Table.Cell>
                                                <Table.Cell>Organisation</Table.Cell>
                                                <Table.Cell>End date</Table.Cell>
                                                <Table.Cell>Status</Table.Cell>
                                                <Table.Cell>Action</Table.Cell>
                                            </Table.Row>
                                        </Table.Head>
                                        <Table.Body>
                                            {
                                                dsfcDashboardData.dsfcAwaitingSignatureContractDetails?.map((dsfcApprovalDetail: IDSFCContractDashboard, index: number) => (
                                                    <Table.Row key={index + 1} id={dsfcApprovalDetail?.dsfcContId}>
                                                        <Table.Cell>{dsfcApprovalDetail?.dsfcContContractNumber}</Table.Cell>
                                                        <Table.Cell>{dsfcApprovalDetail?.dsfcConVersion ? dsfcApprovalDetail?.dsfcConVersion : '-'}</Table.Cell>
                                                        <Table.Cell>{dsfcApprovalDetail?.dsfcContOrganization ? dsfcApprovalDetail?.dsfcContOrganization : '-'}</Table.Cell>
                                                        <Table.Cell>{dsfcApprovalDetail?.dsfcContEndDate ? dsfcApprovalDetail?.dsfcContEndDate : '-'}</Table.Cell>
                                                        <Table.Cell>{setDFSCStatusTag(dsfcApprovalDetail?.dsfcContStatus!)}</Table.Cell>
                                                        <Table.Cell className="nhsuk-u-text-align-right">
                                                            {
                                                               (dsfcApprovalDetail?.dfscCurrentUserIsSignatory && dsfcApprovalDetail?.dsfcContStatus == contractStatus[1008]) ? <a href="#" onClick={(event) => handleClick(event, dsfcApprovalDetail?.dsfcContId)}>Open</a> : <a href="#" onClick={(e) => navigateTodsfcdownload(e, dsfcApprovalDetail?.dsfcContId!, dsfcApprovalDetail?.dsfcContContractNumber!, dsfcApprovalDetail?.dsfcContEndDate!, dsfcApprovalDetail?.dsfcConVersion!, dsfcApprovalDetail?.dsfcContStatus!)} >Download</a>
                                                            }
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                                )
                                            }
                                        </Table.Body>
                                    </Table>
                                </> : null}

                                <div className="nhsuk-u-padding-bottom-4"></div>
                                {dsfcDashboardData.dsfcContractDetails!.length > 0 && <>
                                    <h1>
                                        Data Sharing Framework<br /> Contracts </h1>
                                    <Table responsive>
                                        <Table.Head>
                                            <Table.Row>
                                                <Table.Cell>DSFC reference</Table.Cell>
                                                <Table.Cell>Version</Table.Cell>
                                                <Table.Cell>Organisation</Table.Cell>
                                                <Table.Cell>End date</Table.Cell>
                                                <Table.Cell>Status</Table.Cell>
                                                {hasDsfcRoles() && <Table.Cell>Action</Table.Cell>}
                                            </Table.Row>
                                        </Table.Head>
                                        <Table.Body>
                                            {
                                                dsfcDashboardData.dsfcContractDetails?.map((dsfcContractDetail: IDSFCContractDashboard, index: number) => (
                                                    <Table.Row key={index + 1} id={dsfcContractDetail?.dsfcContId}>
                                                        <Table.Cell>{dsfcContractDetail?.dsfcContContractNumber}</Table.Cell>
                                                        <Table.Cell>{dsfcContractDetail?.dsfcConVersion ? dsfcContractDetail?.dsfcConVersion : '-'}</Table.Cell>
                                                        <Table.Cell>{dsfcContractDetail?.dsfcContOrganization ? dsfcContractDetail?.dsfcContOrganization : '-'}</Table.Cell>
                                                        <Table.Cell>{dsfcContractDetail?.dsfcContEndDate ? dsfcContractDetail?.dsfcContEndDate : '-'}</Table.Cell>
                                                        <Table.Cell>{setDFSCStatusTag(dsfcContractDetail?.dsfcContStatus!)}</Table.Cell>
                                                        {
                                                            hasDsfcRoles() && <Table.Cell className="nhsuk-u-text-align-right">
                                                                {
                                                                    dsfcContractDetail?.dsfcContStatus == contractStatus[1001] && dsfcDashboardData.darsApplicantSecurityRoleDetails!.length > 0 ? <><a href="#" onClick={(e) => openDSFCLink(e, dsfcContractDetail?.dsfcContId!, dsfcContractDetail?.dsfcContContractNumber!)}>Open</a><br /></> : null
                                                                }
                                                                <a href="#" onClick={(e) => navigateTodsfcdownload(e, dsfcContractDetail?.dsfcContId!, dsfcContractDetail?.dsfcContContractNumber!, dsfcContractDetail?.dsfcContEndDate!, dsfcContractDetail?.dsfcConVersion!, dsfcContractDetail?.dsfcContStatus!)} >Download</a>
                                                                {
                                                                    ((dsfcContractDetail?.dsfcContStatus == contractStatus[1005] || dsfcContractDetail?.dsfcContStatus == contractStatus[1006] || dsfcContractDetail?.dsfcContStatus == contractStatus[1007]) && dsfcContractDetail?.dsfcIsEligibleForRenewal == true && dsfcDashboardData.darsApplicantSecurityRoleDetails!.length > 0) ? <><br /><a href="#" onClick={(e) => renewal(e, dsfcContractDetail?.dsfcContId!)}>Renew</a></> : null
                                                                }
                                                            </Table.Cell>
                                                        }
                                                    </Table.Row>
                                                )
                                                )
                                            }

                                        </Table.Body>
                                    </Table>
                                </>
                                }

                                <div className="nhsuk-u-padding-bottom-7"></div>

                                {(!hasActiveContract && hasDsfcRoles()) && <Button onClick={navigateToIntroduction}>{dsfcContent.applyForDSFC}</Button> }
                                <Details>
                                    <Details.Summary>What do these statuses mean?</Details.Summary>
                                    <Details.Text>
                                        <SummaryList>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag color="blue">{contractStatus[1001]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Application has been started but is not yet completed</SummaryList.Value>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag>{contractStatus[1002]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Application has been completed and submitted to DARS</SummaryList.Value>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag>{contractStatus[1003]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Application is undergoing final checks, we may ask you for more information</SummaryList.Value>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag color="orange">{contractStatus[1004]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Application has been approved by DARS and is ready to be signed by the authorised signatory</SummaryList.Value>
                                            </SummaryList.Row>
                                            {dsfcApproverSecurity && <SummaryList.Row>
                                                <SummaryList.Key><Tag color="orange">{contractStatus[1008]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Application has been approved by DARS and awaits your signature</SummaryList.Value>
                                            </SummaryList.Row>}
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag color="orange">{contractStatus[1006]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>The Data Sharing Framework Contract is due to expire or its terms and conditions have been updated and require your approval</SummaryList.Value>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag>{contractStatus[1005]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Your organisation has an existing Data Sharing Framework Contract</SummaryList.Value>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag color="grey">{contractStatus[1007]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>The Data Sharing Framework Contract has expired</SummaryList.Value>
                                            </SummaryList.Row>
                                        </SummaryList>
                                    </Details.Text>
                                </Details>
                            </div>
                            :
                            <div className="nhsuk-width-container">
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <h1>
                                    Data Sharing Framework<br /> Contract </h1>
                                <InsetText>
                                    <p>
                                        It looks like you do not currently have a Data Sharing Framework Contract (DSFC) in place and have not made an application for one. If you think this is wrong, contact us on 0300 303 5678, or email <a href='mailto:enquiries@nhsdigital.nhs.uk'>enquiries@nhsdigital.nhs.uk</a> quoting 'DSFC', along with your DSFC reference number in the subject field. In your email, tell us the name of the organisation that you would expect to see a DSFC for.
                                    </p>
                                    <p>
                                        A DSFC reference number usually begins with 'CON'.
                                    </p>
                                    <p>
                                        If you need help with applying for a DSFC, refer to the <Link to="/guidance#datasharing">DSFC Guidance</Link>.
                                    </p>
                                </InsetText>
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                {(!hasActiveContract && hasDsfcRoles()) && <Button onClick={navigateToIntroduction}>{dsfcContent.applyForDSFC}</Button>}
                            </div>
                            ) : <div className="nhsuk-width-container">
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <h1>Welcome to the Data Sharing Framework Contract (DSFC) page</h1>
                                <InsetText>
                                    <p>The DSFC is an over-arching document that specifies the basis for data to be shared and outlines the terms and conditions of how data must be managed once it has been released to the Controller.</p>
                                    <p>While you may start and save an application for data before you have a DSFC, you will need to have a DSFC before your application is accepted and data is released.</p>
                                    <p>All organisations that request data from NHS England are required to have a DSFC. A DSFC ensures that data-sharing controls are in place, and grants NHS England the right to carry out audits to check that data is processed in line with the terms of the DSFC and DSA.</p>
                                    <p>You can use DARS Online to apply for, approve, or renew a DSFC. Anyone can apply for a DSFC on behalf of an organisation, but the person that approves the DSFC is usually a senior staff member such as a Senior Information Risk Officer (SIRO) or equivalent.</p>
                                    <p>You are seeing this message because either your organisation does not have a DSFC in place, or you do not have permission to view it. Please email <a href='mailto:enquiries@nhsdigital.nhs.uk'>enquiries@nhsdigital.nhs.uk</a> to resolve any issues relating to your DSFC.</p>
                                    
                                </InsetText>
                                <div className="nhsuk-u-padding-bottom-7"></div></div>
                    }
                </div>
            </main>
        </div>
    )
}

export default Dashboard;