import { Button, Checkboxes, ErrorMessage, ErrorSummary, Input, Label, SummaryList } from "nhsuk-react-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Attachment from "../../../Components/Attachment/Attachment";
import Loader from "../../../Components/Loader/Loader";
import GoBack from "../../../Components/Shared/GoBack";
import { ApproveApplication_URL } from "../../../config/api-endpoints.config";
import { dsaContent, dsaErrorMessage, fileTypeSize, routeName, submitApplicationErrorMessage } from "../../../config/page.config";
import { downloadPdfFile, fetchInterceptor, getCookie, getDate, setGeneratedBlobUrl, submitSpinner } from "../../../Helper/Utility";
import { useAppSelector, useAppDispatch } from "../../../state/app/hooks";
import { IApiResActiveAgreementList, IApplication, IUserDetails } from "../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../state/slice/DSA/DSA.slice";
import { getUserDetails } from "../../../state/slice/Header/Header.slice";
import { AppDispatch } from "../../../state/app/store";

/* PO Number Page*/
const PONumber: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [blobUrl, setBlobUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const UserDetailsData: IUserDetails = useAppSelector(getUserDetails);
    const initialStateofUserData: IUserDetails = UserDetailsData || {};
    const [userData, setUserData] = useState(initialStateofUserData);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData, setDsaApplicationData] = useState(initialApplicationState);
    const [isConfirmChecked, setIsConfirmChecked] = useState("");
    const [confirmError, setConfirmError] = useState("");
    const [downloadClicked, setDownloadClicked] = useState(false);
    const abortController = new AbortController();
    const [poNumberLinkError, setPONumberLinkError] = useState("");
    const [poNumberError, setpoNumberError] = useState("");
    /*Get Version from Reference Number*/
    const getVersionNumber = () => dsaApplicationData?.referenceNumber?.split('-')[dsaApplicationData?.referenceNumber?.split('-').length - 1].slice(1);

    /*Get DSA PDF file */
    const getDsaPDFFile = () => {
        downloadPdfFile(abortController, dsaApplicationData?.id!, dsaApplicationData?.referenceNumber!, setBlobUrl);
    }
    const saveDSAApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    /* Handle Checkbox change*/
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === dsaContent.requiredPONumber) {
            updateStore(e, e.target.checked);
        }
        else if (e.target.name === dsaContent.poNumber) {
            updateStore(e, false);
        }
    }

    const updateStore = (e: React.ChangeEvent<HTMLInputElement>, isPONumber: boolean) => {
        let updatedState: IApplication = {};
        updatedState = {
            ...dsaApplicationData,
            poNumber: !isPONumber && (e.target.name === dsaContent.poNumber) ? e.target.value : "",
            isRequiredPOnumber: isPONumber

        }
        setDsaApplicationData(updatedState);
        saveDSAApplicationDataInStore(updatedState);
        resetError(e);
    }

    /* Submit functionality*/
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (validateFields()) {
            setConfirmError("");
            navigate(routeName.dsaArrove);
        }
        else {
            setConfirmError(`${dsaErrorMessage.dsaApprovalConfirm_ErrorMessage}`);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    

    const handleDownload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        event.preventDefault();
        setDownloadClicked(true);
    }

    const validateFields = () => {
        let isValid: boolean = true;
        if (!dsaApplicationData.poNumber && !dsaApplicationData.isRequiredPOnumber && dsaApplicationData.isorganisationpaying) {
            setPONumberLinkError(`${submitApplicationErrorMessage.dsaPONumber_ErrorMessage}`);
            setpoNumberError(`${submitApplicationErrorMessage.dsaPONumber_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueLength = e.target.value.length;
        if (valueLength >= 1) {
            if ((e.target.name === dsaContent.poNumber) || e.target.name === dsaContent.requiredPONumber) {
                setpoNumberError("");
                setPONumberLinkError("");
                setConfirmError("");
            }
        }

    }

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#poNumber">
                            {poNumberLinkError}
                        </ErrorSummary.Item>                        
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    useEffect(() => {
        getDsaPDFFile();
        setUserData(UserDetailsData);
        setLoading(false);
    }, [UserDetailsData]);

    useEffect(() => {
        if (downloadClicked && blobUrl) {
            setGeneratedBlobUrl(blobUrl);
            setDownloadClicked(false);
        }
    }, [downloadClicked, blobUrl]);

    useEffect(() => {
        return (() => {
            abortController.abort();
        });
    }, [navigate]);

    return (
        <>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <div className="nhsuk-grid-column-full">
                                <Loader loadingText={dsaContent.txtLoading} />
                            </div>
                            :
                            <>
                                <div className="nhsuk-grid-column-two-thirds">
                                    <GoBack></GoBack>
                                    <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                        {userData?.userOrgName}
                                    </span>
                                    <h1>Purchase Order number required</h1>
                                    {confirmError !== "" ? errorSummary : null}
                                    <Label>Before agreeing to the terms and conditions of the Data Sharing Agreement (DSA) we need you to provide/confirm the purchase order (PO) number.</Label>
                                    <div className="nhsuk-u-padding-bottom-3"></div>
                                    <Label>If you are paying without a PO number or there is no charge select PO number not required below.</Label>
                                    <div className="nhsuk-u-padding-bottom-3"></div>
                                    <Label size="m">Your application</Label>
                                    <SummaryList noBorder>
                                        <SummaryList.Row>
                                            <SummaryList.Key>Reference number</SummaryList.Key>
                                            <SummaryList.Value>{dsaApplicationData?.referenceNumber}</SummaryList.Value>
                                        </SummaryList.Row>
                                        <SummaryList.Row>
                                            <SummaryList.Key>Start and end date</SummaryList.Key>
                                            <SummaryList.Value>{getDate(dsaApplicationData?.startDate)} - {getDate(dsaApplicationData?.endDate)}</SummaryList.Value>
                                        </SummaryList.Row>
                                    </SummaryList>
                                    <Label>
                                        If you need to check the cost of the service to raise your PO number it can be found in the DSA which can be downloaded below.
                                    </Label>
                                    <div className="nhsuk-u-padding-bottom-7"></div>
                                    <Attachment>
                                        <Attachment.Thumbnail>
                                        </Attachment.Thumbnail>
                                        <Attachment.Details>
                                            <Attachment.Heading>
                                                <p><Attachment.Link href="#" target="_blank" className={downloadClicked ? "nhsuk-link-spinner nhsuk_download_spinner" : ""} onClick={handleDownload}>Data Sharing Agreement {getVersionNumber()}</Attachment.Link></p>
                                            </Attachment.Heading>
                                            <Attachment.Content >
                                                <Attachment.Property>PDF</Attachment.Property>,<Attachment.Property> less than 1 MB</Attachment.Property>
                                            </Attachment.Content>
                                        </Attachment.Details>
                                    </Attachment>
                                </div>
                                {dsaApplicationData.isorganisationpaying ?
                                    <div className="nhsuk-grid-column-two-thirds nhsuk-input--width-30" id={dsaContent.poNumber}>
                                        <ErrorMessage>{poNumberError}</ErrorMessage>
                                        <div></div>
                                        <Input
                                            name={dsaContent.poNumber}
                                            label={dsaContent.lbl_POnumber}
                                            value={dsaApplicationData.poNumber}
                                            onChange={handleChange}
                                        />
                                        <p>OR</p>
                                        <Checkboxes name={dsaContent.requiredPONumber}>
                                            <Checkboxes.Box value={dsaApplicationData.isRequiredPOnumber ? dsaContent.yes : dsaContent.no} checked={dsaApplicationData.isRequiredPOnumber} onChange={handleChange}>{dsaContent.lbl_POnumberNotRequired}</Checkboxes.Box>
                                        </Checkboxes>
                                    </div> : null}

                                <div className="nhsuk-grid-column-full">
                                    <Button name="acceptAgreement" onClick={handleClick} id="nhsuk-button-spinner">{dsaContent.btnTextContinue}</Button>
                                </div>
                            </>
                        }
                    </div>
                </main>
            </div>
        </>
    )
}

export default PONumber;