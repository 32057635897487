import { ErrorMessage, InsetText, Table } from 'nhsuk-react-components';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getKeyByValue } from '../../Helper/Utility';
import { documentType, fileErrorMessage } from '../../config/page.config';

type record = {
    DocumentType?: number;
    ApplicantComments?: string;
    FileId?: number;
    SelectedEvidenceUpload?: number;
    SectionId?: string;
    SectionType?: string;
    RecentlyModified?: boolean;
};

interface AddAnotherListRecordProps {
    linkTo: string;
    linkText: string;
    records: record[];
    summaryData?: string;
    isfromPage?: boolean;
    deleteRecord: (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        i: number,
    ) => void;
    editRecord: (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        i: number,
        fileId: number
    ) => void;
    addAnotherListRecord: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => void;
}

const AddAnotherListRecord: React.FC<AddAnotherListRecordProps> = ({
    linkTo,
    linkText,
    records,
    deleteRecord,
    editRecord,
    addAnotherListRecord,
    summaryData,
    isfromPage,
}) => {
    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);

    const recordLimit = 6;
    function fileUploadedRecently(): number {
        return records.filter(file => file?.RecentlyModified! === true).length;
    }

    function handleDelete(
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        index: number,
        fileId: number
    ): void {
        deleteRecord(e, index);
    }

    function handleEdit(
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        index: number,
        fileId: number
    ): void {
        if (fileUploadedRecently() + 1 > recordLimit && records[index].RecentlyModified !== true) {
            setIsError(true);
        } else {
            editRecord(e, index, fileId);
        }
    }

    function handleAnotherRecord(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number,): void {
        addAnotherListRecord(e, index);
    }

    useEffect(() => {
        if (!(records && records.length)) {
            // navigate to first page
            // To-do - Akshay to check
            navigate(linkTo);
        }
    }, [records]);

    return (
        <>

            {summaryData && <InsetText>
                <p>{summaryData}</p>
            </InsetText>}
            {isError && <ErrorMessage> {fileErrorMessage.fileLength_ErrorMessage}</ErrorMessage>}

            <Table responsive>
                <Table.Head>
                    {records && records.length > 0 ? (
                        <Table.Row>
                            <Table.Cell>Document Type</Table.Cell>
                            <Table.Cell>Comments</Table.Cell>
                            <Table.Cell>Section</Table.Cell>
                            {/*<Table.Cell>&nbsp;</Table.Cell>*/}
                            <Table.Cell>&nbsp;</Table.Cell>
                        </Table.Row>
                    ) : null}
                </Table.Head>
                <Table.Body>
                    {records && records.length > 0 && records.map((record: record, index = 1) => (
                        <Table.Row key={index}>
                            <Table.Cell>{getKeyByValue(documentType, isfromPage ? record?.SelectedEvidenceUpload! : record?.DocumentType!)?.replaceAll('_', ' ')}</Table.Cell>
                            <Table.Cell>{record?.ApplicantComments}</Table.Cell>
                            <Table.Cell>{record?.SectionType ? record?.SectionType : null}</Table.Cell>
                            {/*<Table.Cell>*/}
                            {/*    <a href="#" onClick={(e) => handleEdit(e, index, record.FileId!)}> Change</a>*/}
                            {/*</Table.Cell>*/}
                            <Table.Cell>
                                <a href="#" onClick={(e) => handleDelete(e, index, record.FileId!)}>
                                    Delete
                                </a>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <div className="nhsuk-u-padding-bottom-4"></div>
            {records && fileUploadedRecently() < recordLimit &&
                <p>
                    <Link to={linkTo} onClick={(e) => handleAnotherRecord(e, records.length)}>
                        {linkText}
                    </Link>
                </p>}
        </>
    );
};

export default AddAnotherListRecord;
