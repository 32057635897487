import { Button, Fieldset, Radios } from "nhsuk-react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader';
import ErrorDetails from '../../../../Components/Shared/ErrorSummary';
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitDSASectionStatus_URL } from "../../../../config/api-endpoints.config";
import { controllerDetails, sectionOptions, dsaControllerErrorMessage, taskListSectionStatus, routeName, fileTypeSize, applicationChangeType, dsaContent } from "../../../../config/page.config";
import { checkApplicationChangeType, fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IProcessor } from "../../../../state/models/app.interface";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getProcessorDetails, setProcessorDetails } from "../../../../state/slice/Processor/Processor.slice";

/* Question to be asked at the end of the Processor section so the user can state if the section is completed. */

const HaveYouAddedAllProcessors: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const processorDetailsData: IProcessor = useAppSelector(getProcessorDetails);
    const initialState: IProcessor = processorDetailsData || {};
    const [processorData, setData] = useState(initialState);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData,] = useState(initialApplicationState);

    const [sectionStatus, setSectionStatus] = useState("");
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [isSectionProcessorErrorMessage, setIsSectionProcessorErrorMessage] = useState("");
    const [selected, setSelected] = useState(false);

    /* To dispatch the setProcessorDetails Redux action */
    const saveDataInStore = (stateData?: IProcessor) => {
        dispatch(setProcessorDetails({ ...processorData, ...stateData }));
    }

    /* Function to set the state value on change of Radios(yes/no) */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSectionStatus(e.target.value);
        setSelected(true);
        resetError(e);
    };

    /* Reset the error if the selected value is not blank */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setIsSectionProcessorErrorMessage("");
        }
    };

    /* Calling submitProcessorDetails function if selected value is valid */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, submitProcessorDetails);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    /* API call to submit processor details, marking the status based on Yes(InProgress)/No(Completed) and updating the state */
    const submitProcessorDetails = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "SectionId": sectionOptions.DataProcessor,
            "SectionStatus": sectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitDSASectionStatus_URL, requestOptions)
            .then(() => {
                setProcessorStatus(sectionStatus);
                navigate(routeName.dsaTaskList);
            })
            .catch(() => navigate(routeName.serviceError));
    };

    /* set the processor status to the state and update in store */
    const setProcessorStatus = (aboutProcessorSectionStatus: string) => {
        let updatedState: IProcessor = {};
        updatedState = {
            ...processorData,
            aboutProcessorSectionStatus: aboutProcessorSectionStatus
        }
        setData(updatedState);
        saveDataInStore(updatedState);
    };

    /* Validate if atleast one option(Yes/No) is selected */
    const ValidateFields = () => {
        let isValid: boolean = true;

        if (!selected) {
            if (checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal),String(applicationChangeType.Extension)])) {
                setSummaryLinkErrorMessage(`${dsaContent.selectSectionCompleteLink_RenewErrorMessage}`);
                setIsSectionProcessorErrorMessage(`${dsaContent.selectSectionCompleteLink_RenewErrorMessage}`);
            } else {
                setSummaryLinkErrorMessage(`${dsaControllerErrorMessage.selectSectionCompleteLinkProcessor_ErrorMessage}`);
                setIsSectionProcessorErrorMessage(`${dsaControllerErrorMessage.selectSectionCompleteLinkProcessor_ErrorMessage}`);
            }
            isValid = false;
        }

        return isValid;
    };


    return (
        <>
            <ControllerHeader></ControllerHeader>

            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack />
                            <h1>{checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? dsaContent.isThisSectionComplete : dsaContent.haveYouAddedAllProcessors}</h1>
                                {summaryLinkErrorMessage !== "" && <ErrorDetails errorDescription={summaryLinkErrorMessage} errorHref="#radioSectionComplete"></ErrorDetails>}
                                {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal),String(applicationChangeType.Extension)]) ? <p>If you select 'Yes' the status of this section will change to 'completed', but you can return and make changes if you need to.</p> : null}
                                <Fieldset>
                                <Radios name="radioSectionComplete" id="radioSectionComplete" error={isSectionProcessorErrorMessage}>
                                    <Radios.Radio name="sectionComplete" value={taskListSectionStatus.completed} onChange={handleChange}>{checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? dsaContent.yes : dsaContent.yesIHaveAddedAllprocessors}</Radios.Radio>
                                    <Radios.Radio name="sectionComplete" value={taskListSectionStatus.inProgress} onChange={handleChange}>{checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? dsaContent.noIWillComeBackLater : dsaContent.noIWillAddThemLater}</Radios.Radio>
                                    </Radios>
                                </Fieldset>
                                <div className="nhsuk-u-padding-bottom-8"></div>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{controllerDetails.btnSaveAndContinue}</Button>
                           </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default HaveYouAddedAllProcessors;
