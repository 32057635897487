import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Radios, Hint } from "nhsuk-react-components";
import { controllerSection, dsaContent, fileTypeSize, fundingErrorMessage, initialStateOfFileUpload, routeName, sectionOptions, taskListSectionStatus } from "../../../../config/page.config";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader'
import ErrorDetails from '../../../../Components/Shared/ErrorSummary';
import { fetchInterceptor, getCookie, submitDSASectionStatus, submitSpinner } from '../../../../Helper/Utility';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { AppDispatch } from '../../../../state/app/store';
import Loader from "../../../../Components/Loader/Loader";
import GoBack from "../../../../Components/Shared/GoBack";
import { IApplication, IFileUpload, IFunding, IFundingList } from "../../../../state/models/app.interface";
import { getFundingDetails, setFundingDetailsData } from "../../../../state/slice/Funding/Funding.slice";
import { DeleteDSAChildRecord_URL } from "../../../../config/api-endpoints.config";
import { getFundingListDetails, setFundingListDetailsData } from "../../../../state/slice/FundingList/FundingList.slice";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";

const DeleteFunding: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [deleteValue, setDeleteValue] = useState('');
    const [deletefundingErrorMessage, setDeleteFundingErrorMessage] = useState("");
    const fundingData = useAppSelector(getFundingDetails) || {};
    const initialfundingListState: IFundingList = useAppSelector(getFundingListDetails) || {};
    const [fundingListData, setFundingListData] = useState(initialfundingListState);

    const initialApplicationState: IApplication = useAppSelector(getDSADetails) || {};
    const [dsaApplicationData, setDsaApplicationData] = useState(initialApplicationState);
    const saveFundingListInStore = (stateData?: IFundingList) => {
        dispatch(setFundingListDetailsData({ ...fundingListData, ...stateData }));
    }

    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    /* To dispatch the setFundingDetailsData Redux action */
    const saveDataInStore = (stateData?: IFunding) => {
        dispatch(setFundingDetailsData({ ...fundingData, ...stateData }));
    }

    let fundingLength: number = 0;

    /*Delete section*/
    const resetFundingData = () => {
        let updatedState: IFunding = {}
        updatedState = {
            fundingType: "",
            aboutFundingSectionStatus: "",
            fileUpload: [initialStateOfFileUpload],
            IsFundingSourceDirty: false,
            IsFundingFileUploadDirty: false,
            sourceId: "",
            fundingAwardingInstruction: "",
            referenceTitle: "",
            yearOfSubmissionDay: "",
            yearOfSubmissionMonth: "",
            yearOfSubmissionYear: "",
            isFundingInfoDirty: false,
            isUploadedSummaryPage: false,
            uploadedFile: [initialStateOfFileUpload],
            recentlyAddedFunndingId: '',
            evidenceFileSummary: []
        }
        saveDataInStore(updatedState);
    }
    const resetFundingListData = () => {
        let filteredfundingList: IFunding[];
        const fundingLists = fundingListData.fundingList ? fundingListData.fundingList : [];
        filteredfundingList = fundingLists.filter((funding: IFunding) => funding.sourceId !== fundingData.sourceId)
        fundingLength = filteredfundingList.length;
        saveFundingListInStore({ ...fundingListData, fundingList: (filteredfundingList as [IFunding]), activefundingData: 0 });
    }


    const handleDelete = (sourceId: string) => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "Id": sourceId,
            "SectionId": sectionOptions.Funding
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(DeleteDSAChildRecord_URL, requestOptions)
            .then(() => {
                resetFundingListData();
                resetFundingData();
                updateSectionStatus();
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const handleNavigation = () => {
        if (deleteValue === dsaContent.yes) {
            if (fundingData?.sourceId === fundingData?.recentlyAddedFunndingId) {
                let updatedState: IFunding = {};
                updatedState = {
                    ...fundingData,
                    fileUpload: [initialStateOfFileUpload],
                    uploadedFile: [initialStateOfFileUpload],
                    sourceId: '',
                    isUploadedSummaryPage: true,
                    isFundingInfoDirty: true,
                    recentlyAddedFunndingId: ''
                }
                saveDataInStore(updatedState);
            }
            handleDelete(fundingData?.sourceId!);
        }
        else {
            navigate(routeName.uploadDocumentDetails);
        }
    }

    const updateSectionStatus = async () => {
        const statusResponse = await submitDSASectionStatus(dsaApplicationData.id!, controllerSection.Funding, taskListSectionStatus.inProgress);
        if (statusResponse && fundingLength > 0) {
            navigate(routeName.uploadDocumentDetails);
        }
        else {
            let updatedState: IFundingList = {};
            updatedState = {
                ...fundingListData,
                isFromFundingSummary: false
            }
            setFundingListData(updatedState);
            saveFundingListInStore(updatedState);
            let updatedAppState: IApplication = {};
            updatedAppState = {
                ...dsaApplicationData,
                aboutFundingSectionStatus: taskListSectionStatus.inProgress
            }
            setDsaApplicationData(updatedAppState);
            saveApplicationDataInStore(updatedAppState);
            navigate(routeName.fundingType);
        }
    }

    const handleDeleteContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateDeleteFields();
        if (isValid) {
            submitSpinner(e, handleNavigation);

        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    /*Validate fields*/
    const validateDeleteFields = () => {
        let isValid: boolean = true;

        if (deleteValue === '') {
            setDeleteFundingErrorMessage(`${fundingErrorMessage.deleteFunding_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const resetDeleteError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setDeleteFundingErrorMessage("");
        }
    }

    const handleChangeDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteValue(e.target.value)
        resetDeleteError(e)
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} /> :
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    Funding</span>
                                    Are you sure you want to delete this funding document?
                                </h1>
                                {deletefundingErrorMessage !== "" && <ErrorDetails errorDescription={deletefundingErrorMessage} errorHref="#deletefunding"></ErrorDetails>}
                                <Radios
                                    name="deletefunding"
                                    id="deletefunding"
                                    error={deletefundingErrorMessage}
                                >
                                    <Radios.Radio value={dsaContent.yes} onChange={handleChangeDelete} >
                                        {dsaContent.yes}
                                        <Hint>If you select 'Yes', all details on this funding will be removed.</Hint>
                                    </Radios.Radio>
                                    <Radios.Radio value={dsaContent.no} onChange={handleChangeDelete}>{dsaContent.no}</Radios.Radio>
                                </Radios>
                                <div className="nhsuk-u-padding-bottom-8"></div>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleDeleteContinue}>{dsaContent.btnTextContinue}</Button>

                            </div>
                        }
                    </div>
                </main>
            </div>
        </>
    )
}

export default DeleteFunding;
