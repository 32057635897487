import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialStateOfFileUpload } from '../../../config/page.config';
import type { RootState } from '../../app/store'
import { IFileUpload, IFunding } from '../../models/app.interface'



const initialState: IFunding = {
    fundingType: "",
    fileUpload: [initialStateOfFileUpload],
    fundingAwardingInstruction: "",
    referenceTitle: "",
    yearOfSubmissionDay: "",
    yearOfSubmissionMonth: "",
    yearOfSubmissionYear: "",
    aboutFundingSectionStatus: "",
    IsFundingSourceDirty: false,
    IsFundingFileUploadDirty: false,
    sourceId: "",
    isFundingInfoDirty: false,
    isUploadedSummaryPage: false,
    uploadedFile: [initialStateOfFileUpload],
    recentlyAddedFunndingId: '',
    comments: "",
    fileUploadActive: 0,
    addAnotherRecord: false,
    temporaryFileList: [initialStateOfFileUpload],
    evidenceFileSummary: []
}

export const FundingSlice = createSlice({
    name: 'Funding',
    initialState,
    reducers: {
        setFundingDetailsData: (state: IFunding, action: PayloadAction<IFunding>) => {
            state = Object.assign(state, action.payload);
        },
        resetFundingDetailsData: (state: IFunding, action: PayloadAction<IFunding>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setFundingDetailsData, resetFundingDetailsData } = FundingSlice.actions;
export const getFundingDetails = (state: RootState) => state.fundingDetails;
export default FundingSlice.reducer;