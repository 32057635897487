import { Route, useNavigate } from 'react-router-dom';
import ContainerTemplate from '../../../../Components/Container/ContainerTemplate';
import AddAnotherRecord from '../../../../Components/Shared/AddAnotherRecord';
import {
    deleteUploadedFiles,
    fetchInterceptor,
    getCookie,
    getUploadFileName,
    submitSpinner,
} from '../../../../Helper/Utility';
import { UpdateScreenDataController_URL } from '../../../../config/api-endpoints.config';
import {
    buttonText,
    dsaContent,
    fileTypeSize,
    initialStateOfFileUpload,
    routeName,
    taskListSectionStatus,
} from '../../../../config/page.config';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { IApplication, IController, IDeleteDocument, IFunding } from '../../../../state/models/app.interface';
import { getControllerListDetails } from '../../../../state/slice/ControllerList/ControllerList.slice';
import { getDSADetails, setDSAApplicationDetailsData } from '../../../../state/slice/DSA/DSA.slice';
import {
    getControllerDetails,
    setControllerDetailsData,
} from '../../../../state/slice/DSAAboutController/DSAAboutController.slice';
import { AppDispatch } from '../../../../state/app/store';
import ReviewDateSection from '../../../../Components/Shared/ReviewDateSection';
import { useEffect, useState } from 'react';
import { getFundingDetails, setFundingDetailsData } from '../../../../state/slice/Funding/Funding.slice';
import { getDeleteDocumentData, setDeleteDocumentData } from '../../../../state/slice/shared/DeleteDocument/DeleteDocument.slice';

const AddAnotherFunding: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();

    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const { id: dsaApplicationId, aboutControllerSectionStatus } =
        useAppSelector<IApplication>(getDSADetails);

    const { fileUpload, fileUploadActive, evidenceFileSummary }: IFunding = useAppSelector(getFundingDetails); //controllerData
    const { fileIndex, isDeleted } = useAppSelector(getDeleteDocumentData);

    const initialFundingState: IFunding = useAppSelector(getFundingDetails) || {};
    const [fundingData, setFundingData] = useState(initialFundingState);

    const saveApplicationDataInStore = (stateData: IApplication) => {
        dispatch(setDSAApplicationDetailsData(stateData));
    };

    const saveFundingDataInStore = (stateData?: IFunding) => {
        dispatch(setFundingDetailsData({ ...fundingData, ...stateData }));
    }

    const handleContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        submitSpinner(e, handleSubmit);
    };

    const handleSubmit = () => {
            navigate(routeName.fundingInformation);
    };

    const saveDeleteDocumentInStore = (stateData: IDeleteDocument) => {
        dispatch(setDeleteDocumentData(stateData));
    };
    const deleteRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
        e.preventDefault();

        const documentData: IDeleteDocument = {
            fileIndex: index,
            fileId: fileUpload![index].FileId!,
            approvalEvidenceID: fileUpload![index].ApprovalEvidenceID!,
            isDeleted: false,
        };
        saveDeleteDocumentInStore(documentData);
        navigate(routeName.deleteDocument);
    };
    const editRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
        e.preventDefault();
        let updatedState: IFunding = {};
        updatedState = {
            fileUploadActive: index,
            temporaryFileList: fundingData.fileUpload
        };
        saveFundingDataInStore(updatedState);
        navigate(routeName.fundingDocument);
    };

    const addAnotherRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
        e.preventDefault();
        let updatedState: IFunding = {};
        updatedState = {
            fileUploadActive: fileUpload!.length,
            temporaryFileList: fundingData.fileUpload
        };
        saveFundingDataInStore(updatedState);
        navigate(routeName.fundingDocument);
    };
    useEffect(() => {
        let updatedState: IFunding = {};
        updatedState = {
            ...fundingData,
            temporaryFileList: [initialStateOfFileUpload]
        };
        setFundingData(updatedState);
        saveFundingDataInStore(updatedState);
    }, [])

    useEffect(() => {
        if (isDeleted) {
            const documentData: IDeleteDocument = {
                isDeleted: false,
            };
            saveDeleteDocumentInStore(documentData);

            let updatedDocData = [...fileUpload!];
            var oldFileName = getUploadFileName(updatedDocData[fileIndex!].FileName!);
            updatedDocData.splice(fileIndex!, 1);
            let updatedState: IFunding = {};
            updatedState = {
                fileUpload: updatedDocData,
                fileUploadActive: fileUpload?.length === 1 ? 0 : fileUploadActive,
                evidenceFileSummary: evidenceFileSummary?.filter((x) => x.fileName !== oldFileName)
            };
            setFundingData(updatedState);
            saveFundingDataInStore(updatedState);
        }
    }, [isDeleted]);

    return (
        <ContainerTemplate
            title={'Funding'}
            heading={'Uploaded document(s)'}
            actionButtonText={buttonText.btnSaveAndContinue}
            onActionButtonClick={handleContinue}
            goBackCondition={dsaContent.tasklist}
        >
            <></>
            <AddAnotherRecord
                linkTo={routeName.fundingDocument}
                linkText="Add another funding document"
                records={fileUpload!} //list of files
                deleteRecord={deleteRecord}
                editRecord={editRecord}
                addAnotherRecord={addAnotherRecord}
            />
        </ContainerTemplate>
    );
};

export default AddAnotherFunding;
