import { Button, Label, SummaryList } from "nhsuk-react-components";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import Attachment from "../../../Components/Attachment/Attachment";
import Loader from "../../../Components/Loader/Loader";
import GoBack from "../../../Components/Shared/GoBack";
import { dsaContent, applicationStatus, routeName } from "../../../config/page.config";
import { downloadPdfFile, setGeneratedBlobUrl } from "../../../Helper/Utility";
import { useAppSelector } from "../../../state/app/hooks";
import { IApplication } from "../../../state/models/app.interface";
import { getDSADetails } from "../../../state/slice/DSA/DSA.slice";
/* DSA Confirmation Page*/
const DSAConfirmation: React.FC = () => {
    const navigate = useNavigate();
    const [blobUrl, setBlobUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);
    const [downloadClicked, setDownloadClicked] = useState(false);
    const abortController = new AbortController();

    /*Get Version from Reference Number*/
    const getVersionNumber = () => dsaApplicationData?.referenceNumber?.split('-')[dsaApplicationData?.referenceNumber?.split('-').length - 1].slice(1);

    /*Get DSA PDF file */
    const getDsaPDFFile = () => {

        downloadPdfFile(abortController,dsaApplicationData?.id!, dsaApplicationData?.referenceNumber!, setBlobUrl);
    }

    const handleDownload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        event.preventDefault();
        setDownloadClicked(true);
    }

    const GetPDFComponent = () => {
        return (
            <Attachment>
                <Attachment.Thumbnail>
                </Attachment.Thumbnail>
                <Attachment.Details>
                    <Attachment.Heading>
                        <p><Attachment.Link href="#" target="_blank" className={downloadClicked ? "nhsuk-link-spinner nhsuk_download_spinner" : ""} onClick={handleDownload}>Data Sharing Agreement {getVersionNumber()}</Attachment.Link></p>
                    </Attachment.Heading>
                    <Attachment.Content >
                        <Attachment.Property>PDF</Attachment.Property>,   <Attachment.Property>less than 1 MB</Attachment.Property>
                    </Attachment.Content>
                </Attachment.Details>
            </Attachment>
        )
    }

    const GetConfirmationContent = (headerContent: string) => {
        return (
            <div className="nhsuk-header nhsuk-header__service-name nhsuk-header__navigation-item nhsuk-header__container">
                <h3>{headerContent}</h3>
                <Label> (SIGNED BY {dsaApplicationData?.numberOfSignatures ? dsaApplicationData?.numberOfSignatures : ''} OF {dsaApplicationData.numberOfSignees} APPROVERS) </Label>
                <Label>Reference Number</Label>
                <Label>{dsaApplicationData.referenceNumber}</Label>
            </div>
        )
    }
    useEffect(() => {
        getDsaPDFFile();
        setLoading(false);
    }, []);

    useEffect(() => {
        if (downloadClicked && blobUrl) {
            setGeneratedBlobUrl(blobUrl);
            setDownloadClicked(false);
        }
    }, [downloadClicked, blobUrl]);

    useEffect(() => {
        return (() => {
            abortController.abort();
        });
    }, [navigate]);

    return (
        <>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <div className="nhsuk-grid-column-full">
                                <Loader loadingText={dsaContent.txtLoading} />
                            </div>
                            :
                            (
                                <>
                                    {
                                        dsaApplicationData.status != '' && applicationStatus[1012] == dsaApplicationData.status ? (
                                            <div className="nhsuk-grid-column-two-thirds">
                                                <GoBack />
                                                <p></p>
                                                <p></p>
                                                <h1>Your application for a Data Sharing Agreement</h1>
                                                <Label size="m">Your application</Label>
                                                <SummaryList noBorder>
                                                    <SummaryList.Row>
                                                        <SummaryList.Key>Reference number</SummaryList.Key>
                                                        <SummaryList.Value>{dsaApplicationData?.referenceNumber}</SummaryList.Value>
                                                    </SummaryList.Row>
                                                    <SummaryList.Row>
                                                        <SummaryList.Key>Start and end date</SummaryList.Key>
                                                        <SummaryList.Value>{dsaApplicationData?.startDate ? dsaApplicationData?.startDate : '-'}  -  {dsaApplicationData?.endDate ? dsaApplicationData?.endDate : '-'}</SummaryList.Value>
                                                    </SummaryList.Row>
                                                </SummaryList>
                                                <Label>Once you have downloaded your application for a Data Sharing Agreement, consider changing the file name to something that will help you easily locate it.</Label>
                                                <p></p>
                                                <p></p>
                                                {
                                                    GetPDFComponent()
                                                }
                                            </div>
                                        ) : (
                                            <div className="nhsuk-grid-column-two-thirds">
                                                {
                                                    dsaApplicationData.numberOfSignatures != dsaApplicationData.numberOfSignees ? (
                                                        <>
                                                            <div className="nhsuk-u-padding-bottom-4">
                                                                {
                                                                    GetConfirmationContent('Done')
                                                                }
                                                            </div>
                                                            <p>You have signed the Data Sharing Agreement (DSA).The DSA will be considered 'active' once it is signed by all signatories and fully approved.</p>
                                                            <p>Once fully approved, the DSA can be found in 'Active DSAs'. Until then, you can find it in 'Apply for or Manage DSA'.</p>
                                                            <p>You can download and view the DSA below.</p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="nhsuk-u-padding-bottom-4">

                                                                {
                                                                    GetConfirmationContent('Agreement Active')
                                                                }
                                                            </div>
                                                            <p>You have signed the Data Sharing Agreement (DSA).It is now active and valid until {dsaApplicationData.endDate}.</p>
                                                            <p>You can download and view the DSA below.</p>
                                                        </>
                                                    )
                                                }
                                                <div className="nhsuk-u-padding-bottom-9">
                                                    {
                                                        GetPDFComponent()
                                                    }
                                                </div>

                                                <div className="nhsuk-u-padding-top-7">
                                                    {
                                                        dsaApplicationData.numberOfSignatures != dsaApplicationData.numberOfSignees ? (
                                                                <Button onClick={() => { navigate(routeName.applyDsa) }}>Back to Apply for or Manage DSA</Button>

                                                        ) : (
                                                                    <Button onClick={() => { navigate(routeName.activeDsa) }}>Go to Active DSAs</Button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )

                                    }
                                </>
                            )
                        }
                    </div>
                </main>
            </div>
        </>
    )
}

export default DSAConfirmation;