import React, { useEffect, useState } from "react";
import { Button, ErrorSummary, InsetText, Radios } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IParticipant, IApplication } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getParticipantDetails, setParticipantDetailsData } from "../../../../state/slice/Participant/Participant.slice";
import { participantErrorMessage, dsaContent, dsaErrorMessage, participantTypeOptions, taskListSectionStatus, routeName, fileTypeSize } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { SubmitScreenCohortType_URL } from "../../../../config/api-endpoints.config";
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import Loader from "../../../../Components/Loader/Loader";

const ParticipantTypes: React.FC = () => {
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [participantsErrorMessage, setParticipantErrorMessage] = useState("");
    const participantDetailsData: IParticipant = useAppSelector(getParticipantDetails);
    const initialState: IParticipant = participantDetailsData || {};
    const [participantData, setParticipantData] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const [participantType, setParticipantType] = useState('');
    const [isFromAnswerPage] = useState(participantData.participantcheckYourAnswerPage);
    /* To dispatch the setParticipantDetailsData Redux action */

    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantData, ...stateData }));
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IParticipant = {};
        if (e.target.value == participantTypeOptions.existing)
            setParticipantType(dsaContent.participanExistingtAgreement ? dsaContent.participanExistingtAgreement : '')
        else
            setParticipantType(dsaContent.participantNewAgreement ? dsaContent.participantNewAgreement : '')
        if (e.target.value !== null && e.target.value !== undefined) {
            updatedState = { ...participantData, [e.target.name]: e.target.value, ['participantDataType']: participantType, typeOfCohort: parseInt(e.target.value) }
            resetError(e);
            setParticipantData(updatedState);
            saveDataInStore(updatedState);
        }
    }
    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setParticipantErrorMessage("");
        }
    }
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, SubmitScreenCohortType);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const SubmitScreenCohortType = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "Cohort": true,
            "TypeOfCohort": participantData.participantTypes,
            "RefIfUsingAnotherExistingAgreement": null,
            "SectionStatus": dsaApplicationData.aboutParticipantSectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenCohortType_URL, requestOptions)
            .then(() => {
                    let updatedState: IParticipant = {};
                    updatedState = { ...participantData, participantcheckYourAnswerPage: isFromAnswerPage }
                    setParticipantData(updatedState);
                    saveDataInStore(updatedState);
                    let sectionStatus: IApplication = {
                        ...dsaApplicationData, aboutParticipantSectionStatus: taskListSectionStatus.inProgress
                    }
                    setDsaApplicationData(sectionStatus);
                    saveApplicationDataInStore(sectionStatus);
                if (String(participantData.participantTypes) === String(participantTypeOptions.new)) {
                    if (isFromAnswerPage !== undefined && isFromAnswerPage === dsaContent.checkYourAnswersPageText && participantData.cohort) {
                        navigate(routeName.participantSummary)
                    }
                    else {
                        navigate(routeName.legalBasis);
                    }
                        }
                      else if (String(participantData.participantTypes) === String(participantTypeOptions.existing)) {
                            if (participantData.existingAgreementUploadItems !== undefined && participantData.existingAgreementUploadItems[0] !== '' && participantData.existingAgreementUploadItems.length > 0)
                            { navigate(routeName.existingType); }
                            else {
                                setParticipantType(dsaContent.participantNewAgreement);
                                let updatedState: IParticipant = {};
                                updatedState = { ...participantData, participantTypes: participantTypeOptions.new, ['participantDataType']: participantType, typeOfCohort: Number(participantTypeOptions.new) }
                                setParticipantData(updatedState);
                                saveDataInStore(updatedState);
                                navigate(routeName.noExistingAgreement);
                            }
                        }
                    
               
            })
            .catch(() => navigate(routeName.serviceError));

        setLoading(false);

    }

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#participantTypes">{summaryLinkErrorMessage}</ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    /* To Validate the each field's data */
    const ValidateFields = () => {
        let isValid: boolean = true;
        if (participantData.participantTypes === 'null' || !participantData.participantTypes || participantData.participantTypes === "" ) {
            setSummaryLinkErrorMessage(`${participantErrorMessage.participantType_ErrorMessage}`);
            setParticipantErrorMessage(`${participantErrorMessage.participantType_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            {loading ?
                <Loader loadingText={dsaContent.txtLoading} />

                : <div className="nhsuk-width-container">
                    <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                        <div className="nhsuk-grid-row">
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <h1>  <span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    About the participants</span>
                                    What type of Participant group?
                                </h1>
                                {summaryLinkErrorMessage !== "" ? errorSummary : null}
                                <InsetText>
                                    <p>Select 'New' if you will provide a new list of identified individuals for NHS England to provide data against.</p>
                                    <p>Select 'Existing agreement' if you are requesting more of the same data or making a change to your Data Sharing Agreement (DSA) application and wish to re-use the list of identified individuals already associated with it.</p>
                                </InsetText>
                                <Radios
                                    name={dsaContent.participantTypes}
                                    id={dsaContent.participantTypes}
                                    error={participantsErrorMessage}>
                                    <Radios.Radio name={dsaContent.participantTypes} checked={String(participantData.typeOfCohort) === String(participantTypeOptions.new)} defaultValue={participantTypeOptions.new} onChange={handleChange}>{dsaContent.participantNewAgreement}</Radios.Radio>
                                    <Radios.Radio name={dsaContent.participantTypes} checked={String(participantData.typeOfCohort) === String(participantTypeOptions.existing)} defaultValue={participantTypeOptions.existing} onChange={handleChange}>{dsaContent.participanExistingtAgreement} (using a Participant group already provided on a previous DSA)</Radios.Radio>
                                </Radios>
                                <div className="nhsuk-u-padding-bottom-4">
                                </div>
                                <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                            </div>
                        </div>
                    </main>
                </div>}

        </>
    )
}
export default ParticipantTypes;