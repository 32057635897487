import { Button, Fieldset, Radios } from "nhsuk-react-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitDSASectionStatus_URL } from "../../../../config/api-endpoints.config";
import { amendRenewExtendValue, controllerSection, dsaContent, fileTypeSize, purposeErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IPurpose } from "../../../../state/models/app.interface";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getPurposeDetails, setPurposeDetailsData } from "../../../../state/slice/Purpose/Purpose.slice";

const HaveYouCompletedCommercial: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [purposeData, setPurposeData] = useState<IPurpose>(useAppSelector(getPurposeDetails) || {});
    const savePurposeDataInStore = (stateData?: IPurpose) => {
        dispatch(setPurposeDetailsData({ ...purposeData, ...stateData }));
    }
    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};
    const [sectionStatus, setSectionStatus] = useState("");
    const [confirmationErrorMessage, setConfirmationErrorMessage] = useState("");
    const [selected, setSelected] = useState(false);

    /* Handle completed change */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSectionStatus(e.target.value);
        setSelected(true);
        resetError(e);
    };

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setConfirmationErrorMessage("");
        }
    }
    /* Handle completed change */

    /* Submit Status */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, submitCommercialDetails);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const submitCommercialDetails = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "SectionId": controllerSection.CommercialPurposesAndSublicensing,
            "SectionStatus": sectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitDSASectionStatus_URL, requestOptions)
            .then(() => {
                    setCommercialStatus(sectionStatus);
                    navigate(routeName.dsaTaskList);
               
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setCommercialStatus = (status: string) => {
        let updatedState: IPurpose = {};
        updatedState = {
            ...purposeData,
            aboutCommercialSublicensingSectionStatus: status
        }
        setPurposeData(updatedState);
        savePurposeDataInStore(updatedState);
    }

    const validateFields = () => {
        let isValid: boolean = true;
        if (!selected) {
            setConfirmationErrorMessage(`${purposeErrorMessage.selectCompletedCommercial_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }
    /* Submit Status */


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                Commercial purposes and sub-licensing</span>
                                Have you completed the commercial purposes and sub-licensing section?
                            </h1>
                            {confirmationErrorMessage !== "" && <ErrorDetails errorDescription={confirmationErrorMessage} errorHref="#radioSectionComplete"></ErrorDetails>}
                            <Fieldset>
                                <Radios name="radioSectionComplete" id="radioSectionComplete" error={confirmationErrorMessage}>
                                    <Radios.Radio name="sectionComplete" value={taskListSectionStatus.completed} onChange={handleChange}>{dsaContent.yes}</Radios.Radio>
                                    <Radios.Radio name="sectionComplete" value={taskListSectionStatus.inProgress} onChange={handleChange}>{dsaContent.noIWillBeBackLater}</Radios.Radio>
                                </Radios>
                            </Fieldset>
                            <div className="nhsuk-u-padding-bottom-6"></div>
                            <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default HaveYouCompletedCommercial
