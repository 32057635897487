import React, { HTMLProps } from 'react';
import classNames from 'classnames';
import SummaryCardContext from "./SummaryCardContext"
import SummaryCardContent from "./SummaryCardContent"
import SummaryCardDescription from "./SummaryCardDescription"
import SummaryCardHeading from "./SummaryCardHeading"
import SummaryCardImage from "./SummaryCardImage"
import SummaryCardGroup from "./SummaryCardGroup"
import SummaryCardGroupItem from "./SummaryCardGroupItem"
import SummaryCardLink from "./SummaryCardLink"
import "./SummaryCard.css";

interface SummaryCardProps extends HTMLProps<HTMLDivElement> {
  clickable?: boolean;
  feature?: boolean;
  grey?: boolean;
  red?: boolean;
  green?: boolean;
  yellow?: boolean;
  blue?: boolean;
}

interface ISummaryCard extends React.FC<SummaryCardProps> {
  Content: typeof SummaryCardContent;
  Description: typeof SummaryCardDescription;  
  Heading: typeof SummaryCardHeading;
  Image: typeof SummaryCardImage;
  Link: typeof SummaryCardLink;
  Group: typeof SummaryCardGroup;
  GroupItem: typeof SummaryCardGroupItem;
}

const SummaryCard: ISummaryCard = ({
 blue, grey,red, green, yellow, className, clickable, children, feature, ...rest
}) => (
  <div
    className={classNames(
      'nhsuk-summarycard',
      { 'nhsuk-card--clickable': clickable },
      { 'nhsuk-card--feature': feature },
      {'nhsuk-summarycard--grey': grey},  
      {'nhsuk-summarycard--red': red},  
      {'nhsuk-summarycard--green': green},  
      {'nhsuk-summarycard--blue': blue}, 
      {'nhsuk-summarycard--yellow': yellow},      
      className,
    )}
    {...rest}
  >
    <SummaryCardContext.Provider value={{ feature: Boolean(feature) }}>{children}</SummaryCardContext.Provider>
  </div>
);

SummaryCard.defaultProps = {
  feature: false,
};

SummaryCard.Heading =SummaryCardHeading;
SummaryCard.Description = SummaryCardDescription;
SummaryCard.Content = SummaryCardContent;
SummaryCard.Image = SummaryCardImage;
SummaryCard.Link = SummaryCardLink;
SummaryCard.Group = SummaryCardGroup;
SummaryCard.GroupItem = SummaryCardGroupItem;

export default SummaryCard;