import React from "react";
import { filterHtml } from "../../Helper/Utility";

const htmlToJsx = (htmlString: string): JSX.Element => {
  const tagDiv = document.createElement("div");
  tagDiv.innerHTML = htmlString;

  const generateJsx = (node: Node): JSX.Element | string => {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.nodeValue!;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const tagName = (node as Element).tagName.toLowerCase();
      const attributes = Array.from((node as Element).attributes).reduce((acc, attr) => {
        if (attr.name === "style") {
          const styleObject = attr.value.split(";").reduce((styleAcc, styleStr) => {
            const [property, value] = styleStr.split(":");
            if (property && value) {
              styleAcc[property.trim()] = value.trim();
            }
            return styleAcc;
          }, {} as Record<string, string>);
          acc.style = styleObject;
        } else {
          acc[attr.name] = (node as Element).getAttribute(attr.name) || "";
        }
        return acc;
      }, {} as Record<string, string | Record<string, string>>);

      const children = Array.from((node as Element).childNodes).map((childNode) => {
        return generateJsx(childNode);
      });

      return React.createElement(
        tagName,
        { dangerouslySetInnerHTML: undefined, ...attributes },
        ...children
      );
    } else {
      return "";
    }
  };

  const jsxElement = React.createElement(
    React.Fragment,
    {},
    ...Array.from(tagDiv.childNodes).map((childNode) => {
      return generateJsx(childNode);
    })
  );

  tagDiv.remove();
  return jsxElement;
};

const loadEditorHtml = (htmlData: string) => {
  return htmlToJsx(filterHtml(htmlData));
};

const EditorContent: React.FC<{ htmlContent: string }> = ({ htmlContent }) => {
  return loadEditorHtml(htmlContent);
};

export default EditorContent;
