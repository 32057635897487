import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { IProcessAndStorageLocations } from "../../models/app.interface";

const initialState: IProcessAndStorageLocations = {
    selectedLocation: "",
    locationRegion: undefined, //Api
    locationId: "",
    locationRecordType: undefined,
    aboutProcessingStorageLocationsSectionStatus: ""
};

export const ProcessingStorageLocationsSlice = createSlice({
  name: "ProcessingStorageLocations",
  initialState,
  reducers: {
    setProcessingStorageLocationsData: (
      state: IProcessAndStorageLocations,
      action: PayloadAction<IProcessAndStorageLocations>
    ) => {
      state = Object.assign(state, action.payload);
    },
    resetProcessingStorageLocationsData:  ()=> initialState
  },
});

export const { setProcessingStorageLocationsData, resetProcessingStorageLocationsData } =
  ProcessingStorageLocationsSlice.actions;
export const getProcessingStorageLocationsDetails = (state: RootState) =>
  state.processingStorageLocationsDetails;
export default ProcessingStorageLocationsSlice.reducer;
