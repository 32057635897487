import { Button, Checkboxes,  Hint, Input, InsetText, Label, ReviewDate, Table } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import GoBack from "../../../../Components/Shared/GoBack";
import { GetPageConfig_URL, GetProductLegalCover_URL } from "../../../../config/api-endpoints.config";
import { dataSetErrorMessage, dsaContent, fileTypeSize, initialStatePageConfig, LegalBasesHintText, pageConfigList, routeName } from "../../../../config/page.config";
import { getCookie, submitSpinner, hasEmptyValueForKey, fetchInterceptor } from "../../../../Helper/Utility";
import useFetch from "../../../../Hooks/useFetch";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApiResDataSetList, IApiResLegalBasesCover, IDataSet, ILegalBasesCover, ILegalBasesCovers, IPageConfig } from "../../../../state/models/app.interface";
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";

const LegalBases: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [loading, setLoading] = useState(true);
    const [dataSetData, setDataSetData] = useState<IDataSet>(useAppSelector(getDataSetDetails) || {});
    const [legalBasesErrorMessage, setLegalBasesErrorMessage] = useState("");
    const [pageData, setPageData] = useState<IPageConfig>(initialStatePageConfig);

    const parameters = {
        "UserAuthTokenId": authenticationTokenCookie,
        "Id": pageConfigList.LegalBasisDatasetJourney
    }
    const { response, loading: reviewDateLoader }: any = useFetch(GetPageConfig_URL, "POST", {}, parameters);

    /* To dispatch the setDataSetDetailsData Redux action */
    const saveDataSetDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }
    /*Get, Set Legal Bases*/
    const getProductLegalCover = () => {    
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "AssociatedProduct":
            {
                "CRMProductId": dataSetData.crmProductId,
                "DataSetLegalBasisList": hasEmptyValueForKey((dataSetData?.dataSetLegalBasesList!)[0]) ? null : dataSetData.dataSetLegalBasesList
            }
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetProductLegalCover_URL, requestOptions)
            .then(response => {
                    return response.json()
            })
            .then(data => {
                setProductLegalCover(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setProductLegalCover = (response: IApiResDataSetList) => {
        let updatedState: IDataSet = {};
        let legalBasesCover: ILegalBasesCover = {};
        let legalBasesCoverList: ILegalBasesCover[] = [];
        if (response?.DataSetLegalBasisList!.length > 0) {
            response?.DataSetLegalBasisList!.map((legalBases: IApiResLegalBasesCover) => {
                legalBasesCover = {
                    value: legalBases.Value,
                    name: legalBases.Name,
                    checked: legalBases.Checked,
                    text: legalBases.Text,
                    crmId: legalBases.crmId,
                    processing: legalBases.Processing,
                    dissemination: legalBases.Dissemination,
                    otherLegalBasis: legalBases.OtherLegalBasis,
                    displayProcessing: legalBases.DisplayProcessing,
                    displayDissemination: legalBases.DisplayDissemination,
                    productLegalBasis: legalBases.ProductLegalBasis,
                    otherComments: legalBases.OtherComments,
                    orderBy: legalBases.OrderBy,
                    availableOnline: legalBases.AvailableOnline,
                    currentlyCheckedInCRM: legalBases.CurrentlyCheckedInCRM,
                    notes: legalBases.Notes,
                    description: legalBases.Description
                }
                legalBasesCoverList.push(legalBasesCover);
            });
            updatedState = {
                ...dataSetData,
                dataSetLegalBasesList: legalBasesCoverList
            }
            setDataSetData(updatedState);
            saveDataSetDataInStore(updatedState);
        }
        setLoading(false);
    }

    const setHintText = (value: string, description: string) => {
        switch (value) {
            case LegalBasesHintText.LB006:
                return (
                    <>
                        <Hint>Provide information in the text box </Hint>
                        <div className="nhsuk-u-padding-bottom-1"></div>
                        <Input type="text" autoComplete="off" className="nhsuk-input nhsuk-input--width-20" name={dsaContent.dataSetSelectedLegalBasesOtherText} id={dsaContent.dataSetSelectedLegalBasesOtherText} onChange={handleChange} value={dataSetData?.dataSetSelectedLegalBasesOtherText} />
                    </>
                );
            case LegalBasesHintText.LB020:
            case LegalBasesHintText.LB021:
                return (
                    <Hint>{description}</Hint>
                );
            case LegalBasesHintText.LB023:
            case LegalBasesHintText.LB024:
            case LegalBasesHintText.LB027:
                return (
                    <Hint>{description} </Hint>
                );
            case LegalBasesHintText.LB022:
                return (
                  <Hint>
                        {description ? <>   {description.split('\n').map((str, index) => (
                            <React.Fragment key={index}>
                                {index === 0 ? <>{str}</> : <p>{str}</p>}
                            </React.Fragment>
                        ))} </> : null
                        } </Hint> );
        }
    }

    /*To handle change of Checkbox*/
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IDataSet = {};
        let legalBasesCover: ILegalBasesCover = {};
        let legalBasesCoverList: ILegalBasesCover[] = [];
        let dataSetSelectedLegalBasesOtherText = dataSetData.dataSetSelectedLegalBasesOtherText;
        if (e.target.name === dsaContent.dataSetSelectedLegalBasesOtherText) {
            dataSetSelectedLegalBasesOtherText = e.target.value
        }
        if (e.target.value !== null && e.target.value !== undefined) {
            dataSetData?.dataSetLegalBasesList?.forEach((legalBases: ILegalBasesCover) => {
                if (e.target.value === legalBases.value) {
                    legalBasesCover = {
                        value: legalBases.value,
                        name: legalBases.name,
                        checked: e.target.checked,
                        text: legalBases.text,
                        crmId: legalBases.crmId,
                        processing: legalBases.processing,
                        dissemination: legalBases.dissemination,
                        otherLegalBasis: legalBases.otherLegalBasis,
                        displayProcessing: legalBases.displayProcessing,
                        displayDissemination: legalBases.displayDissemination,
                        productLegalBasis: legalBases.productLegalBasis,
                        otherComments: legalBases.otherComments,
                        orderBy: legalBases.orderBy,
                        availableOnline: legalBases.availableOnline,
                        currentlyCheckedInCRM: legalBases.currentlyCheckedInCRM,
                        notes: legalBases.notes,
                        description: legalBases.description
                    }
                    legalBasesCoverList.push(legalBasesCover);
                } else {
                    if (legalBases.name!.toLowerCase() === dsaContent.otherText) {
                        legalBasesCover = {
                            ...legalBases,
                            otherComments: dataSetSelectedLegalBasesOtherText
                        }
                        legalBasesCoverList.push(legalBasesCover);
                    }
                    else {
                        legalBasesCoverList.push(legalBases);
                    }
                }

            })
        }
        updatedState = {
            ...dataSetData,
            dataSetLegalBasesList: legalBasesCoverList,
            dataSetSelectedLegalBasesOtherText: dataSetSelectedLegalBasesOtherText
        }
        setDataSetData(updatedState);
        saveDataSetDataInStore(updatedState);
        resetError(e);
    }
    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setLegalBasesErrorMessage("");
        }
    }
    /* To Validate the each field's data */
    /* To handle Onclick */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = validateFields();
        if (IsValid) {
            submitSpinner(e, submitDataSetLegalBases());
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    const validateFields = () => {
        let isValid: boolean = false;
        let isValidOther: boolean = true;
        dataSetData?.dataSetLegalBasesList?.forEach((dsaApplication: ILegalBasesCover) => {
            if (dsaApplication.checked)
                isValid = true;
        })
        let otherlegalbasis = dataSetData.dataSetLegalBasesList?.filter(x => x.text?.toLowerCase() === dsaContent.otherText)[0];
        if (otherlegalbasis?.checked && (dataSetData.dataSetSelectedLegalBasesOtherText === '' || dataSetData.dataSetSelectedLegalBasesOtherText === undefined || dataSetData.dataSetSelectedLegalBasesOtherText === null)) {
            isValidOther = false;
        }
        else {
            isValidOther = true;
        }
        if (!isValid) {
            setLegalBasesErrorMessage(`${dataSetErrorMessage.legalBasesMandatory_ErrorMessage}`);
        }
        else if (!isValidOther) {
            setLegalBasesErrorMessage(`${dataSetErrorMessage.legalBasesOtherMandatory_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const submitDataSetLegalBases = () => {
        navigate(routeName.dsaCohort);
    }

    useEffect(() => {
        getProductLegalCover();
    }, [])

    useEffect(() => {
        response && setPageData(response);
    }, [response]);

    /*Get, Set DSPT Status*/
    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading || reviewDateLoader ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom">About the data &gt; {dataSetData.name} </span>
                                <h1>Legal basis</h1>
                                {legalBasesErrorMessage !== "" && <ErrorDetails errorDescription={legalBasesErrorMessage} errorHref="#selectLegalBasis"></ErrorDetails>}
                                <InsetText>
                                    <p>Choose the legal basis or bases that best supports your processing activities.</p>
                                    <p>If you select 'UK General Data Protection Regulation Article 6 (1) (f)', you may be asked to share your Legitimate Interest Assessment (LIA) during the applications assurance process.</p>
                                    <p>Learn more about the <a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/lawful-basis-for-processing/" target="_blank">lawful basis for processing</a> (opens in a new window).</p>
                                    <p>Learn more about <a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/special-category-data/" target="_blank">special category data</a> (opens in a new window).</p>
                                    <p>If you need help, contact the Information Governance (IG) team.</p>
                                </InsetText>
                                <Checkboxes name="selectLegalBasis" id="selectLegalBasis" error={legalBasesErrorMessage}>
                                    <p className="nhsuk-u-margin-bottom-1">Choose your legal basis or bases.</p>
                                    <Table responsive>
                                        <Table.Head>
                                            <Table.Row>
                                                <Table.Cell>{` `}</Table.Cell>
                                                <Table.Cell>Legal Basis</Table.Cell>
                                            </Table.Row>
                                        </Table.Head>
                                        <Table.Body>
                                            {
                                                dataSetData.dataSetLegalBasesList != null ? (
                                                    dataSetData.dataSetLegalBasesList.map((legalbases, index) => (
                                                        <Table.Row key={index} >
                                                            <Table.Cell>
                                                                <Checkboxes.Box value={legalbases?.value} checked={legalbases?.checked} onChange={handleChange}>
                                                                    <Label> </Label>
                                                                </Checkboxes.Box>
                                                            </Table.Cell>
                                                            <Table.Cell  >{legalbases.text}
                                                                {
                                                                    setHintText(legalbases.value!, legalbases.description!)
                                                                }
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))
                                                ) : (null)
                                            }
                                        </Table.Body>
                                    </Table>
                                </Checkboxes>
                                <div className="nhsuk-u-padding-bottom-8"></div>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                                <div className="nhsuk-u-padding-bottom-1"></div>
                                <ReviewDate
                                    lastReviewed={pageData.PageLastReviewed}
                                    nextReview={pageData.NextReviewDue}
                                />
                            </div>
                        }
                    </div>
                </main>
            </div>
        </>
    )
}
export default LegalBases