import { Button, Checkboxes, ErrorSummary, Label, SummaryList } from "nhsuk-react-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Attachment from "../../../Components/Attachment/Attachment";
import Loader from "../../../Components/Loader/Loader";
import GoBack from "../../../Components/Shared/GoBack";
import { ApproveApplication_URL } from "../../../config/api-endpoints.config";
import { dsaContent, dsaErrorMessage, fileTypeSize, routeName } from "../../../config/page.config";
import { downloadPdfFile, fetchInterceptor, getCookie, getDate, setGeneratedBlobUrl, submitSpinner } from "../../../Helper/Utility";
import { useAppSelector, useAppDispatch } from "../../../state/app/hooks";
import { IApiResActiveAgreementList, IApplication, IUserDetails } from "../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../state/slice/DSA/DSA.slice";
import { getUserDetails } from "../../../state/slice/Header/Header.slice";
import { AppDispatch } from "../../../state/app/store";

/* DSA Approve Page*/
const DSAApprove: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [blobUrl, setBlobUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const UserDetailsData: IUserDetails = useAppSelector(getUserDetails);
    const initialStateofUserData: IUserDetails = UserDetailsData || {};
    const [userData, setUserData] = useState(initialStateofUserData);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);
    const [isConfirmChecked, setIsConfirmChecked] = useState("");
    const [confirmError, setConfirmError] = useState("");
    const [downloadClicked, setDownloadClicked] = useState(false);
    const abortController = new AbortController();
    /*Get Version from Reference Number*/
    const getVersionNumber = () => dsaApplicationData?.referenceNumber?.split('-')[dsaApplicationData?.referenceNumber?.split('-').length - 1].slice(1);

    /*Get DSA PDF file */
    const getDsaPDFFile = () => {
        downloadPdfFile(abortController, dsaApplicationData?.id!, dsaApplicationData?.referenceNumber!, setBlobUrl);
    }
    const saveDSAApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    /* Handle Checkbox change*/
    const handleChange = () => {
        if (isConfirmChecked === "" || isConfirmChecked === dsaContent.no) {
            setIsConfirmChecked(dsaContent.yes!);
            setConfirmError("");
        }
        else if (isConfirmChecked === "" || isConfirmChecked === dsaContent.yes) {
            setIsConfirmChecked(dsaContent.no!);
        }
    }

    /* Submit functionality*/
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (isConfirmChecked === dsaContent.yes) {
            submitSpinner(e, submitDSAApproval);
            setConfirmError("");
        }
        else {
            setConfirmError(`${dsaErrorMessage.dsaApprovalConfirm_ErrorMessage}`);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const submitDSAApproval = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "Id": dsaApplicationData.dataControllerId,
            "PONumber": (dsaApplicationData.poNumber === null || dsaApplicationData.poNumber === undefined || dsaApplicationData.poNumber === "") ? null : dsaApplicationData.poNumber,
            "PurchaseOrderNotRequired": (dsaApplicationData.poNumber !== "") ? false : dsaApplicationData.isRequiredPOnumber,
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(ApproveApplication_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setDSADetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setDSADetails = (response: IApiResActiveAgreementList) => {
        let updatedState: IApplication = {};
        updatedState = {
            ...dsaApplicationData,
            id: dsaApplicationData.id,
            referenceNumber: dsaApplicationData.referenceNumber,
            title: dsaApplicationData.title,
            startDate: getDate(dsaApplicationData.startDate),
            endDate: getDate(dsaApplicationData.endDate),
            dataControllerId: dsaApplicationData.dataControllerId,
            status: dsaApplicationData.status,
            numberOfSignatures: response.NumberOfSignatures,
            numberOfSignees: response.NumberOfSignees,

        }
        setDSAApplicationDetailsData(updatedState);
        saveDSAApplicationDataInStore(updatedState);
        navigate(routeName.dsaConfirmation);
    }

    const handleDownload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        event.preventDefault();
        setDownloadClicked(true);
    }

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#confirm">
                            {dsaErrorMessage.dsaApprovalConfirm_ErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    useEffect(() => {
        getDsaPDFFile();
        setUserData(UserDetailsData);
        setLoading(false);
    }, [UserDetailsData]);

    useEffect(() => {
        if (downloadClicked && blobUrl) {
            setGeneratedBlobUrl(blobUrl);
            setDownloadClicked(false);
        }
    }, [downloadClicked, blobUrl]);

    useEffect(() => {
        return (() => {
            abortController.abort();
        });
    }, [navigate]);

    return (
        <>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <div className="nhsuk-grid-column-full">
                                <Loader loadingText={dsaContent.txtLoading} />
                            </div>
                            :
                            <>
                                <div className="nhsuk-grid-column-two-thirds">
                                    <GoBack></GoBack>
                                    <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                        {userData?.userOrgName}
                                    </span>
                                    <h1>Agree your Data Sharing Agreement</h1>
                                    {confirmError !== "" ? errorSummary : null}
                                    <Label>Once you've read your agreement, you'll be ready to accept it.</Label>
                                    <div className="nhsuk-u-padding-bottom-3"></div>
                                    <Label>By selecting 'Accept Agreement', you confirm that the details provided in the application are true and complete, and that you accept the agreement's terms and conditions.</Label>
                                    <div className="nhsuk-u-padding-bottom-3"></div>
                                    <Label size="m">Your application</Label>
                                    <SummaryList noBorder>
                                        <SummaryList.Row>
                                            <SummaryList.Key>Reference number</SummaryList.Key>
                                            <SummaryList.Value>{dsaApplicationData?.referenceNumber}</SummaryList.Value>
                                        </SummaryList.Row>
                                        <SummaryList.Row>
                                            <SummaryList.Key>Start and end date</SummaryList.Key>
                                            <SummaryList.Value>{getDate(dsaApplicationData?.startDate)} - {getDate(dsaApplicationData?.endDate)}</SummaryList.Value>
                                        </SummaryList.Row>
                                    </SummaryList>
                                    <Label>
                                        If you need help with the application, email <a href={'mailto:data.applications@nhsdigital.nhs.uk?subject=' + dsaApplicationData?.referenceNumber}>data.applications@nhsdigital.nhs.uk</a>, quoting the application reference number in the subject field.
                                    </Label>
                                    <div className="nhsuk-u-padding-bottom-7"></div>
                                    <Attachment>
                                        <Attachment.Thumbnail>
                                        </Attachment.Thumbnail>
                                        <Attachment.Details>
                                            <Attachment.Heading>
                                                <p><Attachment.Link href="#" target="_blank" className={downloadClicked ? "nhsuk-link-spinner nhsuk_download_spinner" : ""} onClick={handleDownload}>Data Sharing Agreement {getVersionNumber()}</Attachment.Link></p>
                                            </Attachment.Heading>
                                            <Attachment.Content >
                                                <Attachment.Property>PDF</Attachment.Property>,<Attachment.Property> less than 1 MB</Attachment.Property>
                                            </Attachment.Content>
                                        </Attachment.Details>
                                    </Attachment>
                                </div>
                                <div className="nhsuk-grid-column-full">
                                    <div className="nhsuk-u-padding-bottom-4"></div>
                                    <Checkboxes name="confirm" id="confirm" error={confirmError}>
                                        <Checkboxes.Box value={isConfirmChecked} checked={isConfirmChecked === dsaContent.yes ? true : false} onChange={handleChange}>I confirm that the details provided in the application are true and complete and accept the terms and conditions as described in the document.</Checkboxes.Box>
                                    </Checkboxes>
                                    <div className="nhsuk-u-padding-bottom-4"></div>
                                    <Button name="acceptAgreement" onClick={handleClick} id="nhsuk-button-spinner">{dsaContent.acceptAgreementText}</Button>
                                </div>
                            </>
                        }
                    </div>
                </main>
            </div>
        </>
    )
}

export default DSAApprove;