import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { IDataSet, IDataSetFieldGroupList, IDataSetFieldList, IDataSetFrequency, IHistoricAnnualData, ILegalBasesCover } from '../../models/app.interface'

export const initialStateHistoricData: IHistoricAnnualData = {
    period: "",
    fileAvailabilityId: "",
    periodValue: "",
    displayText: "",
    selected: false,
    periodAreadyMappedToFileAvailabilityInCRM: false,
    alreadyHeld: false,
    version:""
}
const initialStateFrequency: IDataSetFrequency = {
    value: ""
}

const initialStateFieldGroupList: IDataSetFieldList = {
    id: "",
    groupFieldId: "",
    groupName: "",
    fieldName: "",
    fieldType: "",
    fieldLogicalName: "",
    sensitiveFlag: false,
    identifiableFlag: false,
    isGroupField: false,
    isPrimaryField: false,
    description: "",
    startDate: "",
    endDate: "",
    groupType: ""
}
const initialStateGroupFieldList: IDataSetFieldGroupList = {
    groupName: "",
    groupId: "",
    groupFieldList: [initialStateFieldGroupList]
}
export const initialLegalBases: ILegalBasesCover = {
    value: "",
    crmId: "",
    text: "",
    checked: false,
    name: "",
    availableOnline: false,
    currentlyCheckedInCRM: false,
    displayDissemination: false,
    displayProcessing: false,
    dissemination: false,
    notes: "",
    orderBy: 0,
    otherComments: "",
    otherLegalBasis: false,
    processing: false,
    productLegalBasis: false,
    description: ""
}
const initialState: IDataSet = {
    dataDissemination: false,
    serviceType: "",
    description: "",
    crmUID: "",
    aggregatedOrRecordLevelData: "",
    linkedToCohort: false,
    datasetFrequency: "",
    historicAnnualDataList: [initialStateHistoricData],
    serviceTypeOneOff: false,
    serviceTypeRegularBespoke: false,
    serviceTypeRegularStandard: false,
    serviceTypeSystemAccess: false,
    standardisationServiceLevelOptionsSelected: "",
    frequencylist: [initialStateFrequency],
    datasetCohort: "",
    selectedProductFieldsList: [initialStateFieldGroupList],
    firstPointer: 0,
    selectedPaginationPage: 0,
    updatedDaily: false,
    annualPeriodsExist: false,
    currentYTDExists: false,
    latestRelease: false,
    futureRelease: false,
    historicAnnual: false,
    initialHistoricAnnualDataList: [initialStateHistoricData],
    groupByFieldsList: [initialStateGroupFieldList],
    currentCRMSelectedDatasetFields: [initialStateFieldGroupList],
    existingHistoricAnnualDataList: [initialStateHistoricData],
    stepsMinimiseDataRequested: "",
    identifiableData: "",
    legalBasesIdentifiableData: "",
    dataSetLegalBasesList: [initialLegalBases],
    currentDataSetLegalBasesList: [initialLegalBases],
    dataSetSelectedLegalBasesOtherText: "",
    temporaryFutureRelease: false,
    temporaryHistoricAnnual: false,
    temporaryLatestRelease: false,
    temporaryHistoricAnnualDataList: [initialStateHistoricData]
}


export const DataSetSlice = createSlice({
    name: 'DataSet',
    initialState,
    reducers: {
        setDataSetDetailsData: (state: IDataSet, action: PayloadAction<IDataSet>) => {
            state = Object.assign(state, action.payload);
        },
        resetDataSetDetailsData: (state: IDataSet, action: PayloadAction<IDataSet>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setDataSetDetailsData, resetDataSetDetailsData } = DataSetSlice.actions;
export const getDataSetDetails = (state: RootState) => state.dataSetDetails;
export default DataSetSlice.reducer;