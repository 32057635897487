import React, { useEffect, useState } from "react";
import { Button, Label, Hint, ErrorSummary, InsetText, Input, Table, Checkboxes, Details } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IApiResDataSetFieldGroupList, IApiResDataSetFieldList, IApiResDataSetList, IApplication, IDataSet, IDataSetFieldGroupList, IDataSetFieldList, IParticipantLegalBasis } from "../../../../state/models/app.interface";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";
import { getCookie, fetchInterceptor, arrayChunk, getDate, submitSpinner } from "../../../../Helper/Utility";
import { dataSetErrorMessage, dsaContent, dataSetFieldGroupTypeOptions, routeName, fileTypeSize } from "../../../../config/page.config";
import { GetProductFields_URL } from "../../../../config/api-endpoints.config";
import Loader from "../../../../Components/Loader/Loader";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";


const SelectDataSetFields: React.FC = () => {

    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);

    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [dataSetFieldsErrorMessage, setDataSetFieldsErrorMessage] = useState("");
    const dataSetDetailsData: IDataSet = useAppSelector(getDataSetDetails);
    const initialState: IDataSet = dataSetDetailsData || {};
    const [dataSetData, setDataSetData] = useState(initialState);

    /* To dispatch the setDataSetDetailsData Redux action */
    const saveDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }
    const [hasNoFieldList, SetNoHasFieldList] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dataSetFieldGroupList, setDataSetFieldGroupList] = useState<IDataSetFieldGroupList[]>([]);
    const [filteredDataSetFieldList, setFilteredDataSetFieldList] = useState<IDataSetFieldGroupList[]>([]);

    const [currentPage, setCurrentPage] = useState(dataSetData?.selectedPaginationPage || 1);
    const [firstPointer, setFirstPointer] = useState(dataSetData?.firstPointer || 1);
    const [totalPages, setTotalPages] = useState(0);
    const [isSearch, setIsSearch] = useState(false);
    const colNumber: number = 2;

    let fieldDataList: IDataSetFieldList[] = dataSetData?.selectedProductFieldsList?.filter((x: IDataSetFieldList) => x !== undefined && x !== null && x?.id !== "").length !== 0 ? dataSetData?.selectedProductFieldsList! : [];

    /* To handle the GroupSelection or single Selection */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, isGroupSelection: boolean) => {
        if (event) {
            let updatedState: IDataSet = {};
            let selectedData: IDataSetFieldList[];
            let removeSelectedItems: IDataSetFieldList[];
            if (isGroupSelection) {
                const targetName = event.target.name;
                const childElement = document.getElementsByName(targetName);
                const arrayNodeList = Array.from(childElement);
                selectedData = getSelectedFieldData(arrayNodeList as IParticipantLegalBasis[]);
                removeSelectedItems = removeItems(arrayNodeList);
            }
            else {
                const arrayNodeList = [];
                arrayNodeList.push(event.target);
                selectedData = getSelectedFieldData(arrayNodeList);
                removeSelectedItems = removeItems(arrayNodeList);
            }

            if (event.target.checked) {
                fieldDataList = Object.assign([], fieldDataList);
                fieldDataList = [...fieldDataList, ...selectedData];
                updatedState = {
                    ...dataSetData,
                    selectedProductFieldsList: fieldDataList
                }
                setDataSetData(updatedState);
                saveDataInStore(updatedState);
                resetError(fieldDataList);
            }
            else if (!event.target.checked) {
                /*to uncheck the checkbox */
                removeSelectedItems?.forEach((item: IDataSetFieldList) => {
                    fieldDataList?.forEach((items: IDataSetFieldList, index: number) => {
                        if (items?.id === item?.id) {
                            const copyArray = [...fieldDataList];
                            copyArray?.splice(index, 1);
                            fieldDataList = copyArray;
                        }
                    })
                })
                updatedState = {
                    ...dataSetData,
                    selectedProductFieldsList: fieldDataList
                }
                setDataSetData(updatedState);
                saveDataInStore(updatedState);
            }
        }
    };

    /* To get selected record */
    const getSelectedFieldData = (arrayList: IParticipantLegalBasis[]) => {
        let groupFieldsDataList: IDataSetFieldGroupList[] = [];
        let groupFieldListData: IDataSetFieldList = {};
        arrayList?.map((item: IParticipantLegalBasis) => {
            if (item.value !== "" && item.value !== undefined && item.value !== null && ((item.checked && arrayList?.length === 1) || (!item.checked && arrayList?.length > 1))) {
                datasetFieldsArray?.map((items: IDataSetFieldGroupList) => {
                    items?.groupFieldList?.filter((data: IDataSetFieldList) => {
                        return data?.id?.toLowerCase().match(`.*${item.value!.toLowerCase()}.*`)
                    }).map((item: IDataSetFieldList) => {
                        groupFieldListData = {
                            id: item?.id
                        };
                        groupFieldsDataList = Object.assign([], groupFieldsDataList);
                        groupFieldsDataList.push(groupFieldListData);
                    });
                })
            }
        })
        return groupFieldsDataList;
    }

    /* To handle selectAll checkbox */
    const getSelectedAllGroupType = (itemList: IDataSetFieldList[], groupType: string) => {
        let counterActualData: number = 0;
        itemList?.forEach((data: IDataSetFieldList) => {
            if (String(data?.groupType) === String(groupType)) {
                counterActualData++;
            }
        })

        let counterStoredData: number = 0;
        dataSetData?.selectedProductFieldsList?.forEach((item: IDataSetFieldList) => {
            let isMatch: boolean = false;
            isMatch = itemList?.find((items: IDataSetFieldList) => (String(item?.id) === String(items?.id)
                && String(items?.groupType) === String(groupType))) ? true : false;
            if (isMatch) { counterStoredData++; }
        })
        return (counterActualData !== 0 && counterStoredData !== 0 && counterActualData === counterStoredData);
    }

    /* To reset the error */
    const resetError = (fieldDataList: IDataSetFieldList[]) => {
        if (fieldDataList?.filter((x: IDataSetFieldList) => x !== undefined && x !== null && x?.id !== "").length !== 0) {
            setSummaryLinkErrorMessage("");
            setDataSetFieldsErrorMessage("");
        }
    }

    /* Get all DataSetFields */
    const getDataSetFields = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "AssociatedProduct": {
                "CRMUID": dataSetData.crmUID,
                "CRMProductId": dataSetData.crmProductId,
            }
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetProductFields_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setDataSetFields(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    /* Set all DataSetFields */
    const setDataSetFields = (response: IApiResDataSetList) => {
        let groupList: IDataSetFieldGroupList[] = [];
        let lengthOfFields: number = 0;
        if (response !== undefined && response !== null && response! && response?.DataSetFieldGroupList!.length > 0) {
            response?.DataSetFieldGroupList?.map((items: IApiResDataSetFieldGroupList) => {
                let groupFieldsDataList: IDataSetFieldList[] = [];
                items?.GroupFieldList?.map((item: IApiResDataSetFieldList) => {
                    const groupFieldListData: IDataSetFieldList = {
                        id: item?.Id,
                        groupFieldId: item?.GroupFieldId,
                        groupName: item?.GroupName,
                        fieldName: item?.FieldName,
                        fieldType: item?.FieldType,
                        fieldLogicalName: item?.FieldLogicalName,
                        sensitiveFlag: item?.SensitiveFlag,
                        identifiableFlag: item?.IdentifiableFlag,
                        isGroupField: item?.IsGroupField,
                        isPrimaryField: item?.IsPrimaryField,
                        description: item?.Description,
                        startDate: item?.StartDate,
                        endDate: item?.endDate,
                        groupType: item?.GroupType
                    }
                    groupFieldsDataList.push(groupFieldListData);
                })
                const dataSetFieldGroupList: IDataSetFieldGroupList = {
                    groupName: items?.GroupName,
                    groupId: items?.GroupId,
                    groupFieldList: groupFieldsDataList
                }
                groupList.push(dataSetFieldGroupList);
                setDataSetFieldGroupList(groupList);
                lengthOfFields++;
            })
            setTotalPages(lengthOfFields);
        }
        else if (response?.DataSetFieldGroupList?.length == undefined || response?.DataSetFieldGroupList?.length === 0) {
            SetNoHasFieldList(true);
        }
        setLoading(false);
    }

    let datasetFieldsArray: IDataSetFieldGroupList[] = dataSetFieldGroupList ? Array.from(dataSetFieldGroupList) : [];

    /* To remove selected record */
    const removeItems = (arrayList: IDataSetFieldList[]) => {
        let groupFieldsDataList: IDataSetFieldList[] = [];
        let groupFieldListData: IDataSetFieldList = {};
        arrayList?.map((item: IDataSetFieldList) => {
            if (item.value !== "" && item.value !== undefined && item.value !== null) {
                datasetFieldsArray?.map((items: IDataSetFieldGroupList) => {
                    items?.groupFieldList?.filter((data: IDataSetFieldList) => {
                        return data?.id?.toLowerCase().match(`.*${item.value!.toLowerCase()}.*`)
                    }).map((item: IDataSetFieldList) => {
                        groupFieldListData = {
                            id: item?.id
                        };
                        groupFieldsDataList = Object.assign([], groupFieldsDataList);
                        groupFieldsDataList.push(groupFieldListData);
                    });
                })
            }
        })
        return groupFieldsDataList;
    }

    /* Deviding DataSet fields in subgroup chunk to handle the UI */
    const subGroupFieldsList = (arr: IDataSetFieldList[], n: number, groupType: string) => {
        if (arr !== undefined && arr !== null && arr?.filter((x: IDataSetFieldList) => x !== undefined && x !== null && x.groupType !== "").length !== 0) {
            const groupList = arr.filter(
                (item: IDataSetFieldList) => String(item?.groupType) === String(groupType)
            )
            return arrayChunk(groupList, n);
        }
    }

    /* To render data based GroupData */
    const renderFieldsData = (data: IDataSetFieldGroupList[]) => {
        if (data !== null && data! && data !== undefined) {
            return data.map((item: IDataSetFieldGroupList, index: number) => (
                <React.Fragment key={index + item?.groupName! + item?.groupId}>
                    <><Table.Head>
                        <Table.Row>
                            <Table.Cell className="nhsuk-table_groupHeader">
                                <div className="nhsuk-label nhsuk-label--m">{item?.groupName}</div>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Head>
                        <Table.Body>
                            {renderFieldsGroupList(item?.groupFieldList!, colNumber, dataSetFieldGroupTypeOptions?.generalValue!, item?.groupId!)}
                            {renderFieldsGroupList(item?.groupFieldList!, colNumber, dataSetFieldGroupTypeOptions?.highRiskValue!, item?.groupId!)}
                            {renderFieldsGroupList(item?.groupFieldList!, colNumber, dataSetFieldGroupTypeOptions?.identifiableValue!, item?.groupId!)}
                         </Table.Body>
                    </>
                </React.Fragment>
            )
            )
        }

    }

    /* To render SubGroup list based on group and GroupType */
    const renderFieldsGroupList = (data: IDataSetFieldList[], colNumber: number, groupType: string, groupId: string) => {
        if (data !== undefined && data !== null && data?.filter((x: IDataSetFieldList) => x !== undefined && x !== null && x.groupType !== "").length !== 0) {
            const dataLength: string = subGroupFieldsList(data, colNumber, groupType)?.filter(x => x !== undefined && x !== null && x !== '')[0];
            return (<React.Fragment>
                {dataLength.length > 0 ?
                    <Table.Row>
                        <Table.Cell className="nhsuk-table_checkbox">
                            <Checkboxes id={groupId + '_' + groupType} name={groupId + '_' + groupType}>
                                <div className="nhsuk-table_rowheader">
                                    <Checkboxes.Box value={groupId + '_' + groupType} checked={getSelectedAllGroupType(data, groupType)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e, true) }}>
                                        <strong>{String(groupType) === String(dataSetFieldGroupTypeOptions.generalValue) ? 'Select All (General)' : String(groupType) === String(dataSetFieldGroupTypeOptions.highRiskValue) ? 'Select all (High Risk)' : String(groupType) === String(dataSetFieldGroupTypeOptions.identifiableValue) ? 'Select all (Identifiable)' : ''} </strong>
                                    </Checkboxes.Box>
                                </div>
                                <Table>
                                    <Table.Body>
                                        {renderFieldsRow(data, colNumber, groupType, groupId)}
                                    </Table.Body>
                                </Table>
                            </Checkboxes>
                        </Table.Cell>
                    </Table.Row>
                    : null}
            </React.Fragment>
            )
        }
    }

    /* To render SubGrouplist row */
    const renderFieldsRow = (data: IDataSetFieldList[], colNumber: number, groupType: string, groupId: string) => {
        const array = subGroupFieldsList(data, colNumber, groupType);
        return array?.map((row: IDataSetFieldList[], index: number) => (
            <Table.Row key={index + 1 + "row" + groupId + '_' + groupType}>
                {renderTableCell(row)}
            </Table.Row>
        ))
    }

    /*TableCell Populate*/
    const renderTableCell = (data: IDataSetFieldList[]) => {
        return data?.map((item: IDataSetFieldList) => {
            return <Table.Cell role="cell" key={item?.id}>
                <Checkboxes.Box value={item?.id} checked={dataSetData?.selectedProductFieldsList?.some(items => String(items?.id) === String(item?.id)) ? true : false} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e, false) }}>
                    {item?.fieldName} <strong>({item?.fieldLogicalName})</strong>
                    <Details>
                        <Details.Summary>Field information</Details.Summary>
                        <Details.Text className="nhsuk-detailsText_wordWrap">
                            <p><strong>Database field name:</strong> {item?.fieldLogicalName}</p>
                            <p><strong>Field name: </strong>{item?.fieldName}</p>
                            <p><strong>Field Description: </strong>{item?.description}</p>
                            <p><strong>Field Type: </strong>{item?.fieldType}</p>
                            <p><strong>Available from: </strong>{getDate(item?.startDate)}</p>
                            <p><strong>Available to: </strong>{item?.endDate ? getDate(item?.endDate) : 'NA'}</p>
                        </Details.Text>
                    </Details>
                </Checkboxes.Box>
            </Table.Cell>

        });
    }

    /*handle next functionality of pagination*/
    const onNext = () => {
        paginate(currentPage + 1);
    }

    /*handle previous functionality of pagination*/
    const onPrevious = () => {
        paginate(currentPage - 1);
    }

    /*handle onClick functionality of pagination*/
    const paginate = (pageNum: number) => {
        setCurrentPage(pageNum);
        setFirstPointer(pageNum);
        updatePaginationState(pageNum);
    }

    /*To update state of pagination*/
    const updatePaginationState = (pageNumber: number) => {
        let updatedState: IDataSet;
        updatedState = {
            ...dataSetData,
            firstPointer: pageNumber,
            selectedPaginationPage: pageNumber
        }
        setDataSetData(updatedState);
        saveDataInStore(updatedState);
    }

    /*To handle pages of pagination*/
    const showPagination = () => {
        return datasetFieldsArray.map((item: IDataSetFieldList, pageNum: number) => {
            return <React.Fragment key={pageNum}>{(pageNum + 1 <= 3 && firstPointer < 3) ?
                paginationNumber(pageNum + 1) :
                (firstPointer > 1 && (pageNum + 1 >= currentPage - 2 && pageNum + 1 <= currentPage)) ?
                    paginationNumber(pageNum + 1)
                    : null}
            </React.Fragment>
        })
    }

    /*To show page number for pagination*/
    const paginationNumber = (pageNum: number) => {
        return <li className={"nhsuk-pagination__item " + (pageNum === currentPage ? "nhsuk-pagination__item--current" : "")} aria-current="page" >
            <a className="nhsuk-link nhsuk-pagination__link" href="#" onClick={() => paginate(pageNum)}>
                {pageNum}
            </a>
        </li>
    }

    const navigateDataMinimisation = () => {
        navigate(routeName.dataMinimisation);
    }

    /* To handle Onclick */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, navigateDataMinimisation);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    /* To handle searchField functionality */
    const handleOnKeyChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const inputValue = target.value;
        if (inputValue.trim() !== undefined && inputValue.trim() !== null && inputValue.trim() !== "") {
            setIsSearch(true);
            const filteredData = datasetFieldsArray?.filter((item: IDataSetFieldGroupList) => {
                const filteredItems= item?.groupName?.toString().toLowerCase().match(`.*${inputValue.toLowerCase()}.*`) || item?.groupFieldList?.filter((childItems: IDataSetFieldList) => filterQuery(childItems, inputValue.toLowerCase()));
                return filteredItems!.length > 0;
            }).map((item: IDataSetFieldGroupList) => (
                {
                    ...item,
                    groupFieldList: item?.groupFieldList?.filter((childItems: IDataSetFieldList) => filterQuery(childItems, inputValue.toLowerCase()))
                }
            ));
            setFilteredDataSetFieldList(filteredData);
        }
        else {
            setIsSearch(false)
        }
    }

    /* filter Query for searchField functionality */
    const filterQuery = (childItems: IDataSetFieldList, inputVal: string) => {
        return childItems?.fieldName?.toString().toLowerCase().match(`.*${inputVal}.*`) ||
            childItems?.fieldType?.toString().toLowerCase().match(`.*${inputVal}.*`) ||
            childItems?.fieldLogicalName?.toString().toLowerCase().match(`.*${inputVal}.*`) ||
            childItems?.description?.toString().toLowerCase().match(`.*${inputVal}.*`) ||
            childItems?.startDate?.toString().toLowerCase().match(`.*${inputVal}.*`) ||
            childItems?.endDate?.toString().toLowerCase().match(`.*${inputVal}.*`)
    }

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>  <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
            <ErrorSummary.Title id="error-summary-title">{dataSetErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
            <ErrorSummary.Body>
                <ErrorSummary.List><ErrorSummary.Item href="#requiredDataSetFields">{summaryLinkErrorMessage}
                </ErrorSummary.Item>
                </ErrorSummary.List></ErrorSummary.Body> </ErrorSummary>
        </>
    )

    /* To Validate the each field's data */
    const validateFields = () => {
        let isValid: boolean = true;
        if (!hasNoFieldList) {
            if (dataSetData?.selectedProductFieldsList?.filter(x => x !== undefined && x !== null && x?.id !== "").length === 0) {
                setSummaryLinkErrorMessage(`${dataSetErrorMessage.selectDataSetFieldsMandatory_ErrorMessage}`);
                setDataSetFieldsErrorMessage(`${dataSetErrorMessage.selectDataSetFieldsMandatory_ErrorMessage}`);
                isValid = false;
            }
        }
        return isValid;
    }

    useEffect(() => {
        getDataSetFields();
    }, [])

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <>
                                <div className="nhsuk-grid-column-two-thirds">
                                    <GoBack></GoBack>
                                    <span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                        About the data &gt; {dataSetData.name}</span>
                                    {hasNoFieldList ? <> <h1>No data set fields to select</h1>
                                        <InsetText>
                                            <p>You do not have to select fields for this data set</p>
                                        </InsetText> </> : <h1>Select the required field(s)</h1>}
                                </div>
                                {hasNoFieldList ? null : <>
                                    <div className="nhsuk-grid-column-two-thirds">
                                        {summaryLinkErrorMessage !== "" ? errorSummary : null}
                                        <InsetText>
                                            <p>The data within your selected field might be categorised as General, High-risk or Identifiable </p>
                                        </InsetText>
                                        <Label>Search for Field Name or Database Field Names. Database Field Names appear in brackets.</Label>
                                        <Hint>You can search for fields by typing keywords in the text box below.</Hint>
                                        <div className="nhsuk-input--width-30">
                                            <div className="nhsuk-input_container">
                                                <Input type="text" autoComplete="off" className="nhsuk-input" name="searchFields" id="searchFields" onKeyUp={handleOnKeyChange} />
                                            </div>
                                        </div>
                                        <div className="nhsuk-u-padding-bottom-4"></div>
                                    </div>
                                    <div className="nhsuk-grid-column-full">
                                        <Checkboxes error={dataSetFieldsErrorMessage}>
                                            <Table id="requiredDataSetFields">
                                                {renderFieldsData(!isSearch ? datasetFieldsArray : filteredDataSetFieldList)}
                                            </Table>
                                            </Checkboxes>
                                        {/*{!isSearch && totalPages > 1 ? <nav className="nhsuk-pagination pag-float-right nhsuk-pagination-alignment" role="navigation" aria-label="results">*/}
                                        {/*    {currentPage !== 1 ?*/}
                                        {/*        <div className="nhsuk-pagination__prev">*/}
                                        {/*            <a className="nhsuk-link nhsuk-pagination__link" href="#" rel="prev" onClick={onPrevious}>*/}
                                        {/*                <svg className="nhsuk-pagination__icon nhsuk-pagination__icon--prev" xmlns="http://www.w3.org/2000/svg" height="13" width="15" aria-hidden="true" focusable="false" viewBox="0 0 15 13">*/}
                                        {/*                    <path d="m6.5938-0.0078125-6.7266 6.7266 6.7441 6.4062 1.377-1.449-4.1856-3.9768h12.896v-2h-12.984l4.2931-4.293-1.414-1.414z"></path>*/}
                                        {/*                </svg>*/}
                                        {/*                <span className="nhsuk-pagination__link-title">Previous</span></a>*/}
                                        {/*        </div> : null}*/}
                                        {/*    <ul className="nhsuk-pagination__list">*/}
                                        {/*        {showPagination()}*/}
                                        {/*        {firstPointer > 1 && totalPages !== currentPage && totalPages !== 3 ?*/}
                                        {/*            <>*/}
                                        {/*                <li className="nhsuk-pagination__item" aria-current="page">*/}
                                        {/*                    ...*/}
                                        {/*                </li>*/}
                                        {/*                {paginationNumber(totalPages)}*/}
                                        {/*            </>*/}
                                        {/*            : null}*/}
                                        {/*    </ul>*/}
                                        {/*    {(totalPages > currentPage) ?*/}
                                        {/*        <div className="nhsuk-pagination__next">*/}
                                        {/*            <a className="nhsuk-link nhsuk-pagination__link" href="#" rel="next" onClick={onNext}>*/}
                                        {/*                <span className="nhsuk-pagination__link-title">Next</span>*/}
                                        {/*                <svg className="nhsuk-pagination__icon nhsuk-pagination__icon--next" xmlns="http://www.w3.org/2000/svg" height="13" width="15" aria-hidden="true" focusable="false" viewBox="0 0 15 13">*/}
                                        {/*                    <path d="m8.107-0.0078125-1.4136 1.414 4.2926 4.293h-12.986v2h12.896l-4.1855 3.9766 1.377 1.4492 6.7441-6.4062-6.7246-6.7266z"></path>*/}
                                        {/*                </svg></a>*/}
                                        {/*        </div> : null*/}
                                        {/*    }*/}
                                        {/*</nav> : ''}*/}
                                    </div>
                                </>}
                                <div className="nhsuk-grid-column-two-thirds">
                                    <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                                </div>
                            </>
                        }
                    </div>
                </main>
            </div>
        </>
    )
}

export default SelectDataSetFields;