import { Navigate, Outlet } from "react-router-dom";
import { routeName } from "../../config/page.config";
import { useAppSelector } from "../../state/app/hooks";
import { getUserDetails } from "../../state/slice/Header/Header.slice";

const ProtectedRoute = () => {
    const { dsfcApproverSecurity, dsaApproverSecurity, dsaApplicantSecurity, dsfcApplicantSecurity, dsfcApplicantApprover, dsfcApprover, isAuthenticated, hasSecurityRole } = useAppSelector(getUserDetails);

  const renderOutlet = () => {
      return (dsfcApproverSecurity || dsaApproverSecurity || dsaApplicantSecurity || dsfcApplicantSecurity || dsfcApprover || dsfcApplicantApprover || hasSecurityRole) ? (
      <Outlet />
    ) : (
      <Navigate to={{ pathname: routeName.noPermission }} />
    );
  };

  return <>{isAuthenticated ? renderOutlet() : <Navigate to={{ pathname: "/" }} />}</>;
};
export default ProtectedRoute;
