import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Label, SummaryList } from "nhsuk-react-components";
import { getCookie, getB64toBlobData, updatePageTitle, setGeneratedBlobUrl, fetchInterceptor } from "../../../Helper/Utility";
import { useAppSelector } from "../../../state/app/hooks";
import { IApiResFiles, IDSFCContractDetails, IUserDetails } from "../../../state/models/app.interface";
import { getDSFCContractDetails } from "../../../state/slice/DSFC/DSFCContractDetails.slice";
import Attachment from "../../../Components/Attachment/Attachment";
import "../../../Content/css/nhsuk-common.css";
import { DisplayApprovedDSFCFile_URL, DisplayDSFCFile_URL } from "../../../config/api-endpoints.config";
import { getUserDetails } from "../../../state/slice/Header/Header.slice";
import Loader from '../../../Components/Loader/Loader';
import { dsfcContent, fileTypeSize, pageTitles, routeName } from '../../../config/page.config';
import GoBack from "../../../Components/Shared/GoBack";

const DSFCDownload: React.FC = () => {
    const navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const DSFCContractDetailsData: IDSFCContractDetails = useAppSelector(getDSFCContractDetails);
    const initialState: IDSFCContractDetails = DSFCContractDetailsData || {};
    const [dsfcData] = useState(initialState);
    const UserDetailsData: IUserDetails = useAppSelector(getUserDetails);
    const initialStateofUserData: IUserDetails = UserDetailsData || {};
    const [userData, setUserData] = useState(initialStateofUserData);
    const [loading, setLoading] = useState(true);
    const [pdfURL, setPdfURL] = useState("");
    const [downloadClicked, setDownloadClicked] = useState(false);
    const getDsfcFile = (applicationId: string) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "DSFCApplicationId": applicationId
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor((dsfcData.isFullySigned) ? DisplayApprovedDSFCFile_URL : DisplayDSFCFile_URL, requestOptions)
            .then(response => {
                    return response.json()
            })
            .then(result => {
                setDsfcPDFFile(result);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setDsfcPDFFile = (response: IApiResFiles) => {
        const contentType: string = fileTypeSize.fileTypePDF;
        const fileName: string = `DSFC_${dsfcData.contractNumber}.pdf`;
        const b64Data: string = response._buffer;
        const blob: Blob = getB64toBlobData(b64Data, contentType, fileName);
        const blobUrl: string = URL.createObjectURL(blob);
        setPdfURL(blobUrl);
    }
    const handleDownload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        event.preventDefault();
        setDownloadClicked(true);
    }

    useEffect(() => {
        updatePageTitle(pageTitles.dsfcDownload);
    }, []);

    useEffect(() => {
        setUserData(UserDetailsData);
        getDsfcFile(dsfcData.dsfcApplicationId!);
        setLoading(false);
    }, [UserDetailsData]);

    useEffect(() => {
        if (downloadClicked && pdfURL) {
            setGeneratedBlobUrl(pdfURL);
            setDownloadClicked(false);
        }
    }, [downloadClicked, pdfURL]);

    return (
        <>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                             <div className="nhsuk-grid-column-full">
                                <Loader loadingText={dsfcContent.txtLoading} />
                            </div>
                            :
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack />
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                {userData?.userOrgName}
                            </span>
                            <p></p>
                            <p></p>
                            <h1>Your Data Sharing Framework Contract </h1>
                            <Label size="m">Your contract</Label>
                            <SummaryList noBorder>
                                <SummaryList.Row>
                                    <SummaryList.Key>Reference number</SummaryList.Key>
                                    <SummaryList.Value>{dsfcData?.contractNumber ? dsfcData?.contractNumber : '-'}</SummaryList.Value>
                                </SummaryList.Row>
                                <SummaryList.Row>
                                    <SummaryList.Key>End date</SummaryList.Key>
                                    <SummaryList.Value>{dsfcData?.endDate ? dsfcData?.endDate : '-'}</SummaryList.Value>
                                </SummaryList.Row>
                            </SummaryList>
                            <Label>Once you have downloaded your Data Sharing Framework Contract (DSFC), consider changing the file name to something that will help you easily locate it. </Label>
                            <p></p>
                            <p></p>
                            <Attachment>
                                <Attachment.Thumbnail>
                                </Attachment.Thumbnail>
                                <Attachment.Details>
                                    <Attachment.Heading>
                                        <p>
                                            <Attachment.Link href="#" target="_blank" className={downloadClicked ? "nhsuk-link-spinner nhsuk_download_spinner" : ""} onClick={handleDownload}>Data Sharing Framework Contract {dsfcData?.version}</Attachment.Link>
                                        </p>
                                    </Attachment.Heading>
                                    <Attachment.Content >
                                        <Attachment.Property>PDF</Attachment.Property>,   <Attachment.Property>less than 1 MB</Attachment.Property>
                                    </Attachment.Content>
                                </Attachment.Details>
                            </Attachment>
                            </div>
                            }
                    </div>
                </main>
            </div>
        </>
    )
}
export default DSFCDownload;