import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { IYieldedBenefits } from '../../models/app.interface';

const initialState: IYieldedBenefits = {
    yieldedBenefits: "",
    aboutYieldedBenefits: ""
}

export const YieldedBenefitsSlice = createSlice({
    name: 'YieldedBenefits',
    initialState,
    reducers: {
        setYieldedBenefitsDetailsData: (state: IYieldedBenefits, action: PayloadAction<IYieldedBenefits>) => {
            state = Object.assign(state, action.payload);
        },
        resetYieldedBenefitsDetailsData: (state: IYieldedBenefits, action: PayloadAction<IYieldedBenefits>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setYieldedBenefitsDetailsData, resetYieldedBenefitsDetailsData } = YieldedBenefitsSlice.actions;
export const getYieldedBenefitsDetails = (state: RootState) => state.yieldedBenefitsDetails;
export default YieldedBenefitsSlice.reducer;