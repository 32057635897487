import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { IDARSSecurityRoles, IDSFCApplicationDashboard, IDSFCContractDashboard, IDSFCDashboard } from '../../models/app.interface'

const initialStateOfDSFCApplication: IDSFCApplicationDashboard = {
    dsfcAppId: "",
    dsfcAppContractNumber: "",
    dsfcAppStatus: "",
    dsfcAppOrganization: ""
}

const initialStateOfDSFCContract: IDSFCContractDashboard = {
    dsfcContId: "",
    dsfcContContractNumber: "",
    dsfcContStatus: "",
    dsfcContOrganization: "",
    dsfcConVersion:""
    }
    
const initialStateApplicantSecurityRole: IDARSSecurityRoles = {
    darsSecurityRole: 0
}

const initialState: IDSFCDashboard = {
    dsfcApplicationDetails: [initialStateOfDSFCApplication],
    dsfcContractDetails: [initialStateOfDSFCContract],
    darsApplicantSecurityRoleDetails: [initialStateApplicantSecurityRole]
}

export const DSFCDashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDSFCDashboardData: (state: IDSFCDashboard, action: PayloadAction<IDSFCDashboard>) => {
            state = Object.assign(state, action.payload);
        },
        resetDSFCDashboardData: (state: IDSFCDashboard, action: PayloadAction<IDSFCDashboard>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setDSFCDashboardData, resetDSFCDashboardData } = DSFCDashboardSlice.actions;
export const getDSFCDashboard = (state: RootState) => state.dsfcContractDetails;
export default DSFCDashboardSlice.reducer;