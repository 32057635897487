import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkboxes, Button, ErrorSummary, InsetText } from "nhsuk-react-components";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { IApplication, ISubmitApplication } from "../../../../state/models/app.interface";
import GoBack from "../../../../Components/Shared/GoBack";
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { getSubmitApplicationDetailsData, setSubmitApplicationDetailsData } from "../../../../state/slice/SubmitApplication/SubmitApplication.slice";
import { dsaContent, dsaErrorMessage, fileTypeSize, routeName, submitApplicationErrorMessage } from "../../../../config/page.config";
import { AppDispatch } from "../../../../state/app/store";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { SubmitDSA_URL } from "../../../../config/api-endpoints.config";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";

const Declaration: React.FC = () => {
    const navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};
    const initialState: ISubmitApplication = useAppSelector(getSubmitApplicationDetailsData) || {};
    const [dsaSubmitApplicationData, setDsaSubmitApplicationData] = useState(initialState);
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [confirmError, setConfirmError] = useState("");
    const dispatch: AppDispatch = useAppDispatch();

    const savedataInStore = (stateData?: ISubmitApplication) => {
        dispatch(setSubmitApplicationDetailsData({ ...dsaSubmitApplicationData, ...stateData }));
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = validateFields();
        if (IsValid) {
            submitSpinner(e, submitDSA);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const submitDSA = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "AgreementTermsAndConditionsFlag": dsaSubmitApplicationData.isConfirmChecked
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitDSA_URL, requestOptions)
            .then(() => {
                navigate(routeName.dsaSubmit);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: ISubmitApplication;
        if (e.target.value !== null && e.target.value !== undefined) {
            updatedState = { ...dsaSubmitApplicationData, [e.target.name]: e.target.checked ? true : false }
            setDsaSubmitApplicationData(updatedState);
            savedataInStore(updatedState);
        }
        resetError(e);
    }

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setConfirmError("");
        }
    }

    const validateFields = () => {
        let isValid: boolean = true;
        if (!dsaSubmitApplicationData.isConfirmChecked) {
            setSummaryLinkErrorMessage(`${submitApplicationErrorMessage.dsaConfirm_ErrorMessage}`);
            setConfirmError(`${submitApplicationErrorMessage.dsaConfirm_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#isConfirmChecked">{summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <h1>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom ">Submit application</span>
                                Declaration</h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <InsetText><p>Before you can submit your application, you must check the box to confirm the statement below.</p></InsetText>
                            <Checkboxes name="isConfirmChecked" id="isConfirmChecked" error={confirmError}>
                                <Checkboxes.Box value={dsaContent.yes} checked={dsaSubmitApplicationData.isConfirmChecked ? true : false} onChange={handleChange}>By checking the box, I confirm that the information within this application is true and complete. I understand that any omission or misrepresentation within the application may lead to its rejection, or cancellation of any resulting agreement.</Checkboxes.Box>
                            </Checkboxes>
                            <div className="nhsuk-u-padding-bottom-4">
                            </div>
                            <Button name="submit" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextSubmit}</Button>
                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}
export default Declaration;