import { Button, DateInput, Hint, InsetText } from "nhsuk-react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {  controllerSection, dsaContent, dsaControllerErrorMessage, dsaSummary, dsaSummaryErrorMessage, fileTypeSize, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { IApplication, IDSAControllerErrorMessage, IDSASummary, IDSASummaryErrorMessage } from "../../../../state/models/app.interface";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { getCookie, submitSpinner, validateDateValue, calculateExpiryDate, submitDSASectionStatus, fetchInterceptor, calculateExampleDate } from "../../../../Helper/Utility";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitScreenDSADetails_URL } from "../../../../config/api-endpoints.config";
import { getDSASummaryDetails, setDSASummaryDetailsData } from "../../../../state/slice/DSASummary/DSASummary.slice";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";

const ProposedDSADate: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaSummaryDetailsData: IDSASummary = useAppSelector(getDSASummaryDetails);
    const initialState: IDSASummary = dsaSummaryDetailsData || {};
    const [dsaSummaryData, setDsaSummaryData] = useState(initialState);
    const saveDSASummaryDataInStore = (stateData?: IDSASummary) => {
        dispatch(setDSASummaryDetailsData({ ...dsaSummaryData, ...stateData }));
    }

    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};

    const [startDateError, setStartDateError] = useState("");
    const [diDayError, setDiDayError] = useState(false);
    const [diMonthError, setDiMonthError] = useState(false);
    const [diYearError, setDiYearError] = useState(false);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, submitTaskDuration);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const validateFields = () => {
        let isValid: boolean = true;
        let expiryDate: boolean = true;
        let startDate: boolean = true;
        let customErrorMessage: IDSAControllerErrorMessage|IDSASummaryErrorMessage;
        if (!dsaSummaryData?.dsaDay && !dsaSummaryData?.dsaMonth && !dsaSummaryData?.dsaYear) {
            customErrorMessage = { customDateMonthYearMissingDateExpired_ErrorMessage: dsaControllerErrorMessage.validDatePublished_ErrorMessage };
        }
        else {
            customErrorMessage = { customDateMonthYearMissingDateExpired_ErrorMessage: dsaSummaryErrorMessage.enterProposedStartDate_ErrorMessage };
        }
        let dateValueError = validateDateValue(Number(dsaSummaryData?.dsaDay), Number(dsaSummaryData?.dsaMonth), Number(dsaSummaryData?.dsaYear),expiryDate, false, false,customErrorMessage, startDate);
        if (dateValueError[0] !== "") {
            setStartDateError(dateValueError[0]);
            let fields = dateValueError[1].split(",");
            for (let i = 0; i < fields.length; i++) {
                if (fields[i] === dsaContent.dateInputDayField) {
                    setDiDayError(true);
                    isValid = false;
                }
                if (fields[i] === dsaContent.dateInputMonthField) {
                    setDiMonthError(true);
                    isValid = false;
                }
                if (fields[i] === dsaContent.dateInputYearField) {
                    setDiYearError(true);
                    isValid = false;
                }
            }
        }
        return isValid;
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IDSASummary = {};
        updatedState = { ...dsaSummaryData, [e.target.name]: e.target.value }
        resetError(e);
        setDsaSummaryData(updatedState);
        saveDSASummaryDataInStore(updatedState);
    }
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ([dsaSummary.dsaDay, dsaSummary.dsaMonth, dsaSummary.dsaYear].includes(e.target.name)) {
            setDiDayError(false);
            setDiMonthError(false);
            setDiYearError(false);
            setStartDateError("");
        }
    }

    const submitTaskDuration = (e: React.MouseEvent<HTMLButtonElement>) => {
        const startDate = dsaSummaryData?.dsaDay + "/" + dsaSummaryData?.dsaMonth + "/" + dsaSummaryData?.dsaYear;
        let endDate = calculateExpiryDate(startDate, dsaSummaryData.duration!);
        if (String(dsaSummaryData.duration) === '0')
        {
            endDate = dsaSummaryData.dsaEndDate!;
        }
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "Title": dsaSummaryData.title,
            "ApplicationReasonSelected": dsaSummaryData.applicationReasonSelected,
            "DSAStartDate": startDate,
            "DSAEndDate": endDate
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitScreenDSADetails_URL, requestOptions)
            .then(() => {
                submitDSASectionStatus(dsaApplicationData?.id!, controllerSection.ProjectTitleReason, taskListSectionStatus.completed)
                    .then((data: Response) => {
                            if (data) {
                                let updatedState: IDSASummary = {
                                    ...dsaSummaryData,
                                    aboutDSASummarySectionStatus: taskListSectionStatus.completed
                                }
                                setDsaSummaryData(updatedState);
                                saveDSASummaryDataInStore(updatedState);
                                navigate(routeName.dsaTaskList);
                            }
                        });
               
            })
            .catch(() => navigate(routeName.serviceError));
    }
    

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                Data request reason and project name
                            </span>
                            {startDateError !== "" && <ErrorDetails errorDescription={startDateError} errorHref="#dsaStartDate"></ErrorDetails>}
                            <h1>Proposed agreement's start date </h1>
                            <InsetText>
                                <p>
                                    The date you enter as the proposed agreement's start date may not be the date that your agreement starts. The confirmed start date will be determined by how long it takes to process your request for data. 
                                </p>
                            </InsetText>
                            <div className="nhsuk-u-padding-bottom-2"></div>
                                <DateInput id="dsaStartDate" name="DSAStartDate" error={startDateError} >
                                <Hint><p>Your proposed agreement's start date must be in the future, but by no more than one year.</p>
                                <p>For example, {calculateExampleDate(dsaContent.exampleYearForStartDate)}</p></Hint>                                
                                <DateInput.Day id={dsaSummary.dsaDay} name={dsaSummary.dsaDay} onChange={handleChange} value={dsaSummaryData.dsaDay} error={diDayError} />
                                <DateInput.Month id={dsaSummary.dsaMonth} name={dsaSummary.dsaMonth} onChange={handleChange} value={dsaSummaryData.dsaMonth} error={diMonthError} />
                                <DateInput.Year id={dsaSummary.dsaYear} name={dsaSummary.dsaYear} onChange={handleChange} value={dsaSummaryData.dsaYear} error={diYearError} />
                                </DateInput>
                            <div className="nhsuk-u-padding-bottom-7"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default ProposedDSADate;
