import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialStateOfFileUpload } from '../../../config/page.config'
import type { RootState } from '../../app/store'
import { IController, IFileUpload, IAutoComplete, ISecurityAssurance } from '../../models/app.interface'


const initialStateOfControllerOrg: IAutoComplete = {
    id: "",
    name: ""
}

const initialStateofSecurityAssurance: ISecurityAssurance = {
    SAType: "",
    SAId: "",
    ISOVersion: "",
    ISODateComplete: "",
    ISONotes: "",
    SLSPNotes: "",
    SLSPDateComplete: "",
    DSPT_ODS_Code: "",
    DSPT_Latest_Status: "",
    DSPT_Date_Published: "",
    DSPT_Comments:"",
    SectionType: "",
    DSPTDay: "",
    DSPTMonth: "",
    DSPTYear: "",
    ISODay: "",
    ISOMonth: "",
    ISOYear: "",
    SLSPDay: "",
    SLSPMonth: "",
    SLSPYear: "",
    SAIndex: 0
}
const initialState: IController = {
    isJointController: false,
    isSoleController: false,
    isCompleted: false,
    controllerId: "",
    isDirtyController: false,
    fileUpload: [initialStateOfFileUpload],
    fileUploadActive: 0,
    aboutControllerSectionStatus: "",
    dsaApplicationId: "",
    contractNumber: "",
    alternativeOrganisationName: "",
    alternativeStreet1: "",
    alternativeStreet2: "",
    alternativeStreet3: "",
    alternativeCity: "",
    alternativeCounty: "",
    alternativePostCode: "",
    alternativeCountry: "",
    controllerdataOrgGuidId: "",
    dpaRegistrationNumber: "",
    dpaOrganisationName: "",
    dpaRegistrationExpiryDate: "",
    dpDay: "",
    dpMonth: "",
    dpYear: "",
    saList: initialStateofSecurityAssurance,
    temporaryFileList: [initialStateOfFileUpload],
}


export const ControllerSlice = createSlice({
    name: 'Controller',
    initialState,
    reducers: {
        setControllerDetailsData: (state: IController, action: PayloadAction<IController>) => {
            state = Object.assign(state, action.payload);
        },
        resetControllerDetailsData: (state: IController, action: PayloadAction<IController>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setControllerDetailsData, resetControllerDetailsData } = ControllerSlice.actions;
export const getControllerDetails = (state: RootState) => state.controllerDetails;
export default ControllerSlice.reducer;