import { Button, Details, InsetText, ErrorSummary, Fieldset, Textarea, Select, Label } from "nhsuk-react-components";
import React, { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { controllerSection, documentType, dsaContent, dsaControllerErrorMessage, EthicsApprovalInPlaceTypeOptions, evidenceErrorMessage, evidenceTypeOptions, fileErrorMessage, fileTypeSize, legalBasesIdentifiableDataOptions, legalBasisText, legalBasisValue, participantErrorMessage, routeName, taskListSectionStatus } from "../../config/page.config";
import { submitSpinner, validateFile, handleUploadFile, setInputFileCustom, fetchInterceptor, getCookie, getB64toBlobData, mappedEvidence, getEvidenceFileDetails, isValid } from "../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../state/app/hooks";
import { AppDispatch } from "../../state/app/store";
import { IEvidenceList, IFileUpload, ILegalBasesCover, IParticipant,  EvidenceTypeAttributes, IEvidence, IApplication, IParticipantLegalBasis, IEvidenceTypeOptions, IEvidenceFileSummary, IApiResEvidenceFileSummary } from "../../state/models/app.interface";

import { getDataSetDetails } from "../../state/slice/DataSet/DataSet.slice";
import { getEvidenceList, setEvidenceListData } from "../../state/slice/EvidenceList/EvidenceList.slice";
import { getParticipantDetails, setParticipantDetailsData } from "../../state/slice/Participant/Participant.slice";
import ControllerHeader from "./ControllerHeader";
import GoBack from "./GoBack";
import FileUploadControl1 from "../FileUploadControl/FileUploadControl1";
import { getEvidenceDetails, setEvidenceDetailsData } from "../../state/slice/Evidence/Evidence.slice";
import { GetDSAFileById_URL, SubmitScreenEvidenceCreate_URL } from "../../config/api-endpoints.config";
import { getDSADetails, setDSAApplicationDetailsData } from "../../state/slice/DSA/DSA.slice";
import Loader from "../Loader/Loader";

const UploadConsent: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    let [fileLists, setFileLists] = useState<IFileUpload[]>([]);
    let [evidenceSummaryList, setEvidenceSummaryList] = useState<IEvidenceFileSummary[]>([]);
    let [fileListsOnChange, setFileListsOnChange] = useState<File[]>([]);
    const [selectedFile, setSelectedFile] = useState<File[] | []>([]);
    const [isSelected, setIsSelected] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [fileChosen, setfileChosen] = useState(false);
    const [validFileError, setValidFileError] = useState("");
    const [isFileUploadedError, setIsFileUploadedError] = useState("");
    const [isFileUploadedErrorLink, setIsFileUploadedErrorLink] = useState("");
    const [validateChooseFile, setValidateChooseFile] = useState(false);
    const participantDetailsData: IParticipant = useAppSelector(getParticipantDetails);
    const initialState: IParticipant = participantDetailsData || {};
    const [participantData, setParticipantData] = useState(initialState);
    const [isUploadNHS, setIsUploadNHS] = useState(false);
    const evidenceList: IEvidenceList = useAppSelector(getEvidenceList);
    const evidenceinitialState: IEvidenceList = evidenceList || {};
    const [evidenceListDetails, setEvidenceListDetails] = useState(evidenceinitialState);
    const initialStateEvidence: IEvidence = useAppSelector(getEvidenceDetails) || {};
    const [evidenceData, setEvidenceData] = useState(initialStateEvidence)

    const saveEvidenceDataInStore = (stateData?: IEvidence) => {
        dispatch(setEvidenceDetailsData({ ...evidenceData, ...stateData }));
    }
    const { isEthicsApproval } = useAppSelector(getEvidenceDetails);
    const [evidenceTypeErrorMessage, setEvidenceTypeErrorMessage] = useState("");
    const { fileUploadActive = 0 }: any = isEthicsApproval ? initialStateEvidence : participantDetailsData;
    const [isFromParticipantSummaryPage] = useState(participantData?.participantcheckYourAnswerPage);
    const dataSetData = useAppSelector(getDataSetDetails) || {};
    const [isFromDataSetPage] = useState(dataSetData?.legalBasesIdentifiableData ? true : false || false);
    const [isFromParticipantPage] = useState(participantData?.isFromParticipant ? true : false || false)
    const [isFromEvidencePage] = useState(evidenceListDetails?.isFromEvidence ? true : false)
    const [uploadAnother, setUploadAnother] = useState(false);
    const [applicantCommentsErrorMessage, setApplicantCommentsErrorMessage] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showdownload, setshowdownload] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(0);
    const [showLoader, setShowLoader] = useState(true);
    const [selectedFileName, setSelectedFileName] = useState("");
    let showfileLists: IFileUpload[] = ([]);

    const activeObject: IFileUpload[] = isEthicsApproval ? (evidenceData.fileUploadEthics! || []) : isFromEvidencePage ? (evidenceListDetails.fileUpload! || []) : (participantData.fileUploadConsent! || []);
    const [applicantComments, setApplicantComments] = useState(activeObject![fileUploadActive]?.ApplicantComments! !== "" ? activeObject![fileUploadActive]?.ApplicantComments! : "");
    const [evidenceType, setEvidenceType] = useState(activeObject![fileUploadActive]?.SelectedEvidenceUpload! !== 0 ? activeObject![fileUploadActive]?.SelectedEvidenceUpload! : 0);
    const [approvalEvidenceId] = useState(activeObject![fileUploadActive]?.ApprovalEvidenceID! !== '' ? activeObject![fileUploadActive]?.ApprovalEvidenceID! : '')
    /* To dispatch the setParticipantDetailsData Redux action */
    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantData, ...stateData }));
    }
    const saveEvidenceListDataInStore = (stateData?: IEvidenceList) => {
        dispatch(setEvidenceListData({ ...evidenceListDetails, ...stateData }));
    }
    let consentLegalBasis = participantData?.dataSetLegalBasisList?.filter(x => x.value === legalBasisValue.consentValue)[0];
    let nhsLegalBasis = participantData?.dataSetLegalBasisList?.filter(x => x.value === legalBasisValue.nhsHealthValue)[0];
    const handelUploadedFiles = (files: File[]) => {
        const uploaded: File[] = [];
        files.forEach((file: File) => {
            uploaded.push(file);
        })
        setSelectedFile(uploaded)
    }


    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === dsaContent.file) {
            let updatedState: IParticipant | IEvidence = {};
            resetError(e);
            const chosenFiles = Array.prototype.slice.call(e.target.files)
            setValidateChooseFile(true);
            if (validateFile(e, fileTypeSize, setValidFileError, setIsFileUploadedError, fileErrorMessage, setIsSelected, false, selectedFileName!, evidenceSummaryList)) {
                handelUploadedFiles(chosenFiles);
                setfileChosen(true);
                setValidFileError("");
                setValidateChooseFile(false);
                if (isEthicsApproval) {
                    updatedState = { ...evidenceData, [e.target.name]: e.target.value }
                    setEvidenceData(updatedState);
                    saveEvidenceDataInStore(updatedState);
                    handleUpload(e, chosenFiles);

                }
                else {
                    updatedState = { ...participantData, [e.target.name]: e.target.value }
                    setParticipantData(updatedState);
                    saveDataInStore(updatedState);
                    handleUpload(e, chosenFiles);
                }
            }
            else {
                setSelectedFile([]);
                setIsSelected(false);
                e.target.value = '';
            }
        }
        else {
            if (isEthicsApproval) {
                let updatedState: IEvidence = {};
                setEvidenceData(updatedState);
                saveEvidenceDataInStore(updatedState);
                resetError(e);

            }
            else {
                let updatedState: IParticipant = {};
                setParticipantData(updatedState);
                saveDataInStore(updatedState);
                resetError(e);
            }
        }
    };

    const files = selectedFile ? Array.from(selectedFile) : [];

    const validateUploadedFile = (filesToValidate?: File[]) => {
        if ((uploadedFileOnChange?.length + filesToValidate!.length) > 6) {
            setValidFileError(`${fileErrorMessage.fileLength_ErrorMessage}`);
            setSelectedFile([]);
            setIsSelected(false);
            return false;
        }
        else if (filesToValidate?.length === 0 && activeObject![fileUploadActive!]?.FileName === undefined) {
            setValidFileError(`${fileErrorMessage.fileRequiredToUpload_ErrorMessage}`);
            setSelectedFile([]);
            setIsSelected(false);
            return false;
        }
        return true;
    }

    const updateEvidenceFile = () => {
        let updatedStateEvidence: IEvidenceList = {};
        updatedStateEvidence = {
            ...evidenceListDetails,
            fileUpload: fileLists
        }
        setEvidenceListDetails(updatedStateEvidence);
        saveEvidenceListDataInStore(updatedStateEvidence);
    }

    /* To upload the file */
    const handleUpload = (e: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<HTMLInputElement>, fileList?: File[]) => {
        fileList = fileList ? fileList : files;
        const IsValid: boolean = validateUploadedFile(fileList);
        if (IsValid) {
            const EvidenceTypeValue = isEthicsApproval ? evidenceTypeOptions.EthicsReview.value : isFromEvidencePage ? Number(evidenceListDetails.selectedType) : Number(evidenceType);
            handleUploadFile(e, fileLists!, fileListsOnChange!, setIsUploaded, selectedFile!, fileList!, setFileLists!, setFileListsOnChange!, setIsSelected, setSelectedFile, resetError, false, false, setUploadAnother, evidenceSummaryList!, setEvidenceSummaryList!, setSelectedFileName, selectedFileId, applicantComments, fileUploadActive, EvidenceTypeValue, approvalEvidenceId);
        }
        setSelectedFileId(0);
    }
    let finallyUploadedFiles: IFileUpload[] = fileLists ? Array.from(fileLists) : activeObject!;       // submit
    let uploadedFileOnChange = fileListsOnChange ? Array.from(fileListsOnChange) : [];

    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const valueLength = e.target.value.length;
        if (valueLength >= 1) {
            if (e.target.id === dsaContent.file) {
                setIsFileUploadedError("");
                setValidFileError("");
                setIsFileUploadedErrorLink("");
            }
        }
        if (e.target.name === dsaContent.upload) {
            setIsFileUploadedError("");
            setIsFileUploadedErrorLink("");
        }
        if (e.target.id === dsaContent.applicantComments) {
            setApplicantCommentsErrorMessage("");
        }
        if (e.target.id === dsaContent.selectedEvidenceUpload && e.target.value !== "0") {
            setEvidenceTypeErrorMessage("");
        }
    }
    /* To Validate the each field's data */

    const ValidateFields = (e: React.MouseEvent<HTMLButtonElement>) => {
        let isValid: boolean = true;
        if ((isFromDataSetPage || isFromParticipantPage) && (activeObject![fileUploadActive]?.SelectedEvidenceUpload === undefined || activeObject![fileUploadActive]?.SelectedEvidenceUpload === 0)) {
            setEvidenceTypeErrorMessage(`${evidenceErrorMessage.evidenceType_ErrorMessage}`);
            isValid = false;
        }
        if (!(activeObject![fileUploadActive]?.ApplicantComments!)) {
            setApplicantCommentsErrorMessage(`${dsaControllerErrorMessage.applicantComments_ErrorMessage}`);
            isValid = false;
        }
        if ((finallyUploadedFiles.length <= 0 && (e.target as HTMLInputElement).name !== dsaContent.upload) || (activeObject[fileUploadActive]?.FileName! === "" || activeObject[fileUploadActive]?.FileName! === undefined)) {
            if (isFromParticipantSummaryPage || isFromParticipantPage) {
                setIsFileUploadedErrorLink(`${fileErrorMessage.fileLink_ErrorMessage}`);
                setIsFileUploadedError(`${fileErrorMessage.fileUploded_ErrorMessage}`);
            }
            else {
                setIsFileUploadedError(`${evidenceErrorMessage.evidenceFile_ErrorMessage}`);
                setIsFileUploadedErrorLink(`${evidenceErrorMessage.evidenceFile_ErrorMessage}`);
            }
            isValid = false;
        }

        if (!fileChosen) {
            if (isFromParticipantSummaryPage || isFromParticipantPage) {
                setIsFileUploadedError(`${fileErrorMessage.fileUploded_ErrorMessage}`);
                setIsFileUploadedErrorLink(`${fileErrorMessage.fileLink_ErrorMessage}`);
            }
            else {
                setIsFileUploadedError(`${evidenceErrorMessage.evidenceFile_ErrorMessage}`);
                setIsFileUploadedErrorLink(`${evidenceErrorMessage.evidenceFile_ErrorMessage}`);
            }
            isValid = false;
        }
        if (validateChooseFile) {
            setIsFileUploadedError("");
        }
        if (validFileError) {
            isValid = false;
        }

        return isValid;
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const IsValid: boolean = ValidateFields(e);
        if (IsValid) {
            submitSpinner(e, submitDocument);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }


    const submitDocument = () => {
        if (isEthicsApproval) {
            let updatedState: IEvidence = {};
            updatedState = {
                ...evidenceData,
                evidenceFileSummary: evidenceSummaryList
            }
            setEvidenceData(updatedState);
            saveEvidenceDataInStore(updatedState);
            navigate(routeName.addAnotherEthicsReview);
        }
        else if (isFromEvidencePage) {
            submitEvidence();
        }
        else {
            let updatedState: IParticipant = {};
            updatedState = {
                ...participantData,
                evidenceFileSummary: evidenceSummaryList
            }
            setParticipantData(updatedState);
            saveDataInStore(updatedState);
            navigate(routeName.addAnotherFileRecord);
        }
    }
    const submitEvidence = () => {
        const recentlyUploaded = evidenceListDetails.fileUpload!.filter(file => file?.RecentlyModified! === true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "Id": '',
            "SelectedEvidenceUpload": evidenceListDetails.selectedType,
            "FileList": recentlyUploaded,
            "SectionId": isFromEvidencePage ?controllerSection.ListOfUploadedDocuments : 0,
            "SectionStatus": dsaApplicationData.aboutEvidenceSectionStatus,
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenEvidenceCreate_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setParticipantDetails(data?.Id)
            })
            .catch(error => console.log('Error Reading data', error));
    }
    const setParticipantDetails = (recentlyAddedConsentId: string) => {
        if (isFromEvidencePage) {
            let updatedStateEvidence: IEvidenceList = {};
            updatedStateEvidence = {
                ...evidenceListDetails,
                fileUpload: finallyUploadedFiles,
                evidenceFileSummary: evidenceSummaryList
            }
            setEvidenceListDetails(updatedStateEvidence);
            saveEvidenceListDataInStore(updatedStateEvidence);
            let sectionStatus: IApplication = {
                ...dsaApplicationData, aboutEvidenceSectionStatus: taskListSectionStatus.inProgress
            }
            setDsaApplicationData(sectionStatus);
            saveApplicationDataInStore(sectionStatus);
            navigate(routeName.addAnotherApprovalEvidence);
        }
    }
    const getLegalBasisforEvidenceUpload = () => {
        participantData.dataSetLegalBasisList?.forEach((LegalBasis: ILegalBasesCover) => {
            if (LegalBasis.checked && LegalBasis?.text?.toLocaleLowerCase() != legalBasisText.other && LegalBasis?.text?.toLocaleLowerCase() != legalBasisText.consentReasonableExpectation) {
                setIsUploadNHS(true)
            }
        })
    }

    const setFileUrl = (e: any) => {
        e.preventDefault();
        setSelectedIndex(e.currentTarget.id);
        setshowdownload(true);
        e.currentTarget.className += "nhsuk-disableLink";
        downloadfile(e, e.currentTarget.id);
    }

    const downloadfile = (e: any, fileId: any) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "FileId": fileId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: parameters
        };

        fetchInterceptor(GetDSAFileById_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data && data !== undefined) {
                    const b64Data: any = data.Data._buffer;
                    const blob: any = getB64toBlobData(b64Data, data.contentType, data.FileName);
                    const blobUrl: any = URL.createObjectURL(blob);
                    download(blobUrl, data.FileName);
                    setshowdownload(false);
                    e.target.removeAttribute('class');
                }

            })
            .catch(() => navigate('/serviceerror'));

    }

    const download = (path: any, filename: any) => {
        const anchor = document.createElement('a');
        anchor.href = path;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{participantErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#SelectedEvidenceUpload">
                            {evidenceTypeErrorMessage}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#applicantComments">
                            {applicantCommentsErrorMessage}
                        </ErrorSummary.Item>

                        <ErrorSummary.Item href="#fileupload">
                            {validFileError ? validFileError : isFileUploadedErrorLink}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    const setHeader = (type: string) => {
        switch (type) {
            case legalBasesIdentifiableDataOptions.Consent_ReasonableExpectation: return (
                <h1>Consent (Reasonable Expectation)</h1>
            );
            case legalBasesIdentifiableDataOptions.Section251NHSAct2006: return (
                <h1>Section 251 of the NHS Act 2006</h1>
            );
            case legalBasesIdentifiableDataOptions.Mixtureofconfidentialdataflow_swithconsentandflow_swithsupportundersection251NHSAct2006: return (
                <h1>Mixture of confidential data flow(s) with consent and flow(s) with support under Section 251 of the NHS Act 2006</h1>
            );
            case legalBasesIdentifiableDataOptions.Mixtureofconfidentialdataflow_swithconsentandnonconfidentialdataflow_s: return (
                <h1>Mixture of confidential data flow(s) with consent and non-confidential data flow(s)</h1>
            );
            case legalBasesIdentifiableDataOptions.Mixtureofconfidentialdataflow_swithsupportundersection251NHSAct2006andnonconfidentialdataflow_s: return (
                <h1>Mixture of confidential data flow(s) with support under Section 251 of the NHS Act 2006 and non-confidential flow(s)</h1>
            );
            case legalBasesIdentifiableDataOptions.Statutoryexemptiontoflowconfidentialdatawithoutconsent: return (
                <h1>Statutory exemption to flow confidential data without consent</h1>
            );
        }
    }
    const setParticipantHeader = (type: IParticipantLegalBasis[]) => {
       
        if (consentLegalBasis?.value === legalBasisValue.consentValue && consentLegalBasis.checked && nhsLegalBasis?.value === legalBasisValue.nhsHealthValue && !nhsLegalBasis.checked) { 
            return (
                <h1>Consent (Reasonable Expectation)</h1>);
        } else if (nhsLegalBasis?.value === legalBasisValue.nhsHealthValue && nhsLegalBasis.checked && consentLegalBasis?.value === legalBasisValue.consentValue && !consentLegalBasis.checked ) {  
            return (
                <h1>Section 251 of the NHS Act 2006</h1>
            );
        }
        else if (nhsLegalBasis?.value === legalBasisValue.nhsHealthValue && nhsLegalBasis.checked && consentLegalBasis?.value === legalBasisValue.consentValue && consentLegalBasis.checked) {
            return (
                <h1>Mixture of Consent (Reasonable Expectation) and Section 251 NHS Act 2006.</h1>
            );
        }

    }
    const changeUpLoadedDocData = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, fileId: number, fileName: string, fileType: string) => {
        e.preventDefault();
        setUploadAnother(true);

        let fileElement = document.getElementById('file');
        if (fileElement != null) {
            setInputFileCustom(fileElement, fileName, fileType);
        }
        else {
            setTimeout(function () { setInputFileCustom(document.getElementById('file'), fileName, fileType) }, 500);
        }
        setSelectedFileId(fileId);
    }

    const getNameForOption = (evidenceTypeValue: String) => {
        const filteredKey = Object.keys(evidenceTypeOptions).find((key) => Number(evidenceTypeValue) === evidenceTypeOptions[key as keyof IEvidenceTypeOptions].value);
        return filteredKey ? evidenceTypeOptions[filteredKey as keyof IEvidenceTypeOptions].name : "";
    }
    const getTitle = () => {
        if (isFromParticipantPage || isFromParticipantSummaryPage) {
            return "About the Participants";
        } else if (isFromDataSetPage) {
            return "About the data";
        } else if (isEthicsApproval) {
            return "Ethics approval";
        } else if (isFromEvidencePage) {
            return "List of uploaded documents";
        }
    }

    const showFileList = () => {
        let filterFile = null;
        if (isFromParticipantPage || isFromParticipantSummaryPage || isFromDataSetPage) {
            if (activeObject !== null && activeObject != undefined) {
                let filterFile = participantData?.fileUploadConsent?.length && participantData?.fileUploadConsent?.filter(file =>
                    (file?.SelectedEvidenceUpload! === documentType.Consent_Form) ||
                    (file?.SelectedEvidenceUpload! === documentType.Patient_Information) ||
                    (file?.SelectedEvidenceUpload! === documentType.Patient_Gp_Letters) ||
                    (file?.SelectedEvidenceUpload! === documentType.Section_251_Support) ||
                    (file?.SelectedEvidenceUpload! === documentType.IRAS_Form) ||
                    (file?.SelectedEvidenceUpload! === documentType.Other))
                filterFile = participantData?.fileUploadConsent;
                showfileLists = Object.assign([], filterFile);

            }
        }
        else if (isEthicsApproval) {
            if (evidenceData.fileUploadEthics !== null && evidenceData.fileUploadEthics != undefined) {
                filterFile = evidenceData.fileUploadEthics;
                showfileLists = Object.assign([], filterFile);
            }
        }

        else {
            if (evidenceListDetails.fileUpload !== null && evidenceListDetails.fileUpload != undefined) {
                filterFile = evidenceListDetails.fileUpload;
                showfileLists = Object.assign([], filterFile);
            }
        }
        if (showfileLists[0]?.FileName !== "") {
            setFileLists(showfileLists);
            finallyUploadedFiles = showfileLists;
            if (finallyUploadedFiles.length >= 1) {
                setIsSelected(true);
                setfileChosen(true);
            }
        }

    }
    const updateParticipantState = (FileListToUpdate: IFileUpload[]) => {
        let updatedState: IParticipant = {};
        updatedState = {
            ...participantData,
            fileUploadConsent: FileListToUpdate
        }
        setParticipantData(updatedState);
        saveDataInStore(updatedState);
    }
    const updateEthicsReviewState = (FileListToUpdate: IFileUpload[]) => {
        let updatedState: IEvidence = {};
        updatedState = {
            ...evidenceData,
            fileUploadEthics: FileListToUpdate
        }
        setEvidenceData(updatedState);
        saveEvidenceDataInStore(updatedState);
    }
    const updateEvidenceState = (FileListToUpdate: IFileUpload[]) => {
        let updatedStateEvidence: IEvidenceList = {};
        updatedStateEvidence = {
            ...evidenceListDetails,
            fileUpload: FileListToUpdate
        }
        setEvidenceListDetails(updatedStateEvidence);
        saveEvidenceListDataInStore(updatedStateEvidence);
    }
    const handleChangeCommentsEvidenceType = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) => {
        if (e.target.name === dsaContent.selectedEvidenceUpload) {
            setEvidenceType(Number(e.target.value))
        }
        else { setApplicantComments(e.target.value); }

        if (isFromParticipantSummaryPage || isFromParticipantPage || isFromDataSetPage) {
            let updatedFileData: IFileUpload[] = getUpdatedFileData(e);
            updateParticipantState(updatedFileData);
            finallyUploadedFiles = updatedFileData;
            setFileLists(finallyUploadedFiles);
        }
        else if (isEthicsApproval) {
            let updatedFileData: IFileUpload[] = getUpdatedFileData(e)
            updateEthicsReviewState(updatedFileData);
            finallyUploadedFiles = updatedFileData;
            setFileLists(finallyUploadedFiles);
        }
        else {
            let updatedFileData: IFileUpload[] = getUpdatedFileData(e)
            updateEvidenceState(updatedFileData);
            finallyUploadedFiles = updatedFileData;
            setFileLists(finallyUploadedFiles);
        }
        resetError(e);
    }
    const getUpdatedFileData = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) => {
        let targetValues = e.target.name === dsaContent.selectedEvidenceUpload ? Number(e.target.value) : e.target.value;
        let updatedFileData: IFileUpload[] = [];
        if (activeObject?.length === 0 ||
            (participantData.fileUploadNHS?.length !== 0 && activeObject?.length === fileUploadActive && !activeObject![fileUploadActive]?.ApplicantComments)) {
            //new array item
            updatedFileData = [...activeObject!, ...[{ ...activeObject![fileUploadActive], ...{ [e.target.name]: targetValues, RecentlyModified: true } }]];
        } else {
            updatedFileData = activeObject!.map((item, index) => {
                if (fileUploadActive === index) {
                    return { ...item, ...{ [e.target.name]: targetValues, RecentlyModified: true } }
                } else return item;
            })
        }
        return updatedFileData;
    }

    const getEvidenceFileSummary = () => {
        let applicationId = isValid(dsaApplicationData.id) ? dsaApplicationData.id : "";
        if (isValid(applicationId)) {
            getEvidenceFileDetails(applicationId!).then((data) => {
                setEvidenceFileSummary(data);
            });
        }
    }

    const setEvidenceFileSummary = (response: IApiResEvidenceFileSummary[]) => {
        if (isValid(response)) {

            let evidenceFileSummary: IEvidenceFileSummary[] = [];
            response.map(({ FileId: fileId, FileName: fileName, MimeType: mimeType, Source: source }) => {
                evidenceFileSummary.push({ fileId, fileName, mimeType, source });
            });

            if (isEthicsApproval) {
                let updatedState: IEvidence = {};
                updatedState = {
                    ...evidenceData,
                    evidenceFileSummary
                }
                setEvidenceData(updatedState);
                saveEvidenceDataInStore(updatedState);
            }
            else if (isFromEvidencePage) {
                let updatedState: IEvidenceList = {};
                updatedState = {
                    ...evidenceList,
                    evidenceFileSummary
                }
                setEvidenceListData(updatedState);
                saveEvidenceListDataInStore(updatedState);
            }
            else {
                let updatedState: IParticipant = {};
                updatedState = {
                    ...participantData,
                    evidenceFileSummary
                }
                setParticipantData(updatedState);
                saveDataInStore(updatedState);
            }
        }
        setShowLoader(false);
    }
    useEffect(() => {
        showFileList();
        if ((isFromEvidencePage && !isValid(evidenceList.evidenceFileSummary))
            || (((isFromParticipantPage || isFromDataSetPage) && !isValid(participantData.evidenceFileSummary)) || isValid(participantData.evidenceFileSummary?.filter((x) => !isValid(x.fileId))))
            || (isEthicsApproval && !isValid(evidenceData.evidenceFileSummary))) {
            getEvidenceFileSummary();
        }
        else { setShowLoader(false); }
        setSelectedFileName(getLastUploadedFile());
    }, [])

    useEffect(() => {
        if (!isValid(evidenceSummaryList) && isValid(evidenceList.evidenceFileSummary)) {
            setEvidenceSummaryList(evidenceList.evidenceFileSummary!);
        }
        else if (!isValid(evidenceSummaryList) && isValid(evidenceData.evidenceFileSummary)) {
            setEvidenceSummaryList(evidenceData.evidenceFileSummary!);
        }
        else if (!isValid(evidenceSummaryList) && isValid(participantData.evidenceFileSummary)) {
            setEvidenceSummaryList(participantData.evidenceFileSummary!);
        }
    }, [evidenceList.evidenceFileSummary, evidenceData.evidenceFileSummary, participantData.evidenceFileSummary]);

    useEffect(() => {
        if (isFromEvidencePage) {
            updateEvidenceFile();
        }
    }, [fileLists])

    useEffect(() => {
        finallyUploadedFiles = fileLists ? Array.from(fileLists) : [];
        // submit
        if (isEthicsApproval) {
            let updatedState: IEvidence = {};
            updatedState = {
                ...evidenceData,
                fileUploadEthics: finallyUploadedFiles
            }
            setEvidenceData(updatedState);
            saveEvidenceDataInStore(updatedState);
        }
        else if (isFromEvidencePage) {
            let updatedState: IEvidenceList = {};
            updatedState = {
                ...evidenceListDetails,
                fileUpload: finallyUploadedFiles
            }
            setEvidenceListDetails(updatedState);
            saveEvidenceListDataInStore(updatedState);
        }
        else {
            let updatedState: IParticipant = {};
            updatedState = {
                ...participantData,
                fileUploadConsent: finallyUploadedFiles
            }
            setParticipantData(updatedState);
            saveDataInStore(updatedState);
        }
    }, [fileLists]);

    const checkParticipantLBType = (value: string)=> {
        return participantData.dataSetLegalBasisList!.find((element)=> {
            return (value === element.value && element.checked)!;
        });
    }
    const filterEvidenceType = (option: EvidenceTypeAttributes, index: number) => {
        if (isFromDataSetPage) {
            if (option?.legalBases?.filter((value) => value !== undefined && value === Number(dataSetData.legalBasesIdentifiableData)).length! > 0) {
                return <Select.Option key={index + 1} value={option.value}>{option.name}</Select.Option>;
            }
        } else if (isFromParticipantPage) {
           if ((option?.legalBasis?.filter((value) => value !== undefined && checkParticipantLBType(value)).length! > 0)) {
                return <Select.Option key={index + 1} value={option.value}>{option.name}</Select.Option>;
            }
        }
        else {
            return <Select.Option key={index + 1} value={option.value}>{option.name}</Select.Option>;
        }

    }

    const getContentofEvidencType = () => {
        return (String(evidenceListDetails?.selectedType!) === String(evidenceTypeOptions?.IRASReference?.value) || String(evidenceListDetails?.selectedType!) === String(evidenceTypeOptions?.Section251Support?.value)) ?
            <InsetText><p> Your document uploads might include a letter from the Confidentiality Advising Group (CAG) that supports the application Section 251(s251) or another CAG document that supports s251.</p></InsetText> :
            (String(evidenceListDetails?.selectedType!) === String(evidenceTypeOptions?.ConsentForm?.value) ?
                <InsetText>
                <p>You must upload a template of every version of the consent form that has been used and make clear which Patient Information Sheets (PIS) were used with each consent form. </p>
                <p>Document date, version number and an approval date should be included within the document that you upload.</p>
                </InsetText> : null);
    }

    const getInsertText = () => {
        if ((isFromDataSetPage && ((dataSetData.legalBasesIdentifiableData === legalBasesIdentifiableDataOptions.Consent_ReasonableExpectation) || (dataSetData.legalBasesIdentifiableData === legalBasesIdentifiableDataOptions.Mixtureofconfidentialdataflow_swithconsentandnonconfidentialdataflow_s))) || ((isFromParticipantPage || isFromParticipantSummaryPage) && (consentLegalBasis?.value === legalBasisValue.consentValue && consentLegalBasis.checked && nhsLegalBasis?.value === legalBasisValue.nhsHealthValue && !nhsLegalBasis.checked))) {
            return <><InsetText>
                <p>You must upload a template of every version of the consent form that has been used and make clear which Patient Information Sheets (PIS) were used with each consent form.</p>
                <p>Document date, version number and an approval date should be included within the document that you upload.</p>
            </InsetText></>
        }
        else if ((isFromDataSetPage && ((dataSetData.legalBasesIdentifiableData === legalBasesIdentifiableDataOptions.Section251NHSAct2006) || (dataSetData.legalBasesIdentifiableData === legalBasesIdentifiableDataOptions.Mixtureofconfidentialdataflow_swithsupportundersection251NHSAct2006andnonconfidentialdataflow_s))) || ((isFromParticipantPage || isFromParticipantSummaryPage) && (consentLegalBasis?.value === legalBasisValue.consentValue && !consentLegalBasis.checked && nhsLegalBasis?.value === legalBasisValue.nhsHealthValue && nhsLegalBasis.checked)) ) {
            return <> <InsetText>
                <p>Your document uploads might include a letter from the Confidentiality Advising Group (CAG) that supports the application Section 251 (s251) or another CAG document that supports s251.</p>
            </InsetText></>
        }
        else if ((isFromDataSetPage && (dataSetData.legalBasesIdentifiableData === legalBasesIdentifiableDataOptions.Mixtureofconfidentialdataflow_swithconsentandflow_swithsupportundersection251NHSAct2006)) || ((isFromParticipantPage || isFromParticipantSummaryPage) && (consentLegalBasis?.value === legalBasisValue.consentValue && consentLegalBasis.checked && nhsLegalBasis?.value === legalBasisValue.nhsHealthValue && nhsLegalBasis.checked)) ) {
            return <> <InsetText>
                <p>You must upload a template of every version of the consent form that has been used and make clear which Patient Information Sheets (PIS) were used with each consent form.</p>
                <p>Document date, version number and an approval date should be included within the document that you upload.</p>
                <p>Document(s) to support Section 251 (s251) might include a letter from the Confidentiality Advising Group (CAG) that supports the application s251 or another CAG document that supports s251.</p>
            </InsetText></>
        }
        else if (!isFromDataSetPage && !isEthicsApproval && (!isFromParticipantPage || !isFromParticipantSummaryPage)) {
            return <>{getContentofEvidencType()}</>
        }
        else if (!isFromDataSetPage && isEthicsApproval) {
            return <><InsetText>
                <p>You should now upload supporting document(s) to demonstrate that Ethics Approval is in place.</p>
            </InsetText></>
        }
    }

    const removeFileOnGoBack = () => {
            const updateList = [...activeObject];
        updateList.splice(fileUploadActive, 1);
        if ((isFromParticipantSummaryPage || isFromParticipantPage || isFromDataSetPage)) {
            updateParticipantState(participantData.temporaryFileList!);
            }
            else if (isEthicsApproval) {             
               updateEthicsReviewState(evidenceData.temporaryFileList!);
            }
            else {
                updateEvidenceState(updateList);
            }
    }

    const getLastUploadedFile = () => {
        let lastFileName = "";
        if (isFromEvidencePage) {
            lastFileName = isValid(evidenceList.fileUpload) && isValid(evidenceList.fileUpload![fileUploadActive]) ? evidenceList.fileUpload![fileUploadActive!]?.FileName! : "";
        }
        else if (isEthicsApproval) {
            lastFileName = isValid(evidenceData.fileUploadEthics) && isValid(evidenceData.fileUploadEthics![fileUploadActive]) ? evidenceData.fileUploadEthics![fileUploadActive!]?.FileName! : "";
        }
        else if (isFromParticipantPage || isFromDataSetPage) {
            lastFileName = isValid(participantData.fileUploadConsent) && isValid(participantData.fileUploadConsent![fileUploadActive]) ? participantData.fileUploadConsent![fileUploadActive!]?.FileName! : "";
        }
        return lastFileName;
    }
   
    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {showLoader ? 
                        <Loader loadingText={dsaContent.txtLoading}/>
                            :
                            <>
                                <div className="nhsuk-grid-column-two-thirds">
                                    <GoBack onClick={removeFileOnGoBack} condition={(isFromDataSetPage && evidenceListDetails.evidenceList![0].id === '' && !participantData.isFromAddAnotheFileRecord) ? dsaContent.gobackidentifiabledata : ((isFromParticipantPage || isFromParticipantSummaryPage) && evidenceListDetails.evidenceList![0].id === '' && !participantData.isFromAddAnotheFileRecord) ? dsaContent.gobacklegalbasis : (isEthicsApproval && String(evidenceData.ethicsApproval) === String(EthicsApprovalInPlaceTypeOptions.Yes) && !isValid(evidenceData.fileUploadEthics!)) ? dsaContent.gobackEthicsApproval : ""}></GoBack>
                                    {
                                        <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                            {getTitle()}
                                        </span>
                            }

                            {
                                (isFromParticipantPage || isFromParticipantSummaryPage) ? setParticipantHeader(participantData?.dataSetLegalBasisList!)
                                    : (isFromDataSetPage ? setHeader(dataSetData.legalBasesIdentifiableData!)
                                        : isFromEvidencePage ? <h1>{getNameForOption(evidenceListDetails?.selectedType!)}</h1> : isEthicsApproval ? (<h1>Ethics Review</h1>) : ""
                                    )
                            }
                            {applicantCommentsErrorMessage !== "" || isFileUploadedErrorLink !== '' || validFileError !== "" ? errorSummary : null}

                            {getInsertText()}
                        </div>
                        {isFromDataSetPage || isFromParticipantPage ?
                            <div className="nhsuk-grid-column-two-thirds">
                                <Label>Evidence type</Label>
                                <Select name={dsaContent.selectedEvidenceUpload} id={dsaContent.selectedEvidenceUpload} defaultValue={evidenceType} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { handleChangeCommentsEvidenceType(e) }} error={evidenceTypeErrorMessage} >
                                    <Select.Option value='0'>Please select...</Select.Option>
                                    {
                                        Object.values(mappedEvidence).map((option: EvidenceTypeAttributes, index) => (
                                            filterEvidenceType(option, index)))
                                    }
                                </Select>
                            </div> : ""}

                        <div className="nhsuk-grid-column-two-thirds" >
                            <Textarea
                                label="Provide your own description or name for the document (character limit 50)"
                                error={applicantCommentsErrorMessage}
                                name="ApplicantComments"
                                id={dsaContent.applicantComments}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => { handleChangeCommentsEvidenceType(e) }}
                                defaultValue={applicantComments}
                                maxLength={50}
                                rows={3}
                            />
                        </div>
                        <div className="nhsuk-u-padding-bottom-3"></div>
                        <div className="nhsuk-grid-column-two-thirds nhsuk-u-margin-bottom-3">
                            <Fieldset>
                                <Fieldset.Legend>Document required </Fieldset.Legend>
                            </Fieldset>
                            <Details>
                                <Details.Summary>View file guidance </Details.Summary>
                                <Details.Text>
                                    <p>A file should not exceed 3 MB and should be one of the following acceptable file formats: PDF, Word, Excel. </p>
                                </Details.Text>
                            </Details>
                        </div>
                        <FileUploadControl1
                            errorAboveList={isFileUploadedError}
                            errorBelowList={validFileError}
                            isUploaded={isUploaded}
                            finallyUploadedFiles={fileUploadActive === 0 && activeObject!.length === 0 ? undefined : activeObject![fileUploadActive!] as any}
                            handleChange={handleChange}
                            handleUpload={handleUpload}
                            changeFile={changeUpLoadedDocData}
                            setFileUrl={setFileUrl}
                            showDownload={showdownload}
                            selectedIndex={selectedIndex}
                            setSelectedFileId={setSelectedFileId}
                        />
                        <div className="nhsuk-grid-column-two-thirds">
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                                </div>
                            </>}
                    </div>
                </main>
            </div>
        </>
    )
}
export default UploadConsent;
