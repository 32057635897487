import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { IHolder, IApplication, ISection, ITaskEnableSectionMultiSelect } from '../../models/app.interface'

const initialStateOfSection: ISection = {
    sectionId: "",
    sectionStatus: "",
    sectionEnable: false
}

const initialStateOfTaskEnable: ITaskEnableSectionMultiSelect = {
    value: 0
}
const initialStateOfDSADetails: IApplication = {
    id: "",
    referenceNumber: "",
    title: "",
    status: "",
    startDate: "",
    endDate: "",
    firstSubmitDate: "",
    totalEstimatedCost:"",
    aboutControllerSectionStatus: "",
    aboutProcessorSectionStatus: "",
    aboutDataSetSectionStatus: "",
    commercialSublicensingSectionStatus: "",
    section: [initialStateOfSection],
    aboutParticipantSectionStatus: "",
    aboutFundingSectionStatus: "",
    aboutPrivacyNoticeSectionStatus: "",
    aboutEthicsApprovalSectionStatus: "",
    aboutProcessingLocationSectionStatus: "",
    aboutStorageLocationSectionStatus: "",
    aboutExpectedOutputsAndBenefitsSectionStatus: "",
    aboutObjectiveAndProcessingSectionStatus: "",
    aboutYieldedBenefitsSectionStatus:"",
    projectTitleReasons: "",
    duration: 0,
    numberOfSignatures: 0,
    numberOfSignees: 0,
    dataControllerId: "",
    aboutEvidenceSectionStatus: "",
    aboutSubmitApplicationSectionStatus: "",
    applicationChangeType: "",
    taskEnableSectionMultiSelect: [initialStateOfTaskEnable],
    applyingForData: false,
    organisationsInvolved: false,
    dataAndParticipant: false,
    returningJourneyType:"",
    isFromApplyDsa: false,
    applicationOverview: false,
    isorganisationpaying: false,
    applicationWith: 0,
    processStage: 0,
    readyToProceed2d: false,
    readyToProceed1c: false
}

const initialState: IHolder = {
    applicationDetails: [initialStateOfDSADetails],
    existingApplicationDetails: [initialStateOfDSADetails],
    activeAgreementDetails: [initialStateOfDSADetails],
    id: "",
    referenceNumber: ""
}

export const DSASlice = createSlice({
    name: 'dsa',
    initialState,
    reducers: {
        setDSADetailsData: (state: IHolder, action: PayloadAction<IHolder>) => {
            state = Object.assign(state, action.payload);
        },
        resetDSADetailsData: (state: IHolder, action: PayloadAction<IHolder>) => {
            state = { ...initialState, ...action.payload };
        },
        setDSAApplicationDetailsData: (state: IApplication, action: PayloadAction<IApplication>) => {
            state = Object.assign(state, action.payload);
        },
        resetDSAApplicationDetailsData: () => initialState
        
    }
});

export const { setDSADetailsData, resetDSADetailsData, setDSAApplicationDetailsData, resetDSAApplicationDetailsData } = DSASlice.actions;
export const getDSADetails = (state: RootState) => state.dsaDetails;
export default DSASlice.reducer;