import { Button, Label, ReviewDate } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader/Loader";
import { GetPageConfigPublic_URL } from "../../config/api-endpoints.config";
import { dsaContent, pageTitles, pageConfigList, initialStatePageConfig } from "../../config/page.config";
import { closeWindow, updatePageTitle } from "../../Helper/Utility";
import useFetch from "../../Hooks/useFetch";
import { IPageConfig } from "../../state/models/app.interface";

const AboutUs: React.FC = () => {
    
    const [pageData, setPageData] = useState<IPageConfig>(initialStatePageConfig);

    const parameters = {
        "Id": pageConfigList.AboutUs
    }
    const { response, loading }: any = useFetch(GetPageConfigPublic_URL, "POST", {}, parameters);

    useEffect(() => {
        updatePageTitle(pageTitles.aboutUs);
    }, []);

    useEffect(() => {
        response && setPageData(response);
    }, [response]);

    return (
        <div className="nhsuk-width-container">
            <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                <div className="nhsuk-grid-row">
                    {loading ?
                        <Loader loadingText={dsaContent.txtLoading} />
                        : <div className="nhsuk-grid-column-two-thirds">
                            <h1>About NHS England's Data Access Request Service</h1>
                            <Label size="s">
                                NHS England, formerly the Health and Social Care Information Centre (HSCIC) is home to our Data Access Request Service (DARS). The Data Access Request Service can offer clinicians, researchers and commissioners the data they request, providing that its processing benefits the health and social care system.
                            </Label>
                            <Label size="s" className="nhsuk-space-bottom-double">
                                Requesting and processing data and sharing data outputs can help NHS patients to get the best care.
                            </Label>
                            <Label size="s" className="nhsuk-space-bottom-double"><a href="https://digital.nhs.uk/services/data-access-request-service-dars/data-access-request-service-dars-process" target="_blank">Request data</a>
                                <br />
                                <a href="https://digital.nhs.uk/" target="_blank">NHS England</a></Label>
                            <Button onClick={closeWindow}>{dsaContent.btnTextClose}</Button>
                            <div className="nhsuk-u-padding-bottom-1"></div>
                            <ReviewDate
                                lastReviewed={pageData.PageLastReviewed}
                                nextReview={pageData.NextReviewDue}
                            />
                        </div>}
                </div>
            </main>
        </div>
    )
}
export default AboutUs;