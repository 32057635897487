import { InsetText, Button, Textarea, Details, Label } from "nhsuk-react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitScreenObjectiveAndProcessing_URL } from "../../../../config/api-endpoints.config";
import { getCookie, submitSpinner, fetchInterceptor } from "../../../../Helper/Utility";
import { IApplication, IPurpose } from "../../../../state/models/app.interface";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { dsaContent, fileTypeSize, purposeErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getPurposeDetails, setPurposeDetailsData } from "../../../../state/slice/Purpose/Purpose.slice";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";

const ProcessingActivities: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const purposeDetailsData: IPurpose = useAppSelector(getPurposeDetails);
    const initialState: IPurpose = purposeDetailsData || {};
    const [purposeData, setPurposeData] = useState(initialState);
    const savePurposeDataInStore = (stateData?: IPurpose) => {
        dispatch(setPurposeDetailsData({ ...purposeData, ...stateData }));
    }

    const [processingActivitiesErrorMessage, setprocessingActivitiesErrorMessage] = useState("");

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, submitObjectiveAndProcessing);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const submitObjectiveAndProcessing = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "ObjectiveForProcessing": purposeData.objectiveForProcessing,
            "ProcessingActivities": purposeData.processingActivities,
            "SectionStatus": dsaApplicationData.aboutObjectiveAndProcessingSectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitScreenObjectiveAndProcessing_URL, requestOptions)
            .then(() => {
                let sectionStatus: IApplication = {
                    ...dsaApplicationData, aboutObjectiveAndProcessingSectionStatus: taskListSectionStatus.inProgress
                }
                setDsaApplicationData(sectionStatus);
                saveApplicationDataInStore(sectionStatus);
                navigate(routeName.objectiveProcessingCheckAnswer);

            })
            .catch(() => navigate(routeName.serviceError));
    }

    const validateFields = () => {
        let isValid: boolean = true;
        if (!purposeData.processingActivities || purposeData.processingActivities.trim() === "") {
            setprocessingActivitiesErrorMessage(`${purposeErrorMessage.enterProcessingActivities_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IPurpose = {};
        updatedState = {
            ...purposeData,
            processingActivities: e.target.value
        }
        savePurposeDataInStore(updatedState);
        setPurposeData(updatedState);
        resetError(e);
    }

    const resetError = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== "") {
            setprocessingActivitiesErrorMessage("");
        }
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    Objective(s) and processing activities</span>
                                    Processing activities
                                </h1>
                                {processingActivitiesErrorMessage !== "" && <ErrorDetails errorDescription={processingActivitiesErrorMessage} errorHref="#processingActivities"></ErrorDetails>}
                                <p>
                                    Use the 'Guidance' to help you provide information in the text box.
                                </p>
                                <div className="nhsuk-u-padding-bottom-3"></div>
                                <Details>
                                    <Details.Summary>Guidance </Details.Summary>
                                    <Details.Text>
                                        <p>Describe any data flowing into and out of NHS England including details of special categories of personal data such as health data.</p>
                                        <p>Describe any subsequent flows of data. This might include your organisation(s) extracting a subset(s) of data and making this data available to or securely transferring to a department(s) within the same organisation.</p>
                                        <p>Explain:</p>
                                        <ul>
                                            <li>how the data is being accessed by anyone accessing it. For example, data dissemination or remote access</li>
                                            <li>which organisation(s) will store the data</li>
                                            <li>which organisation(s) is processing which data at each stage (providing a data flow diagram where possible) and confirm if the data will be backed up at another location</li>
                                            <li>how the data is being processed at each stage and what is being done with the data to achieve the stated purpose </li>
                                            <li>where the data will be accessed, for example, onsite at the premises of the organisation(s) only</li>
                                            <li>how the data will be accessed, for example, using off-site back-up services or via the Cloud.</li>
                                        </ul>
                                        <p>Explain who will access the data. For example, it may be that: </p>
                                        <ul>
                                            <li>access is restricted to employees or agents of an organisation(s), unit(s) or department(s) who have authorisation from a specific person, such as the Principal Investigator</li>
                                            <li>access to confidential patient identifiable data is restricted to employees or agents of the named organisation(s)</li>
                                            <li>employees or of the named organisation(s) are permitted to access pseudonymised data only</li>
                                            <li>employees or agents of the named organisation(s) are only permitted to access anonymised data, including information derived from NHS England data.</li>
                                            <li>data will be accessed by an individual(s) that hold an honorary contract within a named organisation(s)</li>
                                            <li>core data sets will only be accessed by an individual(s) within an organisation(s) or unit(s) of an organisation(s)</li>
                                        </ul>
                                        <p>Provide details of any data linkages, or confirmation that data is not linked. You should include whether data is combined with publicly available data, whether any linked data will be linked at person record level, and whether any aggregated information derived from the data will be combined with aggregated data from other sources. </p>
                                        <p>Confirm what steps will be taken to protect Participant confidentiality. For example, it might be that:</p>
                                        <ul>
                                            <li>identifying details will be stored in a separate database to the linked data set used for analysis</li>
                                            <li>analysis will use the pseudonymised data set</li>
                                            <li>there will be no requirement or attempt to reidentify individuals when using data or a pseudonymised data set</li>
                                            <li>employees or agents of a named organisation(s) are only permitted to access anonymised data, including information derived from NHS England's data</li>
                                            <li>data will be accessed by named individual(s) within the unit(s) or department</li>
                                        </ul>
                                        <p>Confirm that data processing is only carried out by substantive employees of Controllers and Processors who have been appropriately trained in data protection and confidentiality.</p>
                                        <p>Where data processing is carried out by someone who is not a substantive employee of a Controller or Processor, provide detail of what contractual arrangements are in place to protect the data, such as the Processor receiving training in data protection and confidentiality.</p>
                                    </Details.Text>
                                </Details>
                                <Label>Provide information on your processing activities in the text box below.</Label>
                                <Textarea
                                    hint="You can expand this text box by dragging the bottom right corner down."
                                    error={processingActivitiesErrorMessage}
                                    name="processingActivities"
                                    id="processingActivities"
                                    onChange={handleChangeTextarea}
                                    defaultValue={purposeData.processingActivities ? purposeData.processingActivities : ""}
                                    maxLength={32000}
                                    rows={5}
                                />
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>
                        }
                    </div>
                </main>
            </div>

        </>)
}

export default ProcessingActivities;