import { Button, ErrorSummary, Fieldset, Hint, InsetText, Radios } from "nhsuk-react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { dsaSummary, dsaContent, dsaErrorMessage, dsaSummaryErrorMessage, routeName, } from "../../../../config/page.config";
import { IDSASummary } from "../../../../state/models/app.interface";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import { getDSASummaryDetails, setDSASummaryDetailsData } from "../../../../state/slice/DSASummary/DSASummary.slice";
import { submitSpinner } from "../../../../Helper/Utility";

const ProposedDSATerm: React.FC = () => {
    /*Variable Declarations*/
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const dsaSummaryDetailsData: IDSASummary = useAppSelector(getDSASummaryDetails);
    const initialState: IDSASummary = dsaSummaryDetailsData || {};
    const [dsaSummaryData, setDsaSummaryData] = useState(initialState);
    const saveDSASummaryDataInStore = (stateData?: IDSASummary) => {
        dispatch(setDSASummaryDetailsData({ ...dsaSummaryData, ...stateData }));
    }
    const [taskDurationError, setTaskDurationError] = useState("");


    const handleDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
        const duration = (e.target.value).split(` `)[0];
        let updatedState: IDSASummary = {};
        updatedState = {
            ...dsaSummaryData,
            duration
        }
        saveDSASummaryDataInStore(updatedState);
        setDsaSummaryData(updatedState);
        resetError(e);
    }
    const navigateProposedDate = () => {
        navigate(routeName.proposedDate)
    }

    /*Validate and Submit*/
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, navigateProposedDate);
            
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const validateFields = () => {
        let isValid: boolean = true;
        if (dsaSummaryData.duration === "0") {
            setTaskDurationError(`${dsaSummaryErrorMessage.enterDSATaskDuration_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setTaskDurationError("");
        }
    }


    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#Duration">
                            {taskDurationError}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                Data request reason and project name
                            </span>
                            <h1>What is your proposed Data Sharing Agreement term? </h1>
                            {taskDurationError !== "" ? errorSummary : null}
                            <InsetText>
                                <p>If you will be sub-licensing the data (sharing data with an organisation not mentioned in the Data Sharing Agreement (DSA)), the maximum DSA term is one year.</p>
                                <p>If your DSA is needed for longer than three years, select '3 years' from the options below, and this can be discussed with the DARS team after your application has been submitted and reviewed.</p>
                            </InsetText>
                            <Hint>Select one option to tell us how long you need this DSA </Hint>
                            <div className="nhsuk-grid-column-two-thirds">
                                <Fieldset className="nhsuk-space-bottom-double">
                                    <Radios
                                        name="Duration"
                                        id="Duration"
                                        error={taskDurationError}>
                                        <Radios.Radio
                                            name="Duration"
                                            value={dsaSummary.firstYear}
                                            checked={String(dsaSummaryData.duration) === String(dsaSummary.firstYear)}
                                            onChange={handleDuration}>1 year
                                        </Radios.Radio>
                                        <Radios.Radio
                                            name="Duration"
                                            value={dsaSummary.secondYear}
                                            checked={String(dsaSummaryData.duration) === String(dsaSummary.secondYear)}
                                            onChange={handleDuration}>2 years
                                        </Radios.Radio>
                                        <Radios.Radio
                                            name="Duration"
                                            value={dsaSummary.thirdYear}
                                            checked={String(dsaSummaryData.duration) === String(dsaSummary.thirdYear)}
                                            onChange={handleDuration}>3 years
                                        </Radios.Radio>
                                    </Radios>
                                </Fieldset>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default ProposedDSATerm;

