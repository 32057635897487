import { Button, Details, Textarea, ErrorSummary, Fieldset, InsetText, Hint } from "nhsuk-react-components";
import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { submitSpinner, validateDateValue, getCookie, fetchInterceptor, handleUploadFile, validateFile, getB64toBlobData, setInputFileCustom, getEvidenceFileDetails, isValid } from "../../../../Helper/Utility";
import { IApiResEvidenceFileSummary, IApplication, IController, IEvidenceFileSummary, IFileUpload } from "../../../../state/models/app.interface";
import { getControllerDetails, setControllerDetailsData } from "../../../../state/slice/DSAAboutController/DSAAboutController.slice";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { dsaControllerErrorMessage, fileErrorMessage, dsaContent, fileTypeSize, routeName, buttonText, documentType } from "../../../../config/page.config";
import { GetDSAFileById_URL } from "../../../../config/api-endpoints.config";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import ReviewDateSection from "../../../../Components/Shared/ReviewDateSection";
import FileUploadControl1 from "../../../../Components/FileUploadControl/FileUploadControl1";
import { usePrevLocation } from "../../../../Hooks/usePrevious";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import Loader from "../../../../Components/Loader/Loader";
/*
  Summary: Using this page, you can add the details related to SystemLevelSecurityPolicy and can also upload files. The Jira ticket linked to this is DDA-538.
*/
const SLSP: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    const location: unknown = useLocation();
    const prevLocation = usePrevLocation(location as Location);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData] = useState(dsaApplicationDetails || {});
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const {fileUploadActive = 0} = useAppSelector(getControllerDetails) || {};
    const [controllerData, setData] = useState(useAppSelector(getControllerDetails) || {});

    const saveControllerDataInStore = (stateData?: IController) => {
        dispatch(setControllerDetailsData({ ...controllerData, ...stateData }));
    }

    const [diDayError, setDiDayError] = useState(false);
    const [diMonthError, setDiMonthError] = useState(false);
    const [diYearError, setDiYearError] = useState(false);
    const [reviewDate, setReviewDate] = useState(false);
    const [fileLists, setFileLists] = useState<IFileUpload[]>([]);
    const [fileListsOnChange, setFileListsOnChange] = useState<File[]>([]);
    let showfileLists: IFileUpload[] = ([]);
    const [selectedFile, setSelectedFile] = useState<File[] | []>([]);
    const [isSelected, setIsSelected] = useState(false);
    const [fileChosen, setfileChosen] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [uploadAnother, setUploadAnother] = useState(false);
    const [validFileError, setValidFileError] = useState("");
    const [isFileUploadedError, setIsFileUploadedError] = useState("");
    const [isFileUploadedErrorLink, setIsFileUploadedErrorLink] = useState("");
    const [validateChooseFile, setValidateChooseFile] = useState(false);
    const [dateReviewError, setDateReviewError] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showdownload, setshowdownload] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(0);
    const [selectedApprovalEvidence, setSelectedApprovalEvidence] = useState('');
    const [applicantCommentsErrorMessage, setApplicantCommentsErrorMessage] = useState("");
    const [showReviewDate, setShowReviewDate] = useState(false);
    const [applicantComments, setApplicantComments] = useState(getComment(controllerData.fileUpload![fileUploadActive]));
    let [evidenceSummaryList, setEvidenceSummaryList] = useState<IEvidenceFileSummary[]>([]);
    const [showLoader, setShowLoader] = useState(true);
    const [selectedFileName, setSelectedFileName] = useState("");
    
    function getComment(uploadedFile: IFileUpload) {
        if (
            uploadedFile !== undefined &&
            uploadedFile.DocumentType === documentType.SLSP
        ) {
            return (uploadedFile?.ApplicantComments! !== "" ? uploadedFile?.ApplicantComments!: "")
        } else {
            return "";
        }
    };

    const isDateEntered = () => {
        return (controllerData?.saList?.SLSPDay !== "" &&  controllerData?.saList?.SLSPMonth !== "" && controllerData?.saList?.SLSPYear !== "");
    }

    const handelUploadedFiles = (files: File[]) => {
        const uploaded = [...selectedFile!];
        files.forEach((file: File) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                return true;
            }
        })
        setSelectedFile(uploaded);  
    }
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === dsaContent.file) {
            resetError(e);
            const chosenFiles = Array.prototype.slice.call(e.target.files);
            setValidateChooseFile(true);
            if (validateFile(e, fileTypeSize, setValidFileError, setIsFileUploadedError, fileErrorMessage, setIsSelected, false, selectedFileName!, evidenceSummaryList)) {
                handelUploadedFiles(chosenFiles);
                setfileChosen(true);
                setValidFileError("");
                setValidateChooseFile(false);
                let updatedState: IController = {};
                updatedState = { ...controllerData, [e.target.name]: e.target.value, isDirtyController: true }
                setData(updatedState);
                saveControllerDataInStore(updatedState);
                if (controllerData.fileUpload![fileUploadActive!]?.FileName !== undefined) {
                    handleUpload(e, chosenFiles);
                }
            }
            else {
                setSelectedFile([]);
                setIsSelected(false);
            }
            /* set the state value on change of Input */

        }
        else {
            let updatedStateofController: IController = {};

            let updatedSAList = { ...controllerData.saList, [e.target.name]: e.target.value };
            updatedStateofController = { ...controllerData, 'saList': updatedSAList, isDirtyController: true }

            setData(updatedStateofController);
            saveControllerDataInStore(updatedStateofController);
            resetError(e);
        }
        if (e.target.name === dsaContent.slspDay || e.target.name === dsaContent.slspMonth || e.target.name === dsaContent.slspYear) {
            resetError(e);
        }
    };

    let files = selectedFile ? Array.from(selectedFile) : [];

    const validateUploadedFile = (filesToValidate?: File[]) => {
        if ((uploadedFileOnChange?.length + filesToValidate!.length) > 6) { // check and remove - length validation not required
            setValidFileError(`${fileErrorMessage.fileLength_ErrorMessage}`);
            setSelectedFile([]);
            setIsSelected(false);
            if (inputRef.current != null)
                inputRef.current.value = '';
            return false;
        }
        else if (filesToValidate?.length === 0 && controllerData.fileUpload![fileUploadActive!]?.FileName === undefined) {
            setValidFileError(`${fileErrorMessage.fileRequiredToUpload_ErrorMessage}`);
            setSelectedFile([]);
            setIsSelected(false);
            return false;
        }
        return true;
    }

    /* To upload the file */

    const handleUpload = (e: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<HTMLInputElement>, fileList?: File[]) => {
        fileList = fileList ? fileList : files;
        const IsValid: boolean = validateUploadedFile(fileList);
        if (IsValid) {
            handleUploadFile(e, fileLists, fileListsOnChange, setIsUploaded, selectedFile!, fileList!, setFileLists, setFileListsOnChange, setIsSelected, setSelectedFile, resetError, false, true, setUploadAnother, evidenceSummaryList!, setEvidenceSummaryList, setSelectedFileName, selectedFileId, applicantComments, fileUploadActive, 0, selectedApprovalEvidence);
        }
        setSelectedFileId(0);
        setSelectedApprovalEvidence('');
    }
    let finallyUploadedFiles: IFileUpload[] = fileLists ? Array.from(fileLists) : controllerData.fileUpload!;
    let uploadedFileOnChange = fileListsOnChange ? Array.from(fileListsOnChange) : [];

    const changeUpLoadedDocData = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, fileId: number, fileName: string, fileType: string, approvalEvidence: string) => {
        e.preventDefault();
        setUploadAnother(true);

        let fileElement = document.getElementById('file');
        if (fileElement != null) {
            setInputFileCustom(fileElement, fileName, fileType);
        }
        else {
            setTimeout(function () { setInputFileCustom(document.getElementById('file'), fileName, fileType) }, 500);
        }
        setSelectedFileId(fileId);
        setSelectedApprovalEvidence(approvalEvidence);
    }

    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedStateofController: IController = {};

        let updatedSAList = { ...controllerData.saList, [e.target.name]: e.target.value };
        updatedStateofController = { ...controllerData, 'saList': updatedSAList, isDirtyController: true };

        setData(updatedStateofController);
        saveControllerDataInStore(updatedStateofController);
    }

    const handleChangeApplicantComments = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedStateofControllerComment: IController = {};
        setApplicantComments(e.target.value!);

        let updatedFileData: IFileUpload[] = []
        if (controllerData.fileUpload?.length === 0 ||
            (controllerData.fileUpload?.length !== 0 && controllerData.fileUpload?.length === fileUploadActive && !controllerData.fileUpload![fileUploadActive]?.ApplicantComments)) {
            //new array item
            updatedFileData = [...controllerData.fileUpload!, ...[{ ...controllerData.fileUpload![fileUploadActive], ...{ [e.target.name]: e.target.value, RecentlyModified: true } }]];
        } else {
            updatedFileData = controllerData.fileUpload!.map((item, index) => {
                if (fileUploadActive === index) {
                    return { ...item, ...{ [e.target.name]: e.target.value, RecentlyModified: true } }
                } else return item;
            })
        }

        updatedStateofControllerComment = { ...controllerData, fileUpload: updatedFileData, isDirtyController: true };
        finallyUploadedFiles = updatedFileData;
        
        setFileLists(finallyUploadedFiles);
        
        setData(updatedStateofControllerComment);
        saveControllerDataInStore(updatedStateofControllerComment);
        resetError(e);
    }

    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const valueLength = e.target.value.length;
        if (valueLength >= 1) {
            if (e.target.id === dsaContent.file) {
                setIsFileUploadedError("");
                setValidFileError("");
                setIsFileUploadedErrorLink("");
            }
            if (e.target.name === dsaContent.slspDay || e.target.name === dsaContent.slspMonth || e.target.name === dsaContent.slspYear) {
                setDateReviewError("");
                setDiYearError(false);
                setDiDayError(false);
                setDiMonthError(false);
            }
            if (e.target.id === dsaContent.applicantComments) {
                setApplicantCommentsErrorMessage("");
            }
        }
        if (e.target.name === dsaContent.upload) {
            setIsFileUploadedError("");
            setIsFileUploadedErrorLink("");
        }
    }

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields(e);
        if (isValid) {
            submitSpinner(e, submitSLSP);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    /* To submit the details of the page */
    const submitSLSP = () => {
        let updatedSAList = { ...controllerData.saList, SLSPDateComplete: controllerData.saList ? controllerData.saList.SLSPDay + "/" + controllerData.saList.SLSPMonth + "/" + controllerData.saList.SLSPYear : "" };
        let updatedState: IController = {};
        updatedState = {
            ...controllerData,
            'saList': updatedSAList,
            evidenceFileSummary: evidenceSummaryList
        }
        setData(updatedState);
        saveControllerDataInStore(updatedState);
        navigate(routeName.addAnotherSlsp);
    }
    /* To Validate the each field's data */
    const validateFields = (e: React.MouseEvent<HTMLButtonElement>) => {
        let isValid: boolean = true;
        if (!(controllerData.saList ? controllerData.fileUpload![fileUploadActive]?.ApplicantComments! : null)) {
            setApplicantCommentsErrorMessage(`${dsaControllerErrorMessage.applicantComments_ErrorMessage}`);
            isValid = false;
        }

        if ((controllerData.fileUpload![fileUploadActive]?.FileName! === "" || controllerData.fileUpload![fileUploadActive]?.FileName! === undefined) ) {
            setIsFileUploadedErrorLink(`${fileErrorMessage.fileLink_ErrorMessage}`);
            setIsFileUploadedError(`${fileErrorMessage.fileUploded_ErrorMessage}`);
            isValid = false;
        }
        if (!fileChosen) {
            setIsFileUploadedErrorLink(`${fileErrorMessage.fileLink_ErrorMessage}`);
            setIsFileUploadedError(`${fileErrorMessage.fileRequiredSummaryBody_ErrorMessage}`);
            isValid = false;
        }
        if (validateChooseFile) {
            setIsFileUploadedError("");
        }

        if (validFileError) {
            isValid = false;
        }

        let dateValueError = validateDateValue(Number(controllerData.saList ? controllerData.saList?.SLSPDay : "0"), Number(controllerData.saList ? controllerData.saList?.SLSPMonth : "0"), Number(controllerData.saList ? controllerData.saList?.SLSPYear : "0"), false, reviewDate);
        if (dateValueError[0] !== "" && showReviewDate) {
            setDateReviewError(dateValueError[0]);
            let fields = dateValueError[1].split(",");
            for (let i = 0; i < fields.length; i++) {
                if (fields[i] === dsaContent.dateInputDayField) {
                    setDiDayError(true);
                }
                if (fields[i] === dsaContent.dateInputMonthField) {
                    setDiMonthError(true);
                }
                if (fields[i] === dsaContent.dateInputYearField) {
                    setDiYearError(true);
                }
            }
            isValid = false;
        }
        return isValid;
    }
    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaControllerErrorMessage.selectControllerSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#applicantComments">
                            {applicantCommentsErrorMessage}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#slspDateComplete">
                            {dateReviewError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#fileupload">
                            {validFileError ? validFileError : isFileUploadedErrorLink}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    /* To show the uploaded files on the page */
    const showFileList = () => {
        if (controllerData.fileUpload !== null && controllerData.fileUpload != undefined) {
            const filterFile = controllerData?.fileUpload.length && controllerData?.fileUpload?.filter(file => file?.DocumentType! === documentType.SLSP)
            showfileLists = Object.assign([], filterFile);
            if (showfileLists[0]?.FileName !== "") {
                setFileLists(showfileLists);
                finallyUploadedFiles = showfileLists;
                if (finallyUploadedFiles.length >= 1) {
                    setIsSelected(true);
                    setfileChosen(true);
                }
            }
        }
    }

    /*File Download*/
    const setFileUrl = (e: any) => {
        e.preventDefault();
        setSelectedIndex(e.currentTarget.id);
        setshowdownload(true);
        e.currentTarget.className += "nhsuk-disableLink";
        downloadfile(e, e.currentTarget.id);

    }
    const downloadfile = (e: any, fileId: any) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "FileId": fileId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: parameters
        };

        fetchInterceptor(GetDSAFileById_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data && data !== undefined) {
                    const b64Data: any = data.Data._buffer;
                    const blob: any = getB64toBlobData(b64Data, data.contentType, data.FileName);
                    const blobUrl: any = URL.createObjectURL(blob);
                    download(blobUrl, data.FileName);
                    setshowdownload(false);
                    e.target.removeAttribute('class');
                }

            })
            .catch(() => navigate('/serviceerror'));

    }
    const download = (path: any, filename: any) => {
        const anchor = document.createElement('a');
        anchor.href = path;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };
    /*File Download*/
    const removeFileOnGoBack = () => {
        let updatedState: IController = {};
        let fileUploadUpdated = [...controllerData.temporaryFileList!];
        updatedState = {
            fileUpload: fileUploadUpdated?.filter((x) => isValid(x.FileName)),
            fileUploadActive: 0
        }
        setData(updatedState);
        saveControllerDataInStore(updatedState);
    }

    const getEvidenceFileSummary = () => {
        let applicationId = isValid(dsaApplicationData.id) ? dsaApplicationData.id : "";
        if (isValid(applicationId)) {
            getEvidenceFileDetails(applicationId!).then((data) => {
                setEvidenceFileSummary(data);
            });
        }
    }

    const setEvidenceFileSummary = (response: IApiResEvidenceFileSummary[]) => {
        if (isValid(response)) {
            let evidenceFileSummary: IEvidenceFileSummary[] = [];
            response.map(({ FileId: fileId, FileName: fileName, MimeType: mimeType, Source: source }) => {
                evidenceFileSummary.push({ fileId, fileName, mimeType, source });
            });
            let updatedState: IController = {};
            updatedState = { ...controllerData, evidenceFileSummary }
            setData(updatedState);
            saveControllerDataInStore(updatedState);
        }
        setShowLoader(false);

    }
    useEffect(() => {
        setShowReviewDate(
            (prevLocation?.pathname !== routeName.addAnotherSlsp && controllerData.fileUpload![fileUploadActive!]?.FileName === '') || controllerData.fileUpload!.length === 0 || !isDateEntered());
        showFileList();
        setReviewDate(true);
        if (!isValid(controllerData.evidenceFileSummary)) {
            getEvidenceFileSummary();
        }
        else {
            setEvidenceSummaryList(controllerData.evidenceFileSummary!);
            setShowLoader(false);
        }
        setSelectedFileName(isValid(controllerData.fileUpload ) && isValid(controllerData.fileUpload![fileUploadActive]) ? controllerData.fileUpload![fileUploadActive].FileName! : "");
    }, []);

    useEffect(() => {
        if (!isValid(evidenceSummaryList)) {
            setEvidenceSummaryList(controllerData.evidenceFileSummary!);
        }
    }, [controllerData.evidenceFileSummary]);

    useEffect(() => {
        finallyUploadedFiles = fileLists ? Array.from(fileLists) : [];  // submit
        let updatedState: IController = {};
        updatedState = {
            ...controllerData,
            fileUpload: finallyUploadedFiles,
        }
        setData(updatedState);
        saveControllerDataInStore(updatedState);
    },[fileLists]);  

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {showLoader ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <>
                        <div className="nhsuk-grid-column-two-thirds">
                                    <GoBack onClick={removeFileOnGoBack} />
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the Controller
                            </span>
                            <h1>System Level Security Policy</h1>
                            {(isFileUploadedError !== "" || validFileError !== '' ||  dateReviewError ) ? errorSummary : null}                            
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <Textarea
                                label="Provide your own description or name for the document (character limit 50)"
                                error={applicantCommentsErrorMessage}
                                name="ApplicantComments"
                                id={dsaContent.applicantComments}
                                onChange={handleChangeApplicantComments}
                                defaultValue={applicantComments}
                                maxLength={50}
                                rows={3}
                            />
                        </div>

                        <div className="nhsuk-grid-column-two-thirds">
                            <div className="nhsuk-u-padding-bottom-5"></div>
                            <Fieldset>
                                <Fieldset.Legend>Documents required </Fieldset.Legend>
                                <Fieldset.Legend>- System Level Security Policy (SLSP) form</Fieldset.Legend>
                                <Fieldset.Legend>- Further evidence from the SLSP form </Fieldset.Legend>
                            </Fieldset>
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <div className="nhsuk-u-padding-bottom-3"></div>
                            <Details>
                                <Details.Summary>View file guidance </Details.Summary>
                                <Details.Text>
                                    <p>A file should not exceed 3 MB and should be one of the following acceptable file formats: PDF, Word, Excel. </p>
                                </Details.Text>
                            </Details>
                        </div>

                        <FileUploadControl1
                            inputRef={inputRef=> inputRef}
                            errorAboveList = {isFileUploadedError}
                            errorBelowList = {validFileError}
                            isUploaded = {isUploaded}
                            finallyUploadedFiles = { fileUploadActive === 0 && controllerData.fileUpload!.length === 0 ? undefined : controllerData.fileUpload![fileUploadActive!] as any}
                            handleChange = {handleChange}
                            handleUpload = {handleUpload}
                            changeFile = {changeUpLoadedDocData}
                            setFileUrl = {setFileUrl}
                            showDownload = {showdownload}
                            selectedIndex = {selectedIndex}
                            setSelectedFileId={setSelectedFileId}
                            setSelectedApprovalEvidence={setSelectedApprovalEvidence}
                        />

                        {showReviewDate && <ReviewDateSection 
                            handleChange={handleChange} 
                            handleChangeTextarea={handleChangeTextarea}
                            dateReviewError={dateReviewError} 
                            diDayError= {diDayError}
                            diMonthError= {diMonthError}
                            diYearError= {diYearError}
                            data={{
                                day: controllerData.saList ? controllerData.saList?.SLSPDay : "",
                                month: controllerData.saList ? controllerData.saList?.SLSPMonth : "",
                                year: controllerData.saList ? controllerData.saList?.SLSPYear : "",
                                notes: controllerData.saList ? controllerData.saList?.SLSPNotes : ""
                            }}
                        />}
                        <div className="nhsuk-u-padding-bottom-7"></div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleSubmit}>{buttonText.btnTextContinue}</Button>

                                </div>
                            </>}
                    </div>
                </main>
            </div>
        </>
    )
}
export default SLSP