import { Label } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import EditorContent from "./EditorContent";
import { GetGuidanceList_URL } from "../../config/api-endpoints.config";
import useFetch from "../../Hooks/useFetch";
import { updatePageTitle, getDateFullMonth, scrollToTop } from "../../Helper/Utility";
import Sticky from "../../Components/Sticky/Sticky";
import { dsaContent, pageTitles } from "../../config/page.config";
import Loader from "../../Components/Loader/Loader";
import "./Guidance.css";
import { IGuidance } from "../../state/models/app.interface";

const Guidance: React.FC = () => {
    const avoidOauthToken = true;   // Fetch interceptor not required for public calls
    const { response, loading }: any = useFetch(GetGuidanceList_URL, "POST", undefined, {}, undefined, undefined, avoidOauthToken);
    const [htmlContent, setContents] = useState<string | undefined>();
    const [showUpdate, setShowUpdate] = useState(false);
    const [guidanceUpdate, setGuidanceUpdate] = useState([]);
    useEffect(() => {
        updatePageTitle(pageTitles.guidance);
    }, []);

    useEffect(() => {
        scrollToTop();
        if (response && response.ContentList.length > 0) {
            const { Content: htmlContent } = response?.ContentList[0]!;
            setContents(htmlContent);
            let updateInfo = response?.ContentList[0]?.ContentManagementChildList.length > 0 ? response?.ContentList[0]?.ContentManagementChildList : [];
            if (updateInfo.length > 1) {
                updateInfo.sort(function (firstDate: IGuidance, secondDate: IGuidance) {
                    let convertedFrstDate = new Date(firstDate.Date);
                    let convertedSecondDate = new Date(secondDate.Date);
                    if (convertedFrstDate > convertedSecondDate) return -1;
                    else if (convertedFrstDate === convertedSecondDate) return 0;
                    else return 1;
                });
                setGuidanceUpdate(updateInfo);
            }
        }
    }, [response]);

    function printPage(): void {
        window.print();
    }

    return (
        <div className="nhsuk-width-container">
            <main className="nhsuk-main-wrapper" id="maincontent" role="main">
                <div className="nhsuk-grid-row">
                    <div className="nhsuk-grid-column-full">
                        {loading ? (
                            <Loader loadingText={dsaContent.txtLoading} />
                        ) : (
                            <>
                                <div>
                                    <div className="nhsuk-grid-row">
                                        <div className="nhsuk-grid-column-two-thirds">
                                            <span className="nhsuk-caption-l nhsuk-caption--bottom">Guidance</span>
                                            <h1>Data Access Request Service</h1>
                                            <Label size="s">
                                                When and how to make an application for data using the online Data Access
                                                Request Service (DARS){" "}
                                            </Label>
                                        </div>
                                    </div>
                                    <hr className="nhsuk-u-margin-top-0" />

                                    {response?.ContentList[0]?.Date && htmlContent &&
                                        <div className="nhsuk-grid-row">
                                            <div className="nhsuk-grid-column-two-thirds">
                                                <p className="nhsuk-body-s nhsuk-u-secondary-text-color nhsuk-u-margin-bottom-20">
                                                    From:{" "}
                                                    <a href="" target="_blank">
                                                        {" "}
                                                        NHS England
                                                    </a>
                                                    <br />
                                                    Published{" "}
                                                    {response?.ContentList[0]?.Date &&
                                                        getDateFullMonth(response?.ContentList[0]?.Date)}
                                                    <br />
                                                    Last updated{" "}
                                                    {response?.ContentList[0]?.PublishDate &&
                                                        getDateFullMonth(response?.ContentList[0]?.PublishDate)} {<>&#8212;</>}
                                                    <a href="#showUpdate" onClick={() => setShowUpdate(!showUpdate)}> See all updates</a>
                                                </p>
                                            </div>
                                            <div className="nhsuk-grid-column-one-third dont-print hide">
                                                <button className="nhsuk-link nhsuk-body-s print-button" onClick={printPage}>Print this page</button>
                                            </div>
                                        </div>}
                                </div>

                                <div className="nhsuk-grid-row nhsuk-u-margin-top-20">
                                    <div className="nhsuk-grid-column-two-thirds nhsuk-entity-content">
                                        {htmlContent ?
                                            <>
                                                <EditorContent htmlContent={htmlContent ? htmlContent : ""} />
                                                <hr className="nhsuk-u-margin-top-0" />
                                                {response?.ContentList[0]?.Date &&
                                                    <div id='showUpdate' className="nhsuk-published-dates">
                                                        <p className="nhsuk-body-s nhsuk-u-secondary-text-color nhsuk-u-margin-bottom-20">
                                                            Published{" "}
                                                            {
                                                                getDateFullMonth(response?.ContentList[0]?.Date)}
                                                            <br />
                                                            Last updated{" "}
                                                            {response?.ContentList[0]?.PublishDate &&
                                                                getDateFullMonth(response?.ContentList[0]?.PublishDate)}
                                                            <a href="#showUpdate" onClick={() => setShowUpdate(!showUpdate)}>{showUpdate ? ' - hide all updates' : ' + show all updates'}</a>
                                                        </p>
                                                        {
                                                            guidanceUpdate != null ? (
                                                                <div id='showHideUpdate' className="nhsuk-change-history" style={{ display: showUpdate ? 'block' : 'none' }}>
                                                                    <ol className="nhsuk-published-dates-list">
                                                                        {
                                                                            guidanceUpdate.map((content: IGuidance, index: number) => (
                                                                                <li className="nhsuk-change-item" key={index}>
                                                                                    <time className="nhsuk-change-date timestamp">{getDateFullMonth(content?.Date)}</time>
                                                                                    <p className="">{content?.Summary}</p>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ol>
                                                                </div>
                                                            ) : null
                                                        }

                                                    </div>}
                                                {<Sticky stickFrom={100}>
                                                    <div className="nhsuk-width-container dont-print">
                                                        <div className="row">
                                                            <a
                                                                className="nhsuk-sticky-link"
                                                                href="#"
                                                                onClick={scrollToTop}
                                                            >
                                                                <svg
                                                                    className="nhsuk-top-icon nhsuk-u-margin-right-10"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="13"
                                                                    height="17"
                                                                    viewBox="0 0 13 17"
                                                                    aria-hidden="true"
                                                                    focusable="false"
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M6.5 0L0 6.5 1.4 8l4-4v12.7h2V4l4.3 4L13 6.4z"
                                                                    ></path>
                                                                </svg>
                                                                Contents
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Sticky>}
                                            </> :
                                            <h2 className="nhsuk-u-margin-top-3">Guidance content will be available soon.</h2>
                                        }
                                    </div>
                                    <div className="nhsuk-grid-column-one-third">
                                        <div className="nhsuk-help-sidebar-block">
                                            <>
                                                <>
                                                    <br />
                                                    <h6>Detailed guidance (Standards)</h6>
                                                    <p>All links open in a new window</p>
                                                    <nav role="navigation" className="app-related-nav__nav-section">
                                                        <ul>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/commercial-purpose"
                                                                    target="_blank"
                                                                >
                                                                    Commercial Purpose
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/controllers"
                                                                    target="_blank"
                                                                >
                                                                    Data Controllers
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/data-minimisation"
                                                                    target="_blank"
                                                                >
                                                                    Data Minimisation
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/processors"
                                                                    target="_blank"
                                                                >
                                                                    Data Processors
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/dpa-registration"
                                                                    target="_blank"
                                                                >
                                                                    Data Protection Act Registration
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/data-sharing-standard-7b---duty-of-confidentiality"
                                                                    target="_blank"
                                                                >
                                                                    Duty of Confidentiality
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/ethical-approval"
                                                                    target="_blank"
                                                                >
                                                                    Ethical Approval
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/expected-measurable-benefits"
                                                                    target="_blank"
                                                                >
                                                                    Expected Measurable Benefits
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/expected-outcomes"
                                                                    target="_blank"
                                                                >
                                                                    Expected Outcomes{" "}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/gdpr-consent"
                                                                    target="_blank"
                                                                >
                                                                    GDPR Consent
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/objective-for-processing"
                                                                    target="_blank"
                                                                >
                                                                    Objective for Processing
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/patient-objections"
                                                                    target="_blank"
                                                                >
                                                                    Patient Objections
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/processing-activities"
                                                                    target="_blank"
                                                                >
                                                                    Processing Activities
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/security-assurance"
                                                                    target="_blank"
                                                                >
                                                                    Security Assurance
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/special-conditions"
                                                                    target="_blank"
                                                                >
                                                                    Special Conditions
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/sub-licencing-and-onward-sharing-of-data"
                                                                    target="_blank"
                                                                >
                                                                    Sub-licencing and Onward Sharing
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/term-of-data-sharing-agreement"
                                                                    target="_blank"
                                                                >
                                                                    Term of Data Sharing Agreement
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/territory-of-use"
                                                                    target="_blank"
                                                                >
                                                                    Territory of Use
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-guidance/transparency-fair-processing"
                                                                    target="_blank"
                                                                >
                                                                    Transparency (fair processing)
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </>
                                                <hr className="nhsuk-u-margin-top-0" />
                                                <h6>Related content</h6>
                                                <p>All links open in a new window</p>
                                                <nav role="navigation" className="app-related-nav__nav-section">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/"
                                                                target="_blank"
                                                            >
                                                                A guide to UK GDPR
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.hra.nhs.uk/about-us/committees-and-services/confidentiality-advisory-group/cag-advice-nhs-digital/"
                                                                target="_blank"
                                                            >
                                                                Confidentiality Advisory Group (CAG)
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-products-and-services/services"
                                                                target="_blank"
                                                            >
                                                                DARS services
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-products-and-services/data-set-catalogue"
                                                                target="_blank"
                                                            >
                                                                Data set catalogue
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://digital.nhs.uk/about-nhs-digital/corporate-information-and-documents/independent-group-advising-on-the-release-of-data"
                                                                target="_blank"
                                                            >
                                                                IGARD guidance
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-products-and-services/metadata-dashboard"
                                                                target="_blank"
                                                            >
                                                                Metadata Dashboard
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://digital.nhs.uk/about-nhs-digital/corporate-information-and-documents/independent-group-advising-on-the-release-of-data/the-independent-group-advising-on-the-release-of-data-review-process"
                                                                target="_blank"
                                                            >
                                                                More about IGARD
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://digital.nhs.uk/services/national-data-opt-out/operational-policy-guidance-document/what-are-national-data-opt-outs"
                                                                target="_blank"
                                                            >
                                                                National data opt-out
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://digital.nhs.uk/services/data-access-request-service-dars/how-the-national-data-opt-out-affects-data-released-by-nhs-digital/national-data-opt-out-guidance-for-researchers/appendix-1-section-251-of-the-national-health-service-act-2006"
                                                                target="_blank"
                                                            >
                                                                National Health Service Act 2006
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://digital.nhs.uk/services/nhs-digitrials"
                                                                target="_blank"
                                                            >
                                                                NHS DigiTrials
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://digital.nhs.uk/services/data-access-request-service-dars/data-access-request-service-dars-process/data-access-request-service-dars-pre-application-checklist"
                                                                target="_blank"
                                                            >
                                                                Pre-application Checklist
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://digital.nhs.uk/services/data-access-request-service-dars/how-the-national-data-opt-out-affects-data-released-by-nhs-digital/national-data-opt-out-guidance-for-researchers/appendix-1-section-251-of-the-national-health-service-act-2006"
                                                                target="_blank"
                                                            >
                                                                Section 251 (s251)
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://digital.nhs.uk/coronavirus/coronavirus-data-services-updates/trusted-research-environment-service-for-england"
                                                                target="_blank"
                                                            >
                                                                Trusted Research Environment (TRE)
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </>

                                        </div>

                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </main>
        </div>
    );
};
export default Guidance;
