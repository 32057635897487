import React, { useState } from "react";
import { Button, ErrorSummary, Input } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IController, IApplication, IControllerList } from "../../../../state/models/app.interface";
import { getControllerDetails, setControllerDetailsData } from '../../../../state/slice/DSAAboutController/DSAAboutController.slice';
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { validateAlphabets, validateNumericAlphabets, submitSpinner, getCookie, fetchInterceptor } from "../../../../Helper/Utility";
import { dsaControllerErrorMessage, dsaContent, routeName, fileTypeSize } from "../../../../config/page.config";
import '../../../../Content/css/nhsuk-common.css';
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import InfoHeaderPanel from "../../../../Components/InfoHeaderPanel/InfoHeaderPanel";
import { getControllerListDetails, setControllerListDetailsData } from "../../../../state/slice/ControllerList/ControllerList.slice";
import { UpdateScreenDataController_URL } from "../../../../config/api-endpoints.config";
import GoBack from "../../../../Components/Shared/GoBack";

/*
  Summary: Using this page, you can add the details of an organization as a controller
           (which aren't found in organization search).
*/

const AddOrganisationDetails: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const DSAControllerDetailsData: IController = useAppSelector(getControllerDetails);
    const initialState: IController = DSAControllerDetailsData || {};
    const [controllerData, setcontrollerData] = useState(initialState);

    const dispatch: AppDispatch = useAppDispatch();
    const savedataInStore = (stateData?: IController) => {
        dispatch(setControllerDetailsData({ ...controllerData, ...stateData }));
    }
    const controllerListDetailsData: IControllerList = useAppSelector(getControllerListDetails);
    const initialControllerListState: IControllerList = controllerListDetailsData || {};
    const [controllerListData] = useState(initialControllerListState);
    const controllerIndex = controllerListData.activeController || 0;

    const saveControllerListDataInStore = (stateData?: IControllerList) => {
        dispatch(setControllerListDetailsData({ ...controllerListData, ...stateData }));
    }

    const isDirtyController = controllerData.isDirtyController || false;
    const [alternativeOrganisationNameLinkError, setAlternativeOrganisationNameLinkError] = useState("");
    const [alternativeOrganisationNameError, setAlternativeOrganisationNameError] = useState("");
    const [alternativeStreet1LinkError, setAlternativeStreet1LinkError] = useState("");
    const [alternativeStreet1Error, setAlternativeStreet1Error] = useState("");
    const [alternativeStreet2LinkError, setAlternativeStreet2LinkError] = useState("");
    const [alternativeStreet2Error, setAlternativeStreet2Error] = useState("");
    const [alternativeStreet3LinkError, setAlternativeStreet3LinkError] = useState("");
    const [alternativeStreet3Error, setAlternativeStreet3Error] = useState("");
    const [townOrCityLinkError, setTownOrCityLinkError] = useState("");
    const [townOrCityError, setTownOrCityError] = useState("");
    const [countyLinkError, setCountyLinkError] = useState("");
    const [countyError, setCountyError] = useState("");
    const [postCodeLinkError, setPostCodeLinkError] = useState("");
    const [postCodeError, setPostCodeError] = useState("");
    const [countryLinkError, setCountryLinkError] = useState("");
    const [countryError, setCountryError] = useState("");


    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);

    const ValidateFields = () => {
        let isValid: boolean = true;
        if (!controllerData.alternativeOrganisationName) {
            setAlternativeOrganisationNameLinkError(`${dsaControllerErrorMessage.alternativeOrganisationNameLink_ErrorMessage}`);
            setAlternativeOrganisationNameError(`${dsaControllerErrorMessage.alternativeOrganisationNameLink_ErrorMessage}`);
            isValid = false;
        }
        if (!controllerData.alternativeStreet1) {
            setAlternativeStreet1LinkError(`${dsaControllerErrorMessage.alternativeStreet1Link_ErrorMessage}`);
            setAlternativeStreet1Error(`${dsaControllerErrorMessage.alternativeStreet1Link_ErrorMessage}`);
            isValid = false;
        }
        else if (controllerData.alternativeStreet1) {
            if (!validateNumericAlphabets(controllerData.alternativeStreet1)) {
                setAlternativeStreet1LinkError(`${dsaControllerErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                setAlternativeStreet1Error(`${dsaControllerErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                isValid = false;
            }
        }

        if (controllerData.alternativeStreet2) {
            if (!validateNumericAlphabets(controllerData.alternativeStreet2)) {
                setAlternativeStreet2LinkError(`${dsaControllerErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                setAlternativeStreet2Error(`${dsaControllerErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                isValid = false;
            }
        }

        if (controllerData.alternativeStreet3) {
            if (!validateNumericAlphabets(controllerData.alternativeStreet3)) {
                setAlternativeStreet3LinkError(`${dsaControllerErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                setAlternativeStreet3Error(`${dsaControllerErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                isValid = false;
            }
        }

        if (!controllerData.alternativeCity) {
            setTownOrCityLinkError(`${dsaControllerErrorMessage.alternativeCityLink_ErrorMessage}`);
            setTownOrCityError(`${dsaControllerErrorMessage.alternativeCityLink_ErrorMessage}`);
            isValid = false;
        } else if (controllerData.alternativeCity) {
            if (!validateAlphabets(controllerData.alternativeCity)) {
                setTownOrCityLinkError(`${dsaControllerErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                setTownOrCityError(`${dsaControllerErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                isValid = false;
            }
        }
        if (controllerData.alternativeCounty) {
            if (!validateAlphabets(controllerData.alternativeCounty)) {
                setCountyLinkError(`${dsaControllerErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                setCountyError(`${dsaControllerErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                isValid = false;
            }
        }

        if (!controllerData.alternativePostCode) {
            setPostCodeLinkError(`${dsaControllerErrorMessage.alternativePostCodeLink_ErrorMessage}`);
            setPostCodeError(`${dsaControllerErrorMessage.alternativePostCodeLink_ErrorMessage}`);
            isValid = false;
        }
        else if (controllerData.alternativePostCode) {
            if (!validateNumericAlphabets(controllerData.alternativePostCode)) {
                setPostCodeLinkError(`${dsaControllerErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                setPostCodeError(`${dsaControllerErrorMessage.onlyNumericAlphabetAllowed_ErrorMessage}`);
                isValid = false;
            }
        }

        if (!controllerData.alternativeCountry) {
            setCountryLinkError(`${dsaControllerErrorMessage.alternativeCountryLink_ErrorMessage}`);
            setCountryError(`${dsaControllerErrorMessage.alternativeCountryLink_ErrorMessage}`);
            isValid = false;
        } else if (controllerData.alternativeCountry) {
            if (!validateAlphabets(controllerData.alternativeCountry)) {
                setCountryLinkError(`${dsaControllerErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                setCountryError(`${dsaControllerErrorMessage.onlyAlphabetAllowed_ErrorMessage}`);
                isValid = false;
            }
        }

        return isValid;
    }

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueLength = e.target.value.length;

        if (valueLength >= 1) {

            if (e.target.name === dsaContent.alternativeStreet1) {
                setAlternativeStreet1LinkError("");
                setAlternativeStreet1Error("");
            }

            if (e.target.name === dsaContent.alternativeCity) {
                setTownOrCityLinkError("");
                setTownOrCityError("");
            }

            if (e.target.name === dsaContent.alternativeCounty) {
                setCountyLinkError("");
                setCountyError("");
            }

            if (e.target.name === dsaContent.alternativePostCode) {
                setPostCodeLinkError("");
                setPostCodeError("");
            }

            if (e.target.name === dsaContent.alternativeCountry) {
                setCountryLinkError("");
                setCountryError("");
            }
        }

        if (e.target.name === dsaContent.alternativeOrganisationName) {
            setAlternativeOrganisationNameLinkError("");
            setAlternativeOrganisationNameError("");
        }

        if (e.target.name === dsaContent.alternativeStreet2) {
            setAlternativeStreet2LinkError("");
            setAlternativeStreet2Error("");
        }
        if (e.target.name === dsaContent.alternativeStreet3) {
            setAlternativeStreet3LinkError("");
            setAlternativeStreet3Error("");
        }
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IController = {};
        updatedState = { ...controllerData, [e.target.name]: e.target.value, isDirtyController: true }
        resetError(e);
        setcontrollerData(updatedState);
        savedataInStore(updatedState);
    };
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (ValidateFields()) {
            submitSpinner(e, submitControllerOrgDetails);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const submitControllerOrgDetails = () => {
        let updateState: object = {};
        updateState = {
            ...controllerData,
            alternativeOrganisationName: controllerData.alternativeOrganisationName,
            alternativeStreet1: controllerData.alternativeStreet1,
            alternativeStreet2: controllerData.alternativeStreet2,
            alternativeStreet3: controllerData.alternativeStreet3,
            alternativeCity: controllerData.alternativeCity,
            alternativeCounty: controllerData.alternativeCounty,
            alternativePostCode: controllerData.alternativePostCode,
            alternativeCountry: controllerData.alternativeCountry
        }
        setcontrollerData(updateState);
        savedataInStore(updateState);
        if (controllerListData.controllerDetailsPage && !controllerData?.isNewController) {
            if (isDirtyController) {
                const controllerId = controllerListData.controllerList ? controllerListData.controllerList[controllerIndex].controllerId : null;
                const parameters: string = JSON.stringify({
                    "UserAuthTokenId": authenticationTokenCookie,
                    "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
                    "AddressLine1": controllerData.alternativeStreet1,
                    "AddressLine2": controllerData.alternativeStreet2,
                    "AddressLine3": controllerData.alternativeStreet3,
                    "TownCity": controllerData.alternativeCity,
                    "County": controllerData.alternativeCounty,
                    "Postcode": controllerData.alternativePostCode,
                    "Country": controllerData.alternativeCountry,
                    "DataControllerId": controllerData.controllerId,
                    "OrganisationName": controllerData.alternativeOrganisationName,
                    "IsOrganisationDirty": true,
                    "OrganisationId": ""
                });
                const requestOptions: Object = {
                    method: 'POST',
                    headers: { 'Content-Type': fileTypeSize.fileTypeJson },
                    body: parameters
                };
                fetchInterceptor(UpdateScreenDataController_URL, requestOptions)
                    .then(response => {
                            return response.json()
                    })
                    .then(() => {
                        let updatedState: IControllerList = {
                            ...controllerListData, controllerDetailsPage: controllerListData.controllerDetailsPage
                        }
                        saveControllerListDataInStore(updatedState);
                        navigate(routeName.controllerDetails);
                    })
                    .catch(() => navigate(routeName.serviceError));
            }
            else { navigate(routeName.controllerDetails); }
        }
        else { navigate(routeName.processingData); }
    }

    const addressForm: JSX.Element = (
        <>
            <div>
                <Input
                    id={dsaContent.alternativeOrganisationName}
                    name={dsaContent.alternativeOrganisationName}
                    label={dsaContent.lbl_organisationName}
                    error={alternativeOrganisationNameError}
                    value={controllerData?.alternativeOrganisationName}
                    onChange={handleChange}
                />

                <Input
                    id={dsaContent.alternativeStreet1}
                    name={dsaContent.alternativeStreet1}
                    label={dsaContent.lbl_addressLine1}
                    error={alternativeStreet1Error}
                    value={controllerData?.alternativeStreet1}
                    onChange={handleChange}
                />

                <Input
                    id={dsaContent.alternativeStreet2}
                    name={dsaContent.alternativeStreet2}
                    label={dsaContent.lbl_addressLine2}
                    error={alternativeStreet2Error}
                    value={controllerData?.alternativeStreet2}
                    onChange={handleChange}
                />

                <Input
                    id={dsaContent.alternativeStreet3}
                    name={dsaContent.alternativeStreet3}
                    label={dsaContent.lbl_addressLine3}
                    error={alternativeStreet3Error}
                    value={controllerData?.alternativeStreet3}
                    onChange={handleChange}
                />

                <Input
                    id={dsaContent.alternativeCity}
                    name={dsaContent.alternativeCity}
                    label={dsaContent.lbl_townorCity}
                    error={townOrCityError}
                    value={controllerData?.alternativeCity}
                    onChange={handleChange}
                />

                <Input
                    id={dsaContent.alternativePostCode}
                    name={dsaContent.alternativePostCode}
                    label={dsaContent.lbl_postcode}
                    error={postCodeError}
                    value={controllerData?.alternativePostCode}
                    onChange={handleChange}
                />
                <Input
                    id={dsaContent.alternativeCounty}
                    name={dsaContent.alternativeCounty}
                    label={dsaContent.lbl_county}
                    error={countyError}
                    value={controllerData?.alternativeCounty}
                    onChange={handleChange}
                />


                <Input
                    id={dsaContent.alternativeCountry}
                    name={dsaContent.alternativeCountry}
                    label={dsaContent.lbl_country}
                    error={countryError}
                    value={controllerData?.alternativeCountry}
                    onChange={handleChange}
                />
            </div>
        </>
    );

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaControllerErrorMessage.summaryTitle_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#alternativeOrganisationName">
                            {alternativeOrganisationNameLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeStreet1">
                            {alternativeStreet1LinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeStreet2">
                            {alternativeStreet2LinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeStreet3">
                            {alternativeStreet3LinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeCity">
                            {townOrCityLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativePostCode">
                            {postCodeLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeCounty">
                            {countyLinkError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#alternativeCountry">
                            {countryLinkError}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    );

    return (
        <><InfoHeaderPanel>
            <InfoHeaderPanel.Item>
                <strong>{dsaContent.refNumber}</strong> : {dsaApplicationData.referenceNumber}
            </InfoHeaderPanel.Item>
            <InfoHeaderPanel.Item>
                <strong>{dsaContent.totalestimatedCost}</strong> : {dsaApplicationData.totalEstimatedCost}
            </InfoHeaderPanel.Item>
        </InfoHeaderPanel>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-full">
                            <GoBack/>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the Controller
                            </span>
                            <h1>Add organisation details</h1>
                        </div>

                        <div className="nhsuk-grid-column-two-thirds nhsuk-input--width-30">
                            {
                                alternativeStreet1Error !== "" ||
                                    alternativeStreet2Error !== "" ||
                                    alternativeStreet3Error !== "" ||
                                    countryError !== "" ||
                                    townOrCityError !== "" ||
                                    countyError !== "" ||
                                    postCodeError !== "" ? errorSummary : null
                            }

                            {addressForm}
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnSaveAndContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}
export default AddOrganisationDetails;