import storage from 'redux-persist/lib/storage';
import { abortError, documentType, dsaContent, dsaControllerErrorMessage, fileTypeSize, fundingErrorMessage, regexValidateString, retryLimit, routeName, serverResponse, tables, taskListSectionStatus, emptyGuid, evidenceTypeOptions, controllerSection, ProductGroupTypeOptions, dataSetLevelOptionTypes, SmallNumbersVisibleOrSupressed, frequencyOfDataOptionTypes, standardisationServiceLevelOptions, aPYfrequencyOfDataOptionTypes, applicationChangeType, compuslsorySectionsAmend } from "../config/page.config";
import { DeleteDocument_URL, DisplayDSAFile_URL, GetDocumentSession_URL, GetDSAFileById_URL, GetFileListBySectionId_URL, GetFileSummaries_URL, GetOauthToken_URL, SubmitDSASectionStatus_URL, SubmitScreenProduct_URL } from "../config/api-endpoints.config";
import { EvidenceTypeAttributes, IApiResFiles, IApiResSectionList, IApplication, ICheckService, IController, IDataSet, IDSAControllerErrorMessage, IEvidenceFileSummary, IEvidenceTypeOptions, IFileErrorMessage, IFileTypeSize, IFileUpload, IHistoricAnnualData, IPDF, ISecurityAssurance } from "../state/models/app.interface";

import { Dispatch, SetStateAction } from 'react';

export const destroyStoreData = () => {
    storage.removeItem('persist:root')
}

export const getCookie = (name: string) => {
    // Split cookie string and get all individual name=value pairs in an array
    let cookieArr: string[] = document.cookie.split(";");
    // Loop through the array elements
    for (let i: number = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    // Return null if not found
    return null;
}

export const checkServiceMaintenance = (data: ICheckService) => {
    if (
        data &&
        Object.keys(data).includes(dsaContent.serviceMaintenance) &&
        data["ServiceMaintenance"]
    ) {
        window.location.href = routeName.serviceUnavailable;
    }
};

export const validateResponse = (response: Response) => {
    if (
        response.status !== serverResponse.success &&
        response.status !== serverResponse.noContent &&
        response.status !== serverResponse.resourceCreated
    ) {
        window.location.href = routeName.serviceError;
    } else {
        return true;
    }
};

export const getApplicationChangeTypeValue = (tableName: string, columnName: string, option?: string) => {
    let value: string = "";
    const tableData = JSON.parse(JSON.stringify(tables));
    return value;
}

export const validateAlphabets = (inputText: string) => {
    const letters = regexValidateString.alphabets;
    if (inputText.match(letters)) {
        return true;
    }
    else {
        return false;
    }
}

export const validateNumericAlphabets = (inputText: string) => {
    const letters = regexValidateString.numericAlphabets;
    if (inputText.match(letters)) {
        return true;
    }
    else {
        return false;
    }
}

export const getDate = (inputFormat: string = "") => {
    if (inputFormat !== "" && !isNaN(Date.parse(inputFormat))) {
        let date = new Date(inputFormat);
        const pad = (s: number) => (s < 10) ? '0' + s : s;
        return [pad(date.getDate()), pad(date.getMonth() + 1), date.getFullYear()].join('/');
    }
    else {
        return "";
    }
}

export const getDateFullMonth = (inputFormat: string = "") => {
    const date = new Date(inputFormat);
    const year = date.getFullYear();
    let month = date.toLocaleString('default', { month: 'long' });
    let dt = date.getDate() > 0 ? date.getDate().toString() : '0' + date.getDate().toString();

    return (dt + " " + month + " " + year);
}

export const getB64toBlobData = (b64Data: string, contentType: string, fileName: string, sliceSize: number = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);

        for (let i: number = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    let blob: any = new Blob(byteArrays, { type: contentType });
    blob.name = fileName;
    return blob;
}

export const convertBytesToKB = (bytes: number, decimalPoint: number = 2) => {
    if (bytes == 0) return 0;
    let k = 1024,
        dm = decimalPoint || 2;
    return parseFloat((bytes / k).toFixed(dm));
}

export const buttonClicked = (target: HTMLElement, callback: Function) => {
    target.setAttribute("disabled", dsaContent.disabled);
    target.className += " nhsuk-button-spinner";
    callback && callback();
    setSuccessCondition();
}

export const submitSpinner = (event: React.MouseEvent<HTMLElement>, callback: unknown) => {
    const submit = event.currentTarget;
    if (submit?.getAttribute("disabled") === dsaContent.disabled) {
        event.preventDefault();
    } else {
        buttonClicked(submit, callback as Function);
    }
}

export const replaceNbsps = (inputText: string) => {
    var re = new RegExp(String.fromCharCode(160), "g");
    return inputText.replace(re, " ");
}

export const closeWindow = () => {
    window.close();
}

export const downloadPdfFile = (abortController: AbortController, applicationId: string, referencenumber: string, setBlobUrl: React.Dispatch<React.SetStateAction<string>>, setLoading?: React.Dispatch<React.SetStateAction<boolean>>) => {
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const avoidOauthToken = false,
        retryAttempts = 0;
    const parameters: string = JSON.stringify({
        "UserAuthTokenId": authenticationTokenCookie,
        "ApplicationId": applicationId
    });

    const requestOptions: Object = {
        method: 'POST',
        headers: { 'Content-Type': fileTypeSize.fileTypeJson },
        body: parameters,
        signal: abortController.signal
    };

    fetchInterceptor(DisplayDSAFile_URL, requestOptions, avoidOauthToken, retryAttempts, abortController)
        .then(response => {
            if (isFetchRequestAborted(abortController)) {
                throw new Error(abortError);
            } else {
                return response && response.json();
            }
        })
        .then(result => {
            const pdf = result;
            if (!result._buffer) {
                let updatedState: IPDF = {};
                updatedState = {
                    sessionId: pdf.SessionId
                }
                const sessionId = updatedState.sessionId;
                getDocumentSession(result, applicationId, referencenumber, sessionId!, setBlobUrl, setLoading);
            }
            else {
                generatePdfFile(result, referencenumber, dsaContent.dsaFilenameReplaceSummayFile, setBlobUrl, setLoading);
            }
        })
        .catch((error) => fetchRequestError(error, abortController));
}

export const getDocumentSession = (response: string, applicationId: string, referenceNumber: string, sessionId: string, setBlobUrl: React.Dispatch<React.SetStateAction<string>>, setLoading?: React.Dispatch<React.SetStateAction<boolean>>) => {
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const parameters: string = JSON.stringify({
        "UserAuthTokenId": authenticationTokenCookie,
        "ApplicationId": applicationId,
        "SessionId": sessionId
    });

    const requestOptions: Object = {
        method: 'POST',
        headers: { 'Content-Type': fileTypeSize.fileTypeJson },
        body: parameters
    };

    fetchInterceptor(GetDocumentSession_URL, requestOptions)
        .then(response => {
            return response.json();
        })
        .then(result => {
            const pdfSesssion = result;
            if (pdfSesssion._buffer) {
                generatePdfFile(result, referenceNumber, dsaContent.dsaFilenameReplaceSummayFile, setBlobUrl, setLoading);
            }
            else {
                setTimeout(function () { getDocumentSession(response, applicationId, referenceNumber, sessionId, setBlobUrl, setLoading) }, 5000);
            }
        })
        .catch(() => window.location.href = routeName.serviceError);
}

export const generatePdfFile = (response: IApiResFiles, referenceNumber: string, filename: string, setBlobUrl: React.Dispatch<React.SetStateAction<string>>, setLoading?: React.Dispatch<React.SetStateAction<boolean>>) => {
    const contentType: string = fileTypeSize.fileTypePDF;
    const filenameFragment: string = filename.replace("filenameReplace", referenceNumber);
    const b64Data: string = response._buffer;
    const blob = getB64toBlobData(b64Data, contentType, filenameFragment);
    const blobUrl: string = URL.createObjectURL(blob);
    setBlobUrl(blobUrl);
    setLoading && setLoading(false);
}

export const validateNumeric = (inputText: string) => {
    const letters = regexValidateString.numeric;
    if (inputText.match(letters)) {
        return true;
    }
    else {
        return false;
    }
}

export const validateDateValue = (day: number, month: number, year: number, expiryDate?: boolean, reviewDate?: boolean, isFundingInfo?: boolean, customErrorMessage?: IDSAControllerErrorMessage, startDate?: boolean) => {
    let errorMessage = "";
    let fields = "";
    const thirtyDaysMonth = [4, 6, 9, 11];
    let allFields = dsaContent.dateInputAllFields || "";
    let dayField = dsaContent.dateInputDayField || "";
    let monthField = dsaContent.dateInputMonthField || "";
    let yearField = dsaContent.dateInputYearField || "";
    let dayMonthFields = dsaContent.dateInputDayMonthField || "";
    let dayYearFields = dsaContent.dateInputDayYearField || "";
    let monthYearFields = dsaContent.dateInputMonthYearField || "";
    const futureDate1yr = new Date();
    futureDate1yr.setDate(futureDate1yr.getDate() + 365);
    if (!day && !month && !year) {
        if (expiryDate || isFundingInfo) {
            errorMessage = (customErrorMessage && customErrorMessage?.customDateMonthYearMissingDateExpired_ErrorMessage !== "")
                ? customErrorMessage.customDateMonthYearMissingDateExpired_ErrorMessage!
                : `${dsaControllerErrorMessage.dateMonthYearMissingDateExpired_ErrorMessage}`;
        }
        else if (reviewDate) {
            errorMessage = `${dsaControllerErrorMessage.dateMonthYearMissingDateReview_ErrorMessage}`;
        }
        else {
            errorMessage = (customErrorMessage && customErrorMessage?.customDateMonthYearMissingDateExpired_ErrorMessage !== "")
                ? customErrorMessage.customDateMonthYearMissingDateExpired_ErrorMessage!
                : `${dsaControllerErrorMessage.dateMonthYearMissingDatePublished_ErrorMessage}`;
        }
        fields = allFields;
    }
    else if (!day && !month) {
        errorMessage = `${dsaControllerErrorMessage.dateMonthMissingDatePublished_ErrorMessage}`;
        fields = dayMonthFields;
    }
    else if (!day && !year) {
        errorMessage = `${dsaControllerErrorMessage.dateYearMissingDatePublished_ErrorMessage}`;
        fields = dayYearFields;
    }
    else if (!month && !year) {
        errorMessage = `${dsaControllerErrorMessage.monthYearMissingDatePublished_ErrorMessage}`;
        fields = monthYearFields;
    }
    else if (!day) {
        if (isFundingInfo) { errorMessage = `${fundingErrorMessage.day_ErrorMessage}`; }
        else { errorMessage = `${dsaControllerErrorMessage.dateMissingDatePublished_ErrorMessage}`; }
        fields = dayField;
    }
    else if (!month) {
        if (isFundingInfo) { errorMessage = `${fundingErrorMessage.month_ErrorMessage}`; }
        else { errorMessage = `${dsaControllerErrorMessage.monthMissingDatePublished_ErrorMessage}`; }
        fields = monthField;
    }
    else if (!year) {
        if (isFundingInfo) { errorMessage = `${fundingErrorMessage.year_ErrorMessage}`; }
        else { errorMessage = `${dsaControllerErrorMessage.yearMissingDatePublished_ErrorMessage}`; }
        fields = yearField;
    }
    else if (!validateNumeric(String(day)) || !validateNumeric(String(month)) || !validateNumeric(String(year))) {
        errorMessage = `${dsaControllerErrorMessage.validDatePublished_ErrorMessage}`;
        if (!validateNumeric(String(day))) {
            fields = dayField;
        }
        else if (!validateNumeric(String(month))) {
            fields = monthField;
        }
        else if (!validateNumeric(String(year))) {
            fields = yearField;
        }
    }
    else if (((day <= 0 || day > 30) && thirtyDaysMonth.includes(parseInt((month).toString(), 10))) || day > 31 || (month == 2 && ((isLeapYear(year) && day > 29) || (!isLeapYear(year) && day > 28)))) {
        errorMessage = `${dsaControllerErrorMessage.validDayPublished_ErrorMessage}`;
        fields = dayField;
    }
    else if (month > 12 || month <= 0) {
        errorMessage = `${dsaControllerErrorMessage.validMonthPublished_ErrorMessage}`;
        fields = monthField;
    }
    else if (((year < 1950 || year > (new Date().getFullYear() + 50)) && !startDate) ) {
        errorMessage = `${dsaControllerErrorMessage.validYearPublished_ErrorMessage}`;
        fields = yearField;
    }
    else if (((year < 1950 || new Date(month + '/' + day + '/' + year) > futureDate1yr) && startDate)) {
        errorMessage = `${dsaControllerErrorMessage.validateFutureStartDate_ErrorMessage}`;
        fields = yearField;
    }
    else if (expiryDate) {
        if (new Date(month + '/' + day + '/' + year) < new Date()) {
            if (startDate) {
                errorMessage = (customErrorMessage && customErrorMessage?.customDateMonthYearMissingDateExpired_ErrorMessage !== "")
                    ? customErrorMessage.customDateMonthYearMissingDateExpired_ErrorMessage!
                    : `${dsaControllerErrorMessage.enteredExpiryDate_ErrorMessage}`;
            }
            else {
                errorMessage = `${dsaControllerErrorMessage.enteredExpiryDate_ErrorMessage}`;
            }
            fields = allFields;
        }
    }
    else if (!expiryDate && isFundingInfo) {
        if (new Date(month + '/' + day + '/' + year) > new Date()) {
            errorMessage = (customErrorMessage && customErrorMessage?.customDateMonthYearMissingDateExpired_ErrorMessage !== "")
                ? customErrorMessage.customDateMonthYearMissingDateExpired_ErrorMessage!
                : `${dsaControllerErrorMessage.futureDatePublished_ErrorMessage}`!;
            fields = allFields;
        }
    }
    else if (!expiryDate && !isFundingInfo) {
        if (new Date(month + '/' + day + '/' + year) > new Date()) {
            errorMessage = `${dsaControllerErrorMessage.futureDatePublished_ErrorMessage}`;
            fields = allFields;
        }
    }
    return [errorMessage, fields];
}


export const isLeapYear = (year: number) => {
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}
export const numberToWords = (numberInput: number) => {

    let baseNum = ['', 'First ', 'Second ', 'Third ', 'Fourth ', 'Fifth ', 'Sixth ', 'Seventh ', 'Eighth ', 'Ninth ', 'Tenth ', 'Eleventh ', 'Twelfth ', 'Thirteenth ', 'Fourteenth ',
        'Fifteenth ', 'Sixteenth ', 'Seventeenth ', 'Eighteenth ', 'Nineteenth '];
    let extendNum = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    if (numberInput.toString().length > 7) return 'overlimit';
    let num: string[] | null = ('0000000' + numberInput).slice(-7).match(/^(\d{1})(\d{1})(\d{2})(\d{1})(\d{2})$/);
    if (!num) return;

    let outputText = Number(num[1]) != 0 ? (baseNum[Number(num[1])] || `${extendNum[Number(num[1][0])]} ${baseNum[Number(num[1][1])]}`) + ' million ' : '';
    outputText += Number(num[2]) != 0 ? (baseNum[Number(num[2])] || `${extendNum[Number(num[2][0])]} ${baseNum[Number(num[2][1])]}`) + 'hundred ' : '';
    outputText += Number(num[3]) != 0 ? (baseNum[Number(num[3])] || `${extendNum[Number(num[3][0])]} ${baseNum[Number(num[3][1])]}`) + ' thousand ' : '';
    outputText += Number(num[4]) != 0 ? (baseNum[Number(num[4])] || `${extendNum[Number(num[4][0])]} ${baseNum[Number(num[4][1])]}`) + 'hundred ' : '';
    outputText += Number(num[5]) != 0 ? (baseNum[Number(num[5])] || `${extendNum[Number(num[5][0])]} ${baseNum[Number(num[5][1])]} `) : '';

    return outputText;
}

export const isContainsJson = (json: { [x: string]: any; }, value: string) => {
    let contains = false;
    Object.keys(json).some(key => {
        contains = typeof json[key] === 'object' ? isContainsJson(json[key], value) : json[key] === value;
        return contains;
    });
    return contains;
}

export const isObjectEmpty = (objName: JSON) => { return JSON.stringify(objName) === '[{}]' };

export const convertToDate = (dateString: string) => {
    let initialDate = dateString.split("/");
    let updatedDate = new Date(initialDate[2] + '/' + initialDate[1] + '/' + initialDate[0]);
    return updatedDate;
}

export const validateLength = (data: React.ChangeEvent<HTMLInputElement>) => {
    if (data.target.value.length > data.target.maxLength) {
        data.target.value = data.target.value.slice(0, -1);
    }
}

export const arrayChunk = (arr: any, n: number) => {
    const array = arr.slice();
    const chunks = [];
    if (array.length >= n) {
        while (array.length) chunks.push(array.splice(0, n));
    }
    else {
        chunks.push(array.splice(0, array.length))
    }
    return chunks;
};

export const deleteUploadedFiles = (fileId: number) => {
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const parameters: string = JSON.stringify({
        "UserAuthTokenId": authenticationTokenCookie,
        "FileId": fileId
    });
    const requestOptions: Object = {
        method: 'POST',
        headers: { 'Content-Type': fileTypeSize.fileTypeJson },
        body: parameters
    };

    fetchInterceptor(DeleteDocument_URL, requestOptions);

}

export const isGuidEmpty = (guid: string) => {
    return guid === emptyGuid;
}

export const calculateDuration = (startDate: string, endDate: string) => {
    let duration: number = 0;
    if (startDate && endDate) {
        let formattedStartDate = convertToDate(startDate);
        let formattedEndDate = convertToDate(endDate);
        if (formattedEndDate > formattedStartDate)
            duration = Math.abs(formattedEndDate.getFullYear() - formattedStartDate.getFullYear());
    }
    return duration.toString();
}

export const calculateExpiryDate = (startDate: string, duration: string) => {
    let expirydate: string = "";
    let formattedStartDate = new Date(calculateDateInFormat(startDate));
    let day = formattedStartDate.getDate();
    let month = formattedStartDate.getMonth() + 1;
    let year = formattedStartDate.getFullYear();
    if (startDate && duration) {
        if (month == 2 && ((isLeapYear(year) && day >= 29))) {
            year = formattedStartDate.getFullYear() + Number(duration);
            day = day - 2;
            expirydate = day + "/" + month + "/" + year;
        }
        else {
            formattedStartDate.setFullYear(formattedStartDate.getFullYear() + Number(duration));
            formattedStartDate.setDate(formattedStartDate.getDate() - 1);
            month = formattedStartDate.getMonth() + 1;
            expirydate = formattedStartDate.getDate() + "/" + month + "/" + formattedStartDate.getFullYear();
        }

    }
    return expirydate;
}

export const getKeyByValue = <T extends Record<string, any>, K extends keyof T>(
    object: T,
    value: T[K]
): keyof T | undefined => Object.keys(object).find((key) => object[key] === value);

export const hasEmptyValueForKey = (obj: any) => {
    let empty = false;
    for (let key in obj) {
        if (
            obj[key] !== '' &&
            obj[key] !== '00000000-0000-0000-0000-000000000000' &&
            obj[key] !== false &&
            obj[key] !== 0
        ) {
            empty = true;
            break;
        } else empty = false;
    }
    return !empty;
}

/* to Validate the the file uploaded */
export const validateFile = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, fileTypeSize: IFileTypeSize, setValidFileError: Dispatch<SetStateAction<string>>, setIsFileUploadedError: Dispatch<SetStateAction<string>>, fileErrorMessage: IFileErrorMessage, setIsSelected: Dispatch<SetStateAction<boolean>>, isOnlyCheckPdf: boolean, lastUploadedFile: string, evidenceFileList?: IEvidenceFileSummary[]) => {
    let listofSelectedFiles = Array.prototype.slice.call((event.target as HTMLInputElement).files);
    if (event !== undefined && event !== null && listofSelectedFiles.length > 0) {
        for (let file of listofSelectedFiles) {
            let isValidFile = true;
            if (file) {
                setIsFileUploadedError("");
                let isPDFWordOrExcel = true;
                if (isOnlyCheckPdf) {
                    isPDFWordOrExcel = file.type === fileTypeSize.fileTypePDF;
                }
                else {
                    isPDFWordOrExcel = checkFileType(file, fileTypeSize);
                }
                let errorMessage = ''
                if (!isPDFWordOrExcel) {
                    if (isOnlyCheckPdf)
                        errorMessage = `${fileErrorMessage.fileCorrectPDF_ErrorMessage}`;
                    else
                        errorMessage = `${fileErrorMessage.fileCorrect_ErrorMessage}`;
                    isValidFile = false;
                }
                if (convertBytesToKB(file.size) === Number(fileTypeSize.fileSizeEmpty)) {
                    isValidFile = false
                    errorMessage = `${fileErrorMessage.fileSize_EmptyErrorMessage}`;
                }
                if (convertBytesToKB(file.size) > Number(fileTypeSize.fileSize)) {
                    isValidFile = false
                    errorMessage = `${fileErrorMessage.fileSize_ErrorMessage}`;
                }

                if (isValid(evidenceFileList)) {
                    let fileName = getUploadFileName(file.name);
                    if (evidenceFileList!.find((x) => x.fileName == fileName) !== undefined && getUploadFileName(lastUploadedFile) != fileName) {
                        isValidFile = false
                        errorMessage = `${fileErrorMessage.fileAlreadyExists_ErrorMessage}`;
                    }
                }

                if (!isValidFile) {
                    setValidFileError(errorMessage);
                    event.target.value = '';
                    setIsSelected(false);
                    return isValidFile;
                }
            }
        };
        return true;
    }
    else {
        setValidFileError("");
        return false;
    }
}

export const checkFileType = (file: File, fileTypeSize: IFileTypeSize) => {
    let isFileTypeAccepted = true;
    var fileTypeArray = [fileTypeSize.fileTypeDoc, fileTypeSize.fileTypeDocx, fileTypeSize.fileTypeDotx, fileTypeSize.fileTypeDotm,
    fileTypeSize.fileTypeDocm, fileTypeSize.fileTypeXLSB, fileTypeSize.fileTypeXLSM, fileTypeSize.fileTypeXLAM,
    fileTypeSize.fileTypeXLS, fileTypeSize.fileTypeXLTX, fileTypeSize.fileTypeXLTM, fileTypeSize.fileTypeWord,
    fileTypeSize.fileTypeExcel, fileTypeSize.fileTypePDF, fileTypeSize.fileTypeCSV]
    if (fileTypeArray.includes(file.type)) {
        isFileTypeAccepted = true
    }
    else {
        isFileTypeAccepted = false;
    }
    return isFileTypeAccepted;
}

export const getEvidenceFileDetails = async (applicationId: string) => {
    try {
        const authenticationTokenCookie: string = getCookie('authenticationToken')!;
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": applicationId
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };
        const result = await fetchInterceptor(GetFileSummaries_URL, requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    window.location.href = routeName.serviceError;
                }
            });

        return result;
    }
    catch {
        window.location.href = routeName.serviceError;
    }
}

export const submitDSASectionStatus = async (applicationid: string, sectionid: string, sectionstatus: string) => {
    try {
        const authenticationTokenCookie: string = getCookie('authenticationToken')!;
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": applicationid,
            "SectionId": sectionid,
            "SectionStatus": sectionstatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };
        const response = await fetchInterceptor(SubmitDSASectionStatus_URL, requestOptions).then(response => {
            return response.ok
        });
        return response;
    }
    catch {
        window.location.href = routeName.serviceError;
    }
}

export const debounce = (fn: Function, interval = dsaContent.defaultDebouncedInterval) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), interval);
    };
};

export const scrollToTop = (event?: React.MouseEvent<HTMLElement>) => {
    event && event.preventDefault();
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

export const updatePageTitle = (pageTitle: string): void => {
    const nhsWord = " – NHS";
    const currentTitle = document.title.replace(nhsWord, "");
    if (nhsWord !== currentTitle) {
        document.title = pageTitle + nhsWord;
    }
};

export const calculateExampleDate = (yearDifference: number, monthDifference?: number) => {
    let date: string = "";
    if (yearDifference !== undefined || yearDifference !== null) {
        const current = new Date();
        let day = current.getDate();
        let month;
        if (monthDifference) {
            month = (current.getMonth() + 1) + monthDifference;
            if (current.getMonth() === 0) { month = 12; yearDifference = -1; }
        }
        else {
            month = current.getMonth() + 1;
        }

        let fullDate = '';
        let fullMonth = '';
        let fullYear = '';
        if (yearDifference > 0) {
            const futureDate1yr = new Date();
            const numberOfDays = 365 * yearDifference;
            futureDate1yr.setDate(futureDate1yr.getDate() + numberOfDays);
            month = futureDate1yr.getMonth() + 1;
            day = futureDate1yr.getDate();
            fullYear = futureDate1yr.getFullYear().toString();
            month < 10 ? fullMonth = '0' + month : fullMonth = month.toString();
            day < 10 ? fullDate = '0' + day : fullDate = day.toString();
            date = `${fullDate} ${fullMonth} ${fullYear}`;
        }
        else {
            month < 10 ? fullMonth = '0' + month : fullMonth = month.toString();
            day < 10 ? fullDate = '0' + day : fullDate = day.toString();
            date = `${fullDate} ${fullMonth} ${current.getFullYear() + yearDifference}`;
        }
    }
    return date;
}

export const validateEmail = (inputText: string) => {
    const letters = regexValidateString.email;
    if (inputText.match(letters)) {
        return true;
    }
    else {
        return false;
    }
}
export const getTaskListType = (taskListType: string) => {
    return (dsaContent.tasklist);
}

export const filterHtml = (htmlData: string) => {
    const patterns = [
        { "<p></p>": "" },
        { "<p><br></p>": "" },
        { "<p><span><br></span></p>": "" },
        { "<p><b><br></b></p>": "" },
        { "font-size": "fontSize" },
    ];
    patterns.forEach((pattern: any) => {
        htmlData = htmlData.replaceAll(Object.keys(pattern)[0], pattern[Object.keys(pattern)[0]]);
    });
    return htmlData;
};

export const setToken = (newToken: string): void => {
    localStorage.setItem('token', newToken);
}

export const getToken = (): string | null => {
    return localStorage.getItem('token');
}

export const getOauthToken = async (retry: boolean = false): Promise<string | null> => {
    const token = getToken();
    if (token && !retry) {
        return token;
    } else {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const parameters = JSON.stringify({
            "UserAuthTokenId": getCookie('authenticationToken')
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: myHeaders,
            body: parameters,
            redirect: 'follow'
        };
        return fetch(GetOauthToken_URL, requestOptions) //fetch token
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    window.location.href = routeName.serviceError;
                }
            })
            .then(data => {
                const tokenValue = data.AuthToken;
                setToken(tokenValue);
                return tokenValue;
            })
            .catch(error => {
                console.error("Failed to fetch authentication token:", error);
                window.location.href = routeName.serviceError;
                return null
            });
    }
}


export const fetchInterceptor = async (url: string, options: RequestInit, avoidOauthToken = false, retryAttempts = 0, abortController?: AbortController): Promise<any> => {
    let modifiedOptions: RequestInit = options;
    if (!avoidOauthToken) {
        const token = getToken();
        let headers = { ...options.headers };
        if (token) {
            const authHeader = { 'Authorization': 'Bearer ' + token };
            headers = { ...headers, ...authHeader };
        }
        modifiedOptions = { ...options, headers };
    }
    return fetch(url, modifiedOptions)  //fetch request
        .then(response => {
            if (!avoidOauthToken && response.status === serverResponse.unauthorized && retryAttempts < retryLimit) {
                return getOauthToken(true)
                    .then(() => fetchInterceptor(url, options, avoidOauthToken, retryAttempts + 1));
            }
            if (validateResponse(response)) {
                return response;
            }
        })
        .catch((error) => {
            fetchRequestError(error, abortController);
        });
};

export const isCompleted = (sectionStatus: string) => {
    return String(sectionStatus) === taskListSectionStatus.completed ? true : false
}

export const isCanNotStartYet = (sectionStatus: string) => {
    return String(sectionStatus) === taskListSectionStatus.cannotStartYet ? true : false
}

export const isNotStarted = (sectionStatus: string) => {
    return String(sectionStatus) === taskListSectionStatus.notStarted ? true : false
}

export const isInProgress = (sectionStatus: string) => {
    return String(sectionStatus) === taskListSectionStatus.inProgress ? true : false
}

export const setSuccessCondition = () => {
    setSessionStorage(dsaContent.eventType, dsaContent.txtSubmit);
}

export const setSessionStorage = (key: string, value: string) => {
    sessionStorage.setItem(key, value);
}

export const setGeneratedBlobUrl = (blobUrl: string) => {
    const linkElement = document.createElement('a');
    linkElement.target = '_blank';
    linkElement.href = blobUrl;
    linkElement.click();
}

export const sanitizeHTML = (html: string) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const elements = doc.body.getElementsByTagName('*');

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const attributes = element.attributes;

        for (let j = attributes.length - 1; j >= 0; j--) {
            const attribute = attributes[j];


            if (attribute.name.startsWith('on')) {
                element.removeAttribute(attribute.name);
            }
        }
    }
    return doc.body.innerHTML;
}

export const handleUploadFile = (e: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<HTMLInputElement>, fileLists: IFileUpload[], fileListsOnChange: File[] | IFileUpload[], setIsUploaded: Dispatch<SetStateAction<boolean>>,
    selectedFile: File[], files: unknown[], setFileLists: Dispatch<SetStateAction<IFileUpload[]>>, setFileListsOnChange: Dispatch<SetStateAction<File[] | IFileUpload | any>>, setIsSelected: Dispatch<SetStateAction<boolean>>, setSelectedFile: any,
    resetError: any, isISO: boolean, isSLSP: boolean, setUploadAnother: Dispatch<SetStateAction<boolean>>, evidenceFileSummary: IEvidenceFileSummary[], setEvidenceFileSummaryList: Dispatch<SetStateAction<IEvidenceFileSummary[]>>, setSelectedFileName: Dispatch<SetStateAction<string>>, fileId: number = 0, applicantComments?: string, fileUploadActive?: number, evidenceType?: number, approvalEvidenceId?: string) => {

    let fileList = [...fileLists];
    let filelistonchange = [...fileListsOnChange];
    setIsUploaded(true);
    if (selectedFile || files.length > 0) {
        {
            files.forEach((file: any) => {
                const blob = new Blob([file]);
                const reader: FileReader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    let base64data = reader.result! as string;
                    base64data = base64data.split(",").pop()!;
                    let saveDocumentType = 0;
                    if (isISO) {
                        saveDocumentType = documentType.ISO
                    }
                    else if (isSLSP) {
                        saveDocumentType = documentType.SLSP
                    }
                    else if (evidenceType) {
                        saveDocumentType = evidenceType
                    }
                    const fileUploads: IFileUpload = ({
                        "ContentType": file.type,
                        "FileId": fileId > 0 ? fileId : 0,
                        "Data": {
                            "_buffer": base64data,
                            "_origin": 0,
                            "_position": 0,
                            "_length": file.size,
                            "_capacity": file.size,
                            "_expandable": false,
                            "_writable": true,
                            "_exposable": false,
                            "_isOpen": true,
                            "__identity": null
                        },
                        "FileName": file.name,
                        "DocumentType": saveDocumentType,
                        "ApplicantComments": applicantComments,
                        "SelectedEvidenceUpload": saveDocumentType,
                        "ApprovalEvidenceID": approvalEvidenceId,
                        "RecentlyModified": true

                    })
                    fileList = Object.assign([], fileList);

                    let oldFileName = "";
                    if (fileUploadActive != undefined && fileList[fileUploadActive] != undefined) {
                        oldFileName = getUploadFileName(fileList[fileUploadActive].FileName!);
                    }
                    
                    if (fileId > 0) {
                        fileList = fileList.map((u: any) => u.FileId !== fileUploads.FileId ? u : fileUploads);
                    }
                    else {
                        fileList[fileUploadActive!] = fileUploads;
                    }

                    let filteredRecords = evidenceFileSummary.filter((x) => x.fileName != oldFileName);
                    evidenceFileSummary = [...filteredRecords, { fileName: getUploadFileName(file.name), mimeType: file.type }];

                    setSelectedFileName(getUploadFileName(file.name));
                    setEvidenceFileSummaryList(evidenceFileSummary);
                    setFileLists(fileList);
                    filelistonchange = Object.assign([], filelistonchange);
                    // filelistonchange.push(fileUploads)
                    filelistonchange[fileUploadActive!] = fileUploads;
                    setFileListsOnChange(filelistonchange as File[]);
                    setIsSelected(true);
                    setIsUploaded(false);
                }
            })
        }
        setSelectedFile('');
        resetError(e);
    }
    setUploadAnother(false);
}

export const urlQueryParameter = (locationString: string) => {
    const urlParams = new URLSearchParams(locationString);
    const userType = urlParams.get("type");
    const appId = urlParams.get("appid");
    const id = urlParams.get("id");
    return { userType, appId, id };
}

export const loadSpinner = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    (event.target as HTMLElement).classList.add("nhsuk-link-spinner", "nhsuk_download_spinner");
}

export const isFetchRequestAborted = (abortController: AbortController) => { return abortController.signal.aborted };

export const fetchRequestError = (error: Error, abortController?: AbortController) => {
    if (error.name === abortError || (abortController && isFetchRequestAborted(abortController))) return;
    console.log('Service Error', error);
    window.location.href = routeName.serviceError;
}

export const setInputFileCustom = (fileEvent: any, fileName: string, fileType: string,) => {
    if (fileEvent) {
        const dataTransfer = new DataTransfer()

        const file = new File([fileName], fileName, { type: fileType })

        dataTransfer.items.add(file)

        fileEvent.files = dataTransfer.files
    }
}



/*File Download*/
export const setFileUrl = (e: any, setSelectedIndex: React.Dispatch<React.SetStateAction<number>>, setshowdownload: React.Dispatch<React.SetStateAction<boolean>>) => {
    e.preventDefault();
    setSelectedIndex(e.currentTarget.id);
    setshowdownload(true);
    e.currentTarget.className += "nhsuk-disableLink";
    downloadfile(e, e.currentTarget.id, setshowdownload);

}
export const downloadfile = (e: any, fileId: any, setshowdownload: React.Dispatch<React.SetStateAction<boolean>>) => {
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const parameters: string = JSON.stringify({
        "UserAuthTokenId": authenticationTokenCookie,
        "FileId": fileId
    });

    const requestOptions: Object = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: parameters
    };

    fetchInterceptor(GetDSAFileById_URL, requestOptions)
        .then(response => {
            return response.json()
        })
        .then(data => {
            if (data && data !== undefined) {
                const b64Data: any = data.Data._buffer;
                const blob: any = getB64toBlobData(b64Data, data.contentType, data.FileName);
                const blobUrl: any = URL.createObjectURL(blob);
                download(blobUrl, data.FileName);
                setshowdownload(false);
                e.target.removeAttribute('class');
            }

        })
        .catch(() => window.location.href = routeName.serviceError);

}
export const download = (path: any, filename: any) => {
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
};

export const getNameForOption = (evidenceTypeValue: String) => {
    const filteredKey = Object.keys(evidenceTypeOptions).find((key) => Number(evidenceTypeValue) === evidenceTypeOptions[key as keyof IEvidenceTypeOptions].value);
    return filteredKey ? evidenceTypeOptions[filteredKey as keyof IEvidenceTypeOptions].name : "";
}
export const enableStatus = (sectionId: Number, taskEnableSectionMultiSelect: IApiResSectionList[]) => {
    const section = taskEnableSectionMultiSelect.find((item) => Number(item.SectionId!) === sectionId!);
    if (section) {
        return section.Enable
    }
    else {
        return false;
    }
}
export const sectionCondition = (sectionId: number, taskEnableSectionMultiSelect: IApiResSectionList[], IsManadatory: boolean) => {
    let result: boolean = true;
    const section = taskEnableSectionMultiSelect.find((item) => Number(item.SectionId!) === sectionId!);
    let condition = IsManadatory ? String(section!.Status) === taskListSectionStatus.completed : ((String(section!.Status) === taskListSectionStatus.completed) || String(section!.Status) === taskListSectionStatus.makeChanges)
    if (section && section.Enable) {
        if (condition) {
            result = true;
        }
        else {
            result = false;
        }
    }
    else {
        result = true;
    }
    return result;
}
export const checkStatusForAmend = (sectionId: number, taskEnableSectionMultiSelect: IApiResSectionList[]) => {
    if (compuslsorySectionsAmend.includes(sectionId)) {
        return sectionCondition(sectionId, taskEnableSectionMultiSelect, true);
    }
    else {
        return sectionCondition(sectionId, taskEnableSectionMultiSelect, false);
    }
}
export const filterEvidenceType = (legalBasesIdentifiableData?: string) => {
    let multiAEArray: number[] = [];
    Object.values(evidenceTypeOptions).forEach((option: EvidenceTypeAttributes, index) => {
        if (option?.legalBases?.filter((value) => value !== undefined && value === Number(legalBasesIdentifiableData)).length! > 0) {
            multiAEArray.push(option.value);
        }
    })
    return multiAEArray;
}

export const getUploadedFiles = async (dsaApplicationData?: string, isFromParticipantPage?: boolean) => {
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    try {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData !== "" ? dsaApplicationData : "",
            "SectionId": isFromParticipantPage ? controllerSection.Participants : controllerSection.aboutData,
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        const response = await fetchInterceptor(GetFileListBySectionId_URL, requestOptions).then(response => {
            return response.json()
        });
        return response;
    }
    catch {
        window.location.href = routeName.serviceError;
    }
}
export const submitProductData = async (dsaApplicationData: IApplication, dataSetData: IDataSet) => {
    try {
        const authenticationTokenCookie: string = getCookie('authenticationToken')!;
        if (dsaApplicationData !== null && dsaApplicationData !== undefined && dsaApplicationData.id !== "" && dataSetData !== null && dataSetData !== undefined) {
            const parameters: string = JSON.stringify({
                "UserAuthTokenId": authenticationTokenCookie,
                "ApplicationId": dsaApplicationData.id,
                "AssociatedProduct":
                {
                    "CRMUID": dataSetData.crmUID,
                    "CRMProductId": dataSetData.crmProductId,
                    "CRMAssociatedProductId": dataSetData.crmAssociatedProductId,
                    "Name": dataSetData.name,
                    "ParentType": ProductGroupTypeOptions.HES,
                    "AggregatedOrRecordLevelData": dataSetData.aggregatedOrRecordLevelData,
                    "SmallNumbersVisibleOrSupressed": String(dataSetData.aggregatedOrRecordLevelData) === String(dataSetLevelOptionTypes.aggregatedLevelValue) ? SmallNumbersVisibleOrSupressed.SmallNumbersNotSupressed : 0,
                    "HowOftenReceiveDataset": dataSetData.futureRelease ? dataSetData.datasetFrequency : frequencyOfDataOptionTypes.oneOffValue,
                    "DatasetTimePeriods": dataSetData.historicAnnual ? dataSetData.historicAnnualDataList : null,
                    "CurrentCRMDatasetTimePeriods": null,
                    "AddedDatasetFields": dataSetData.selectedProductFieldsList?.filter(item => item !== undefined && item !== null && item?.id !== "").length !== 0 ? dataSetData.selectedProductFieldsList : [],
                    "CurrentCRMSelectedDatasetFields": dataSetData.currentCRMSelectedDatasetFields,
                    "CurrentYearToLatestSelected": dataSetData.latestRelease,
                    "MappedAnnualPeriodsList": dataSetData.historicAnnual ? dataSetData.initialHistoricAnnualDataList : dataSetData.existingHistoricAnnualDataList,
                    "ServiceTypeOneOff": dataSetData.serviceTypeOneOff,
                    "ServiceTypeRegularBespoke": dataSetData.serviceTypeRegularBespoke,
                    "ServiceTypeRegularStandard": dataSetData.serviceTypeRegularStandard,
                    "ServiceTypeSystemAccess": dataSetData.serviceTypeSystemAccess,
                    "StandardisationServiceLevelOptionsSelected": (dataSetData.futureRelease && dataSetData.serviceTypeRegularBespoke) ? standardisationServiceLevelOptions?.RegularBespokeValue : (dataSetData.futureRelease && dataSetData.serviceTypeRegularStandard) ? standardisationServiceLevelOptions?.RegularStandardValue : (!dataSetData.futureRelease ? standardisationServiceLevelOptions?.OneOffBespokeValue : 0),
                    "APYFrequency": String(dataSetData.datasetFrequency) === String(frequencyOfDataOptionTypes.monthlyValue) ? aPYfrequencyOfDataOptionTypes.monthlyValue : String(dataSetData.datasetFrequency) === String(frequencyOfDataOptionTypes.annuallyValue) ? aPYfrequencyOfDataOptionTypes.annuallyValue : String(dataSetData.datasetFrequency) === String(frequencyOfDataOptionTypes.quarterlyValue) ? aPYfrequencyOfDataOptionTypes.quarterlyValue : aPYfrequencyOfDataOptionTypes.oneOffValue,
                    "LinkedToCohort": dataSetData.linkedToCohort,
                    "StepsMinimiseDataRequested": dataSetData.stepsMinimiseDataRequested,
                    "DataSetLegalBasisList": dataSetData.dataSetLegalBasesList?.filter(item => item.checked === true),
                    "CurrentDataSetLegalBasisList": dataSetData.currentDataSetLegalBasesList,
                },
                "SectionStatus": dsaApplicationData.aboutDataSetSectionStatus
            });

            const requestOptions: Object = {
                method: 'POST',
                headers: { 'Content-Type': fileTypeSize.fileTypeJson },
                body: parameters
            };
            const response = await fetchInterceptor(SubmitScreenProduct_URL, requestOptions)
                .then(response => {
                    return response.ok
                })
            return response;
        }
    }
    catch {
        window.location.href = routeName.serviceError;
    }
}
export const mappedEvidence = Object.keys(evidenceTypeOptions)
    .sort((a, b) => evidenceTypeOptions[a].name.localeCompare(evidenceTypeOptions[b].name))
    .map((key) => evidenceTypeOptions[key]);


export const checkApplicationChangeType = (applicationChangeType: string, checkChangeTypeArr: string[]) => {
    let checkApplicationChangeTypeArray: string[] = checkChangeTypeArr.toString().split(',');
    return  checkApplicationChangeTypeArray.includes(applicationChangeType);
}

export const disableHistoricAnnualCheckBox = (historicAnnualDataList: IHistoricAnnualData[], existingHistoricAnnualDataList: IHistoricAnnualData[] ) => {
    let showHistoricAnnualCheckBox: boolean = false;
    const alreadyHeldfilteredData = historicAnnualDataList?.filter((items: IHistoricAnnualData) => { return items?.alreadyHeld });
    const filteredData = existingHistoricAnnualDataList?.filter((items: IHistoricAnnualData) => { return !alreadyHeldfilteredData?.includes(items) })
    if (filteredData!.length > 0) {
        showHistoricAnnualCheckBox = false;
    }
    else {
        showHistoricAnnualCheckBox = true;
    }
    return showHistoricAnnualCheckBox;
}

export const calculateDaysDiff = (startDate: string, endDate: string) => {
    var difference_In_Time = new Date(endDate).getTime() - new Date(startDate).getTime();
    var days_difference = difference_In_Time / (1000 * 3600 * 24);
    return days_difference;
}

export const calculateDateInFormat = (date:string)=>{
    return date?.split("/")[1] + "/" + date?.split("/")[0] + "/" + date?.split("/")[2]; 
}

export const checkservicemaintenance = () => {
    return true;
    if (window.location.pathname === routeName.serviceUnavailable) {
        return;
    }
    const authenticationTokenCookie = null;
    document.cookie = `authenticationToken=${authenticationTokenCookie}; expires= ${new Date(Date.now()).toUTCString()};`;
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = routeName.serviceUnavailable;
}

export const isValid = (value: any) => {
    switch (typeof value) {
        case 'undefined':
            return false;
        case 'string':
        case 'number':
        case 'boolean':
            return !!value;
        case 'object':
            return (value instanceof Array) ?
                        ((value === null || value.length === 0) ? false : true) :
                   (value instanceof Date) ?
                        ((value === null || isNaN(value.getTime())) ? false : true) : 
                   (value === null)? false: true;
        default:
            return true;
    }
}

export const getUploadFileName = (value: string) => {
    return isValid(value) ? value.replace(/[^a-zA-Z0-9._ -]/g, "") : "";
}

export const resetSecurityAssuranceState = () => {
    let stateSA: ISecurityAssurance = {};
    stateSA = {
        SAType: "",
        SAId: "",
        ISOVersion: "",
        ISODateComplete: "",
        ISONotes: "",
        SLSPNotes: "",
        SLSPDateComplete: "",
        DSPT_ODS_Code: "",
        DSPT_Latest_Status: "",
        DSPT_Date_Published: "",
        DSPT_Comments: "",
        DSPTDay: "",
        DSPTMonth: "",
        DSPTYear: "",
        SectionType: "",
        ISODay: "",
        ISOMonth: "",
        ISOYear: "",
        SLSPDay: "",
        SLSPMonth: "",
        SLSPYear: "",
        SAIndex: 0
    }
    return stateSA;
}