import React, { useState, useEffect } from "react";
import { Label } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../state/app/hooks";
import { IApiResFiles, IDSFCContractDetails } from "../../../state/models/app.interface";
import { getDSFCContractDetails } from "../../../state/slice/DSFC/DSFCContractDetails.slice";
import Attachment from "../../../Components/Attachment/Attachment";
import SummaryCard from "../../../Components/SummaryCard/SummaryCard";
import { fetchInterceptor, getB64toBlobData, getCookie, getDate } from "../../../Helper/Utility";
import GoBack from "../../../Components/Shared/GoBack";
import { dsaContent, fileTypeSize, routeName } from "../../../config/page.config";
import Loader from "../../../Components/Loader/Loader";
import { DisplayApprovedDSFCFile_URL } from "../../../config/api-endpoints.config";

const DSFCAgree: React.FC = () => {
    const navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!
    const DSFCContractDetailsData: IDSFCContractDetails = useAppSelector(getDSFCContractDetails);
    const initialState: IDSFCContractDetails = DSFCContractDetailsData || {};
    const [dsfcData] = useState(initialState);
    const [pdfURL, setPdfURL]= useState("");
    const [loading, setLoading] = useState(false);

    const getDsfcPDFFile = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "DSFCApplicationId": dsfcData.dsfcApplicationId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeBlob },
            body: parameters
        };

        fetchInterceptor(DisplayApprovedDSFCFile_URL, requestOptions)
            .then(response => {
                    return response.json();
              
            })
            .then((response) => {
                setDsfcPDFFile(response);
                setLoading(false);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setDsfcPDFFile = (response: IApiResFiles) => {
        const contentType: string = fileTypeSize.fileTypePDF;
        const fileName: string = `DSFC_${dsfcData.contractNumber}.pdf`;
        const b64Data: string = response._buffer;
        const blob: Blob = getB64toBlobData(b64Data, contentType, fileName);
        const blobUrl: string = URL.createObjectURL(blob);
        setPdfURL(blobUrl);
    }
    useEffect(() => {
        if (!dsfcData.dsfcapprove) {
            navigate(routeName.dashboard);
        }
        getDsfcPDFFile();
    }, []);

    return (
        <>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <>
                        <div className="nhsuk-grid-column-full">
                            <GoBack/>
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <SummaryCard blue>
                                <SummaryCard.Content>
                                    <SummaryCard.Heading>Contract active</SummaryCard.Heading>
                                    <SummaryCard.Description>
                                        Your reference number
                                        <span className="nhsuk-summarycard__description2">{dsfcData?.contractNumber}</span>
                                    </SummaryCard.Description>
                                </SummaryCard.Content>
                            </SummaryCard>
                                    <Label size="s">You have signed the Data Sharing Framework Contract (DSFC).</Label>
                                    <Label size="s"> It is now active and valid until {getDate(dsfcData?.endDate)}.</Label>
                            <Label size="s"> You can download and view the DSFC below, or you can find it in the 'Data Sharing Framework Contract' section of the service.</Label>
                            <Attachment>
                                <Attachment.Thumbnail>
                                </Attachment.Thumbnail>
                                <Attachment.Details>
                                    <Attachment.Heading>
                                        <p><Attachment.Link href={pdfURL} target="_blank">Data Sharing Framework Contract {dsfcData?.version}</Attachment.Link></p>
                                    </Attachment.Heading>
                                    <Attachment.Content >
                                        <Attachment.Property>PDF</Attachment.Property>,   <Attachment.Property>smaller than 1 mb</Attachment.Property>
                                    </Attachment.Content>
                                </Attachment.Details>
                            </Attachment>
                                </div>
                            </>
                        }
                    </div>
                </main>
            </div>
        </>
    )
}

export default DSFCAgree;