import React, { useEffect, useState } from "react";
import { Label } from "nhsuk-react-components";
import { fetchInterceptor, filterHtml, sanitizeHTML, updatePageTitle, urlQueryParameter } from "../../Helper/Utility";
import { useNavigate } from "react-router-dom";
import { IApiResNewsDetails, INewsDetails } from "../../state/models/app.interface";
import { GetDARSLatestNewsById_URL } from "../../config/api-endpoints.config";
import { fileTypeSize, pageTitles, routeName } from "../../config/page.config";
import GoBack from "../../Components/Shared/GoBack";

const ReadMore: React.FC = () => {
    let navigate = useNavigate();

    const initialState: INewsDetails = {};
    const [readMore, setReadMore] = useState(initialState);
    const [loading, setLoading] = useState(true);

    const getLatestNews = () => {
        setLoading(true);
        const queryString = window.location.search;
        const {id} = urlQueryParameter(queryString);

        const parameters: string = JSON.stringify(id);

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(GetDARSLatestNewsById_URL, requestOptions)
            .then(response => {
                    return response.json()
            })
            .then(data => {
                setRedMoreDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));

    }

    const setRedMoreDetails = (newsDetailsResponse: IApiResNewsDetails) => {
        let updatedState: INewsDetails = {};

        updatedState = {
            ...readMore,
            Content: newsDetailsResponse?.Content ? filterHtml(newsDetailsResponse?.Content) : "",
            Title: newsDetailsResponse.Title
        }
        setReadMore(updatedState);
        setLoading(false);
    }

    useEffect(() => {
        getLatestNews();
        updatePageTitle(pageTitles.latestnews);
    }, []);

    return (
        <>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <div className="nhsuk-grid-column-full">
                                <div className="nhsuk-loader__container">
                                    <h1 className="nhsuk-heading-l">Loading results</h1>
                                </div>
                                <div className="nhsuk-loader__container">
                                    <div className="nhsuk-loader">
                                    </div>
                                </div>
                            </div>
                            : null}
                        {!loading ?
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack/>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                    Updates
                                </span>
                                <h1>{readMore.Title}</h1>
                                <Label
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHTML(readMore.Content!)
                                    }}></Label>
                            </div> : null}
                    </div>
                </main>
            </div>
        </>
    )
}

export default ReadMore;