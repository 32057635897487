import { Button, Label, SummaryList } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader'
import { applicationChangeType, dataControllerTypeOptions, documentType, dsaContent, dsptStatus, entity, fileTypeSize, initialStateOfFileUpload, routeName, saType, taskListSectionStatus } from "../../../../config/page.config";
import { useAppSelector } from "../../../../state/app/hooks";
import { GetDataController_URL, GetDSAFileById_URL } from "../../../../config/api-endpoints.config";
import { IApiResController, IApiResControllerList, IApplication, ISecurityAssurance } from "../../../../state/models/app.interface";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getCookie, submitSpinner, numberToWords, getB64toBlobData, fetchInterceptor, checkApplicationChangeType, resetSecurityAssuranceState } from '../../../../Helper/Utility';
import { IController, IControllerList, IFileUpload } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch } from '../../../../state/app/hooks';
import { getControllerDetails, setControllerDetailsData } from "../../../../state/slice/DSAAboutController/DSAAboutController.slice";
import Loader from "../../../../Components/Loader/Loader";
import { getControllerListDetails, setControllerListDetailsData } from "../../../../state/slice/ControllerList/ControllerList.slice";
import { getSecurityAssuranceDetails, setSecurityAssuranceDetailsData } from "../../../../state/slice/SecurityAssurance/SecurityAssurance.slice";
import GoBack from "../../../../Components/Shared/GoBack";
import ShowAll from "../../../../Components/ShowAll/ShowAll";
/*This page is for retrieve Controller Details and show the data as Summary or Check your answers with edit functionality*/
const ControllerDetails: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData,] = useState(initialApplicationState);

    const controllerListDetailsData: IControllerList = useAppSelector(getControllerListDetails);
    const initialControllerListState: IControllerList = controllerListDetailsData || {};
    const [controllerListData, setControllerListData] = useState(initialControllerListState);
    const [controllerdataList, setcontrollerdataList] = useState<IController[]>();
    const saveControllerListDataInStore = (stateData?: IControllerList) => {
        dispatch(setControllerListDetailsData({ ...controllerListData, ...stateData }));
    }

    const controllerDetailsData: IController = useAppSelector(getControllerDetails);
    const initialState: IController = controllerDetailsData || {};
    const [controllerData, setControllertData] = useState(initialState);
    const [showdownload, setshowdownload] = useState(false);
    const saveControllerDataInStore = (stateData?: IController) => {
        dispatch(setControllerDetailsData({ ...controllerData, ...stateData }));
    }
    const isDirtyController = controllerData.isDirtyController || false;

    const securityAssuranceDetailsData: ISecurityAssurance = useAppSelector(getSecurityAssuranceDetails);
    const initiailSecurityAssuranceState: ISecurityAssurance = securityAssuranceDetailsData || {};
    const [securityAssuranceData, setSecurityAssuranceData] = useState(initiailSecurityAssuranceState);
    const saveSecurityAssuranceDataInStore = (stateData?: ISecurityAssurance) => {
        dispatch(setSecurityAssuranceDetailsData({ ...securityAssuranceData, ...stateData }));
    }

    const [activeControllerIndex, setActiveControllerIndex] = useState(controllerListData?.controllerList?.length || 0)
    const [payingController, setPayingController] = useState(initialState);
    const [isExpender, setIsExpender] = useState(false);
    /*Variable Declarations*/

    useEffect(() => {
        const paycontroller = controllerListData?.controllerList?.find(controller => controller.isPayingForDSA === true) || initialState;
        setPayingController(paycontroller);
    }, [controllerListData]);

    /*Get Set Controller data*/
    const getControllerData = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetDataController_URL, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                setControllerDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setControllerDetails = (response: IApiResControllerList) => {
        let controllerlist: IController[] = [];
        let updatedState: IControllerList = {};

        let dsaControllerDetail: IController = {};
        let dsaControllerDetailList: IController[] = [];

        if (response && response !== undefined && response.DataControllerList !== null) {
            setActiveControllerIndex(response.DataControllerList.length);
            response.DataControllerList.map((dsaController: IApiResController) => {
                let securityAssuranceDetail: ISecurityAssurance = {};
                securityAssuranceDetail = {
                    ...dsaController.SecurityAssurance,
                    SAType: dsaController.SecurityAssurance?.SAType,
                    SAId: dsaController.SecurityAssurance?.SAId,
                    ISOVersion: dsaController.SecurityAssurance?.ISOVersion,
                    ISODateComplete: dsaController.SecurityAssurance?.ISODateComplete,
                    ISONotes: dsaController.SecurityAssurance?.ISONotes,
                    SLSPNotes: dsaController.SecurityAssurance?.SLSPNotes,
                    SLSPDateComplete: dsaController.SecurityAssurance?.SLSPDateComplete,
                    DSPT_ODS_Code: dsaController.SecurityAssurance?.DSPT_ODS_Code,
                    DSPT_Latest_Status: dsaController.SecurityAssurance?.DSPT_Latest_Status,
                    DSPT_Comments: dsaController.SecurityAssurance?.DSPT_Comments,
                    DSPT_Date_Published: dsaController.SecurityAssurance?.DSPT_Date_Published,
                    SectionType: dsaController.SecurityAssurance?.SAType,
                    DSPTDay: dsaController.SecurityAssurance?.DSPT_Date_Published?.split("/")[0] ? dsaController.SecurityAssurance?.DSPT_Date_Published?.split("/")[0] : "",
                    DSPTMonth: dsaController.SecurityAssurance?.DSPT_Date_Published?.split("/")[1] ? dsaController.SecurityAssurance?.DSPT_Date_Published?.split("/")[1] : "",
                    DSPTYear: dsaController.SecurityAssurance?.DSPT_Date_Published?.split("/")[2] ? dsaController.SecurityAssurance?.DSPT_Date_Published?.split("/")[2] : "",
                    ISODay: dsaController.SecurityAssurance?.ISODateComplete?.split("/")[0] ? dsaController.SecurityAssurance.ISODateComplete?.split("/")[0] : "",
                    ISOMonth: dsaController.SecurityAssurance?.ISODateComplete?.split("/")[1] ? dsaController.SecurityAssurance?.ISODateComplete?.split("/")[1] : "",
                    ISOYear: dsaController.SecurityAssurance?.ISODateComplete?.split("/")[2] ? dsaController.SecurityAssurance?.ISODateComplete?.split("/")[2] : "",
                    SLSPDay: dsaController.SecurityAssurance?.SLSPDateComplete?.split("/")[0] ? dsaController.SecurityAssurance?.SLSPDateComplete?.split("/")[0] : "",
                    SLSPMonth: dsaController.SecurityAssurance?.SLSPDateComplete?.split("/")[1] ? dsaController.SecurityAssurance?.SLSPDateComplete?.split("/")[1] : "",
                    SLSPYear: dsaController.SecurityAssurance?.SLSPDateComplete?.split("/")[2] ? dsaController.SecurityAssurance?.SLSPDateComplete?.split("/")[2] : ""
                }
                dsaControllerDetail = {
                    controllerId: dsaController.DataControllerId,
                    isDirtyController: dsaController.IsControlleraProcessorDirty,
                    dpaRegistrationNumber: dsaController.DPARegisteredCode,
                    dpaOrganisationName: dsaController.DPARegisteredName,
                    dpaRegistrationExpiryDate: dsaController.DPARegisteredExpiry,
                    dpaId: dsaController.DpaId,
                    isControllerProcessingData: dsaController.IsControlleraProcessor,
                    controllerProcessingData: dsaController.IsControlleraProcessor ? dsaContent.controllerProcessingTheData : dsaContent.controllerNotProcessingTheData,
                    fileUpload: dsaController.FileList,
                    dsaApplicationId: dsaController.ApplicationId,
                    controllerdataOrgDesc: dsaController.OrganisationName,
                    controllerdataOrgGuidId: dsaController.OrganisationId,
                    isNewController: false,
                    isPayingForDSA: dsaController.IsPayingForDSA,
                    alternativeOrganisationName: dsaController.OrganisationName,
                    alternativeStreet1: dsaController.AddressLine1,
                    alternativeStreet2: dsaController.AddressLine2,
                    alternativeStreet3: dsaController.AddressLine3,
                    alternativeCity: dsaController.TownCity,
                    alternativeCounty: dsaController.County,
                    alternativePostCode: dsaController.Postcode,
                    alternativeCountry: dsaController.Country,
                    dpDay: dsaController.DPARegisteredExpiry?.split("/")[0] ? dsaController.DPARegisteredExpiry?.split("/")[0] : "",
                    dpMonth: dsaController.DPARegisteredExpiry?.split("/")[1] ? dsaController.DPARegisteredExpiry?.split("/")[1] : "",
                    dpYear: dsaController.DPARegisteredExpiry?.split("/")[2] ? dsaController.DPARegisteredExpiry?.split("/")[2] : "",
                    saList: securityAssuranceDetail,
                    evidenceFileSummary: []
                }
                dsaControllerDetailList.push(dsaControllerDetail);
            })

            if (controllerListData.controllerDetailsPage === dsaContent.summaryPageText) {
                controllerlist = Object.assign([], controllerlist);
                controllerlist.push(dsaControllerDetailList[0]);
                setcontrollerdataList(controllerlist);
            }
            else {
                controllerlist = Object.assign([], controllerlist);
                controllerlist = dsaControllerDetailList;
                setcontrollerdataList(controllerlist);
            }
            updatedState = {
                ...controllerListData,
                controllerList: dsaControllerDetailList
            }
            setControllerListData(updatedState);
            saveControllerListDataInStore(updatedState);
        }
        setLoading(false);
    }
    /*Get Set Controller data*/

    /*File Download*/
    const setFileUrl = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setSelectedIndex(Number(e.currentTarget.id));
        setshowdownload(true);
        e.currentTarget.className += "nhsuk-disableLink";
        downloadfile(e, e.currentTarget.id);
    }
    const downloadfile = (e: React.MouseEvent<HTMLAnchorElement>, fileId: string) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "FileId": fileId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(GetDSAFileById_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data && data !== undefined) {
                    const b64Data: string = data.Data._buffer;
                    const blob: Blob = getB64toBlobData(b64Data, data.contentType, data.FileName);
                    const blobUrl: string = URL.createObjectURL(blob);
                    download(blobUrl, data.FileName);
                    setshowdownload(false);
                    let targetElement = e.target as HTMLAnchorElement;
                    targetElement.removeAttribute('class');
                }
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const maxCount = entity.validSave.find(item => {
        return item.controllerDetails?.maxCount
    })?.controllerDetails?.maxCount

    const download = (path: string, filename: string) => {
        const anchor = document.createElement('a');
        anchor.href = path;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };
    /*File Download*/

    /*Navigate to next page*/
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        submitSpinner(e, handleClickNavigate);
    }
    const handleClickNavigate = () => {
        if (!isDirtyController && controllerListData.controllerList?.length === 1) {
            return navigate(routeName.completedControllerSection);
        }
        if (controllerListData.controllerDetailsPage === dsaContent.summaryPageText) {
            if (controllerListData.controllerList && controllerListData.controllerList.length > 1 && isDirtyController) {
                let updatedStateSA = resetSecurityAssuranceState();
                setSecurityAssuranceData(updatedStateSA);
                saveSecurityAssuranceDataInStore(updatedStateSA);

                let updatedState: IController = {};
                updatedState = {
                    controllerId: "",
                    dpaRegistrationNumber: "",
                    dpaOrganisationName: "",
                    dpaRegistrationExpiryDate: "",
                    isControllerProcessingData: false,
                    dpDay: "",
                    dpMonth: "",
                    dpYear: "",
                    fileUpload: [initialStateOfFileUpload],
                    dsaApplicationId: "",
                    contractNumber: "",
                    alternativeOrganisationName: "",
                    alternativeStreet1: "",
                    alternativeStreet2: "",
                    alternativeStreet3: "",
                    alternativeCity: "",
                    alternativeCounty: "",
                    alternativePostCode: "",
                    alternativeCountry: "",
                    controllerdataOrgGuidId: "",
                    controllerdataOrgDesc: "",
                    controllerIndex: 0,
                    dpaId: "",
                    isNewController: true,
                    controllerProcessingData: "",
                    saList: {}
                }
                setControllerDetailsData(updatedState);
                saveControllerDataInStore(updatedState);
                navigate(routeName.controllerData);
            }
            else {
                let updatedState: IControllerList = {
                    ...controllerListData, controllerDetailsPage: dsaContent.checkYourAnswersPageText
                }
                saveControllerListDataInStore(updatedState);
                window.location.reload();
            }
        }
        else if (controllerListData.controllerDetailsPage === dsaContent.checkYourAnswersPageText) {
            if (payingController.controllerdataOrgDesc === "") {
                navigate(routeName.payForThisService)
            }
            else if (isDirtyController || (String(dsaApplicationData.aboutControllerSectionStatus) !== String(taskListSectionStatus.completed))) {
                navigate(routeName.completedControllerSection);
            }
            else {
                navigate(routeName.dsaTaskList);
            }
        }
    }
    /*Navigate to next page*/

    /*Edit page navigation*/
    const handleNavigateAddControllerLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        let updatedStateSA = resetSecurityAssuranceState();
        setSecurityAssuranceData(updatedStateSA);
        saveSecurityAssuranceDataInStore(updatedStateSA);

        let updatedState: IController = {};
        updatedState = {
            controllerId: "",
            dpaRegistrationNumber: "",
            dpaOrganisationName: "",
            dpaRegistrationExpiryDate: "",
            isControllerProcessingData: false,
            dpDay: "",
            dpMonth: "",
            dpYear: "",
            fileUpload: [initialStateOfFileUpload],
            dsaApplicationId: "",
            contractNumber: "",
            alternativeOrganisationName: "",
            alternativeStreet1: "",
            alternativeStreet2: "",
            alternativeStreet3: "",
            alternativeCity: "",
            alternativeCounty: "",
            alternativePostCode: "",
            alternativeCountry: "",
            controllerdataOrgGuidId: "",
            controllerdataOrgDesc: "",
            controllerIndex: 0,
            dpaId: "",
            isNewController: true,
            controllerProcessingData: "",
            saList: {}
        }
        setControllerDetailsData(updatedState);
        saveControllerDataInStore(updatedState);
        navigate(routeName.controllerData);
    }
    const handleClickNavigation = (e: React.MouseEvent<HTMLAnchorElement>, navigatePage: string, controllerIndex: number, controllerDetailsPage: string, saId?: string) => {
        e.preventDefault();
        setContollerData(controllerIndex);
        let updatedState: IControllerList = {};
        updatedState = {
            ...controllerListData,
            activeController: controllerIndex,
            controllerDetailsPage: controllerDetailsPage
        }
        setControllerListData(updatedState);
        saveControllerListDataInStore(updatedState);
        if (saId !== "" && saId !== null && saId !== undefined) {
            let updatedStateSA: ISecurityAssurance = {};
            updatedStateSA = {
                SAId: saId,
                SAIndex: 0
            }
            setSecurityAssuranceData(updatedStateSA);
            saveSecurityAssuranceDataInStore(updatedStateSA);
        }
        navigate(navigatePage);
    }
    const setContollerData = (controllerIndex: number) => {
        let updatedState: IController = {};
        updatedState = controllerListData.controllerList ? controllerListData.controllerList[controllerIndex] : {};
        setControllertData(updatedState);
        saveControllerDataInStore(updatedState);
    }
    /*Edit page navigation*/

    const handlePayControllerChange = () => {
        navigate(routeName.payForThisService);
    }

    const handleExpend = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setIsExpender(isExpender ? false : true)
    }

    useEffect(() => {
        getControllerData();
    }, []);

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {!loading ?
                            <div className="nhsuk-grid-column-two-thirds">
                                {
                                    <GoBack condition={dsaContent.tasklist} />
                                }
                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    About the Controller</span>
                                    {controllerListData.controllerDetailsPage === dsaContent.summaryPageText ? dsaContent.controllerDetailsHeaderSummaryPage : dsaContent.controllerDetailsHeaderCheckYourAnswersPage}
                                </h1>{checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)])?"": (controllerListData.controllerDetailsPage === dsaContent.checkYourAnswersPageText) &&
                                    <SummaryList.Row>
                                        <SummaryList.Actions><Label><a href="#" onClick={handleNavigateAddControllerLink}>Add another controller</a></Label>
                                        </SummaryList.Actions>
                                    </SummaryList.Row>}
                                {
                                    controllerdataList?.map((controllerData: IController, index: number) => (
                                        <SummaryList key={index + 1}>
                                            <SummaryList.Row>
                                                <SummaryList.Key><h3>Controller {controllerListData.controllerDetailsPage === dsaContent.summaryPageText ? activeControllerIndex : index + 1}</h3></SummaryList.Key>
                                                <SummaryList.Value></SummaryList.Value>
                                                <SummaryList.Actions>
                                                    {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal),String(applicationChangeType.Extension)]) ?null:
                                                        <a href="#" onClick={(e) => handleClickNavigation(e, routeName.deleteController, index, controllerListData.controllerDetailsPage!)}>Delete</a>}
                                                </SummaryList.Actions>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key>Organisation acting as Controller {controllerListData.controllerDetailsPage === dsaContent.summaryPageText ? activeControllerIndex : index + 1}</SummaryList.Key>
                                                <SummaryList.Value>
                                                    <p>{controllerData.controllerdataOrgDesc}</p>
                                                    <p>{controllerData.alternativeStreet1}</p>
                                                    <p>{controllerData.alternativeStreet2}</p>
                                                    <p>{controllerData.alternativeStreet3}</p>
                                                    <p>{controllerData.alternativeCity}</p>
                                                    <p>{controllerData.alternativeCounty}</p>
                                                    <p>{controllerData.alternativePostCode}</p>
                                                    <p>{controllerData.alternativeCountry}</p>
                                                </SummaryList.Value>
                                                <SummaryList.Actions>

                                                    {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension), String(applicationChangeType.Amendment)]) ? null :
                                                        <a href="#" onClick={(e) => handleClickNavigation(e, routeName.controllerData, index, controllerListData.controllerDetailsPage!)}>Change</a> }
                                                </SummaryList.Actions>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key>Is this Controller processing the data?</SummaryList.Key>
                                                <SummaryList.Value>{controllerData.isControllerProcessingData ? "Yes" : "No"}</SummaryList.Value>
                                                <SummaryList.Actions>
                                                    {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal),String(applicationChangeType.Extension)]) ?null:
                                                        <a href="#" onClick={(e) => handleClickNavigation(e, routeName.processingData, index, controllerListData.controllerDetailsPage!)}>Change</a>}
                                                </SummaryList.Actions>
                                            </SummaryList.Row>
                                            {/*Set Security Assurance Type*/}

                                            <SummaryList.Row>
                                                <SummaryList.Key>How will you provide security assurances for this Controller?</SummaryList.Key>
                                                <SummaryList.Value>{controllerData.saList?.SAType && saType[controllerData.saList?.SAType]}</SummaryList.Value>
                                                <SummaryList.Actions>
                                                    <a href="#" onClick={(e) => handleClickNavigation(e, routeName.securityAssurance, index, controllerListData.controllerDetailsPage!, controllerData.saList?.SAId)}>Change</a>
                                                </SummaryList.Actions>
                                            </SummaryList.Row>
                                            {/*Set DSPT*/}

                                            <SummaryList.Row>
                                                <SummaryList.Key>{String(controllerData.saList?.SAType) === String(dataControllerTypeOptions.dsptSecurityAssuranceValue) ? "Data Security and Protection Toolkit (DSPT) details" : (String(controllerData.saList?.SAType) === String(dataControllerTypeOptions.isoSecurityAssuranceValue) ? "ISO/IEC 27001 Information Security Management Standard details " : (String(controllerData.saList?.SAType) === String(dataControllerTypeOptions.slspSecurityAssuranceValue) ? "SLSP/System Level Security Policy" : ""))}</SummaryList.Key>
                                                <SummaryList.Value>
                                                    {String(controllerData.saList?.SAType) === String(dataControllerTypeOptions.dsptSecurityAssuranceValue) ?
                                                        <>
                                                            <p>{controllerData.saList?.DSPT_ODS_Code}</p>
                                                            <p>{controllerData.saList?.DSPT_Latest_Status ? dsptStatus[controllerData.saList?.DSPT_Latest_Status] : null} </p>
                                                            <p>Published {controllerData.saList?.DSPT_Date_Published}</p>
                                                        </> : (String(controllerData.saList?.SAType) === String(dataControllerTypeOptions.isoSecurityAssuranceValue) ?
                                                            <>
                                                                <p>{controllerData.saList?.ISOVersion}</p>
                                                                <p>{controllerData.saList?.ISODateComplete} </p>
                                                                <p>
                                                                    Uploaded Certificate </p>
                                                                <p>
                                                                    {
                                                                        controllerData?.fileUpload?.filter(fl => fl.DocumentType === documentType.ISO).map((fl: IFileUpload, index: number) => {
                                                                            return (<div key={index + 1}>
                                                                                <div className="nhsuk-u-margin-top-3">{String(fl?.ApplicantComments)}</div>
                                                                                <a href="#" id={String(fl?.FileId)} onClick={(e) => setFileUrl(e)} > {fl?.FileName}
                                                                                </a>
                                                                                {(showdownload && String(fl?.FileId) === String(selectedIndex)) ?
                                                                                    <span className="nhsuk-loader__container loading-results ">
                                                                                        <span className="nhsuk-loader-file"></span>
                                                                                        Downloading file
                                                                                    </span> : null}
                                                                            </div>)

                                                                        })
                                                                    }
                                                                </p>
                                                            </> : (String(controllerData.saList?.SAType) === String(dataControllerTypeOptions.slspSecurityAssuranceValue) ?
                                                                <>
                                                                    <p>{controllerData.saList?.SLSPDateComplete} </p>
                                                                    <p>Uploaded Certificate</p>

                                                                    <ShowAll
                                                                        uploadedFiles={controllerData?.fileUpload!.length > 0 ? controllerData?.fileUpload?.filter(fl => fl.DocumentType === documentType.SLSP) : undefined as any}
                                                                        showDownload={showdownload}
                                                                        selectedIndex={selectedIndex}
                                                                        isExpender={isExpender}
                                                                        handleExpend={handleExpend}
                                                                        setFileUrl={setFileUrl}
                                                                    />

                                                                </> : null)
                                                        )
                                                    }
                                                </SummaryList.Value>
                                                <SummaryList.Actions>
                                                    <a href="#" onClick={(e) => handleClickNavigation(e,
                                                        String(controllerData.saList?.SAType) === String(dataControllerTypeOptions.dsptSecurityAssuranceValue) ? routeName.dsptDetails : String(controllerData.saList?.SAType) === String(dataControllerTypeOptions.isoSecurityAssuranceValue) ? routeName.dsaIso : String(controllerData.saList?.SAType) === String(dataControllerTypeOptions.slspSecurityAssuranceValue) ? routeName.addAnotherSlsp : '', index, controllerListData.controllerDetailsPage!, controllerData.saList?.SAId)}>Change</a>
                                                </SummaryList.Actions>
                                            </SummaryList.Row>
                                            {/*Set Additional DSPT */}
                                            <SummaryList.Row>
                                                <SummaryList.Key>Data protection registration for the Controller</SummaryList.Key>
                                                <SummaryList.Value><p>{controllerData.dpaRegistrationNumber}</p>
                                                    <p>{controllerData.dpaOrganisationName}</p>
                                                    <p>Expires {controllerData.dpaRegistrationExpiryDate}</p>
                                                </SummaryList.Value>
                                                <SummaryList.Actions>
                                                    <a href="#" onClick={(e) => handleClickNavigation(e, routeName.dataProtectionRegistration, index, controllerListData.controllerDetailsPage!)}>Change</a>
                                                </SummaryList.Actions>
                                            </SummaryList.Row>

                                            {controllerListData.controllerDetailsPage === dsaContent.checkYourAnswersPageText &&
                                                controllerListData.controllerList?.length === index + 1 &&
                                                <>
                                                    <div className="nhsuk-u-padding-bottom-6"></div>
                                                    <div className="nhsuk-u-padding-bottom-6"></div>
                                                    <h4 className="nhsuk-width-maxwidth">For information about all the Controllers</h4>
                                                    <SummaryList.Row>
                                                        <SummaryList.Key>
                                                        </SummaryList.Key>
                                                        <SummaryList.Value>
                                                        </SummaryList.Value>
                                                        <SummaryList.Actions>
                                                        </SummaryList.Actions>
                                                    </SummaryList.Row>
                                                    <SummaryList.Row>
                                                        <SummaryList.Key>Who is going to pay</SummaryList.Key>
                                                        <SummaryList.Value>
                                                            {payingController.controllerdataOrgDesc}
                                                        </SummaryList.Value>
                                                        <SummaryList.Actions>
                                                            {controllerListData.controllerList.length > 1 && <a href="" onClick={() => handlePayControllerChange()}>Change</a>}
                                                        </SummaryList.Actions>
                                                    </SummaryList.Row>
                                                </>}
                                        </SummaryList>
                                    )
                                    )
                                }
                                <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>
                            :
                            <Loader loadingText={dsaContent.txtLoading} />
                        }
                    </div>
                </main>
            </div>

        </>
    );
}
export default ControllerDetails;
