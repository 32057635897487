import React, { HTMLProps, useContext } from 'react';
import classNames from 'classnames';
import SummaryCardContext from './SummaryCardContext';

const SummaryCardContent: React.FC<HTMLProps<HTMLDivElement>> = ({ className, ...rest }) => {
  const { feature } = useContext(SummaryCardContext);
  return (
    <div
      className={classNames(
        'nhsuk-summarycard__content',
        { 'nhsuk-card__heading--feature': feature },
        className,
      )}
      {...rest}
      
    />
  );
};

export default SummaryCardContent;