import React, { useEffect, useState } from "react";
import { BodyText, Button, Checkboxes, ErrorSummary, Label, SummaryList } from "nhsuk-react-components";
import { Link, useNavigate } from "react-router-dom";
import { AppDispatch } from '../../../state/app/store';
import { useAppSelector, useAppDispatch } from "../../../state/app/hooks";
import { IDSFCContractDetails } from "../../../state/models/app.interface";
import { getDSFCContractDetails, setDSFCContractDetailsData } from "../../../state/slice/DSFC/DSFCContractDetails.slice";
import { SubmitDSFC_URL } from "../../../config/api-endpoints.config";
import { getCookie, submitSpinner, fetchInterceptor } from "../../../Helper/Utility";
import { dsfcContent, dsfcErrorMessage, dsfcLinks, fileTypeSize, routeName } from "../../../config/page.config";
import "../../../Content/css/nhsuk-common.css";
import InfoHeaderPanel from "../../../Components/InfoHeaderPanel/InfoHeaderPanel";
import GoBack from "../../../Components/Shared/GoBack";

const DSFCPreview: React.FC = () => {
    const navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!
    const DSFCContractDetailsData: IDSFCContractDetails = useAppSelector(getDSFCContractDetails);
    const initialState: IDSFCContractDetails = DSFCContractDetailsData || {};
    const [dsfcData, setDSFCData] = useState(initialState);
    const dispatch: AppDispatch = useAppDispatch();
    const savedataInStore = (stateData?: IDSFCContractDetails) => {
        dispatch(setDSFCContractDetailsData({ ...dsfcData, ...stateData }));
    }

    const [showButtonSubmit, setShowButtonSubmit] = useState(true);
    const [showButtonClose, setShowButtonClose] = useState(true);
    const [isConfirmChecked, setIsConfirmChecked] = useState("");
    const [confirmError, setConfirmError] = useState("");

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLInputElement;
        if (isConfirmChecked === dsfcContent.yes) {
            setConfirmError("");
            if (target.name !== "undefined" && target.name !== null && target.name === dsfcContent.close) {
                setShowButtonSubmit(false);
            }
            else if (target.name !== "undefined" && target.name !== null && target.name === dsfcContent.submit) {
                setShowButtonClose(false);
            }
            submitSpinner(e, submitPreview);
            submitDSFC();
        }
        else {
            setConfirmError(`${dsfcErrorMessage.dsfcConfirm}`);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const submitPreview = () => {
        let updatedState: IDSFCContractDetails = {};
        updatedState = { ...dsfcData, dsfcpreview: true, dsfcsubmit: true }
        setDSFCData(updatedState);
        savedataInStore(updatedState);
    }

    const handleChange = () => {
        if (isConfirmChecked === "" || isConfirmChecked === dsfcContent.no) {
            setIsConfirmChecked(dsfcContent.yes);
            setConfirmError("");
        }
        else if (isConfirmChecked === "" || isConfirmChecked === dsfcContent.yes) {
            setIsConfirmChecked(dsfcContent.no);
        }
    }

    const submitDSFC = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "DSFCApplicationId": dsfcData.dsfcApplicationId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitDSFC_URL, requestOptions)
            .then(() => {
                navigate(routeName.dsfcSubmitContract);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsfcErrorMessage.summaryTitle_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <p>{dsfcErrorMessage.dsfcSummaryBody_ErrorMessage}</p>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#confirm">
                            {dsfcErrorMessage.dsfcLink_ErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    useEffect(() => {
        if (!dsfcData.dsfcsignatory || dsfcData.dsfcsubmit) {
            navigate(routeName.dashboard);
        }
    }, []);

    return (
        <>
            <InfoHeaderPanel>
                <InfoHeaderPanel.Item >
                    <strong>Reference number</strong> : {dsfcData?.contractNumber}
                </InfoHeaderPanel.Item>
            </InfoHeaderPanel>

            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-full">
                            <GoBack/>
                            <h1>Apply  for a Data Sharing Framework Contract</h1>
                            {confirmError !== "" ? errorSummary : null}
                            <SummaryList>
                                <SummaryList.Row>
                                    <SummaryList.Key> <Label size="m">Contract details</Label></SummaryList.Key>
                                    <SummaryList.Value></SummaryList.Value>
                                    <SummaryList.Actions></SummaryList.Actions>
                                </SummaryList.Row>
                                <SummaryList.Row>
                                    <SummaryList.Key>Reference number</SummaryList.Key>
                                    <SummaryList.Value>{dsfcData?.contractNumber}</SummaryList.Value>
                                    <SummaryList.Actions></SummaryList.Actions>
                                </SummaryList.Row>
                                <SummaryList.Row>
                                    <SummaryList.Key>Contract version</SummaryList.Key>
                                    <SummaryList.Value>{dsfcData?.editionName}</SummaryList.Value>
                                    <SummaryList.Actions>
                                        <a href={dsfcLinks.dsfcTemplate}>View</a>
                                    </SummaryList.Actions>
                                </SummaryList.Row>
                                <SummaryList.Row>
                                    <SummaryList.Key>Duration</SummaryList.Key>
                                    <SummaryList.Value>
                                        <BodyText>{dsfcData?.contractDuration}</BodyText>
                                    </SummaryList.Value>
                                    <SummaryList.Actions></SummaryList.Actions>
                                </SummaryList.Row>
                                <SummaryList>
                                </SummaryList>
                                <SummaryList.Row>
                                    <SummaryList.Key><Label size="m">Organisation details</Label></SummaryList.Key>
                                    <SummaryList.Value></SummaryList.Value>
                                    <SummaryList.Actions></SummaryList.Actions>
                                </SummaryList.Row>
                                <SummaryList.Row >
                                    <SummaryList.Key className="nhsuk-remove-border">Organisation name</SummaryList.Key>
                                    <SummaryList.Value className="nhsuk-remove-border">
                                        {dsfcData?.alternativeOrganisationName ? <>{dsfcData.alternativeOrganisationName} <br></br></> :
                                            dsfcData?.organisation ? <>{dsfcData.organisation} <br></br></> : null}</SummaryList.Value>                                    
                                    <SummaryList.Actions className="nhsuk-remove-border">
                                        <a href="#" onClick={() => (navigate(routeName.dsfcDetails, { state: { dsfcPreviewChange: true } }))}>Change</a>
                                    </SummaryList.Actions>
                                </SummaryList.Row>
                                <SummaryList.Row >
                                    <SummaryList.Key>Address</SummaryList.Key>
                                    {dsfcData.companiesHouseICO?.toLowerCase() == dsfcContent.yes ?
                                        <SummaryList.Value>
                                            {dsfcData?.organisationAddress ? <> {dsfcData?.organisationAddress.split('\n').map((str, index) => <div key={index + 1}>{str}</div>)} </> : null}
                                        </SummaryList.Value>
                                        :
                                        <SummaryList.Value>
                                            <div>
                                                {dsfcData?.alternativeStreet1}
                                            </div>
                                            <div>
                                                {dsfcData?.alternativeStreet2}
                                            </div>
                                            <div>
                                                {dsfcData?.alternativeStreet3}
                                            </div>
                                            <div>
                                                {dsfcData?.alternativeCity}
                                            </div>
                                            <div>
                                                {dsfcData?.alternativeCounty}
                                            </div>
                                            <div>
                                                {dsfcData?.alternativePostCode}
                                            </div>
                                            <div>
                                                {dsfcData?.alternativeCountry}
                                            </div>
                                        </SummaryList.Value>                                       
                                    }
                                    <SummaryList.Actions>
                                        
                                    </SummaryList.Actions>
                                </SummaryList.Row>
                                <SummaryList.Row>
                                    <SummaryList.Key>Signing the contract</SummaryList.Key>
                                    <SummaryList.Value>{dsfcData?.fullName}</SummaryList.Value>
                                    {dsfcData.signatoryDetalis?.length === 1 ? null :
                                        <SummaryList.Actions>
                                            <Link to={routeName.dsfcSignatory}>Change</Link>
                                        </SummaryList.Actions>}
                                </SummaryList.Row>
                            </SummaryList>
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <Label size="m">Submit application to NHS England</Label>
                            <Label size="s">
                                I confirm that the information within this application is true and complete.
                                I understand that any omission or misrepresentation within the application may lead
                                to its rejection or cancellation of any resulting agreement.
                            </Label>
                            <Checkboxes name="confirm" id="confirm" error={confirmError}>
                                <Checkboxes.Box value={isConfirmChecked} checked={isConfirmChecked === dsfcContent.yes ? true : false} onChange={handleChange}>Confirm</Checkboxes.Box>
                            </Checkboxes>
                            {showButtonSubmit ?
                                <>
                                    <Button name="submit" onClick={handleClick} id="nhsuk-button-spinner">{dsfcContent.btnTextSubmit}</Button>
                                </>
                                : null}
                           
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default DSFCPreview;