import { Input } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { IAutoComplete, IAutoCompleteData, IPostOrganization } from "../../state/models/app.interface";
import { OrganisationLookup_URL } from "../../config/api-endpoints.config";
import { fetchInterceptor, getCookie } from "../../Helper/Utility";
import { useNavigate } from "react-router-dom";
import { fileTypeSize, routeName } from "../../config/page.config";
import useDebounce from "../../Hooks/useDebounce";



interface AutocompleteTextboxProps {
    onSelectSuggestion: (suggestion: IAutoComplete | IPostOrganization) => void,
    id?: string,
    name?: string,
    errorMessage?: string,
    hint?: string,
    label?: string,
    userInputValue?: string,
    onKeyChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

const AutoComplete: React.FC<AutocompleteTextboxProps> = (props: AutocompleteTextboxProps) => {
    const navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [organizationList, setOrganizationList] = useState<IPostOrganization[]>([]);

    const [autoCompleteErrorMsg, setAutoCompleteErrorMsg] = useState("nhsuk-autocomplete-items");
    const [filteredSuggestions, setFilteredSuggestions] = useState<IAutoComplete[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [isfromList, setIsfromList] = useState(false);


    const [showSpinner, setShowSpinner] = useState("");
    const [iconContainerSpinner,] = useState("nhsuk-icon_container-spinner");
    let activeSuggestion: number = -1;

    let suggestionsListComponent: JSX.Element = <></>;

    let suggestions = organizationList;
    const [userInput, setUserInput] = useState(props.userInputValue);
    const [userQuery, setuserQuery] = useState("");

    const getOrganisationData = async () => {
        try {
                setShowSuggestions(false);
                setShowSpinner("nhsuk-loader-three");
                const parameters: string = JSON.stringify({
                    "UserAuthTokenId": authenticationTokenCookie,
                    "SearchString": userQuery
                });
                const requestOptions: Object = {
                    method: 'POST',
                    headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
                    body: parameters
                };
                const response = await fetchInterceptor(OrganisationLookup_URL, requestOptions).then(response => {
                    setShowSpinner("");
                    return response.json()
                });
                const data = await response;
                if (data) {
                    setShowSuggestions(false);
                    setOrganizationList(data);
                    suggestions = data;
                    bindSuggestionList();
                }
        }
        catch {
            navigate(routeName.serviceError);
        }
    };

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowSuggestions(false);
        activeSuggestion = -1;
        setUserInput(e.target.value);
        setuserQuery(e.target.value);
        setShowSpinner("");
        setFilteredSuggestions([]);
        setIsfromList(false);
        if (props.errorMessage !== "") { setAutoCompleteErrorMsg("nhsuk-autocomplete-items nhsuk-form-group--error") } else { setAutoCompleteErrorMsg("nhsuk-autocomplete-items") }
        props.onKeyChange(e);
    };

    const searchQuery = useDebounce(userQuery);
    useEffect(() => {
        if (userQuery.length >= 3 && searchQuery) {
            getOrganisationData();
        }
    }, [searchQuery])

    const onFocusOut = (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault();
        let isOrganizationSet = false;
        if (!isfromList) {
            let selectedOrgDetails = [];
            if (suggestions !== undefined && suggestions !== null) {
                selectedOrgDetails = suggestions?.filter((post: IPostOrganization) => {
                    if (post.Name === userInput) {
                        return post?.Name
                    }
                });
                let newSuggestionArray: React.SetStateAction<IAutoComplete[]> = [];
                setFilteredSuggestions(newSuggestionArray);
                setShowSpinner("");
                if (selectedOrgDetails.length > 0) {
                    setUserInput(selectedOrgDetails[0]?.Name);
                    props.onSelectSuggestion(selectedOrgDetails[0]);
                    isOrganizationSet = true;
                }
                setShowSuggestions(false);
            }
        }
        if (!isOrganizationSet && !isfromList)
            resetOrganizationValue();
    }

    const resetOrganizationValue = () => {
        setUserInput('');
        props.onSelectSuggestion({ id: "", name:"" })
        setShowSuggestions(false);
    }

    const bindSuggestionList = () => {
        if (suggestions !== null && suggestions !== undefined) {
            const filteredSuggestions = suggestions.map((suggestion: IAutoCompleteData) => {
                const getListData: IAutoComplete = { id: suggestion?.Id!, name: suggestion.Name! };
                return getListData;
            });
            setFilteredSuggestions(filteredSuggestions);
            setShowSuggestions(true);
        }
        setShowSpinner("");
    }

    const handleOnselect = (e: React.MouseEvent<HTMLElement>) => {
        if (suggestions !== undefined && suggestions !== null) {
            const selectedOrgDetails = suggestions?.filter((post: IAutoCompleteData) => {
                if (post.Id === e.currentTarget.id) {
                    return post?.Name
                }
            });
            setFilteredSuggestions([]);
            setShowSuggestions(false);
            setShowSpinner("");
            setUserInput(selectedOrgDetails[0]?.Name);
            props.onSelectSuggestion(selectedOrgDetails[0]);
        }
    };

    function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (filteredSuggestions !== undefined && filteredSuggestions !== null && filteredSuggestions.length) {
            if (e.keyCode === 13) {
                setShowSuggestions(false);
                props.onSelectSuggestion(filteredSuggestions[activeSuggestion]);
                setUserInput(filteredSuggestions[activeSuggestion].name);
            }
            // User pressed the up arrow
            else if (e.keyCode === 38) {
                activeSuggestion--;
                addActiveonElement();
            }
            // User pressed the down arrow
            else if (e.keyCode === 40) {
                if (filteredSuggestions !== undefined || filteredSuggestions !== null) {
                    activeSuggestion++;
                    addActiveonElement();
                }
            }
        }
    };

    const removeActiveElement = (elements: HTMLCollection) => {
        for (let j = 0; j < elements.length; j++) {
            elements[j].classList.remove("nhsuk-autocomplete-active");
        }
    }

    function addActiveonElement() {
        let autopupulatenameList: HTMLElement = document.getElementById("myInputautocomplete-list")!;
        if (autopupulatenameList !== undefined && autopupulatenameList !== null) {
            let childlist = autopupulatenameList.getElementsByTagName("div");
            if (childlist !== undefined && childlist !== null && childlist.length > 0) {
                removeActiveElement(childlist);
                if (activeSuggestion >= childlist.length) { activeSuggestion = 0 }
                if (activeSuggestion < 0) { activeSuggestion = childlist.length - 1 };
                childlist[activeSuggestion].classList.add("nhsuk-autocomplete-active");
            }
        }
    }

    if (showSuggestions && userInput) {
        if (filteredSuggestions !== undefined && filteredSuggestions !== null) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <><div id="myInputautocomplete-list" className={autoCompleteErrorMsg}>

                        {filteredSuggestions.map((suggestion: IAutoComplete, index) => {
                            return (
                                <div onClick={handleOnselect} onMouseDown={() => setIsfromList(true)} id={suggestion.id} key={index} >
                                    {suggestion.name}
                                </div>
                            );
                        })}
                    </div>
                        <div className="nhsuk-u-padding-bottom-4"></div></>
                );
            }
            else if (filteredSuggestions.length === 0) {
                setShowSpinner("");
            }
        }
    }

    return (
        <>
            <div className="nhsuk-input_container">
                <Input autoComplete="off" label={props.label} id={props.id} name={props.name} error={props.errorMessage} hint={props.hint} onChange={onChange} onKeyDown={handleOnKeyDown} value={userInput} onBlur={onFocusOut} />
                <div className={iconContainerSpinner} >
                    <div className={showSpinner}>
                    </div>
                </div>
            </div>
            {suggestionsListComponent}
        </>
    )
}

export default AutoComplete;
