import { useNavigate } from 'react-router-dom';
import ContainerTemplate from '../../Components/Container/ContainerTemplate';
import AddAnotherRecord from '../../Components/Shared/AddAnotherRecord';
import { fetchInterceptor, getCookie, getUploadedFiles, submitDSASectionStatus, submitSpinner, } from '../../Helper/Utility';
import { GetFileListBySectionId_URL, SubmitScreenEvidenceCreate_URL } from '../../config/api-endpoints.config';
import { amendRenewExtendValue, buttonText, controllerSection, dsaContent, fileTypeSize, initialStateOfFileUpload, routeName, taskListSectionStatus } from '../../config/page.config';
import { useAppDispatch, useAppSelector } from '../../state/app/hooks';
import { IAPiResParticipantDetails, IApplication, IDeleteDocument, IEvidenceList, IParticipant } from '../../state/models/app.interface';
import { getDSADetails } from '../../state/slice/DSA/DSA.slice';
import { AppDispatch } from '../../state/app/store';
import { useEffect, useState } from 'react';
import { getParticipantDetails, setParticipantDetailsData } from '../../state/slice/Participant/Participant.slice';
import { getEvidenceList, setEvidenceListData } from '../../state/slice/EvidenceList/EvidenceList.slice';
import { getDataSetDetails } from '../../state/slice/DataSet/DataSet.slice';
import { getDeleteDocumentData, setDeleteDocumentData } from '../../state/slice/shared/DeleteDocument/DeleteDocument.slice';

const AddAnotherFileRecord: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();

    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData] = useState(dsaApplicationDetails || {});

    const participantDetailsData: IParticipant = useAppSelector(getParticipantDetails);
    const initialState: IParticipant = participantDetailsData || {};
    const [participantData, setParticipantData] = useState(initialState);

    const { evidenceFileSummary, fileUploadConsent, fileUploadActive }: IParticipant = useAppSelector(getParticipantDetails); //participantData
    const evidenceList: IEvidenceList = useAppSelector(getEvidenceList);
    const evidenceinitialState: IEvidenceList = evidenceList || {};
    const [evidenceListDetails, setEvidenceListDetails] = useState(evidenceinitialState);


    const saveParticipantDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantData, ...stateData }));
    }

    const [recentlyAddedConsentId] = useState(participantData?.recentlyAddedConsentId != '' ? participantData?.recentlyAddedConsentId : '' || '')
    const dataSetData = useAppSelector(getDataSetDetails) || {};
    const [isFromDataSetPage] = useState(dataSetData?.legalBasesIdentifiableData ? true : false || false);
    const [isFromParticipantPage] = useState(participantData?.isFromParticipant ? true : false);
    const [isFromParticipantSummaryPage] = useState<boolean>(participantData?.participantcheckYourAnswerPage! !== '' && !participantData?.participantcheckYourAnswerPage);
    const { fileIndex, isDeleted } = useAppSelector(getDeleteDocumentData);
    const [evidenceListResult] = useState([evidenceList]);

    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantData, ...stateData }));
    }
    const saveEvidenceDataInStore = (stateData?: IEvidenceList) => {
        dispatch(setEvidenceListData({ ...evidenceListDetails, ...stateData }));
    }

    const saveDeleteDocumentInStore = (stateData: IDeleteDocument) => {
        dispatch(setDeleteDocumentData(stateData));
    };

    const handleContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        submitSpinner(e, handleSubmit);
    };

    const handleSubmit = () => {
        submitEvidence();
    }
    const submitEvidence = () => {
        const recentlyUploaded = fileUploadConsent!.filter(file => file?.RecentlyModified! === true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "Id": recentlyAddedConsentId !== '' ? recentlyAddedConsentId : '',
            "FileList": recentlyUploaded,
            "SectionId": isFromParticipantPage ? controllerSection.Participants : isFromDataSetPage ? controllerSection.aboutData : controllerSection.ListOfUploadedDocuments,
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenEvidenceCreate_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(() => {
                handleClickNavigate();
            })
            .catch(error => console.log('Error Reading data', error));
    }

    const handleClickNavigate = async () => {
        getUploadedFiles().then((data) => {
            setUploadedDetails(data);
            if (isFromParticipantPage) {
                if (participantData.participantcheckYourAnswerPage === dsaContent.checkYourAnswersPageText)
                    navigate(routeName.participantSummary);
                else
                    navigate(routeName.linkingParticipant);
            }
            else if (isFromDataSetPage) {
                navigate(routeName.addedAllDataSet);
            }
            else {
                let UpdateSectionStatus = evidenceListResult.length === 0 ? taskListSectionStatus.inProgress : taskListSectionStatus.completed
                submitDSASectionStatus(dsaApplicationData?.id!, controllerSection.ListOfUploadedDocuments, UpdateSectionStatus)
                    .then((data: Response) => {
                        if (data) {
                            let updateState: IEvidenceList = {}
                            updateState = {
                                ...evidenceListDetails,
                                aboutEvidenceSectionStatus: taskListSectionStatus.completed
                            }
                            setEvidenceListDetails(updateState);
                            saveEvidenceDataInStore(updateState);
                            if (dsaApplicationData.returningJourneyType === amendRenewExtendValue.amend)
                                navigate(routeName.dsaTaskList);
                        }
                    });
            }
        });

    }


    const getUploadedFiles = async () => {
        const authenticationTokenCookie: string = getCookie('authenticationToken')!;
        try {
            const parameters: string = JSON.stringify({
                "UserAuthTokenId": authenticationTokenCookie,
                "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
                "SectionId": isFromParticipantPage ? controllerSection.Participants : controllerSection.aboutData,
            });
            const requestOptions: Object = {
                method: 'POST',
                headers: { 'Content-Type': fileTypeSize.fileTypeJson },
                body: parameters
            };
            const response = await fetchInterceptor(GetFileListBySectionId_URL, requestOptions).then(response => {
                return response.json()
            });
            return response;
        }
        catch {
            window.location.href = routeName.serviceError;
        }
    }

    const deleteRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
        e.preventDefault();
        const documentData: IDeleteDocument = {
            fileIndex: index,
            fileId: fileUploadConsent![index].FileId!,
            approvalEvidenceID: fileUploadConsent![index].ApprovalEvidenceID!,
            isDeleted: false,
        };
        saveDeleteDocumentInStore(documentData);
        navigate(routeName.deleteDocument);
    };

    const editRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
        e.preventDefault();
        let updatedState: IParticipant = {};
        updatedState = {
            fileUploadActive: index,
            temporaryFileList: participantData.fileUploadConsent,
            isFromAddAnotheFileRecord: true
        };
        saveDataInStore(updatedState);
        navigate(routeName.uploadConsent);
    };

    const addAnotherRecord = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
        e.preventDefault();
        let updatedState: IParticipant = {};
        updatedState = {
            fileUploadActive: fileUploadConsent!.length,
            temporaryFileList: participantData.fileUploadConsent,
            isFromAddAnotheFileRecord: true
        };
        saveDataInStore(updatedState);
        navigate(routeName.uploadConsent);
    };
    useEffect(() => {
        let updatedState: IParticipant = {};
        updatedState = {
            ...participantData,
            temporaryFileList: [initialStateOfFileUpload],
            isFromAddAnotheFileRecord: false
        };
        setParticipantData(updatedState);
        saveDataInStore(updatedState);
    }, [])
    useEffect(() => {
        if (isDeleted) {
            const documentData: IDeleteDocument = {
                isDeleted: false,
            };
            saveDeleteDocumentInStore(documentData);

            let updatedDocData = [...fileUploadConsent!];
            var oldFileName = updatedDocData[fileIndex!].FileName;
            updatedDocData.splice(fileIndex!, 1);
            let updatedState: IParticipant = {};
            updatedState = {
                fileUploadConsent: updatedDocData,
                fileUploadActive: fileUploadConsent?.length === 1 ? 0 : fileUploadActive,
                evidenceFileSummary: evidenceFileSummary?.filter((x) => x.fileName !== oldFileName)
            };
            setParticipantData(updatedState);
            saveDataInStore(updatedState);
            if (updatedDocData.length === 0) {
                navigate(routeName.uploadConsent)
            }
        }
    }, [isDeleted]);

    const goBackCondition = () => {
        return isFromDataSetPage ? (String(evidenceListDetails.isAddAnotherAE) === String(dsaContent.no) ? 'approval-evidence-data' : 'uploadConsent') : isFromParticipantSummaryPage ? 'participant-summary' : '';
    }

    const setUploadedDetails = (response: IAPiResParticipantDetails) => {
        if (response && response !== undefined) {
            let updatedState: IParticipant = {};
            updatedState.fileUploadConsent = response.FileList;
            setParticipantDetailsData(updatedState)
            saveParticipantDataInStore(updatedState);
        }

    }

    return (
        <ContainerTemplate
            title={isFromParticipantPage ? "About the participants" : ""}
            heading={'Uploaded document(s)'}
            actionButtonText={buttonText.btnSaveAndContinue}
            goBackCondition={goBackCondition()}
            onActionButtonClick={handleContinue}
        >
            <></>
            <AddAnotherRecord
                linkTo={routeName.addedAllDataSet}
                isfromPage={isFromDataSetPage! || isFromParticipantPage! || isFromParticipantSummaryPage!}
                linkText="Add another document"
                records={fileUploadConsent!}
                summaryData={(isFromParticipantPage || isFromDataSetPage) ?
                    "If you need to upload another document, select 'Add another document'."
                    : "If you need to upload another document, select 'Add another document'."
                }
                deleteRecord={deleteRecord}
                editRecord={editRecord}
                addAnotherRecord={addAnotherRecord}
            />
        </ContainerTemplate>
    );
};

export default AddAnotherFileRecord;
