import React, { HTMLProps, useContext } from 'react';
import classNames from 'classnames';
import HeadingLevel, { HeadingLevelType } from './HeadingLevel';
import SummaryCardContext from "./SummaryCardContext";
import "./SummaryCard.css";

interface SummaryCardHeadingProps extends HTMLProps<HTMLHeadingElement> {
  headingLevel?: HeadingLevelType; 
}

const SummaryCardHeading: React.FC<SummaryCardHeadingProps> = ({ className, ...rest }) => {
  const { feature } = useContext(SummaryCardContext);
  return (
    <HeadingLevel
      className={classNames(
        'nhsuk-card__heading',
        { 'nhsuk-card__heading--feature': feature },        
        className,
      )}
      {...rest}
    />
  );
};

SummaryCardHeading.defaultProps = {
  headingLevel: 'h2',
};

export default SummaryCardHeading;