import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialStateOfFileUpload } from '../../../config/page.config'
import type { RootState } from '../../app/store'
import { IFunding, IFundingList, IFileUpload } from '../../models/app.interface'



const initialStatefunding: IFunding = {
    fundingType:"",
    fileUpload: [initialStateOfFileUpload],
    fundingAwardingInstruction:"",
    referenceTitle:"",
    yearOfSubmissionDay:"",
    yearOfSubmissionMonth:"",
    yearOfSubmissionYear: "",
    aboutFundingSectionStatus:"",
    IsFundingSourceDirty: false,
    IsFundingFileUploadDirty: false,
    sourceId:"",
    isFundingInfoDirty: false,
    isUploadedSummaryPage: false,
    comments: "",
    temporaryFileList: [initialStateOfFileUpload],
    fileUploadActive: 0,
    evidenceFileSummary: []
}
const initialState: IFundingList = {
    activefundingData: 0,
    fundingList: [initialStatefunding],
    aboutFundingSectionStatus: "",
    isFromFundingSummary: false
}


export const FundingListSlice = createSlice({
    name: 'FundingList',
    initialState,
    reducers: {
        setFundingListDetailsData: (state: IFundingList, action: PayloadAction<IFundingList>) => {
            state = Object.assign(state, action.payload);
        },
        resetFundingListDetailsData: (state: IFundingList, action: PayloadAction<IFundingList>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setFundingListDetailsData, resetFundingListDetailsData } = FundingListSlice.actions;
export const getFundingListDetails = (state: RootState) => state.fundingListDetails;
export default FundingListSlice.reducer;