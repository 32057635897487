import React, { useState } from "react";
import { Button, ErrorSummary, InsetText, Select } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IParticipant, IApplication } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getParticipantDetails, setParticipantDetailsData } from "../../../../state/slice/Participant/Participant.slice";
import { participantErrorMessage, dsaContent, dsaErrorMessage, routeName, fileTypeSize } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { SubmitScreenCohortType_URL } from "../../../../config/api-endpoints.config";

const ExistingType: React.FC = () => {
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [participantsErrorMessage, setParticipantErrorMessage] = useState("");
    const participantDetailsData: IParticipant = useAppSelector(getParticipantDetails);
    const initialState: IParticipant = participantDetailsData || {};
    const [participantData, setParticipantData] = useState(initialState);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);
    const [isFromAnswerPage] = useState(participantData?.participantcheckYourAnswerPage);
    /* To dispatch the setParticipantDetailsData Redux action */

    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantData, ...stateData }));
    }

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let updatedState: IParticipant = {};
        if (e.target.value !== null && e.target.value !== undefined) {
            updatedState = { ...participantData }
            updatedState.refIfUsingAnotherExistingAgreement = e.target.value;
            resetError(e);
            setParticipantData(updatedState);
            saveDataInStore(updatedState);
        }
    }
    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setParticipantErrorMessage("");
        }
    }

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#participantTypes">{summaryLinkErrorMessage}</ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    /* To Validate the each field's data */
    const ValidateFields = () => {
        let isValid: boolean = true;
        if (participantData.refIfUsingAnotherExistingAgreement === '' || participantData.refIfUsingAnotherExistingAgreement == null) {
            setSummaryLinkErrorMessage(`${participantErrorMessage.existingType_ErrorMessage}`);
            setParticipantErrorMessage(`${participantErrorMessage.existingType_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, SubmitScreenCohortType);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    const checkExistingType = () => {
        const isexistingType = participantData?.existingAgreementUploadItems!.length > 0 ? true : false;
        return isexistingType;
    }
    const SubmitScreenCohortType = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "Cohort": true,
            "TypeOfCohort": participantData.participantTypes,
            "RefIfUsingAnotherExistingAgreement": participantData.refIfUsingAnotherExistingAgreement
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenCohortType_URL, requestOptions)
            .then(() => {
                if (isFromAnswerPage !== undefined && isFromAnswerPage === dsaContent.checkYourAnswersPageText && participantData.cohort) {
                    navigate(routeName.participantSummary);
                }
                else {
                    navigate(routeName.legalBasis);
                }
                
            })
            .catch(() => navigate(routeName.serviceError));
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <h1>  <span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                About the participants</span>
                                DARS reference number
                            </h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <InsetText>
                                <p>If you would like to use a Participant group, that has been supplied to NHS England, select the NIC number from the drop-down list.</p>
                                <p>If promoted, complete the DARS Reference Number of the application or live agreement that contains the Participant group you wish to use (for example, DARS-NIC-12345-ABCDv0.4).</p>
                                <p>If you would like to use a Participant group from an existing DSA that does not appear in the drop-down list, email <a href={'mailto:data.applications@nhsdigital.nhs.uk?subject=' + dsaApplicationData?.referenceNumber}>data.applications@nhsdigital.nhs.uk</a> quoting (in the subject field) both the NIC number of this application and the NIC number you would like to use.</p>
                            </InsetText>
                            <Select id="participantTypes" label="Select reference number" defaultValue={participantData.refIfUsingAnotherExistingAgreement} onChange={handleChange} error={summaryLinkErrorMessage} >
                                {
                                    participantData?.existingAgreementUploadItems?.map((agreement, index) => (
                                        <Select.Option key={index} defaultValue={agreement}>{agreement}</Select.Option>
                                    ))
                                }
                            </Select>
                            <div className="nhsuk-u-padding-bottom-4">
                            </div>
                            {checkExistingType() ?
                                <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                                : null}

                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}
export default ExistingType;