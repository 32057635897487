import React, { useState } from "react";
import { Button, ErrorSummary, InsetText, Checkboxes, Fieldset } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IApplication, IDataSet, IHistoricAnnualData } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";
import { applicationChangeType,  dataSetErrorMessage, dsaContent,routeName,  taskListSectionStatus } from "../../../../config/page.config";
import '../../../../Content/css/nhsuk-common.css';
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { arrayChunk, checkApplicationChangeType,   submitProductData, submitSpinner, } from "../../../../Helper/Utility";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";

const HistoricDataAnnual: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const dataSetDetailsData: IDataSet = useAppSelector(getDataSetDetails);
    const initialState: IDataSet = dataSetDetailsData || {};
    const [dataSetData, setDataSetData] = useState(initialState);

    const initialApplicationState: IApplication = useAppSelector(getDSADetails) || {};
    const [dsaApplicationData, setDsaApplicationData] = useState(initialApplicationState || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    /* To dispatch the setDataSetDetailsData Redux action */
    const saveDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }

    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [historicErrorMessage, setHistoricErrorMessage] = useState("");

    const [historicAnnualData, setHistoricAnnualDataList] = useState<IHistoricAnnualData[]>([]);

    const numOfRows = dataSetData.initialHistoricAnnualDataList!.length > 3 ? Math.ceil(dataSetData.initialHistoricAnnualDataList!.length / 3) : 1;

    let historicDataList: IHistoricAnnualData[] = dataSetData?.historicAnnualDataList?.filter((x: null | undefined | IHistoricAnnualData) => x !== undefined && x !== null && x?.periodValue !== "").length !== 0 ? dataSetData?.historicAnnualDataList! : [];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IDataSet = {};
        if (e.target.checked && e.target.value !== undefined && e.target.value !== "" && e.target.value !== null) {
            const filteredData: IHistoricAnnualData[] = getSelectPeriodData(e)!;
            historicDataList = Object.assign([], historicDataList);
            historicDataList.push(filteredData[0])
            setHistoricAnnualDataList(historicDataList);
            updatedState = {
                ...dataSetData,
                historicAnnualDataList: historicDataList
            }
            setDataSetData(updatedState);
            saveDataInStore(updatedState);
            resetError(e);
        }
        else if (!e.target.checked) {
            /* deleting target element from the list to uncheck the checkbox */
            historicDataList?.map((item: IHistoricAnnualData, index: number) => {
                if (item.periodValue === e.target.value) {
                    const copyArray = [...historicDataList];
                    copyArray.splice(index, 1);
                    historicDataList = copyArray;
                    setHistoricAnnualDataList(historicDataList);
                }
            })
            updatedState = {
                ...dataSetData,
                historicAnnualDataList: historicDataList
            }
            setDataSetData(updatedState);
            saveDataInStore(updatedState);
        }
    }

    const getSelectPeriodData = (e: React.ChangeEvent<HTMLInputElement>) => {
        const filteredData = dataSetData.initialHistoricAnnualDataList?.filter((items: IHistoricAnnualData) => {
            return items?.periodValue?.toLowerCase().match(`.*${e.target.value.toLowerCase()}.*`)
        }).map((item: IHistoricAnnualData) => {
            const historicData: IHistoricAnnualData = {
                periodValue: item?.periodValue,
                fileAvailabilityId: item?.fileAvailabilityId,
                period: item?.period,
                displayText: item?.displayText,
                selected: item?.selected,
                alreadyHeld: item?.alreadyHeld,
                periodAreadyMappedToFileAvailabilityInCRM: item?.periodAreadyMappedToFileAvailabilityInCRM
            };
            return historicData;
        });
        return filteredData;
    }

    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setHistoricErrorMessage("");
        }
    }    
    const handleDatasetNavigate = () => {
        if (checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)])) {
            submitProductData(dsaApplicationData, dataSetData)
                .then((data: Response) => {
                    if (data) {
                        let sectionStatus: IApplication = {
                            ...dsaApplicationData, aboutDataSetSectionStatus: taskListSectionStatus.inProgress
                        }
                        setDsaApplicationData(sectionStatus);
                        saveApplicationDataInStore(sectionStatus);
                        navigate(routeName.datasetDetails);
                    }
                });
        } else {
            navigateDataSetField();            
        }
    }
    const navigateDataSetField = () => {
        navigate(routeName.selectDatasetField);
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, handleDatasetNavigate);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>  <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
            <ErrorSummary.Title id="error-summary-title">{dataSetErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
            <ErrorSummary.Body>
                <ErrorSummary.List><ErrorSummary.Item href="#historicdata">{summaryLinkErrorMessage}
                </ErrorSummary.Item>
                </ErrorSummary.List></ErrorSummary.Body> </ErrorSummary>
        </>
    )
    /* To Validate the each field's data */
    const validateFields = () => {
        let isValid: boolean = true;
        if (dataSetData?.historicAnnualDataList?.filter((x: null | undefined | IHistoricAnnualData) => x !== undefined && x !== null && x.periodValue !== "").length === 0) {
            setSummaryLinkErrorMessage(`${dataSetErrorMessage.historicMandatory_ErrorMessage}`);
            setHistoricErrorMessage(`${dataSetErrorMessage.historicMandatory_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const disableAlreadyHeldAPY = (periodValue?: string) => {
        let isDisable = false;
        if (checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Amendment)])){
            isDisable = dataSetData.historicAnnualDataList?.some((items: IHistoricAnnualData) => String(items?.periodValue) === periodValue && items?.alreadyHeld) ? true : false
        }
        return isDisable;
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <>
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    About the data &gt; {dataSetData.name}</span>
                                    Historic annual data
                                </h1>
                                {summaryLinkErrorMessage !== "" ? errorSummary : null}
                                <InsetText>
                                    <p>
                                        You can check the period of data coverage (for example, financial year, calendar year or otherwise) for each data set using the <a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-products-and-services/data-set-catalogue" target="_blank">data set catalogue.</a>
                                       </p>
                                    <p>
                                        The questions that follow on the next page require you to justify your reasons for requesting the time frames selected.</p>
                                </InsetText>
                            </div>
                            <div className="nhsuk-grid-column-full">
                                <Fieldset aria-describedby="waste-hint">
                                    <Checkboxes name="historicAnnualDataList" id="historicdata" error={historicErrorMessage} hint="Select all years that are relevant to you." >
                                        {arrayChunk(dataSetData.initialHistoricAnnualDataList, numOfRows).map((row, index) => (
                                            <div key={index} className="nhsuk-column-split nhsuk-grid-column-one-third">
                                                {row.map((item: IHistoricAnnualData) => (
                                                    <Checkboxes.Box value={item?.periodValue} name="historicAnnualDataList"
                                                        checked={dataSetData.historicAnnualDataList?.some((items: IHistoricAnnualData) => String(items?.periodValue) === String(item?.periodValue)) ? true : false}
                                                        disabled={disableAlreadyHeldAPY(String(item?.periodValue))}
                                                        onChange={handleChange} key={item?.periodValue}>{item?.displayText}</Checkboxes.Box>
                                                ))}
                                            </div>
                                        ))}
                                    </Checkboxes>
                                </Fieldset>
                            </div>
                            <div className="nhsuk-grid-column-two-thirds">
                                <div className="nhsuk-u-padding-bottom-8"></div>
                                <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                            </div></>
                    </div>
                </main>
            </div>
        </>
    )
}

export default HistoricDataAnnual;
