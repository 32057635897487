import { Button, Fieldset, InsetText, Radios, Hint, ErrorSummary } from "nhsuk-react-components";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getEvidenceFileDetails, isValid, submitSpinner } from "../../../../Helper/Utility";
import { dsaControllerErrorMessage, dataControllerTypeOptions, dsaContent, routeName } from "../../../../config/page.config";
import { IController, IApplication, IControllerList, ISecurityAssurance, IEvidenceFileSummary, IApiResEvidenceFileSummary, IFileUpload } from "../../../../state/models/app.interface";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getControllerDetails, setControllerDetailsData } from "../../../../state/slice/DSAAboutController/DSAAboutController.slice";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { getControllerListDetails } from "../../../../state/slice/ControllerList/ControllerList.slice";
import { getSecurityAssuranceDetails } from "../../../../state/slice/SecurityAssurance/SecurityAssurance.slice";
import GoBack from "../../../../Components/Shared/GoBack";
import Loader from "../../../../Components/Loader/Loader";

/*
  Summary: Using this page, customer can select the type of Security Assurance they need. The Jira ticket linked to this is DDA-325.
*/
const SecurityAssurance: React.FC = () => {
    /*Variable Declarations*/
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [isSecurityAssuranceErrorMessage, setIsSecurityAssuranceErrorMessage] = useState("");

    const controllerDetailsData: IController = useAppSelector(getControllerDetails);
    const initialState: IController = controllerDetailsData || {};
    const [controllerData, setData] = useState(initialState);
    const saveDataInStore = (stateData?: IController) => {
        dispatch(setControllerDetailsData({ ...controllerData, ...stateData }));
    }

    const controllerListDetailsData: IControllerList = useAppSelector(getControllerListDetails);
    const initialControllerListState: IControllerList = controllerListDetailsData || {};
    const [controllerListData] = useState(initialControllerListState);
    let isDirtyController = controllerData.isDirtyController || false;

    const securityAssuranceDetailsData: ISecurityAssurance = useAppSelector(getSecurityAssuranceDetails);
    const initiailSecurityAssuranceState: ISecurityAssurance = securityAssuranceDetailsData || {};
    const [securityAssuranceData] = useState(initiailSecurityAssuranceState);
    const [showLoader, setShowLoader] = useState(true);
    /*Update value in store and reset validation error onChange*/
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedStateSA: ISecurityAssurance = {};
        let updatedStateofController: IController = {};
        updatedStateSA = { ...securityAssuranceData, [e.target.name]: e.target.value };
        updatedStateofController = { ...controllerData, 'saList': updatedStateSA, isDirtyController: true }
        setData(updatedStateofController);
        saveDataInStore(updatedStateofController);
        resetError(e);
    }
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setIsSecurityAssuranceErrorMessage("");
        }
    }
    /*Update value in store and reset validation error onChange*/

    /*Validate and Submit*/
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            const targetName = e.target as HTMLInputElement;
            if (targetName.name !== "undefined" && targetName.name !== null && targetName.name === dsaContent.saveAndContinue) {
                submitSpinner(e, submitSecurityAssurance);
            }
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    const ValidateFields = () => {
        let isValid: boolean = true;
        if (!(controllerData.saList ? controllerData.saList.SAType : "")) {
            setSummaryLinkErrorMessage(`${dsaControllerErrorMessage.selectSecurityAssuranceLink_ErrorMessage}`);
            setIsSecurityAssuranceErrorMessage(`${dsaControllerErrorMessage.selectSecurityAssurance_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }
    const submitSecurityAssurance = () => {
        if (controllerListData.controllerDetailsPage && !controllerData?.isNewController) {
            if (isDirtyController) {
                let updatedSAList = { ...controllerData.saList, SAId: "" };
                let updatedState: IController = {};
                let fileNames = controllerData.fileUpload?.map((item: IFileUpload) => item.FileName);
                updatedState = { ...controllerData, saList: updatedSAList, evidenceFileSummary: controllerData.evidenceFileSummary?.filter((x) => !fileNames?.includes(x.fileName)) };
                setData(updatedState);
                saveDataInStore(updatedState);
                if (String(controllerData.saList ? controllerData.saList.SAType : "") === String(dataControllerTypeOptions.dsptSecurityAssuranceValue)) {
                    navigate(routeName.dsptDetails);
                }
                else if (String(controllerData.saList ? controllerData.saList.SAType : "") === String(dataControllerTypeOptions.isoSecurityAssuranceValue)) {
                    navigate(routeName.dsaIso);
                }
                else if (String(controllerData.saList ? controllerData.saList.SAType : "") === String(dataControllerTypeOptions.slspSecurityAssuranceValue)) {
                    navigate(routeName.dsaSlsp);
                }
            }
            else {
                navigate(routeName.controllerDetails);
            }
        }
        else {
            if (String(controllerData.saList ? controllerData.saList.SAType : "") === String(dataControllerTypeOptions.dsptSecurityAssuranceValue)) {
                navigate(routeName.dsptDetails);
            }
            else if (String(controllerData.saList ? controllerData.saList.SAType : "") === String(dataControllerTypeOptions.isoSecurityAssuranceValue)) {
                navigate(routeName.dsaIso);
            }
            else if (String(controllerData.saList ? controllerData.saList.SAType : "") === String(dataControllerTypeOptions.slspSecurityAssuranceValue)) {
                navigate(routeName.dsaSlsp);
            }
        }
    }

    const getEvidenceFileSummary = () => {
        let applicationId = isValid(dsaApplicationData.id) ? dsaApplicationData.id : "";
        if (isValid(applicationId)) {
            getEvidenceFileDetails(applicationId!).then((data) => {
                setEvidenceFileSummary(data);
            });
        }
    }

    const setEvidenceFileSummary = (response: IApiResEvidenceFileSummary[]) => {
        if (isValid(response)) {
            let evidenceFileSummary: IEvidenceFileSummary[] = [];
            response.map(({ FileId: fileId, FileName: fileName, MimeType: mimeType, Source: source }) => {
                evidenceFileSummary.push({ fileId, fileName, mimeType, source });
            });
            let updatedState: IController = {};
            updatedState = { ...controllerData, evidenceFileSummary }
            setData(updatedState);
            saveDataInStore(updatedState);
        }
        setShowLoader(false);
    }

    useEffect(() => {
        if (!isValid(controllerData.evidenceFileSummary) || isValid(controllerData.evidenceFileSummary?.filter((x) => !isValid(x.fileId)))) {
            getEvidenceFileSummary();
        }
        else {
            setShowLoader(false);
        }
    }, []);

    /*Validate and Submit*/
    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaControllerErrorMessage.selectControllerSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#saType">
                            {summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    /* To Validate the each field's data */

    /*Radio Populate*/
    const renderRadio = (renderOptionsList: string[]) => {
        return renderOptionsList && renderOptionsList?.map((option: string, index: number) => {
            return <React.Fragment key={option}>  {(index > 1) ? <Radios.Divider>or</Radios.Divider> : <></>}
                <Radios.Radio name="SAType" checked={String(controllerData.saList ? controllerData.saList.SAType : "") === String(option)} value={option} onChange={handleChange}>{getContent(option)}
                    {(index > 1) ? <Hint><p>You should only select 'System Level Security Policy (SLSP)' in exceptional circumstances. Contact <a href={'mailto:data.applications@nhsdigital.nhs.uk?subject=SecurityAssurance - DARS Online Application Issue ' + dsaApplicationData?.referenceNumber}>data.applications@nhsdigital.nhs.uk</a> (opens in a new window) before selecting this option. Your NIC number will appear in the subject field for reference.</p>
                        <p>If you don't know what security assurance to provide, contact your Data Protection Officer (DPO) or Information Governance (IG) team for help.</p>
                        <p>If you don't think you have security assurance, contact <a href={'mailto:data.applications@nhsdigital.nhs.uk?subject=SecurityAssurance - DARS Online Application Issue ' + dsaApplicationData?.referenceNumber}>data.applications@nhsdigital.nhs.uk</a> (opens in a new window). Your NIC number will appear in the subject field for reference.</p>
                    </Hint> : ""}
                </Radios.Radio>
            </React.Fragment>
        });
    }
    const getContent = (option: string) => {
        switch (option) {
            case dataControllerTypeOptions.dsptSecurityAssuranceValue: return "Data Security and Protection Toolkit (DSPT)";
            case dataControllerTypeOptions.isoSecurityAssuranceValue: return "ISO/IEC 27001 Information Security management standard";
            case dataControllerTypeOptions.slspSecurityAssuranceValue: return "System Level Security Policy (SLSP)";
        }
    }
    /*Radio Populate*/

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                    {showLoader ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <>
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the Controller
                            </span>
                            <h1>What security assurance will you provide for this Controller?</h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <InsetText>
                                <p>For a Controller to be given access to data from NHS England, they must provide security assurance.</p>                                
                            </InsetText>
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <Fieldset className="nhsuk-space-bottom-double">
                                <Radios name="SAType" id="saType" hint="" error={isSecurityAssuranceErrorMessage}>
                                    {dataControllerTypeOptions && renderRadio([dataControllerTypeOptions.dsptSecurityAssuranceValue!, dataControllerTypeOptions.isoSecurityAssuranceValue!, dataControllerTypeOptions.slspSecurityAssuranceValue!])}
                                </Radios>
                            </Fieldset>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>
                        </>}
                    </div>
                </main>
            </div>
        </>
    )
}
export default SecurityAssurance;