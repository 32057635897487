import { Button, InsetText, Radios } from "nhsuk-react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader';
import ErrorDetails from '../../../../Components/Shared/ErrorSummary';
import { controllerDetails, dsfcErrorMessage, dsaContent, sectionOptions, taskListSectionStatus, routeName, fileTypeSize, applicationChangeType } from "../../../../config/page.config";
import { IControllerList, IController, IApplication, IProcessor } from "../../../../state/models/app.interface";
import { useAppSelector, useAppDispatch } from "../../../../state/app/hooks";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getControllerListDetails, } from "../../../../state/slice/ControllerList/ControllerList.slice";
import { getProcessorDetails, setProcessorDetails } from "../../../../state/slice/Processor/Processor.slice";
import { checkApplicationChangeType, fetchInterceptor, getCookie, getTaskListType, submitSpinner } from "../../../../Helper/Utility";
import { SubmitDSASectionStatus_URL } from "../../../../config/api-endpoints.config";
import { AppDispatch } from '../../../../state/app/store';
import GoBack from "../../../../Components/Shared/GoBack";

/*If an applicant answers 'Yes' to the 'Is this Controller going to be processing the data?' for any controller,
 * This will be first question asked to complete in the processor journey. */

const SolelyProcessor: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData,] = useState(initialApplicationState);
    const controllerListDetailsData: IControllerList = useAppSelector(getControllerListDetails);
    const initialControllerListState: IControllerList = controllerListDetailsData || {};
    const [controllerListData,] = useState(initialControllerListState);
    const processorDetailsData: IProcessor = useAppSelector(getProcessorDetails);
    const initialState: IProcessor = processorDetailsData || {};
    const [processorData, setProcessorData] = useState(initialState);
    const [solelyaProcessorErrorMessage, setSolelyaProcessorErrorMessage] = useState('');
    const [solelyaProcessorValue, setSolelyaProcessorValue] = useState('');

    /* Filtered list with 'Is this controller processing Data' question is answered as Yes */
    const [controllerAsProcessorList,] = useState<IController[]>(controllerListData.controllerList?.filter((controller: IController) => controller.isControllerProcessingData === true) || []);

    /* To dispatch the setProcessorDetails Redux action */
    const saveProcessorDataInStore = (stateData?: IProcessor) => {
        dispatch(setProcessorDetails({ ...processorData, ...stateData }));
    }

    /* Reset the error if the selected value is not blank */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSolelyaProcessorErrorMessage("");
        }
    };

    /* Function to set the state value on change of Radios(yes/no) */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSolelyaProcessorValue(e.target.value);
        resetError(e);
    };

    const navigateProcessorData = () => {
        setProcessorStatus(taskListSectionStatus.inProgress);
        navigate(routeName.processorData);
    }

    /* Redirection based on yes/no and updating processor status accordingly */
    const handleContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal),String(applicationChangeType.Extension)]) ? true : validateFields();
        if (isValid) {
            if (solelyaProcessorValue === dsaContent.yes) {
                submitSpinner(e, navigateProcessorData);

            } else {
                submitSpinner(e, submitProcessorDetails);
            }
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    /*API call to submit processor details and marking the status as completed */
    const submitProcessorDetails = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "SectionId": sectionOptions.DataProcessor,
            "SectionStatus": taskListSectionStatus.completed
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitDSASectionStatus_URL, requestOptions)
            .then(() => {
                setProcessorStatus(taskListSectionStatus.completed);
                navigate(routeName.dsaTaskList);
            })
            .catch(() => navigate(routeName.serviceError));
    }


    /* Validate if atleast one option(Yes/No) is selected */
    const validateFields = () => {
        let isValid: boolean = true;
        if (solelyaProcessorValue === '') {
            setSolelyaProcessorErrorMessage(`${dsfcErrorMessage.dsfcDetailsRegisteredOrganisation_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    };

    /* Setting the section status based on Yes(InProgress)/No(Completed) and updating the state */
    const setProcessorStatus = (aboutProcessorSectionStatus: string) => {
        let updatedState: IProcessor = {};
        updatedState = {
            ...processorData,
            aboutProcessorSectionStatus: aboutProcessorSectionStatus
        }
        setProcessorData(updatedState);
        saveProcessorDataInStore(updatedState);
    }


    /* JSX element to map and show the controllerList which processes the data */
    const ControllerAsProcessorList: JSX.Element = (
        <table className="nhsuk-table ctrl-border">
            <tbody className="nhsuk-table__body">
                {
                    controllerAsProcessorList.map((controllerOrg: IController, index: number) => {
                        return (
                            <tr role="row" className="nhsuk-table__row" key={controllerOrg.controllerId}>
                                <td className="nhsuk-table__cell" >{index + 1}. {controllerOrg.controllerdataOrgDesc}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    );

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            {
                                <GoBack condition={getTaskListType(dsaApplicationData.returningJourneyType!)} />
                            }
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the Processor
                            </span>
                            {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? <h1>These are the Controllers that are processing the data</h1> :
                                <h1>Are there any other organisations that are processing the data?</h1>}

                            <InsetText>
                                <p>Processing means using personal data in any way, including; collecting, storing, retrieving, consulting, disclosing or sharing with someone else, erasing, or destroying personal data.</p>
                            </InsetText>

                            {solelyaProcessorErrorMessage !== "" && <ErrorDetails errorDescription={solelyaProcessorErrorMessage} errorHref="#solelyaProcessor"></ErrorDetails>}
                            <p>The below Controller(s) have been added</p>
                            {ControllerAsProcessorList}
                            <div className="nhsuk-u-padding-bottom-4"></div>

                            {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? null :
                                <>
                                <Radios
                                    name="solelyaProcessor"
                                    id="solelyaProcessor"
                                    error={solelyaProcessorErrorMessage}
                                >
                                    <Radios.Radio value={dsaContent.yes} onChange={handleChange}>{dsaContent.yes}</Radios.Radio>
                                    <Radios.Radio value={dsaContent.no} onChange={handleChange}>{dsaContent.no}</Radios.Radio>
                                </Radios>
                                 <div className="nhsuk-u-padding-bottom-8"></div>  </>}
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleContinue}>{controllerDetails.btnSaveAndContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default SolelyProcessor