import React, { useState } from "react";
import { Button, ErrorSummary, Radios, InsetText } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IApplication, IEvidence } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getEvidenceDetails, setEvidenceDetailsData } from "../../../../state/slice/Evidence/Evidence.slice";
import { amendRenewExtendValue, controllerSection, dsaContent, dsaErrorMessage, EthicsApprovalInPlaceTypeOptions, evidenceErrorMessage, fileTypeSize, routeName, taskListSectionStatus } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { fetchInterceptor, getCookie, submitDSASectionStatus, submitSpinner } from "../../../../Helper/Utility";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { SubmitEthicsApproval_URL } from "../../../../config/api-endpoints.config";

const EthicsApproval: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [ethicsApprovalErrorMessage, setEthicsApprovalErrorMessage] = useState("");

    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};
    const initialState: IEvidence = useAppSelector(getEvidenceDetails) || {};
    const [evidenceData, setevidenceData] = useState(initialState)

    const saveEvidenceDataInStore = (stateData?: IEvidence) => {
        dispatch(setEvidenceDetailsData({ ...evidenceData, ...stateData }));
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IEvidence = {};
        if (e.target.value !== null && e.target.value !== undefined) {
            updatedState = {
                ...evidenceData,
                ethicsApproval: e.target.value,
                ethicsApprovalNotInPlace: ""
            }
            setevidenceData(updatedState);
            saveEvidenceDataInStore(updatedState);
            resetError(e);
        }
    }

    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setEthicsApprovalErrorMessage("");
        }
    }

    const handleNavigation = () => {
        if (String(evidenceData.ethicsApproval) === String(EthicsApprovalInPlaceTypeOptions.Yes)) {
            if (evidenceData.fileUploadEthics! && evidenceData.fileUploadEthics![0]?.FileName! !== undefined && evidenceData.fileUploadEthics![0]?.FileName! !== "") {
                navigate(routeName.addAnotherEthicsReview);
            }
            else {
                navigate(routeName.uploadConsent);
            }
        } else if (String(evidenceData.ethicsApproval) === String(EthicsApprovalInPlaceTypeOptions.No)) {
            navigate(routeName.ethicsApprovalNotInPlace);
        }
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            if (String(evidenceData.ethicsApproval) === String(EthicsApprovalInPlaceTypeOptions.Yes)) {
                submitSpinner(e, handleNavigation);
            }
            else {
                submitSpinner(e, handleNavigation);
            }
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href={"#" + dsaContent.ethicsApproval}>{summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    /* To Validate the field's data */
    const validateFields = () => {
        let isValid: boolean = true;
        if (evidenceData.ethicsApproval && String(evidenceData.ethicsApproval) === String(EthicsApprovalInPlaceTypeOptions.Na)) {
            setSummaryLinkErrorMessage(`${evidenceErrorMessage.ethicsApproval_ErrorMessage}`);
            setEthicsApprovalErrorMessage(`${evidenceErrorMessage.ethicsApproval_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack condition={dsaContent.gobackevidenceapproval}></GoBack>
                            <h1>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom ">Ethics approval</span>
                                Is the relevant ethics approval in place?</h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <InsetText>
                                <p>You told us that your purpose requires ethics approval. Now tell us if the relevant ethics approval is in place.</p>
                            </InsetText>
                            <Radios
                                name={dsaContent.ethicsApproval}
                                id={dsaContent.ethicsApproval}
                                error={ethicsApprovalErrorMessage}>
                                <Radios.Radio name={dsaContent.ethicsApproval} checked={String(evidenceData.ethicsApproval) === String(EthicsApprovalInPlaceTypeOptions.Yes)} value={EthicsApprovalInPlaceTypeOptions.Yes} onChange={handleChange}>{dsaContent.yes}</Radios.Radio>
                                <Radios.Radio name={dsaContent.ethicsApproval} checked={String(evidenceData.ethicsApproval) === String(EthicsApprovalInPlaceTypeOptions.No)} value={EthicsApprovalInPlaceTypeOptions.No} onChange={handleChange}>{dsaContent.no}</Radios.Radio>
                            </Radios>
                            <div className="nhsuk-u-padding-bottom-4">
                            </div>
                            <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                        </div>
                    </div>
                </main>
            </div>
        </>)
}
export default EthicsApproval;
