import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialStateOfFileUpload } from '../../../config/page.config'
import type { RootState } from '../../app/store'
import { IEvidence, IParticipant, IParticipantContributor, IParticipantLegalBasis } from '../../models/app.interface'

const initialParticipantContributor: IParticipantContributor = {
    id: "",
    firstName: "",
    lastName: "",
    role: "",
    organisationName: "",
    email: "",
    makeValidation: "",
    mobile: 0,
    organisation: "",
    organisationId: "",
    userType: 0
}

const initialParticipantLegalBasis: IParticipantLegalBasis = {
    value: "",
    crmId: "",
    text: "",
    checked: false,
    name: "",
    availableOnline: false,
    currentlyCheckedInCRM: false,
    displayDissemination: false,
    displayProcessing: false,
    dissemination: false,
    notes: "",
    orderBy: 0,
    otherComments: "",
    otherLegalBasis: false,
    processing: false,
    productLegalBasis: false,
}
const initialEvidence: IEvidence = {
    id: "",
    referenceNumber: "",
    comments: "",
    approvalsEvidenceType: ""
}
const initialState: IParticipant = {
    participantTypes: "",
    aboutParticipantSectionStatus: "",
    participantDataType: "",
    legalBasis_Other: "",
    dataProviderFirstName: "",
    dataProviderLastName: "",
    dataProviderRole: "",
    dataProviderOrganisationName: "",
    dataProviderOrganisationNameNotListed: "",
    dataProviderOrganisationId: "",
    dataProviderEmail: "",
    dataProviderConfirmEmail:"",
    participantContributorList: [initialParticipantContributor],
    participantLegalBasisList: [initialParticipantLegalBasis],
    additionalLinkageCohortInformation: "",
    addressDate: false,
    applicationId: "",
    dataAsAtDate: false,
    dataProductionSubmissionSelected: 0,
    dataSetLegalBasisList: [initialParticipantLegalBasis],
    dataSetSelectedLegalBasisOtherText: "",
    dateOfBirth: false,
    dateOfDeath: false,
    emailAddress: false,
    existingAgreementUploadItems: [""],
    forename: false,
    gpPracticeCode: false,
    howManyValidationCredits: 0,
    journals: [initialParticipantContributor],
    journalToAdd: "",
    manualCohortValidation: false,
    maximumSize: 0,
    minimumSizeCohortValidationRate: 0,
    mobilePhoneNumber: false,
    nhsNumber: false,
    ongoingRecruitment: false,
    otherGivenName: false,
    postcode: false,
    productionCommence: "",
    refIfUsingAnotherExistingAgreement: "",
    sex: false,
    studyId: false,
    surname: false,
    telephoneNumber: false,
    typeOfCohort: 0,
    userAuthTokenId: "",
    participantcheckYourAnswerPage: '',
    evidenceList: [initialEvidence],
    recentlyAddedConsentId: '',
    recentlyAddedNHSHealthId: '',
    isAllLegalBasisAdded: false,
    deleteEvidenceId: '',
    temporaryFileList: [initialStateOfFileUpload],
    fileUploadActive: 0,
    isFromAddAnotheFileRecord: false,
    evidenceFileSummary: []
}

export const ParticipantSlice = createSlice({
    name: 'Participant',
    initialState,
    reducers: {
        setParticipantDetailsData: (state: IParticipant, action: PayloadAction<IParticipant>) => {
            state = Object.assign(state, action.payload);
        },
        resetParticipantDetailsData: (state: IParticipant, action: PayloadAction<IParticipant>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setParticipantDetailsData, resetParticipantDetailsData } = ParticipantSlice.actions;
export const getParticipantDetails = (state: RootState) => state.participantDetails;
export default ParticipantSlice.reducer;