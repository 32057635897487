import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { IPrivacyNotice } from '../../models/app.interface'

export const initialState: IPrivacyNotice = {
    doesControllerEnsureCompliance: "",
    explanationForNonCompliance: "",
    aboutPrivacyNoticeStatus:"",
}

export const PrivacyNoticeSlice = createSlice({
    name: 'PrivacyNotice',
    initialState,
    reducers: {
        setPrivacyNoticeDetailsData: (state: IPrivacyNotice, action: PayloadAction<IPrivacyNotice>) => {
            state = Object.assign(state, action.payload);
        },
        resetPrivacyNoticeData: () => initialState,
         }
});

export const { setPrivacyNoticeDetailsData, resetPrivacyNoticeData } = PrivacyNoticeSlice.actions;
export const getPrivacyNoticeDetails = (state: RootState) => state.privacyNoticeDetails;
export default PrivacyNoticeSlice.reducer;