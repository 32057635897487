import { Label } from "nhsuk-react-components";
import React, { useEffect } from "react";
import { pageTitles } from "../../config/page.config";
import { updatePageTitle } from "../../Helper/Utility";

const SignOut: React.FC = () => {

    useEffect(() => {
        updatePageTitle(pageTitles.signOut);
    }, []);

    return (
        <div className="nhsuk-width-container">
            <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                <div className="nhsuk-grid-row">
                    <div className="nhsuk-grid-column-two-thirds">
                        <h1>Signed out</h1>
                        <Label size="s">You have successfully signed out of your account.</Label>
                        <Label size="s"></Label>
                        <Label size="s"><a href={process.env.REACT_APP_DARS_SSO_AUTHENTICATE_URL}>Sign in to your account</a></Label>
                    </div>
                </div>
            </main>
        </div>
    )
}
export default SignOut;