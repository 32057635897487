import { InsetText, Button, Textarea, Details, ContentsList } from "nhsuk-react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GoBack from "../../../../Components/Shared/GoBack";
import { fetchInterceptor, getCookie, submitSpinner} from "../../../../Helper/Utility";
import { IApplication, IPurpose } from "../../../../state/models/app.interface";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { dsaContent, fileTypeSize, purposeErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getPurposeDetails, setPurposeDetailsData } from "../../../../state/slice/Purpose/Purpose.slice";
import { SubmitScreenObjectiveAndProcessing_URL } from "../../../../config/api-endpoints.config";

const ObjectiveAndProcessing: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const purposeDetailsData: IPurpose = useAppSelector(getPurposeDetails);
    const initialState: IPurpose = purposeDetailsData || {};
    const [purposeData, setPurposeData] = useState(initialState);
    const isFromAnswerPage = purposeData?.objectiveForProcessingCheckYourAnswerPage;
    const savePurposeDataInStore = (stateData?: IPurpose) => {
        dispatch(setPurposeDetailsData({ ...purposeData, ...stateData }));
    }

    const [objectiveErrorMessage, setobjectiveErrorMessage] = useState("");

    const submitObjectiveAndProcessing = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "ObjectiveForProcessing": purposeData.objectiveForProcessing,
            "ProcessingActivities": purposeData.processingActivities,
            "SectionStatus": dsaApplicationData.aboutObjectiveAndProcessingSectionStatus
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitScreenObjectiveAndProcessing_URL, requestOptions)
            .then(() => {
                let sectionStatus: IApplication = {
                    ...dsaApplicationData, aboutObjectiveAndProcessingSectionStatus: taskListSectionStatus.inProgress
                }
                setDsaApplicationData(sectionStatus);
                saveApplicationDataInStore(sectionStatus);
                navigate(routeName.objectiveProcessingCheckAnswer);

            })
            .catch(() => navigate(routeName.serviceError));
    }

    const handleNavigation = () => {
        if (isFromAnswerPage !== undefined && isFromAnswerPage != '')
            submitObjectiveAndProcessing();
        else
            navigate(routeName.processingActivities);
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, handleNavigation);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const validateFields = () => {
        let isValid: boolean = true;
        if (!purposeData.objectiveForProcessing || purposeData.objectiveForProcessing.trim() === "") {
            setobjectiveErrorMessage(`${purposeErrorMessage.enterObjective_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IPurpose = {};
        updatedState = {
            ...purposeData,
            objectiveForProcessing: e.target.value
        }
        savePurposeDataInStore(updatedState);
        setPurposeData(updatedState);
        resetError(e);
    }
    const resetError = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== "") {
            setobjectiveErrorMessage("");
        }
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">

                    <div className="nhsuk-grid-row">
                        {
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    Objective and processing activities</span>
                                    What is the objective(s) for processing the data?
                                </h1>
                                {objectiveErrorMessage !== "" && <ErrorDetails errorDescription={objectiveErrorMessage} errorHref="#objective"></ErrorDetails>}
                                <InsetText>
                                    <p>
                                        On this page, tell us how the data sets requested will help you to achieve the aims associated with processing the data.
                                    </p>
                                    <p>
                                        You may already have produced an appropriate summary for use in a Patient Information Sheet (PIS), transparency notice or an application for funding, a Research Ethics Committee (REC) document or a Confidential Advisory Group (CAG) approval. If so, you may find it helpful to use some of this information.
                                    </p>
                                </InsetText>
                                <div className="nhsuk-u-padding-bottom-3"></div>
                                <Details>
                                    <Details.Summary>Guidance </Details.Summary>
                                    <Details.Text>
                                        <p>Provide a brief explanation of the public interest (for processing under Article 9(2)(i) and (j)), or substantial public interest (if processing under Article 9(2)(g)). If applicable, explain why the intended outcomes of the proposed use of the data are in the public's interest.</p>
                                        <p>Describe the data subjects, including control and Participant groups.</p>
                                        <p>Explain the purpose of the request, providing detailed information about:</p>
                                        <ul>
                                            <li>the purpose of the project under this application</li>
                                            <li>why the data is required for the purpose, to include justification for:</li>
                                            <ContentsList aria-label="Pages in this guide" className="nhsuk-contents-list_custom nhsuk-u-margin-bottom-0">
                                                <ContentsList.Item current>
                                                    The data sets requested
                                                </ContentsList.Item>
                                                <ContentsList.Item current>
                                                    The type of data, for example, identifiable, pseudonymised, unsuppressed or aggregated
                                                </ContentsList.Item>
                                                <ContentsList.Item current>
                                                    The number of years requested
                                                </ContentsList.Item>
                                                <ContentsList.Item current>
                                                    The geographical spread of the data requested
                                                </ContentsList.Item>
                                            </ContentsList>
                                        </ul>
                                        <p>Provide details of any efforts taken to minimise the data required, including:</p>
                                        <ul>
                                            <li>whether you require data containing identifying details or a unique patient ID which would enable you to reidentify individuals in the data set. If you require identifiable data, explain why</li>
                                            <li>the decisions taken to ensure you are requesting the minimum amount of data required to achieve the purpose(s) for processing </li>
                                        </ul>
                                        <p>Explain which organisations are involved, including whether there are:</p>
                                        <ul>
                                            <li>any organisations that are involved in the wider project (but not processing the data) and if so, who they are and the nature of their role </li>
                                            <li>any Funders or Commissioners involved and the nature of their role</li>
                                        </ul>
                                        <p>Provide details of organisations involved in the purpose of processing the data other than those you have named as a Controller or Processor and clarify their role. </p>
                                    </Details.Text>
                                </Details>
                                <Textarea
                                    hint="Provide information on your objective(s) for processing the data in the text box below."
                                    error={objectiveErrorMessage}
                                    name="objective"
                                    id="objective"
                                    onChange={handleChangeTextarea}
                                    defaultValue={purposeData.objectiveForProcessing ? purposeData.objectiveForProcessing : ""}
                                    maxLength={50000}
                                    rows={5}
                                />
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>
                        }
                    </div>
                </main>
            </div>
        </>)
}

export default ObjectiveAndProcessing;