import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Label, WarningCallout } from "nhsuk-react-components";
import { dsaContent, routeName } from "../../../../config/page.config";
import { IApplication } from "../../../../state/models/app.interface";
import { useAppSelector } from "../../../../state/app/hooks";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import InfoHeaderPanel from "../../../../Components/InfoHeaderPanel/InfoHeaderPanel";
import GoBack from "../../../../Components/Shared/GoBack";
import { getTaskListType } from "../../../../Helper/Utility";

const DataSetIntro: React.FC = () => {
    let navigate = useNavigate();
    const [btnText, setBtnText] = useState(dsaContent.btnTextStart);

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);

    const handleClick = () => {
        navigate(routeName.selectDataset);
    };

    useEffect(() => {
        (dsaApplicationData && dsaApplicationData.id !== "" && dsaApplicationData.id !== undefined) ?
            setBtnText(dsaContent.btnTextContinue) : setBtnText(dsaContent.btnTextStart)
    }, []);

    return (
        <>
            {(dsaApplicationData && dsaApplicationData.id !== "" && dsaApplicationData.id !== undefined) ?
                <InfoHeaderPanel>
                    <InfoHeaderPanel.Item >
                        <strong>Reference number</strong> : {dsaApplicationData?.referenceNumber}
                    </InfoHeaderPanel.Item>
                    <InfoHeaderPanel.Item>
                        <strong>Total Estimated Cost</strong> : {dsaApplicationData.totalEstimatedCost}
                    </InfoHeaderPanel.Item>
                </InfoHeaderPanel>
                : null}
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            {
                                <GoBack condition={getTaskListType(dsaApplicationData.returningJourneyType!)} />
                            }
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">About the data</span>
                            <h1>Data set(s) </h1>
                            <WarningCallout>
                                <WarningCallout.Label>Ensuring the information you enter is saved </WarningCallout.Label>
                                <p>
                                    Details you enter for each data set will not be saved until you have completed the information in full. Once information on a single data set has been saved, the data set will show on the 'You have added the below data set(s)' page within the 'About the data' section of the service.
                                </p>
                            </WarningCallout>                            
                            <Label size="m">Data set(s) selection </Label>
                            <Label size="s">This data set(s) selection part of your Data Sharing Agreement (DSA) application enables you to: </Label>
                            <Label size="s">
                                <ul>
                                    <li>choose and select data set(s) </li>
                                    <li>choose year-to-date, historic annual or future release data</li>
                                    <li>choose and select fields within a data set(s) </li>
                                    <li>choose and select a data set(s) frequency</li>
                                    <li>provide information that helps NHS England  link data to a group of Participants you are going to provide </li>                                    
                                </ul>
                            </Label>

                            <Label size="m">Before you start </Label>
                            <Label size="s">Before you start, we recommend that this section is completed (or supported) by someone who will be using the data. </Label>
                            <Label size="s">Make sure you understand the available data sets, fields and services using our:</Label>
                            <ul>
                                <li><a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-products-and-services/data-set-catalogue" target="_blank">data sets catalogue (opens in a new window)</a></li>
                                <li><a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-products-and-services/metadata-dashboard" target="_blank">metadata dashboard (opens in a new window) </a></li>
                                <li><a href="https://app.powerbi.com/view?r=eyJrIjoiZmRjODc0ZjMtNTllOC00ODY3LTg4MGQtNTNmYTY5NzQyNjU3IiwidCI6IjUwZjYwNzFmLWJiZmUtNDAxYS04ODAzLTY3Mzc0OGU2MjllMiIsImMiOjh9" target="_blank">metadata store (opens in a new window)</a></li>
                            </ul>
                            <Label size="s">Understanding data sets and the services that you can request through the <a href="https://digital.nhs.uk/services/data-access-request-service-dars/dars-products-and-services/services" target="_blank"> Data Access Request Service (DARS)</a> (opens in a new window) will help you to select data you need that supports both the purpose and legal basis or bases. </Label>
                            <Label size="s">Understanding <a href="https://digital.nhs.uk/services/data-access-request-service-dars/data-access-request-service-dars-charges" target="_blank"> charges (opens in a new window)</a> will help you to breakdown the costs associated with requesting data.</Label>
                            <Label size="m">Services </Label>                            
                            <Label size="s">The DARS team deal with a multitude of requests that include requests for data sets, linked data sets, tabulations, bespoke linkage and patient tracking.</Label>                            
                            <Label size="s">If you need help with your data set(s) selection, contact NHS England on 0300 303 5678, or email <a href='mailto:data.applications@nhsdigital.nhs.uk'> data.applications@nhsdigital.nhs.uk</a> </Label>
                            <br></br>
                            <Button id="nhsuk-button-spinner" onClick={handleClick} >{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}


export default DataSetIntro;
