import { Button, Fieldset, Radios, InsetText } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader'
import ErrorDetails from '../../../../Components/Shared/ErrorSummary';
import { controllerDetails, dsaControllerErrorMessage, dsaContent, taskListSectionStatus, routeName, fileTypeSize } from "../../../../config/page.config";
import { IControllerList, IController, IApplication } from "../../../../state/models/app.interface";
import { useAppSelector, useAppDispatch } from "../../../../state/app/hooks";
import { getControllerListDetails, setControllerListDetailsData } from "../../../../state/slice/ControllerList/ControllerList.slice";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { UpdateScreenDataController_URL } from "../../../../config/api-endpoints.config";
import { AppDispatch } from '../../../../state/app/store';
import { getControllerDetails, setControllerDetailsData } from "../../../../state/slice/DSAAboutController/DSAAboutController.slice";
import GoBack from "../../../../Components/Shared/GoBack";

const PayForThisService: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();

    const controllerListDetailsData: IControllerList = useAppSelector(getControllerListDetails);
    const initialControllerListState: IControllerList = controllerListDetailsData || {};
    const [controllerListData,] = useState(initialControllerListState);

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    const [payForThisServiceError, setpayForThisServiceError] = useState('');
    const [payingController, setPayingController] = useState('');
    const [payingControllerId, setPayingControllerId] = useState('');

    const controllerDetailsData: IController = useAppSelector(getControllerDetails);
    const initialState: IController = controllerDetailsData || {};
    const [controllerData] = useState(initialState);
    /* To dispatch the setControllerDetailsData Redux action */
    const saveControllerDataInStore = (stateData?: IController) => {
        dispatch(setControllerDetailsData({ ...controllerData, ...stateData }));
    }

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setpayForThisServiceError("");
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPayingController(e.target.value);
        setPayingControllerId(e.target?.id);
        resetError(e);
        let updatedState: IController = {};
        updatedState = { ...controllerData, isDirtyController: true }
        saveControllerDataInStore(updatedState);
    };

    const handleContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, updateDataController);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const ValidateFields = () => {
        let isValid: boolean = true;
        if (payingController === '') {
            setpayForThisServiceError(`${dsaControllerErrorMessage.payForThisService_ErrorMessage}`);
            isValid = false;
        }

        return isValid;
    }

    const saveControllerListDataInStore = (stateData?: IControllerList) => {
        dispatch(setControllerListDetailsData({ ...controllerListData, ...stateData }));
    }

    const updateDataController = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "DataControllerId": payingControllerId,
            "IsPayingForDSA": true,
            "IsPayingForDSADirty": true,
            "SectionStatus": dsaApplicationData.aboutControllerSectionStatus
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(UpdateScreenDataController_URL, requestOptions)
            .then(response => {
                    return response.json()
            })
            .then(() => {
                let updatedState: IControllerList = {
                    ...controllerListData, controllerDetailsPage: dsaContent.checkYourAnswersPageText
                }
                let sectionStatus: IApplication = {
                    ...dsaApplicationData, aboutControllerSectionStatus: taskListSectionStatus.inProgress
                }
                setDsaApplicationData(sectionStatus);
                saveApplicationDataInStore(sectionStatus);
                saveControllerListDataInStore(updatedState);
                navigate(routeName.controllerDetails);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    useEffect(() => {
        const controllerList = controllerListData.controllerList ? controllerListData.controllerList : [];
        const paidController = controllerList.find(controller => controller.isPayingForDSA === true) || {};
        setPayingController(paidController?.controllerdataOrgDesc || '');
        const paidControllerId:string = paidController?.controllerId! || '';
        setPayingControllerId(paidControllerId);
    }, [controllerListData])

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack condition={ dsaContent.controllerDetails} />
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the Controller
                            </span>
                            <h1>Which organisation will pay the invoice for this service? </h1>
                            <InsetText>
                                <p>
                                    If you believe the organisation is exempt from paying, email <a href={'mailto:data.applications@nhsdigital.nhs.uk?subject=DSA application'}>data.applications@nhsdigital.nhs.uk</a> quoting 'DSA application' in the subject field.
                                </p>
                            </InsetText>

                            {payForThisServiceError !== "" && <ErrorDetails errorDescription={payForThisServiceError} errorHref="#payInvoice"></ErrorDetails>}

                            <Fieldset>
                                <Radios name="payInvoice" id="payInvoice" error={payForThisServiceError}>
                                    {controllerListData.controllerList?.map((controller: IController) => (
                                        <Radios.Radio
                                            key={controller.controllerId}
                                            id={controller.controllerId}
                                            checked={controller.controllerdataOrgDesc === payingController}
                                            value={controller.controllerdataOrgDesc}
                                            onChange={handleChange}>
                                            {controller.controllerdataOrgDesc}
                                        </Radios.Radio>
                                    ))}
                                </Radios>
                            </Fieldset>

                            <div className="nhsuk-u-padding-bottom-8"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleContinue}>{controllerDetails.btnSaveAndContinue}</Button>
                           
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default PayForThisService;