import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Label, SummaryList } from "nhsuk-react-components";
import { getDate } from "../../../Helper/Utility";
import { useAppSelector } from "../../../state/app/hooks";
import { IDSFCContractDetails, ILocationStateProps, IUserDetails } from "../../../state/models/app.interface";
import { getDSFCContractDetails } from "../../../state/slice/DSFC/DSFCContractDetails.slice";
import { getUserDetails } from "../../../state/slice/Header/Header.slice";
import Attachment from "../../../Components/Attachment/Attachment";
import GoBack from "../../../Components/Shared/GoBack";

const DSFCLatestSignedContract: React.FC = () => {
    const location = useLocation();
    const [pdfURL, setPdfURL] = useState((location.state as ILocationStateProps)?.pdfURL);
    const UserDetailsData: IUserDetails = useAppSelector(getUserDetails);
    const initialStateofUserData: IUserDetails = UserDetailsData || {};
    const [userData, setUserData] = useState(initialStateofUserData);
    const DSFCContractDetailsData: IDSFCContractDetails = useAppSelector(getDSFCContractDetails);
    const initialState: IDSFCContractDetails = DSFCContractDetailsData || {};
    const [dsfcData] = useState(initialState);

    useEffect(() => {
        setUserData(UserDetailsData);
    }, [UserDetailsData]);

    return (
        <>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack/>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                {userData?.userOrgName}
                            </span>
                            <h1>Your Data Sharing Framework Contract</h1>
                            <Label size="m">Your contract</Label>
                            <SummaryList noBorder>
                                <SummaryList.Row>
                                    <SummaryList.Key>Reference number</SummaryList.Key>
                                    <SummaryList.Value>{dsfcData?.contractNumber}</SummaryList.Value>
                                </SummaryList.Row>
                                <SummaryList.Row>
                                    <SummaryList.Key>End date</SummaryList.Key>
                                    <SummaryList.Value>{getDate(dsfcData?.endDate)}</SummaryList.Value>
                                </SummaryList.Row>
                            </SummaryList>
                            <Label>Once you have downloaded your Data Sharing Framework Contract (DSFC), consider changing the file name to something that will help you easily locate it. </Label>
                            <div className="nhsuk-u-padding-bottom-5"></div>
                            <Attachment>
                                <Attachment.Thumbnail>
                                </Attachment.Thumbnail>
                                <Attachment.Details>
                                    <Attachment.Heading>
                                        <Attachment.Link href={pdfURL} target="_blank">Data Sharing Framework Contract {dsfcData?.version}</Attachment.Link>
                                    </Attachment.Heading>
                                    <Attachment.Content >
                                        <Attachment.Property>PDF</Attachment.Property>,   <Attachment.Property>less than 1 MB</Attachment.Property>
                                    </Attachment.Content>
                                </Attachment.Details>
                            </Attachment>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default DSFCLatestSignedContract;
