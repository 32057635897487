import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetNewAndExistingDSAList_URL } from "../../../config/api-endpoints.config";
import { getCookie, getDate, isGuidEmpty, updatePageTitle, fetchInterceptor } from "../../../Helper/Utility";
import { AppDispatch } from "../../../state/app/store";
import { useAppDispatch, useAppSelector } from "../../../state/app/hooks";
import { IApiResActiveAgreementList, IApiResNewAndExistingDSAList, IApplication, IController, IControllerList, IDataSet, IDataSetList, IHolder, IProcessor, IProcessorList, IPurpose, ITaskEnableSectionMultiSelect, IUserDetails } from "../../../state/models/app.interface";
import { getDSADetails, resetDSAApplicationDetailsData, setDSAApplicationDetailsData, setDSADetailsData } from "../../../state/slice/DSA/DSA.slice";
import { Button, Details, InsetText, SummaryList, Table, Tag } from "nhsuk-react-components";
import { getUserDetails } from "../../../state/slice/Header/Header.slice";
import { dsaContent, applicationStatus, pageTitles, routeName, fileTypeSize, ApplicationwithOptions, ProcessStageOptions } from "../../../config/page.config";
import { setControllerDetailsData } from "../../../state/slice/DSAAboutController/DSAAboutController.slice";
import { getProcessorDetails, setProcessorDetails } from "../../../state/slice/Processor/Processor.slice";
import { getControllerListDetails, setControllerListDetailsData } from "../../../state/slice/ControllerList/ControllerList.slice";
import { getProcessorListDetails, setProcessorListDetailsData } from "../../../state/slice/ProcessorList/ProcessorList.slice";
import { getDataSetDetails, setDataSetDetailsData } from "../../../state/slice/DataSet/DataSet.slice";
import { getDataSetListDetails, setDataSetListDetailsData } from "../../../state/slice/DataSetList/DataSetList.slice";
import { getPurposeDetails, setPurposeDetailsData } from "../../../state/slice/Purpose/Purpose.slice";

const ApplyDSA: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;

    const UserDetailsData: IUserDetails = useAppSelector(getUserDetails);
    const initialStateofUserData: IUserDetails = UserDetailsData || {};
    const [userData, setUserData] = useState(initialStateofUserData);

    const initialState: IHolder = {};
    const [dsaData, setDSAData] = useState(initialState);

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData, setDsaApplicationData] = useState(initialApplicationState);

    const [showDSAs, setShowDSAs] = useState(false);
    const [showNewApplication, setShowNewApplication] = useState(false);
    const [showManageApplication, setShowManageApplication] = useState(false);
    const [showApproveApplication, setShowAprroveApplication] = useState(false);
    const [hasApplyDSAs, setHasApplyDSAs] = useState(false);

    const controllerListDetailsData: IControllerList = useAppSelector(getControllerListDetails);
    const initialControllerListState: IControllerList = controllerListDetailsData || {};
    const [controllerListData] = useState(initialControllerListState);

    const processorListDetailsData: IProcessorList = useAppSelector(getProcessorListDetails);
    const initialProcessorListState: IProcessorList = processorListDetailsData || {};
    const [processorListData] = useState(initialProcessorListState);
    const { dsaApproverSecurity, dsaApplicantSecurity } = useAppSelector(getUserDetails);


    const saveDSADataInStore = (stateData?: IHolder) => {
        dispatch(setDSADetailsData({ ...dsaData, ...stateData }));
    }
    const resetdataInStore = (stateData?: IHolder) => {
        dispatch(setDSADetailsData({ ...dsaData, ...stateData }));
    }

    const saveDSAApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    const resetDSAApplicationdataInStore = () => {
        dispatch(resetDSAApplicationDetailsData());
    }
    const saveControllerListDataInStore = (stateData?: IControllerList) => {
        dispatch(setControllerListDetailsData({ ...controllerListData, ...stateData }));
    }

    const saveProcessorListDataInStore = (stateData?: IProcessorList) => {
        dispatch(setProcessorListDetailsData({ ...processorListData, ...stateData }));
    }

    const resetStoreData = () => {
        let updatedState: IHolder = {};
        updatedState = {
            ...dsaData,
            applicationDetails: [{}],
            existingApplicationDetails: [{}],
            awaitingSignatureApplicationDetails: [{}],
            activeAgreementDetails: [{}],
            id: "",
            referenceNumber: ""
        }
        resetdataInStore(updatedState);
        resetDSAApplicationdataInStore();
    };

    const initialStateController: IController = {};
    const [controllerData] = useState(initialStateController);

    const processorDetailsData: IProcessor = useAppSelector(getProcessorDetails);
    const initialStateOfProcessor: IProcessor = processorDetailsData || {};
    const [processorData, seProcessortData] = useState(initialStateOfProcessor);

    const dataSetDetails: IDataSet = useAppSelector(getDataSetDetails);
    const initialDataSetState: IDataSet = dataSetDetails || {};
    const [dataSetData, setDataSetData] = useState(initialDataSetState);

    const dataSetListDetails: IDataSetList = useAppSelector(getDataSetListDetails);
    const initialDataSetListState: IDataSetList = dataSetListDetails || {};
    const [dataSetListData] = useState(initialDataSetListState);

    const purposeData = useAppSelector(getPurposeDetails) || {};

    const saveDataListInStore = (stateData?: IDataSetList) => {
        dispatch(setDataSetListDetailsData({ ...dataSetListData, ...stateData }));
    }
    const resetDataSetDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }
    const resetControllerDataInStore = (stateData?: IController) => {
        dispatch(setControllerDetailsData({ ...controllerData, ...stateData }));
    }
    const resetProcessorDataInStore = (stateData?: IProcessor) => {
        dispatch(setProcessorDetails({ ...processorData, ...stateData }));
    }
    const resetPurposeDataInStore = (stateData?: IPurpose) => {
        dispatch(setPurposeDetailsData({ ...purposeData, ...stateData }));
    }

    const resetControllerStoreData = () => {
        let updatedState: IController = {};
        updatedState = {
            ...controllerData,
            isJointController: false,
            isCompleted: false,
            aboutControllerSectionStatus: "",
            evidenceFileSummary: []
        }
        resetControllerDataInStore(updatedState);

    };

    const resetControllerListData = () => {
        let updatedState: IControllerList = {};
        updatedState = {
            activeController: 0,
            controllerDetailsPage: "",
            controllerList: [controllerData]
        }
        saveControllerListDataInStore(updatedState);
    }
    const resetProcessorListData = () => {
        let updatedState: IProcessorList = {};
        updatedState = {
            activeProcessor: 0,
            processorDetailsPage: "",
            processorList: [processorData]
        }
        saveProcessorListDataInStore(updatedState);
    }
    const resetProcessorStoreData = () => {
        let updatedState: IProcessor = {};
        updatedState = {
            ...processorData,
            isCompleted: false,
            aboutProcessorSectionStatus: "",
            evidenceFileSummary: []
        }
        resetProcessorDataInStore(updatedState);
    };

    const resetDataSetStoreData = () => {
        let updatedState: IDataSet = {};
        updatedState = {
            ...dataSetData,
            aboutDataSectionStatus: ""
        }
        resetDataSetDataInStore(updatedState);

    };

    const resetDataSetListData = () => {
        let updatedState: IDataSetList = {};
        updatedState = {
            activeDataSet: 0,
            associatedProduct: [dataSetData]
        }
        saveDataListInStore(updatedState);
    }

    const resetPurposeData = () => {
        let updatedState: IPurpose = {}
        updatedState = {
            ...purposeData,
            aboutExpectedOutputsAndBenefitsSectionStatus: "",
            aboutObjectiveAndProcessingSectionStatus: ""
        }
        resetPurposeDataInStore(updatedState);
    }

    const getApplyDSADetails = () => {
        const parameters: string = JSON.stringify(authenticationTokenCookie);
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(GetNewAndExistingDSAList_URL, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                setDSADetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    };

    const setDSADetails = (response: IApiResNewAndExistingDSAList) => {
        const dsaList = response;
        let updatedState: IHolder = {};
        let dsaApplicationDetail: IApplication = {};
        let dsaNewApplicationDetailsList: IApplication[] = [];
        let dsaExistingApplicationDetailsList: IApplication[] = [];
        let dsaApproverList: IApplication[] = [];
        if (dsaList.NewApplicationList.length > 0 || dsaList.ExistingApplicationList.length > 0 || dsaList.DSAApprovalList.length > 0) {
            if (dsaList.NewApplicationList.length > 0) {
                setShowNewApplication(true);
                dsaList.NewApplicationList.map((dsaApplication: IApiResActiveAgreementList) => {
                    dsaApplicationDetail = {
                        id: dsaApplication.Id,
                        referenceNumber: dsaApplication.ReferenceNumber,
                        title: dsaApplication.ApplicationTitle,
                        status: applicationStatus[dsaApplication?.ApplicationStatusCustom!],
                        firstSubmitDate: getDate(dsaApplication.CustomerSubmittedDate),
                        processStage: dsaApplication.ProcessStage,
                        applicationWith: dsaApplication.ApplicationWith,
                        readyToProceed2d: dsaApplication.ReadyToProceed2d
                    }
                    dsaNewApplicationDetailsList.push(dsaApplicationDetail);
                })
            }
            if (dsaList.ExistingApplicationList.length > 0) {
                setShowManageApplication(true);
                dsaList.ExistingApplicationList.map((dsaApplication: IApiResActiveAgreementList) => {
                    dsaApplicationDetail = {
                        id: dsaApplication.Id,
                        referenceNumber: dsaApplication.ReferenceNumber,
                        title: dsaApplication.ApplicationTitle,
                        status: applicationStatus[dsaApplication?.ApplicationStatusCustom!],
                        firstSubmitDate: getDate(dsaApplication.CustomerSubmittedDate),
                        applicationChangeType: dsaApplication.ApplicationChangeType,
                        processStage: dsaApplication.ProcessStage,
                        applicationWith: dsaApplication.ApplicationWith,
                        readyToProceed2d: dsaApplication.ReadyToProceed2d
                    }
                    dsaExistingApplicationDetailsList.push(dsaApplicationDetail);
                })
            }
            if (dsaList.DSAApprovalList.length > 0) {
                setShowAprroveApplication(true);
                dsaList.DSAApprovalList.map((dsaApplication: IApiResActiveAgreementList) => {
                    dsaApplicationDetail = {
                        id: dsaApplication.Id,
                        referenceNumber: dsaApplication.ReferenceNumber,
                        title: dsaApplication.ApplicationTitle,
                        status: applicationStatus[dsaApplication?.ApplicationStatusCustom!],
                        firstSubmitDate: getDate(dsaApplication.CustomerSubmittedDate),
                        numberOfSignatures: dsaApplication.NumberOfSignatures,
                        numberOfSignees: dsaApplication.NumberOfSignees,
                        startDate: dsaApplication.DsaStartDate,
                        endDate: dsaApplication.DsaEndDate,
                        dataControllerId: dsaApplication.DataControllerId,
                        isorganisationpaying: dsaApplication.Isorganisationpaying,
                        poNumber: dsaApplication.PONumber,
                        isRequiredPOnumber: dsaApplication.PurchaseOrderNumberNotRequired
                    }
                    dsaApproverList.push(dsaApplicationDetail);
                })
            }
            updatedState = {
                ...dsaData,
                applicationDetails: dsaNewApplicationDetailsList,
                awaitingSignatureApplicationDetails: dsaApproverList,
                existingApplicationDetails: dsaExistingApplicationDetailsList
            }
            setDSAData(updatedState);
            saveDSADataInStore(updatedState);
            setHasApplyDSAs(true);
        }
        setShowDSAs(true);
    }

    const navigateToBeforeYouStart = () => {
        navigate(routeName.dsaBeforeYouStart);
    }

    const setStatusTag = (status: string) => {
        switch (status) {
            case applicationStatus[1001]:
                return (
                    <Tag color="blue">{status.replace(/&nbsp;/g, ' ')}</Tag>
                );
            case applicationStatus[1002]:
            case applicationStatus[1003]:
            case applicationStatus[1005]:
            case applicationStatus[1006]:
            case applicationStatus[1008]:
                return (
                    <Tag>{status}</Tag>
                );
            case applicationStatus[1004]:
            case applicationStatus[1007]:
            case applicationStatus[1011]:
                return (
                    <Tag color="orange">{status.replace(/&nbsp;/g, ' ')}</Tag>
                );
            case applicationStatus[1012]:
            case applicationStatus[1013]:
                return (
                    <Tag>{status.replace(/&nbsp;/g, ' ')}</Tag>
                );
        }
    }

    const showActionLinks = (status: string, applicationId: string, referenceNumber: string, title: string, firstSubmitDate: string, endDate?: string, dataControllerId?: string, numberOfSignatures?: number, numberOfSignees?: number, applicationChangeValue?: string, isorganisationpaying?: boolean, applicationWith?: number, processStage?: number, poNumber?: string, purchaseOrderNotRequired?: boolean, readyToProceed2d?: boolean) => {
        switch (status) {
            case applicationStatus[1001]:
            case applicationStatus[1004]:
                return (
                    <>
                        {applicationWith === ApplicationwithOptions.WithCustomer && (processStage === ProcessStageOptions.ApplicationInProgress || (processStage === ProcessStageOptions.ActionPlanActive && readyToProceed2d)) ?
                           <><a href="#" onClick={(e) => openDSALink(applicationId, referenceNumber, applicationChangeValue!, e)}>Open</a><br /> </>:""}
                        <a href="#" className="nhsuk_downloadOnclick" onClick={(e) => onDownload(e, applicationId, referenceNumber, title, firstSubmitDate)}>Download&nbsp;(PDF)</a>
                    </>
                );
            case applicationStatus[1002]:
            case applicationStatus[1003]:
            case applicationStatus[1005]:
            case applicationStatus[1006]:
            case applicationStatus[1007]:
                return (
                    <a href="#" className="nhsuk_downloadOnclick" onClick={(e) => onDownload(e, applicationId, referenceNumber, title, firstSubmitDate)}>Download&nbsp;(PDF)</a>
                );
            case applicationStatus[1011]:
                return (
                    !isGuidEmpty(dataControllerId!) ? <a href="#" onClick={(e) => OpenApprovalLink(e, applicationId, referenceNumber, title, firstSubmitDate, endDate!, dataControllerId!, numberOfSignatures!, numberOfSignees!, status, isorganisationpaying!, poNumber!, purchaseOrderNotRequired!)}>Open</a> : dsaContent.blankText
                );
            case applicationStatus[1012]:
                return (
                    !isGuidEmpty(dataControllerId!) ? <a href="#" onClick={(e) => ViewApplication(e, applicationId, referenceNumber, title, firstSubmitDate, endDate!, dataControllerId!, status)}>View</a> : dsaContent.blankText

                );
            case applicationStatus[1013]:
                return (
                    !isGuidEmpty(dataControllerId!) ? <a href="#" onClick={(e) => openClosedApplication(e, applicationId, referenceNumber, title)}>Open</a> : dsaContent.blankText
                );
        }
    }

    const onDownload = (event: React.MouseEvent<HTMLAnchorElement>, applicationId: string, referencenumber: string, title: string, firstSubmitDate: string) => {
        event.preventDefault();
        let updatedState: IApplication = {};
        updatedState = {
            ...dsaApplicationData,
            id: applicationId,
            referenceNumber: referencenumber,
            title,
            firstSubmitDate
        }
        setDSAApplicationDetailsData(updatedState);
        saveDSAApplicationDataInStore(updatedState);
        navigate(routeName.downloadApplyDsa);
    }

    const initialStateOfTaskEnable: ITaskEnableSectionMultiSelect = {
        value: 0
    }

    const openDSALink = (applicationId: string, referenceNumber: string, applicationChangeValue: string, e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        let updatedState: IApplication = {};
        updatedState = {
            id: applicationId,
            referenceNumber: referenceNumber,
            taskEnableSectionMultiSelect: [initialStateOfTaskEnable],
            returningJourneyType: "",
            applicationChangeType: applicationChangeValue,
            returningFromExtendRenewAmend: "",
            isFromApplyDsa: true
        }
        setDsaApplicationData(updatedState);
        saveDSAApplicationDataInStore(updatedState);
        navigate(routeName.dsaTaskList);
}
    /* Open Approaval Link for Awaiting Signature*/

    const OpenApprovalLink = (event: React.MouseEvent<HTMLAnchorElement>, applicationId: string, referenceNumber: string, title: string, startDate: string, endDate: string, dataControllerId: string, numberOfSignatures: number, numberOfSignees: number, status: string, isorganisationpaying: boolean, poNumber: string, purchaseOrderNotRequired: boolean) => {
        event.preventDefault();
        let updatedState: IApplication = {};
        updatedState = {
            ...dsaApplicationData,
            id: applicationId,
            referenceNumber: referenceNumber,
            title: title,
            startDate: startDate,
            endDate: endDate,
            dataControllerId: dataControllerId,
            numberOfSignatures: numberOfSignatures,
            numberOfSignees: numberOfSignees,
            status: status,
            isorganisationpaying: isorganisationpaying,
            poNumber: poNumber,
            isRequiredPOnumber: purchaseOrderNotRequired
        }
        setDSAApplicationDetailsData(updatedState);
        saveDSAApplicationDataInStore(updatedState);
        isorganisationpaying ? navigate(routeName.poNumber) : navigate(routeName.dsaArrove); 
    }

    const ViewApplication = (event: React.MouseEvent<HTMLAnchorElement>, applicationId: string, referenceNumber: string, title: string, startDate: string, endDate: string, dataControllerId: string, status: string) => {
        event.preventDefault();
        let updatedState: IApplication = {};
        updatedState = {
            ...dsaApplicationData,
            id: applicationId,
            referenceNumber: referenceNumber,
            title: title,
            startDate: getDate(startDate),
            endDate: getDate(endDate),
            dataControllerId: dataControllerId,
            status: status
        }
        setDSAApplicationDetailsData(updatedState);
        saveDSAApplicationDataInStore(updatedState);
        navigate(routeName.dsaConfirmation);
    }

    const openClosedApplication = (event: React.MouseEvent<HTMLAnchorElement>, applicationId: string, referencenumber: string, title: string) => {
        event.preventDefault();
        let updatedState: IApplication = {};
        updatedState = {
            ...dsaApplicationData,
            id: applicationId,
            referenceNumber: referencenumber,
            title: title
        }
        setDSAApplicationDetailsData(updatedState);
        saveDSAApplicationDataInStore(updatedState);
        navigate(routeName.closingAgreement);
    }

    useEffect(() => {
        resetStoreData();
        resetControllerStoreData();
        resetProcessorStoreData();
        resetDataSetStoreData();
        resetDataSetListData();
        resetControllerListData();
        resetProcessorListData();
        resetPurposeData();
        setUserData(UserDetailsData);
    }, [UserDetailsData]);

    useEffect(() => {
        updatePageTitle(pageTitles.applydsa);
    }, []);

    useEffect(() => {
        if (!userData.pageLoader) {
            getApplyDSADetails();
        }
    }, [userData.pageLoader]);

    return (
        <div className="nhsuk-width-container">
            <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                <div className="nhsuk-grid-row">
                    {!showDSAs ?
                        <div className="nhsuk-grid-column-full">
                            <div className="nhsuk-loader__container">
                                <h1 className="nhsuk-heading-l">Loading results</h1>
                            </div>
                            <div className="nhsuk-loader__container">
                                <div className="nhsuk-loader">
                                </div>
                            </div>
                        </div>
                        : (hasApplyDSAs ?
                            <div className="nhsuk-width-container">
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                    {userData?.userOrgName}
                                </span>
                                <h1>Manage applications</h1>
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                {showApproveApplication && dsaApproverSecurity ?
                                    <>
                                        <h3>Approve Applications
                                            <div className="nhsuk-hint">
                                                Here, you can see applications that are waiting approval. </div>
                                        </h3>
                                        <Table responsive>
                                            <Table.Head>
                                                <Table.Row>
                                                    <Table.Cell>Reference number</Table.Cell>
                                                    <Table.Cell>Title</Table.Cell>
                                                    <Table.Cell>First submitted</Table.Cell>
                                                    <Table.Cell>Status</Table.Cell>
                                                    <Table.Cell>Action</Table.Cell>
                                                </Table.Row>
                                            </Table.Head>
                                            <Table.Body>
                                                {
                                                    dsaData.awaitingSignatureApplicationDetails?.map((dsaApplicationDetail: IApplication, index: number) => (
                                                        <Table.Row key={index + 1} id={dsaApplicationDetail?.id}>
                                                            <Table.Cell>{dsaApplicationDetail?.referenceNumber ? dsaApplicationDetail?.referenceNumber : dsaContent.blankText}</Table.Cell>
                                                            <Table.Cell className="nhsuk-word-break">{dsaApplicationDetail?.title ? dsaApplicationDetail?.title : dsaContent.blankText}</Table.Cell>
                                                            <Table.Cell>{dsaApplicationDetail?.firstSubmitDate ? dsaApplicationDetail?.firstSubmitDate : dsaContent.blankText}</Table.Cell>
                                                            <Table.Cell className="nhsuk-u-nowrap">{setStatusTag(dsaApplicationDetail?.status!)}

                                                                <p> (SIGNED BY {dsaApplicationDetail.numberOfSignatures} OF {dsaApplicationDetail.numberOfSignees} APPROVERS) </p>
                                                            </Table.Cell>
                                                            <Table.Cell className="nhsuk-u-text-align-right">
                                                                {showActionLinks(dsaApplicationDetail?.status!, dsaApplicationDetail?.id!, dsaApplicationDetail?.referenceNumber!, dsaApplicationDetail?.title!, dsaApplicationDetail?.startDate!, dsaApplicationDetail?.endDate, dsaApplicationDetail?.dataControllerId, dsaApplicationDetail?.numberOfSignatures!, dsaApplicationDetail?.numberOfSignees, dsaApplicationDetail?.applicationChangeType, dsaApplicationDetail.isorganisationpaying!, 0, 0, dsaApplicationDetail.poNumber!, dsaApplicationDetail.isRequiredPOnumber!)}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )
                                                    )
                                                }
                                            </Table.Body>
                                        </Table>
                                        <div className="nhsuk-u-padding-bottom-7"></div>
                                    </> : null}                                
                                {showNewApplication && dsaApplicantSecurity ?
                                    <>
                                        <h3>New Applications
                                            <div className="nhsuk-hint">
                                                Here, you can see new applications that you have made.</div>
                                        </h3>
                                        <Table responsive>
                                            <Table.Head>
                                                <Table.Row>
                                                    <Table.Cell>Reference number</Table.Cell>
                                                    <Table.Cell>Title</Table.Cell>
                                                    <Table.Cell>First submitted</Table.Cell>
                                                    <Table.Cell>Status</Table.Cell>
                                                    <Table.Cell>Action</Table.Cell>
                                                </Table.Row>
                                            </Table.Head>
                                            <Table.Body>
                                                {
                                                    dsaData.applicationDetails?.map((dsaApplicationDetail: IApplication, index: number) => (
                                                        <Table.Row key={index + 1} id={dsaApplicationDetail?.id}>
                                                            <Table.Cell>{dsaApplicationDetail?.referenceNumber ? dsaApplicationDetail?.referenceNumber : dsaContent.blankText}</Table.Cell>
                                                            <Table.Cell className="nhsuk-word-break">{dsaApplicationDetail?.title ? dsaApplicationDetail?.title : dsaContent.blankText}</Table.Cell>
                                                            <Table.Cell>{dsaApplicationDetail?.firstSubmitDate ? dsaApplicationDetail?.firstSubmitDate : dsaContent.blankText}</Table.Cell>
                                                        <Table.Cell className="nhsuk-u-nowrap">{setStatusTag(dsaApplicationDetail?.status!)}</Table.Cell>
                                                            <Table.Cell className="nhsuk-u-text-align-right">
                                                                {showActionLinks(dsaApplicationDetail?.status!, dsaApplicationDetail?.id!, dsaApplicationDetail?.referenceNumber!, dsaApplicationDetail?.title!, dsaApplicationDetail?.firstSubmitDate!, "", "", 0, 0, dsaApplicationDetail?.applicationChangeType, false, dsaApplicationDetail?.applicationWith!, dsaApplicationDetail?.processStage!,"",false,dsaApplicationDetail.readyToProceed2d)}

                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )
                                                    )
                                                }
                                            </Table.Body>
                                        </Table>
                                        <div className="nhsuk-u-padding-bottom-7"></div>
                                    </> : null}                                
                                {showManageApplication && dsaApplicantSecurity ?
                                    <>
                                        <h3>Extension, renewal and amendment applications
                                            <div className="nhsuk-hint">
                                                Here, you can manage the changes you are making to existing Data Sharing Agreements (DSAs). Changes to an existing DSA are still considered an 'application', because you are 'applying' to make change.
                                            </div>
                                            <div className="nhsuk-hint">
                                                New extension, renewal and amendment applications can be created by going to &apos;Manage DSAs&apos; and selecting the &apos;Manage&apos; link next to the relevant DSA.
                                            </div>
                                        </h3>
                                        <Table responsive>
                                            <Table.Head>
                                                <Table.Row>
                                                    <Table.Cell>Reference number</Table.Cell>
                                                    <Table.Cell>Title</Table.Cell>
                                                    <Table.Cell>First submitted</Table.Cell>
                                                    <Table.Cell>Status</Table.Cell>
                                                    <Table.Cell>Action</Table.Cell>
                                                </Table.Row>
                                            </Table.Head>
                                            <Table.Body>
                                                {
                                                    dsaData.existingApplicationDetails?.map((dsaApplicationDetail: IApplication, index: number) => (
                                                        <Table.Row key={index + 1} id={dsaApplicationDetail?.id}>
                                                            <Table.Cell>{dsaApplicationDetail?.referenceNumber ? dsaApplicationDetail?.referenceNumber : dsaContent.blankText}</Table.Cell>
                                                            <Table.Cell className="nhsuk-word-break">{dsaApplicationDetail?.title ? dsaApplicationDetail?.title : dsaContent.blankText}</Table.Cell>
                                                            <Table.Cell>{dsaApplicationDetail?.firstSubmitDate ? dsaApplicationDetail?.firstSubmitDate : dsaContent.blankText}</Table.Cell>
                                                        <Table.Cell className="nhsuk-u-nowrap">{setStatusTag(dsaApplicationDetail?.status!)}</Table.Cell>

                                                            <Table.Cell className="nhsuk-u-text-align-right">
                                                                {showActionLinks(dsaApplicationDetail?.status!, dsaApplicationDetail?.id!, dsaApplicationDetail?.referenceNumber!, dsaApplicationDetail?.title!, dsaApplicationDetail?.firstSubmitDate!, "", "", 0, 0, dsaApplicationDetail?.applicationChangeType, false, dsaApplicationDetail?.applicationWith!, dsaApplicationDetail?.processStage!,"",false,dsaApplicationDetail.readyToProceed2d)}

                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )
                                                    )
                                                }
                                            </Table.Body>
                                        </Table>
                                    </> : null}
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                {dsaApplicantSecurity && <Button onClick={navigateToBeforeYouStart}>{dsaContent.btnNewApplication}</Button>}

                                <Details>
                                    <Details.Summary>What do these statuses mean?</Details.Summary>
                                    <Details.Text>
                                        <SummaryList>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag color="blue">{applicationStatus[1001]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Application has been started but is not yet completed</SummaryList.Value>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag>{applicationStatus[1002]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Application has been completed and submitted to DARS</SummaryList.Value>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag>{applicationStatus[1003]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Application is being reviewed by the application team</SummaryList.Value>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag color="orange">{(applicationStatus[1004]).replace(/&nbsp;/g, ' ')}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Application has been sent back to the applicant for changes</SummaryList.Value>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag>{applicationStatus[1005]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>When your application is ready for IGARD to review it, your Case Officer will let you know</SummaryList.Value>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag>{applicationStatus[1006]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Application is undergoing final checks, we may ask you for more information</SummaryList.Value>
                                            </SummaryList.Row>
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag color="orange">{applicationStatus[1007]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Application has been approved and awaits signature by all Controllers</SummaryList.Value>
                                            </SummaryList.Row>
                                            {
                                                dsaApproverSecurity ? (
                                                    <>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key><Tag color="orange">{applicationStatus[1011]}</Tag></SummaryList.Key>
                                                            <SummaryList.Value>Application is awaiting a signature by one or more Approvers </SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key><Tag>{applicationStatus[1012]}</Tag></SummaryList.Key>
                                                            <SummaryList.Value>You have signed this application for a DSA </SummaryList.Value>
                                                        </SummaryList.Row>
                                                    </>
                                                ) : (null)
                                            }
                                            <SummaryList.Row>
                                                <SummaryList.Key><Tag>{applicationStatus[1013]}</Tag></SummaryList.Key>
                                                <SummaryList.Value>Your DSA is in the process of being closed. You cannot make changes to this DSA</SummaryList.Value>
                                            </SummaryList.Row>
                                        </SummaryList>
                                    </Details.Text>
                                </Details>
                            </div> :
                            <div className="nhsuk-width-container">

                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <h1>
                                    Applications for Data Sharing <br /> Agreements </h1>
                                <InsetText>
                                    <p>
                                        It looks like you do not currently have any new applications for Data Sharing Agreements (DSAs). If you think this is wrong, email <a href='mailto:data.applications@nhsdigital.nhs.uk'>data.applications@nhsdigital.nhs.uk</a> quoting your DSA reference number in the subject field. A DSA reference number usually begins with 'DARS-NIC'. </p>
                                    <p>
                                        DSAs can be found in 'Manage DSAs'.
                                    </p>
                                </InsetText>
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                {(dsaApplicantSecurity || dsaApproverSecurity) && <Button onClick={navigateToBeforeYouStart}>{dsaContent.btnNewApplication}</Button>}
                            </div>)}
                </div>
            </main>
        </div>
    )
}
export default ApplyDSA;