import { InsetText, Button, Fieldset, Radios, ErrorSummary } from "nhsuk-react-components";
import React, { useState } from "react";
import GoBack from "../../../../Components/Shared/GoBack";
import { useNavigate } from "react-router-dom";
import { controllerSection, dsaContent, dsaErrorMessage, evidenceErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader';
import { submitDSASectionStatus, submitSpinner } from "../../../../Helper/Utility";
import { IApplication, IEvidenceList } from "../../../../state/models/app.interface";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getEvidenceList, setEvidenceListData } from "../../../../state/slice/EvidenceList/EvidenceList.slice";

const UploadSupportingEvidence: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const [evidenceListDetails, setEevidenceListDetails] = useState(useAppSelector(getEvidenceList) || {});
    const [selectedValue, setSelectedValue] = useState('');

    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const handleNavigate = () => {
        if (selectedValue === dsaContent.yes) {
            navigate(routeName.evidenceType);
        }
        else if (selectedValue === dsaContent.no) {
            handleClickNavigate();
        }
    }
    const saveEvidenceListDataInStore = (stateData?: IEvidenceList) => {
        dispatch(setEvidenceListData({ ...setEevidenceListDetails, ...stateData }));
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(e.target.value);
        setSummaryLinkErrorMessage("");
        resetError(e);
    }
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
        }
    }
    const ValidateFields = () => {
        let isValid: boolean = true;
        if (selectedValue === '') {
            setSummaryLinkErrorMessage(`${evidenceErrorMessage.uploadEvidence_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }
    const handleClickNavigate = () => {
        let UpdateSectionStatus = taskListSectionStatus.completed;
        submitDSASectionStatus(dsaApplicationData?.id!, controllerSection.ListOfUploadedDocuments, UpdateSectionStatus)
            .then((data: Response) => {
                if (data) {
                    let updateState: IEvidenceList = {}
                    updateState = {
                        ...evidenceListDetails,
                        aboutEvidenceSectionStatus: taskListSectionStatus.completed
                    }
                    setEevidenceListDetails(updateState);
                    saveEvidenceListDataInStore(updateState);
                    navigate(routeName.dsaTaskList);
                }
            });

    }

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#uploaddocument">{summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )


    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, handleNavigate);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-three-quarters">
                            <GoBack condition={dsaContent.tasklist}></GoBack>
                            <h1>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    List of uploaded documents</span>
                                Uploading documents
                            </h1>
                            <InsetText>
                                <p> Are there any documents you want to upload to support your application such as protocol documents or data flow diagrams?                                </p>
                            </InsetText>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <Fieldset>
                            <Radios
                                name="uploaddocument"
                                id="uploaddocument"
                                error={summaryLinkErrorMessage}
                            >
                                <Radios.Radio value={dsaContent.yes} onChange={handleChange} >
                                    {dsaContent.yes}
                                </Radios.Radio>
                                <Radios.Radio value={dsaContent.no} onChange={handleChange}>{dsaContent.no}</Radios.Radio>
                                </Radios>
                            </Fieldset>
                            <div className="nhsuk-u-padding-bottom-6"></div>
                            <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                        </div>
                    </div>
                </main>
            </div>
        </>)
}
export default UploadSupportingEvidence;