import { Button, Checkboxes, Hint, Label } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../../Components/Loader/Loader";
import ControllerHeader from "../../../../../Components/Shared/ControllerHeader";
import ErrorDetails from "../../../../../Components/Shared/ErrorSummary";
import GoBack from "../../../../../Components/Shared/GoBack";
import { SubmitDSATaskEnableSection_URL, SubmitScreenDSAIntro_URL } from "../../../../../config/api-endpoints.config";
import { controllerSection, dsaContent, dsaErrorMessage, fileTypeSize, routeName } from "../../../../../config/page.config";
import { fetchInterceptor, getCookie, getDate, submitSpinner } from "../../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../../state/app/hooks";
import { AppDispatch } from "../../../../../state/app/store";
import { IApiResDSADetails, IApiResSectionList, IApplication, ITaskEnableSectionMultiSelect, IUserDetails } from "../../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../../state/slice/DSA/DSA.slice";
import { getUserDetails } from "../../../../../state/slice/Header/Header.slice";


const ChooseSection: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [chooseSectionErrorMessage, setChooseSectionErrorMessage] = useState("");
    const [loading, setLoading] = useState(true);

    const initialApplicationState: IApplication = useAppSelector(getDSADetails) || {};
    const [applicationData, setApplicationData] = useState(initialApplicationState);
    const saveDSAApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...applicationData, ...stateData }));
    }

    const [taskEnableSectionMultiSelect, setTaskEnableSectionMultiSelect] = useState<ITaskEnableSectionMultiSelect[]>([]);
    const dataAndParticipantList: ITaskEnableSectionMultiSelect[] = [controllerSection.aboutData, controllerSection.Participants, controllerSection.EthicsApproval]
    const organisationsInvolvedList = [controllerSection.aboutController, controllerSection.PrivacyNotice, controllerSection.aboutProcessor, controllerSection.ProcessingLocations, controllerSection.StorageLocations, controllerSection.Funding]
    const applyingForDataList = [controllerSection.ProjectTitleReason, controllerSection.ObjectiveAndProcessingActivities, controllerSection.ExpectedOutputsAndBenefits, controllerSection.CommercialPurposesAndSublicensing, controllerSection.YieldedBenefits]
    const applicationOverview: ITaskEnableSectionMultiSelect[] = [controllerSection.ListOfUploadedDocuments, controllerSection.SubmitApplication];
    const cumpulsoryValues: ITaskEnableSectionMultiSelect[] = [controllerSection.ListOfUploadedDocuments, controllerSection.SubmitApplication, controllerSection.ProjectTitleReason];
    const UserDetailsData: IUserDetails = useAppSelector(getUserDetails);
    const initialStateofUserData: IUserDetails = UserDetailsData || {};
    const [userData, setUserData] = useState(initialStateofUserData);
    const [taskSectionData, settaskSectionData] = useState<ITaskEnableSectionMultiSelect[]>([]);

    let taskSectionStatus: ITaskEnableSectionMultiSelect[] = [...taskEnableSectionMultiSelect]
    const pushSectionValue = (sectionList: ITaskEnableSectionMultiSelect[], isHandleChange?: boolean) => {
        let selectedValue: ITaskEnableSectionMultiSelect[] = (!isHandleChange || isHandleChange === undefined) ? taskSectionStatus : (applicationData?.taskEnableSectionMultiSelect![0]?.value !== 0) ? applicationData?.taskEnableSectionMultiSelect! : [];
        let taskSection: ITaskEnableSectionMultiSelect = {};
        sectionList?.forEach((items: ITaskEnableSectionMultiSelect) => {
            taskSection = {
                value: Number(items)
            };
            selectedValue = Object.assign([], selectedValue);
            selectedValue.push(taskSection);
        });
        setTaskEnableSectionMultiSelect(selectedValue!);
        return selectedValue;
    }

    const removeSectionValue = (sectionList: ITaskEnableSectionMultiSelect[]) => {
        let sectionSelected: ITaskEnableSectionMultiSelect[] = applicationData.taskEnableSectionMultiSelect!;
        sectionList?.forEach((item: ITaskEnableSectionMultiSelect) => {
            sectionSelected?.forEach((items: ITaskEnableSectionMultiSelect, index: number) => {
                if (String(items?.value) === String(item)) {
                    const copyArray = [...sectionSelected];
                    copyArray?.splice(index, 1);
                    sectionSelected = copyArray;
                }
            })
        })
        setTaskEnableSectionMultiSelect(sectionSelected);
        return sectionSelected;
    }

    const updateRecord = (data: ITaskEnableSectionMultiSelect[], e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IApplication = {};
        updatedState = {
            ...applicationData,
            [e.target.name]: e.target.checked,
            taskEnableSectionMultiSelect: data
        }
        setApplicationData(updatedState);
        saveDSAApplicationDataInStore(updatedState);
    }

    /*To handle change of Checkbox*/
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            if (e.target.value === dsaContent.dataAndParticipant) {
                let data = pushSectionValue(dataAndParticipantList, true)!;
                updateRecord(data, e);
            }
            if (e.target.value === dsaContent.organisationsInvolved) {
                let data = pushSectionValue(organisationsInvolvedList, true)!;
                updateRecord(data, e);
            }
            if (e.target.value === dsaContent.applyingForData) {
                let data = pushSectionValue(applyingForDataList, true)!;
                updateRecord(data, e);
            }
        }

        if (!e.target.checked) {
            if (e.target.value === dsaContent.dataAndParticipant) {
                let remoevdData = removeSectionValue(dataAndParticipantList);
                updateRecord(remoevdData, e);
            }
            if (e.target.value === dsaContent.organisationsInvolved) {
                let remoevdData = removeSectionValue(organisationsInvolvedList);
                updateRecord(remoevdData, e);
            }
            if (e.target.value === dsaContent.applyingForData) {
                let remoevdData = removeSectionValue(applyingForDataList);
                updateRecord(remoevdData, e);
            }
        }
        resetError(e);
    }

    const handleContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, submitDsaTaskSection);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setChooseSectionErrorMessage("");
        }
    }

    /* To Validate the each field's data */
    const validateFields = () => {
        let isValid: boolean = true;
        if (!applicationData.organisationsInvolved && !applicationData.dataAndParticipant && !applicationData.applyingForData) {
            setSummaryLinkErrorMessage(`${dsaErrorMessage.chooseSection_ErrorMessage}`);
            setChooseSectionErrorMessage(`${dsaErrorMessage.chooseSection_ErrorMessage}`);
            isValid = false;
        }

        return isValid;
    }
    const compulsoryMultiSelectValue = (sectionList: ITaskEnableSectionMultiSelect[]) => {
        let data: ITaskEnableSectionMultiSelect[] = pushSectionValue(sectionList, false)!;
        const uniqueValues = data.filter((val, id, array) => array.indexOf(val) === id);
        let updatedState: IApplication = {};
        updatedState = {
            ...applicationData,
            taskEnableSectionMultiSelect: uniqueValues
        }
        setApplicationData(updatedState);
        saveDSAApplicationDataInStore(updatedState);
        return uniqueValues;
    }
    const submitDsaTaskSection = () => {
        let taskValue: ITaskEnableSectionMultiSelect[] = compulsoryMultiSelectValue(cumpulsoryValues);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": applicationData.id !== "" ? applicationData.id : "",
            "TaskEnableSectionMultiSelect": taskValue!
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitDSATaskEnableSection_URL, requestOptions)
            .then(() => {
                navigate(routeName.dsaTaskList);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const getSectionStatus = async (applicationId: string) => {
        setLoading(true);
        try {
            const parameters: string = JSON.stringify({
                "UserAuthTokenId": authenticationTokenCookie,
                "ApplicationId": applicationId ? applicationId : ""
            });
            const requestOptions: Object = {
                method: 'POST',
                headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
                body: parameters
            };
            const response = await fetchInterceptor(SubmitScreenDSAIntro_URL, requestOptions).then(response => {
                return response.json();
            });
            const data = await response;
            if (data) {
                setSectionStatus(data);
            }
            return response;
        }
        catch {
            window.location.href = routeName.serviceError;
        }
    }

    const setSectionStatus = (data: IApiResDSADetails) => {

        const sectionData: IApiResSectionList[] = data.SectionList;
        let aboutControllerStatus = "";
        let aboutProcessorStatus = "";
        let aboutDataStatus = "";
        let aboutObjectiveAndProcessingStatus = "";
        let aboutExpectedOutputsAndBenefitsStatus = "";
        let commercialSublicensingStatus = "";
        let funding = "";
        let projectTitleReasons = "";
        let ethicsApproval = "";
        let privacyNotice = "";
        let processingLocations = "";
        let storageLocations = "";
        let participants = "";
        let listOfUploadedDocuments = "";
        let submitApplication = "";
        let yieldedBenefits = "";
        let applyingForData: boolean = false;
        let organisationsInvolved: boolean = false;
        let dataAndParticipant: boolean = false;
        let applicationOverview: boolean = false;

        if (sectionData) {
            for (let i = 0; i < sectionData.length; i++) {
                if (sectionData[i].SectionId === controllerSection.aboutController) {
                    aboutControllerStatus = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        organisationsInvolved = sectionData[i].Enable;
                    }
                }
                if (sectionData[i].SectionId === controllerSection.aboutProcessor) {
                    aboutProcessorStatus = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        organisationsInvolved = sectionData[i].Enable;
                    }
                }
                if (sectionData[i].SectionId === controllerSection.aboutData) {
                    aboutDataStatus = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        dataAndParticipant = sectionData[i].Enable;
                    }
                }
                if (sectionData[i].SectionId === controllerSection.ObjectiveAndProcessingActivities) {
                    aboutObjectiveAndProcessingStatus = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        applyingForData = sectionData[i].Enable;
                    }
                }
                if (sectionData[i].SectionId === controllerSection.ExpectedOutputsAndBenefits) {
                    aboutExpectedOutputsAndBenefitsStatus = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        applyingForData = sectionData[i].Enable;
                    }
                }

                if (sectionData[i].SectionId === controllerSection.ProjectTitleReason) {
                    projectTitleReasons = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        applyingForData = sectionData[i].Enable;
                    }
                }
                if (sectionData[i].SectionId === controllerSection.CommercialPurposesAndSublicensing) {
                    commercialSublicensingStatus = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        applyingForData = sectionData[i].Enable;
                    }
                }
                if (sectionData[i].SectionId === controllerSection.Funding) {
                    funding = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        organisationsInvolved = sectionData[i].Enable;
                    }
                }
                if (sectionData[i].SectionId === controllerSection.EthicsApproval) {
                    ethicsApproval = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        dataAndParticipant = sectionData[i].Enable;
                    }
                }
                if (sectionData[i].SectionId === controllerSection.PrivacyNotice) {
                    privacyNotice = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        organisationsInvolved = sectionData[i].Enable;
                    }
                }
                if (sectionData[i].SectionId === controllerSection.Participants) {
                    participants = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        dataAndParticipant = sectionData[i].Enable;
                    }
                }
                if (sectionData[i].SectionId === controllerSection.ProcessingLocations) {
                    processingLocations = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        organisationsInvolved = sectionData[i].Enable;
                    }
                }
                if (sectionData[i].SectionId === controllerSection.ListOfUploadedDocuments) {
                    listOfUploadedDocuments = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        applicationOverview = sectionData[i].Enable;
                    }
                }
                if (sectionData[i].SectionId === controllerSection.SubmitApplication) {
                    submitApplication = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        applicationOverview = sectionData[i].Enable;
                    }
                }
                if (sectionData[i].SectionId === controllerSection.StorageLocations) {
                    storageLocations = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        organisationsInvolved = sectionData[i].Enable;
                    }
                } if (sectionData[i].SectionId === controllerSection.YieldedBenefits) {
                    yieldedBenefits = sectionData[i].Status;
                    if (sectionData[i].Enable === true) {
                        applyingForData = sectionData[i].Enable;
                    }
                }

            }
        }
        let updatedState: IApplication = {};
        updatedState = {
            ...applicationData,
            referenceNumber: applicationData.referenceNumber,
            totalEstimatedCost: data.TotalChargeInclVat,
            section: data.SectionList as any,
            aboutControllerSectionStatus: aboutControllerStatus,
            aboutProcessorSectionStatus: aboutProcessorStatus,
            aboutDataSetSectionStatus: aboutDataStatus,
            aboutObjectiveAndProcessingSectionStatus: aboutObjectiveAndProcessingStatus,
            aboutExpectedOutputsAndBenefitsSectionStatus: aboutExpectedOutputsAndBenefitsStatus,
            commercialSublicensingSectionStatus: commercialSublicensingStatus,
            aboutFundingSectionStatus: funding,
            aboutEthicsApprovalSectionStatus: ethicsApproval,
            aboutPrivacyNoticeSectionStatus: privacyNotice,
            aboutParticipantSectionStatus: participants,
            aboutEvidenceSectionStatus: listOfUploadedDocuments,
            aboutProcessingLocationSectionStatus: processingLocations,
            aboutStorageLocationSectionStatus: storageLocations,
            aboutYieldedBenefitsSectionStatus: yieldedBenefits,
            startDate: getDate(data.DSAStartDate),
            organisationsInvolved: applicationData.isFromApplyDsa ? organisationsInvolved : false,
            applyingForData: applicationData.isFromApplyDsa ? applyingForData : false,
            applicationOverview: applicationData.isFromApplyDsa ? applicationOverview : false,
            dataAndParticipant: applicationData.isFromApplyDsa ? dataAndParticipant : false,
            projectTitleReasons: projectTitleReasons,
            returningFromExtendRenewAmend: dsaContent.isFromAmend
        }
        addselectSection(updatedState);
    }

    const addselectSection = (updatedState: IApplication) => {
        if (updatedState.organisationsInvolved || updatedState.dataAndParticipant || updatedState.applyingForData || updatedState.applicationOverview) {
            if (updatedState.organisationsInvolved) {
                let data: ITaskEnableSectionMultiSelect[] = pushSectionValue(organisationsInvolvedList)!;
                pushSectionValueOnLoad(data, updatedState);
            }
            if (updatedState.dataAndParticipant) {
                let data: ITaskEnableSectionMultiSelect[] = pushSectionValue(dataAndParticipantList)!;
                pushSectionValueOnLoad(data, updatedState);
            }
            if (updatedState.applyingForData) {
                let data: ITaskEnableSectionMultiSelect[] = pushSectionValue(applyingForDataList)!;
                pushSectionValueOnLoad(data, updatedState);
            }
            if (updatedState.applicationOverview) {
                let data: ITaskEnableSectionMultiSelect[] = pushSectionValue(applicationOverview)!;
                pushSectionValueOnLoad(data, updatedState);
            }
        }
        else {
            setApplicationData(updatedState);
            saveDSAApplicationDataInStore(updatedState);
        }
        setLoading(false);
    }

    let arrayList: ITaskEnableSectionMultiSelect[] = taskSectionData;
    const pushSectionValueOnLoad = (data: ITaskEnableSectionMultiSelect[], updatedState: IApplication) => {
        let updatedStateSection: IApplication = {};
        let selectedData: ITaskEnableSectionMultiSelect[] = data;
        arrayList = Object.assign([], arrayList);
        arrayList = [...arrayList, ...selectedData];
        updatedStateSection = {
            ...updatedState,
            taskEnableSectionMultiSelect: arrayList
        }
        setApplicationData(updatedStateSection);
        saveDSAApplicationDataInStore(updatedStateSection);
        settaskSectionData(arrayList);
    }

    const initialStateOfTaskEnable: ITaskEnableSectionMultiSelect = {
        value: 0
    }

    const resetApplicationData = () => {
        let updatedState: IApplication = {};
        updatedState = {
            id: applicationData.id,
            referenceNumber: applicationData.referenceNumber,
            taskEnableSectionMultiSelect: [initialStateOfTaskEnable]
        }
        setApplicationData(updatedState);
        saveDSAApplicationDataInStore(updatedState);
        let arrayList = (updatedState?.taskEnableSectionMultiSelect!.length > 0 && updatedState?.taskEnableSectionMultiSelect![0]?.value !== 0) ? updatedState?.taskEnableSectionMultiSelect! : [];
        settaskSectionData(arrayList);
    }

    useEffect(() => {
        resetApplicationData();
        getSectionStatus(applicationData.id!);
        setUserData(UserDetailsData);
    }, []);

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ? <Loader loadingText={dsaContent.txtLoading} /> :
                            <>
                                <div className="nhsuk-grid-column-two-thirds">
                                    <GoBack condition={dsaContent.amendIntro} />
                                    <h1>
                                        <span className="nhsuk-caption-l nhsuk-caption--bottom ">{userData?.userOrgName} </span>
                                        Tell us which sections you would like to edit </h1>
                                    {summaryLinkErrorMessage !== "" && <ErrorDetails errorDescription={chooseSectionErrorMessage} errorHref="#section"></ErrorDetails>}

                                    <Label>Select all options that are relevant to you.</Label>
                                    <div className="nhsuk-u-padding-bottom-4">
                                    </div>
                                    <Checkboxes name="section" id="section" error={chooseSectionErrorMessage}>
                                        <Checkboxes.Box id={dsaContent.organisationsInvolved} name={dsaContent.organisationsInvolved} type="checkbox" value={dsaContent.organisationsInvolved} checked={applicationData.organisationsInvolved} onChange={handleChange}>
                                            1. About the organisations involved
                                            <Hint><ul>
                                                <li>about the Controller</li>
                                                <li>privacy notice</li>
                                                <li>about the Processor </li>
                                                <li>processing locations </li>
                                                <li>storage locations </li>
                                                <li>funding </li>
                                            </ul></Hint>
                                        </Checkboxes.Box>
                                        <Checkboxes.Box id={dsaContent.applyingForData} name={dsaContent.applyingForData} checked={applicationData.applyingForData} value={dsaContent.applyingForData} onChange={handleChange}>
                                            2. Why you are applying for the data
                                            <Hint><ul>
                                                <li>data request reason and project name </li>
                                                <li>objective(s) and processing activities </li>
                                                <li>yielded benefits </li>
                                                <li>expected outputs and benefits</li>
                                                <li>commercial purposes and sub-licensing </li>
                                            </ul></Hint>
                                        </Checkboxes.Box>
                                        <Checkboxes.Box id={dsaContent.dataAndParticipant} name={dsaContent.dataAndParticipant} checked={applicationData.dataAndParticipant} value={dsaContent.dataAndParticipant} onChange={handleChange}>
                                            3. Data and participants
                                            <Hint>
                                                <ul>
                                                    <li>about the data </li>
                                                    <li>about the Participants</li>
                                                    <li>Ethics Approval</li>
                                                </ul>
                                            </Hint>
                                        </Checkboxes.Box>
                                    </Checkboxes>
                                    <div className="nhsuk-u-padding-bottom-4">
                                    </div>
                                    <Button name="continue" id="nhsuk-button-spinner" onClick={handleContinue}>{dsaContent.btnTextContinue}</Button>
                                </div>
                            </>}
                    </div>
                </main>
            </div>
        </>
    )
}
export default ChooseSection;
