import { Button, Fieldset, InsetText, Radios } from "nhsuk-react-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitScreenCommercialPurposeAndSublicensing_URL } from "../../../../config/api-endpoints.config";
import { dsaContent, fileTypeSize, purposeErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IPurpose } from "../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getPurposeDetails, setPurposeDetailsData } from "../../../../state/slice/Purpose/Purpose.slice";

const Sublicensing: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [purposeData, setPurposeData] = useState<IPurpose>(useAppSelector(getPurposeDetails) || {});
    const [sublicensingErrorMessage, setSublicensingErrorMessage] = useState("");
    const savePurposeDataInStore = (stateData?: IPurpose) => {
        dispatch(setPurposeDetailsData({ ...purposeData, ...stateData }));
    }
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const isFromAnswerPage = purposeData?.commercialSublicensingCheckYourAnswerPage;

    /* Function to set the state value on change of Radios(yes/no) */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IPurpose = {};
        updatedState = {
            ...purposeData,
            [e.target.name]: e.target.value,
            isPurposeUpdated: true
        }
        setPurposeData(updatedState);
        savePurposeDataInStore(updatedState);
        resetError(e);
    }
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSublicensingErrorMessage("");
        }
    };
    /* Function to set the state value on change of Radios(yes/no) */

    /* To handle Onclick */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            if (isFromAnswerPage !== undefined && isFromAnswerPage != '')
                submitSpinner(e, submitSublicensing());
            else
                submitSpinner(e, navigateSublicensing());
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    /* To Validate the each field's data */
    const ValidateFields = () => {
        let isValid: boolean = true;
        if (!purposeData.doesSublicensingApply) {
            setSublicensingErrorMessage(`${purposeErrorMessage.selectSublicensing_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const navigateSublicensing = () => {
        if (purposeData.doesSublicensingApply === dsaContent.yes) {
            navigate(routeName.sublicensingDuration);
        } else {
            submitSublicensing();
        }
    }

    const submitSublicensing = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "ApplicationForCommercialPurposes": purposeData.applicationForCommercialPurposes === dsaContent.yes ? true : false,
            "ApplicationForCommercialPurposesExplanation": purposeData.applicationForCommercialPurposesExplanation,
            "DoesSublicensingApply": purposeData.doesSublicensingApply === dsaContent.yes ? true : false,
            "Duration": purposeData.doesSublicensingApply === dsaContent.yes ? purposeData.duration : null,
            "SectionStatus": dsaApplicationData.commercialSublicensingSectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitScreenCommercialPurposeAndSublicensing_URL, requestOptions)
            .then(() => {
                    let sectionStatus: IApplication = {
                        ...dsaApplicationData, commercialSublicensingSectionStatus: taskListSectionStatus.inProgress
                    }
                    setDsaApplicationData(sectionStatus);
                    saveApplicationDataInStore(sectionStatus);
                    if (purposeData.doesSublicensingApply === dsaContent.yes) {
                        navigate(routeName.sublicensingDuration);
                    } else {
                        navigate(routeName.summaryCommercial);
                    }
              
            })
            .catch(() => navigate(routeName.serviceError));
    }

    /* To handle Onclick */


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                Commercial purposes and sub-licensing
                            </span>
                            <h1>Will you be sub-licensing the data?</h1>
                            {sublicensingErrorMessage !== "" && <ErrorDetails errorDescription={sublicensingErrorMessage} errorHref="#radioDoesSublicensingApply"></ErrorDetails>}
                            <InsetText>
                                <p>Sub-licensing means sharing data from NHS England with an organisation not mentioned in the Data Sharing Agreement (DSA).</p>
                                <p>A sub-licensing agreement must be in place before sharing data with an organisation not mentioned in the DSA.</p>
                                <p>If you are only sharing data with Controllers or Processors you have already told us about for the sole purpose of achieving the objectives described in the 'Data request reason and project name' section, select 'No'.</p>
                            </InsetText>
                            <Fieldset>
                                <Radios name="radioDoesSublicensingApply" id="radioDoesSublicensingApply" error={sublicensingErrorMessage}>
                                    <Radios.Radio name="doesSublicensingApply" onChange={handleChange} value={dsaContent.yes} checked={purposeData.doesSublicensingApply === dsaContent.yes}>Yes</Radios.Radio>
                                    <Radios.Radio name="doesSublicensingApply" onChange={handleChange} value={dsaContent.no} checked={purposeData.doesSublicensingApply === dsaContent.no}>No</Radios.Radio>
                                </Radios>
                            </Fieldset>

                            <div className="nhsuk-u-padding-bottom-8"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default Sublicensing