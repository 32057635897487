import { Button, Checkboxes, ErrorSummary, Hint, InsetText, Label } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import { ScreenProduct_DataDuration_URL, SubmitScreenProduct_DataDuration_URL } from "../../../../config/api-endpoints.config";
import { applicationChangeType, dataSetErrorMessage, dsaContent, fileTypeSize, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { checkApplicationChangeType, fetchInterceptor, getCookie, submitProductData, submitSpinner, disableHistoricAnnualCheckBox } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApiResDataDurationDetails, IApiResDataDurationList, IApiResDataDurationType, IApplication, IDataSet, IHistoricAnnualData } from "../../../../state/models/app.interface";
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";

const DataDuration: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [loading, setLoading] = useState(true);

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData, setDsaApplicationData] = useState(initialApplicationState);
    
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }


    const dataSetDetails: IDataSet = useAppSelector(getDataSetDetails);
    const initialDataSetState: IDataSet = dataSetDetails || {};
    const [dataSetData, setDataSetData] = useState<IDataSet>(initialDataSetState);

    const [dataDurationErrorMessage, setDataDurationErrorMessage] = useState("");
    /* To dispatch the setDataSetDetailsData Redux action */
    const saveDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }
    /*Variable Declarations*/

    /*Populate Dataduration Type onload*/
    const showDataDurationType = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "AssociatedProduct": {
                "CRMProductId": dataSetData.crmProductId,
                "ServiceTypeOneOff": dataSetData.serviceTypeOneOff,
                "ServiceTypeRegularBespoke": dataSetData.serviceTypeRegularBespoke,
                "ServiceTypeRegularStandard": dataSetData.serviceTypeRegularStandard,
                "ServiceTypeSystemAccess": dataSetData.serviceTypeSystemAccess
            }
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(ScreenProduct_DataDuration_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setDataDurationType(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setDataDurationType = (response: IApiResDataDurationType) => {
        if (response !== undefined && response !== null) {
            let updatedState: IDataSet = {};
            updatedState = {
                ...dataSetData,
                annualPeriodsExist: response?.AnnualPeriodsExist,
                currentYTDExists: response?.Current_YTDExists
            }
            setDataSetData(updatedState);
            saveDataInStore(updatedState);
        }
        setLoading(false);
    }
    /*Populate Dataduration Type onload*/

    /*To handle change of Checkbox*/
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IDataSet = {};
        if (e.target.checked) {
            updatedState = { ...dataSetData, [e.target.name]: true }
        }
        else {
            updatedState = { ...dataSetData, [e.target.name]: false }
        }
        resetError(e);
        setDataSetData(updatedState);
        saveDataInStore(updatedState);
    }
    /*To handle change of Checkbox*/

    /* To handle Onclick */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, submitDataDuration());
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    /* To handle Onclick */

    /* Submit DataDuration */
    const submitDataDuration = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "AssociatedProduct": {
                "CRMUID": dataSetData.crmUID,
                "CRMProductId": dataSetData.crmProductId,
                "DataDurationLatestAvailable": dataSetData.latestRelease,
                "DataDurationFutureRelease": dataSetData.futureRelease,
                "DataDurationHistoricAnnual": dataSetData.historicAnnual,
                "DatasetTimePeriods": dataSetData.historicAnnualDataList
            }
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenProduct_DataDuration_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setDataDurationtList(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setDataDurationtList = (response: IApiResDataDurationList) => {
        let arrhistoricAPYData: IHistoricAnnualData[] = [];
        if (response !== undefined && response !== null && response?.MappedAnnualPeriodsList !== null) {
            let historicData: IHistoricAnnualData = {};
            let updatedState: IDataSet = {};
            response?.MappedAnnualPeriodsList?.map((item: IApiResDataDurationDetails) => {
                historicData = {
                    period: item?.Period,
                    fileAvailabilityId: item?.FileAvailabilityId,
                    periodValue: item?.PeriodValue,
                    displayText: item?.DisplayText,
                    selected: item?.Selected,
                    periodAreadyMappedToFileAvailabilityInCRM: item?.PeriodAreadyMappedToFileAvailabilityInCRM,
                    alreadyHeld: item?.AlreadyHeld,
                    version: item?.Version
                };
                arrhistoricAPYData.push(historicData);
                updatedState = {
                    ...dataSetData,
                    initialHistoricAnnualDataList: checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)]) ? arrhistoricAPYData.filter(x => !x.alreadyHeld && (x.version===""||x.version===null)) : arrhistoricAPYData
                }
            })
            setDataSetData(updatedState);
            saveDataInStore(updatedState);
        };
        handleNavigation(dataSetData.futureRelease!, dataSetData.historicAnnual!);
        setLoading(false);
    }

    const handleNavigation = (futureRelease: boolean, historicAnnual: boolean) => {
        if (futureRelease) {
            navigate(routeName.datasetFrequency);
        }
        else if (historicAnnual && !disableHistoricAnnualCheckBox(dataSetData.historicAnnualDataList!, dataSetData.existingHistoricAnnualDataList!)) {
            navigate(routeName.historicAnnualData);
        }
        else if (checkApplicationChangeType(String(dsaApplicationData.applicationChangeType),[String(applicationChangeType.Renewal)])) {
            submitProductData(dsaApplicationData, dataSetData)
                .then((data: Response) => {
                    if (data) {
                        let sectionStatus: IApplication = {
                            ...dsaApplicationData, aboutDataSetSectionStatus: taskListSectionStatus.inProgress
                        }
                        setDsaApplicationData(sectionStatus);
                        saveApplicationDataInStore(sectionStatus);
                        navigate(routeName.datasetDetails);
                    }
                });
        }
        else navigate(routeName.selectDatasetField);
    }
    /* Submit DataDuration */

    /*Reset Error*/
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setDataDurationErrorMessage("");
        }
    }
    /*Reset Error*/

    /* To Validate the each field's data */
    const ValidateFields = () => {
        let isValid: boolean = true;
        if (dataSetData.currentYTDExists || dataSetData.serviceTypeRegularBespoke || dataSetData.serviceTypeRegularStandard || dataSetData.annualPeriodsExist) {
            if (!checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)])) {
                if (!dataSetData.latestRelease && !dataSetData.futureRelease && !dataSetData.historicAnnual) {
                    setDataDurationErrorMessage(`${dataSetErrorMessage.selectDataDuration_ErrorMessage}`);
                    isValid = false;
                }
            } else {
                if (!dataSetData.latestRelease && !dataSetData.historicAnnual) {
                    setDataDurationErrorMessage(`${dataSetErrorMessage.selectDataDuration_ErrorMessage}`);
                    isValid = false;
                }
            }
        }
        return isValid;
    }
    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
            <ErrorSummary.Title id="error-summary-title">{dataSetErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
            <ErrorSummary.Body>
                <ErrorSummary.List><ErrorSummary.Item href="#dataDuration">
                    {dataDurationErrorMessage}</ErrorSummary.Item>
                </ErrorSummary.List>
            </ErrorSummary.Body>
        </ErrorSummary>
    )
    /* To Validate the each field's data */

    useEffect(() => {
        showDataDurationType();
    }, [])

    const removeTemporaryData = () => {
        let updatedState: IDataSet = {};
        updatedState = {
            ...dataSetData,
            futureRelease: dataSetData?.temporaryFutureRelease,
            historicAnnual: dataSetData?.temporaryHistoricAnnual,
            latestRelease: dataSetData?.temporaryLatestRelease,
            historicAnnualDataList: dataSetData?.temporaryHistoricAnnualDataList
        };
        setDataSetData(updatedState);
        saveDataInStore(updatedState);
    }    

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            : <div className="nhsuk-grid-column-two-thirds">
                                <GoBack onClick={removeTemporaryData} condition={checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)]) ? "dataset-check-your-answer" : ""}></GoBack>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom">About the data &gt; {dataSetData.name} </span>
                                <h1>Data duration</h1>
                                {dataDurationErrorMessage !== "" ? errorSummary : null}
                                {(dataSetData.currentYTDExists || dataSetData.serviceTypeRegularBespoke || dataSetData.serviceTypeRegularStandard || dataSetData.annualPeriodsExist) ?
                                    <>
                                        <Label size="s">Select the data duration options that you require. If available, you can select more than one option.</Label>
                                        <Checkboxes name="dataDuration" id="dataDuration" error={dataDurationErrorMessage}>
                                            {dataSetData.currentYTDExists ?
                                                <Checkboxes.Box
                                                    id="latestRelease"
                                                    name="latestRelease"
                                                    onChange={handleChange}
                                                    checked={dataSetData.latestRelease}
                                                    hint={dataSetData.updatedDaily ? "'Latest available' generates different data results depending on the data set. When you select 'Latest available', we will provide the latest available data. This might mean you receive data as recent as the current month and year, or only as recent as 6 months ago." : "You will receive the year-to-date data as a one-off data drop dependent on the start date of your Data Sharing Agreement (DSA)."}
                                                >
                                                    {dataSetData.updatedDaily ? "Latest available data" : "Year-to-date data"}
                                                </Checkboxes.Box> : null
                                            }
                                            {(dataSetData.serviceTypeRegularBespoke || dataSetData.serviceTypeRegularStandard) ?
                                                <Checkboxes.Box
                                                    id="futureRelease"
                                                    name="futureRelease"
                                                    disabled={checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)]) ? true : false}
                                                    onChange={handleChange}
                                                    checked={dataSetData.futureRelease}
                                                >
                                                    Future release data
                                                    <Hint>Select this option if you would like to receive data throughout the term of your DSA.</Hint>
                                                    <Hint>Some data is only collected for a short time, and sometimes, collection of a specific data set is retired. In these cases, it might not be possible to apply for a future data release. </Hint>
                                                </Checkboxes.Box> : null}

                                            {dataSetData.annualPeriodsExist ?
                                                <Checkboxes.Box
                                                    id="historicAnnual"
                                                    name="historicAnnual"
                                                    disabled={checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)]) ? disableHistoricAnnualCheckBox(dataSetData.historicAnnualDataList!, dataSetData.existingHistoricAnnualDataList!) :false  }
                                                    onChange={handleChange}
                                                    checked={dataSetData.historicAnnual}
                                                    hint="You will receive the full year's data from your chosen year."
                                                >
                                                    Historic annual data
                                                </Checkboxes.Box> : null}
                                        </Checkboxes>
                                    </> :
                                    <>
                                        {(dataSetData.serviceTypeOneOff && !dataSetData.serviceTypeRegularBespoke && !dataSetData.serviceTypeRegularStandard) ?
                                            <InsetText>
                                                <p>You only One-Off service type for this data set.</p>
                                            </InsetText>

                                            : null}
                                    </>
                                }
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>}
                    </div>
                </main>
            </div>

        </>
    );
}
export default DataDuration;