import { InsetText, Button, Textarea, Hint } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GoBack from "../../../../Components/Shared/GoBack";
import { applicationChangeType, dsaContent, dsaSummary, dsaSummaryErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IDSASummary } from "../../../../state/models/app.interface";
import { getDSASummaryDetails, setDSASummaryDetailsData } from "../../../../state/slice/DSASummary/DSASummary.slice";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { calculateDaysDiff, calculateDateInFormat, checkApplicationChangeType, submitSpinner } from "../../../../Helper/Utility";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";

const DataRequestProject: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const [projectNameErrorMessage, setprojectNameErrorMessage] = useState("");
    const [projectNameMaxLength, setprojectNameMaxLength] = useState(0);
    const maxLength = 300;

    const dsaSummaryDetailsData: IDSASummary = useAppSelector(getDSASummaryDetails);
    const initialState: IDSASummary = dsaSummaryDetailsData || {};
    const [dsaSummaryData, setDsaSummaryData] = useState(initialState);
    const initialApplicationState: IApplication = useAppSelector(getDSADetails) || {};
    const [dsaApplicationData, setdsaApplicationData] = useState(initialApplicationState || {});
    const saveDSASummaryDataInStore = (stateData?: IDSASummary) => {
        dispatch(setDSASummaryDetailsData({ ...dsaSummaryData, ...stateData }));
    }

    const getdDatedays = (year: number) => {
        return year * 365;
    }

    const calculateYears = (daysDiff: string, days: number) => {
        if (String(daysDiff) === String(days) || String(daysDiff) === String(days + 1) || String(daysDiff) === String(days - 1)) {
            return true;
        }
        else {
            return false
        }
    }


    const navigateTermDate = () => {
        if (calculateYears(String(calculateDaysDiff(calculateDateInFormat(dsaSummaryData.dsaStartDate!), calculateDateInFormat(dsaSummaryData.dsaEndDate!)))!, getdDatedays(Number(dsaSummary.firstYear!)))
            || calculateYears(String(calculateDaysDiff(calculateDateInFormat(dsaSummaryData.dsaStartDate!), calculateDateInFormat(dsaSummaryData.dsaEndDate!)))!, getdDatedays(Number(dsaSummary.secondYear!)))
            || calculateYears(String(calculateDaysDiff(calculateDateInFormat(dsaSummaryData.dsaStartDate!), calculateDateInFormat(dsaSummaryData.dsaEndDate!)))!, getdDatedays(Number(dsaSummary.thirdYear!))))
        {
            navigate(routeName.proposedDsaTerm)
        }
        else {
            let updatedState: IDSASummary = {};
            updatedState = {
                ...dsaSummaryData,
                duration: '0'
            }
            saveDSASummaryDataInStore(updatedState);
            setDsaSummaryData(updatedState);
            navigate(routeName.proposedDate)
        }
    }

    const handleNavigation = () => {
        if (checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Extension), String(applicationChangeType.Renewal), String(applicationChangeType.Amendment)])) {
            String(dsaApplicationData.projectTitleReasons) === String(taskListSectionStatus.actionNeeded) || (dsaSummaryData.dsaEndDate! === null || dsaSummaryData.dsaEndDate! === "" || dsaSummaryData.dsaEndDate! === undefined) ? navigate(routeName.proposedDsaTerm) : navigateTermDate();
        }
        else {
            String(dsaApplicationData.projectTitleReasons) === String(taskListSectionStatus.completed) ? navigateTermDate() : navigate(routeName.proposedDsaTerm);

        }
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, handleNavigation);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const validateFields = () => {
        let isValid: boolean = true;
        if (!dsaSummaryData.title) {
            setprojectNameErrorMessage(`${dsaSummaryErrorMessage.enterProjectName_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IDSASummary = {};
        updatedState = {
            ...dsaSummaryData,
            title: e.target.value
        }
        setprojectNameMaxLength(updatedState.title ? maxLength - updatedState.title.length : maxLength);
        saveDSASummaryDataInStore(updatedState);
        setDsaSummaryData(updatedState);
        resetError(e);
    }

    const resetError = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== "") {
            setprojectNameErrorMessage("");
        }
    }

    useEffect(() => {
        setprojectNameMaxLength(dsaSummaryData.title ? maxLength - dsaSummaryData.title.length : maxLength);
    }, []);

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    Data request reason and project name</span>
                                    Project name
                                </h1>
                                {projectNameErrorMessage !== "" && <ErrorDetails errorDescription={projectNameErrorMessage} errorHref="#projectName"></ErrorDetails>}
                                <InsetText>
                                    <p>
                                        You must provide a name for your project to help you identify your Data Sharing Agreement (DSA) application.
                                    </p>
                                    <p>
                                        The project name should be consistent with any previous project name submitted for regulatory purposes (such as Confidentiality Advisory Group (CAG) or Protocol) and will help you identify the application.
                                    </p>
                                    <p>
                                        The project name will be referenced throughout the Data Access Request Service (DARS) Online application and on documentation related to your request for data.
                                    </p>
                                    <p>
                                        The project name and details of your application may be published.
                                    </p>
                                </InsetText>
                                <Textarea
                                    hint="Use the text box to tell us the name of your project"
                                    error={projectNameErrorMessage}
                                    name="ProjectName"
                                    id="projectName"
                                    onChange={handleChangeTextarea}
                                    defaultValue={dsaSummaryData.title ? dsaSummaryData.title : ""}
                                    maxLength={maxLength}
                                    rows={5}
                                />
                                <Hint>You have {projectNameMaxLength} characters remaining</Hint>
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>
                        }
                    </div>
                </main>
            </div>
        </>)
}

export default DataRequestProject;