import { controllerSection, taskListSectionStatus } from "../../../../config/page.config";
import { IApiResSectionList, IApplication, ISectionStatus, ISectionStatusTaskList } from "../../../../state/models/app.interface";
import { Tag } from "nhsuk-react-components";
import { enableStatus } from "../../../../Helper/Utility";

interface ApplyingForDataProps  {
    headingNumber: number, 
    taskListStatus:ISectionStatus, 
    sectionStatus: ISectionStatusTaskList,
    taskEnableSectionMultiSelect: IApiResSectionList[],
    dsaApplicationData:IApplication,
    handleClickDataRequestReason: (e: React.MouseEvent<HTMLAnchorElement>) => void,
    handleClickObjectiveAndProcessing: (e: React.MouseEvent<HTMLAnchorElement>) => void,
    handleClickExpectedOutputs: (e: React.MouseEvent<HTMLAnchorElement>) => void,
    handleClickCommercialPurpose: (e: React.MouseEvent<HTMLAnchorElement>) => void,
    handleClickYieldedbenefits: (e: React.MouseEvent<HTMLAnchorElement>) => void,
}

const ApplyingForData: React.FC<ApplyingForDataProps> = ({
    headingNumber,
    taskListStatus,
    sectionStatus,
    taskEnableSectionMultiSelect,
    dsaApplicationData,
    handleClickDataRequestReason,
    handleClickObjectiveAndProcessing,
    handleClickExpectedOutputs,
    handleClickCommercialPurpose,
    handleClickYieldedbenefits
  }) => {
    const checkEnable = (sectionId: Number) => {
        return enableStatus(sectionId, taskEnableSectionMultiSelect);
    }
    return (
        <>
            <h3>{headingNumber}. Why you are applying for the data</h3>
            <p>We need to know why you are requesting data from NHS England so that we can assess whether your request meets legal requirements. Data use must benefit healthcare provision, adult social care, or the promotion of health.</p>
            <table className="nhsuk-table section-list-no-border">
                <tbody className="nhsuk-table__body">
                     <tr className="nhsuk-table__row section-list-border-tp">
                        <td className="nhsuk-table__cell">
                            <a href="#" onClick={handleClickDataRequestReason}>Data request reason and project name</a>
                        </td>
                        <td className="nhsuk-table__cell">
                            {String(taskListStatus.ProjectTitleReason) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag>
                                : (String(taskListStatus.ProjectTitleReason) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag>
                                    : (String(taskListStatus.ProjectTitleReason) === String(taskListSectionStatus.actionNeeded)) ? <Tag color="orange" className="nhsuk-float-right">ACTION NEEDED</Tag>
                                        : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)}

                        </td>
                    </tr>

                    {dsaApplicationData.applyingForData ?
                        <>
                            {checkEnable(controllerSection.ObjectiveAndProcessingActivities) && <tr className="nhsuk-table__row">
                                <td className="nhsuk-table__cell"><a href="#" onClick={handleClickObjectiveAndProcessing}>Objective(s) and processing activities</a></td>
                                <td className="nhsuk-table__cell">
                                    {String(taskListStatus.ObjectiveAndProcessingActivities) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.ObjectiveAndProcessingActivities) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)}
                                </td>
                            </tr>}

                            {checkEnable(controllerSection.ExpectedOutputsAndBenefits) && <tr className="nhsuk-table__row">
                                <td className="nhsuk-table__cell">
                                    <a href="#" onClick={handleClickExpectedOutputs}>Expected outputs and benefits</a>
                                </td>
                                <td className="nhsuk-table__cell">
                                    {String(taskListStatus.ExpectedOutputsAndBenefits) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.ExpectedOutputsAndBenefits) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)}
                                </td>
                            </tr>}

                            {checkEnable(controllerSection.YieldedBenefits) && <tr className="nhsuk-table__row">
                                <td className="nhsuk-table__cell">
                                   <a href="#" onClick={handleClickYieldedbenefits}>Yielded benefits</a>
                                </td>
                                <td className="nhsuk-table__cell">
                                    {String(taskListStatus.YieldedBenefits) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag>
                                        : (String(taskListStatus.YieldedBenefits) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag>
                                            : (String(taskListStatus.YieldedBenefits) === String(taskListSectionStatus.actionNeeded)) ? <Tag color="orange" className="nhsuk-float-right">ACTION NEEDED</Tag>
                                                : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)}

                                </td>
                            </tr>}

                            {checkEnable(controllerSection.CommercialPurposesAndSublicensing) && <tr className="nhsuk-table__row">
                                <td className="nhsuk-table__cell">
                                    <a href="#" onClick={handleClickCommercialPurpose}>Commercial purposes and sub-licensing</a>
                                </td>
                                <td className="nhsuk-table__cell">
                                    {String(taskListStatus.CommercialPurposesAndSublicensing) === String(taskListSectionStatus.completed) ? <Tag className="nhsuk-float-right">COMPLETED</Tag> : (String(taskListStatus.CommercialPurposesAndSublicensing) === String(taskListSectionStatus.inProgress) ? <Tag color="blue" className="nhsuk-float-right">IN PROGRESS</Tag> : <Tag color="white" className="nhsuk-float-right"> MAKE CHANGES</Tag>)}
                                </td>
                            </tr>}</> : ""}
                </tbody>
            </table>
        </>
    )
}

export default ApplyingForData;