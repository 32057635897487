import React, { useEffect, useState } from "react";
import { Button, Fieldset, Radios, InsetText, ErrorSummary, Hint } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { dsaContent, participantTypeOptions, controllerSection, taskListSectionStatus, legalBasisValue, routeName, fileTypeSize, sectionOptions } from "../../../../config/page.config";
import { useAppSelector, useAppDispatch } from "../../../../state/app/hooks";
import { IApplication, IParticipant, IParticipantLegalBasis, IParticipantContributor, IEvidence, IAPiResParticipantDetails, IApiResParticipantContributor, IApiResParticipantLegalBasis, IApiResEvidence, IApiResEvidenceList } from "../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { AppDispatch } from '../../../../state/app/store';
import { getParticipantDetails, setParticipantDetailsData } from "../../../../state/slice/Participant/Participant.slice";
import { participantErrorMessage, dsaErrorMessage } from "../../../../config/page.config";
import { getCookie, submitSpinner, fetchInterceptor, submitDSASectionStatus } from "../../../../Helper/Utility";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import { GetCohortData_URL, DeleteDSAChildRecord_URL } from "../../../../config/api-endpoints.config";
import Loader from "../../../../Components/Loader/Loader";

const ParticipantData: React.FC = () => {
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [participantDataErrorMessage, setParticipantDataErrorMessage] = useState("");
    const participantDataDetails: IParticipant = useAppSelector(getParticipantDetails);
    const initialState: IParticipant = participantDataDetails || {};
    const [participantDetails, setParticipantDetails] = useState(initialState);
    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantDetails, ...stateData }));
    }
    const [loading, setLoading] = useState(false);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const [isFromAnswerPage] = useState(participantDetails?.participantcheckYourAnswerPage);
    const isNewParticipant = String(dsaApplicationData.aboutParticipantSectionStatus) === String(taskListSectionStatus.notStarted);

    const handleNavigation = () => {
        if (isFromAnswerPage !== undefined && isFromAnswerPage === dsaContent.checkYourAnswersPageText && participantDetails.cohort)
            navigate(routeName.participantSummary);
        else
            navigate(routeName.participantTypes);
}

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            if (String(participantDetails.participantProvidingData) === String(participantTypeOptions.participantProvidingTheData)) {
                submitSpinner(e, handleNavigation);
            }
            if (String(participantDetails.participantProvidingData) === String(participantTypeOptions.participantNotProvidingTheData)) {
                submitSpinner(e, submitData);
            }
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IParticipant = {};
        updatedState = { ...participantDetails, [e.target.name]: e.target.value }
        if (e.target.value === participantTypeOptions.participantProvidingTheData) {
            updatedState = {
                ...participantDetails,
                isParticipantProvidingData: true,
                participantProvidingData: participantTypeOptions.participantProvidingTheData,
            }
        }
        if (e.target.value === participantTypeOptions.participantNotProvidingTheData) {
            updatedState = {
                ...participantDetails,
                isParticipantProvidingData: false,
                participantProvidingData: participantTypeOptions.participantNotProvidingTheData,
            }
        }

        resetError(e);
        setParticipantDetails(updatedState);
        saveDataInStore(updatedState);
    }

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setParticipantDataErrorMessage("");
        }
    }

    const validateFields = () => {
        let isValid: boolean = true;
        if (!participantDetails.participantProvidingData) {
            setSummaryLinkErrorMessage(`${participantErrorMessage.participantData_ErrorMessage}`);
            setParticipantDataErrorMessage(`${participantErrorMessage.participantData_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const getCohortDetailsData = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "SectionId": sectionOptions.Participants
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetCohortData_URL, requestOptions)
            .then(response => {
                    return response.json()
            })
            .then(data => {
                setDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setDetails = (data: IAPiResParticipantDetails) => {
        let participant: IParticipant = {};
        let participantlegal: IParticipantLegalBasis = {};
        let legalBasisList: IParticipantLegalBasis[] = [];
        let participantjournal: IParticipantContributor = {};
        let journalsList: IParticipantContributor[] = [];
        let existingAgreement: string[] = [];
        if (data !== undefined && data !== null) {
            const { Cohort, TypeOfCohort, RefIfUsingAnotherExistingAgreement, OngoingRecruitment, AdditionalLinkageCohortInformation, MaximumSize, StudyId,
                DateOfDeath, NhsNumber, Surname, DateOfBirth, AddressDate, Forename, GpPracticeCode, OtherGivenName, DataAsAtDate, Sex, TelephoneNumber, Postcode,
                MobilePhoneNumber, EmailAddress, DataSetSelectedLegalBasisOtherText, MinimumSizeCohortValidationRate, ManualCohortValidation, HowManyValidationCredits,
                ProductionCommence, DataProductionSubmissionSelected, ExistingAgreementUploadItems } = data;
            participant.cohort = Cohort
            participant.typeOfCohort = TypeOfCohort
            participant.refIfUsingAnotherExistingAgreement = RefIfUsingAnotherExistingAgreement
            participant.ongoingRecruitment = OngoingRecruitment
            participant.additionalLinkageCohortInformation = AdditionalLinkageCohortInformation
            participant.maximumSize = MaximumSize
            participant.studyId = StudyId
            participant.dateOfDeath = DateOfDeath
            participant.nhsNumber = NhsNumber
            participant.surname = Surname
            participant.dateOfBirth = DateOfBirth
            participant.addressDate = AddressDate
            participant.forename = Forename
            participant.gpPracticeCode = GpPracticeCode
            participant.otherGivenName = OtherGivenName
            participant.dataAsAtDate = DataAsAtDate
            participant.sex = Sex
            participant.telephoneNumber = TelephoneNumber
            participant.postcode = Postcode
            participant.mobilePhoneNumber = MobilePhoneNumber
            participant.emailAddress = EmailAddress
            participant.dataSetSelectedLegalBasisOtherText = DataSetSelectedLegalBasisOtherText
            participant.minimumSizeCohortValidationRate = MinimumSizeCohortValidationRate
            participant.manualCohortValidation = ManualCohortValidation
            participant.howManyValidationCredits = HowManyValidationCredits
            participant.productionCommence = ProductionCommence
            participant.participantTypes = String(TypeOfCohort)
            participant.dataProductionSubmissionSelected = DataProductionSubmissionSelected
            participant.existingAgreementUploadItems = ExistingAgreementUploadItems
            participant.isParticipantProvidingData = Cohort
            participant.participantProvidingData = Cohort ? String(participantTypeOptions.participantProvidingTheData) : String(participantTypeOptions.participantNotProvidingTheData)
            if (data.Journals.length > 0) {
                data.Journals.map((dsaApplication: IApiResParticipantContributor) => {
                    participantjournal = {
                        id: dsaApplication.Id,
                        firstName: dsaApplication.FirstName,
                        lastName: dsaApplication.LastName,
                        organisation: dsaApplication.Organisation,
                        role: dsaApplication.Role,
                        email: dsaApplication.Email
                    }
                    journalsList.push(participantjournal)
                })
            }
            participant.participantContributorList = journalsList
            let legalbasisCount = 0;
            let isAllLegalBasisiAdded = false;
            if (data.DataSetLegalBasisList.length > 0) {
                data.DataSetLegalBasisList.map((dsaApplication: IApiResParticipantLegalBasis) => {
                    participantlegal = {
                        value: dsaApplication.Value,
                        name: dsaApplication.Name,
                        checked: dsaApplication.Checked,
                        text: dsaApplication.Text,
                        crmId: dsaApplication.crmId,
                        processing: dsaApplication.Processing,
                        dissemination: dsaApplication.Dissemination,
                        otherLegalBasis: dsaApplication.OtherLegalBasis,
                        displayProcessing: dsaApplication.DisplayProcessing,
                        displayDissemination: dsaApplication.DisplayDissemination,
                        productLegalBasis: dsaApplication.ProductLegalBasis,
                        otherComments: dsaApplication.OtherComments,
                        orderBy: dsaApplication.OrderBy,
                        availableOnline: dsaApplication.AvailableOnline,
                        currentlyCheckedInCRM: dsaApplication.CurrentlyCheckedInCRM,
                        notes: dsaApplication.Notes,
                    }
                    if (dsaApplication.Checked)
                        legalbasisCount = legalbasisCount + 1
                    legalBasisList.push(participantlegal);
                })
                if (legalbasisCount > Number(legalBasisValue.checkLegalBasisLength))
                    isAllLegalBasisiAdded = true
            }
            participant.dataSetLegalBasisList = legalBasisList
            if (data.ExistingAgreementUploadItems.length > 0) {
                data.ExistingAgreementUploadItems.forEach((agreement: string) => {
                    existingAgreement.push(agreement)
                })
                participant.existingAgreementUploadItems = existingAgreement;
                if (participant.refIfUsingAnotherExistingAgreement === null || participant.refIfUsingAnotherExistingAgreement === '') {
                    participant.refIfUsingAnotherExistingAgreement = data.ExistingAgreementUploadItems[0]
                }
            }
            participant.isAllLegalBasisAdded = isAllLegalBasisiAdded;
            let participantEvidence: IEvidence = {};
            let evidenceList: IEvidence[] = [];
            if (data.EvidenceList != null && data.EvidenceList.length > 0) {
                data.EvidenceList.map((evidence: IApiResEvidenceList) => {
                    participantEvidence = {
                        id: evidence.Id,
                        comments: evidence.Comments,
                        referenceNumber: evidence.ReferenceNumber,
                        approvalsEvidenceType: evidence.ApprovalsEvidenceType
                    }
                    evidenceList.push(participantEvidence)
                })
            }
            participant.evidenceList = evidenceList;
            participant.isFromParticipant = true;
            setParticipantDetails(participant)
            saveDataInStore(participant)
            setLoading(false);
        }
    }

    const submitData = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "SectionId": controllerSection.Participants,
            "Id": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(DeleteDSAChildRecord_URL, requestOptions)
            .then(() => {
                updateSectionStatus();
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const updateSectionStatus = async () => {
        const statusResponse = await submitDSASectionStatus(dsaApplicationData.id!, controllerSection.Participants, taskListSectionStatus.completed);
        if (statusResponse) {
            let sectionStatus: IApplication = {
                ...dsaApplicationData, aboutParticipantSectionStatus: taskListSectionStatus.completed
            }
            setDsaApplicationData(sectionStatus);
            saveApplicationDataInStore(sectionStatus);
            navigate(routeName.dsaTaskList);
        }
    }
    useEffect(() => {
        getCohortDetailsData()
    }, [])

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#participantData">{summaryLinkErrorMessage}</ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ? (<Loader loadingText={dsaContent.txtLoading} />) :
                            <>
                                <div className="nhsuk-grid-column-two-thirds">
                                    <GoBack></GoBack>
                                    <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                        About the Participants
                                    </span>
                                    <h1>Are you providing (or have you provided) any Participant data to NHS England for this application?</h1>
                                    {summaryLinkErrorMessage !== "" ? errorSummary : null}
                                    <InsetText>
                                        <p>Participants are a group of identified individuals that you supply to NHS England, for NHS England to provide data against. Participant groups are sometimes known as Cohorts. </p>
                                        <p>If you are seeking data from NHS England and are not providing any Participant data to NHS England, selecting 'No' will skip this section. </p>
                                        <p>If you are providing or have provided Participant data, answer 'Yes' to this question. </p>
                                        <p>If you want NHS England to identify Participants on your behalf based on criteria, such as, females over the age of 30 who have had a hysterectomy in the last 12 months, select 'No' to this question and add your criteria to the data minimisation section within the application.</p>
                                    </InsetText>
                                    <Hint>You may only submit one group of Participants per Data Sharing Agreement (DSA), but you can update your Participant group at any time. If you need to associate multiple Participant groups with the same requested data set, you must create an application for each Participant group.</Hint>
                                </div>
                                <div className="nhsuk-grid-column-two-thirds">
                                    <Fieldset className="nhsuk-space-bottom-double">
                                        <Radios
                                            name={dsaContent.participantDatatype}
                                            id={dsaContent.participantDatatype}
                                            error={participantDataErrorMessage}>
                                            <Radios.Radio
                                                name={dsaContent.participantDatatype}
                                                value={participantTypeOptions.participantProvidingTheData}
                                                checked={String(participantDetails.participantProvidingData) === String(participantTypeOptions.participantProvidingTheData)}
                                                onChange={handleChange}>Yes                                                
                                            </Radios.Radio>
                                            <Radios.Radio
                                                name={dsaContent.participantDatatype}
                                                value={participantTypeOptions.participantNotProvidingTheData}
                                                checked={String(participantDetails.participantProvidingData) === String(participantTypeOptions.participantNotProvidingTheData)}
                                                onChange={handleChange}>No
                                            </Radios.Radio>
                                        </Radios>
                                    </Fieldset>
                                    <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                                </div>
                            </>
                        }
                    </div>
                </main>
            </div>
        </>
    )
}
export default ParticipantData;