import { Button, ErrorSummary, Fieldset, Radios } from "nhsuk-react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitDSASectionStatus_URL } from "../../../../config/api-endpoints.config";
import { amendRenewExtendValue, applicationChangeType, controllerDetails, controllerSection, dsaContent, dsaControllerErrorMessage, fileTypeSize, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { checkApplicationChangeType, fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IController } from "../../../../state/models/app.interface";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getControllerDetails, setControllerDetailsData } from "../../../../state/slice/DSAAboutController/DSAAboutController.slice";

const HaveYouCompletedThisSection: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const controllerDetailsData: IController = useAppSelector(getControllerDetails);
    const initialState: IController = controllerDetailsData || {};
    const [controllerData, setData] = useState(initialState);

    const saveDataInStore = (stateData?: IController) => {
        dispatch(setControllerDetailsData({ ...controllerData, ...stateData }));
    }

    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);
    
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [sectionStatus, setSectionStatus] = useState("");
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [isSectionControllerErrorMessage, setIsSectionControllerErrorMessage] = useState("");
    const [selected, setSelected] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSectionStatus(e.target.value);
        setSelected(true);
        resetError(e);
    };

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setIsSectionControllerErrorMessage("");
        }
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, submitControllerDetails);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    const submitControllerDetails = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "SectionId": controllerSection.aboutController,
            "SectionStatus": sectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitDSASectionStatus_URL, requestOptions)
            .then(() => {
                setControllerStatus(sectionStatus);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setControllerStatus = (aboutControllerSectionStatus: string) => {
        let updatedState: IController = {};
        updatedState = {
            ...controllerData,
            aboutControllerSectionStatus: aboutControllerSectionStatus
        }
        setData(updatedState);
        saveDataInStore(updatedState);
        navigate(routeName.dsaTaskList);
    }

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaControllerErrorMessage.selectControllerSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#radioSectionComplete">
                            {summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )


    const ValidateFields = () => {
        let isValid: boolean = true;

        if (!selected) {
            if (String(dsaApplicationData.applicationChangeType) !== String(applicationChangeType.Renewal)) {
                setSummaryLinkErrorMessage(`${dsaControllerErrorMessage.selectSectionCompleteLinkController_ErrorMessage}`);
                setIsSectionControllerErrorMessage(`${dsaControllerErrorMessage.selectSectionCompleteLinkController_ErrorMessage}`);
            } else {
                setSummaryLinkErrorMessage(`${dsaContent.selectSectionCompleteLink_RenewErrorMessage}`);
                setIsSectionControllerErrorMessage(`${dsaContent.selectSectionCompleteLink_RenewErrorMessage}`);
            }
            isValid = false;
        }

        return isValid;
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper" id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack />
                            <h1>{checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? dsaContent.isThisSectionComplete : dsaContent.haveYouCompletedController}</h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal),String(applicationChangeType.Extension)]) ? <p>If you select 'Yes' the status of this section will change to 'completed', but you can return and make changes if you need to.</p> : null}
                            <Fieldset>
                                <Radios name="radioSectionComplete" id="radioSectionComplete" error={isSectionControllerErrorMessage}>
                                    <Radios.Radio name="sectionComplete" value={taskListSectionStatus.completed} onChange={handleChange}>{checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? dsaContent.yes : dsaContent.yesIHaveCompletedSection}</Radios.Radio>
                                    <Radios.Radio name="sectionComplete" value={taskListSectionStatus.inProgress} onChange={handleChange}>{checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal), String(applicationChangeType.Extension)]) ? dsaContent.noIWillComeBackLater : dsaContent.noIWillBeBackLater}</Radios.Radio>
                                </Radios>
                            </Fieldset>
                            <div className="nhsuk-u-padding-bottom-6"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{controllerDetails.btnSaveAndContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default HaveYouCompletedThisSection;