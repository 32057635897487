import React, { useEffect, useRef, useState } from "react";
import { Button, ErrorSummary, InsetText, Fieldset, Details, ErrorMessage, Table, Textarea } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IApiResEvidenceFileSummary, IApplication, IEvidenceFileSummary, IFileUpload, IFunding } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { documentType, dsaContent, dsaControllerErrorMessage, dsaErrorMessage, fileErrorMessage, fileTypeSize, fundingErrorMessage, routeName } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { deleteUploadedFiles, fetchInterceptor, getB64toBlobData, getCookie, getEvidenceFileDetails, handleUploadFile, isValid, submitSpinner, validateFile } from "../../../../Helper/Utility";
import { getFundingDetails, setFundingDetailsData } from "../../../../state/slice/Funding/Funding.slice";
import FileUploadControl from "../../../../Components/FileUploadControl/FileUploadControl";
import FileUploadControl1 from "../../../../Components/FileUploadControl/FileUploadControl1";
import { GetDSAFileById_URL } from "../../../../config/api-endpoints.config";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import Loader from "../../../../Components/Loader/Loader";


const FundingDocument: React.FC = () => {
    /*Variable Declarations*/
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;

    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();

    const { fileUploadActive = 0 } = useAppSelector(getFundingDetails) || {};
    const [fundingData, setfundingData] = useState(useAppSelector(getFundingDetails) || {});
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData] = useState(dsaApplicationDetails || {});
    let [fileLists, setFileLists] = useState<IFileUpload[]>([]);
    let [fileListsOnChange, setFileListsOnChange] = useState<File[]| IFileUpload[]>([]);
    let showfileLists: IFileUpload[] = ([]);
    const [selectedFile, setSelectedFile] = useState<File[] | []>([]);
    const [isSelected, setIsSelected] = useState(false);
    const [uploadAnother, setUploadAnother] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [fileChosen, setfileChosen] = useState(false);
    const [validFileError, setValidFileError] = useState("");
    const [isFileUploadedError, setIsFileUploadedError] = useState("");
    const [isFileUploadedErrorLink, setIsFileUploadedErrorLink] = useState("");
    const [validateChooseFile, setValidateChooseFile] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    let [uploadedFile, setUploadedFile] = useState<IFileUpload[]>([]);
    let [evidenceSummaryList, setEvidenceSummaryList] = useState<IEvidenceFileSummary[]>([]);
    const [showLoader, setShowLoader] = useState(true);
    const [selectedFileName, setSelectedFileName] = useState("");

    const [applicantCommentsErrorMessage, setApplicantCommentsErrorMessage] = useState("");
    const [applicantComments, setApplicantComments] = useState(fundingData.fileUpload![fileUploadActive]?.ApplicantComments! !== "" ? fundingData.fileUpload![fileUploadActive]?.ApplicantComments! : "");
    const [showdownload, setshowdownload] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [approvalEvidenceId] = useState(fundingData.fileUpload![fileUploadActive]?.ApprovalEvidenceID! !== '' ? fundingData.fileUpload![fileUploadActive]?.ApprovalEvidenceID! : '')
    /* To dispatch the setFundingDetailsData Redux action */

    const saveDataInStore = (stateData?: IFunding) => {
        dispatch(setFundingDetailsData({ ...fundingData, ...stateData }));
    }

    const handelUploadedFiles = (files: File[]) => {
        const uploaded = [...selectedFile!];
        files.forEach((file: File) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                return true;
            }
        })
        setSelectedFile(uploaded);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === dsaContent.file) {
            resetError(e);
            const chosenFiles = Array.prototype.slice.call(e.target.files)
            setValidateChooseFile(true);
            if (validateFile(e, fileTypeSize, setValidFileError, setIsFileUploadedError, fileErrorMessage, setIsSelected, false, selectedFileName!, evidenceSummaryList)) {
                handelUploadedFiles(chosenFiles);
                setfileChosen(true);
                setValidFileError("");
                setValidateChooseFile(false);
                handleUpload(e, chosenFiles);
            }
            else {
                setSelectedFile([]);
                setIsSelected(false);
            }
            let updatedState: IFunding = {};
            updatedState = { ...fundingData, [e.target.name]: e.target.value, IsFundingFileUploadDirty: fundingData.isUploadedSummaryPage ? true : false }
            setfundingData(updatedState);
            saveDataInStore(updatedState);

        }
    }

    const files = selectedFile ? Array.from(selectedFile) : [];

    const validateUploadedFile = (filesToValidate?: File[]) => {
        if ((uploadedFileOnChange?.length + filesToValidate!.length) > 6) {
            setValidFileError(`${fileErrorMessage.fileLength_ErrorMessage}`);
            setSelectedFile([]);
            setIsSelected(false);
            if (inputRef.current != null)
                inputRef.current.value = ''
            return false;
        }
        else if (filesToValidate?.length === 0) {
            setValidFileError(`${fileErrorMessage.fileRequiredToUpload_ErrorMessage}`);
            setSelectedFile([]);
            setIsSelected(false);
            return false;
        }
        return true;
    }

    /* To upload the file */


    const handleUpload = (e: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<HTMLInputElement>, fileList?: File[]) => {
        fileList = fileList ? fileList : files;
        const IsValid: boolean = validateUploadedFile(fileList);
        if (IsValid) {
            handleUploadFile(e, fileLists, fileListsOnChange as File[], setIsUploaded, selectedFile!, fileList!, setFileLists, setFileListsOnChange, setIsSelected, setSelectedFile, resetError, false, false, setUploadAnother, evidenceSummaryList!, setEvidenceSummaryList, setSelectedFileName, selectedFileId, applicantComments, fileUploadActive, documentType.Funding, approvalEvidenceId)
        }
        setSelectedFileId(0);
    }

    let finallyUploadedFiles: IFileUpload[] = fileLists ? Array.from(fileLists) : fundingData.fileUpload!;
    let uploadedFileOnChange = fileListsOnChange ? Array.from(fileListsOnChange as IFileUpload[]) : [];

    const removeFileFromTheList = (fileList: IFileUpload[], fileId: number, filename: string) => {
        let filtered_arr = fileList.filter(function (val: IFileUpload) {
            if (val.FileId === fileId && val.FileName === filename) {
            } else {
                return val;
            }
        })
        return filtered_arr;
    }


    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const valueLength = e.target.value.length;
        if (valueLength >= Number(fileTypeSize.checkFileLength)) {
            if (e.target.id === dsaContent.file) {
                setIsFileUploadedError("");
                setValidFileError("");
                setIsFileUploadedErrorLink("");
            }

            if (e.target.id === dsaContent.applicantComments) {
                setApplicantCommentsErrorMessage("");
            }
        }
        if (e.target.name === dsaContent.upload) {
            setIsFileUploadedError("");
            setIsFileUploadedErrorLink("");
        }
    }

    const uploadAnotherFile = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setUploadAnother(true);
    }

    /* submit the funding data */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields(e);
        if (IsValid) {
            submitSpinner(e, SubmitFundingDocument);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const SubmitFundingDocument = () => {
        let updatedState: IFunding = {};
        updatedState = {
            ...fundingData,
            fileUpload: finallyUploadedFiles,
            evidenceFileSummary: evidenceSummaryList
        }
        setfundingData(updatedState);
        saveDataInStore(updatedState);
        navigate(routeName.addAnotherFunding);
    }

    const handleChangeApplicantComments = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedStateofFundingComment: IFunding = {};
        setApplicantComments(e.target.value!);

        let updatedFileData: IFileUpload[] = []
        if (fundingData.fileUpload?.length === 0 ||
            (fundingData.fileUpload?.length !== 0 && fundingData.fileUpload?.length === fileUploadActive && !fundingData.fileUpload![fileUploadActive]?.ApplicantComments)) {
            //new array item
            updatedFileData = [...fundingData.fileUpload!, ...[{ ...fundingData.fileUpload![fileUploadActive], ...{ [e.target.name]: e.target.value } }]];
        } else {
            updatedFileData = fundingData.fileUpload!.map((item, index) => {
                if (fileUploadActive === index) {
                    return { ...item, ...{ [e.target.name]: e.target.value } }
                } else return item;
            })
        }

        updatedStateofFundingComment = { ...fundingData, fileUpload: updatedFileData };
        finallyUploadedFiles = updatedFileData;

        setFileLists(finallyUploadedFiles);

        setfundingData(updatedStateofFundingComment);
        saveDataInStore(updatedStateofFundingComment);
        resetError(e);
    }

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#applicantComments">
                            {applicantCommentsErrorMessage}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#fileupload">
                            {validFileError ? validFileError : isFileUploadedErrorLink}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    /* To Validate the each field's data */
    const ValidateFields = (e: React.MouseEvent<HTMLButtonElement>) => {
        let isValid: boolean = true;
        const target = e.target as HTMLButtonElement;
        if ((finallyUploadedFiles.length <= 0 && target.name !== dsaContent.upload) || (fundingData.fileUpload![fileUploadActive]?.FileName! === "" || fundingData.fileUpload![fileUploadActive]?.FileName! === undefined)) {
            setIsFileUploadedErrorLink(`${fundingErrorMessage.fundingDocument_ErrorMessage}`);
            setIsFileUploadedError(`${fundingErrorMessage.fundingDocument_ErrorMessage}`);
            isValid = false;
        }
        if (!(fundingData.fileUpload![fileUploadActive]?.ApplicantComments!)) {
            setApplicantCommentsErrorMessage(`${dsaControllerErrorMessage.applicantComments_ErrorMessage}`);
            isValid = false;
        }
        if (!fileChosen) {
            setIsFileUploadedErrorLink(`${fundingErrorMessage.fundingDocument_ErrorMessage}`);
            setIsFileUploadedError(`${fundingErrorMessage.fundingDocument_ErrorMessage}`);
            isValid = false;
        }
        if (validateChooseFile) {
            setIsFileUploadedError("");
        }
        if (validFileError) {
            isValid = false;
        }
        return isValid;
    }

    const showFileList = () => {
        if (fundingData.fileUpload !== null && fundingData.fileUpload != undefined) {
            const filterFile = fundingData?.fileUpload;
            showfileLists = Object.assign([], filterFile);
            if (showfileLists[0]?.FileName !== "") {
                setFileLists(showfileLists);
                finallyUploadedFiles = showfileLists;
                if (finallyUploadedFiles.length >= 1) {
                    setIsSelected(true);
                    setfileChosen(true);
                }
            }
        }
    }

    /*File Download*/
    const setFileUrl = (e: any) => {
        e.preventDefault();
        setSelectedIndex(e.currentTarget.id);
        setshowdownload(true);
        e.currentTarget.className += "nhsuk-disableLink";
        downloadfile(e, e.currentTarget.id);

    }
    const downloadfile = (e: any, fileId: any) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "FileId": fileId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: parameters
        };

        fetchInterceptor(GetDSAFileById_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data && data !== undefined) {
                    const b64Data: any = data.Data._buffer;
                    const blob: any = getB64toBlobData(b64Data, data.contentType, data.FileName);
                    const blobUrl: any = URL.createObjectURL(blob);
                    download(blobUrl, data.FileName);
                    setshowdownload(false);
                    e.target.removeAttribute('class');
                }

            })
            .catch(() => navigate('/serviceerror'));

    }
    const download = (path: any, filename: any) => {
        const anchor = document.createElement('a');
        anchor.href = path;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };
    /*File Download*/
    const removeFileOnGoBack = () => {
        let updatedState: IFunding = {};
        updatedState = {
            ...fundingData,
            fileUpload: fundingData.temporaryFileList
        }
        setfundingData(updatedState);
        saveDataInStore(updatedState);        
    }

    const getEvidenceFileSummary = () => {
        let applicationId = isValid(dsaApplicationData.id) ? dsaApplicationData.id : "";
        if (isValid(applicationId)) {
            getEvidenceFileDetails(applicationId!).then((data) => {
                setEvidenceFileSummary(data);
            });
        }
    }

    const setEvidenceFileSummary = (response: IApiResEvidenceFileSummary[]) => {
        if (isValid(response)) {

            let evidenceFileSummary: IEvidenceFileSummary[] = [];
            response.map(({ FileId: fileId, FileName: fileName, MimeType: mimeType, Source: source }) => {
                evidenceFileSummary.push({ fileId, fileName, mimeType, source });
            });
            let updatedState: IFunding = {};
            updatedState = {
                ...fundingData,
                evidenceFileSummary
            }
            setfundingData(updatedState);
            saveDataInStore(updatedState);
        }
        setShowLoader(false);

    }

    useEffect(() => {
        showFileList();
        if (!isValid(fundingData.evidenceFileSummary)) {
            getEvidenceFileSummary();
        }
        else {
            setEvidenceSummaryList(fundingData.evidenceFileSummary!);
            setShowLoader(false);
        }
        setSelectedFileName(isValid(fundingData.fileUpload) && isValid(fundingData.fileUpload![fileUploadActive]) ? fundingData.fileUpload![fileUploadActive].FileName! : "");
    }, [])

    useEffect(() => {
        if (!isValid(evidenceSummaryList)) {
            setEvidenceSummaryList(fundingData.evidenceFileSummary!);
        }
    }, [fundingData.evidenceFileSummary]);

    useEffect(() => {
        finallyUploadedFiles = fileLists ? Array.from(fileLists) : [];       // submit
        let updatedState: IFunding = {};
        updatedState = {
            ...fundingData,
            fileUpload: finallyUploadedFiles,
        }
        setfundingData(updatedState);
        saveDataInStore(updatedState);
    }, [fileLists]);

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {showLoader ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <>
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack onClick={removeFileOnGoBack}></GoBack>
                            <h1><span className="nhsuk-caption-l nhsuk-caption--bottom">
                                Funding</span>
                                Upload funding document
                            </h1>
                            {applicantCommentsErrorMessage !== "" || isFileUploadedError !== "" || validFileError !== "" ? errorSummary : null}
                           
                            <InsetText>
                                <p>You should now upload a supporting document from the organisation providing the funding.</p>
                            </InsetText>
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <Textarea
                                label="Provide your own description or name for the document (character limit 50)"
                                error={applicantCommentsErrorMessage}
                                name="ApplicantComments"
                                id={dsaContent.applicantComments}
                                onChange={handleChangeApplicantComments}
                                defaultValue={(fundingData.fileUpload![fileUploadActive])?.ApplicantComments!}
                                maxLength={50}
                                rows={3}
                            />
                            <Fieldset>
                                <Fieldset.Legend>Document required </Fieldset.Legend>
                            </Fieldset>
                            <div className="nhsuk-u-padding-bottom-2"></div>
                            <Details>
                                <Details.Summary>View file guidance </Details.Summary>
                                <Details.Text>
                                    <p>A file should not exceed 3 MB and should be one of the following acceptable file formats: PDF, Word, Excel.</p>
                                </Details.Text>
                            </Details>
                        </div>
                       
                        <FileUploadControl1
                            errorAboveList={isFileUploadedError}
                            errorBelowList={validFileError}
                            isUploaded={isUploaded}
                            finallyUploadedFiles={fileUploadActive === 0 && fundingData.fileUpload!.length === 0 ? undefined : fundingData.fileUpload![fileUploadActive!] as any}
                            handleChange={handleChange}
                            handleUpload={handleUpload}
                            // deleteFile = {deleteupLoadedDocData}
                            //changeFile={changeUpLoadedDocData}
                            setFileUrl={setFileUrl}
                            showDownload={showdownload}
                            selectedIndex={selectedIndex}
                            setSelectedFileId={setSelectedFileId}
                            // uploadAnother = {uploadAnother}
                            // uploadAnotherFile = {uploadAnotherFile}
                        />
                        {/* <div className="nhsuk-grid-column-two-thirds" id="fileupload" >
                            <ErrorMessage>{isFileUploadedError}</ErrorMessage>
                            {isSelected || finallyUploadedFiles.length >= 1 ?
                                <>
                                    <p>Files you have uploaded</p>
                                    <Table responsive>
                                        <Table.Body>
                                            {finallyUploadedFiles.map((file: IFileUpload, i: number) => (
                                                <Table.Row key={i + 1} >
                                                    <Table.Cell> {file.FileName}</Table.Cell>
                                                    <Table.Cell><a href="#" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => deleteupLoadedDocData(e, i, file.FileId!, file.FileName!)}>Remove file</a></Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                    <div className="nhsuk-u-padding-bottom-4"></div>
                                    <p><a href="#" onClick={(event) => uploadAnotherFile(event)} >Upload another file</a></p>
                                </>
                                : null}

                            <ErrorMessage> {validFileError}</ErrorMessage>
                            {!isSelected || uploadAnother ?
                                <>
                                    <input type="file" ref={inputRef} id={dsaContent.file} multiple name={dsaContent.file} accept="application/pdf" onChange={handleChange} />
                                    <p></p>
                                    <p>
                                        <Button secondary name={dsaContent.upload} id={dsaContent.upload} onClick={handleUpload} >Upload </Button>
                                        {isUploaded ?
                                            <span className="nhsuk-loader__container loading-results ">
                                                <span className="nhsuk-loader-file"></span>
                                                Uploading
                                            </span> : null}
                                    </p>
                                </>
                                : null}
                        </div> */}
                        <div className="nhsuk-u-padding-bottom-4">
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                                </div>
                            </>}
                    </div>
                </main>
            </div>
        </>
    )
}
export default FundingDocument;


