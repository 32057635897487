import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialStateOfFileUpload } from '../../../config/page.config'
import type { RootState } from '../../app/store'
import { IController, IControllerList, IFileUpload, IAutoComplete, ISecurityAssurance } from '../../models/app.interface'



const initialStateOfControllerOrg: IAutoComplete = {
    id: "",
    name: ""
}

const initialStateofSecurityAssurance: ISecurityAssurance = {
    SAType: "",
    SAId: "",
    ISOVersion: "",
    ISODateComplete: "",
    ISONotes: "",
    SLSPNotes: "",
    SLSPDateComplete: "",
    DSPT_ODS_Code: "",
    DSPT_Latest_Status: "",
    DSPT_Date_Published: "",
    DSPT_Comments:"",
    SectionType: "",
    DSPTDay: "",
    DSPTMonth: "",
    DSPTYear: "",
    ISODay: "",
    ISOMonth: "",
    ISOYear: "",
    SLSPDay: "",
    SLSPMonth: "",
    SLSPYear: "",
    SAIndex: 0
}
const initialStateOfController: IController = {
    isJointController: false,
    isSoleController: false,
    isCompleted: false,
    controllerId: "",
    isDirtyController: false,
    fileUpload: [initialStateOfFileUpload],
    fileUploadActive: 0,
    aboutControllerSectionStatus: "",
    dsaApplicationId: "",
    contractNumber: "",
    alternativeOrganisationName: "",
    alternativeStreet1: "",
    alternativeStreet2: "",
    alternativeStreet3: "",
    alternativeCity: "",
    alternativeCounty: "",
    alternativePostCode: "",
    alternativeCountry: "",
    controllerdataOrgGuidId: "",
    dpaRegistrationNumber: "",
    dpaOrganisationName: "",
    dpaRegistrationExpiryDate: "",
    dpDay: "",
    dpMonth: "",
    dpYear: "",
    saList: initialStateofSecurityAssurance,
    temporaryFileList: [initialStateOfFileUpload],
    evidenceFileSummary: []
}
const initialState: IControllerList = {
    activeController: 0,
    controllerDetailsPage: "",
    controllerList: [initialStateOfController]
}

export const ControllerListSlice = createSlice({
    name: 'dsaControllerListDetails',
    initialState,
    reducers: {
        setControllerListDetailsData: (state: IControllerList, action: PayloadAction<IControllerList>) => {
            state = Object.assign(state, action.payload);
        },
        resetControllerlistDetailsData: (state: IControllerList, action: PayloadAction<IControllerList>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setControllerListDetailsData, resetControllerlistDetailsData } = ControllerListSlice.actions;
export const getControllerListDetails = (state: RootState) => state.controllerListDetails;
export default ControllerListSlice.reducer;