import { Button, Details, Textarea } from "nhsuk-react-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import GoBack from "../../../../Components/Shared/GoBack";
import { dataSetErrorMessage, dsaContent, routeName } from "../../../../config/page.config";
import { submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IDataSet } from "../../../../state/models/app.interface";
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";

const DataMinimisation: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const [minimisationErrorMessage, setMinimisationErrorMessage] = useState("");
    const dataSetDetails: IDataSet = useAppSelector(getDataSetDetails);
    const initialDataSetState: IDataSet = dataSetDetails || {};
    const [dataSetData, setDataSetData] = useState<IDataSet>(initialDataSetState);
    /* To dispatch the setDataSetDetailsData Redux action */
    const saveDataSetDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }

    /* Function to set the state value*/
    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IDataSet = {};
        updatedState = {
            ...dataSetData,
            [e.target.name]: e.target.value
        }
        setDataSetData(updatedState);
        saveDataSetDataInStore(updatedState);
        resetError(e);
    }

    const resetError = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== "") {
            setMinimisationErrorMessage("");
        }
    };
    /* Function to set the state value*/
    /* To handle Onclick */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, submitDataMinimisation());
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    /* To Validate the each field's data */
    const ValidateFields = () => {
        let isValid: boolean = true;
        if (dataSetData.stepsMinimiseDataRequested === null || dataSetData.stepsMinimiseDataRequested === undefined || dataSetData.stepsMinimiseDataRequested.trim() === "") {
            setMinimisationErrorMessage(`${dataSetErrorMessage.dataMinimisation_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }
    const submitDataMinimisation = () => {
        navigate(routeName.legalBases);
    }
    /* To handle Onclick */

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-u-padding-bottom-5"></div>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">About the data &gt; {dataSetData.name} </span>
                            <h1>How will you minimise the data you are requesting?</h1>
                            {minimisationErrorMessage !== "" && <ErrorDetails errorDescription={minimisationErrorMessage} errorHref="#stepsMinimiseDataRequested"></ErrorDetails>}
                            <Details>
                                <Details.Summary>Guidance</Details.Summary>
                                <Details.Text>
                                    <p>The UK General Data Protection Regulation (UK GDPR) states that "Personal data shall be adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed". This means that the amount of data requested must be justified by the purpose stated within the application.</p>
                                    <p>Additional justification of your request for data is required where identifiable or high-risk (sensitive) data is requested, or when requesting data for patients aged under 16. </p>
                                    <p>When providing information in the text box, consider whether: </p>
                                    <ul>
                                        <li>it is possible to reduce the number of data sets requested </li>
                                        <li>the purpose can be achieved using anonymised or pseudonymised data</li>
                                        <li>it is possible to reduce the number of years requested </li>
                                        <li>the data can be narrowed by geography  </li>
                                        <li>the data can be narrowed by demographics </li>
                                        <li>the data can be narrowed by clinical factors, for example, a diagnosis or procedure  </li>
                                        <li>all patients' episodes are required to achieve the purpose </li>
                                        <li>all elective episodes are required to achieve the purpose </li>
                                        <li>maternity episodes are required to achieve the purpose. If they are, consider whether receiving unborn child and neonatal records are necessary </li>
                                        <li>you can achieve your purpose by asking for 30-day mortality dates rather than a specific date of death (for example)</li>
                                        <li>additional filters can be applied. For example, whether Hospital Episode Statistics (HES) data linked to mental health data is only useful where there is an associated mental health record </li>
                                    </ul>
                                </Details.Text>
                            </Details>
                            <Textarea
                                label="Use the text box to provide information on how you will minimise the data you are requesting"
                                name="stepsMinimiseDataRequested"
                                id="stepsMinimiseDataRequested"
                                onChange={handleChangeTextarea}
                                maxLength={10000}
                                rows={5}
                                error={minimisationErrorMessage}
                                defaultValue={dataSetData.stepsMinimiseDataRequested}
                            />
                            <div className="nhsuk-u-padding-bottom-8"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}
export default DataMinimisation;