import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/store';
import { IDeleteDocument } from '../../../models/app.interface';

const initialState: IDeleteDocument = {
  fileIndex: 0,
  approvalEvidenceID: '',
  fileId: 0,
  isDeleted: false,
};

export const DeleteDocumentSlice = createSlice({
  name: 'DeleteDocument',
  initialState,
  reducers: {
    setDeleteDocumentData: (state: IDeleteDocument, action: PayloadAction<IDeleteDocument>) => {
      state = Object.assign(state, action.payload);
    },
    resetDeleteDocumentData: () => initialState
  },
});

export const { setDeleteDocumentData, resetDeleteDocumentData } = DeleteDocumentSlice.actions;
export const getDeleteDocumentData = (state: RootState) => state.deleteDocumentDetails;
export default DeleteDocumentSlice.reducer;