import { Button, Textarea, Label } from "nhsuk-react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitScreenYieldedBenefits_URL } from "../../../../config/api-endpoints.config";
import { getCookie, submitSpinner, fetchInterceptor } from "../../../../Helper/Utility";
import { IApplication, IYieldedBenefits } from "../../../../state/models/app.interface";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { dsaContent, fileTypeSize, routeName, taskListSectionStatus, yieldedBenefitsErrorMessageConfig } from "../../../../config/page.config";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import { getYieldedBenefitsDetails, setYieldedBenefitsDetailsData } from "../../../../state/slice/YieldedBenefits/YieldedBenefits.slice";

const YieldedBenefits: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    const yieldedBenefitsDetailsData: IYieldedBenefits = useAppSelector(getYieldedBenefitsDetails);
    const initialStateYB: IYieldedBenefits = yieldedBenefitsDetailsData || {};
    const [yieldedBenefitsData, setYieldedBenefitsData] = useState(initialStateYB);
    const saveYieldedBenefitsDataInStore = (stateData?: IYieldedBenefits) => {
        dispatch(setYieldedBenefitsDetailsData({ ...yieldedBenefitsData, ...stateData }));
    }

    const [yieldedBenefitsErrorMessage, setYieldedBenefitsErrorMessage] = useState("");

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, submitYieldedBenefits);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const submitYieldedBenefits = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "YieldedBenefits": yieldedBenefitsData.yieldedBenefits,
            "SectionStatus": dsaApplicationData.aboutYieldedBenefitsSectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenYieldedBenefits_URL, requestOptions)
            .then(() => {
                let sectionStatus: IApplication = {
                    ...dsaApplicationData, aboutYieldedBenefitsSectionStatus: taskListSectionStatus.inProgress
                }
                setDsaApplicationData(sectionStatus);
                saveApplicationDataInStore(sectionStatus);
                navigate(routeName.completedYieldedBenefits);

            })
            .catch(() => navigate(routeName.serviceError));

    }



    const validateFields = () => {
        let isValid: boolean = true;
        if (!yieldedBenefitsData.yieldedBenefits || yieldedBenefitsData.yieldedBenefits.trim() === "") {
            setYieldedBenefitsErrorMessage(`${yieldedBenefitsErrorMessageConfig.enterYieldedBenefits_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IYieldedBenefits = {};
        updatedState = {
            ...yieldedBenefitsData,
            yieldedBenefits: e.target.value
        }
        saveYieldedBenefitsDataInStore(updatedState);
        setYieldedBenefitsData(updatedState);
        resetError(e);
    }

    const resetError = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== "") {
            setYieldedBenefitsErrorMessage("");
        }
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <h1>Yielded benefits</h1>
                                {yieldedBenefitsErrorMessage !== "" && <ErrorDetails errorDescription={yieldedBenefitsErrorMessage} errorHref="#yieldedBenefitsActivities"></ErrorDetails>}
                                <div className="nhsuk-u-padding-bottom-3"></div>
                                <Label size="s"><p>Tell us what measurable benefits to health and social care have been yielded to date.
                                     You can see some examples of yielded benefits in our <a href="https://digital.nhs.uk/services/data-access-request-service-dars/data-uses-register" target="_blank">Data Uses Register</a>.</p>
                                    <p> Provide information on the yielded benefits in the text box below</p>
                                 </Label>
                                <div className="nhsuk-u-padding-bottom-3"></div>
                                <Textarea
                                    hint="You can expand this text box by dragging the bottom right corner down."
                                    error={yieldedBenefitsErrorMessage}
                                    name="yieldedBenefitsActivities"
                                    id="yieldedBenefitsActivities"
                                    onChange={handleChangeTextarea}
                                    defaultValue={yieldedBenefitsData.yieldedBenefits ? yieldedBenefitsData.yieldedBenefits : ""}
                                    maxLength={10000}
                                    rows={5}
                                />
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>
                        }
                    </div>
                </main>
            </div>

        </>)
}

export default YieldedBenefits;