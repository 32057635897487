import { InsetText } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader';
import { IApiResDSADetails, IApplication, IUserDetails } from "../../../../state/models/app.interface";
import { getUserDetails } from "../../../../state/slice/Header/Header.slice";
import { useAppSelector } from "../../../../state/app/hooks";
import { fetchInterceptor, getCookie } from '../../../../Helper/Utility';
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { CloseDSAApplication_URL, SubmitScreenDSAIntro_URL } from "../../../../config/api-endpoints.config";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import { dsaContent, fileTypeSize, routeName } from "../../../../config/page.config";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch } from '../../../../state/app/hooks';

const ClosingAgreement: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const initialStateofUserData: IUserDetails = useAppSelector(getUserDetails) || {};
    const [userData] = useState(initialStateofUserData);
    const [loading, setLoading] = useState(true);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData, setDsaApplicationData] = useState(initialApplicationState);

    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }

    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const closeAgreement = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(CloseDSAApplication_URL, requestOptions)
            .then(() => {
                    setLoading(false);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setSectionStatus = (data: IApiResDSADetails, applicationId: string) => {
        let updatedState: IApplication = {};
        updatedState = {
            ...dsaApplicationData,
            id: applicationId,
            referenceNumber: data.AppReferenceNumber,
            totalEstimatedCost: data.TotalChargeInclVat,
        }
        setDsaApplicationData(updatedState);
        saveApplicationDataInStore(updatedState);
    }
    const getSectionStatus = (applicationId: string) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": applicationId ? applicationId : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenDSAIntro_URL, requestOptions)
            .then(response => {
                    return response.json()
            })
            .then(data => {
                setSectionStatus(data, applicationId);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    useEffect(() => {
        getSectionStatus(dsaApplicationData.id!);
        closeAgreement();
    }, []);

    return (
        <>
            <ControllerHeader></ControllerHeader>
            {loading ?
                (<div className="nhsuk-grid-column-full nhsuk-u-margin-top-4">
                    <Loader loadingText={dsaContent.txtLoading} />
                </div>) : null}
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            (null) : (
                                <div className="nhsuk-grid-column-three-quarters">
                                    <GoBack></GoBack>
                                    <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                        {userData?.userOrgName}
                                    </span>
                                    <h1>
                                        Closing your Data Sharing Agreement
                                    </h1>
                                    <InsetText>
                                        <p>
                                            Closing an agreement will mean that all data associated with your Data Sharing Agreement (DSA) must be deleted or destroyed. You will be required to provide proof of its destruction.
                                        </p>
                                    </InsetText>
                                    <p>
                                        You have selected, 'I would like to arrange to close my DSA because it has either expired, is due to expire or for another reason'.
                                    </p>
                                    <p>
                                        To close your agreement, email <a href="mailto:enquiries@nhsdigital.nhs.uk?subject='Close agreement'">
                                            enquiries@nhsdigital.nhs.uk
                                        </a>, quoting 'Close agreement' in the subject field.
                                    </p>
                                </div>
                            )}

                    </div>
                </main>
            </div>
        </>)
}
export default ClosingAgreement;