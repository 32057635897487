import { Button, Details, InsetText, Input, ErrorSummary, Fieldset, ErrorMessage, Table } from "nhsuk-react-components";
import React, { useState, ChangeEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { submitSpinner, fetchInterceptor, getCookie, validateFile, handleUploadFile, deleteUploadedFiles, getEvidenceFileDetails, isValid } from "../../../../Helper/Utility";
import { IApiResEvidenceFileSummary, IApplication, IEvidenceFileSummary, IFileUpload, IParticipant } from "../../../../state/models/app.interface";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { fileErrorMessage, dsaContent, fileTypeSize, evidenceTypeOptions, participantErrorMessage, routeName, controllerSection } from "../../../../config/page.config";
import { SubmitScreenEvidenceCreate_URL } from "../../../../config/api-endpoints.config";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { getParticipantDetails, setParticipantDetailsData } from "../../../../state/slice/Participant/Participant.slice";
import GoBack from "../../../../Components/Shared/GoBack";
import FileUploadControl from "../../../../Components/FileUploadControl/FileUploadControl";

const UploadConsent: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);
    let [fileLists, setFileLists] = useState<IFileUpload[]>([]);
    let [fileListsOnChange, setFileListsOnChange] = useState<File[]>([]);
    const [selectedFile, setSelectedFile] = useState<File[] | []>([]);
    const [isSelected, setIsSelected] = useState(false);
    const [uploadAnother, setUploadAnother] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [fileChosen, setfileChosen] = useState(false);
    const [validFileError, setValidFileError] = useState("");
    const [isFileUploadedError, setIsFileUploadedError] = useState("");
    const [isFileUploadedErrorLink, setIsFileUploadedErrorLink] = useState("");
    const [validateChooseFile, setValidateChooseFile] = useState(false);
    const participantDetailsData: IParticipant = useAppSelector(getParticipantDetails);
    const initialState: IParticipant = participantDetailsData || {};
    const [participantData, setParticipantData] = useState(initialState);
    const [comments, setComments] = useState('');
    const [isCommentError, setIsCommentError] = useState("");
    const [recentlyAddedNHSId] = useState(participantData?.recentlyAddedNHSHealthId != '' ? participantData?.recentlyAddedNHSHealthId : '' || '');
    let [evidenceSummaryList, setEvidenceSummaryList] = useState<IEvidenceFileSummary[]>([]);
    const [selectedFileName, setSelectedFileName] = useState("");

    /* To dispatch the setParticipantDetailsData Redux action */
    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantData, ...stateData }));
    }

    const handelUploadedFiles = (files: File[]) => {
        const uploaded: File[] = [];
        files.forEach((file: File) => {
            uploaded.push(file);
        })
        setSelectedFile(uploaded)
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === dsaContent.file) {
            resetError(e);
            const chosenFiles = Array.prototype.slice.call(e.target.files)
            setValidateChooseFile(true);
            if (validateFile(e, fileTypeSize, setValidFileError, setIsFileUploadedError, fileErrorMessage, setIsSelected, false, uploadedFileOnChange[0].name, evidenceSummaryList)) {
                handelUploadedFiles(chosenFiles);
                setfileChosen(true);
                setValidFileError("");
                setValidateChooseFile(false);
            }
            else {
                setSelectedFile([]);
                setIsSelected(false);
                e.target.value = '';
            }
            let updatedState: IParticipant = {};
            updatedState = { ...participantData, [e.target.name]: e.target.value }
            setParticipantData(updatedState);
            saveDataInStore(updatedState);
        }
        else {
            let updatedState: IParticipant = {};
            setParticipantData(updatedState);
            saveDataInStore(updatedState);
            resetError(e);
        }
        if (e.target.name === dsaContent.version) {
            resetError(e);
        }

    };
    const files = selectedFile ? Array.from(selectedFile) : [];
    const validateUploadedFile = () => {
        if ((uploadedFileOnChange?.length + files?.length) > 6) {
            setValidFileError(`${fileErrorMessage.fileLength_ErrorMessage}`);
            setSelectedFile([]);
            setIsSelected(false);
            return false;
        }
        else if (files?.length === 0) {
            setValidFileError(`${fileErrorMessage.fileRequiredToUpload_ErrorMessage}`);
            setSelectedFile([]);
            setIsSelected(false);
            return false;
        }
        return true;
    }

    /* To upload the file */
    const handleUpload = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = validateUploadedFile();
        if (IsValid) {
            handleUploadFile(e, fileLists, fileListsOnChange, setIsUploaded, selectedFile!, files, setFileLists, setFileListsOnChange, setIsSelected, setSelectedFile, resetError, false, false, setUploadAnother, evidenceSummaryList!, setEvidenceSummaryList, setSelectedFileName)
        }
    }

    let finallyUploadedFiles: IFileUpload[] = fileLists ? Array.from(fileLists) : [];
    let uploadedFileOnChange  = fileListsOnChange ? Array.from(fileListsOnChange) : [];

    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueLength = e.target.value.length;
        if (valueLength >= 1) {
            if (e.target.id === dsaContent.file) {
                setIsFileUploadedError("");
                setValidFileError("");
                setIsFileUploadedErrorLink("");
            }
        }
        if (e.target.name === dsaContent.upload) {
            setIsFileUploadedError("");
            setIsFileUploadedErrorLink("");
        }
        if (e.target.value != '') {
            setIsCommentError('');
        }
    }
    /* To Validate the each field's data */
    const ValidateFields = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLInputElement;
        let isValid: boolean = true;
        if (finallyUploadedFiles.length <= 0 && target.name !== dsaContent.upload) {
            setIsFileUploadedErrorLink(`${fileErrorMessage.fileLink_ErrorMessage}`);
            setIsFileUploadedError(`${fileErrorMessage.fileUploded_ErrorMessage}`);
            isValid = false;
        }
        if (!fileChosen) {
            setIsFileUploadedErrorLink(`${fileErrorMessage.fileLink_ErrorMessage}`);
            setIsFileUploadedError(`${fileErrorMessage.fileRequiredSummaryBody_ErrorMessage}`);
            isValid = false;
        }
        if (validateChooseFile) {
            setIsFileUploadedError("");
        }
        if (comments === '') {
            setIsCommentError(`${participantErrorMessage.uploadDocumentComment_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields(e);
        if (IsValid) {
            submitSpinner(e, submitEvidence);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const submitEvidence = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "Id": participantData.isFromParticipant && recentlyAddedNHSId !== '' ? recentlyAddedNHSId : '',
            "Comments": comments,
            "SelectedEvidenceUpload": evidenceTypeOptions.Section251Support?.value,
            "FileList": uploadedFileOnChange,
            "SectionId": controllerSection.Participants

        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenEvidenceCreate_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                let updatedState: IParticipant = {};
                updatedState = {
                    ...participantData,
                    fileUploadNHS: finallyUploadedFiles,
                    isFromParticipant: true,
                    recentlyAddedNHSHealthId: data.Id,
                    evidenceFileSummary: evidenceSummaryList
                }
                setParticipantData(updatedState);
                saveDataInStore(updatedState);
                navigate(routeName.uploadedFiles);

            })
            .catch(error => console.log('Error Reading data', error));
    }

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{participantErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#commentbox">
                            {isCommentError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#fileupload">
                            {isFileUploadedErrorLink}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    const HandleOtherTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setComments(e.target.value);
        let updatedState: IParticipant = {};
        updatedState = {
            ...participantData,
            fileCommentNHS: e.target.value
        }
        setParticipantData(updatedState);
        saveDataInStore(updatedState);
        resetError(e);
        resetError(e);
    };

    const showFileList = () => {
        let showfileLists = null
        if (participantData.fileUploadConsent !== null && participantData.fileUploadConsent != undefined) {
            const filterFile = participantData.fileUploadNHS;
            showfileLists = Object.assign([], filterFile);
            if (showfileLists?.filter((x: IFileUpload) => x.FileName !== "")[0]) {
                setFileLists(showfileLists);
                finallyUploadedFiles = showfileLists;
                if (finallyUploadedFiles.length >= 1) {
                    setIsSelected(true);
                    setfileChosen(true);
                }
            }
            setComments(participantData?.fileCommentNHS != undefined ? participantData?.fileCommentNHS : '')
        }
    }

    const uploadAnotherFile = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        setUploadAnother(true);
    }

    const getEvidenceFileSummary = () => {
        let applicationId = isValid(dsaApplicationData.id) ? dsaApplicationData.id : "";
        if (isValid(applicationId)) {
            getEvidenceFileDetails(applicationId!).then((data) => {
                setEvidenceFileSummary(data);
            });
        }
    }

    const setEvidenceFileSummary = (response: IApiResEvidenceFileSummary[]) => {
        if (isValid(response)) {
            let evidenceFileSummary: IEvidenceFileSummary[] = [];

            response.map(({ FileId: fileId, FileName: fileName, MimeType: mimeType, Source: source }) => {
                evidenceFileSummary.push({ fileId, fileName , mimeType , source });
            });
            let updatedState: IParticipant = {};
            updatedState = {
                ...participantData,
                evidenceFileSummary
            }
            setParticipantData(updatedState);
            saveDataInStore(updatedState);
        }

    }

    useEffect(() => {
        showFileList();
        if (!isValid(participantData.evidenceFileSummary)) {
            getEvidenceFileSummary();
        }
        else {
            setEvidenceSummaryList(participantData.evidenceFileSummary!);
        }
    }, [])
    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack />
                            <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                About the Participants
                            </span>
                            <h1>Upload National Health Service Act 2006 - s251 - 'Control of patient information' document</h1>
                            {isCommentError !== "" || isFileUploadedErrorLink != '' ? errorSummary : null}
                            <InsetText>
                                <p>Your document uploads might include a letter from the Confidentiality Advising Group (CAG) that supports the application Section 251 (s251) or another CAG document that supports s251.</p>
                                <p>Get help <a href="https://www.hra-decisiontools.org.uk/consent/examples.html" target='_blank'>improving your consent documentation</a></p>
                            </InsetText>
                        </div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <p>Document name</p>
                            <p>Provide the document name in the text box below </p>
                            <Input type="text" error={isCommentError} id='commentbox' autoComplete="off" className="nhsuk-input nhsuk-input--width-20" name="dataSetSelectedLegalBasisOtherText" value={comments} onChange={HandleOtherTextChange} />
                        </div>
                        <div className="nhsuk-u-padding-bottom-3"></div>
                        <div className="nhsuk-grid-column-two-thirds">
                            <Fieldset>
                                <Fieldset.Legend>Document required </Fieldset.Legend>
                            </Fieldset>
                            <div className="nhsuk-u-padding-bottom-2"></div>
                            <Details>
                                <Details.Summary>View file guidance </Details.Summary>
                                <Details.Text>
                                    <p>A file should not exceed 3 MB and should be one of the following acceptable file formats: PDF, Word, Excel. </p>
                                </Details.Text>
                            </Details>
                        </div>

                        <FileUploadControl
                            errorAboveList = {isFileUploadedError}
                            errorBelowList = {validFileError}
                            fileExist = {isSelected}
                            isUploaded = {isUploaded}
                            finallyUploadedFiles = {finallyUploadedFiles}
                            handleChange = {handleChange}
                            handleUpload = {handleUpload}
                            uploadAnother = {uploadAnother} 
                            uploadAnotherFile = {uploadAnotherFile}
                        />

                        {/* <div className="nhsuk-grid-column-two-thirds" id="fileupload" >
                            <ErrorMessage>{isFileUploadedError}</ErrorMessage>
                            {isSelected || finallyUploadedFiles.length >= 1 ?
                                <>                                   
                                    <p>Files you have uploaded</p>
                                    <Table responsive>
                                        <Table.Body>
                                            {finallyUploadedFiles.map((file: IFileUpload, i: number) => (
                                                <Table.Row key={i + 1} >
                                                    <Table.Cell> {file.FileName}</Table.Cell>
                                                    <Table.Cell><a href="#" onClick={(e) => deleteupLoadedDocData(e, i, file.FileId!)}>Remove file</a></Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                    <div className="nhsuk-u-padding-bottom-4"></div>
                                    <p><a href="#" onClick={(event) => uploadAnotherFile(event)} >Upload another file</a></p>
                                </>
                                : null}
                            <ErrorMessage> {validFileError}</ErrorMessage>
                            {!isSelected || uploadAnother ?
                                <>
                                    <input type="file" id={dsaContent.file} multiple name={dsaContent.file} onChange={handleChange} />
                                    <p></p>
                                    <p>
                                        <Button secondary name={dsaContent.upload} id={dsaContent.upload} onClick={handleUpload} >Upload </Button>
                                        {isUploaded ?
                                            <span className="nhsuk-loader__container loading-results ">
                                                <span className="nhsuk-loader-file"></span>
                                                Uploading
                                            </span> : null}
                                    </p>
                                </>
                                : null}
                        </div> */}
                        <div className="nhsuk-grid-column-two-thirds">
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default UploadConsent;