import { Button, Fieldset, InsetText, Radios } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitScreenCommonLawDuty_URL} from "../../../../config/api-endpoints.config";
import { controllerSection, dataSetErrorMessage, dsaContent, fileTypeSize, legalBasesIdentifiableDataOptions, routeName } from "../../../../config/page.config";
import { getCookie, submitSpinner, fetchInterceptor, filterEvidenceType } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IDataSet, IEvidenceList } from "../../../../state/models/app.interface";
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getEvidenceList, initialEvidence, setEvidenceListData } from "../../../../state/slice/EvidenceList/EvidenceList.slice";

const LegalBasesIdentifiableData: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [legalBasesIdentifiableDataErrorMessage, setLegalBasesIdentifiableDataErrorMessage] = useState("");
    const [dataSetData, setDataSetData] = useState<IDataSet>(useAppSelector(getDataSetDetails) || {});
    const [isLBchange, setisLBChange] = useState(false);

    /* To dispatch the setDataSetDetailsData Redux action */
    const saveDataSetDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }

    const evidenceList: IEvidenceList = useAppSelector(getEvidenceList);
    const evidenceinitialState: IEvidenceList = evidenceList || {};
    const [evidenceListDetails, setEvidenceListDetails] = useState(evidenceinitialState);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);

    const saveEvidenceDataInStore = (stateData?: IEvidenceList) => {
        dispatch(setEvidenceListData({ ...evidenceListDetails, ...stateData }));
    }

    /* Function to set the state value on change of Radios(yes/no) */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IDataSet = {};
        updatedState = {
            ...dataSetData,
            [e.target.name]: e.target.value
        }
        setDataSetData(updatedState);
        saveDataSetDataInStore(updatedState);
        setisLBChange(true);
        resetError(e);
    }
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setLegalBasesIdentifiableDataErrorMessage("");
        }
    };
    /* Function to set the state value on change of Radios(yes/no) */

    /* To handle Onclick */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, submitLegalBasesIdentifiableData());
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    /* To Validate the each field's data */
    const ValidateFields = () => {
        let isValid: boolean = true;
        if (!dataSetData.legalBasesIdentifiableData) {
            setLegalBasesIdentifiableDataErrorMessage(`${dataSetErrorMessage.legalBasesIdentifiableDataErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const submitLegalBasesIdentifiableData = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "CommonLawDutyOfConfidentialityType": dataSetData.legalBasesIdentifiableData,
            "ApprovalEvidenceType": filterEvidenceType(dataSetData.legalBasesIdentifiableData),
            "SectionId": controllerSection.aboutData,
            "IsLegalBasisChanged": isLBchange
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenCommonLawDuty_URL, requestOptions)
            .then(() => {
                let updatedStateEvidence: IEvidenceList = {};
                updatedStateEvidence = {
                    ...evidenceListDetails,
                    isAddAnotherAE: dsaContent.no,

                }
                setEvidenceListDetails(updatedStateEvidence);
                saveEvidenceDataInStore(updatedStateEvidence);
                navigate(routeName.approvalEvidenceData);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    /* To handle Onclick */

    /*Radio Populate*/
    const renderRadio = (renderOptionsList: string[]) => {
        return renderOptionsList && renderOptionsList?.map((option: string) => {
            return (<React.Fragment key={option}>
                <Radios.Radio name="legalBasesIdentifiableData" value={option} onChange={handleChange} key={option} checked={String(dataSetData.legalBasesIdentifiableData) === String(option)}>{getContent(option)}
                </Radios.Radio>
            </React.Fragment>
            )
        });
    }

    const getContent = (option: string) => {
        switch (option) {
            case legalBasesIdentifiableDataOptions.Consent_ReasonableExpectation: return "Consent (Reasonable Expectation)";
            case legalBasesIdentifiableDataOptions.Section251NHSAct2006: return "Section 251 of the NHS Act 2006";
            case legalBasesIdentifiableDataOptions.Mixtureofconfidentialdataflow_swithconsentandflow_swithsupportundersection251NHSAct2006: return "Mixture of confidential data flow(s) with consent and flow(s) with support under Section 251 of the NHS Act 2006";
            case legalBasesIdentifiableDataOptions.Mixtureofconfidentialdataflow_swithconsentandnonconfidentialdataflow_s: return "Mixture of confidential data flow(s) with consent and non-confidential data flow(s)";
            case legalBasesIdentifiableDataOptions.Mixtureofconfidentialdataflow_swithsupportundersection251NHSAct2006andnonconfidentialdataflow_s: return "Mixture of confidential data flow(s) with support under Section 251 of the NHS Act 2006 and non-confidential flow(s)";
            case legalBasesIdentifiableDataOptions.Statutoryexemptiontoflowconfidentialdatawithoutconsent: return "Statutory exemption to flow confidential data without consent";
        }
    }
    /*Radio Populate*/
    useEffect(() => {
        let updatedState: IEvidenceList = {};
        updatedState = {
            ...evidenceListDetails,
            evidenceList: [initialEvidence]
        };
        setEvidenceListDetails(updatedState);
        saveEvidenceDataInStore(updatedState);
    }, [])
    

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <span className="nhsuk-caption-l nhsuk-caption--bottom"> About the data </span>
                            <h1>Select the legal basis for receiving identifiable data from NHS England</h1>
                            {legalBasesIdentifiableDataErrorMessage !== "" && <ErrorDetails errorDescription={legalBasesIdentifiableDataErrorMessage} errorHref="#radioLegalBasesIdentifiableData"></ErrorDetails>}
                            <InsetText>
                                <p> Depending on your selection, you may be asked to provide further evidence. If further evidence is required, you will be asked to upload it on the next page.</p>
                            </InsetText>
                            <Fieldset>
                                <Radios name="radioLegalBasesIdentifiableData" id="radioLegalBasesIdentifiableData" hint="" error={legalBasesIdentifiableDataErrorMessage}>
                                    {legalBasesIdentifiableDataOptions && renderRadio([legalBasesIdentifiableDataOptions.Consent_ReasonableExpectation!, legalBasesIdentifiableDataOptions.Section251NHSAct2006!, legalBasesIdentifiableDataOptions.Mixtureofconfidentialdataflow_swithconsentandflow_swithsupportundersection251NHSAct2006!, legalBasesIdentifiableDataOptions.Mixtureofconfidentialdataflow_swithconsentandnonconfidentialdataflow_s!, legalBasesIdentifiableDataOptions.Mixtureofconfidentialdataflow_swithsupportundersection251NHSAct2006andnonconfidentialdataflow_s!, legalBasesIdentifiableDataOptions.Statutoryexemptiontoflowconfidentialdatawithoutconsent!])}
                                </Radios>
                            </Fieldset>
                            <div className="nhsuk-u-padding-bottom-8"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default LegalBasesIdentifiableData