import { Button, ErrorSummary, Fieldset, Radios } from "nhsuk-react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import { SubmitDSASectionStatus_URL } from "../../../../config/api-endpoints.config";
import { amendRenewExtendValue, controllerSection, dsaContent, dsaErrorMessage, fileTypeSize, participantErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { fetchInterceptor, getCookie, submitSpinner } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IParticipant } from "../../../../state/models/app.interface";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getParticipantDetails, setParticipantDetailsData } from "../../../../state/slice/Participant/Participant.slice";

const HaveAllRelevantParticipantData: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);
    const participantDetailsData: IParticipant = useAppSelector(getParticipantDetails);
    const initialState: IParticipant = participantDetailsData || {};
    const [participantData, setParticipantData] = useState(initialState);
    /* To dispatch the setParticipantDetailsData Redux action */

    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantData, ...stateData }));
    }

    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [sectionStatus, setSectionStatus] = useState("");
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [isSectionDataSetErrorMessage, setIsSectionDataSetErrorMessage] = useState("");
    const [selected, setSelected] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSectionStatus(e.target.value);
        setSelected(true);
        resetError(e);
    };

    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setIsSectionDataSetErrorMessage("");
        }
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = ValidateFields();
        if (IsValid) {
            submitSpinner(e, submitParticipantDetails);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    const submitParticipantDetails = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "SectionId": controllerSection.Participants,
            "SectionStatus": sectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitDSASectionStatus_URL, requestOptions)
            .then(() => {
                    setParticipantStatus(sectionStatus);
                    navigate(routeName.dsaTaskList);
               
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setParticipantStatus = (aboutParticipantSectionStatus: string) => {
        let updatedState: IParticipant = {};
        updatedState = {
            ...participantData,
            aboutParticipantSectionStatus: aboutParticipantSectionStatus
        }
        setParticipantData(updatedState);
        saveDataInStore(updatedState);
    }

    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#radioSectionComplete">
                            {summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )


    const ValidateFields = () => {
        let isValid: boolean = true;

        if (!selected) {
            setSummaryLinkErrorMessage(`${participantErrorMessage.selectParticipantSectionCompleteBody_ErrorMessage}`);
            setIsSectionDataSetErrorMessage(`${participantErrorMessage.selectParticipantSectionCompleteBody_ErrorMessage}`);
            isValid = false;
        }

        return isValid;
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <h1>Have all relevant details for the Participants been added? </h1>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <Fieldset>
                                <Radios name="radioSectionComplete" id="radioSectionComplete" error={isSectionDataSetErrorMessage}>
                                    <Radios.Radio name="sectionComplete" value={taskListSectionStatus.completed} onChange={handleChange}>Yes, all relevant details for the Participants have been added </Radios.Radio>
                                    <Radios.Radio name="sectionComplete" value={taskListSectionStatus.inProgress} onChange={handleChange}>No, I'll add them later</Radios.Radio>
                                </Radios>
                            </Fieldset>
                            <div className="nhsuk-u-padding-bottom-6"></div>
                            <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default HaveAllRelevantParticipantData;