import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { IFileUpload } from '../../state/models/app.interface';
import { dsaContent } from '../../config/page.config';
import { Button, ErrorMessage, Table } from 'nhsuk-react-components';

interface FileUploadControlProps {
    inputRef?: React.Ref<HTMLInputElement>,
    errorAboveList: string; // isFileUploadedError
    errorBelowList: string; // validFileError
    fileExist?: boolean; // isSelected
    isUploaded: boolean;
    allowedFileExtension?: string;
    finallyUploadedFiles: IFileUpload;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleUpload: (e: React.MouseEvent<HTMLButtonElement>) => void;
    changeFile?: (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        fileId: number,
        fileName: string,
        fileType: string,
        approvalEvidence: string,
    ) => void;
    setFileUrl?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, setSelectedIndex: React.Dispatch<React.SetStateAction<number>>, setshowdownload: React.Dispatch<React.SetStateAction<boolean>>) => void;
    showDownload?: boolean; //showDownload
    selectedIndex?: number;
    setSelectedIndex?: React.Dispatch<React.SetStateAction<number>>;
    setshowdownload?: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedFileId?: Dispatch<SetStateAction<number>>;
    setSelectedApprovalEvidence?: Dispatch<SetStateAction<string>>;
}

const changeUploadedFile = (
    fileId: number,
    setSelectedFileId: Dispatch<SetStateAction<number>>,
    approvalEvidenceId: string,
    setSelectedApprovalEvidence: Dispatch<SetStateAction<string>>

) => {
    const fileElement = document.getElementById(dsaContent.file!);
    if (fileElement) {
        fileElement?.click();
        setSelectedFileId(fileId);
        if (setSelectedApprovalEvidence !== undefined && setSelectedApprovalEvidence !== null)
        {
            setSelectedApprovalEvidence(approvalEvidenceId);
        }

    }
};

const FileUploadControl1: React.FC<FileUploadControlProps> = ({
    inputRef,
    errorAboveList,
    errorBelowList,
    fileExist,
    isUploaded,
    finallyUploadedFiles,
    handleChange,
    handleUpload,
    setFileUrl,
    showDownload,
    selectedIndex,
    setSelectedFileId,
    setSelectedIndex,
    setshowdownload,
    setSelectedApprovalEvidence,
}) => {
    const [isChangeFile, setIsChangeFile] = useState(false);

    const fileHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsChangeFile(true);
        handleChange(e);
    }

    return (
        <div className="nhsuk-grid-column-two-thirds" id="fileupload">
            <ErrorMessage>{errorAboveList}</ErrorMessage>
            {(fileExist || finallyUploadedFiles!) &&
                finallyUploadedFiles!?.FileName! !== '' &&
                finallyUploadedFiles!?.FileName !== undefined ? (
                <>
                        <p>Files you have uploaded</p>
                    <Table responsive>
                        <Table.Body>
                            <Table.Row>
                                    <Table.Cell>
                                    {' '}
                                    {finallyUploadedFiles!.FileId! > 0 && !isChangeFile && setFileUrl ? (
                                        <a
                                            href="#" id={String(finallyUploadedFiles!.FileId)}

                                                onClick={(e) => setFileUrl(e, setSelectedIndex!, setshowdownload!)}

                                        >
                                            {finallyUploadedFiles!.FileName}
                                        </a>
                                    ) : (
                                        finallyUploadedFiles!.FileName
                                    )}
                                    {showDownload &&
                                        String(finallyUploadedFiles!.FileId) === String(selectedIndex) ? (
                                        <span className="nhsuk-loader__container loading-results ">
                                            <span className="nhsuk-loader-file"></span>
                                            Downloading file
                                        </span>
                                    ) : null}
                                </Table.Cell>
                                    <Table.Cell>
                                    <a
                                        href="javascript:void(0)"
                                        onClick={(e) =>
                                            changeUploadedFile(finallyUploadedFiles!.FileId!, setSelectedFileId!, finallyUploadedFiles!.ApprovalEvidenceID!, setSelectedApprovalEvidence!)
                                        }
                                    >
                                        Change
                                    </a>
                                </Table.Cell>
                            </Table.Row>
                            {/* ))} */}
                        </Table.Body>
                    </Table>
                </>
            ) : null}
            <ErrorMessage> {errorBelowList}</ErrorMessage>
            <div className={finallyUploadedFiles?.FileName! !== undefined && finallyUploadedFiles?.FileName! !== "" ? 'hide' : ''}>
                <>
                    <input ref={inputRef} type="file" id={dsaContent.file} name={dsaContent.file} onChange={fileHandleChange}/>
                    <p></p>
                    <p>
                        <Button
                            secondary
                            name={dsaContent.upload}
                            id={dsaContent.upload}
                            onClick={handleUpload}
                        >
                            Upload{' '}
                        </Button>
                        {isUploaded ? (
                            <span className="nhsuk-loader__container loading-results">
                                <span className="nhsuk-loader-file"></span>
                                Uploading
                            </span>
                        ) : null}
                    </p>
                </>
            </div>
        </div>
    );
};
export default FileUploadControl1;