import React, { useState } from "react";
import { Button, InsetText, Textarea } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IApplication, IPrivacyNotice } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { amendRenewExtendValue, controllerSection, dsaContent, fileTypeSize, privacyNoticeErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import { getPrivacyNoticeDetails, setPrivacyNoticeDetailsData } from "../../../../state/slice/PrivacyNotice/PrivacyNotice.slice";
import { fetchInterceptor, getCookie, submitDSASectionStatus, submitSpinner } from "../../../../Helper/Utility";
import { SubmitScreenPrivacyNotice_URL } from "../../../../config/api-endpoints.config";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";

const NoPrivacyNotice: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};
    const [evidenceApprovalErrorMessage, setEvidenceApprovalErrorMessage] = useState("");
    const initialState: IPrivacyNotice = useAppSelector(getPrivacyNoticeDetails) || {};
    const [privacyNoticeData, setPrivacyNoticeData] = useState(initialState)
    const savePrivacyNoticeDataInStore = (stateData?: IPrivacyNotice) => {
        dispatch(setPrivacyNoticeDetailsData({ ...privacyNoticeData, ...stateData }));
    }
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = validateFields();
        if (IsValid) {
            submitSpinner(e, submitPrivacyNotice);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };
    const submitPrivacyNotice = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "ExplanationForNonCompliance": privacyNoticeData.explanationForNonCompliance,
            "DoesControllerEnsureCompliance": privacyNoticeData.doesControllerEnsureCompliance
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitScreenPrivacyNotice_URL, requestOptions)
            .then(() => {
                    setPrivacySectionStatus();
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setPrivacySectionStatus = () => {
        submitDSASectionStatus(dsaApplicationData?.id!, controllerSection.PrivacyNotice, taskListSectionStatus.completed)
            .then((data: object) => {
                if (data) {
                    let updatedState: IPrivacyNotice = {
                        ...privacyNoticeData,
                        aboutPrivacyNoticeStatus: taskListSectionStatus.completed
                    }
                    setPrivacyNoticeData(updatedState);
                    savePrivacyNoticeDataInStore(updatedState);
                    navigate(routeName.dsaTaskList);
                }
            });
    }

    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IPrivacyNotice = {};
        updatedState = {
            ...privacyNoticeData,
            explanationForNonCompliance: e.target.value,

        }
        setPrivacyNoticeData(updatedState);
        savePrivacyNoticeDataInStore(updatedState);
        resetError(e);
    }

    const resetError = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== "") {
            setEvidenceApprovalErrorMessage("");
        }
    };

    const validateFields = () => {
        let isValid: boolean = true;
        if (!privacyNoticeData.explanationForNonCompliance || privacyNoticeData.explanationForNonCompliance.trim() === "") {
            setEvidenceApprovalErrorMessage(`${privacyNoticeErrorMessage.explanationPrivacyNotice_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <GoBack></GoBack>
                            <h1>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    Privacy notice</span>
                                The UK General Data Protection Regulation Privacy Notice </h1>
                            {evidenceApprovalErrorMessage !== "" && <ErrorDetails errorDescription={evidenceApprovalErrorMessage} errorHref={"#" + dsaContent.noPrivacyNotice}></ErrorDetails>}
                            <InsetText>
                                <p>Because you answered 'No' on the previous page, therefore not confirming the statement, we now need you to tell us why you answered 'No' to 'The Controller(s) listed within your application agree that they will ensure that a UK General Data Protection Regulation (GDPR) Privacy Notice relating to the data within the application will be maintained throughout the life of this agreement'.</p>
                            </InsetText>
                            <Textarea
                                label="Tell us why you answered 'No' on the previous page to the statement shown above. Provide information in the text box below."
                                name={dsaContent.noPrivacyNotice}
                                id={dsaContent.noPrivacyNotice}
                                onChange={handleChangeTextarea}
                                defaultValue={privacyNoticeData.explanationForNonCompliance ? privacyNoticeData.explanationForNonCompliance : ""}
                                maxLength={32000}
                                rows={5}
                                error={evidenceApprovalErrorMessage}
                            />

                            <div className="nhsuk-u-padding-bottom-8"></div>
                            <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick} >{dsaContent.btnTextContinue}</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );

}
export default NoPrivacyNotice;
