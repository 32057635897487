import React, { useEffect, useState } from "react";
import { Button, ErrorSummary, Radios } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IApplication, IFunding, IFundingList } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { dsaContent, dsaErrorMessage, fundingSourceTypeOptions, fundingErrorMessage, taskListSectionStatus, controllerSection, routeName, amendRenewExtendValue } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { getFundingDetails, setFundingDetailsData } from "../../../../state/slice/Funding/Funding.slice";
import { getTaskListType, submitDSASectionStatus, submitSpinner } from "../../../../Helper/Utility";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { getFundingListDetails } from "../../../../state/slice/FundingList/FundingList.slice";

const FundingType: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [fundingTypeErrorMessage, setFundingTypeErrorMessage] = useState("");
    const initialFundingState: IFunding = useAppSelector(getFundingDetails) || {};
    const [fundingData, setfundingData] = useState(initialFundingState);
    const initialFundingListState: IFundingList = useAppSelector(getFundingListDetails) || {};
    const [fundingListData, setfundingListData] = useState(initialFundingListState);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData,] = useState(dsaApplicationDetails || {});
    /* To dispatch the setFundingDetailsData Redux action */
    const saveDataInStore = (stateData?: IFunding) => {
        dispatch(setFundingDetailsData({ ...fundingData, ...stateData }));
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IFunding = {};
        if (e.target.value !== null && e.target.value !== undefined) {
            updatedState = { ...fundingData, [e.target.name]: e.target.value, IsFundingSourceDirty: fundingData.isUploadedSummaryPage ? true : false }
            resetError(e);
            setfundingData(updatedState);
            saveDataInStore(updatedState);
        }

    }

    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setFundingTypeErrorMessage("");
        }
    }

    const navigateFundingDoc = () => {
        if (fundingData.sourceId) {
            navigate(routeName.addAnotherFunding);
        }
        else {
            navigate(routeName.fundingDocument);
        }
       
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            if (String(fundingData.fundingType) === String(fundingSourceTypeOptions.noFunding)) {
                submitSpinner(e, updateSectionStatus);
            }
            else {
                submitSpinner(e, navigateFundingDoc);
            }
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    const updateSectionStatus = async () => {
        submitDSASectionStatus(dsaApplicationData?.id!, controllerSection.Funding, taskListSectionStatus.completed)
            .then((data: Response) => {
                if (data) {
                    let updateState: IFunding = {}
                    updateState = {
                        ...fundingData,
                        aboutFundingSectionStatus: taskListSectionStatus.completed
                    }
                    setfundingData(updateState);
                    saveDataInStore(updateState);
                    navigate(routeName.dsaTaskList);
                }
            });
    }
    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#fundingType">{summaryLinkErrorMessage}</ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    /* To Validate the each field's data */
    const validateFields = () => {
        let isValid: boolean = true;
        if (!fundingData.fundingType) {
            if (fundingListData.isFromFundingSummary) {
                setSummaryLinkErrorMessage(`${fundingErrorMessage.fundingType_ErrorMessage}`);
                setFundingTypeErrorMessage(`${fundingErrorMessage.fundingType_ErrorMessage}`);
            }
            else {
                setSummaryLinkErrorMessage(`${fundingErrorMessage.noFundingType_ErrorMessage}`);
                setFundingTypeErrorMessage(`${fundingErrorMessage.noFundingType_ErrorMessage}`);
            }
            isValid = false;
        }
        return isValid;
    }
    useEffect(() => {
        if (((String(dsaApplicationData.aboutFundingSectionStatus)) === String(taskListSectionStatus.completed)) && !fundingListData.isFromFundingSummary) {
            let updateState: IFunding = {}
            updateState = {
                ...fundingData,
                fundingType: fundingSourceTypeOptions.noFunding
            }
            setfundingData(updateState);
            saveDataInStore(updateState);
        }
    }, []);

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            {
                                <GoBack condition={!fundingListData.isFromFundingSummary ? getTaskListType(dsaApplicationData.returningJourneyType!) : "uploaded-document-details"} />
                            }
                            <h1> <span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                Funding</span>
                                What type of funding source are you providing?
                            </h1>
                            <p>Is anyone else going to provide funding who is not the Controller and therefore, doesn&apos;t have any say in what the purpose of processing the data is, or how the processing is carried out?</p> 
                            <div className="nhsuk-u-padding-bottom-4">
                            </div>
                            {summaryLinkErrorMessage !== "" ? errorSummary : null}
                            <Radios
                                name={dsaContent.fundingtype}
                                id={dsaContent.fundingtype}
                                error={fundingTypeErrorMessage}>
                                {!fundingListData.isFromFundingSummary ?
                                    <Radios.Radio name={dsaContent.fundingtype} checked={String(fundingData.fundingType) === String(fundingSourceTypeOptions.noFunding)} value={fundingSourceTypeOptions.noFunding} onChange={handleChange}>{dsaContent.noFunding}</Radios.Radio>
                                    : null}
                                <Radios.Radio name={dsaContent.fundingtype} checked={String(fundingData.fundingType) === String(fundingSourceTypeOptions.Public)} value={fundingSourceTypeOptions.Public} onChange={handleChange}>{dsaContent.fundingPublic}</Radios.Radio>
                                <Radios.Radio name={dsaContent.fundingtype} checked={String(fundingData.fundingType) === String(fundingSourceTypeOptions.Private)} value={fundingSourceTypeOptions.Private} onChange={handleChange}>{dsaContent.fundingPrivate}</Radios.Radio>
                            </Radios>
                            <div className="nhsuk-u-padding-bottom-4">
                            </div>
                            <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default FundingType;


