import { InsetText, Button, Textarea } from "nhsuk-react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GoBack from "../../../../Components/Shared/GoBack";
import { amendRenewExtendValue, controllerSection, dsaContent, evidenceErrorMessage, fileTypeSize, maxLength, routeName, taskListSectionStatus } from "../../../../config/page.config";
import ErrorDetails from "../../../../Components/Shared/ErrorSummary";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IEvidence } from "../../../../state/models/app.interface";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { getEvidenceDetails, setEvidenceDetailsData } from "../../../../state/slice/Evidence/Evidence.slice";
import { fetchInterceptor, getCookie, submitDSASectionStatus, submitSpinner} from "../../../../Helper/Utility";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { SubmitEthicsApproval_URL } from "../../../../config/api-endpoints.config";

const EthicsApprovalNotInPlace: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};
    const initialState: IEvidence = useAppSelector(getEvidenceDetails) || {};
    const [evidenceData, setEvidenceData] = useState(initialState)

    const saveEvidenceDataInStore = (stateData?: IEvidence) => {
        dispatch(setEvidenceDetailsData({ ...evidenceData, ...stateData }));
    }

    const [EthicsApprovalNotInPlaceErrorMessage, setEthicsApprovalNotInPlaceErrorMessage] = useState("");

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateFields();
        if (isValid) {
            submitSpinner(e, submitEthicsApproval);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const submitEthicsApproval = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id,
            "EthicsApprovalRequire": evidenceData.isEvidenceApproval,
            "EthicsApprovalInPlace": evidenceData.ethicsApproval,
            "EthicsApprovalReason": evidenceData.noEthicsApproval,
            "EthicsApprovalPlaceReason": evidenceData.ethicsApprovalNotInPlace
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(SubmitEthicsApproval_URL, requestOptions)
            .then(() => {
                    setEthicsApprovalStatus();
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setEthicsApprovalStatus = () => {
        submitDSASectionStatus(dsaApplicationData?.id!, controllerSection.EthicsApproval, taskListSectionStatus.completed)
            .then((data: Response) => {
                if (data) {
                    let updatedState: IEvidence = {
                        ...evidenceData,
                        aboutEthicsApprovalStatus: taskListSectionStatus.completed
                    }
                    setEvidenceData(updatedState);
                    saveEvidenceDataInStore(updatedState);
                    navigate(routeName.dsaTaskList);
                }
            });
    }
    const validateFields = () => {
        let isValid: boolean = true;
        if (!evidenceData.ethicsApprovalNotInPlace) {
            setEthicsApprovalNotInPlaceErrorMessage(`${evidenceErrorMessage.ethicsApprovalNotInPlace_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IEvidence = {};
        updatedState = {
            ...evidenceData,
            ethicsApprovalNotInPlace: e.target.value
        }
        setEvidenceData(updatedState);
        saveEvidenceDataInStore(updatedState);
        resetError(e);
    }

    const resetError = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== "") {
            setEthicsApprovalNotInPlaceErrorMessage("");
        }
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom">
                                    Ethics approval</span>
                                    Tell us why your ethics approval is not in place
                                </h1>
                                {EthicsApprovalNotInPlaceErrorMessage !== "" && <ErrorDetails errorDescription={EthicsApprovalNotInPlaceErrorMessage} errorHref="#ethicsApprovalNotInPlace"></ErrorDetails>}
                                <InsetText>
                                    <p>
                                        You told us that your ethics approval is not in place. Now tell us why
                                    </p>
                                </InsetText>
                                <Textarea
                                    hint="Provide information the below text box"
                                    error={EthicsApprovalNotInPlaceErrorMessage}
                                    name="Ethics Approval"
                                    id="ethicsApprovalNotInPlace"
                                    onChange={handleChangeTextarea}
                                    defaultValue={evidenceData.ethicsApprovalNotInPlace ? evidenceData.ethicsApprovalNotInPlace : ""}
                                    maxLength={maxLength.ethicsApprovalNotInPlaceText}
                                    rows={5}
                                />
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>
                        }
                    </div>
                </main>
            </div>
        </>)
}

export default EthicsApprovalNotInPlace;