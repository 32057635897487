import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Radios, Hint } from "nhsuk-react-components";
import { controllerSection, dsaContent, evidenceErrorMessage, fileTypeSize, routeName, sectionOptions, taskListSectionStatus } from "../../config/page.config";
import ControllerHeader from '../../Components/Shared/ControllerHeader'
import ErrorDetails from '../../Components/Shared/ErrorSummary';
import { fetchInterceptor, getCookie, submitDSASectionStatus, submitSpinner } from '../../Helper/Utility';
import { useAppDispatch, useAppSelector } from '../../state/app/hooks';
import { AppDispatch } from '../../state/app/store';
import Loader from "../../Components/Loader/Loader";
import GoBack from "../../Components/Shared/GoBack";
import { IApplication, IDeleteDocument, IEvidenceList, IParticipant } from "../../state/models/app.interface";
import { DeleteDocument_URL } from "../../config/api-endpoints.config";
import { getEvidenceList, setEvidenceListData } from "../../state/slice/EvidenceList/EvidenceList.slice";
import { getDSADetails } from "../../state/slice/DSA/DSA.slice";
import { getParticipantDetails, setParticipantDetailsData } from "../../state/slice/Participant/Participant.slice";
import { getDeleteDocumentData, setDeleteDocumentData } from "../../state/slice/shared/DeleteDocument/DeleteDocument.slice";

const DeleteDocument: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const dsaApplicationData: IApplication = useAppSelector(getDSADetails) || {};
    const [loading, setLoading] = useState(false);
    const [deleteValue, setDeleteValue] = useState('');
    const [deletedocumentErrorMessage, setDeletedocumentErrorMessage] = useState("");
    const [evidenceListDetails, setEevidenceListDetails] = useState(useAppSelector(getEvidenceList) || {});
    const [participantDetails, setParticipantDetails] = useState(useAppSelector(getParticipantDetails) || {});
    const { fileIndex, approvalEvidenceID, fileId } = useAppSelector(getDeleteDocumentData);
    

    const saveEvidenceDataInStore = (stateData?: IEvidenceList) => {
        dispatch(setEvidenceListData({ ...evidenceListDetails, ...stateData }));
    }
    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantDetails, ...stateData }));
    }
    const saveDeleteDocumentInStore = (stateData: IDeleteDocument) => {
        dispatch(setDeleteDocumentData(stateData));
    };
    /*Reset store section*/
    const resetEvidence = (isFileDeleted: boolean) => {
        // if (participantDetails.isFromParticipant) {
        //     let recentlyAddedconsentEvidenceId = participantDetails?.recentlyAddedConsentId;
        //     let recentlyAddedNHsEvidenceId = participantDetails?.recentlyAddedNHSHealthId;
        //     if (recentlyAddedconsentEvidenceId === participantDetails?.recentlyAddedConsentId) {
        //         recentlyAddedconsentEvidenceId = '';
        //     } else if (recentlyAddedNHsEvidenceId === participantDetails?.recentlyAddedNHSHealthId) {
        //         recentlyAddedNHsEvidenceId = '';
        //     }
        //     let participant: IParticipant = {};
        //     participant = {
        //         ...participantDetails,
        //         recentlyAddedConsentId: recentlyAddedconsentEvidenceId,
        //         recentlyAddedNHSHealthId: recentlyAddedNHsEvidenceId,
        //         deleteEvidenceId: ''
        //     }
        //     setParticipantDetails(participant)
        //     saveDataInStore(participant)
        // } else {
        //     let updatedState: IEvidenceList = {}
        //     updatedState = {
        //         selectedType: '',
        //         fileComment: '',
        //         aboutEvidenceSectionStatus: '',
        //         deleteEvidenceId: ''
        //     }
        //     saveEvidenceDataInStore(updatedState);
        //     setEevidenceListDetails(updatedState)
        // }
        // navigate(routeName.uploadedFiles)
        const documentData: IDeleteDocument = {
            fileIndex,
            isDeleted: isFileDeleted
        };
        saveDeleteDocumentInStore(documentData);
        setLoading(false);
        navigate(-1);
    }

    const handleDelete = (isFileDeleted: boolean) => {
        setLoading(true);
        // let documentId = ''
        // let sectionType: number = 0;
        // if (participantDetails.isFromParticipant && participantDetails.deleteEvidenceId != undefined) {
        //     documentId = participantDetails.deleteEvidenceId;
        //     sectionType = participantDetails.sectionType!;
        // }
        // else if (evidenceListDetails.deleteEvidenceId != undefined) {
        //     documentId = evidenceListDetails.deleteEvidenceId;
            // sectionType = evidenceListDetails.sectionType!;
        // }
           

        // const parameters: string = JSON.stringify({
        //     "UserAuthTokenId": authenticationTokenCookie,
        //     "SectionId": sectionOptions.ListOfUploadedDocuments,
        //     "Id": documentId,
        // });
        // const requestOptions: Object = {
        //     method: 'POST',
        //     headers: { 'Content-Type': fileTypeSize.fileTypeJson },
        //     body: parameters
        // };
        // fetchInterceptor(DeleteDSAChildRecord_URL, requestOptions)
        //     .then(() => {
        //         resetEvidence();
        //         updateSectionStatus(sectionType);
        //     })
        //     .catch(() => navigate(routeName.serviceError));
        if(fileId && isFileDeleted) {
            const authenticationTokenCookie: string = getCookie('authenticationToken')!;
            const parameters: string = JSON.stringify({
                "UserAuthTokenId": authenticationTokenCookie,
                "FileId": fileId,
                "ApprovalEvidenceID": approvalEvidenceID,          
            });
            const requestOptions: Object = {
                method: 'POST',
                headers: { 'Content-Type': fileTypeSize.fileTypeJson },
                body: parameters
            };
        
            fetchInterceptor(DeleteDocument_URL, requestOptions)
                .then(() => {
                    resetEvidence(isFileDeleted);
                    // updateSectionStatus(sectionType);
                })
                .catch(() => navigate(routeName.serviceError));
        } else {
            resetEvidence(isFileDeleted);
        }
    }

    const updateSectionStatus = async (sectionType: number) => {
        submitDSASectionStatus(dsaApplicationData.id!, sectionType!.toString(), taskListSectionStatus.inProgress);
    }

    const navigateHandleDelete = () => {
        handleDelete(deleteValue === dsaContent.yes!);
    }

    const handleDeleteContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isValid: boolean = validateDeleteFields();
        if (isValid) {
            submitSpinner(e, navigateHandleDelete)
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const validateDeleteFields = () => {
        let isValid: boolean = true;
        if (deleteValue === '') {
            setDeletedocumentErrorMessage(`${evidenceErrorMessage.deleteDocument_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const handleChangeDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteValue(e.target.value);
        setDeletedocumentErrorMessage("");
    }

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} /> :
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <h1>
                                    Are you sure you want to delete this document?
                                </h1>
                                {deletedocumentErrorMessage !== "" && <ErrorDetails errorDescription={deletedocumentErrorMessage} errorHref="#deletedocument"></ErrorDetails>}
                                <Radios
                                    name="deletedocument"
                                    id="deletedocument"
                                    error={deletedocumentErrorMessage}
                                >
                                    <Radios.Radio value={dsaContent.yes} onChange={handleChangeDelete} >
                                        {dsaContent.yes}
                                        <Hint>If you select yes, your uploaded document will be removed.</Hint>
                                    </Radios.Radio>
                                    <Radios.Radio value={dsaContent.no} onChange={handleChangeDelete}>{dsaContent.no}</Radios.Radio>
                                </Radios>
                                <div className="nhsuk-u-padding-bottom-8"></div>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleDeleteContinue}>{dsaContent.btnTextContinue}</Button>
                            </div>
                        }
                    </div>
                </main>
            </div>
        </>
    )
}

export default DeleteDocument;
