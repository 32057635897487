/* eslint-disable array-callback-return */
import { Button, DateInput, ErrorSummary, Fieldset, Input, InsetText, Label, Radios, Textarea } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import { GetDSPTLatestStatusList_URL, UpdateScreenDataProcessor_URL } from "../../../../config/api-endpoints.config";
import { dsaContent, dsaErrorMessage, dsaProcessorErrorMessage, fileTypeSize, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { calculateExampleDate, fetchInterceptor, getCookie, submitSpinner, validateDateValue } from "../../../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IProcessor, IApplication, IDsptLatestStatus, IDsptLatestStatuses, IProcessorList, IApiResDSPTStstus } from "../../../../state/models/app.interface";
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { getProcessorDetails, setProcessorDetails } from '../../../../state/slice/Processor/Processor.slice';
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import GoBack from "../../../../Components/Shared/GoBack";
import { getProcessorListDetails, setProcessorListDetailsData } from "../../../../state/slice/ProcessorList/ProcessorList.slice";
/*
  Summary: Using this page, user can add the details related to DSPT for Processors.
*/
const ProcessorDSPTDetails: React.FC = () => {
    /*Variable Declarations*/
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const processorListDetailsData: IProcessorList = useAppSelector(getProcessorListDetails);
    const initialprocessorListState: IProcessorList = processorListDetailsData || {};
    const [processorListData] = useState(initialprocessorListState);
    const saveprocessorListDataInStore = (stateData?: IProcessorList) => {
        dispatch(setProcessorListDetailsData({ ...processorListData, ...stateData }));
    }

    const dsaProcessorDetailsData: IProcessor = useAppSelector(getProcessorDetails);
    const initialState: IProcessor = dsaProcessorDetailsData || {};
    const [processorData, setProcessorData] = useState(initialState);
    /*�To�dispatch�the�setProcessorDetails�Redux�action�*/
    const saveDataInStore = (stateData?: IProcessor) => {
        dispatch(setProcessorDetails({ ...processorData, ...stateData }));
    }
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const initialStateOfDsptLatestStatuses: IDsptLatestStatuses = {};
    const [dsptLatestStatusesData, setDsptLatestStatusesData] = useState(initialStateOfDsptLatestStatuses);
    const [loading, setLoading] = useState(true);
    const [dsptOdsCodeError, setDsptOdsCodeError] = useState("");
    const [dsptStatusError, setDsptStatusError] = useState("");
    const [datePublishedError, setDatePublishedError] = useState("");
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const [diDayError, setDiDayError] = useState(false);
    const [diMonthError, setDiMonthError] = useState(false);
    const [diYearError, setDiYearError] = useState(false);
    let isDirtyprocessor = processorData.isDirtyProcessor || false;
    /*Variable Declarations*/
    /* set the state value on change of Input */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IProcessor = {};
        let updatedSAList= { ...processorData.saList, [e.target.name]: e.target.value };
        updatedState = { ...processorData, 'saList': updatedSAList, isDirtyProcessor: true }
        setProcessorData(updatedState);
        saveDataInStore(updatedState);
        resetError(e);
    }
    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedState: IProcessor = {};
        let updatedSAList = { ...processorData.saList, [e.target.name]: e.target.value };
        updatedState = { ...processorData, 'saList': updatedSAList, isDirtyProcessor: true }
        setProcessorData(updatedState);
        saveDataInStore(updatedState);
        resetError(e);
    };
    /* Reset Error */
    const resetError = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => {
        const valueLength = e.target.value.length;
        if (valueLength >= 1) {
            if (e.target.name === dsaContent.dsptOdsCodeField) {
                setDsptOdsCodeError("");
            }
            if (e.target.name === dsaContent.dateInputDayField) {
                setDatePublishedError("");
                setDiDayError(false);
            }
            if (e.target.name === dsaContent.dateInputMonthField) {
                setDatePublishedError("");
                setDiMonthError(false);
            }
            if (e.target.name === dsaContent.dateInputYearField) {
                setDatePublishedError("");
                setDiYearError(false);
            }
            if (e.target.name === dsaContent.dsptStatusField) {
                setDsptStatusError("");
            }
        }
    }
    /*Calling submitDsptDetails if fields validation successful*/
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (validateFields()) {
            submitSpinner(e, submitDsptDetails);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    /*Validate Fields */
    const validateFields = () => {
        let isValid: boolean = true;
        if (!(processorData.saList ? processorData.saList.DSPT_ODS_Code : null)) {
            setDsptOdsCodeError(`${dsaErrorMessage.dsptOdsCode_ErrorMessage}`);
            isValid = false;
        }
        if (!(processorData.saList ? processorData.saList.DSPT_Latest_Status : null)) {
            setDsptStatusError(`${dsaErrorMessage.dsptStatus_ErrorMessage}`);
            isValid = false;
        }
        let dateValueError = validateDateValue(Number(processorData.saList ? processorData.saList.DSPTDay : "0"), Number(processorData.saList ? processorData.saList.DSPTMonth : "0"), Number(processorData.saList ? processorData.saList.DSPTYear : "0"));
        if (dateValueError[0] !== "") {
            setDatePublishedError(dateValueError[0]);
            let fields = dateValueError[1].split(",");
            for (let i = 0; i < fields.length; i++) {
                if (fields[i] === dsaContent.dateInputDayField) {
                    setDiDayError(true);
                }
                if (fields[i] === dsaContent.dateInputMonthField) {
                    setDiMonthError(true);
                }
                if (fields[i] === dsaContent.dateInputYearField) {
                    setDiYearError(true);
                }
            }
            isValid = false;
        }
        return isValid;
    }
    /*Validate Fields */

    /* API call to submit the processor data based on new processor/existing processor */
    const submitDsptDetails = () => {
        let updatedStateofProcessor: IProcessor = {};
        let updatedSAList = { ...processorData.saList, DSPT_Date_Published: processorData.saList ? processorData.saList.DSPTDay + "/" + processorData.saList.DSPTMonth + "/" + processorData.saList.DSPTYear : "" };
        updatedStateofProcessor = { ...processorData, 'saList': updatedSAList }
        setProcessorData(updatedStateofProcessor);
        saveDataInStore(updatedStateofProcessor);
        if (processorListData.processorDetailsPage && !processorData?.isNewProcessor) {
            if (isDirtyprocessor) {
                const parameters: string = JSON.stringify({
                    "UserAuthTokenId": authenticationTokenCookie,
                    "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
                    "DataprocessorId": processorData.processorId,
                    "SecurityAssurance": updatedSAList,
                    "IsSADirty": true,
                    "SectionStatus": dsaApplicationData.aboutProcessorSectionStatus
                });
                const requestOptions: Object = {
                    method: 'POST',
                    headers: { 'Content-Type': fileTypeSize.fileTypeJson },
                    body: parameters
                };
                fetchInterceptor(UpdateScreenDataProcessor_URL, requestOptions)
                    .then(response => {
                            return response.json()
                    })
                    .then(() => {
                        let updatedState: IProcessorList = {
                            ...processorListData, processorDetailsPage: processorListData.processorDetailsPage
                        }
                        let sectionStatus: IApplication = {
                            ...dsaApplicationData, aboutProcessorSectionStatus: taskListSectionStatus.inProgress
                        }
                        setDsaApplicationData(sectionStatus);
                        saveApplicationDataInStore(sectionStatus);
                        saveprocessorListDataInStore(updatedState);
                        navigate(routeName.processorDetails);
                    })
                    .catch(() => navigate(routeName.serviceError));
            }
            else { navigate(routeName.processorDetails); }
        }
        else {
            navigate(routeName.dataProtectionRegistrationProcessor);
        }
    }
    /* API call to submit the processor data based on new processor/existing processor */

    /*API call to get and set Dspt status  */
    const getDsptStatus = () => {
        const parameters: string = JSON.stringify(authenticationTokenCookie);
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetDSPTLatestStatusList_URL, requestOptions)
            .then(response => {
                    return response.json()
            })
            .then(data => {
                setDsptStatus(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setDsptStatus = (dsptStatusResponse: IApiResDSPTStstus[]) => {
        let updatedState: IDsptLatestStatuses = {};
        let dsptLatestStatus: IDsptLatestStatus = {};
        let dsptLatestStateList: IDsptLatestStatus[] = [];
        dsptStatusResponse.map((status: IApiResDSPTStstus) => {
            dsptLatestStatus = {
                name: status.Name,
                value: status.Value
            }
            dsptLatestStateList.push(dsptLatestStatus);
        });
        updatedState = {
            dsptLatestStatus: dsptLatestStateList
        }
        setDsptLatestStatusesData(updatedState);
        setLoading(false);
    }
    /*API call to get and set Dspt status  */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaProcessorErrorMessage.selectProcessorSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#dsptOdsCode">
                            {dsptOdsCodeError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#dsptStatus">
                            {dsptStatusError}
                        </ErrorSummary.Item>
                        <ErrorSummary.Item href="#dsptDatePublished">
                            {datePublishedError}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )
    useEffect(() => {
        getDsptStatus();
    }, [])

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <Loader loadingText={dsaContent.txtLoading} />
                            :
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack></GoBack>
                                <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                    About the Processor
                                </span>
                                <h1>Data Security and Protection Toolkit details</h1>
                                {dsptOdsCodeError !== "" || dsptStatusError !== "" || datePublishedError ? errorSummary : null}
                                <InsetText>
                                    <Label size="s">Your selected Data Security and Protection Toolkit (DSPT) must match the DSPT information we have on record for your organisation. If these do not match it may lead to a delay in the acceptance of your application.</Label>
                                    <Label size="s">Each department within a university can have a different security policy depending on the type of information they access. Universities can have multiple DSPTs across their organisation, usually split between schools and departments. If the organisation is a university and you are not sure which DSPT to add (and therefore do not know the Organisation's Data Service code), contact your Data Protection Officer (DPO) or Information Governance (IG) team for help.</Label>
                                    <Label size="s">Find the DSPT status and supporting information for the organisation on the <a href="https://www.dsptoolkit.nhs.uk/OrganisationSearch" target="_blank">DSPT organisation search</a> (opens in a new window).</Label>
                                    <Label size="s">If you need further help, email <a href={"mailto:exeter.helpdesk@nhs.net;data.applications@nhsdigital.nhs.uk?subject=DSPT - DARS Online Application Issue " + dsaApplicationData?.referenceNumber}>exeter.helpdesk@nhs.net.</a> The email will open with your NIC number and 'DSPT - DARS Online Application Issue' in the subject field.</Label>
                                    <Label size="s">Selecting the exeter.helpdesk email address will drop the <a href="mailto:data.applications@nhsdigital.nhs.uk">data.applications@nhsdigital.nhs.uk</a> email address in the 'To' field of your email. Keeping the data applications team informed in this way will help to enable a smooth application process.</Label>
                                </InsetText>
                                <Input id="dsptOdsCode"
                                    name={dsaContent.dsptOdsCodeField}
                                    label="Organisation's Data Service (ODS) code"
                                    value={processorData.saList ? processorData.saList.DSPT_ODS_Code : ""}
                                    error={dsptOdsCodeError}
                                    onChange={handleChange}
                                />
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <Fieldset>
                                    <Fieldset.Legend>Status</Fieldset.Legend>
                                    <Radios
                                        name="DSPT_Latest_Status"
                                        id="dsptStatus"
                                        hint=""
                                        error={dsptStatusError}
                                    >
                                        {dsptLatestStatusesData.dsptLatestStatus?.map((status: IDsptLatestStatus, index: number = 0) => (
                                            <Radios.Radio
                                                name={dsaContent.dsptStatusField}
                                                key={index + 1}
                                                value={status.value}
                                                onChange={handleChange}
                                                checked={String(processorData.saList ? processorData.saList.DSPT_Latest_Status : "") === String(status.value)}
                                            >
                                                {status.name}
                                            </Radios.Radio>
                                        ))
                                        }
                                    </Radios>
                                </Fieldset>
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <DateInput id="dsptDatePublished" hint={`For example, ${calculateExampleDate(dsaContent.exampleYearForPast)}`} label="Date published" name="DSPT_Date_Published" error={datePublishedError}>
                                    <DateInput.Day id={dsaContent.dateInputDayField} name={dsaContent.dateInputDayField} onChange={handleChange} value={processorData.saList ? processorData.saList.DSPTDay : ""} error={diDayError} />
                                    <DateInput.Month id={dsaContent.dateInputMonthField} name={dsaContent.dateInputMonthField} onChange={handleChange} value={processorData.saList ? processorData.saList.DSPTMonth : ""} error={diMonthError} />
                                    <DateInput.Year id={dsaContent.dateInputYearField} name={dsaContent.dateInputYearField} onChange={handleChange} value={processorData.saList ? processorData.saList.DSPTYear : ""} error={diYearError} />
                                </DateInput>
                                <div className="nhsuk-u-padding-bottom-4"></div>
                                <Textarea
                                    label="Additional DSPT submission (optional)"
                                    hint="If there is more than one school or department within the university that will be involved in the agreement, you must add them to the application. Provide details in the below text box."
                                    id="additional-detail"
                                    name="DSPT_Comments"
                                    rows={5}
                                    onChange={handleChangeTextarea}
                                    defaultValue={processorData.saList ? processorData.saList.DSPT_Comments : ""}
                                />
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                <Button name="saveAndContinue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>
                        }
                    </div>
                </main>
            </div>
        </>
    );
}
export default ProcessorDSPTDetails;