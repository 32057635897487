import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetDARSLatestNews_URL } from "../../config/api-endpoints.config";
import { fetchInterceptor, getDateFullMonth, updatePageTitle } from "../../Helper/Utility";
import { useAppDispatch, useAppSelector } from "../../state/app/hooks";
import { AppDispatch } from "../../state/app/store";
import { IApiResNewsDetails, IApiResNewsList, INews, INewsList } from "../../state/models/app.interface";
import { getNewsDetails, setNewsDetailsData } from "../../state/slice/News/News.slice";
import { fileTypeSize, latestNewsContent, pageTitles, routeName } from "../../config/page.config";

const LatestNews: React.FC = () => {
    let navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const newsDetailsData: INews = useAppSelector(getNewsDetails);
    const initialState: INews = newsDetailsData || {};

    const saveNewsInStore = (stateData?: INews) => {
        dispatch(setNewsDetailsData({ ...newsData, ...stateData }));
    }

    const [newsData, setNewsData] = useState(initialState);
    const [newsPerPage] = useState(3);
    const [currentPage, setCurrentPage] = useState(newsDetailsData.selectedNews || 1);
    const [firstPointer, SetFirstPointer] = useState(newsDetailsData.firstPointer || 1);
    const [lastPointer, SetLastPointer] = useState(newsDetailsData.lastPointer || 1);
    const [hasMoreRecords, setHasMoreRecords] = useState(newsDetailsData.hasMoreRecords || false);

    const getLatestNewsDetails = (pageNumber = 1, nextPreviousClicked?: string, currentPage?: number) => {
        if (nextPreviousClicked || newsData!.newsList!.length < 2) {
             setLoading(true);
            const parameters: string = JSON.stringify({
                "PageSize": 3,
                "PageNumber": pageNumber,
                "NumberOfPages": 3
            });
            const requestOptions: Object = {
                method: 'POST',
                headers: { 'Content-Type': fileTypeSize.fileTypeJson, 'Accept': fileTypeSize.fileTypeJson },
                body: parameters
            };
            const avoidOauthToken = true;   // Fetch interceptor not required for public calls
            
            fetchInterceptor(GetDARSLatestNews_URL, requestOptions, avoidOauthToken)
                .then(response => {
                        return response.json()
                })
                .then(data => {
                    updateLatestNews(data, pageNumber, currentPage);
                })
                .catch(() => navigate(routeName.serviceError));
        }
    }

    const updateLatestNews = (response: IApiResNewsList, pageNumber?: number, currentPage?: number) => {
        if (response !== null) {
            let updatedState: INews = {};
            let newsDetails: INewsList = {};
            let newsDetailsList: INewsList[] = [];

            response.ContentList.map((news: IApiResNewsDetails) => {
                newsDetails = {
                    id: news.Id,
                    date: getDateFullMonth(news.PublishDate),
                    title: news.Title,
                    summary: news.Summary,
                    content: news.Content,
                    contentId: news.ContentId
                }
                newsDetailsList.push(newsDetails);
                setHasMoreRecords(response.HasMoreRecords);
            })

            SetFirstPointer(pageNumber!);
            SetLastPointer((pageNumber! + Math.ceil(newsDetailsList.length / 3)) - 1);

            updatedState = {
                newsList: newsDetailsList,
                hasMoreRecords: response.HasMoreRecords,
                firstPointer: pageNumber,
                lastPointer: pageNumber! + Math.ceil(newsDetailsList.length / 3) - 1,
                selectedNews: currentPage
            }

            setNewsData(updatedState);
            saveNewsInStore(updatedState);
        }
        setLoading(false);
    }


    const indexOfLastNews = (currentPage - (firstPointer - 1)) * newsPerPage;
    const indexOfFirstNews = indexOfLastNews - newsPerPage;
    const currentNews = newsData.newsList?.slice(indexOfFirstNews, indexOfLastNews);


    let pageNumbers = [];
    for (let i = firstPointer; i <= lastPointer; i++) {
        pageNumbers.push(i);
    };

    const paginate = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, pageNum: number) => {
        e.preventDefault();
        setCurrentPage(pageNum);
        let updatedState: INews = {};
        updatedState = {
            ...newsData,
            selectedNews: pageNum
        }
        setNewsData(updatedState);
        saveNewsInStore(updatedState);
    }

    const onNext = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        if ((currentPage + 1) > lastPointer) {
            getLatestNewsDetails(firstPointer + 1, latestNewsContent.Next, currentPage + 1);
            setCurrentPage(currentPage + 1);
        }
        else {
            paginate(e, currentPage + 1);
        }
      
    }

    const onPrevious = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        if ((currentPage - 1) < firstPointer) {
            getLatestNewsDetails(firstPointer - 1, latestNewsContent.Previous, currentPage - 1);
            setCurrentPage(currentPage - 1);
        }
        else {
            paginate(e, currentPage - 1);
        }
    }

    const openNewsDetails = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, contentId: string) => {
        e.preventDefault();
        navigate(routeName.latestNewsDetails + `?id=` + contentId);
    }

    useEffect(() => {
        getLatestNewsDetails();
        updatePageTitle(pageTitles.latestnews);
    }, []);


    return (
        <>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            <div className="nhsuk-grid-column-full">
                                <div className="nhsuk-loader__container">                                   
                                    <h1 className="nhsuk-heading-l">Loading results</h1>
                                </div>
                                <div className="nhsuk-loader__container">
                                    <div className="nhsuk-loader">
                                    </div>
                                </div>
                            </div>
                            : null}
                        <div className="nhsuk-grid-column-two-thirds">
                            <div className="nhsuk-u-padding-bottom-4"></div>
                            {!loading ?
                                <h1>Updates</h1>
                                : null}
                            {!loading ?
                                currentNews && currentNews?.map((newsDetailsData: INewsList, index: number) => (
                                    <div key={index}>
                                        <h3><a href="#" onClick={(e) => openNewsDetails(e,newsDetailsData.contentId!)}>{newsDetailsData?.title}</a>
                                            <br />
                                            <span className="nhsuk-pagination-date">
                                                {newsDetailsData?.date}
                                            </span>
                                        </h3>
                                        {(newsDetailsData.summary)?.length! > 100 ?
                                            <>
                                                <p>{newsDetailsData.summary} [...]</p>
                                            </>
                                            : <p>{newsDetailsData.summary} </p>}
                                        <p><a href="#" onClick={(e) => openNewsDetails(e,newsDetailsData.contentId!)}>Read more</a></p>
                                        <hr />

                                    </div>
                                )
                                )
                                : null}
                            {!loading ?
                                <nav className="nhsuk-pagination pag-float-right" role="navigation" aria-label="results">
                                    {currentPage !== 1 ?
                                        <div className="nhsuk-pagination__prev">
                                            <a className="nhsuk-link nhsuk-pagination__link" href="#" rel="prev" onClick={onPrevious}>
                                                <svg className="nhsuk-pagination__icon nhsuk-pagination__icon--prev" xmlns="http://www.w3.org/2000/svg" height="13" width="15" aria-hidden="true" focusable="false" viewBox="0 0 15 13">
                                                    <path d="m6.5938-0.0078125-6.7266 6.7266 6.7441 6.4062 1.377-1.449-4.1856-3.9768h12.896v-2h-12.984l4.2931-4.293-1.414-1.414z"></path>
                                                </svg>
                                                <span className="nhsuk-pagination__link-title">Previous</span></a>
                                        </div> : null}
                                    <ul className="nhsuk-pagination__list">
                                        {pageNumbers.map(pageNum => (
                                            <li key={pageNum} className={"nhsuk-pagination__item " + (pageNum === currentPage ? "nhsuk-pagination__item--current" : "")} aria-current="page">
                                                <a className="nhsuk-link nhsuk-pagination__link" href="#" onClick={(e) => paginate(e, pageNum)}>
                                                    {pageNum}
                                                </a>
                                            </li>
                                        )
                                        )
                                        }
                                    </ul>
                                    {(hasMoreRecords === true || lastPointer > currentPage) ?
                                        <div className="nhsuk-pagination__next">
                                            <a className="nhsuk-link nhsuk-pagination__link" href="#" rel="next" onClick={onNext}>        <span className="nhsuk-pagination__link-title">Next</span>      <svg className="nhsuk-pagination__icon nhsuk-pagination__icon--next" xmlns="http://www.w3.org/2000/svg" height="13" width="15" aria-hidden="true" focusable="false" viewBox="0 0 15 13">
                                                <path d="m8.107-0.0078125-1.4136 1.414 4.2926 4.293h-12.986v2h12.896l-4.1855 3.9766 1.377 1.4492 6.7441-6.4062-6.7246-6.7266z"></path>
                                            </svg></a>
                                        </div> : null
                                    }
                                </nav> : null
                            }
                        </div>
                    </div>
                </main >
            </div >
        </>
    )
}
export default LatestNews;