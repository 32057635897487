import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { IPurpose } from '../../models/app.interface';

const initialState: IPurpose = {
    isPurposeUpdated: false,
    applicationForCommercialPurposes: "",
    applicationForCommercialPurposesExplanation: "",
    doesSublicensingApply: "",
    duration: "",
    aboutCommercialSublicensingSectionStatus: "",
    commercialSublicensingCheckYourAnswerPage: "",
    aboutExpectedOutputsAndBenefitsSectionStatus: "",
    specificOutputsExpected: "",
    expectedMeasurableBenefits: "",
    expectedCheckYourAnswerPage: "",
    objectiveForProcessing: "",
    processingActivities: ""
}

export const PurposeSlice = createSlice({
    name: 'Purpose',
    initialState,
    reducers: {
        setPurposeDetailsData: (state: IPurpose, action: PayloadAction<IPurpose>) => {
            state = Object.assign(state, action.payload);
        },
        resetPurposeDetailsData: (state: IPurpose, action: PayloadAction<IPurpose>) => {
            state = { ...initialState, ...action.payload };
        },
    }
});

export const { setPurposeDetailsData, resetPurposeDetailsData } = PurposeSlice.actions;
export const getPurposeDetails = (state: RootState) => state.purposeDetails;
export default PurposeSlice.reducer;