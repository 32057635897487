import { Button, Label } from "nhsuk-react-components";
import React, { useEffect } from "react";
import { pageTitles, routeName, signInSignOut } from "../../config/page.config";
import { useAppDispatch, useAppSelector } from "../../state/app/hooks";
import { IUserDetails } from "../../state/models/app.interface";
import { getDSFCContractDetails } from "../../state/slice/DSFC/DSFCContractDetails.slice";
import { useNavigate } from "react-router-dom";
import { setUserDetailsData } from "../../state/slice/Header/Header.slice";
import { AppDispatch } from "../../state/app/store";
import { checkservicemaintenance, updatePageTitle } from "../../Helper/Utility";

const Home: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const authenticationToken = urlParams.get("at");
    const timeStamp = urlParams.get("ts");
    const hasSecurityRole = urlParams.get("hsr");
    const { redirectionURL } = useAppSelector(getDSFCContractDetails);

    const saveDataInStore = (stateData: IUserDetails) => {
        dispatch(setUserDetailsData(stateData));
    }

    useEffect(() => {
        if (authenticationToken && timeStamp) {
            document.cookie = `authenticationToken=${authenticationToken}; expires=${new Date(Date.now() + 10000000).toUTCString()}; path=/`;
            const updatedState = {
                hasSecurityRole: !(hasSecurityRole?.toLowerCase() === "false"),
                isAuthenticated: true
            };
            saveDataInStore(updatedState);
            navigate(redirectionURL && redirectionURL !== "" ? redirectionURL : routeName.applyDsa);
        }
    }, []);

    useEffect(() => {
        checkservicemaintenance();
        updatePageTitle(pageTitles.home);
    }, []);

    return (
        <div className="nhsuk-width-container">
            <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                <div className="nhsuk-grid-row">
                    <div className="nhsuk-grid-column-two-thirds">
                        <h1>Sign in to DARS or register for an account</h1>
                        <Label size="m" className="nhsuk-label-font-normal-500">This page provides guidance on how to access the Data Access Request Service (DARS).</Label>
                        <Label size="s">
                            If you have not yet registered for an account with DARS Online, you will need to register before you can apply for a Data Sharing Agreement (DSA).
                        </Label>
                        <Label size="s">
                            Not all requests for data will progress to the application stage. It may be that your requirement can be satisfied through another NHS England service, existing published data, or that you don’t have the legal basis to access the data.
                        </Label>
                        <Label size="s">
                            If you are a first-time user of DARS Online, email NHS England at <a href='mailto:enquiries@nhsdigital.nhs.uk'>enquiries@nhsdigital.nhs.uk </a> before registering for an account or using this service.
                        </Label>
                        <Label size="s" className="nhsuk-space-bottom-double">
                            <a href={process.env.REACT_APP_DARS_SSO_REGISTER_URL}>Register</a> for an account or sign in to access DARS Online
                        </Label>
                        <Button href={process.env.REACT_APP_DARS_SSO_AUTHENTICATE_URL}>{signInSignOut.signInText}</Button>
                    </div>
                </div>
            </main>
        </div>
    );
};
export default Home;