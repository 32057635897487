import React, { useEffect, useState } from "react";
import { Button, ErrorSummary, SummaryList } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";
import { IParticipant, IApplication, IParticipantLegalBasis, IParticipantContributor, IEvidence, IAPiResParticipantDetails, IApiResParticipantContributor, IApiResParticipantLegalBasis, IApiResEvidence, IApiResEvidenceList, EvidenceTypeAttributes, IEvidenceList, IFileUpload } from "../../../../state/models/app.interface";
import { AppDispatch } from '../../../../state/app/store';
import { useAppDispatch, useAppSelector } from '../../../../state/app/hooks';
import { getParticipantDetails, setParticipantDetailsData } from "../../../../state/slice/Participant/Participant.slice";
import { participantErrorMessage, dsaContent, participantTypeOptions, routeName, fileTypeSize, initialStateOfFileUpload, evidenceTypeOptions, sectionOptionsDesc, sectionOptions } from "../../../../config/page.config";
import GoBack from "../../../../Components/Shared/GoBack";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { download, fetchInterceptor, filterEvidenceType, getB64toBlobData, getCookie, getKeyByValue, getNameForOption, getTaskListType, submitSpinner } from "../../../../Helper/Utility";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { GetCohortData_URL, GetDSAFileById_URL, GetEvidenceList_URL } from "../../../../config/api-endpoints.config";
import Loader from "../../../../Components/Loader/Loader";
import ShowAll from "../../../../Components/ShowAll/ShowAll";
import { getEvidenceList, setEvidenceListData } from "../../../../state/slice/EvidenceList/EvidenceList.slice";

const ParticipantSummary: React.FC = () => {
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const participantDetailsData: IParticipant = useAppSelector(getParticipantDetails);
    const initialState: IParticipant = participantDetailsData || {};
    const [participantData, setParticipantData] = useState(initialState);
    const [loading, setLoading] = useState(true);
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);
    const [showdownload, setshowdownload] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isExpender, setIsExpender] = useState(false);
    /* To dispatch the setParticipantDetailsData Redux action */
    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantData, ...stateData }));
    }
    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{participantErrorMessage.legalBasisMandatory_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List>
                        <ErrorSummary.Item href="#selectLegalBasis">{summaryLinkErrorMessage}
                        </ErrorSummary.Item>
                    </ErrorSummary.List>
                </ErrorSummary.Body>
            </ErrorSummary>
        </>
    )

    const handleCLick = (pageNavigation: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        let updatedState: IParticipant = {};
        updatedState = {
            ...participantData,
            participantcheckYourAnswerPage: dsaContent.checkYourAnswersPageText,
            fileUploadConsent: participantData.fileUploadConsent
        }
        setParticipantData(updatedState);
        saveDataInStore(updatedState);
        navigate(pageNavigation)
    }

    const handlerelevantParticipantData = () => { navigate(routeName.relevantParticipantData); }

    const handleClickContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        submitSpinner(e, handlerelevantParticipantData);

    }
    const checkParticipantLBType = (value: string) => {
        return participantData.dataSetLegalBasisList!.find((element) => {
            return (value === element.value && element.checked)!;
        });
    }

    const filterParticipantLB = () => {
        let multiAEArray: number[] = [];
        Object.values(evidenceTypeOptions).forEach((option: EvidenceTypeAttributes, index) => {
            if (option?.legalBasis?.filter((value) => value !== undefined && checkParticipantLBType(value)).length! > 0) {
                multiAEArray.push(option.value);
            }
        })
        return multiAEArray;
    }
    const getCohortDetailsData = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "ApprovalEvidenceType": filterParticipantLB(),
            "SectionId": sectionOptions.Participants
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetCohortData_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setDetails(data);
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const setDetails = (data: IAPiResParticipantDetails) => {
        let participant: IParticipant = {};
        let participantFileUploads: IFileUpload[] = [];
        let participantlegal: IParticipantLegalBasis = {};
        let legalBasisList: IParticipantLegalBasis[] = [];
        let participantjournal: IParticipantContributor = {};
        let journalsList: IParticipantContributor[] = [];
        let existingAgreement: string[] = [];
        const { Cohort, TypeOfCohort, RefIfUsingAnotherExistingAgreement, OngoingRecruitment, AdditionalLinkageCohortInformation, MaximumSize, StudyId,
            DateOfDeath, NhsNumber, Surname, DateOfBirth, AddressDate, Forename, GpPracticeCode, OtherGivenName, DataAsAtDate, Sex, TelephoneNumber, Postcode,
            MobilePhoneNumber, EmailAddress, DataSetSelectedLegalBasisOtherText, MinimumSizeCohortValidationRate, ManualCohortValidation, HowManyValidationCredits,
            ProductionCommence, DataProductionSubmissionSelected } = data;

        participant.cohort = Cohort
        participant.typeOfCohort = TypeOfCohort
        participant.participantTypes = String(TypeOfCohort)
        participant.refIfUsingAnotherExistingAgreement = RefIfUsingAnotherExistingAgreement
        participant.ongoingRecruitment = OngoingRecruitment
        participant.additionalLinkageCohortInformation = AdditionalLinkageCohortInformation
        participant.maximumSize = MaximumSize
        participant.studyId = StudyId
        participant.dateOfDeath = DateOfDeath
        participant.nhsNumber = NhsNumber
        participant.surname = Surname
        participant.dateOfBirth = DateOfBirth
        participant.addressDate = AddressDate
        participant.forename = Forename
        participant.gpPracticeCode = GpPracticeCode
        participant.otherGivenName = OtherGivenName
        participant.dataAsAtDate = DataAsAtDate
        participant.sex = Sex
        participant.telephoneNumber = TelephoneNumber
        participant.postcode = Postcode
        participant.mobilePhoneNumber = MobilePhoneNumber
        participant.emailAddress = EmailAddress
        participant.dataSetSelectedLegalBasisOtherText = DataSetSelectedLegalBasisOtherText
        participant.minimumSizeCohortValidationRate = MinimumSizeCohortValidationRate
        participant.manualCohortValidation = ManualCohortValidation
        participant.howManyValidationCredits = HowManyValidationCredits
        participant.productionCommence = ProductionCommence
        participant.dataProductionSubmissionSelected = DataProductionSubmissionSelected

        if (data.Journals.length > 0) {
            data.Journals.forEach((dsaApplication: IApiResParticipantContributor) => {
                participantjournal = {
                    id: dsaApplication.Id,
                    firstName: dsaApplication.FirstName,
                    lastName: dsaApplication.LastName,
                    organisation: dsaApplication.Organisation,
                    role: dsaApplication.Role,
                    email: dsaApplication.Email
                }
                journalsList.push(participantjournal)
            })
        }
        participant.participantContributorList = journalsList;

        if (data.DataSetLegalBasisList.length > 0) {
            let orderby = 0;
            data.DataSetLegalBasisList.forEach((dsaApplication: IApiResParticipantLegalBasis) => {
                if (dsaApplication.Checked && orderby === 0)
                    orderby = 1;
                else if (dsaApplication.Checked)
                    orderby = orderby + 1;

                participantlegal = {
                    value: dsaApplication.Value,
                    name: dsaApplication.Name,
                    checked: dsaApplication.Checked,
                    text: dsaApplication.Text,
                    crmId: dsaApplication.crmId,
                    processing: dsaApplication.Processing,
                    dissemination: dsaApplication.Dissemination,
                    otherLegalBasis: dsaApplication.OtherLegalBasis,
                    displayProcessing: dsaApplication.DisplayProcessing,
                    displayDissemination: dsaApplication.DisplayDissemination,
                    productLegalBasis: dsaApplication.ProductLegalBasis,
                    otherComments: dsaApplication.OtherComments,
                    orderBy: dsaApplication.OrderBy,
                    availableOnline: dsaApplication.AvailableOnline,
                    currentlyCheckedInCRM: dsaApplication.CurrentlyCheckedInCRM,
                    notes: dsaApplication.Notes,
                    sortBy: orderby
                }
                legalBasisList.push(participantlegal);

            })
        }
        participant.dataSetLegalBasisList = legalBasisList;
        if (data.ExistingAgreementUploadItems.length > 0) {
            data.ExistingAgreementUploadItems.forEach((agreement: string) => {
                existingAgreement.push(agreement)
            })
            participant.existingAgreementUploadItems = existingAgreement;
        }
        let participantEvidence: IEvidence = {};
        let evidenceList: IEvidence[] = [];
        if (data.EvidenceList != null && data.EvidenceList.length > 0) {
            data.EvidenceList.map((evidence: IApiResEvidenceList) => {
                participantEvidence = {
                    id: evidence.Id,
                    comments: evidence.Comments,
                    referenceNumber: evidence.ReferenceNumber,
                    approvalsEvidenceType: evidence.ApprovalsEvidenceType
                }
                evidence.FileList.map((fileItem: IFileUpload) => {
                    participantFileUploads.push(fileItem);
                })
                evidenceList.push(participantEvidence)
            })
        }
        participant.evidenceList = evidenceList;
        participant.fileUploadConsent = participantFileUploads;
        participant.isFromParticipant = true;
        setParticipantData(participant)
        saveDataInStore(participant)
        setLoading(false);
    }

    const getLegalBasis = () => {
        return (
            <>
                {participantData?.dataSetLegalBasisList?.map((legalbasis, index) => (
                    legalbasis.checked ? (
                        <React.Fragment key={index}>
                            <SummaryList.Row >
                                <SummaryList.Key>
                                    <div>{
                                        legalbasis.sortBy === 1 ? 'Duty of confidentiality' : ''
                                    }</div>
                                </SummaryList.Key>
                                <SummaryList.Value>
                                    <div> {
                                        legalbasis.text
                                    }</div>
                                    <div> {
                                        legalbasis.name === 'Other' ? (participantData.dataSetSelectedLegalBasisOtherText) : (null)
                                    }</div>
                                </SummaryList.Value>
                                <SummaryList.Actions  >
                                    {
                                        legalbasis.sortBy === 1 ? <a href="" onClick={(e) => handleCLick(routeName.legalBasis, e)}>Change</a> : ''

                                    }
                                </SummaryList.Actions>
                            </SummaryList.Row>
                            {participantData.fileUploadConsent && participantData.fileUploadConsent.filter(item => checkIfEvidenceisOfLegalBasis(item.SelectedEvidenceUpload, legalbasis.value)).length > 0 ?
                                <SummaryList.Row >
                                    <SummaryList.Key>
                                       
                                    </SummaryList.Key>
                                    <SummaryList.Value>
                                        <>
                                            <ShowAll
                                                uploadedFiles={participantData.fileUploadConsent && participantData.fileUploadConsent?.length > 0 ? participantData.fileUploadConsent.filter(item => checkIfEvidenceisOfLegalBasis(item.SelectedEvidenceUpload, legalbasis.value)) : undefined as any}
                                                showDownload={showdownload}
                                                selectedIndex={selectedIndex}
                                                isExpender={isExpender}
                                                handleExpend={handleExpend}
                                                setFileUrl={setFileUrl}
                                            />
                                        </>
                                    </SummaryList.Value>
                                    <SummaryList.Actions  >
                                        <a href="" onClick={(e) => handleCLick(routeName.addAnotherFileRecord, e)}>Change</a>
                                    </SummaryList.Actions>
                                </SummaryList.Row>
                                : null}

                        </React.Fragment>

                    ) : (null)
                ))}
       </>)
    }
     const filterEvidenceTypeByLegalBasis = (legalBasisValue?: string) => {
        let multiTypeArray: number[] = [];
        Object.values(evidenceTypeOptions).forEach((option: EvidenceTypeAttributes, index) => {
            if (option?.legalBasis?.filter((value) => value !== undefined && value === legalBasisValue).length! > 0) {
                multiTypeArray.push(option.value);
            }
        })
        return multiTypeArray;
    }
    const checkIfEvidenceisOfLegalBasis = (selectedEvidenceType?: number, legalBasisValue?: string) => {
        let evidenceTypeArray = filterEvidenceTypeByLegalBasis(legalBasisValue);
        if (evidenceTypeArray.includes(selectedEvidenceType!)) {
            return true;
        } else {
            return false;
        }
    }

    const setFileUrl = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setSelectedIndex(Number(e.currentTarget.id));
        setshowdownload(true);
        e.currentTarget.className += "nhsuk-disableLink";
        downloadfile(e, e.currentTarget.id);
    }

    const downloadfile = (e: React.MouseEvent<HTMLAnchorElement>, fileId: string) => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "FileId": fileId
        });

        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };

        fetchInterceptor(GetDSAFileById_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data && data !== undefined) {
                    const b64Data: string = data.Data._buffer;
                    const blob: Blob = getB64toBlobData(b64Data, data.contentType, data.FileName);
                    const blobUrl: string = URL.createObjectURL(blob);
                    download(blobUrl, data.FileName);
                    setshowdownload(false);
                    let targetElement = e.target as HTMLAnchorElement;
                    targetElement.removeAttribute('class');
                }
            })
            .catch(() => navigate(routeName.serviceError));
    }

    const handleExpend = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setIsExpender(isExpender ? false : true)
    }

    useEffect(() => {
        getCohortDetailsData();
    }, [])

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {loading ?
                            (<Loader loadingText={dsaContent.txtLoading} />) : (
                                <div className="nhsuk-grid-column-two-thirds">
                                    {
                                        <GoBack condition={getTaskListType(dsaApplicationData.returningJourneyType!)} />
                                    }
                                    <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                        About the participants</span>
                                        Check your answers
                                    </h1>
                                    {summaryLinkErrorMessage !== "" ? errorSummary : null}
                                    <SummaryList>
                                        <SummaryList.Row>
                                            <SummaryList.Key>
                                                Participant data to NHS England
                                            </SummaryList.Key>
                                            <SummaryList.Value>
                                                {participantData.cohort ? 'Yes' : 'No'}
                                            </SummaryList.Value>
                                            <SummaryList.Actions  >
                                                <a href="" onClick={(e) => handleCLick(routeName.participantData, e)}>Change</a>
                                            </SummaryList.Actions>
                                        </SummaryList.Row>
                                        <SummaryList.Row>
                                            <SummaryList.Key>
                                                Type of Participant data
                                            </SummaryList.Key>
                                            <SummaryList.Value>
                                                {
                                                    String(participantData.typeOfCohort) === String(participantTypeOptions.new) ? dsaContent.participantNewAgreement : String(participantData.typeOfCohort) === String(participantTypeOptions.existing) ? dsaContent.participanExistingtAgreement : ""
                                                }
                                            </SummaryList.Value>
                                            <SummaryList.Actions>
                                                <a href="" onClick={(e) => handleCLick(routeName.participantTypes, e)}>Change</a>
                                            </SummaryList.Actions>
                                        </SummaryList.Row>
                                        <SummaryList.Row>
                                            <SummaryList.Key>
                                                Linking your Participant data with NHS England's data
                                            </SummaryList.Key>
                                            <SummaryList.Value>
                                                {(participantData.nhsNumber || participantData.dateOfBirth || participantData.postcode || participantData.forename || participantData.surname || participantData.sex || participantData.dateOfDeath || participantData.addressDate || participantData.telephoneNumber) ?
                                                    <div className="nhsuk-u-padding-bottom-4">
                                                        {participantData.nhsNumber ? <div>- NHS Number</div> : ""}
                                                        {participantData.dateOfBirth ? <div>- Date of Birth</div> : ""}
                                                        {participantData.forename ? <div> - Given name</div> : ""}
                                                        {participantData.surname ? <div>- Family name</div> : ""}
                                                        {participantData.sex ? <div>- Gender</div> : ""}
                                                        {participantData.postcode ? <div>- Postcode</div> : ""}
                                                        {participantData.dateOfDeath ? <div>- Date of death</div> : ""}
                                                        {participantData.addressDate ? <div>- Address date</div> : ""}
                                                        {participantData.telephoneNumber ? <div>- Telephone number</div> : ""}

                                                    </div>
                                                    : "N/A"}
                                                {participantData.additionalLinkageCohortInformation ?
                                                    <><div className="nhsuk-u-padding-bottom-4">
                                                        <div>Any additional or relevant information</div>
                                                    </div>
                                                        <div>
                                                            <p>{participantData.additionalLinkageCohortInformation}</p>
                                                        </div> </> : null}

                                            </SummaryList.Value>
                                            <SummaryList.Actions>
                                                <a href="#" onClick={(e) => handleCLick(routeName.linkingParticipant, e)}>Change</a>
                                            </SummaryList.Actions>
                                        </SummaryList.Row>
                                        <SummaryList.Row>
                                            <SummaryList.Key>
                                                Participant Data Provider(s)
                                            </SummaryList.Key>
                                            <SummaryList.Value>
                                                {
                                                    participantData.participantContributorList != null ? (
                                                        participantData.participantContributorList.map((journal) => (
                                                            <div className='nhsuk-u-margin-bottom-3' key={journal.id}>
                                                                <div>{journal.firstName} {' '} {journal.lastName}</div>
                                                                <div>{journal.role}</div>
                                                                <div>{journal.organisation}</div>
                                                                <div> {journal.email}</div>
                                                            </div>))

                                                    ) : (null)
                                                }
                                            </SummaryList.Value>
                                            <SummaryList.Actions>
                                                <a href="" onClick={(e) => handleCLick(routeName.addCohortContributor, e)}>Change</a>
                                            </SummaryList.Actions>
                                        </SummaryList.Row>
                                        {
                                            getLegalBasis()
                                        }
                                    </SummaryList>
                                    <div className="nhsuk-u-padding-bottom-4"></div>
                                    <Button name="continue" id="nhsuk-button-spinner" onClick={handleClickContinue}>{dsaContent.btnTextContinue}</Button>
                                </div>)}
                    </div>
                </main>
            </div >


        </>)
}
export default ParticipantSummary;
